import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./bottom-menu.styles";
import { NavItem } from "@shared/types/nav-item";
import { t } from "@lingui/macro";
import { ROUTES } from "../../../routing/routes";
import { CoursesIcon } from "@shared/images/courses-icon";
import { InstructionsIcon } from "@shared/images/instructions-icon";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProfileIcon } from "@shared/images/profile-icon";
import { HomeIcon } from "@shared/images/home-icon";

export type BottomMenuProps = AppWithStyles<typeof styles>;

const BottomMenu: FC<BottomMenuProps> = ({ classes }: BottomMenuProps) => {
  const menuItems: Array<NavItem> = [
    { label: t`Home`, path: ROUTES.home, icon: <HomeIcon /> },
    { label: t`Courses`, path: ROUTES.courses, icon: <CoursesIcon /> },
    {
      label: t`Catalog`,
      path: ROUTES.catalog,
      icon: <InstructionsIcon />,
    },
    { label: t`Profile`, path: ROUTES.profile, icon: <ProfileIcon /> },
  ];

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0px",
        left: "0px",
        width: "100%",
        height: "80px",
        backgroundColor: baseTheme.palette.primary.main,
        display: "flex",
        justifyContent: "space-around",
        padding: "0px 24px",
        zIndex: 9999,
        [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
          display: "none",
        },
      }}
    >
      {menuItems.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => navigate(item.path)}
          >
            <IconButton aria-label={item.label}>{item.icon}</IconButton>
            <div className={classes.label}>{item.label}</div>
          </Box>
        );
      })}
    </Box>
  );
};

export default appWithStyles(styles)(BottomMenu);
