import React, { FC, KeyboardEvent, useEffect } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles, TextButton } from "./otp-view.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { OTPTextField } from "@shared/components/otp-text-field";
import { AuthFormLayout } from "@shared/components/layouts/auth-form-layout";
import {
  IOTPView,
  OTPViewFields,
  OTPViewFieldsResolver,
} from "@ui/auth/login/components/otp-view/otp-view.validator";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import { getTimeNumber } from "@shared/utils/number";
import { useTimer } from "react-timer-hook";
import moment from "moment";
import { Loader } from "@shared/components/loader";

export interface OTPViewProps extends AppWithStyles<typeof styles> {
  onSubmit: (formData: IOTPView) => void;
  onResend: () => void;
  onBack: () => void;
  timerDate: Date;
  timerDelayMinutes: number;
  isLoading: boolean;
  error?: string;
}

const OTPView: FC<OTPViewProps> = ({
  classes,
  onSubmit,
  onResend,
  timerDate,
  timerDelayMinutes,
  error,
  onBack,
}: OTPViewProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OTPViewFields>({ resolver: OTPViewFieldsResolver });

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(),
  });

  const handleRestart = () => {
    restart(moment(timerDate).add(timerDelayMinutes, "m").toDate());
  };

  useEffect(handleRestart, [timerDate]);

  const loader = (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  );

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: IOTPView) => {
    onSubmit(data);
  };

  const resendComponent = isRunning ? (
    <Box sx={{ padding: "10px 0px" }}>
      <Typography
        component="div"
        className={classes.resendTitle}
      >{t`Resend Code in ${getTimeNumber(minutes)}:${getTimeNumber(seconds)} sec`}</Typography>
    </Box>
  ) : (
    <>
      <Typography
        component="div"
        className={classes.resendTitle}
      >{t`Didn't receive the code?`}</Typography>
      <Box sx={{ padding: "10px 0px" }}>
        <TextButton onClick={onResend}>{t`Send again`}</TextButton>
      </Box>
    </>
  );

  return (
    <AuthFormLayout title={t`You have been sent an SMS code`}>
      <Box
        className={classes.form}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <OTPTextField
          controls={register(nameOf<IOTPView>("code"))}
          label={t`Enter the code from the SMS`}
          size={4}
          hasError={!!errors.code || !!error}
        />
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {resendComponent}
          <Box sx={{ padding: "10px 0px" }}>
            <TextButton onClick={onBack}>{t`Change phone number`}</TextButton>
          </Box>
        </Box>
        <StyledButton
          label={t`Next`}
          type={"submit"}
          disabled={!isValid}
          onClick={() => {}}
        />
      </Box>
    </AuthFormLayout>
  );
};

export default appWithStyles(styles)(OTPView);
