import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    image: {
      height: "200px",
      borderRadius: "10px",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        width: "100%",
        height: "auto",
      },
    },
    tabs: {
      "& .MuiTabs-flexContainer": {
        flexDirection: "column",
        gap: "10px",
      },
    },
  });
}
