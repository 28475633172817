import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "shared/material";
import { styles } from "@ui/app/app.styles";
import { Box } from "@mui/material";
import { NotFoundComponent } from "@shared/components/not-found-component";
import theme from "@shared/theme/theme";
import { CssBaseline, ThemeProvider } from "@shared/material";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { PrivateLayoutWithFooter } from "@shared/components/layouts/private-layout-with-footer";
import { AuthLayout } from "@shared/components/layouts/auth-layout";

export type NotFoundScreenProps = AppWithStyles<typeof styles>;

const NotFoundScreen: FC<NotFoundScreenProps> = () => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  let component = <></>;
  if (authService.isLoggedIn) {
    component = (
      <PrivateLayoutWithFooter>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <NotFoundComponent />
        </Box>
      </PrivateLayoutWithFooter>
    );
  } else {
    component = (
      <AuthLayout>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <NotFoundComponent />
        </Box>
      </AuthLayout>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component}
    </ThemeProvider>
  );
};

export default appWithStyles(styles)(NotFoundScreen);
