import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Button } from "shared/material";
import { styles } from "@ui/app/app.styles";
import { ButtonProps } from "@mui/material";

export interface StyledButtonProps extends AppWithStyles<typeof styles> {
  label: string;
  color?: string;
  variant?: string;
  onClick: () => void;
}

const StyledButton: FC<StyledButtonProps & ButtonProps> = ({
  classes,
  label,
  onClick,
  color,
  variant,
  ...props
}) => {
  return (
    <Button
      {...props}
      variant={variant || "contained"}
      onClick={onClick}
      color={color || "primary"}
      className={classes.root}
    >
      {label}
    </Button>
  );
};

export default appWithStyles(styles)(StyledButton);
