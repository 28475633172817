import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25093 13.3536L12.1085 5.77867C12.4269 5.37018 12.5401 4.89791 12.4339 4.41704C12.342 3.97988 12.0731 3.56422 11.6699 3.2489L10.6866 2.46775C9.83057 1.78694 8.76941 1.8586 8.16102 2.63975L7.5031 3.49328C7.41821 3.60006 7.43943 3.75772 7.54554 3.84372C7.54554 3.84372 9.20802 5.17668 9.2434 5.20535C9.35659 5.31285 9.44148 5.45618 9.4627 5.62817C9.49807 5.965 9.26462 6.28032 8.91797 6.32332C8.75526 6.34482 8.59963 6.29466 8.48644 6.20149L6.73906 4.81119C6.65417 4.74741 6.52683 4.76103 6.45609 4.84702L2.30343 10.2219C2.0346 10.5587 1.94263 10.9959 2.0346 11.4187L2.56518 13.7191C2.59348 13.841 2.69959 13.927 2.82693 13.927L5.16148 13.8983C5.58594 13.8911 5.98211 13.6976 6.25093 13.3536ZM9.51979 12.6372H13.3265C13.6979 12.6372 14 12.9432 14 13.3195C14 13.6964 13.6979 14.0017 13.3265 14.0017H9.51979C9.14839 14.0017 8.84631 13.6964 8.84631 13.3195C8.84631 12.9432 9.14839 12.6372 9.51979 12.6372Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}
