import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./users-management.styles";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { SectionHeader } from "@shared/components/section-header";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import { Loader } from "@shared/components/loader";
import { UsersTable } from "@ui/admin/users-management/components/users-table";
import { UsersManagementViewModel } from "@ui/admin/users-management/users-managment.vm";
import { PaginationResponseModel } from "@shared/models/pagination-response.model";
import { UserDetailsDto } from "@shared/models/users/user-details.dto";
import { UsersInfoDialog } from "./components/user-info-dialog/inded";
import { Role } from "@shared/enum/role.enum";
import { UserStatus } from "@shared/enum/user-status.enum";
import { UpdateUserPermissionRequestDto } from "@shared/dto/admin/update-user-permission-request.dto";
import { StyledSearchField } from "@shared/components/styled-search-field";
import { StyledButton } from "@shared/components/buttons/styled-button";

export type UsersManagementProps = AppWithStyles<typeof styles>;

const UsersManagement: FC<UsersManagementProps> = appObserver(() => {
  const [_, render] = useState(0);

  const usersListQuery: UseQueryResult<
    PaginationResponseModel<UserDetailsDto>,
    unknown
  > = useQuery(["download-users-list"], () => $vm.getUsersList(), {
    refetchOnMount: true,
  });

  const updateUserMutation = useMutation(
    (data: { id: string; role?: Role; status?: UserStatus }) =>
      $vm.updateUser(data),
    {
      onSuccess: () => {
        usersListQuery.refetch();
      },
    },
  );

  const updatePermissionMutation = useMutation(
    (data: { id: string } & UpdateUserPermissionRequestDto) =>
      $vm.updatePermission(data),
    {
      onSuccess: () => {
        usersListQuery.refetch();
      },
    },
  );

  const $vm: UsersManagementViewModel = useMemo(
    () => new UsersManagementViewModel().setQuery(usersListQuery),
    [],
  );

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  let component = <></>;
  if (usersListQuery.isLoading) {
    component = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 367px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else {
    component = (
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          paddingBottom: "80px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        {usersListQuery.data ? (
          <UsersTable
            isLoading={
              (usersListQuery.isFetching && !usersListQuery.isLoading) ||
              updateUserMutation.isLoading
            }
            data={usersListQuery.data}
            sortField={$vm.sortField}
            sortOrder={$vm.sortOrder}
            pageNumber={$vm.pageNumber}
            pageSize={$vm.pageSize}
            filterRole={$vm.filterRole}
            filterStatus={$vm.filterStatus}
            onChangePageNumber={(e) => $vm.setPageNumber(e)}
            onChangePageSize={(e) => $vm.setPageSize(e)}
            onChangeSortField={(e) => $vm.setSortField(e)}
            onChangeFilterRole={(e) => $vm.setFilterRole(e)}
            onChangeFilterStatus={(e) => $vm.setFilterStatus(e)}
            onSelectUser={(user) => $vm.setSelectedUser(user)}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  }

  return (
    <Box>
      {$vm.selectedUser ? (
        <UsersInfoDialog
          isOpen={$vm.isOpenUserDialog}
          onClose={() => $vm.closeUserDialog()}
          user={$vm.selectedUser}
          onApproveUser={(id: string) =>
            updateUserMutation.mutate({ id, status: UserStatus.VERIFIED })
          }
          onUnblockUser={(id: string) =>
            updateUserMutation.mutate({ id, status: UserStatus.VERIFIED })
          }
          onBlockUser={(id: string) =>
            updateUserMutation.mutate({ id, status: UserStatus.BLOCKED })
          }
          onAddAdminRole={(id: string) =>
            updateUserMutation.mutate({ id, role: Role.ADMIN })
          }
          onRemoveAdminRole={(id: string) =>
            updateUserMutation.mutate({ id, role: Role.USER })
          }
          onUpdatePermission={(data) => updatePermissionMutation.mutate(data)}
        />
      ) : (
        <></>
      )}
      <SectionHeader
        title={t`Users List`}
        searchField={
          <Box
            sx={{
              width: "30vw",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                position: "absolute",
                bottom: "-80px",
                left: "0px",
              },
            }}
          >
            <StyledSearchField
              placeholder={t`Search in table`}
              onSearch={(e) => $vm.setSearchString(e)}
              value={$vm.searchString}
            />
          </Box>
        }
        button={
          <StyledButton
            label={t`Add User`}
            onClick={() => {}}
            color="secondary"
          />
        }
      />
      {component}
    </Box>
  );
});

export default appWithStyles(styles)(UsersManagement);
