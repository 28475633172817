import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export const MuiToolbar = {
  styleOverrides: {
    root: {
      paddingLeft: "135px",
      paddingRight: "135px",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        paddingLeft: "135px",
        paddingRight: "135px",
      },
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
  },
};
