import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {
      "& .MuiAvatar-root": {
        borderRadius: "15px",
      },

      "& .MuiDialogActions-root>:not(style)~:not(style)": {
        marginLeft: "0px",
      },
    },
  });
}
