import { TranslationDto } from "@shared/models/translation.dto";

import { BaseModel } from "@core/models/base-model";
import { Version } from "@shared/enum/version.enum";

export interface CarInstallationDto {
  id: string;
  systemId: string;
  nameOfConnectionPoints: string;
  connectionPointsImg: string[];
  nameOfConnectionDiagram: string;
  connectionDiagram: string;
  version: Version;
  connectionDescription: TranslationDto;
  descriptionPoints: TranslationDto;
}

export class CarInstallationModel extends BaseModel<CarInstallationDto> {
  private id?: string;
  private systemId?: string;
  private nameOfConnectionPoints?: string;
  private connectionPointsImg?: string[];
  private nameOfConnectionDiagram?: string;
  private connectionDiagram?: string;
  private version?: Version;
  private connectionDescription?: TranslationDto;
  private descriptionPoints?: TranslationDto;

  constructor(data: CarInstallationDto) {
    super();

    this.update(data);
  }

  get asJson(): CarInstallationDto {
    return {
      id: this.id || "",
      systemId: this.systemId || "",
      nameOfConnectionPoints: this.nameOfConnectionPoints || "",
      connectionPointsImg: this.connectionPointsImg || [],
      nameOfConnectionDiagram: this.nameOfConnectionDiagram || "",
      connectionDiagram: this.connectionDiagram || "",
      version: this.version || Version.V1,
      connectionDescription: this.connectionDescription || {
        uk: "",
        en: "",
        ru: "",
      },
      descriptionPoints: this.descriptionPoints || { uk: "", en: "", ru: "" },
    };
  }
}
