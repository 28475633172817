import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./not-found-component.styles";
import { Box } from "@mui/material";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { t } from "@lingui/macro";
import { StyledButton } from "@shared/components/buttons/styled-button";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import NotFoundIcon from "@shared/images/404";
import { IAuthService } from "@shared/interfaces/auth-service.interface";

export type NotFoundComponentProps = AppWithStyles<typeof styles>;

const NotFoundComponent: FC<NotFoundComponentProps> = () => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  const navigate = useNavigate();

  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
      }}
    >
      <NotFoundIcon sx={{ width: "140px" }} />
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          width: "240px",
          lineHeight: "19.2px",
          textAlign: "center",
        }}
      >{t`The page does not exist or a server error occurred`}</Box>
      <StyledButton
        sx={{
          width: "350px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            width: "100%",
          },
        }}
        label={t`Home`}
        onClick={() =>
          navigate(authService.isLoggedIn ? ROUTES.home : ROUTES.welcomeScreen)
        }
      />
    </Box>
  );
};

export default appWithStyles(styles)(NotFoundComponent);
