import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./onboarding-form.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { AuthFormLayout } from "@shared/components/layouts/auth-form-layout";
import { StyledTextField } from "@shared/components/styled-text-field";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import {
  IOnboardingForm,
  OnboardingFormFields,
  OnboardingFormFieldsResolver,
} from "@ui/onboarding/components/onboarding-form/onboarding-form.validator";
import { appInject } from "@core/di/utils";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { StyledOutlinedSelect2 } from "@shared/components/styled-outlined-select2";
import { IUserDataForm } from "@ui/private/profile/components/user-data-form/user-data-form.validator";
import { SelectItem } from "@shared/types/select-item";
import { Countries } from "@shared/resources/countries";

export interface NameFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (data: IOnboardingForm) => void;
}

const OnboardingForm: FC<NameFormProps> = ({
  classes,
  onSubmit,
}: NameFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<OnboardingFormFields>({ resolver: OnboardingFormFieldsResolver });

  const usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  const [cities, setCities] = useState<Array<SelectItem>>([]);
  const [defaultCity, setDefaultCity] = useState<string>(
    usersService.me?.asJson.city || "",
  );
  const [isVisibleCity, setIsVisibleCity] = useState<boolean>(true);
  const [isVisibleCountry, setIsVisibleCountry] = useState<boolean>(true);

  useEffect(() => {
    setValue(
      nameOf<IOnboardingForm>("email"),
      usersService.me?.asJson.email || "",
    );
    setValue(
      nameOf<IOnboardingForm>("country"),
      usersService.me?.asJson.country || "",
    );
    setValue(
      nameOf<IOnboardingForm>("city"),
      usersService.me?.asJson.city || "",
    );
    setValue(
      nameOf<IOnboardingForm>("organization"),
      usersService.me?.asJson.organization || "",
    );
    setValue(
      nameOf<IOnboardingForm>("dealershipCenter"),
      usersService.me?.asJson.dealershipCenter || "",
    );
  }, []);

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const refreshCity = () => {
    setIsVisibleCity(false);
    setTimeout(() => setIsVisibleCity(true), 0);
  };

  const submitForm = (data: IOnboardingForm) => {
    onSubmit(data);
  };

  const CountriesList = useMemo(
    () =>
      Object.keys(Countries).map(
        (country) =>
          ({
            id: country,
            label: country,
          }) as SelectItem,
      ),
    [],
  );

  const getCitiesList = useCallback(() => {
    const currentCountry = getValues()?.country || "";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return (Countries[currentCountry].cities || []).map(
      (city: string) =>
        ({
          id: city,
          label: city,
        }) as SelectItem,
    );
  }, []);

  return (
    <AuthFormLayout title={t`Your data`}>
      <Box
        className={classes.form}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <Box
          sx={{
            paddingBottom: "40px",
          }}
        >
          {isVisibleCountry ? (
            <StyledOutlinedSelect2
              items={CountriesList}
              controls={register(nameOf<IOnboardingForm>("country"))}
              label={t`Country`}
              hasError={!!errors.country}
              error={errors.country?.message || ""}
              defaultValue={usersService.me?.asJson.country || ""}
              onChange={() => {
                setDefaultCity("");
                setValue(nameOf<IOnboardingForm>("city"), "");
                setCities(getCitiesList());
                refreshCity();
              }}
            />
          ) : (
            <StyledTextField
              controls={register(nameOf<IOnboardingForm>("country"))}
              label={""}
              boldLabel={t`Country`}
              hasError={!!errors.country}
              error={errors.country?.message || ""}
            />
          )}

          {isVisibleCity ? (
            <StyledOutlinedSelect2
              items={cities}
              controls={register(nameOf<IOnboardingForm>("city"))}
              label={t`City`}
              hasError={!!errors.city}
              error={errors.city?.message || ""}
              defaultValue={defaultCity}
            />
          ) : (
            <StyledTextField
              controls={register(nameOf<IOnboardingForm>("city"))}
              label={""}
              boldLabel={t`City`}
              hasError={!!errors.city}
              error={errors.city?.message || ""}
            />
          )}
          <StyledTextField
            controls={register(nameOf<IOnboardingForm>("organization"))}
            label={t`Organization`}
            hasError={!!errors.organization?.message}
            error={errors.organization?.message || ""}
          />
          <StyledTextField
            controls={register(nameOf<IOnboardingForm>("dealershipCenter"))}
            label={t`Dealership center`}
            hasError={!!errors.dealershipCenter?.message}
            error={errors.dealershipCenter?.message || ""}
          />
          <StyledTextField
            controls={register(nameOf<IOnboardingForm>("email"))}
            label={t`Email`}
            hasError={!!errors.email?.message}
            error={errors.email?.message || ""}
          />
        </Box>
        <StyledButton
          label={t`Next`}
          onClick={() => {}}
          type={"submit"}
          disabled={false}
        />
      </Box>
    </AuthFormLayout>
  );
};

export default appWithStyles(styles)(OnboardingForm);
