export const ROUTES = {
  initial: "/",
  welcomeScreen: "/welcome",
  contactScreen: "/contact",
  permissionScreen: "/waiting",
  onboardingScreen: "/onboarding",
  login: "/login",
  registration: "/registration",
  home: "/home",
  courses: "/courses-list",
  blockDetails: (blockId: string = ":blockId") => `/course/${blockId}`,
  courseDetails: (
    blockId: string = ":blockId",
    courseId: string = ":courseId",
  ) => `/course/${blockId}/${courseId}`,
  catalog: "/catalog",
  installer: "/installer",
  usersManagement: "/users-management",
  brands: (version: string = ":version") => `/catalog/${version}`,
  models: (version: string = ":version", brand: string = ":brand") =>
    `/catalog/${version}/${brand}`,
  modifications: (
    version: string = ":version",
    brand: string = ":brand",
    model: string = ":model",
    years: string = ":years",
  ) => `/catalog/${version}/${brand}/${model}/${years}`,
  profile: "/profile",
};
