import { appMakeObservable, appObservable } from "@core/state-management/utils";

export class ConnectionDiagramVM {
  private _isLoad: boolean = false;
  private _isVisiblePDF: boolean = true;

  constructor() {
    appMakeObservable(this, {
      _isVisiblePDF: appObservable,
      _isLoad: appObservable,
    });
    this.start();
  }

  async start() {
    for (;;) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      if (this._isLoad) {
        return;
      }
      this._isVisiblePDF = false;
      setTimeout(() => {
        this._isVisiblePDF = true;
      }, 0);
    }
  }

  get isVisiblePDF() {
    return this._isVisiblePDF;
  }

  setAsLoaded() {
    this._isLoad = true;
  }
}
