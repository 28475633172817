import React, { ChangeEvent, FC } from "react";
import { AppWithStyles, appWithStyles, FormControl } from "shared/material";
import { styles } from "./styled-search-field.styles";
import { TextField } from "@mui/material";

export interface StyledSearchFieldProps extends AppWithStyles<typeof styles> {
  placeholder?: string;
  onSearch: (searchValue: string) => void;
  value: string;
}

const StyledSearch: FC<StyledSearchFieldProps> = ({
  classes,
  placeholder,
  value,
  onSearch,
}: StyledSearchFieldProps) => {
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onSearch(e.target.value);
  };

  return (
    <FormControl fullWidth className={classes.root}>
      <TextField
        variant="outlined"
        placeholder={placeholder || ""}
        value={value}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledSearch);
