import { BaseModel } from "@core/models/base-model";

export interface UserDetailsDto {
  id: string;
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  organization: string;
  dealershipCenter: string;
  phoneNumber: string;
  email: string;
  role: string;
  permissions: Array<string>;
  status: string;
  avatarUrl: string;
  createdAt: string;
}

export class UserDetailsModel extends BaseModel<UserDetailsDto> {
  private id?: string;
  private firstName?: string;
  private lastName?: string;
  private country?: string;
  private city?: string;
  private organization?: string;
  private dealershipCenter?: string;
  private phoneNumber?: string;
  private email?: string;
  private role?: string;
  private permissions?: Array<string>;
  private status?: string;
  private avatarUrl?: string;
  private createdAt?: string;

  constructor(data: UserDetailsDto) {
    super();

    this.update(data);
  }

  get asJson(): UserDetailsDto {
    return {
      id: this.id || "",
      firstName: this.firstName || "",
      lastName: this.lastName || "",
      country: this.country || "",
      city: this.city || "",
      organization: this.organization || "",
      dealershipCenter: this.dealershipCenter || "",
      phoneNumber: this.phoneNumber || "",
      email: this.email || "",
      role: this.role || "",
      permissions: this.permissions || [],
      status: this.status || "",
      avatarUrl: this.avatarUrl || "",
      createdAt: this.createdAt || "",
    };
  }
}
