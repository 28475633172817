import { appInjectable } from "@core/di/utils";
import {
  IScrollPosition,
  IScrollService,
} from "@shared/interfaces/scroll-service.interface";
import { appMakeObservable, appObservable } from "@core/state-management/utils";

@appInjectable()
export class ScrollService implements IScrollService {
  private _currentScrollPosition: number = 0;
  private _canScroll: boolean = true;
  private _positionsMap: Map<string, IScrollPosition> = new Map();

  constructor() {
    appMakeObservable(this, {
      _currentScrollPosition: appObservable,
      _canScroll: appObservable,
    });
    window.addEventListener("scroll", () => this.checkScroll());
  }

  checkScroll() {
    this._currentScrollPosition = this.scrollableComponent?.scrollTop || 0;
    if (this.scrollableComponent) {
      this._canScroll =
        this.scrollableComponent.scrollHeight -
          this.scrollableComponent.clientHeight >
        30;
    } else {
      this._canScroll = false;
    }
  }

  get canScroll() {
    return this._canScroll;
  }

  get currentScrollPosition() {
    return this._currentScrollPosition;
  }

  setScrollPosition(position: number): void {
    if (this.scrollableComponent) {
      this.scrollableComponent.scrollTo({
        top: position,
      });
    }
  }

  private get scrollableComponent() {
    return window.document.querySelector("html");
  }

  scrollToEnd(): void {
    if (this.scrollableComponent) {
      this.scrollableComponent.scrollTo({
        top: this.scrollableComponent.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  scrollToTop(): void {
    if (this.scrollableComponent) {
      this.scrollableComponent.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  storePosition(key: string, position: number, conditions: string = ""): void {
    this._positionsMap.set(key, {
      position,
      conditions,
    });
  }

  getStoredPosition(key: string): IScrollPosition {
    return this._positionsMap.get(key) || { position: 0, conditions: "" };
  }

  deleteStoredPosition(key: string): void {
    this._positionsMap.delete(key);
  }
}
