import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import RedBlurBackground from "@shared/images/red-blur.png";
import GreyBlurBackground from "@shared/images/grey-blur.png";

export function styles() {
  return appCreateStyles({
    item: {
      cursor: "pointer",
      width: "100%",
      height: "43vh",
      borderRadius: "2vh",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionX: "0px",
      backgroundPositionY: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        width: "38vw",
        height: "30vw",
        borderRadius: "1vw",
        backgroundSize: "cover",
        backgroundPositionX: "0px",
        backgroundPositionY: "-31px",
      },
    },
    red: {
      border: `1px solid ${baseTheme.palette.secondary.main}`,
      backgroundImage: `url(${RedBlurBackground})`,
    },
    grey: {
      border: `1px solid #80868B`,
      backgroundImage: `url(${GreyBlurBackground})`,
    },
    label: {
      position: "absolute",
      width: "270px",
      transform: "translate(-50%, 0)",
      left: "50%",
      top: "-21px",
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "33.6px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      backgroundColor: baseTheme.palette.background.default,
    },
    description: {
      position: "absolute",
      width: "205px",
      textAlign: "center",
      transform: "translate(-50%, 0)",
      left: "50%",
      top: "35px",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      backgroundColor: "transparent",
      color: "#E8EAED",
    },
    image: {
      width: "30vh",
      marginTop: "58px",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        width: "30vw",
        marginTop: "0px",
      },
    },
  });
}
