import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Breadcrumbs } from "shared/material";
import { styles } from "./styled-breadcrumb.styles";
import { NavItem } from "@shared/types/nav-item";
import { useNavigate } from "react-router-dom";

export interface StyledBreadcrumbProps extends AppWithStyles<typeof styles> {
  paths: Array<NavItem>;
}

const StyledBreadcrumb: FC<StyledBreadcrumbProps> = ({
  classes,
  paths,
}: StyledBreadcrumbProps) => {
  const navigate = useNavigate();

  const breadcrumbs = paths.map((navItem, index) => {
    if (index === paths.length - 1) {
      return (
        <div key={index} className={classes.label}>
          {navItem.label}
        </div>
      );
    } else {
      return (
        <div
          className={classes.link}
          key={index}
          onClick={() => navigate(navItem.path)}
        >
          {navItem.label}
        </div>
      );
    }
  });

  return (
    <Breadcrumbs
      className={classes.root}
      separator={">"}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default appWithStyles(styles)(StyledBreadcrumb);
