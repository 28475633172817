import { t } from "@lingui/macro";
import { PhoneConverter } from "@shared/utils/phone-converter";
import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  Validate,
} from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

export interface IPhoneNumberForm {
  phoneNumber?: string;
}

@ValidatorConstraint({ name: "phoneNumber", async: false })
export class PhoneNumber implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
    const phoneConverter = new PhoneConverter();
    return phoneConverter.isValidNumber(text);
  }

  defaultMessage() {
    return t`Phone number must be exactly 10 digits long and contain only numbers`;
  }
}

export class PhoneNumberFormFields implements IPhoneNumberForm {
  @Validate(PhoneNumber)
  phoneNumber?: string;
}

export const PhoneNumberFieldsResolver = classValidatorResolver(
  PhoneNumberFormFields,
);
