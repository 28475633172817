import { t } from "@lingui/macro";

export enum FormErrorType {
  MIN_LENGTH = "minLength",
  MAX_LENGTH = "maxLength",
  IS_EMAIL = "isEmail",
  IS_PHONE_NUMBER = "isPhoneNumber",
  IS_URL = "isURL",
  IS_FILE = "isFile",
}

export const getFormError = (
  errorType: FormErrorType,
  opts: { length?: number } = {},
): string => {
  switch (errorType) {
    case FormErrorType.MIN_LENGTH:
      return t`Field must be longer than ${opts.length} characters`;
    case FormErrorType.MAX_LENGTH:
      return t`Field must be longer than ${opts.length} characters`;
    case FormErrorType.IS_EMAIL:
      return t`Email address must be valid`;
    case FormErrorType.IS_PHONE_NUMBER:
      return t`Phone number must be valid`;
    case FormErrorType.IS_URL:
      return t`Url must be valid`;
    case FormErrorType.IS_FILE:
      return t`File must be valid`;
    default:
      return "";
  }
};
