import { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./user-status-label.styles";
import { UserStatus } from "@shared/enum/user-status.enum";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";

export interface UserStatusLabelProps extends AppWithStyles<typeof styles> {
  status: UserStatus;
}

const UserStatusLabel: FC<UserStatusLabelProps> = ({
  status,
}: UserStatusLabelProps) => {
  let text = "";
  let color = "#fff";
  switch (status) {
    case UserStatus.VERIFIED:
      text = t`Verified`;
      color = "#20B957";
      break;
    case UserStatus.BLOCKED:
      text = t`Blocked`;
      color = "#D52037";
      break;
    case UserStatus.NEW:
      text = t`New`;
      color = "#fff";
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        padding: "5px",
        backgroundColor: "transparent",
        color: color,
        fontWeight: 600,
        borderRadius: "5px",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {text}
    </Box>
  );
};

export default appWithStyles(styles)(UserStatusLabel);
