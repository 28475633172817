import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function HistoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65566 6.14366C11.9959 0.673143 20.6713 0.673141 26.0115 6.14366C31.3298 11.5917 31.3298 20.4086 26.0115 25.8566C20.6713 31.3271 11.9959 31.3271 6.65566 25.8566C6.01268 25.1979 6.02539 24.1427 6.68406 23.4998C7.34272 22.8568 8.39792 22.8695 9.04091 23.5281C13.0732 27.6588 19.5939 27.6588 23.6262 23.5281C27.6805 19.3751 27.6805 12.6252 23.6262 8.47211C19.5939 4.34144 13.0732 4.34143 9.04091 8.47211L7.84828 7.30789L9.04091 8.47211C7.36319 10.1908 6.37697 12.3554 6.08914 14.604C6.72878 14.1857 7.5948 14.2515 8.16435 14.8074C8.82302 15.4504 8.83573 16.5056 8.19275 17.1643L5.53635 19.8855C5.23302 20.2016 4.80625 20.3985 4.33352 20.3985C3.93597 20.3984 3.57094 20.2593 3.28448 20.0269C3.23435 19.9863 3.18636 19.9426 3.14083 19.896L0.474165 17.1643C-0.16882 16.5056 -0.156106 15.4504 0.502562 14.8074C1.11712 14.2075 2.07686 14.1784 2.72492 14.7122C3.00658 11.5925 4.31409 8.54234 6.65566 6.14366ZM16.6667 10.5367C16.6667 9.61626 15.9205 8.87007 15 8.87007C14.0796 8.87007 13.3334 9.61626 13.3334 10.5367V17.366C13.3334 18.2864 14.0796 19.0326 15 19.0326H21.6667C22.5872 19.0326 23.3334 18.2864 23.3334 17.366C23.3334 16.4455 22.5872 15.6993 21.6667 15.6993H16.6667V10.5367Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}

export default HistoryIcon;
