import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function HeaderLogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="143"
      height="24"
      viewBox="0 0 143 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ cursor: "pointer" }}
      {...props}
    >
      <path
        d="M10.8658 14.1085H22.7751V15.4428C22.7751 17.2173 22.062 17.9405 20.1787 17.9405H9.19343C7.46746 17.9405 6.55677 17.0867 6.55677 15.4428V8.54395C6.55677 6.44132 7.46746 5.48878 9.69565 5.48878H27.7153V0.19873H8.97412C3.63887 0.19873 0.5 3.08984 0.5 8.08358V15.3122C0.5 20.4365 3.48151 23.1971 8.97412 23.1971H20.3561C25.534 23.1971 28.5791 20.4047 28.5791 15.5416V9.19516H10.8658V14.1085Z"
        fill="white"
      />
      <path
        d="M49.7391 0.19873H32.6603V5.48711H49.5081C51.2341 5.48711 51.9439 6.34088 51.9439 7.95133V8.87207H38.995C33.8171 8.87207 30.772 11.5004 30.772 16.0337C30.772 20.5671 33.8154 23.1971 38.995 23.1971H49.339C54.6743 23.1971 57.8132 20.3059 57.8132 15.3122V8.08358C57.8132 2.95759 55.2318 0.19873 49.7408 0.19873H49.7391ZM51.9422 14.9155C51.9422 17.0516 51.0315 17.9388 48.805 17.9388H39.2461C37.639 17.9388 36.735 17.3144 36.735 15.8362C36.735 14.358 37.639 13.7336 39.2461 13.7336H51.9439V14.9155H51.9422Z"
        fill="white"
      />
      <path
        d="M67.7323 17.1504L82.0021 9.20022C83.9791 8.11543 84.8898 6.6322 84.8898 4.60156C84.8898 1.90799 82.8893 0.200439 80.4954 0.200439H60.266V5.48882H76.4542C76.933 5.48882 77.0083 6.03624 76.6116 6.24549L62.3402 14.1956C60.3949 15.2804 59.4524 16.7385 59.4524 18.796C59.4524 20.8534 61.026 23.1988 63.9422 23.1988H84.1114V17.9422H67.8897C67.3975 17.9422 67.3222 17.3663 67.7323 17.1537V17.1504Z"
        fill="white"
      />
      <path
        d="M105.703 14.522C110.881 14.522 113.926 11.8938 113.926 7.36039C113.926 2.82701 110.883 0.19873 105.703 0.19873H95.1182C89.783 0.19873 86.6458 3.08984 86.6458 8.08358V15.3122C86.6458 20.4365 89.6273 23.1971 95.1182 23.1971H112.289V17.9405H95.3392C93.4877 17.9405 92.515 17.0214 92.515 15.2787V14.5237H105.705L105.703 14.522ZM92.5134 8.43012C92.5134 6.32749 93.424 5.45363 95.6522 5.45363H105.452C107.272 5.45363 107.962 6.06801 107.962 7.55625C107.962 9.0445 107.272 9.65888 105.452 9.65888H92.5134V8.43012Z"
        fill="white"
      />
      <path
        d="M139.441 13.1426C139.208 13.0455 139.201 12.7576 139.426 12.6571C141.657 11.646 142.862 9.79113 142.862 7.16285C142.862 2.69476 139.911 0.19873 134.641 0.19873H124.22C118.778 0.19873 116.349 3.2154 116.349 8.20914V23.1954H122.312V15.5231H133.648C135.751 15.5231 136.399 16.2129 136.399 17.9221V23.1954H142.424V18.0711C142.424 15.5734 141.512 14.0048 139.444 13.1426H139.441ZM133.889 10.5327H122.308V8.43012C122.308 6.32749 123.018 5.45363 125.246 5.45363H133.889C135.898 5.45363 136.802 6.37604 136.802 8.06517C136.802 9.59359 135.898 10.5327 133.889 10.5327Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default HeaderLogoIcon;
