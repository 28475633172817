import { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./user-info-dialog.styles";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { UserDetailsDto } from "@shared/models/users/user-details.dto";
import { t } from "@lingui/macro";
import { StyledButton } from "@shared/components/buttons/styled-button";
import { UserStatus } from "@shared/enum/user-status.enum";
import { Role } from "@shared/enum/role.enum";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Version } from "@shared/enum/version.enum";
import { UpdateUserPermissionRequestDto } from "@shared/dto/admin/update-user-permission-request.dto";

export interface UserInfoDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  user: UserDetailsDto;
  onApproveUser: (id: string) => void;
  onUnblockUser: (id: string) => void;
  onBlockUser: (id: string) => void;
  onAddAdminRole: (id: string) => void;
  onRemoveAdminRole: (id: string) => void;
  onUpdatePermission: (
    data: { id: string } & UpdateUserPermissionRequestDto,
  ) => void;
}

const UserInfoDialog: FC<UserInfoDialogProps> = ({
  classes,
  isOpen,
  onClose,
  user,
  onApproveUser,
  onUnblockUser,
  onBlockUser,
  onAddAdminRole,
  onRemoveAdminRole,
  onUpdatePermission,
}: UserInfoDialogProps) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t`User info`}</DialogTitle>
      <DialogContent
        dividers
        className={classes.root}
        sx={{
          borderTop: "1px solid #fff",
          borderBottom: "1px solid #fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Avatar
              alt={`${user.firstName} ${user.lastName}`}
              src={user.avatarUrl}
              sx={{ width: 120, height: 120 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t`ID`}: {user.id}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t`Status`}: {user.status}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t`Role`}: {user.role}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t`Created`}: {new Date(user.createdAt).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "16px" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              {t`Country`}: {user.country}
            </Typography>
            <Typography variant="body2">
              {t`City`}: {user.city}
            </Typography>
            <Typography variant="body2">
              {t`Organization`}: {user.organization}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              {t`Dealership center`}: {user.dealershipCenter}
            </Typography>
            <Typography variant="body2">
              {t`Phone number`}: {user.phoneNumber}
            </Typography>
            <Typography variant="body2">
              {t`Email`}: {user.email}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.NEW ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"success"}
              label={t`Approve`}
              onClick={() => onApproveUser(user.id)}
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.BLOCKED ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"success"}
              label={t`Unblock`}
              onClick={() => onUnblockUser(user.id)}
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          [UserStatus.VERIFIED, UserStatus.NEW].includes(
            user.status as UserStatus,
          ) ? (
            <StyledButton
              color={"secondary"}
              label={t`Block`}
              onClick={() => onBlockUser(user.id)}
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.ADMIN ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"secondary"}
              label={t`Remove admin role`}
              onClick={() => onRemoveAdminRole(user.id)}
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.USER ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"success"}
              label={t`Add admin role`}
              onClick={() => onAddAdminRole(user.id)}
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.USER &&
          !user.permissions.includes(Version.V1) ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"success"}
              label={t`Authorize ${Version.V1}`}
              onClick={() =>
                onUpdatePermission({
                  id: user.id,
                  permission: Version.V1,
                  isAdding: true,
                })
              }
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.USER &&
          !user.permissions.includes(Version.V2) ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"success"}
              label={t`Authorize ${Version.V2}`}
              onClick={() =>
                onUpdatePermission({
                  id: user.id,
                  permission: Version.V2,
                  isAdding: true,
                })
              }
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.USER &&
          user.permissions.includes(Version.V1) ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"secondary"}
              label={t`Revoke authorization ${Version.V1}`}
              onClick={() =>
                onUpdatePermission({
                  id: user.id,
                  permission: Version.V1,
                  isAdding: false,
                })
              }
              variant={"contained"}
            />
          ) : (
            <></>
          )}
          {authService.user?.asJson.id !== user.id &&
          user.status === UserStatus.VERIFIED &&
          user.role === Role.USER &&
          user.permissions.includes(Version.V2) ? (
            <StyledButton
              sx={{ color: "#fff" }}
              color={"secondary"}
              label={t`Revoke authorization ${Version.V2}`}
              onClick={() =>
                onUpdatePermission({
                  id: user.id,
                  permission: Version.V2,
                  isAdding: false,
                })
              }
              variant={"contained"}
            />
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <StyledButton
          color={"inherit"}
          label={t`Close`}
          onClick={onClose}
          variant={"text"}
        />
      </DialogActions>
    </Dialog>
  );
};

export default appWithStyles(styles)(UserInfoDialog);
