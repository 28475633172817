import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { appInject } from "@core/di/utils";
import { UseMutationResult } from "react-query";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { IOnboardingForm } from "@ui/onboarding/components/onboarding-form/onboarding-form.validator";

export class OnboardingViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  private _mutation: UseMutationResult<void, unknown, IOnboardingForm, unknown>;
  private _error: string = "";

  constructor(params: {
    mutation: UseMutationResult<void, unknown, IOnboardingForm, unknown>;
  }) {
    this._mutation = params.mutation;
    appMakeObservable(this, {
      _error: appObservable,
    });
  }

  get error() {
    return this._error;
  }

  setError(error: string) {
    this._error = error;
  }

  getMyUserDetails() {
    return this.usersService.getMyUserDetails();
  }

  async update(data: IOnboardingForm) {
    await this.usersService.updateMyUserDetails({
      firstName: this.usersService.me?.asJson.firstName || "",
      lastName: this.usersService.me?.asJson.lastName || "",
      phoneNumber: this.usersService.me?.asJson.phoneNumber || "",
      country: data.country || "",
      city: data.city || "",
      organization: data.organization || "",
      dealershipCenter: data.dealershipCenter || "",
      email: data.email || "",
    });
  }
}
