import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    title: {
      fontWeight: 400,
      fontSize: "30px",
      lineHeight: "36.31px",
      marginBottom: "10px",
    },
  });
}
