import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    form: {
      height: "65vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        height: "270px",
      },
      "& .MuiFormControl-root + .MuiFormControl-root": {
        marginTop: "20px",
      },
    },
  });
}
