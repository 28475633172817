import React, { FC } from "react";

import { styles } from "./country-list-item.styles";
import { appWithStyles, AppWithStyles } from "@shared/material";
import { MenuItem, MenuItemProps } from "@mui/material";
import { Flag } from "@shared/components/input-phone/components/flag";

export type CountryListItemProps = AppWithStyles<typeof styles> &
  MenuItemProps & {
    name: string;
    iso2: string;
    dialCode: string;
    localization: string;
    onSelectItem: (iso2: string, code: string) => void;
  };

const CountryListItem: FC<CountryListItemProps> = ({
  classes,
  name,
  iso2,
  dialCode,
  localization,
  onSelectItem,
  ...restProps
}) => {
  return (
    <MenuItem
      className={classes.country}
      data-dial-code="1"
      data-country-code={iso2}
      onClick={() => onSelectItem(iso2, dialCode)}
      {...restProps}
    >
      <Flag code={iso2} key={iso2} />

      <span className={classes.countryName}>{localization || name}</span>

      <span className={classes.dialCode}>{`+${dialCode}`}</span>
    </MenuItem>
  );
};

export default appWithStyles(styles)(CountryListItem);
