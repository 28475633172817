import React from "react";

import Acura from "@shared/images/brands/brand=acura.svg";
import AlfaRomeo from "@shared/images/brands/brand=alfa_romeo.svg";
import AstonMartin from "@shared/images/brands/brand=aston_martin.svg";
import Audi from "@shared/images/brands/brand=audi.svg";
import Bentley from "@shared/images/brands/brand=bentley.svg";
import Bmw from "@shared/images/brands/brand=bmw.svg";
import Brilliance from "@shared/images/brands/brand=brilliance.svg";
import Buick from "@shared/images/brands/brand=buick.svg";
import Byd from "@shared/images/brands/brand=byd.svg";
import Cadilac from "@shared/images/brands/brand=cadilac.svg";
import Changan from "@shared/images/brands/brand=changan.svg";
import Chery from "@shared/images/brands/brand=chery.svg";
import Chevrolet from "@shared/images/brands/brand=chevrolet.svg";
import Chrysler from "@shared/images/brands/brand=chrysler.svg";
import Citroen from "@shared/images/brands/brand=citroen.svg";
import Cupra from "@shared/images/brands/brand=cupra.svg";
import Dacia from "@shared/images/brands/brand=dacia.svg";
import Daihatsu from "@shared/images/brands/brand=daihatsu.svg";
import Datsun from "@shared/images/brands/brand=datsun.svg";
import Dfm from "@shared/images/brands/brand=dfm.svg";
import Dodge from "@shared/images/brands/brand=dodge.svg";
import Evolute from "@shared/images/brands/brand=evolute.svg";
import Exeed from "@shared/images/brands/brand=exeed.svg";
import Faw from "@shared/images/brands/brand=faw.svg";
import Ferrari from "@shared/images/brands/brand=ferrari.svg";
import Fiat from "@shared/images/brands/brand=fiat.svg";
import Ford from "@shared/images/brands/brand=ford.svg";
import Forthing from "@shared/images/brands/brand=forthing.svg";
import Foton from "@shared/images/brands/brand=foton.svg";
import GacMotor from "@shared/images/brands/brand=gac_motor.svg";
import Gac from "@shared/images/brands/brand=gac.svg";
import Gaz from "@shared/images/brands/brand=gaz.svg";
import Geely from "@shared/images/brands/brand=geely.svg";
import Genesis from "@shared/images/brands/brand=genesis.svg";
import Gmc from "@shared/images/brands/brand=gmc.svg";
import Gwm from "@shared/images/brands/brand=gwm.svg";
import Halma from "@shared/images/brands/brand=halma.svg";
import HarleyDevidson from "@shared/images/brands/brand=harley_devidson.svg";
import Haval from "@shared/images/brands/brand=haval.svg";
import Hawtai from "@shared/images/brands/brand=hawtai.svg";
import Honda from "@shared/images/brands/brand=honda.svg";
import Hongqi from "@shared/images/brands/brand=hongqi.svg";
import Hower from "@shared/images/brands/brand=hower.svg";
import Hummer from "@shared/images/brands/brand=hummer.svg";
import Hyundai from "@shared/images/brands/brand=hyundai.svg";
import Ikco from "@shared/images/brands/brand=ikco.svg";
import Infinity from "@shared/images/brands/brand=infinity.svg";
import Isuzu from "@shared/images/brands/brand=isuzu.svg";
import Iveco from "@shared/images/brands/brand=iveco.svg";
import Jaecoo from "@shared/images/brands/brand=jaecoo.svg";
import Jaguar from "@shared/images/brands/brand=jaguar.svg";
import Jeep from "@shared/images/brands/brand=jeep.svg";
import Jetour from "@shared/images/brands/brand=jetour.svg";
import Jetta from "@shared/images/brands/brand=jetta.svg";
import Kaiyi from "@shared/images/brands/brand=kaiyi.svg";
import Kamaz from "@shared/images/brands/brand=kamaz.svg";
import Kia from "@shared/images/brands/brand=kia.svg";
import Lada from "@shared/images/brands/brand=lada.svg";
import Lamborghini from "@shared/images/brands/brand=lamborghini.svg";
import LandRover from "@shared/images/brands/brand=land_rover.svg";
import Lexus from "@shared/images/brands/brand=lexus.svg";
import Lifan from "@shared/images/brands/brand=lifan.svg";
import Lincoln from "@shared/images/brands/brand=lincoln.svg";
import Livan from "@shared/images/brands/brand=livan.svg";
import Lotus from "@shared/images/brands/brand=lotus.svg";
import Luxgen from "@shared/images/brands/brand=luxgen.svg";
import Lynkco from "@shared/images/brands/brand=lynk&co.svg";
import Man from "@shared/images/brands/brand=man.svg";
import Maserati from "@shared/images/brands/brand=maserati.svg";
import Mazda from "@shared/images/brands/brand=mazda.svg";
import Maz from "@shared/images/brands/brand=maz.svg";
import Mclaren from "@shared/images/brands/brand=mclaren.svg";
import Mercedes from "@shared/images/brands/brand=mercedes.svg";
import Mg from "@shared/images/brands/brand=mg.svg";
import Mini from "@shared/images/brands/brand=mini.svg";
import Mitsubishi from "@shared/images/brands/brand=mitsubishi.svg";
import Moskvich from "@shared/images/brands/brand=moskvich.svg";
import Neta from "@shared/images/brands/brand=neta.svg";
import Nissan from "@shared/images/brands/brand=nissan.svg";
import Omoda from "@shared/images/brands/brand=omoda.svg";
import Opel from "@shared/images/brands/brand=opel.svg";
import Ora from "@shared/images/brands/brand=ora.svg";
import Oshan from "@shared/images/brands/brand=oshan.svg";
import Oting from "@shared/images/brands/brand=oting.svg";
import Peugeot from "@shared/images/brands/brand=peugeot.svg";
import Porsche from "@shared/images/brands/brand=porsche.svg";
import Ravon from "@shared/images/brands/brand=ravon.svg";
import RenaultSamsung from "@shared/images/brands/brand=renault_samsung.svg";
import Renault from "@shared/images/brands/brand=renault.svg";
import RollsRoyce from "@shared/images/brands/brand=rolls_royce.svg";
import Scania from "@shared/images/brands/brand=scania.svg";
import Seat from "@shared/images/brands/brand=seat.svg";
import Shacman from "@shared/images/brands/brand=shacman.svg";
import Skoda from "@shared/images/brands/brand=skoda.svg";
import Skywell from "@shared/images/brands/brand=skywell.svg";
import Smart from "@shared/images/brands/brand=smart.svg";
import Sollers from "@shared/images/brands/brand=sollers.svg";
import Soueast from "@shared/images/brands/brand=soueast.svg";
import Ssangyong from "@shared/images/brands/brand=ssangyong.svg";
import Subaru from "@shared/images/brands/brand=subaru.svg";
import Suzuki from "@shared/images/brands/brand=suzuki.svg";
import Swm from "@shared/images/brands/brand=swm.svg";
import Tank from "@shared/images/brands/brand=tank.svg";
import Tesla from "@shared/images/brands/brand=tesla.svg";
import Toyota from "@shared/images/brands/brand=toyota.svg";
import Uaz from "@shared/images/brands/brand=uaz.svg";
import Ural from "@shared/images/brands/brand=ural.svg";
import Volkswagen from "@shared/images/brands/brand=volkswagen.svg";
import Volvo from "@shared/images/brands/brand=volvo.svg";
import Voyah from "@shared/images/brands/brand=voyah.svg";
import Wey from "@shared/images/brands/brand=wey.svg";
import Zeekr from "@shared/images/brands/brand=zeekr.svg";
import Zotye from "@shared/images/brands/brand=zotye.svg";

const IconsMap = new Map();
IconsMap.set("Acura", <img src={Acura} alt={""} />);
IconsMap.set("Alfa Romeo", <img src={AlfaRomeo} alt={""} />);
IconsMap.set("Aston Martin", <img src={AstonMartin} alt={""} />);
IconsMap.set("Audi", <img src={Audi} alt={""} />);
IconsMap.set("Bentley", <img src={Bentley} alt={""} />);
IconsMap.set("BMW", <img src={Bmw} alt={""} />);
IconsMap.set("Brilliance", <img src={Brilliance} alt={""} />);
IconsMap.set("Buick", <img src={Buick} alt={""} />);
IconsMap.set("BYD", <img src={Byd} alt={""} />);
IconsMap.set("Cadillac", <img src={Cadilac} alt={""} />);
IconsMap.set("Changan", <img src={Changan} alt={""} />);
IconsMap.set("Chery", <img src={Chery} alt={""} />);
IconsMap.set("Chevrolet", <img src={Chevrolet} alt={""} />);
IconsMap.set("Chrysler", <img src={Chrysler} alt={""} />);
IconsMap.set("Citroen", <img src={Citroen} alt={""} />);
IconsMap.set("Cupra", <img src={Cupra} alt={""} />);
IconsMap.set("Dacia", <img src={Dacia} alt={""} />);
IconsMap.set("Daihatsu", <img src={Daihatsu} alt={""} />);
IconsMap.set("Datsun", <img src={Datsun} alt={""} />);
IconsMap.set("Dongfeng", <img src={Dfm} alt={""} />);
IconsMap.set("Dodge", <img src={Dodge} alt={""} />);
IconsMap.set("Evolute", <img src={Evolute} alt={""} />);
IconsMap.set("Exeed", <img src={Exeed} alt={""} />);
IconsMap.set("FAW", <img src={Faw} alt={""} />);
IconsMap.set("Ferrari", <img src={Ferrari} alt={""} />);
IconsMap.set("Fiat", <img src={Fiat} alt={""} />);
IconsMap.set("Ford", <img src={Ford} alt={""} />);
IconsMap.set("Forthing", <img src={Forthing} alt={""} />);
IconsMap.set("Foton", <img src={Foton} alt={""} />);
IconsMap.set("GacMotor", <img src={GacMotor} alt={""} />);
IconsMap.set("JAC", <img src={Gac} alt={""} />);
IconsMap.set("Gaz", <img src={Gaz} alt={""} />);
IconsMap.set("Geely", <img src={Geely} alt={""} />);
IconsMap.set("Genesis", <img src={Genesis} alt={""} />);
IconsMap.set("GMC", <img src={Gmc} alt={""} />);
IconsMap.set("GWM", <img src={Gwm} alt={""} />);
IconsMap.set("Haima", <img src={Halma} alt={""} />);
IconsMap.set("Harley Davidson", <img src={HarleyDevidson} alt={""} />);
IconsMap.set("Haval", <img src={Haval} alt={""} />);
IconsMap.set("Hawtai", <img src={Hawtai} alt={""} />);
IconsMap.set("Honda", <img src={Honda} alt={""} />);
IconsMap.set("Hongqi", <img src={Hongqi} alt={""} />);
IconsMap.set("Hower", <img src={Hower} alt={""} />);
IconsMap.set("Hummer", <img src={Hummer} alt={""} />);
IconsMap.set("Hyundai", <img src={Hyundai} alt={""} />);
IconsMap.set("Ikco", <img src={Ikco} alt={""} />);
IconsMap.set("Infiniti", <img src={Infinity} alt={""} />);
IconsMap.set("Isuzu", <img src={Isuzu} alt={""} />);
IconsMap.set("Iveco", <img src={Iveco} alt={""} />);
IconsMap.set("Jaecoo", <img src={Jaecoo} alt={""} />);
IconsMap.set("Jaguar", <img src={Jaguar} alt={""} />);
IconsMap.set("Jeep", <img src={Jeep} alt={""} />);
IconsMap.set("Jetour", <img src={Jetour} alt={""} />);
IconsMap.set("Jetta", <img src={Jetta} alt={""} />);
IconsMap.set("Kaiyi", <img src={Kaiyi} alt={""} />);
IconsMap.set("Kamaz", <img src={Kamaz} alt={""} />);
IconsMap.set("Kia", <img src={Kia} alt={""} />);
IconsMap.set("Lada", <img src={Lada} alt={""} />);
IconsMap.set("Lamborghini", <img src={Lamborghini} alt={""} />);
IconsMap.set("Land Rover", <img src={LandRover} alt={""} />);
IconsMap.set("Lexus", <img src={Lexus} alt={""} />);
IconsMap.set("Lifan", <img src={Lifan} alt={""} />);
IconsMap.set("Lincoln", <img src={Lincoln} alt={""} />);
IconsMap.set("Livan", <img src={Livan} alt={""} />);
IconsMap.set("Lotus", <img src={Lotus} alt={""} />);
IconsMap.set("Luxgen", <img src={Luxgen} alt={""} />);
IconsMap.set("Lynk&co", <img src={Lynkco} alt={""} />);
IconsMap.set("MAN", <img src={Man} alt={""} />);
IconsMap.set("Maserati", <img src={Maserati} alt={""} />);
IconsMap.set("Mazda", <img src={Mazda} alt={""} />);
IconsMap.set("Maz", <img src={Maz} alt={""} />);
IconsMap.set("Mclaren", <img src={Mclaren} alt={""} />);
IconsMap.set("Mercedes-benz", <img src={Mercedes} alt={""} />);
IconsMap.set("MG", <img src={Mg} alt={""} />);
IconsMap.set("Mini", <img src={Mini} alt={""} />);
IconsMap.set("Mitsubishi", <img src={Mitsubishi} alt={""} />);
IconsMap.set("Moskvich", <img src={Moskvich} alt={""} />);
IconsMap.set("Neta", <img src={Neta} alt={""} />);
IconsMap.set("Nissan", <img src={Nissan} alt={""} />);
IconsMap.set("Omoda", <img src={Omoda} alt={""} />);
IconsMap.set("Opel", <img src={Opel} alt={""} />);
IconsMap.set("ORA", <img src={Ora} alt={""} />);
IconsMap.set("Oshan", <img src={Oshan} alt={""} />);
IconsMap.set("Oting", <img src={Oting} alt={""} />);
IconsMap.set("Peugeot", <img src={Peugeot} alt={""} />);
IconsMap.set("Porsche", <img src={Porsche} alt={""} />);
IconsMap.set("Ravon", <img src={Ravon} alt={""} />);
IconsMap.set("Renault Samsung", <img src={RenaultSamsung} alt={""} />);
IconsMap.set("Renault", <img src={Renault} alt={""} />);
IconsMap.set("Rolls-royce", <img src={RollsRoyce} alt={""} />);
IconsMap.set("Scania", <img src={Scania} alt={""} />);
IconsMap.set("Seat", <img src={Seat} alt={""} />);
IconsMap.set("Shacman", <img src={Shacman} alt={""} />);
IconsMap.set("Skoda", <img src={Skoda} alt={""} />);
IconsMap.set("Skywell", <img src={Skywell} alt={""} />);
IconsMap.set("Smart", <img src={Smart} alt={""} />);
IconsMap.set("Sollers", <img src={Sollers} alt={""} />);
IconsMap.set("Soueast", <img src={Soueast} alt={""} />);
IconsMap.set("Ssangyong", <img src={Ssangyong} alt={""} />);
IconsMap.set("Subaru", <img src={Subaru} alt={""} />);
IconsMap.set("Suzuki", <img src={Suzuki} alt={""} />);
IconsMap.set("SWM", <img src={Swm} alt={""} />);
IconsMap.set("Tank", <img src={Tank} alt={""} />);
IconsMap.set("Tesla", <img src={Tesla} alt={""} />);
IconsMap.set("Toyota", <img src={Toyota} alt={""} />);
IconsMap.set("Uaz", <img src={Uaz} alt={""} />);
IconsMap.set("Ural", <img src={Ural} alt={""} />);
IconsMap.set("Volkswagen", <img src={Volkswagen} alt={""} />);
IconsMap.set("Volvo", <img src={Volvo} alt={""} />);
IconsMap.set("Voyah", <img src={Voyah} alt={""} />);
IconsMap.set("Wey", <img src={Wey} alt={""} />);
IconsMap.set("Zeekr", <img src={Zeekr} alt={""} />);
IconsMap.set("Zotye", <img src={Zotye} alt={""} />);

export default IconsMap;
