import React, { FC, ReactNode, useEffect, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./gazer-metasmart-car-layout.styles";
import { WelcomeScreenLogoIcon } from "@shared/components/welcome-screen-logo";
import { Box } from "@mui/material";
import { PublicLocales } from "@shared/components/locales/public-locales";
import { appObserver } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import Light from "@shared/images/light.png";

export interface WelcomeScreenProps extends AppWithStyles<typeof styles> {
  children: ReactNode;
}

const WelcomeScreen: FC<WelcomeScreenProps> = appObserver(
  ({ classes, children }: WelcomeScreenProps) => {
    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
      const handleResize = () => {
        setHeight(window.innerHeight);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    console.log(height);
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url(${Light})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 30%",
          backgroundPositionY: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "334px",
            maxWidth: "none",
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: height > 800 ? "50%" : "40%",
              transform: "translate(0, -100%)",
            }}
          >
            <WelcomeScreenLogoIcon />
          </Box>
          <Box>{children}</Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
              top: "10px",
              right: "135px",
            },
          }}
        >
          <PublicLocales
            locale={localesViewModel.locale}
            onChange={(local) => localesViewModel.setLocale(local)}
          />
        </Box>
      </Box>
    );
  },
);

export default appWithStyles(styles)(WelcomeScreen);
