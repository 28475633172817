import { IsNotEmpty, MaxLength, MinLength } from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

export interface INameForm {
  firstName?: string;
  lastName?: string;
}

export class NameFormFields implements INameForm {
  @MinLength(3)
  @MaxLength(100)
  @IsNotEmpty()
  firstName?: string;

  @MinLength(3)
  @MaxLength(100)
  @IsNotEmpty()
  lastName?: string;
}

export const NameFormFieldsResolver = classValidatorResolver(NameFormFields);
