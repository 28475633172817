import { BaseModel } from "@core/models/base-model";

export type PaginationResponseDto<T> = {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
  data: Array<T>;
};

export class PaginationResponseModel<T> extends BaseModel<
  PaginationResponseDto<T>
> {
  private pageNumber?: number;
  private pageSize?: number;
  private totalCount?: number;
  private totalPages?: number;
  private hasNext?: boolean;
  private hasPrevious?: boolean;
  private data?: Array<T>;

  constructor(data: PaginationResponseDto<T>) {
    super();

    this.update(data);
  }

  get asJson(): PaginationResponseDto<T> {
    return {
      pageNumber: this.pageNumber || 0,
      pageSize: this.pageSize || 0,
      totalCount: this.totalCount || 0,
      totalPages: this.totalPages || 0,
      hasNext: this.hasNext || false,
      hasPrevious: this.hasPrevious || false,
      data: this.data || [],
    };
  }
}
