import React, { FC } from "react";
import { AppWithStyles, appWithStyles, List } from "@shared/material";
import { styles } from "./navbar.styles";
import { NavLink } from "@shared/router";
import { Box } from "@mui/material";
import { NavItem } from "@shared/types/nav-item";
import { t } from "@lingui/macro";
import { ROUTES } from "../../../routing/routes";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import classNames from "classnames";

export type NavbarProps = AppWithStyles<typeof styles>;

const Navbar: FC<NavbarProps> = ({ classes }: NavbarProps) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  const menuItems: Array<NavItem> = [
    { label: t`Home`, path: ROUTES.home },
    { label: t`Courses`, path: ROUTES.courses },
    { label: t`Catalog`, path: ROUTES.catalog },
  ];

  if (authService.isAdmin) {
    menuItems.push({
      label: t`Users management`,
      path: ROUTES.usersManagement,
    });
  }

  return (
    <List className={classes.root}>
      {menuItems.map((item, index) => (
        <NavLink
          to={item.path}
          key={index}
          className={({ isActive }) =>
            classNames(classes.link, { [classes.active]: isActive })
          }
        >
          <Box className={classes.text}>{item.label}</Box>
        </NavLink>
      ))}
    </List>
  );
};

export default appWithStyles(styles)(Navbar);
