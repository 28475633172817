import { testBlocks } from "@shared/resources/test-lessons";
import { BlockListModel } from "@shared/models/block-list.model";

export class BlockDetailsViewModel {
  //private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private _block: BlockListModel | null = null;

  get currentBlockTitle() {
    return this._block?.asJson.title || ";";
  }

  get currentBlock() {
    return this._block || null;
  }

  setBlockId(blockId: string): BlockDetailsViewModel {
    this._block =
      testBlocks.find((block) => (block.asJson.id = blockId)) || null;
    return this;
  }
}
