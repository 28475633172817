import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62304 11.4873C4 12.6255 4 13.9986 4 16.7447V18.57C4 23.251 4 25.5916 5.40588 27.0457C6.81178 28.5 9.07451 28.5 13.6 28.5H18.4C22.9254 28.5 25.1883 28.5 26.5941 27.0457C28 25.5916 28 23.251 28 18.57V16.7447C28 13.9986 28 12.6255 27.377 11.4873C26.7539 10.3491 25.6157 9.64261 23.3392 8.22974L20.9392 6.74024C18.5327 5.24675 17.3295 4.5 16 4.5C14.6705 4.5 13.4673 5.24675 11.0608 6.74024L8.66084 8.22976C6.38434 9.64261 5.24608 10.3491 4.62304 11.4873Z"
        fill="#E8EAED"
      />
      <path
        d="M10.236 21.9855C10.6307 21.453 11.3822 21.3414 11.9146 21.7361C13.08 22.5997 14.4866 23.1001 16 23.1001C17.5134 23.1001 18.92 22.5997 20.0854 21.7361C20.6179 21.3414 21.3694 21.453 21.7639 21.9855C22.1586 22.518 22.047 23.2695 21.5145 23.664C19.9587 24.8173 18.056 25.5 16 25.5C13.944 25.5 12.0413 24.8173 10.4855 23.664C9.95307 23.2695 9.84139 22.518 10.236 21.9855Z"
        fill="#D52037"
      />
    </SvgIcon>
  );
}

export default HomeIcon;
