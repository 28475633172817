import { FC, useState } from "react";

import { Box } from "@mui/material";
import { styles } from "./installer.styles";
import { appWithStyles, AppWithStyles } from "@shared/material";
import { t } from "@lingui/macro";
import { StyledButton } from "@shared/components/buttons/styled-button";
import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IInstallerViewModel } from "@shared/interfaces/installer-vm.interface";
import copy from "copy-to-clipboard";
import InstallerLoginIcon from "@shared/images/login";
import InstallerPassIcon from "@shared/images/pass";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";

export type InstallerProps = AppWithStyles<typeof styles>;

const Installer: FC<InstallerProps> = ({ classes }) => {
  const navigate = useNavigate();
  const installerVM = appInject<IInstallerViewModel>(
    DI_TOKENS.installerViewModel,
  );

  const [isVisibleCopyLogin, setIsVisibleCopyLogin] = useState<boolean>(false);
  const [isVisibleCopyPass, setIsVisibleCopyPass] = useState<boolean>(false);

  const handleCopyLogin = (text: string) => {
    setIsVisibleCopyLogin(true);
    copy(text);
    setTimeout(() => {
      setIsVisibleCopyLogin(false);
    }, 1000);
  };

  const handleCopyPass = (text: string) => {
    setIsVisibleCopyPass(true);
    copy(text);
    setTimeout(() => {
      setIsVisibleCopyPass(false);
    }, 1000);
  };

  const navigateToInstallerPage = () => {
    navigate(ROUTES.installer);
  };

  return (
    <Box sx={{ display: "flex", gap: "20px" }}>
      {installerVM.androidLink || installerVM.macosLink ? (
        <StyledButton
          sx={{ height: "40px" }}
          label={t`Installer`}
          color={"secondary"}
          onClick={navigateToInstallerPage}
        />
      ) : (
        <></>
      )}
      {installerVM.login && installerVM.pass ? (
        <Box sx={{ display: "flex", height: "40px", width: "200px" }}>
          <Box
            className={classes.copyButton}
            onClick={() => handleCopyLogin(installerVM.login)}
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "10px 0px 0px 10px",
              borderLeft: "1px solid #747B89",
              borderTop: "1px solid #747B89",
              borderBottom: "1px solid #747B89",
            }}
          >
            {isVisibleCopyLogin ? <></> : <InstallerLoginIcon />}
            <Box>{isVisibleCopyLogin ? t`Copied!` : t`Login`}</Box>
          </Box>
          <Box
            className={classes.copyButton}
            onClick={() => handleCopyPass(installerVM.pass)}
            sx={{
              borderRadius: "0px 10px 10px 0px",
              border: "1px solid #747B89",
            }}
          >
            {isVisibleCopyPass ? <></> : <InstallerPassIcon />}
            <Box>{isVisibleCopyPass ? t`Copied!` : t`Pass`}</Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default appWithStyles(styles)(Installer);
