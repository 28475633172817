export enum ResourceItem {
  DOWNLOAD_SW = 0,
  CAN_FUNCTIONS = 1,
  INSTALLATION_DIAGRAM = 2,
  CONNECTION_DIAGRAM = 3,
}

export interface ResourceSwitcherItem {
  label: string;
  id: ResourceItem;
  disabled?: boolean;
}
