import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";

export const styles = () =>
  appCreateStyles({
    copyButton: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16.8px",
      color: "#E8EAED",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      backgroundColor: baseTheme.palette.background.paper,
    },
  });
