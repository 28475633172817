import { BaseModel } from "@core/models/base-model";

export interface CarModificationListDto {
  id: string;
  type: string;
  years: string;
  imageUrl: string;
}

export class CarModificationListModel extends BaseModel<CarModificationListDto> {
  private id?: string;
  private type?: string;
  private years?: string;
  private imageUrl?: string;

  constructor(data: CarModificationListDto) {
    super();

    this.update(data);
  }

  get asJson(): CarModificationListDto {
    return {
      id: this.id || "",
      years: this.years || "",
      type: this.type || "",
      imageUrl: this.imageUrl || "",
    };
  }
}
