import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    title: {
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "43.2px",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        fontSize: "26px",
      },
    },
  });
}
