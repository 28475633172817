import { BaseModel } from "@core/models/base-model";
import { CourseListModel } from "@shared/models/course-list.model";

export interface BlockListDto {
  id: string;
  title: string;
  courses: Array<CourseListModel>;
}

export class BlockListModel extends BaseModel<BlockListDto> {
  private id?: string;
  private title?: string;
  private courses?: Array<CourseListModel>;

  constructor(data: BlockListDto) {
    super();

    this.update(data);
  }

  get asJson(): BlockListDto {
    return {
      id: this.id || "",
      title: this.title || "",
      courses: this.courses || [],
    };
  }
}
