import { BaseModel } from "@core/models/base-model";

export type InstallersCredentialsDto = {
  login: string;
  pass: string;
};

export class InstallersCredentialsModel extends BaseModel<InstallersCredentialsDto> {
  private login?: string;
  private pass?: string;

  constructor(data: InstallersCredentialsDto) {
    super();

    this.update(data);
  }

  get asJson(): InstallersCredentialsDto {
    return {
      login: this.login || "",
      pass: this.pass || "",
    };
  }
}
