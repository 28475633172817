import { BaseModel } from "@core/models/base-model";

export interface CarLinksDto {
  firmwareLink: string;
  name: string;
}

export class CarLinksModel extends BaseModel<CarLinksDto> {
  private firmwareLink?: string;
  private name?: string;

  constructor(data: CarLinksDto) {
    super();

    this.update(data);
  }

  get asJson(): CarLinksDto {
    return {
      firmwareLink: this.firmwareLink || "",
      name: this.name || "",
    };
  }
}
