import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export const styles = () =>
  appCreateStyles({
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        border: "1px solid white",
        borderRadius: "12px",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "18px",
        fontWeight: 700,
      },
      "& .MuiIconButton-root": {
        padding: "2px",
        display: "block",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
      "& .MuiInputAdornment-root": {
        height: "30px",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: "none",
        WebkitTextFillColor: baseTheme.palette.text.primary,
        transition: "background-color 5000s ease-in-out 0s",
      },
    },
    dropdown: {
      maxHeight: "600px",
      "& .MuiPaper-root::-webkit-scrollbar": {
        width: "12px",
      },
      "& .MuiPaper-root::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "& .MuiPaper-root::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: "1px",
        border: "none",
      },

      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        maxHeight: "400px",
      },
    },
    input: {
      position: "relative",
      width: "100%",
    },
    inputPhone: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },

      "& .MuiOutlinedInput-input": {
        paddingLeft: "6px",
      },
    },
    label: {
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
    boldLabel: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "26.63px",
      marginBottom: "10px",
    },
    error: {
      "& .MuiOutlinedInput-root": {
        border: `1px solid ${baseTheme.palette.secondary.main}`,
      },
    },
    errorText: {
      color: baseTheme.palette.secondary.main,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
    hidden: {
      visibility: "hidden",
      height: "0px",
      padding: "0px",
      marginBottom: "-4px",
    },
  });
