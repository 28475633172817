import React, { FC } from "react";
import { AppWithStyles, appWithStyles, FormControl } from "shared/material";
import { styles } from "./styled-text-field.styles";
import { TextField } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";
import classNames from "classnames";

export interface StyledTextFieldProps extends AppWithStyles<typeof styles> {
  resetValidation?: () => void;
  controls?: UseFormRegisterReturn<string>;
  label: string;
  boldLabel?: string;
  hasError?: boolean;
  error?: string;
  placeholder?: string;
}

const StyledSelect: FC<StyledTextFieldProps> = ({
  classes,
  label,
  controls,
  hasError,
  error,
  placeholder,
  boldLabel,
}: StyledTextFieldProps) => {
  return (
    <FormControl
      fullWidth
      className={classNames(classes.root, { [classes.error]: hasError })}
    >
      {label && !boldLabel && !hasError ? (
        <div className={classes.label}>{label}</div>
      ) : (
        <></>
      )}
      {boldLabel && !hasError ? (
        <div className={classes.boldLabel}>{boldLabel}</div>
      ) : (
        <></>
      )}
      {hasError ? <div className={classes.errorText}>{error}</div> : <></>}
      <TextField
        {...controls}
        variant="outlined"
        placeholder={placeholder || ""}
      />
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledSelect);
