import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./block.styles";
import { Course } from "@ui/private/courses/components/course-item";
import { BlockListModel } from "@shared/models/block-list.model";
import { StyledIconButton } from "@shared/components/buttons/styled-icon-button";
import { EditIcon } from "@shared/images/icons/edit-icon";
import { DeleteIcon } from "@shared/images/icons/delete-icon";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";

export interface BlockProps extends AppWithStyles<typeof styles> {
  block: BlockListModel;
}

const Block: FC<BlockProps> = ({ classes, block }: BlockProps) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  return (
    <Box sx={{ margin: "75px 0px", position: "relative" }}>
      {authService.isAdmin ? (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            position: "absolute",
            top: "0px",
            right: "10px",
          }}
        >
          <StyledIconButton
            icon={<EditIcon />}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          />
          <StyledIconButton
            icon={<DeleteIcon />}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            color={"secondary"}
          />
        </Box>
      ) : (
        <></>
      )}
      <div className={classes.title}>{block.asJson.title}</div>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "50px 20px" }}>
        {block.asJson.courses.map((course) => (
          <Course key={course.asJson.id} block={block} course={course} />
        ))}
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(Block);
