import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    contact: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiButton-root + .MuiButton-root": {
        marginTop: "15px",
      },
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        height: "57vh",
      },
    },
    contacts: {
      marginTop: "50px",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center",
    },
    dialog: {
      "& .MuiPaper-root": {
        borderRadius: "10px",
      },
    },
  });
}
