import { ROUTES } from "./routes";
import { appCreateBrowserRouter } from "@shared/router";
import { WelcomeScreen } from "ui/auth/welcome-screen";
import { Login } from "@ui/auth/login";
import { ContactScreen } from "@ui/auth/contact-screen";
import { Registration } from "@ui/auth/registration";
import { UnauthorizedUserGuard } from "@shared/components/guards/unauthorized-user-guard";
import { AuthorizedUserGuard } from "@shared/components/guards/authorized-user-guard";
import { HomeScreen } from "@ui/private/home";
import { PrivateLayoutWithFooter } from "@shared/components/layouts/private-layout-with-footer";
import { Courses } from "@ui/private/courses/courses-list";
import App from "@ui/app/app";
import { CourseDetails } from "@ui/private/courses/course-details";
import BlockDetails from "@ui/private/courses/block-details/block-details";
import { Catalog } from "ui/private/catalog";
import { PrivateLayout } from "@shared/components/layouts/private-layout";
import { Brands } from "@ui/private/brands";
import { Models } from "@ui/private/models";
import { Modifications } from "@ui/private/modifications";
import { Profile } from "@ui/private/profile";
import { AdminGuard } from "@shared/components/guards/admin-guard";
import { UsersManagement } from "@ui/admin/users-management";
import { Onboarding } from "@ui/onboarding";
import { PermissionScreen } from "@ui/auth/permission-screen";
import { ErrorScreen } from "@ui/error-screen";
import { NotFoundScreen } from "@ui/not-found-screen";
import { InstallerPage } from "@ui/private/installer-page";

export const router = appCreateBrowserRouter([
  {
    path: ROUTES.initial,
    element: <App />,
    errorElement: <NotFoundScreen />,
    children: [
      {
        path: ROUTES.welcomeScreen,
        element: (
          <UnauthorizedUserGuard>
            <WelcomeScreen />
          </UnauthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.contactScreen,
        element: <ContactScreen />,
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.permissionScreen,
        element: <PermissionScreen />,
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.login,
        element: (
          <UnauthorizedUserGuard>
            <Login />
          </UnauthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.registration,
        element: (
          <UnauthorizedUserGuard>
            <Registration />
          </UnauthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.onboardingScreen,
        element: (
          <AuthorizedUserGuard>
            <Onboarding />
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.home,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <HomeScreen />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.installer,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <InstallerPage />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.courses,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Courses />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.blockDetails(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <BlockDetails />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.courseDetails(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <CourseDetails />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.catalog,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Catalog />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.brands(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Brands />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.models(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Models />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.modifications(),
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Modifications />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.profile,
        element: (
          <AuthorizedUserGuard>
            <PrivateLayoutWithFooter>
              <Profile />
            </PrivateLayoutWithFooter>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
      {
        path: ROUTES.usersManagement,
        element: (
          <AuthorizedUserGuard>
            <AdminGuard>
              <PrivateLayout>
                <UsersManagement />
              </PrivateLayout>
            </AdminGuard>
          </AuthorizedUserGuard>
        ),
        errorElement: <ErrorScreen />,
      },
    ],
  },
]);
