import React, { ChangeEvent, FC, useState } from "react";
import { AppWithStyles, appWithStyles, FormControl } from "shared/material";
import { styles } from "./styled-input-file.styles";
import { Box, Button, IconButton, Input } from "@mui/material";
import { t } from "@lingui/macro";
import { FiX } from "react-icons/fi";

export interface StyledInputFileProps extends AppWithStyles<typeof styles> {
  onFile: (file: File | null) => void;
  label?: string;
  boldLabel?: string;
  hasError?: boolean;
  error?: string;
  placeholder?: string;
}

const StyledInputFile: FC<StyledInputFileProps> = ({
  classes,
  label,
  onFile,
  hasError,
  error,
  boldLabel,
}: StyledInputFileProps) => {
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<string>(error || "");
  const [hasErrors, setHasErrors] = useState<boolean>(hasError || false);

  const onReset = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setFile(null);
    onFile(null);
    setFileName("");
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(file);
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];

      if (file.size > 1024 * 1024) {
        setHasErrors(true);
        setFileName("");
        setErrors(t`File is too large. Max size is ${1} MB`);
        setFile(null);
        return;
      }

      setFileName(file?.name || "");
      onFile(file);
      setHasErrors(false);
    }
  };

  return (
    <FormControl fullWidth className={classes.root}>
      {label && !boldLabel && !hasErrors ? (
        <div className={classes.label}>{label}</div>
      ) : (
        <></>
      )}
      {boldLabel && !hasErrors ? (
        <div className={classes.boldLabel}>{boldLabel}</div>
      ) : (
        <></>
      )}
      {hasErrors ? <div className={classes.errorText}>{errors}</div> : <></>}
      <Input
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
        value={file}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <Button
          sx={{
            width: "100%",
            maxWidth: "none",
            backgroundColor: "transparent",
            border: "1px solid #fff",
            color: "#fff",
            justifyContent: "left",
            "&:hover": {
              border: "1px solid #fff",
            },
          }}
          variant="outlined"
          component="span"
        >
          {fileName ? (
            <IconButton
              sx={{ position: "absolute", right: "10px" }}
              onClick={onReset}
              color={"secondary"}
            >
              <FiX />
            </IconButton>
          ) : (
            <></>
          )}
          <Box
            component={"span"}
            sx={{
              width: "100%",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              textAlign: "start",
            }}
          >
            {fileName ? fileName : t`Upload`}
          </Box>
        </Button>
      </label>
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledInputFile);
