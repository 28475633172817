import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./onboarding.styles";
import { AuthLayout } from "@shared/components/layouts/auth-layout";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { Loader } from "@shared/components/loader";
import { useMutation, useQuery } from "react-query";
import { UseMutationResult } from "react-query/types/react/types";
import { useNavigate } from "react-router-dom";
import { OnboardingViewModel } from "@ui/onboarding/onboarding.vm";
import { IOnboardingForm } from "@ui/onboarding/components/onboarding-form/onboarding-form.validator";
import { OnboardingForm } from "./components/onboarding-form";
import { ROUTES } from "../../routing/routes";

export type OnboardingProps = AppWithStyles<typeof styles>;

const Onboarding: FC<OnboardingProps> = appObserver(
  ({ classes }: OnboardingProps) => {
    const navigate = useNavigate();
    const onboardingMutation: UseMutationResult<
      void,
      unknown,
      IOnboardingForm,
      unknown
    > = useMutation(
      (data: IOnboardingForm): Promise<void> => $vm.update(data),
      {
        onSuccess: () => {
          navigate(ROUTES.home);
        },
      },
    );
    const myUserDetailsQuery = useQuery(
      ["download-my-user-details"],
      () => $vm.getMyUserDetails(),
      {
        refetchOnMount: true,
        onSuccess: (data) => {
          if (
            data.asJson.country &&
            data.asJson.city &&
            data.asJson.organization &&
            data.asJson.dealershipCenter &&
            data.asJson.email
          ) {
            navigate(ROUTES.home);
          }
        },
      },
    );

    const $vm = useMemo(
      () => new OnboardingViewModel({ mutation: onboardingMutation }),
      [],
    );

    const [_, render] = useState(0);
    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );
    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    const loader = (
      <Box
        className={classes.root}
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

    let activeForm = (
      <OnboardingForm
        onSubmit={(data: IOnboardingForm) => onboardingMutation.mutate(data)}
      />
    );

    if (onboardingMutation.isLoading || myUserDetailsQuery.isFetching) {
      activeForm = loader;
    }

    return <AuthLayout>{activeForm}</AuthLayout>;
  },
);

export default appWithStyles(styles)(Onboarding);
