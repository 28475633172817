import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import RedBlurBackground from "@shared/images/red-blur2.png";

export function styles() {
  return appCreateStyles({
    item: {
      width: "100%",
      height: "43vh",
      borderRadius: "2vh",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionX: "0px",
      backgroundPositionY: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        //width: "27vw",
        //height: "30vw",
        width: "100%",
        height: "60vh",
        borderRadius: "1vw",
        backgroundSize: "contain",
        backgroundPosition: "50% 30%",
      },
    },
    red: {
      border: `1px solid ${baseTheme.palette.secondary.main}`,
      backgroundImage: `url(${RedBlurBackground})`,
    },
    label: {
      position: "absolute",
      width: "270px",
      transform: "translate(-50%, 0)",
      left: "50%",
      top: "-21px",
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "33.6px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      backgroundColor: baseTheme.palette.background.default,
    },
    description: {
      position: "absolute",
      width: "245px",
      textAlign: "center",
      transform: "translate(-50%, 0)",
      left: "50%",
      top: "35px",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      backgroundColor: "transparent",
      color: "#E8EAED",
    },
    image: {
      width: "35vh",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        width: "60vh",
      },
    },
    screen: {
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        width: "100%",
      },
    },
  });
}
