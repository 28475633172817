import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./navigation.styles";
import { Box } from "@mui/material";
import { CoursesIcon, CatalogIcon } from "@shared/images/footer-nav-icons";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../routing/routes";

export interface NavigationProps extends AppWithStyles<typeof styles> {}

const Navigation: FC<NavigationProps> = ({ classes }: NavigationProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
      }}
    >
      <Box
        className={classes.menuItem}
        onClick={() => navigate(ROUTES.courses)}
      >
        <CoursesIcon />
        <div className={classes.label}>{t`Courses`}</div>
      </Box>
      <Box
        className={classes.menuItem}
        onClick={() => navigate(ROUTES.catalog)}
      >
        <CatalogIcon />
        <div className={classes.label}>{t`Catalog`}</div>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(Navigation);

/*
* <Box className={classes.menuItem} onClick={() => navigate(ROUTES.home)}>
        <HistoryIcon />
        <div className={classes.label}>{t`History`}</div>
      </Box>*/
