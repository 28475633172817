import React, { FC, ReactNode } from "react";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../routing/routes";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { UserStatus } from "@shared/enum/user-status.enum";
import { Version } from "@shared/enum/version.enum";

export interface AuthorizedUserGuardProps {
  children: ReactNode;
}

const AuthorizedUserGuard: FC<AuthorizedUserGuardProps> = ({ children }) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  const location = useLocation();

  if (location.pathname === ROUTES.onboardingScreen && authService.isLoggedIn) {
    return <>{children}</>;
  }

  if (location.pathname === ROUTES.permissionScreen && authService.isLoggedIn) {
    return <>{children}</>;
  }

  if (
    location.pathname === ROUTES.contactScreen &&
    authService.isLoggedIn &&
    usersService.me?.asJson.country &&
    usersService.me?.asJson.city &&
    usersService.me?.asJson.organization &&
    usersService.me?.asJson.dealershipCenter &&
    usersService.me?.asJson.email
  ) {
    return <>{children}</>;
  }

  if (!authService.isLoggedIn) {
    return <Navigate to={ROUTES.login} />;
  } else if (
    !usersService.me?.asJson.country ||
    !usersService.me?.asJson.city ||
    !usersService.me?.asJson.organization ||
    !usersService.me?.asJson.dealershipCenter ||
    !usersService.me?.asJson.email
  ) {
    return <Navigate to={ROUTES.onboardingScreen} />;
  } else if (
    !authService.isAdmin &&
    usersService.me?.asJson.status !== UserStatus.VERIFIED
  ) {
    return <Navigate to={ROUTES.contactScreen} />;
  } else if (
    !authService.isAdmin &&
    !authService.hasPermission(Version.V1) &&
    !authService.hasPermission(Version.V2)
  ) {
    return <Navigate to={ROUTES.permissionScreen} />;
  }
  return <>{children}</>;
};

export default AuthorizedUserGuard;
