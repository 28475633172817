import { SvgIcon, SvgIconProps } from "@shared/material";

function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="158"
      height="159"
      viewBox="0 0 158 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3356_8505)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.964 124.325L91.1551 16.8445C88.6018 12.6103 83.9442 9.97981 78.9996 9.97981C74.055 9.97981 69.3974 12.6103 66.8438 16.8448L2.03507 124.325C-0.603717 128.702 -0.681791 134.185 1.83109 138.635C4.34458 143.085 9.08026 145.849 14.1906 145.849H143.809C148.919 145.849 153.655 143.085 156.168 138.634C158.681 134.184 158.603 128.701 155.964 124.325ZM147.211 133.576C146.519 134.801 145.215 135.562 143.809 135.562H14.1906C12.7837 135.562 11.4799 134.801 10.7883 133.577C10.0965 132.351 10.1181 130.842 10.8442 129.637L75.6535 22.1566C76.3565 20.9911 77.6387 20.2668 78.9999 20.2668C80.3608 20.2668 81.643 20.9911 82.346 22.1566L147.155 129.637C147.881 130.842 147.903 132.351 147.211 133.576ZM79.1436 107.943C74.9446 107.943 71.7948 111.283 71.7948 115.292C71.7948 119.205 74.9443 122.64 79.1436 122.64C83.0566 122.64 86.3971 119.205 86.3971 115.292C86.3971 111.283 83.0563 107.943 79.1436 107.943ZM72.0813 58.1249C72.0813 54.4027 75.1351 52.303 79.0481 52.303C82.7704 52.303 85.9196 54.403 85.9196 58.1249C85.9196 63.8036 85.5856 70.7228 85.2516 77.6421C84.9176 84.5612 84.5837 91.4803 84.5837 97.1587C84.5837 100.117 81.3388 101.358 79.0484 101.358C75.9946 101.358 73.4175 100.117 73.4172 97.1587C73.4172 91.48 73.0832 84.5608 72.7492 77.6415C72.4153 70.7224 72.0813 63.8033 72.0813 58.1249Z"
          fill="#D52037"
        />
      </g>
      <defs>
        <clipPath id="clip0_3356_8505">
          <rect
            width="158"
            height="158"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default WarningIcon;
