import { SelectItem } from "@shared/types/select-item";

import React, { FC } from "react";
import {
  AppWithStyles,
  appWithStyles,
  FormControl,
  MenuItem,
  Select,
} from "shared/material";
import { styles } from "./styled-select.styles";
import { SelectChangeEvent } from "@mui/material";
import { ArrowIcon } from "@shared/components/styled-select/arrow";

export interface StyledSelectProps extends AppWithStyles<typeof styles> {
  items: Array<SelectItem>;
  value: string;
  label: string;
  onChange: (value: string) => void;
}

const StyledSelect: FC<StyledSelectProps> = ({
  classes,
  items,
  value,
  label,
  onChange,
}: StyledSelectProps) => {
  const handleOnChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value as string);
  };

  return (
    <FormControl fullWidth className={classes.root}>
      <Select
        variant={"outlined"}
        labelId={`select-label-id-${label}`}
        id={`select-id-${label}`}
        label={""}
        value={value}
        IconComponent={ArrowIcon}
        onChange={handleOnChange}
        renderValue={(selected: string) => {
          const selectedItem = items.find((item) => item.id === selected);
          if (!selectedItem) return selected;
          return selectedItem?.selectedLabel ?? selectedItem?.label;
        }}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={`key-${item.id}`}
              value={item.id}
              aria-hidden={false}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledSelect);
