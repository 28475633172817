import React, { FC } from "react";
import { appWithStyles, AppWithStyles, Box } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { StyledButton } from "shared/components/buttons/styled-button";
import { styles } from "./downloading-software.styles";
import { t } from "@lingui/macro";
import { CarLinksModel } from "@shared/models/cars/car-links.model";

export interface DownloadingSoftwareProps extends AppWithStyles<typeof styles> {
  data: CarLinksModel | undefined;
  installerLink: string;
}

const DownloadingSoftware: FC<DownloadingSoftwareProps> = ({
  data,
  installerLink,
}: DownloadingSoftwareProps) => {
  const handleDownload = (url: string) => {
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "file";
    link.click();
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "50px 0px",
      }}
    >
      <Box
        sx={{
          fontSize: "28px",
          fontWeight: 700,
          lineHeight: "33.6px",
          marginBottom: "20px",
        }}
      >{t`Download SW`}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          width: "100%",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            flexDirection: "column",
          },
        }}
      >
        <StyledButton
          sx={{
            height: "60px",
            width: "100%",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
          }}
          color={"secondary"}
          disabled={!data?.asJson.firmwareLink}
          label={t`Download ${data?.asJson.name || ""}`}
          onClick={() => handleDownload(data?.asJson.firmwareLink || "")}
        ></StyledButton>
        <StyledButton
          sx={{
            height: "60px",
            width: "100%",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
          }}
          color={"secondary"}
          label={t`Download Gazer Installer`}
          onClick={() => handleDownload(installerLink)}
        ></StyledButton>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(DownloadingSoftware);
