import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    form: {
      gap: "10px",
    },
    formInput: {
      height: "85px",
      width: "40vw",
      maxWidth: 400,
      display: "flex",
      alignItems: "flex-end",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        width: "100%",
        maxWidth: "unset",
      },

      "& .MuiOutlinedInput-input": {
        padding: "14px 14px",
      },
    },
  });
}
