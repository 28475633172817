import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./error-component.styles";
import { Box } from "@mui/material";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import WarningIcon from "@shared/images/warning";
import { t } from "@lingui/macro";
import { StyledButton } from "@shared/components/buttons/styled-button";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export interface ErrorComponentProps extends AppWithStyles<typeof styles> {
  label?: string;
}

const ErrorComponent: FC<ErrorComponentProps> = ({
  label,
}: ErrorComponentProps) => {
  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
      }}
    >
      <WarningIcon />
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          width: "240px",
          lineHeight: "19.2px",
          textAlign: "center",
        }}
      >
        {label || t`An error occurred while loading the page`}
      </Box>
      <StyledButton
        sx={{
          width: "350px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            width: "100%",
          },
        }}
        label={t`Refresh`}
        onClick={() => window.location.reload()}
      />
    </Box>
  );
};

export default appWithStyles(styles)(ErrorComponent);
