import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./block-details.styles";
import { SectionHeader } from "@shared/components/section-header";
import { t } from "@lingui/macro";
import { Block } from "@ui/private/courses/components/block-item";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { useParams } from "react-router-dom";
import { NavItem } from "@shared/types/nav-item";
import { ROUTES } from "../../../../routing/routes";
import { BlockDetailsViewModel } from "@ui/private/courses/block-details/block-details.vm";
import { StyledBreadcrumb } from "@shared/components/styled-breadcrumb";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { StyledSearchField } from "@shared/components/styled-search-field";

export interface BlockDetailsProps extends AppWithStyles<typeof styles> {}

const BlockDetails: FC<BlockDetailsProps> = ({
  classes,
}: BlockDetailsProps) => {
  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const { blockId } = useParams();

  const $vm = useMemo(
    () => new BlockDetailsViewModel().setBlockId(blockId || ""),
    [],
  );

  const breadcrumbs: NavItem[] = [
    { label: t`Video courses`, path: ROUTES.courses },
    { label: $vm?.currentBlockTitle || "", path: ROUTES.blockDetails(blockId) },
  ];

  return (
    <Box>
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <StyledBreadcrumb paths={breadcrumbs} />
      </Box>
      <SectionHeader
        title={t`Video courses`}
        searchField={
          <Box
            sx={{
              width: "30vw",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                position: "absolute",
                bottom: "-80px",
                left: "0px",
              },
            }}
          >
            <StyledSearchField
              placeholder={t`Search by keywords`}
              onSearch={(e) => {}}
              value={""}
            />
          </Box>
        }
      />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        {$vm.currentBlock ? <Block block={$vm.currentBlock} /> : <></>}
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(BlockDetails);
