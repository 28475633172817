import React, { FC, useMemo } from "react";
import { appWithStyles, AppWithStyles, Box } from "@shared/material";
import { styles } from "./installation-map.styles";
import baseTheme from "@shared/theme/base-theme";
import { CarInstallationModel } from "@shared/models/cars/car-installation.dto";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Locale } from "@shared/enum/locale.enum";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import PhotoPlaceholder from "@shared/images/photo-placeholder";
import PhotoPlaceholderIcon from "@shared/images/photo-placeholder";

export interface InstallationMapProps extends AppWithStyles<typeof styles> {
  data: CarInstallationModel;
}

const InstallationMap: FC<InstallationMapProps> = ({
  classes,
  data,
}: InstallationMapProps) => {
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const images = useMemo(
    () => data.asJson.connectionPointsImg.filter((url) => !!url),
    [data],
  );

  const descriptions = useMemo(
    () => ({
      [Locale.English]: data.asJson.descriptionPoints[Locale.English]
        .split("//")
        .filter((text) => !!text),
      [Locale.Russian]: data.asJson.descriptionPoints[Locale.Russian]
        .split("//")
        .filter((text) => !!text),
      [Locale.Ukrainian]: data.asJson.descriptionPoints[Locale.Ukrainian]
        .split("//")
        .filter((text) => !!text),
    }),
    [data],
  );

  const items = useMemo(
    () =>
      new Array(
        Math.max(
          images.length,
          descriptions[Locale.English].length,
          descriptions[Locale.Russian].length,
          descriptions[Locale.Ukrainian].length,
        ),
      ).fill(""),
    [data],
  );

  return (
    <Box
      sx={{
        borderRadius: "32px",
        padding: "40px 0px",
        [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
          padding: "40px 0px",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            gap: "20px 20px",
            justifyContent: "space-between",
          }}
        >
          {items.map((_, index) => {
            return (
              <Box
                key={index}
                sx={{
                  backgroundColor: "#20242B",
                  borderRadius: "15px",
                  height: "fit-content",
                  width: "48%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  padding: "15px",
                  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                    width: "100%",
                    justifyContent: "center",
                  },
                }}
              >
                <div className={classes.title}>
                  <div className={classes.itemNumber}>
                    {index + 1}/{items.length}
                  </div>
                  {descriptions[localesViewModel.locale][index] || ""}
                </div>
                {images[index] ? (
                  <img
                    src={images[index]}
                    alt={""}
                    className={classes.image}
                  ></img>
                ) : (
                  <PhotoPlaceholderIcon className={classes.placeholder} />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <></>
    </Box>
  );
};

export default appWithStyles(styles)(InstallationMap);
