import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";

export function styles() {
  return appCreateStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        border: "1px solid white",
        borderRadius: "12px",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "21.6px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "10px 14px",
      },
      "& .MuiOutlinedInput-input::placeholder": {
        color: baseTheme.palette.text.secondary,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21.6px",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: "none",
        WebkitTextFillColor: baseTheme.palette.text.primary,
        transition: "background-color 5000s ease-in-out 0s",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
    label: {
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
    boldLabel: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "26.63px",
      marginBottom: "10px",
    },
    error: {
      "& .MuiOutlinedInput-root": {
        border: `1px solid ${baseTheme.palette.secondary.main}`,
      },
    },
    errorText: {
      color: baseTheme.palette.secondary.main,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
      textWrap: "nowrap",
    },
  });
}
