import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function PlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.1239 29.8596L29.2451 20.4977C28.7724 20.1551 28.145 20.1035 27.627 20.3697C27.1048 20.6339 26.7787 21.1705 26.7787 21.7505V40.4682C26.7787 41.0543 27.1048 41.5889 27.627 41.8531C27.8478 41.9645 28.0893 42.0202 28.3328 42.0202C28.6507 42.0202 28.9726 41.9191 29.2451 41.7189L42.1239 32.3652C42.5305 32.066 42.7679 31.6036 42.7679 31.1124C42.7699 30.613 42.5264 30.1527 42.1239 29.8596Z"
        fill="white"
      />
      <path
        d="M31.8951 0.0649414C14.7646 0.0649414 0.88269 13.9468 0.88269 31.0774C0.88269 48.2017 14.7646 62.0795 31.8951 62.0795C49.0215 62.0795 62.9055 48.1996 62.9055 31.0774C62.9075 13.9468 49.0215 0.0649414 31.8951 0.0649414ZM31.8951 56.9052C17.6293 56.9052 6.06312 45.3452 6.06312 31.0774C6.06312 16.8157 17.6293 5.24125 31.8951 5.24125C46.1588 5.24125 57.723 16.8136 57.723 31.0774C57.725 45.3452 46.1588 56.9052 31.8951 56.9052Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default PlayIcon;
