import { DiEntity, DiEntityIdentifier } from "@core/di/types";
import { DI_TOKENS } from "@shared/constants/di";
import { LocalesViewModel } from "@shared/view-models/locales.view-model";
import { AuthService } from "@shared/services/auth.service";
import { AppInitializationViewModel } from "@shared/view-models/app-initialization.view-model";
import { CarsService } from "@shared/services/cars.service";
import { UsersService } from "@shared/services/users.service";
import { InstallerViewModel } from "@shared/view-models/installer.view-model";
import { ScrollService } from "@shared/services/scroll.service";

export const sharedAppServices: Array<{
  diToken: DiEntityIdentifier;
  entity: DiEntity;
}> = [
  {
    diToken: DI_TOKENS.appInitializationViewModel,
    entity: AppInitializationViewModel,
  },
  { diToken: DI_TOKENS.localesViewModel, entity: LocalesViewModel },
  { diToken: DI_TOKENS.authService, entity: AuthService },
  { diToken: DI_TOKENS.carsService, entity: CarsService },
  { diToken: DI_TOKENS.usersService, entity: UsersService },
  { diToken: DI_TOKENS.installerViewModel, entity: InstallerViewModel },
  { diToken: DI_TOKENS.scrollService, entity: ScrollService },
];
