import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.0575 8.18617C4.00917 8.13814 3.8025 7.9568 3.6325 7.78787C2.56333 6.79752 0.813333 4.21398 0.279167 2.86176C0.193333 2.65641 0.0116667 2.13721 0 1.85982C0 1.59401 0.06 1.34062 0.181667 1.09883C0.351667 0.797418 0.619167 0.555626 0.935 0.423137C1.15417 0.337847 1.81 0.205358 1.82167 0.205358C2.53917 0.072869 3.705 0 4.99333 0C6.22083 0 7.33917 0.072869 8.0675 0.181344C8.07917 0.193765 8.89417 0.326254 9.17333 0.471164C9.68333 0.73697 10 1.25616 10 1.81179V1.85982C9.9875 2.22168 9.67083 2.98266 9.65917 2.98266C9.12417 4.26201 7.46 6.78592 6.35417 7.80029C6.35417 7.80029 6.07 8.08597 5.8925 8.21018C5.6375 8.40395 5.32167 8.5 5.00583 8.5C4.65333 8.5 4.325 8.39152 4.0575 8.18617Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}
