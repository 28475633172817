import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";
import { PhoneConverter } from "@shared/utils/phone-converter";
import { FormErrorType, getFormError } from "@shared/resources/form-erros";

@ValidatorConstraint({ name: "phoneNumber", async: false })
export class PhoneNumber implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
    const phoneConverter = new PhoneConverter();
    return phoneConverter.isValidNumber(text);
  }

  defaultMessage() {
    return getFormError(FormErrorType.IS_PHONE_NUMBER);
  }
}
