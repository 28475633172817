import { appCreateStyles, Button } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { styled } from "@mui/styles";

export const TextButton = styled(Button)({
  background: "none",
  backgroundColor: "transparent !important",
  color: `${baseTheme.palette.text.primary} !important`,
  textTransform: "none",
  padding: "0 !important",
  fontWeight: "700 !important",
  fontSize: "14px !important",
  lineHeight: "16.8px !important",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
});

export function styles() {
  return appCreateStyles({
    title: {
      marginBottom: "10vh",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        marginBottom: "50px",
      },
    },
    form: {
      height: "65vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        height: "40vh",
      },
    },
    resendTitle: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      color: "#747B89",
      marginBottom: "5px",
    },
  });
}
