import React, { FC, useEffect, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./contact-screen.styles";
import { t } from "@lingui/macro";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { GazerMetasmartCarLayout } from "@shared/components/layouts/gazer-metasmart-car-layout";
import { appObserver, appReaction } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { UserStatus } from "@shared/enum/user-status.enum";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { Socials } from "@shared/components/footer/components/socials";
import { StyledButton } from "@shared/components/buttons/styled-button";

export type ContactScreenProps = AppWithStyles<typeof styles>;

const ContactScreen: FC<ContactScreenProps> = appObserver(
  ({ classes }: ContactScreenProps) => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [_, render] = useState(0);

    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    const authService = appInject<IAuthService>(DI_TOKENS.authService);

    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    useEffect(() => {
      if (
        authService.isAdmin ||
        authService.user?.asJson.status === UserStatus.VERIFIED
      ) {
        navigate(ROUTES.home);
      }
    }, []);

    const handleDialogClose = () => {
      setOpenDialog(false);
    };

    const handleConfirmLogout = () => {
      setOpenDialog(false);
      authService.logout();
      setTimeout(() => {
        navigate(ROUTES.welcomeScreen);
      }, 0);
    };

    return (
      <GazerMetasmartCarLayout>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          className={classes.dialog}
        >
          <DialogTitle>{t`Sign out`}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "#fff" }}>
              {t`Are you sure you want to sign out?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton
              sx={{ color: "#fff" }}
              label={t`Cancel`}
              onClick={handleDialogClose}
              variant={"text"}
              color="primary"
            />
            <StyledButton
              label={t`Sign out`}
              onClick={handleConfirmLogout}
              color="secondary"
              autoFocus
            />
          </DialogActions>
        </Dialog>
        <Box className={classes.contact}>
          <Box
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "19.2px",
              textAlign: "center",
              margin: "20px 0px",
            }}
          >{t`Contact your distributor to add your details`}</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "20px",
              margin: "20px 0px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <StyledButton
                sx={{ width: "100%" }}
                label={t`Sign out`}
                onClick={() => setOpenDialog(true)}
                color={"secondary"}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <StyledButton
                sx={{ width: "100%" }}
                label={t`Refresh`}
                onClick={() => window.document.location.reload()}
              />
            </Box>
          </Box>
          <div className={classes.contacts}>{t`Contacts`}</div>
          <Box
            sx={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Socials />
          </Box>
        </Box>
      </GazerMetasmartCarLayout>
    );
  },
);

export default appWithStyles(styles)(ContactScreen);
