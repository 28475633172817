import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./legal-links.styles";
import { t } from "@lingui/macro";
import { Link } from "@mui/material";

export interface LegalLinksProps extends AppWithStyles<typeof styles> {}

const LegalLinks: FC<LegalLinksProps> = ({ classes }: LegalLinksProps) => {
  return (
    <div className={classes.root}>
      <div
        className={classes.text}
      >{`©${new Date().getFullYear()} All rights reserved`}</div>
      <Link
        target={"_blank"}
        sx={{
          color: "#fff",
          textDecoration: "underline",
          cursor: "pointer",
          textWrap: "nowrap",
        }}
        href={"https://gazer.com/en/user_agreement"}
      >{t`Terms of use`}</Link>
      <Link
        target={"_blank"}
        sx={{
          color: "#fff",
          textDecoration: "underline",
          cursor: "pointer",
          textWrap: "nowrap",
        }}
        href={"https://gazer.com/en/privacy_policy"}
      >{t`Privacy policy`}</Link>
    </div>
  );
};

export default appWithStyles(styles)(LegalLinks);
