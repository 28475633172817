import { IconButton, SvgIcon } from "@mui/material";
import { withStyles } from "@mui/styles";
import { appCreateStyles } from "@shared/material";

export const styles = () =>
  appCreateStyles({
    root: {
      width: "30px",
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      "& img": {
        width: "28px",
        height: "28px",
      },
    },
  });

export const StyledIconButton = withStyles(() => ({
  root: {
    width: "25px",
    height: "25px",
  },
}))(IconButton);

export const StyledSvgIcon = withStyles(() => ({
  root: {
    width: "15px",
    height: "15px",
  },
}))(SvgIcon);
