import React, { FC, MouseEventHandler, ReactNode } from "react";
import { AppWithStyles, appWithStyles } from "shared/material";
import { styles } from "@ui/app/app.styles";
import { IconButton, IconButtonProps } from "@mui/material";
import baseTheme from "@shared/theme/base-theme";

export interface StyledIconButtonProps extends AppWithStyles<typeof styles> {
  icon: ReactNode;
  color?: string;
  variant?: string;
  onClick: (e: MouseEventHandler<HTMLButtonElement>) => void;
}

const StyledIconButton: FC<StyledIconButtonProps & IconButtonProps> = ({
  classes,
  onClick,
  color,
  icon,
  ...props
}) => {
  return (
    <IconButton
      {...props}
      sx={{
        width: "40px",
        height: "40px",
        borderRadius: "6px",
        backgroundColor:
          color === "secondary"
            ? baseTheme.palette.secondary.main
            : baseTheme.palette.primary.main,
        "&:hover": {
          backgroundColor:
            color === "secondary"
              ? baseTheme.palette.secondary.main
              : baseTheme.palette.primary.main,
        },
      }}
      onClick={onClick}
      className={classes.root}
    >
      {icon}
    </IconButton>
  );
};

export default appWithStyles(styles)(StyledIconButton);
