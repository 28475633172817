import { SelectItem } from "@shared/types/select-item";

import React, { FC } from "react";
import {
  AppWithStyles,
  appWithStyles,
  FormControl,
  MenuItem,
  Select,
} from "shared/material";
import { styles } from "./styled-outlined-select.styles";
import { Box, SelectChangeEvent } from "@mui/material";
import { CustomArrowIcon } from "@shared/images/custom-arrow";

export interface StyledSelectProps extends AppWithStyles<typeof styles> {
  items: Array<SelectItem>;
  value: string;
  label: string;
  onChange: (value: string) => void;
}

const StyledOutlinedSelect: FC<StyledSelectProps> = ({
  classes,
  items,
  value,
  label,
  onChange,
}: StyledSelectProps) => {
  const handleOnChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value as string);
  };

  return (
    <FormControl fullWidth className={classes.root}>
      {value ? (
        <></>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "14px",
            left: "12px",
            color: "#BDC1C6",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "19.2px",
          }}
          id="filter-label"
        >
          {label}
        </Box>
      )}
      <Select
        variant={"outlined"}
        labelId={`select-label-id-${label}`}
        id={`select-id-${label}`}
        value={value}
        onChange={handleOnChange}
        IconComponent={CustomArrowIcon}
        renderValue={(selected: string) => {
          const selectedItem = items.find((item) => item.id === selected);
          if (!selectedItem) return selected;
          return selectedItem?.selectedLabel ?? selectedItem?.label;
        }}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={`key-${item.id}`}
              value={item.id}
              aria-hidden={false}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledOutlinedSelect);
