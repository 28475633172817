import { appCreateStyles } from "@shared/material";

export const styles = () =>
  appCreateStyles({
    country: {},
    countryName: {
      marginLeft: "15px",
    },
    dialCode: {
      marginLeft: "15px",
    },
  });
