import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./footer.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { Box } from "@mui/material";
import { LegalLinks } from "@shared/components/footer/components/legal-links";
import { Socials } from "@shared/components/footer/components/socials";
import { Navigation } from "@shared/components/footer/components/navigation";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import FooterBackground from "@shared/images/footer-light.png";

export type FooterProps = AppWithStyles<typeof styles>;

const Footer: FC<FooterProps> = appObserver(({}: FooterProps) => {
  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      component="footer"
      sx={{
        height: "160px",
        backgroundColor: "transparent",
        backgroundImage: `url(${FooterBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100vw 160px",
        [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          height: "60px",
          padding: "0px 135px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: baseTheme.palette.secondary.main,
        }}
      >
        <Box>
          <Navigation />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Socials />
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          padding: "0px 135px",
          height: "auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LegalLinks />
        </Box>
      </Box>
    </Box>
  );
});

export default appWithStyles(styles)(Footer);
