import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    contact: {
      height: "65vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiButton-root + .MuiButton-root": {
        marginTop: "15px",
      },
    },
    text: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.2px",
      textAlign: "center",
    },
    contacts: {
      marginTop: "50px",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center",
    },
  });
}
