import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {
      padding: "20px 0px",
      "& .MuiBreadcrumbs-separator": {
        color: "#D1D3D9 !important",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "18.5px",
      },
    },
    label: {
      color: "#E8EAED",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "18.5px",
    },
    link: {
      color: "#D1D3D9",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "18.5px",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  });
}
