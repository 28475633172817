import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {},
    title: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19.2px",
      display: "flex",
      alignItems: "center",
      padding: "10px",
      height: "auto",
    },
    image: {
      width: "100%",
      height: "auto",
    },
  });
}
