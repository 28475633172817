import React, { FC, ReactNode } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./private-layout-with-footer.styles";
import { appObserver } from "@core/state-management/utils";
import { PrivateLayout } from "@shared/components/layouts/private-layout";
import { Footer } from "@shared/components/footer";
import { Box } from "@mui/material";

export interface PrivateLayoutWithFooterProps
  extends AppWithStyles<typeof styles> {
  children: ReactNode;
}

const PrivateLayoutWithFooter: FC<PrivateLayoutWithFooterProps> = appObserver(
  ({ children }: PrivateLayoutWithFooterProps) => {
    return (
      <PrivateLayout>
        <Box
          sx={{
            minHeight: "calc(100vh - 224px)",
          }}
        >
          {children}
        </Box>
        <Footer />
      </PrivateLayout>
    );
  },
);

export default appWithStyles(styles)(PrivateLayoutWithFooter);
