import { SelectItem } from "@shared/types/select-item";

import React, { FC } from "react";
import {
  AppWithStyles,
  appWithStyles,
  FormControl,
  MenuItem,
  Select,
} from "shared/material";
import { styles } from "./styled-outlined-select2.styles";
import { CustomArrowIcon } from "@shared/images/custom-arrow";
import { UseFormRegisterReturn } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import classNames from "classnames";

export interface StyledSelectProps extends AppWithStyles<typeof styles> {
  items: Array<SelectItem>;
  resetValidation?: () => void;
  controls?: UseFormRegisterReturn<string>;
  label: string;
  boldLabel?: string;
  hasError?: boolean;
  error?: string;
  defaultValue?: string;
  onChange?: (id: string) => void;
}

const StyledOutlinedSelect2: FC<StyledSelectProps> = ({
  classes,
  items,
  controls,
  label,
  boldLabel,
  hasError,
  error,
  defaultValue,
  onChange,
}: StyledSelectProps) => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    controls?.onChange(e);
    if (onChange) {
      onChange(e.target.value || "");
    }
  };
  return (
    <FormControl
      fullWidth
      className={classNames(classes.root, { [classes.error]: hasError })}
    >
      {label && !boldLabel && !hasError ? (
        <div className={classes.label}>{label}</div>
      ) : (
        <></>
      )}
      {boldLabel && !hasError ? (
        <div className={classes.boldLabel}>{boldLabel}</div>
      ) : (
        <></>
      )}
      {hasError ? <div className={classes.errorText}>{error}</div> : <></>}
      <Select
        {...controls}
        variant="outlined"
        labelId={`select-label-id-${label}`}
        id={`select-id-${label}`}
        onBlur={controls?.onBlur}
        inputRef={controls?.ref}
        defaultValue={defaultValue}
        onChange={handleChange}
        IconComponent={CustomArrowIcon}
        renderValue={(selected: string) => {
          const selectedItem = items.find((item) => item.id === selected);
          if (!selectedItem) return selected;
          return selectedItem?.selectedLabel ?? selectedItem?.label;
        }}
      >
        {items.map((item) => (
          <MenuItem key={`key-${item.id}`} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledOutlinedSelect2);
