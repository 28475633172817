import { MuiSvgIcon } from "@shared/theme/overrides/svg-icon";
import { Components, Theme } from "@mui/material";
import { MuiToolbar } from "@shared/theme/overrides/toolbar";
import { MuiButton } from "@shared/theme/overrides/button";
import { MuiContainer } from "@shared/theme/overrides/container";
import { MuiSelect } from "@shared/theme/overrides/select";

export const components: Components<Omit<Theme, "components">> = {
  MuiSvgIcon,
  MuiToolbar,
  MuiButton,
  MuiContainer,
  MuiSelect,
};
