import { appInject, appInjectable } from "@core/di/utils";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { DI_TOKENS } from "@shared/constants/di";

import { IUsersService } from "@shared/interfaces/users-service.interface";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { UpdateUserDto } from "@shared/models/users/update-user.dto";
import {
  UserDetailsDto,
  UserDetailsModel,
} from "@shared/models/users/user-details.dto";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import {
  PaginationResponseDto,
  PaginationResponseModel,
} from "@shared/models/pagination-response.model";
import { UserListRequestDto } from "@shared/dto/admin/users-list-request.dto";
import { UpdateUserByAdminRequestDto } from "@shared/dto/admin/update-user-by-admin-request.dto";
import { UpdateUserPermissionRequestDto } from "@shared/dto/admin/update-user-permission-request.dto";
import {
  InstallersCredentialsDto,
  InstallersCredentialsModel,
} from "@shared/models/installers-credentials.model";

@appInjectable()
export class UsersService implements IUsersService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private _baseURL: string;
  private _userData: UserDetailsModel | null = null;
  private _userPhoto: string | null = null;

  get me() {
    return this._userData;
  }

  get myPhotoUrl() {
    return this._userPhoto;
  }

  constructor() {
    this._baseURL = this.configService.apiUrl;
    appMakeObservable(this, {
      _userData: appObservable,
      _userPhoto: appObservable,
    });
  }

  async getMyUserDetails(): Promise<UserDetailsModel> {
    const { data } = await this.httpClient.get<UserDetailsDto>("/users/me", {
      baseURL: this._baseURL,
    });
    const userData = new UserDetailsModel(data);
    this._userData = userData;
    this._userPhoto = userData.asJson.avatarUrl;
    return userData;
  }

  async getMyInstallerCredentials(): Promise<InstallersCredentialsModel> {
    const { data } = await this.httpClient.get<InstallersCredentialsDto>(
      "/users/my-installers-credentials",
      {
        baseURL: this._baseURL,
      },
    );
    return new InstallersCredentialsModel(data);
  }

  async updateMyUserDetails(dto: UpdateUserDto): Promise<UserDetailsModel> {
    const { data } = await this.httpClient.put<UserDetailsDto>(
      "/users/update-my-details",
      dto,
      {
        baseURL: this._baseURL,
      },
    );
    return new UserDetailsModel(data);
  }

  async updateUserByAdmin(
    userId: string,
    dto: UpdateUserByAdminRequestDto,
  ): Promise<void> {
    await this.httpClient.patch<UserDetailsDto>(`/admin/users/${userId}`, dto, {
      baseURL: this._baseURL,
    });
  }

  async updatePermissionsByAdmin(
    userId: string,
    dto: UpdateUserPermissionRequestDto,
  ): Promise<void> {
    await this.httpClient.patch<UserDetailsDto>(
      `/admin/permissions/${userId}`,
      dto,
      {
        baseURL: this._baseURL,
      },
    );
  }

  async uploadMyProfilePhoto(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    await this.httpClient.post<UserDetailsDto>(
      "/users/upload-my-photo",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        baseURL: this._baseURL,
      },
    );
  }

  async getUsersList(
    dto: UserListRequestDto,
  ): Promise<PaginationResponseModel<UserDetailsDto>> {
    const filteredDto = Object.fromEntries(
      Object.entries(dto).filter(
        ([, value]) => value !== null && value !== undefined && value !== "",
      ),
    );

    const { data } = await this.httpClient.get<
      PaginationResponseDto<UserDetailsDto>
    >("/admin/users", {
      baseURL: this._baseURL,
      params: filteredDto,
    });
    return new PaginationResponseModel<UserDetailsDto>(data);
  }

  onLogout() {
    this._userData = null;
    this._userPhoto = null;
  }
}
