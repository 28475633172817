import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";

import { styles } from "./scroll-component.styles";
import { Box } from "@mui/material";
import { StyledIconButton } from "@shared/components/buttons/styled-icon-button";
import { ArrowIcon } from "@shared/images/arrow-icon";
import { appInject } from "@core/di/utils";
import { IScrollService } from "@shared/interfaces/scroll-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver } from "@core/state-management/utils";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export type ScrollComponentProps = AppWithStyles<typeof styles>;

const ScrollComponent: FC<ScrollComponentProps> = appObserver(
  ({ classes }: ScrollComponentProps) => {
    const scrollService = appInject<IScrollService>(DI_TOKENS.scrollService);

    return (
      <Box
        sx={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
          zIndex: 999,
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            bottom: "100px",
          },
        }}
      >
        {scrollService.currentScrollPosition === 0 ? (
          <StyledIconButton
            color={"secondary"}
            sx={{ cursor: "pointer" }}
            onClick={() => scrollService.scrollToEnd()}
            icon={<ArrowIcon className={classes.rotatedImage} />}
          />
        ) : (
          <></>
        )}
        {scrollService.currentScrollPosition > 0 ? (
          <StyledIconButton
            color={"secondary"}
            sx={{ cursor: "pointer" }}
            onClick={() => scrollService.scrollToTop()}
            icon={<ArrowIcon />}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

export default appWithStyles(styles)(ScrollComponent);
