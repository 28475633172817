import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./welcome-screen.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { GazerMetasmartCarLayout } from "@shared/components/layouts/gazer-metasmart-car-layout";
import { appObserver, appReaction } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";

export interface WelcomeScreenProps extends AppWithStyles<typeof styles> {}

const WelcomeScreen: FC<WelcomeScreenProps> = appObserver(
  ({ classes }: WelcomeScreenProps) => {
    const [_, render] = useState(0);

    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    const navigate = useNavigate();

    const navigateToLogin = () => {
      navigate(ROUTES.login);
    };

    const navigateToRegistration = () => {
      navigate(ROUTES.registration);
    };

    return (
      <GazerMetasmartCarLayout>
        <Box className={classes.buttons}>
          <StyledButton label={t`Sign up`} onClick={navigateToRegistration} />
          <StyledButton label={t`Sign in`} onClick={navigateToLogin} />
        </Box>
      </GazerMetasmartCarLayout>
    );
  },
);

export default appWithStyles(styles)(WelcomeScreen);
