import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./home.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import PlayIcon from "@shared/images/play-icon/play-icon";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import classNames from "classnames";

export type HomeProps = AppWithStyles<typeof styles>;

const Home: FC<HomeProps> = ({ classes }: HomeProps) => {
  const navigate = useNavigate();

  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "auto",
        padding: "36px 24px 116px 24px",
        gap: "30px",
        position: "relative",
        [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
          height: "calc(100vh - 260px)",
          padding: "0px 135px 0px 135px",
          flexDirection: "row",
          gap: "20px",
        },
      }}
    >
      <div
        className={classNames(classes.item, classes.red)}
        onClick={() => navigate(ROUTES.courses)}
      >
        <div className={classes.label}>{t`Courses`}</div>
        <div
          className={classes.description}
        >{t`Professional development courses`}</div>
        <img
          className={classes.image}
          src={`${window.location.origin}/assets/tv-1.svg`}
          alt={""}
        />
        <div className={classes.playIcon}>
          <PlayIcon />
        </div>
      </div>
      <div
        className={classNames(classes.item, classes.grey)}
        onClick={() => navigate(ROUTES.catalog)}
      >
        <div className={classes.label}>{t`Catalog`}</div>
        <div
          className={classes.description}
        >{t`Catalog with instructions and firmware`}</div>
        <img
          className={classes.image}
          src={`${window.location.origin}/assets/phones.png`}
          alt={""}
        />
      </div>
    </Box>
  );
};

export default appWithStyles(styles)(Home);

/*
*
*
* <div
        className={classNames(classes.item, classes.grey)}
        onClick={() => navigate(ROUTES.home)}
      >
        <div className={classes.label}>{t`History`}</div>
        <div
          className={classes.description}
        >{t`History of installed products`}</div>
        <img
          className={classes.image}
          src={`${window.location.origin}/assets/cars.png`}
          alt={""}
        />
      </div>*/
