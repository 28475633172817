import React from "react";
import Lottie from "lottie-react";
import animationData from "./loader.lottie.json";
import { styles } from "./loader.styles";
import { appWithStyles, AppWithStyles } from "@shared/material";

export interface LoaderProps extends AppWithStyles<typeof styles> {}

const Loader: React.FC<LoaderProps> = ({ classes }) => {
  return (
    <Lottie
      className={classes.loader}
      animationData={animationData}
      loop={true}
    />
  );
};

export default appWithStyles(styles)(Loader);
