import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    title: {
      marginBottom: "10vh",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        marginBottom: "50px",
      },
    },
    form: {
      height: "65vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        height: "350px",
      },
    },
  });
}
