import React, { FC, ReactNode, useState } from "react";
import {
  AppBar,
  AppWithStyles,
  appWithStyles,
  Box,
  Toolbar,
  useMediaQuery,
} from "@shared/material";
import { styles } from "./private-layout.styles";
import { appObserver, appReaction } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { HeaderLogoIcon } from "shared/images/header-logo";
import { PrivateLocales } from "@shared/components/locales/private-locales";
import { NavBar } from "@shared/components/navbar";
import { ProfileIcon } from "@shared/components/profile-icon";
import { BottomMenu } from "@shared/components/bottom-menu";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routing/routes";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { Installer } from "@shared/components/installer";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export interface PrivateLayoutProps extends AppWithStyles<typeof styles> {
  children: ReactNode;
}

const PrivateLayout: FC<PrivateLayoutProps> = appObserver(
  ({ children }: PrivateLayoutProps) => {
    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );
    const isMobile = useMediaQuery(() =>
      baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
    );
    const usersService = appInject<IUsersService>(DI_TOKENS.usersService);

    const [_, render] = useState(0);
    appReaction(
      () => usersService.myPhotoUrl,
      () => render(_ + 1),
    );

    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    const navigate = useNavigate();
    return (
      <>
        <AppBar position="static" color={"transparent"}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "40px" }}>
              <HeaderLogoIcon
                onClick={() => navigate(ROUTES.home)}
              ></HeaderLogoIcon>
              {isMobile ? <></> : <Installer />}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <NavBar />
              <BottomMenu />
              <PrivateLocales
                locale={localesViewModel.locale}
                onChange={(local) => localesViewModel.setLocale(local)}
              />
              <ProfileIcon
                firstName={usersService.me?.asJson.firstName || ""}
                lastName={usersService.me?.asJson.lastName || ""}
                link={usersService.me?.asJson.avatarUrl || ""}
              />
            </Box>
          </Toolbar>
          {isMobile ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  padding: "5px",
                  backgroundColor: baseTheme.palette.background.paper,
                  borderRadius: "10px",
                }}
              >
                <Installer />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </AppBar>
        {children}
      </>
    );
  },
);

export default appWithStyles(styles)(PrivateLayout);
