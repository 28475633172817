import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {
      height: "15px",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14.4px",
      display: "flex",
      gap: "40px",
    },
    text: {
      textWrap: "nowrap",
    },
  });
}
