import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";

export const styles = () =>
  appCreateStyles({
    root: {},
    container: {
      justifyContent: "center",
    },
    input: {
      width: "65px !important",
      height: "65px",
      border: "1px solid #fff",
      borderRadius: "12px",
      color: baseTheme.palette.text.primary,
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700,
      backgroundColor: "transparent",
      fontFamily: "inherit",
      margin: "0 4px",
      padding: "8px 15px",

      "&:focus": {
        outline: "none",
        border: "1px solid #fff",
      },
    },
    label: {
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
    error: {
      "& $input": {
        border: `1px solid ${baseTheme.palette.secondary.main}`,
      },
    },
    errorText: {
      color: baseTheme.palette.secondary.main,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
  });
