import React, { FC, useEffect } from "react";
import {
  AppWithStyles,
  appWithStyles,
  CssBaseline,
  ThemeProvider,
} from "shared/material";
import { styles } from "@ui/app/app.styles";
import theme from "@shared/theme/theme";
import { AppOutlet } from "@shared/router";
import { ROUTES } from "../../routing/routes";
import { useNavigate } from "react-router-dom";

export type AppProps = AppWithStyles<typeof styles>;

const App: FC<AppProps> = ({ classes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === ROUTES.initial) {
      navigate(ROUTES.welcomeScreen);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppOutlet />
    </ThemeProvider>
  );
};

export default appWithStyles(styles)(App);
