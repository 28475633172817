import { testBlocks } from "@shared/resources/test-lessons";
import { CourseListModel } from "@shared/models/course-list.model";
import { BlockListModel } from "@shared/models/block-list.model";

export class CourseDetailsViewModel {
  //private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private _currentLessons: CourseListModel | null = null;
  private _nextLessons: CourseListModel | null = null;
  private _previousLessons: CourseListModel | null = null;
  private _block: BlockListModel | null = null;

  get currentBlockTitle() {
    return this._block?.asJson.title || "";
  }

  get currentLessonsTitle(): string {
    return this.currentLessons?.asJson.title || "";
  }

  get currentLessonsUrl(): string {
    return this.currentLessons?.asJson.url || "";
  }

  get nextLessonsTitle(): string | null {
    return this._nextLessons?.asJson.title || "";
  }

  get previousLessonsTitle(): string | null {
    return this._previousLessons?.asJson.title || "";
  }

  get currentLessons(): CourseListModel | null {
    return this._currentLessons;
  }

  get nextLessons(): CourseListModel | null {
    return this._nextLessons;
  }

  get previousLessons(): CourseListModel | null {
    return this._previousLessons;
  }

  setBlockIdAndCourseId(
    blockId: string,
    courseId: string,
  ): CourseDetailsViewModel {
    this._block =
      testBlocks.find((block) => (block.asJson.id = blockId)) || null;
    if (!this._block) return this;
    const index = this._block.asJson.courses.findIndex(
      (lesson) => lesson.asJson.id === courseId,
    );
    this._currentLessons = this._block.asJson.courses[index] || null;
    this._nextLessons = this._block.asJson.courses[index + 1] || null;
    this._previousLessons = this._block.asJson.courses[index - 1] || null;
    return this;
  }
}
