import { DiEntityIdentifier } from "@core/di/types";

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  appInitializationViewModel: "appInitializationViewModel",
  baseService: "base-service",
  httpClientService: "http-client-service",
  configService: "config-service",
  storageService: "storage-service",
  localesViewModel: "locales-view-model",
  authService: "auth-service",
  carsService: "cars-service",
  usersService: "user-service",
  installerViewModel: "installer-view-model",
  scrollService: "scroll-service",
});
