import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    image: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      cursor: "pointer",
      objectFit: "cover",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {},
    },
    placeholder: {
      padding: "12px",
      borderRadius: "50%",
      backgroundColor: "#515A6C",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {},
    },
  });
}
