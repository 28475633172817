import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./users-table.styles";
import {
  Box,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { Loader } from "@shared/components/loader";
import { t } from "@lingui/macro";
import { UserDetailsDto } from "@shared/models/users/user-details.dto";
import { PaginationResponseModel } from "@shared/models/pagination-response.model";
import { SortOrder } from "@shared/enum/sort-order.enum";
import { Role } from "@shared/enum/role.enum";
import { UserStatus } from "@shared/enum/user-status.enum";
import { UsersStatusLabel } from "../user-status-label";
import { UsersRoleLabel } from "../user-role-label";
import { dateUtils } from "@shared/utils/date";
import UserPermissionsLabel from "@ui/admin/users-management/components/user-permissions-label/user-permissions-label";
import { StyledOutlinedSelect } from "@shared/components/styled-outlined-select";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export interface UsersTableProps extends AppWithStyles<typeof styles> {
  isLoading: boolean;
  data: PaginationResponseModel<UserDetailsDto>;

  sortField: string;
  sortOrder: SortOrder;
  pageNumber: number;
  pageSize: number;

  filterRole: Role | string;
  filterStatus: UserStatus | string;

  onChangeSortField: (e: string) => void;
  onChangePageNumber: (e: number) => void;
  onChangePageSize: (e: number) => void;
  onChangeFilterRole: (e: Role | string) => void;
  onChangeFilterStatus: (e: UserStatus | string) => void;
  onSelectUser: (user: UserDetailsDto) => void;
}

const UsersTable: FC<UsersTableProps> = ({
  classes,
  isLoading,
  data,
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  filterRole,
  filterStatus,
  onChangeFilterRole,
  onChangeFilterStatus,
  onChangeSortField,
  onChangePageNumber,
  onChangePageSize,
  onSelectUser,
}: UsersTableProps) => {
  let component = <></>;
  if (isLoading) {
    component = (
      <Box
        sx={{
          height: "calc(100vh - 521px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Loader />
        </Box>
      </Box>
    );
  } else {
    component = (
      <TableBody>
        {data.asJson.data.map((row: UserDetailsDto) => (
          <TableRow key={row.id}>
            <TableCell
              onClick={() => onSelectUser(row)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {row.id}
            </TableCell>
            <TableCell>
              <UsersRoleLabel role={row.role as Role} />
            </TableCell>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.phoneNumber}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>
              <UsersStatusLabel status={row.status as UserStatus} />
            </TableCell>
            <TableCell>
              <UserPermissionsLabel
                role={row.role as Role}
                permission={row.permissions}
              />
            </TableCell>
            <TableCell>{row.country}</TableCell>
            <TableCell>{row.city}</TableCell>
            <TableCell>{row.organization}</TableCell>
            <TableCell>{row.dealershipCenter}</TableCell>
            <TableCell>
              {dateUtils(new Date(row.createdAt)).format("DD.MM.YYYY")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "15px",
          margin: "20px 0px 20px 0px",
        }}
      >
        <Box
          sx={{
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "21.6px",
            color: "#BDC1C6",
          }}
        >{t`Filters`}</Box>
        <Box
          className={classes.filters}
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <StyledOutlinedSelect
            items={[
              { id: "", label: t`All` },
              { id: Role.USER, label: t`User` },
              { id: Role.ADMIN, label: t`Admin` },
            ]}
            value={`${filterRole}`}
            label={t`Role`}
            onChange={onChangeFilterRole}
          />
          <StyledOutlinedSelect
            items={[
              { id: "", label: t`All` },
              { id: UserStatus.NEW, label: t`New` },
              { id: UserStatus.BLOCKED, label: t`Blocked` },
              { id: UserStatus.VERIFIED, label: t`Verified` },
            ]}
            value={`${filterStatus}`}
            label={t`Status`}
            onChange={onChangeFilterStatus}
          />
        </Box>
      </Box>
      <Paper
        className={classes.root}
        sx={{
          margin: "20px 0px",
          borderRadius: "15px",
        }}
      >
        <TableContainer>
          <Table sx={{ position: "relative" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "id"}
                    direction={sortField === "id" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("id")}
                  >
                    {t`ID`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "role"}
                    direction={sortField === "role" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("role")}
                  >
                    {t`Role`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "firstName"}
                    direction={sortField === "firstName" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("firstName")}
                  >
                    {t`First name`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "lastName"}
                    direction={sortField === "lastName" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("lastName")}
                  >
                    {t`Last name`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "phoneNumber"}
                    direction={sortField === "phoneNumber" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("phoneNumber")}
                  >
                    {t`Phone number`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "email"}
                    direction={sortField === "email" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("email")}
                  >
                    {t`Email`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "status"}
                    direction={sortField === "status" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("status")}
                  >
                    {t`Status`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "status"}
                    direction={sortField === "status" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("status")}
                  >
                    {t`Resources`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "country"}
                    direction={sortField === "country" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("country")}
                  >
                    {t`Country`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "city"}
                    direction={sortField === "city" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("city")}
                  >
                    {t`City`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "organization"}
                    direction={sortField === "organization" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("organization")}
                  >
                    {t`Organization`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "dealershipCenter"}
                    direction={
                      sortField === "dealershipCenter" ? sortOrder : "asc"
                    }
                    onClick={() => onChangeSortField("dealershipCenter")}
                  >
                    {t`Dealership center`}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "createdAt"}
                    direction={sortField === "createdAt" ? sortOrder : "asc"}
                    onClick={() => onChangeSortField("createdAt")}
                  >
                    {t`Created`}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {component}
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data.asJson.totalCount}
          page={pageNumber - 1}
          onPageChange={(_, newPage) => onChangePageNumber(newPage + 1)}
          rowsPerPage={pageSize || 25}
          onRowsPerPageChange={(e) =>
            onChangePageSize(parseInt(e.target.value))
          }
        />
      </Paper>
    </>
  );
};

export default appWithStyles(styles)(UsersTable);
