import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";

import { styles } from "./profile-icon.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";

export interface ProfileIconProps extends AppWithStyles<typeof styles> {
  firstName: string;
  lastName: string;
  link?: string;
}

const Navbar: FC<ProfileIconProps> = ({
  classes,
  firstName,
  lastName,
  link,
}: ProfileIconProps) => {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(ROUTES.profile);
  };

  return (
    <>
      {link ? (
        <img
          className={classes.image}
          src={link}
          alt=""
          onClick={navigateToProfile}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <div className={classes.placeholder} onClick={navigateToProfile}>
          {`${firstName[0]?.toUpperCase() || ""}${lastName[0]?.toUpperCase() || ""}`}
        </div>
      )}
    </>
  );
};

export default appWithStyles(styles)(Navbar);
