import React, { useState } from "react";

import classNames from "classnames";
import OtpInput from "react-otp-input-rc-17";

import { styles } from "./otp-text-field.styles";
import { AppWithStyles, appWithStyles, FormControl } from "@shared/material";
import { UseFormRegisterReturn } from "react-hook-form";
import { t } from "@lingui/macro";

export interface OtpTextFieldProps extends AppWithStyles<typeof styles> {
  resetValidation?: () => void;
  controls: UseFormRegisterReturn<string>;
  label?: string;
  hasError?: boolean;
  size: number;
  error?: string;
}

const OtpTextField: React.FC<OtpTextFieldProps> = ({
  classes,
  controls,
  size,
  label,
  resetValidation,
  hasError,
  error,
}) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (e: unknown) => {
    if (resetValidation) {
      resetValidation();
    }
    setValue(`${e}`);
    controls.onChange({
      target: { name: "code", value: e },
      type: "text",
    });
  };

  return (
    <FormControl
      fullWidth
      className={classNames(classes.root, { [classes.error]: hasError })}
    >
      {label && !hasError ? (
        <div className={classes.label}>{label}</div>
      ) : (
        <></>
      )}
      {hasError ? (
        <div className={classes.errorText}>
          {error || t`Invalid code entered, please try again`}
        </div>
      ) : (
        <></>
      )}
      <OtpInput
        containerStyle={classes.container}
        inputStyle={classNames(classes.input)}
        shouldAutoFocus={true}
        numInputs={size}
        value={value}
        placeholder={""}
        onChange={handleChange}
        isInputNum={true}
      />
    </FormControl>
  );
};

export default appWithStyles(styles)(OtpTextField);
