import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    dialog: {
      "& .MuiPaper-root": {
        borderRadius: "10px",
      },
    },
  });
}
