import React, { FC } from "react";
import {
  appWithStyles,
  AppWithStyles,
  Box,
  Divider,
  Tabs,
  useMediaQuery,
} from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { StyledButton } from "shared/components/buttons/styled-button";
import {
  ResourceItem,
  ResourceSwitcherItem,
} from "@ui/private/modifications/components/resource-switcher/resource-switcher-items";
import { styles } from "./resource-switcher.styles";

export interface ResourceSwitcherProps extends AppWithStyles<typeof styles> {
  activeValue: ResourceItem;
  items: ResourceSwitcherItem[];
  onChange: (id: ResourceItem) => void;
}

const ResourceSwitcher: FC<ResourceSwitcherProps> = ({
  classes,
  activeValue,
  items,
  onChange,
}: ResourceSwitcherProps) => {
  const isMobile = useMediaQuery(() =>
    baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
  );

  const handleResource = (event: unknown, newValue: ResourceItem) => {
    onChange(newValue);
  };

  const tabsWithDividers = items.flatMap((item, index) => [
    index !== 0 && (
      <Divider
        key={`divider-${item.id}`}
        orientation={isMobile ? "vertical" : "horizontal"}
        flexItem
        sx={{ margin: "0 10px" }}
      />
    ),
    <StyledButton
      key={item.id}
      sx={{ width: "100%" }}
      label={item.label}
      disabled={item.disabled || false}
      variant={activeValue === item.id ? "contained" : "text"}
      color={activeValue === item.id ? "secondary" : "inherit"}
      onClick={() => onChange(item.id)}
    />,
  ]);

  return (
    <Box
      className={classes.root}
      sx={{
        border: `1px solid ${baseTheme.palette.info.main}`,
        width: "100%",
        height: "auto",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Tabs
        value={activeValue}
        onChange={handleResource}
        aria-label="tabs"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {tabsWithDividers}
      </Tabs>
    </Box>
  );
};

export default appWithStyles(styles)(ResourceSwitcher);
