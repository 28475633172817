import { SvgIcon, SvgIconProps } from "@shared/material";

function PhotoPlaceholderIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="518"
      height="664"
      viewBox="0 0 518 664"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="518" height="664" rx="16" fill="transparent" />
      <g clipPath="url(#clip0_3770_8704)">
        <path
          d="M156.584 320.594C151.834 286.904 149.459 270.059 159.553 259.156C169.647 248.253 187.617 248.253 223.557 248.253H294.431C330.371 248.253 348.341 248.253 358.435 259.156C368.529 270.059 366.154 286.904 361.404 320.594L356.9 352.543C353.174 378.963 351.313 392.172 341.758 400.084C332.203 407.994 318.111 407.994 289.926 407.994H228.061C199.877 407.994 185.785 407.994 176.23 400.084C166.676 392.172 164.813 378.963 161.088 352.543L156.584 320.594Z"
          stroke="#20242B"
          strokeWidth="20.435"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297.346 296.175C297.346 290.882 301.637 286.59 306.93 286.59C312.223 286.59 316.515 290.882 316.515 296.175C316.515 301.468 312.223 305.759 306.93 305.759C301.637 305.759 297.346 301.468 297.346 296.175ZM306.93 273.811C294.579 273.811 284.566 283.824 284.566 296.175C284.566 308.526 294.579 318.539 306.93 318.539C319.281 318.539 329.294 308.526 329.294 296.175C329.294 283.824 319.281 273.811 306.93 273.811ZM213.848 323.189L213.848 323.189C203.406 314.546 187.099 314.143 176.098 322.111L176.098 322.111L161.998 332.323C159.14 334.393 158.502 338.388 160.572 341.246C162.642 344.104 166.637 344.743 169.495 342.673L183.594 332.461L183.594 332.461C189.958 327.852 199.825 328.171 205.699 333.034L205.7 333.034L246.574 366.866L246.574 366.866C255.27 374.065 268.499 374.917 278.293 369.219L278.294 369.218L281.134 367.566L268.869 359.477C268.869 359.477 268.87 359.477 268.87 359.477L281.135 367.566C289.887 362.475 301.868 363.131 309.664 368.938L309.664 368.938L340.379 391.819C343.209 393.927 347.212 393.342 349.321 390.512C351.429 387.682 350.844 383.679 348.014 381.571L317.299 358.69L317.298 358.689C305.285 349.741 287.762 348.927 274.709 356.519L274.709 356.52L271.868 358.172L271.867 358.173C270.922 358.723 269.914 359.156 268.87 359.477L213.848 323.189ZM213.848 323.189L268.869 359.477C263.996 360.975 258.346 360.021 254.723 357.022L254.723 357.021L213.848 323.189Z"
          fill="#20242B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3770_8704">
          <rect
            width="244"
            height="244"
            fill="white"
            transform="matrix(1 0 0 -1 137 454)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PhotoPlaceholderIcon;
