import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiSelect-select": {
        backgroundColor: "transparent",
        borderRadius: "7px",
        paddingRight: "10px !important",
        padding: "10px 10px 10px 10px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        background: "#E8EAED",
        display: "flex",
        alignItems: "center",
        width: "35px",
      },
      "& .MuiSvgIcon-root": {
        right: "5px",
        top: "17px",
      },
    },
  });
}
