import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./user-data-form.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";
import {
  IUserDataForm,
  UserDataFormFields,
  UserDataFormFieldsResolver,
} from "@ui/private/profile/components/user-data-form/user-data-form.validator";
import { nameOf } from "@shared/utils/nameof";
import { StyledTextField } from "@shared/components/styled-text-field";
import { UserDetailsModel } from "@shared/models/users/user-details.dto";
import { InputPhone } from "@shared/components/input-phone";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { StyledInputFile } from "@shared/components/styled-input-file";
import { SelectItem } from "@shared/types/select-item";
import { Countries } from "@shared/resources/countries";
import { StyledOutlinedSelect2 } from "@shared/components/styled-outlined-select2";

export interface PhoneFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (formData: IUserDataForm) => void;
  error?: string;
  userData: UserDetailsModel;
  onFile: (file: File | null) => void;
}

const PhoneForm: FC<PhoneFormProps> = ({
  classes,
  onSubmit,
  userData,
  onFile,
}: PhoneFormProps) => {
  const [_, render] = useState(0);
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const [cities, setCities] = useState<Array<SelectItem>>([]);
  const [defaultCity, setDefaultCity] = useState<string>(userData.asJson.city);
  const [isVisibleCity, setIsVisibleCity] = useState<boolean>(true);
  const [isVisibleCountry, setIsVisibleCountry] = useState<boolean>(true);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [fileWasAdded, setFileWasAdded] = useState<boolean>(false);
  const [isVisibleFile, setIsVisibleFile] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<UserDataFormFields>({ resolver: UserDataFormFieldsResolver });

  useEffect(() => {
    setFormToDefault();
    watch(() => {
      checkChanges();
    });
    render(_ + 1);
  }, []);

  const refreshCity = () => {
    setIsVisibleCity(false);
    setTimeout(() => setIsVisibleCity(true), 0);
  };

  const refreshCountry = () => {
    setIsVisibleCountry(false);
    setTimeout(() => setIsVisibleCountry(true), 0);
  };

  const checkChanges = () => {
    if (!userData || !userData.asJson) {
      setHasChanges(false);
    }
    setHasChanges(
      !(
        getValues().firstName === userData.asJson.firstName &&
        getValues().lastName === userData.asJson.lastName &&
        getValues().email === userData.asJson.email &&
        getValues().phoneNumber === userData.asJson.phoneNumber &&
        getValues().country === userData.asJson.country &&
        getValues().city === userData.asJson.city &&
        getValues().organization === userData.asJson.organization &&
        getValues().dealershipCenter === userData.asJson.dealershipCenter
      ),
    );
  };

  const CountriesList = useMemo(
    () =>
      Object.keys(Countries).map(
        (country) =>
          ({
            id: country,
            label: country,
          }) as SelectItem,
      ),
    [],
  );

  const getCitiesList = useCallback(() => {
    const currentCountry = getValues()?.country || "";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error-component
    const country = Countries[currentCountry];
    if (!country) return [];
    return (country.cities || []).map(
      (city: string) =>
        ({
          id: city,
          label: city,
        }) as SelectItem,
    );
  }, []);

  const onFileChanged = (e: File | null) => {
    setFileWasAdded(!!e);
    onFile(e);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: IUserDataForm) => {
    onSubmit(data);
  };

  const setFormToDefault = () => {
    if (!userData || !userData.asJson) {
      return;
    }
    setValue(nameOf<IUserDataForm>("firstName"), userData.asJson.firstName);
    setValue(nameOf<IUserDataForm>("lastName"), userData.asJson.lastName);
    setValue(nameOf<IUserDataForm>("email"), userData.asJson.email);
    setValue(nameOf<IUserDataForm>("phoneNumber"), userData.asJson.phoneNumber);
    setValue(nameOf<IUserDataForm>("country"), userData.asJson.country);
    setValue(nameOf<IUserDataForm>("city"), userData.asJson.city);
    setDefaultCity(userData.asJson.city || "");
    setValue(
      nameOf<IUserDataForm>("organization"),
      userData.asJson.organization,
    );
    setValue(
      nameOf<IUserDataForm>("dealershipCenter"),
      userData.asJson.dealershipCenter,
    );
    setCities(getCitiesList());
    refreshCountry();
    refreshCity();
  };

  const reset = () => {
    setFormToDefault();
    if (fileWasAdded) {
      resetFileElement();
    }
  };

  const resetFileElement = () => {
    setIsVisibleFile(false);
    setFileWasAdded(false);
    setTimeout(() => setIsVisibleFile(true), 0);
  };

  return (
    <Box
      sx={{
        gap: "20px 40px",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: 1000,
        padding: "20px 0px 100px 0px",
      }}
      component={"form"}
      onSubmit={handleSubmit(submitForm)}
      onKeyDown={(e) => onKeyPress(e)}
    >
      <Box className={classes.formInput}>
        <StyledTextField
          controls={register(nameOf<IUserDataForm>("firstName"))}
          label={""}
          boldLabel={t`First name`}
          hasError={!!errors.firstName}
          error={errors.firstName?.message || ""}
        />
      </Box>
      <Box className={classes.formInput}>
        <StyledTextField
          controls={register(nameOf<IUserDataForm>("lastName"))}
          label={""}
          boldLabel={t`Last name`}
          hasError={!!errors.lastName}
          error={errors.lastName?.message || ""}
        />
      </Box>
      <Box className={classes.formInput}>
        <InputPhone
          boldLabel={t`Phone number`}
          defaultValue={getValues().phoneNumber || ""}
          controls={register(nameOf<IUserDataForm>("phoneNumber"))}
          resetValidation={() => {}}
          hasError={!!errors.phoneNumber}
          withoutFocus={true}
        />
      </Box>
      <Box className={classes.formInput}>
        {isVisibleCountry ? (
          <StyledOutlinedSelect2
            items={CountriesList}
            controls={register(nameOf<IUserDataForm>("country"))}
            label={""}
            boldLabel={t`Country`}
            hasError={!!errors.country}
            error={errors.country?.message || ""}
            defaultValue={userData.asJson.country}
            onChange={() => {
              setDefaultCity("");
              setValue(nameOf<IUserDataForm>("city"), "");
              setCities(getCitiesList());
              refreshCity();
            }}
          />
        ) : (
          <StyledTextField
            controls={register(nameOf<IUserDataForm>("country"))}
            label={""}
            boldLabel={t`Country`}
            hasError={!!errors.country}
            error={errors.country?.message || ""}
          />
        )}
      </Box>
      <Box className={classes.formInput}>
        {isVisibleCity ? (
          <StyledOutlinedSelect2
            items={cities}
            controls={register(nameOf<IUserDataForm>("city"))}
            label={""}
            boldLabel={t`City`}
            hasError={!!errors.city}
            error={errors.city?.message || ""}
            defaultValue={defaultCity}
          />
        ) : (
          <StyledTextField
            controls={register(nameOf<IUserDataForm>("city"))}
            label={""}
            boldLabel={t`City`}
            hasError={!!errors.city}
            error={errors.city?.message || ""}
          />
        )}
      </Box>
      <Box className={classes.formInput}>
        <StyledTextField
          controls={register(nameOf<IUserDataForm>("organization"))}
          label={""}
          boldLabel={t`Organization`}
          hasError={!!errors.organization}
          error={errors.organization?.message || ""}
        />
      </Box>
      <Box className={classes.formInput}>
        <StyledTextField
          controls={register(nameOf<IUserDataForm>("dealershipCenter"))}
          label={""}
          boldLabel={t`Dealership center`}
          hasError={!!errors.dealershipCenter}
          error={errors.dealershipCenter?.message || ""}
        />
      </Box>
      <Box className={classes.formInput}>
        <StyledTextField
          controls={register(nameOf<IUserDataForm>("email"))}
          label={""}
          boldLabel={t`Email`}
          hasError={!!errors.email}
          error={errors.email?.message || ""}
        />
      </Box>
      <Box className={classes.formInput}>
        {isVisibleFile ? (
          <StyledInputFile onFile={onFileChanged} boldLabel={t`Avatar`} />
        ) : (
          <></>
        )}
      </Box>
      {hasChanges || fileWasAdded ? (
        <Box
          className={classes.formInput}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "27px",
            gap: "20px",
          }}
        >
          <StyledButton
            sx={{ width: "100%" }}
            label={t`Cancel`}
            onClick={reset}
            type={"button"}
          />
          <StyledButton
            sx={{ width: "100%" }}
            label={t`Save`}
            color="secondary"
            disabled={false}
            onClick={() => {}}
            type={"submit"}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default appWithStyles(styles)(PhoneForm);
