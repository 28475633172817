import { appInject, appInjectable } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { IInstallerViewModel } from "@shared/interfaces/installer-vm.interface";
import { ICarsService } from "@shared/interfaces/cars-service.interface";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { Version } from "@shared/enum/version.enum";
import { IDataResetHandler } from "@shared/interfaces/data-reset.interface";

@appInjectable()
export class InstallerViewModel
  implements IInstallerViewModel, IDataResetHandler
{
  private carsService = appInject<ICarsService>(DI_TOKENS.carsService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);

  private _installerMacosLink: string = "";
  private _installerAndroidLink: string = "";
  private _login: string = "";
  private _pass: string = "";

  constructor() {
    appMakeObservable(this, {
      _installerMacosLink: appObservable,
      _installerAndroidLink: appObservable,
      _login: appObservable,
      _pass: appObservable,
    });
  }

  get macosLink() {
    return this._installerMacosLink;
  }

  get androidLink() {
    return this._installerAndroidLink;
  }

  get login() {
    return this._login;
  }

  get pass() {
    return this._pass;
  }

  async fetchData() {
    if (
      this.authService.isLoggedIn &&
      (this.authService.hasPermission(Version.V1) ||
        this.authService.hasPermission(Version.V2))
    ) {
      const { android, macos } = await this.carsService.getInstallerLinks();
      this._installerMacosLink = macos || "";
      this._installerAndroidLink = android || "";
      try {
        const credentials = await this.usersService.getMyInstallerCredentials();
        this._login = credentials?.asJson.login || "";
        this._pass = credentials?.asJson.pass || "";
      } catch {
        this._login = "";
        this._pass = "";
      }
    } else {
      this._installerMacosLink = "";
      this._installerAndroidLink = "";
      this._login = "";
      this._pass = "";
    }
  }

  //Erase data protocol - happens on logout
  onDataReset = () => {
    this._installerAndroidLink = "";
    this._installerMacosLink = "";
    this._login = "";
    this._pass = "";
  };
}
