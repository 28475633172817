import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.7087 3.40688C13.9767 3.40688 14.1998 3.62651 14.1998 3.90599V4.16439C14.1998 4.43707 13.9767 4.6635 13.7087 4.6635H2.29157C2.02291 4.6635 1.7998 4.43707 1.7998 4.16439V3.90599C1.7998 3.62651 2.02291 3.40688 2.29157 3.40688H4.30017C4.70819 3.40688 5.06328 3.1206 5.15507 2.71668L5.26025 2.25293C5.42373 1.62122 5.96172 1.20166 6.57744 1.20166H9.42217C10.0312 1.20166 10.5752 1.62122 10.7327 2.21961L10.8452 2.716C10.9363 3.1206 11.2914 3.40688 11.7001 3.40688H13.7087Z"
        fill="#E8EAED"
      />
      <path
        d="M12.6883 12.8528C12.898 10.9237 13.2651 6.3405 13.2651 6.29426C13.2785 6.15418 13.2323 6.02158 13.1405 5.91482C13.042 5.81487 12.9174 5.75571 12.7801 5.75571H3.22479C3.08677 5.75571 2.95546 5.81487 2.86434 5.91482C2.77188 6.02158 2.72632 6.15418 2.73302 6.29426C2.73425 6.30276 2.74743 6.4642 2.76945 6.7341C2.8673 7.9331 3.13981 11.2726 3.3159 12.8528C3.44052 14.0169 4.21435 14.7486 5.33523 14.7751C6.20017 14.7949 7.09125 14.8017 8.00242 14.8017C8.86067 14.8017 9.73231 14.7949 10.6241 14.7751C11.7838 14.7554 12.557 14.0367 12.6883 12.8528Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}
