import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./courses-list.styles";
import { SectionHeader } from "@shared/components/section-header";
import { t } from "@lingui/macro";
import { Block } from "@ui/private/courses/components/block-item";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { testBlocks } from "@shared/resources/test-lessons";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { StyledSearchField } from "@shared/components/styled-search-field";
import { AddNewSectionButton } from "@ui/private/courses/courses-list/components/add-new-section-button";
import { IAuthService } from "@shared/interfaces/auth-service.interface";

export interface CoursesProps extends AppWithStyles<typeof styles> {}

const CoursesList: FC<CoursesProps> = ({ classes }: CoursesProps) => {
  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box>
      <SectionHeader
        title={t`Video courses`}
        searchField={
          <Box
            sx={{
              width: "30vw",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                position: "absolute",
                bottom: "-80px",
                left: "0px",
              },
            }}
          >
            <StyledSearchField
              placeholder={t`Search by keywords`}
              onSearch={(e) => {}}
              value={""}
            />
          </Box>
        }
      />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        {testBlocks.map((block, index) => (
          <Block key={index} block={block} />
        ))}
        {authService.isAdmin ? (
          <AddNewSectionButton label={t`Add new section`} onClick={() => {}} />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(CoursesList);
