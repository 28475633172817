import { SvgIcon, SvgIconProps } from "@shared/material";

function InstallerLoginIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.86642 9.46289C6.86642 8.86421 7.37393 8.37891 8 8.37891C8.62607 8.37891 9.13358 8.86421 9.13358 9.46289C9.13358 9.84609 8.92546 10.1826 8.61161 10.3753V11.6602C8.61161 11.9837 8.33726 12.2461 7.99887 12.2461C7.66045 12.2461 7.38612 11.9837 7.38612 11.6602V10.374C7.07353 10.181 6.86642 9.84516 6.86642 9.46289Z"
        fill="#E8EAED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3077 5.50781H12.4118C13.4254 5.50781 14.25 6.29637 14.25 7.26562V13.2422C14.25 14.2114 13.4254 15 12.4118 15H3.58824C2.57463 15 1.75 14.2114 1.75 13.2422V7.26562C1.75 6.29637 2.57463 5.50781 3.58824 5.50781H4.69004V3.09873C4.69004 1.39008 6.17439 0 7.99887 0C9.82335 0 11.3077 1.39008 11.3077 3.09873V5.50781ZM7.99887 1.17188C6.85012 1.17188 5.91553 2.03625 5.91553 3.09873V5.50781H10.0822V3.09873C10.0822 2.03625 9.14761 1.17188 7.99887 1.17188ZM12.4118 13.8281C12.7496 13.8281 13.0245 13.5653 13.0245 13.2422V7.26562C13.0245 6.94254 12.7496 6.67969 12.4118 6.67969H3.58824C3.25037 6.67969 2.97549 6.94254 2.97549 7.26562V13.2422C2.97549 13.5653 3.25037 13.8281 3.58824 13.8281H12.4118Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}

export default InstallerLoginIcon;
