import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function ProfileIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 30.4999C10.0358 30.4999 5 29.5128 5 25.7048C5 21.8954 10.0674 20.9434 16 20.9434C21.9656 20.9434 27 21.9304 27 25.7384C27 29.5478 21.9326 30.4999 16 30.4999Z"
        fill="#E8EAED"
      />
      <path
        d="M23.2792 9.90748C23.2792 14.0193 20.0412 17.3164 15.9999 17.3164C11.96 17.3164 8.7207 14.0193 8.7207 9.90748C8.7207 5.79563 11.96 2.5 15.9999 2.5C20.0412 2.5 23.2792 5.79563 23.2792 9.90748Z"
        fill="#D52037"
      />
    </SvgIcon>
  );
}

export default ProfileIcon;
