import { Locale } from "@shared/enum/locale.enum";
import { SelectItem } from "@shared/types/select-item";

export const localesList: Array<SelectItem> = [
  {
    id: Locale.Ukrainian,
    label: "UA Українська",
    selectedLabel: "UA",
  },
  {
    id: Locale.Russian,
    label: "RU Русский",
    selectedLabel: "RU",
  },
  {
    id: Locale.English,
    label: "EN English",
    selectedLabel: "EN",
  },
];
