import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./login.styles";
import { PhoneNumber } from "ui/auth/login/components/phone-form";
import { OTPView } from "ui/auth/login/components/otp-view";
import { AuthLayout } from "@shared/components/layouts/auth-layout";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { LoginViewModel } from "@ui/auth/login/login.vm";
import { LoginScreen } from "@ui/auth/login/login-screen.enum";
import { IPhoneNumberForm } from "@ui/auth/login/components/phone-form/phone-form.validator";
import { IOTPView } from "@ui/auth/login/components/otp-view/otp-view.validator";
import { Loader } from "@shared/components/loader";
import { useMutation } from "react-query";
import { UseMutationResult } from "react-query/types/react/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { t } from "@lingui/macro";
import { ServerHttpError } from "@shared/types/error";

export type LoginProps = AppWithStyles<typeof styles>;

const Login: FC<LoginProps> = appObserver(({ classes }: LoginProps) => {
  const navigate = useNavigate();

  const [lastLoginTryDate, setLastLoginTryDate] = useState<Date | null>(null);
  const [otpError, setOtpError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");

  const sendCodeMutation: UseMutationResult<
    void,
    unknown,
    IPhoneNumberForm,
    unknown
  > = useMutation(
    (data: IPhoneNumberForm): Promise<void> =>
      $vm.sendCode(data.phoneNumber || ""),
    {
      onSuccess: () => {
        setLastLoginTryDate(new Date());
        $vm.setActiveScreen(LoginScreen.OTP_VIEW);
      },
      onError: (err) => {
        $vm.setError("Invalid Credentials");
      },
    },
  );

  const loginMutation: UseMutationResult<
    void,
    unknown,
    IPhoneNumberForm & IOTPView,
    unknown
  > = useMutation(
    (data: IPhoneNumberForm & IOTPView): Promise<void> =>
      $vm.login(data.phoneNumber || "", data.code || ""),
    {
      onSuccess: () => {
        navigate(ROUTES.home);
      },
      onError: (err: ServerHttpError) => {
        if (err.status === 401) {
          setOtpError(t`Invalid Code`);
        } else if (err.status === 404) {
          $vm.setActiveScreen(LoginScreen.PHONE);
          setPhoneError(t`You are not registered, please register`);
          setTimeout(() => {
            navigate(ROUTES.registration);
          }, 4000);
        } else {
          setOtpError(err.message);
        }
      },
    },
  );

  const onResendCode = () => {
    sendCodeMutation.mutate({ phoneNumber: $vm.phoneNumber });
  };

  const handleDisableOtp = () => {
    $vm.setActiveScreen(LoginScreen.PHONE);
  };

  const $vm = useMemo(
    () => new LoginViewModel({ sendCodeMutation, loginMutation }),
    [],
  );

  const [_, render] = useState(0);
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const loader = (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  );

  let activeForm = <></>;
  switch ($vm.activeScreen) {
    case LoginScreen.PHONE:
      activeForm = (
        <PhoneNumber
          defaultValue={$vm.phoneNumber}
          onSubmit={(data: IPhoneNumberForm) => $vm.onSubmitPhoneNumber(data)}
          error={phoneError}
          resetValidation={() => {}}
        />
      );
      break;
    case LoginScreen.OTP_VIEW:
      activeForm = (
        <OTPView
          onSubmit={(data: IOTPView) => $vm.onSubmitCode(data)}
          isLoading={loginMutation.isLoading || sendCodeMutation.isLoading}
          timerDate={lastLoginTryDate as Date}
          timerDelayMinutes={2}
          error={otpError}
          onResend={onResendCode}
          onBack={handleDisableOtp}
        />
      );
      break;
    default:
      break;
  }

  if (sendCodeMutation.isLoading) {
    activeForm = loader;
  }

  return <AuthLayout>{activeForm}</AuthLayout>;
});

export default appWithStyles(styles)(Login);
