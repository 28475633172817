import React, { FC, KeyboardEvent } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./name-form.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { AuthFormLayout } from "@shared/components/layouts/auth-form-layout";
import { StyledTextField } from "@shared/components/styled-text-field";
import { useForm } from "react-hook-form";
import {
  INameForm,
  NameFormFields,
  NameFormFieldsResolver,
} from "@ui/auth/registration/components/name-form/name-form.validator";
import { nameOf } from "@shared/utils/nameof";

export interface NameFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (data: INameForm) => void;
}

const NameForm: FC<NameFormProps> = ({ classes, onSubmit }: NameFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NameFormFields>({ resolver: NameFormFieldsResolver });

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: INameForm) => {
    onSubmit(data);
  };

  return (
    <AuthFormLayout title={t`Your name`}>
      <Box
        className={classes.form}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <Box>
          <StyledTextField
            controls={register(nameOf<INameForm>("firstName"))}
            label={t`Enter your first name`}
            hasError={!!errors.firstName}
            error={t`First name is required`}
          />
          <StyledTextField
            controls={register(nameOf<INameForm>("lastName"))}
            label={t`Enter your last name`}
            hasError={!!errors.lastName?.message}
            error={t`Last name is required`}
          />
        </Box>
        <StyledButton
          label={t`Next`}
          onClick={() => {}}
          type={"submit"}
          disabled={false}
        />
      </Box>
    </AuthFormLayout>
  );
};

export default appWithStyles(styles)(NameForm);
