import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";

export function styles() {
  return appCreateStyles({
    root: {},
    title: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19.2px",
      display: "flex",
      alignItems: "center",
      padding: "10px 0px",
      height: "auto",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: "15px",
    },
    placeholder: {
      borderRadius: "15px",
      width: "100% !important",
      height: "100%",
      backgroundColor: "#101216",
    },
    itemNumber: {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "26.4px",
      backgroundColor: baseTheme.palette.secondary.main,
      padding: "10px",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textWrap: "nowrap",
      marginRight: "10px",
    },
  });
}
