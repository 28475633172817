import {
  IsEmail,
  IsNotEmpty,
  MaxLength,
  MinLength,
  Validate,
} from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { FormErrorType, getFormError } from "@shared/resources/form-erros";
import { PhoneNumber } from "@shared/validators/phone-number";

export interface IUserDataForm {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  country?: string;
  city?: string;
  organization?: string;
  dealershipCenter?: string;
  email?: string;
}

export class UserDataFormFields implements IUserDataForm {
  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  firstName?: string;

  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  lastName?: string;

  @Validate(PhoneNumber)
  phoneNumber?: string;

  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  country?: string;

  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  city?: string;

  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  organization?: string;

  @MinLength(2, {
    message: getFormError(FormErrorType.MIN_LENGTH, { length: 2 }),
  })
  @MaxLength(100, {
    message: getFormError(FormErrorType.MAX_LENGTH, { length: 100 }),
  })
  dealershipCenter?: string;

  @IsNotEmpty()
  @IsEmail(
    {},
    {
      message: getFormError(FormErrorType.IS_EMAIL),
    },
  )
  email?: string;
}

export const UserDataFormFieldsResolver =
  classValidatorResolver(UserDataFormFields);
