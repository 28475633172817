import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.25839 16.3469L12.5081 10.3369L18.7577 16.3469C19.3859 16.951 20.4007 16.951 21.0289 16.3469C21.657 15.7428 21.657 14.7669 21.0289 14.1628L13.6356 7.05305C13.0074 6.44895 11.9926 6.44895 11.3644 7.05305L3.97114 14.1628C3.34295 14.7669 3.34295 15.7428 3.97114 16.3469C4.59933 16.9355 5.6302 16.951 6.25839 16.3469Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}
