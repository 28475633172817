import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    videoFrame: {},
    imageTv: {
      width: "33vh",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        width: "30vw",
      },
    },
    playIcon: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
      top: "50%",
    },
  });
}
