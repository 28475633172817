import { ComponentsOverrides, Theme } from "@mui/material";
import baseTheme from "@shared/theme/base-theme";

export const MuiButton = {
  styleOverrides: {
    root: {
      padding: "12px 16px",
      borderRadius: "10px",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "center",
      textTransform: "capitalize",

      "&.Mui-disabled": {
        backgroundColor: baseTheme.palette.background.paper,
        color: baseTheme.palette.text.disabled,
      },

      "&.MuiButton-colorPrimary": {
        backgroundColor: "#313641",
      },
    },
  } as ComponentsOverrides<Theme>["MuiButton"],
};
