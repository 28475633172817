import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { appInject } from "@core/di/utils";
import { IPhoneNumberForm } from "@ui/auth/login/components/phone-form/phone-form.validator";
import { IOTPView } from "@ui/auth/login/components/otp-view/otp-view.validator";
import { UseMutationResult } from "react-query";
import { cleanPhoneNumber } from "@shared/utils/clean-phone-number";
import { RegistrationScreen } from "@ui/auth/registration/registration-screen.enum";
import { RegisterUserDto } from "@shared/types/register-user.dto";
import { INameForm } from "@ui/auth/registration/components/name-form/name-form.validator";

export class RegistrationViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);

  private _sendCodeMutation: UseMutationResult<
    void,
    unknown,
    IPhoneNumberForm,
    unknown
  >;
  private _registerMutation: UseMutationResult<
    void,
    unknown,
    INameForm & IPhoneNumberForm & IOTPView,
    unknown
  >;
  private _screen: RegistrationScreen;
  private _data: RegisterUserDto = {
    phoneNumber: "",
    code: "",
    firstName: "",
    lastName: "",
  };
  private _error: string = "";

  constructor(params: {
    sendCodeMutation: UseMutationResult<
      void,
      unknown,
      IPhoneNumberForm,
      unknown
    >;
    registerMutation: UseMutationResult<
      void,
      unknown,
      INameForm & IPhoneNumberForm & IOTPView,
      unknown
    >;
  }) {
    this._screen = RegistrationScreen.NAME;
    this._sendCodeMutation = params.sendCodeMutation;
    this._registerMutation = params.registerMutation;
    appMakeObservable(this, {
      _screen: appObservable,
      _data: appObservable,
      _error: appObservable,
    });
  }

  setActiveScreen(screen: RegistrationScreen) {
    this._screen = screen;
    return this;
  }

  get phoneNumber() {
    return this._data.phoneNumber;
  }

  get error() {
    return this._error;
  }

  setError(error: string) {
    this._error = error;
  }

  get activeScreen() {
    return this._screen;
  }

  async onSubmitName(formData: INameForm) {
    this._data.firstName = formData.firstName || "";
    this._data.lastName = formData.lastName || "";
    this.setActiveScreen(RegistrationScreen.PHONE);
  }

  async onSubmitPhoneNumber(formData: IPhoneNumberForm) {
    this._data.phoneNumber = cleanPhoneNumber(formData.phoneNumber || "");
    this._sendCodeMutation.mutate(this._data);
  }

  async onSubmitCode(formData: IOTPView) {
    this._data.code = formData.code as string;
    this._registerMutation.mutate(this._data);
  }

  async sendCode(phoneNumber: string): Promise<void> {
    await this.authService.sendPhoneVerification(phoneNumber);
  }

  async register(data: RegisterUserDto) {
    await this.authService.register(data);
  }
}
