import { appCreateStyles } from "@shared/material";
import { Theme } from "@mui/material";
import baseTheme from "@shared/theme/base-theme";

export function styles({ palette }: Theme) {
  return appCreateStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        border: "1px solid white",
        borderRadius: "12px",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "21.6px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "10px 14px",
      },
      "& .MuiOutlinedInput-input::placeholder": {
        color: baseTheme.palette.text.secondary,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21.6px",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: "none",
        WebkitTextFillColor: baseTheme.palette.text.primary,
        transition: "background-color 5000s ease-in-out 0s",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiSelect-select": {
        backgroundColor: "transparent",
        paddingRight: "10px !important",
        padding: "10px 10px 10px 10px",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "21.6px",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        height: "34px",
      },
      "& .MuiOutlinedInput-notchedOutline span": {
        color: "red",
      },
      "& .MuiSelect-icon": {
        right: "15px",
        top: "20px",
      },
    },
    label: {
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
    },
    boldLabel: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "26.63px",
      marginBottom: "10px",
    },
    error: {
      "& .MuiOutlinedInput-root": {
        border: `1px solid ${baseTheme.palette.secondary.main}`,
      },
    },
    errorText: {
      color: baseTheme.palette.secondary.main,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
      textWrap: "nowrap",
    },
  });
}
