import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function InstructionsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.573 3.16688H11.2662C10.9562 3.1668 10.7185 3.16675 10.5108 3.18707C9.0337 3.33156 7.82469 4.21964 7.31201 5.41591H24.5271C24.0144 4.21964 22.8054 3.33156 21.3283 3.18707C21.1206 3.16675 20.883 3.1668 20.573 3.16688ZM20.3563 10.5562H11.4827C6.98296 10.5562 4.73308 10.5562 3.46928 11.872C2.20548 13.1878 2.50282 15.2206 3.09752 19.2863L3.09753 19.2863L3.66149 23.1416C4.12786 26.33 4.36105 27.9242 5.5573 28.8788C6.75356 29.8335 8.51793 29.8335 12.0467 29.8335H19.7923C23.3211 29.8335 25.0855 29.8335 26.2818 28.8788C27.4779 27.9243 27.7111 26.3304 28.1774 23.1427L28.1775 23.1416L28.7415 19.2863L28.7415 19.2862C29.3362 15.2205 29.6335 13.1878 28.3698 11.872C27.1059 10.5562 24.856 10.5562 20.3563 10.5562Z"
        fill="#E8EAED"
      />
      <path
        d="M8.33345 6.79736C6.47928 6.79736 4.95893 7.91703 4.45156 9.40242C4.44098 9.43338 4.43084 9.46451 4.42114 9.49578C4.95201 9.33502 5.5045 9.22999 6.06378 9.1583C7.50429 8.97362 9.32474 8.97371 11.4394 8.97383H11.5974H20.6289C22.7436 8.97371 24.5641 8.97362 26.0046 9.1583C26.5638 9.22999 27.1164 9.33502 27.6472 9.49578C27.6376 9.46451 27.6274 9.43338 27.6168 9.40242C27.1094 7.91703 25.589 6.79736 23.7349 6.79736H8.33345Z"
        fill="#D52037"
      />
      <path
        d="M20.4239 20.7999C20.1295 20.6257 19.6847 20.3998 19.6847 20C19.6847 19.6002 20.1295 19.3743 20.4239 19.2001C20.9751 18.8741 21.1635 18.1544 20.8453 17.5902L20.4615 16.9098C20.1441 16.3468 19.4383 16.1535 18.8887 16.4784C18.5811 16.6598 18.1159 16.9623 17.7657 16.7546C17.4459 16.5653 17.5354 16.006 17.5354 15.6786C17.5354 15.0288 17.0188 14.5 16.384 14.5H15.6164C14.9816 14.5 14.465 15.0288 14.465 15.6786C14.465 16.0061 14.5545 16.5657 14.2347 16.755C13.8839 16.962 13.4193 16.6604 13.1117 16.4784C12.5621 16.1535 11.8562 16.3468 11.5385 16.9098L11.1547 17.5902C10.8365 18.1544 11.0249 18.8745 11.5761 19.2001C11.8707 19.3741 12.3157 19.6001 12.3157 20C12.3157 20.3998 11.8709 20.6257 11.5765 20.7999C11.0253 21.1255 10.8369 21.8456 11.155 22.4098L11.5388 23.0902C11.8566 23.6532 12.5624 23.8465 13.1117 23.5216C13.4197 23.34 13.8838 23.0382 14.2347 23.2454C14.5544 23.4347 14.465 23.994 14.465 24.3214C14.465 24.9712 14.9816 25.5 15.6164 25.5H16.384C17.0188 25.5 17.5354 24.9712 17.5354 24.3214C17.5354 23.9939 17.4459 23.4343 17.7657 23.245C18.1162 23.0378 18.5812 23.3397 18.8887 23.5216C19.4383 23.8461 20.1441 23.6528 20.4615 23.0902L20.8453 22.4098C21.1635 21.8456 20.9751 21.1255 20.4239 20.7999ZM16.0002 21.9643C14.942 21.9643 14.0812 21.0831 14.0812 20C14.0812 18.9169 14.942 18.0357 16.0002 18.0357C17.0583 18.0357 17.9192 18.9169 17.9192 20C17.9192 21.0831 17.0583 21.9643 16.0002 21.9643Z"
        fill="#D52037"
      />
    </SvgIcon>
  );
}

export default InstructionsIcon;
