import React, { FC, KeyboardEvent } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./phone-form.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { AuthFormLayout } from "@shared/components/layouts/auth-form-layout";
import {
  IPhoneNumberForm,
  PhoneNumberFieldsResolver,
  PhoneNumberFormFields,
} from "@ui/auth/login/components/phone-form/phone-form.validator";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import { InputPhone } from "@shared/components/input-phone";

export interface PhoneFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (formData: IPhoneNumberForm) => void;
  error?: string;
  defaultValue?: string;
  resetValidation: () => void;
}

const PhoneForm: FC<PhoneFormProps> = ({
  classes,
  onSubmit,
  error,
  defaultValue,
  resetValidation,
}: PhoneFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PhoneNumberFormFields>({ resolver: PhoneNumberFieldsResolver });

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: IPhoneNumberForm) => {
    onSubmit(data);
  };
  return (
    <AuthFormLayout title={t`Your phone number`}>
      <Box
        className={classes.form}
        component={"form"}
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <InputPhone
          label={t`Enter your phone number`}
          defaultValue={defaultValue || ""}
          controls={register(nameOf<IPhoneNumberForm>("phoneNumber"))}
          resetValidation={resetValidation}
          hasError={!!errors.phoneNumber || !!error}
          errors={error}
        />
        <StyledButton
          label={t`Next`}
          disabled={false}
          onClick={() => {}}
          type={"submit"}
        />
      </Box>
    </AuthFormLayout>
  );
};

export default appWithStyles(styles)(PhoneForm);
