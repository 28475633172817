import { LoginScreen } from "@ui/auth/login/login-screen.enum";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { appInject } from "@core/di/utils";
import { IPhoneNumberForm } from "@ui/auth/login/components/phone-form/phone-form.validator";
import { IOTPView } from "@ui/auth/login/components/otp-view/otp-view.validator";
import { UseMutationResult } from "react-query";
import { cleanPhoneNumber } from "@shared/utils/clean-phone-number";

export class LoginViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);

  private _sendCodeMutation: UseMutationResult<
    void,
    unknown,
    IPhoneNumberForm,
    unknown
  >;
  private _loginMutation: UseMutationResult<
    void,
    unknown,
    IPhoneNumberForm & IOTPView,
    unknown
  >;
  private _screen: LoginScreen;
  private _data: { phoneNumber?: string; code?: string } = {};
  private _error: string = "";

  constructor(params: {
    sendCodeMutation: UseMutationResult<
      void,
      unknown,
      IPhoneNumberForm,
      unknown
    >;
    loginMutation: UseMutationResult<
      void,
      unknown,
      IPhoneNumberForm & IOTPView,
      unknown
    >;
  }) {
    this._screen = LoginScreen.PHONE;
    this._sendCodeMutation = params.sendCodeMutation;
    this._loginMutation = params.loginMutation;
    appMakeObservable(this, {
      _screen: appObservable,
      _data: appObservable,
      _error: appObservable,
    });
  }

  setActiveScreen(screen: LoginScreen) {
    this._screen = screen;
    return this;
  }

  get phoneNumber() {
    return this._data.phoneNumber;
  }

  get error() {
    return this._error;
  }

  setError(error: string) {
    this._error = error;
  }

  get activeScreen() {
    return this._screen;
  }

  async onSubmitPhoneNumber(formData: IPhoneNumberForm) {
    this._data.phoneNumber = cleanPhoneNumber(formData.phoneNumber || "");
    this._sendCodeMutation.mutate(this._data);
  }

  async onSubmitCode(formData: IOTPView) {
    this._data.code = formData.code;
    this._loginMutation.mutate(this._data);
  }

  async sendCode(phoneNumber: string): Promise<void> {
    await this.authService.sendPhoneVerification(phoneNumber);
  }

  async login(phoneNumber: string, code: string) {
    await this.authService.login(phoneNumber, code);
  }
}
