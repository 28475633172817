import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";

export function styles() {
  return appCreateStyles({
    root: {
      display: "flex",
      "& .MuiCheckbox-root": {
        width: "24px",
        padding: "0px",
      },
      "& .MuiSvgIcon-root": {
        height: "24px",
      },
    },
    label: {
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
      marginBottom: "10px",
      maxWidth: "250px",
    },
  });
}
