import React, { FC, useEffect, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./permission-screen.styles";
import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { GazerMetasmartCarLayout } from "@shared/components/layouts/gazer-metasmart-car-layout";
import { appObserver, appReaction } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { UserStatus } from "@shared/enum/user-status.enum";
import { ROUTES } from "../../../routing/routes";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { useNavigate } from "react-router-dom";
import { Version } from "@shared/enum/version.enum";

export type PermissionScreenProps = AppWithStyles<typeof styles>;

const PermissionScreen: FC<PermissionScreenProps> = appObserver(
  ({ classes }: PermissionScreenProps) => {
    const [_, render] = useState(0);

    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    const authService = appInject<IAuthService>(DI_TOKENS.authService);

    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    const navigate = useNavigate();

    useEffect(() => {
      if (
        authService.isAdmin ||
        authService.hasPermission(Version.V1) ||
        authService.hasPermission(Version.V2)
      ) {
        navigate(ROUTES.home);
      }
    }, []);

    return (
      <GazerMetasmartCarLayout>
        <Box className={classes.contact}>
          <div
            className={classes.text}
          >{t`Your application has been submitted, please wait`}</div>
        </Box>
      </GazerMetasmartCarLayout>
    );
  },
);

export default appWithStyles(styles)(PermissionScreen);
