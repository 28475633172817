import { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./user-permissions-label.styles";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { Role } from "@shared/enum/role.enum";
import { Version } from "@shared/enum/version.enum";

export interface UserPermissionsLabelProps
  extends AppWithStyles<typeof styles> {
  permission: Array<string>;
  role: Role;
}

const UserPermissionsLabel: FC<UserPermissionsLabelProps> = ({
  permission,
  role,
}: UserPermissionsLabelProps) => {
  let backgroundColorV1 = "#D52037";
  let backgroundColorV2 = "#D52037";
  if (role === Role.ADMIN) {
    backgroundColorV1 = "#20B957";
    backgroundColorV2 = "#20B957";
  } else {
    if (permission.includes(Version.V1)) {
      backgroundColorV1 = "#20B957";
    }
    if (permission.includes(Version.V2)) {
      backgroundColorV2 = "#20B957";
    }
  }

  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <Box
        sx={{
          padding: "5px",
          backgroundColor: backgroundColorV1,
          color: "#fff",
          fontWeight: 600,
          borderRadius: "5px",
          fontSize: "16px",
          display: "flex",
          width: "40px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`V1`}
      </Box>
      <Box
        sx={{
          padding: "5px",
          backgroundColor: backgroundColorV2,
          color: "#fff",
          fontWeight: 600,
          borderRadius: "5px",
          fontSize: "16px",
          display: "flex",
          width: "40px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`V2`}
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(UserPermissionsLabel);
