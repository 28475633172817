import React, { FC, KeyboardEvent } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./phone-form.styles";
import { StyledButton } from "shared/components/buttons/styled-button";
import { t } from "@lingui/macro";
import { AuthFormLayout } from "@shared/components/layouts/auth-form-layout";
import {
  IPhoneNumberForm,
  PhoneNumberFieldsResolver,
  PhoneNumberFormFields,
} from "@ui/auth/login/components/phone-form/phone-form.validator";
import { useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import { StyledCheckbox } from "@shared/components/styled-checkbox";
import { InputPhone } from "@shared/components/input-phone";
import { Link } from "@mui/material";
import baseTheme from "@shared/theme/base-theme";

export interface PhoneFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (formData: IPhoneNumberForm) => void;
  error?: string;
  defaultValue?: string;
  isCheckedPrivacyPolicy: boolean;
  isCheckedTermsOfUse: boolean;
  setIsCheckedPrivacyPolicy: (isChecked: boolean) => void;
  setIsCheckedTermsOfUse: (isChecked: boolean) => void;
}

const PhoneForm: FC<PhoneFormProps> = ({
  classes,
  onSubmit,
  error,
  defaultValue,
  isCheckedPrivacyPolicy,
  isCheckedTermsOfUse,
  setIsCheckedPrivacyPolicy,
  setIsCheckedTermsOfUse,
}: PhoneFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PhoneNumberFormFields>({ resolver: PhoneNumberFieldsResolver });

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: IPhoneNumberForm) => {
    onSubmit(data);
  };

  return (
    <AuthFormLayout title={t`Your phone number`}>
      <Box
        className={classes.form}
        component={"form"}
        autoComplete="off"
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => onKeyPress(e)}
      >
        <InputPhone
          label={t`Enter your phone number`}
          defaultValue={defaultValue || ""}
          controls={register(nameOf<IPhoneNumberForm>("phoneNumber"))}
          resetValidation={() => {}}
          hasError={!!errors.phoneNumber || !!error}
          errors={error}
        />
        <StyledCheckbox
          checked={isCheckedPrivacyPolicy}
          label={t`I have read and accept the Privacy Policy and consent to the processing of my personal data`}
          onChange={() => setIsCheckedPrivacyPolicy(!isCheckedPrivacyPolicy)}
          link={
            <Link
              target={"_blank"}
              sx={{
                color: baseTheme.palette.text.secondary,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href={"https://gazer.com/en/privacy_policy"}
            >{t`Read`}</Link>
          }
        />
        <StyledCheckbox
          checked={isCheckedTermsOfUse}
          label={t`I have read and accept the terms of use`}
          onChange={() => setIsCheckedTermsOfUse(!isCheckedTermsOfUse)}
          link={
            <Link
              target={"_blank"}
              sx={{
                color: baseTheme.palette.text.secondary,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href={"https://gazer.com/en/user_agreement"}
            >{t`Read`}</Link>
          }
        />
        <StyledButton
          label={t`Next`}
          disabled={!isCheckedPrivacyPolicy || !isCheckedTermsOfUse}
          onClick={() => {}}
          type={"submit"}
        />
      </Box>
    </AuthFormLayout>
  );
};

export default appWithStyles(styles)(PhoneForm);
