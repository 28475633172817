import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function CoursesIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74658 18.6847H4.79364H21.6661V8.23432C21.6661 6.16524 21.6637 4.7222 21.5144 3.6334C21.3692 2.57571 21.1037 2.01561 20.6965 1.61667C20.2893 1.21773 19.7177 0.957628 18.6381 0.815428C17.5268 0.669041 16.0539 0.666748 13.942 0.666748H8.05693C6.7213 0.666748 5.64124 0.667668 4.74658 0.705375V18.6847ZM2.53966 0.969849C1.96276 1.12058 1.59116 1.3337 1.30234 1.61668C0.89515 2.01562 0.62967 2.57572 0.484524 3.63342C0.33511 4.72221 0.332764 6.16525 0.332764 8.23433V19.659C0.85099 19.3042 1.43508 19.0342 2.06475 18.869C2.21671 18.829 2.37414 18.7978 2.53966 18.7733V0.969849ZM4.96417 20.8469H6.58582V24.8557C6.58582 25.253 6.58582 25.4515 6.72538 25.5318C6.86494 25.6121 7.05578 25.5233 7.43748 25.3457L7.43751 25.3457L9.26521 24.4951C9.39427 24.435 9.45894 24.405 9.52841 24.405C9.59787 24.405 9.66241 24.435 9.79161 24.4951L11.6192 25.3457L11.6195 25.3458C12.001 25.5233 12.1918 25.612 12.3313 25.5318C12.4709 25.4515 12.4709 25.253 12.4709 24.8557V24.8557V20.8469H21.6652C21.6604 22.3543 21.6364 23.4785 21.5145 24.3667C21.3693 25.4245 21.1039 25.9846 20.6967 26.3835C20.2895 26.7825 19.7179 27.0425 18.6383 27.1847C17.5269 27.3311 16.054 27.3334 13.9421 27.3334H8.05709C5.94521 27.3334 4.47231 27.3311 3.36099 27.1847C2.28141 27.0425 1.70973 26.7825 1.30253 26.3835C0.895341 25.9846 0.629861 25.4245 0.484715 24.3667C0.429741 23.9662 0.394688 23.5177 0.372314 23.0078C0.734128 22.0059 1.57781 21.2353 2.63611 20.9574C3.02171 20.8562 3.52489 20.8469 4.96417 20.8469Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}

export default CoursesIcon;
