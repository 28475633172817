import { appInject, appInjectable } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { dateUtils } from "@shared/utils/date";
import { en, ru, uk } from "make-plural/plurals";

import { messages as uaMessage } from "../../locales/uk/messages";
import { messages as ruMessage } from "../../locales/ru/messages";
import { messages as enMessages } from "../../locales/en/messages";
import {
  IStorageService,
  StorageType,
} from "@shared/interfaces/storage-service.interface";
import { Locale } from "@shared/enum/locale.enum";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { appI18n } from "@core/translations/i18n-provider";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { IConfigService } from "@shared/interfaces/config-service.interface";

const STORED_LOCALE_KEY = "STORED_LOCALE_KEY";

@appInjectable()
export class LocalesViewModel implements ILocalesViewModel {
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);

  private _locale: Locale;

  constructor() {
    this._locale = this.getStoredLocale();

    appMakeObservable(this, {
      _locale: appObservable,
    });
  }

  initialize() {
    appI18n.load({
      [Locale.English]: enMessages,
      [Locale.Ukrainian]: uaMessage,
      [Locale.Russian]: ruMessage,
    });
    appI18n.loadLocaleData({
      [Locale.English]: { plurals: en },
      [Locale.Ukrainian]: { plurals: uk },
      [Locale.Russian]: { plurals: ru },
    });
    this.setLocale(this._locale);
  }

  get locale(): Locale {
    return this._locale;
  }

  setLocale(locale: Locale) {
    this._locale = locale;
    appI18n.activate(locale as string);
    dateUtils.locale(locale as string);
    this.setStoredLocale(this._locale);
  }

  toggleLocales() {
    const locales = Object.values(Locale);
    const currentIndex = locales.indexOf(appI18n.locale as Locale);
    const nextIndex = (currentIndex + 1) % locales.length;
    const nextLocale = locales[nextIndex];

    this.setLocale(nextLocale as Locale);
  }

  private getStoredLocale = (): Locale => {
    const storedLocale = this.storageService.get(
      StorageType.localStorage,
      STORED_LOCALE_KEY,
    );
    if (storedLocale) {
      return storedLocale as Locale;
    }
    return (this.configService.defaultLocale as Locale) || Locale.English;
  };

  private setStoredLocale = (locale: Locale) => {
    this.storageService.set(
      StorageType.localStorage,
      STORED_LOCALE_KEY,
      locale,
    );
  };

  //Erase data protocol - happens on logout
  onDataReset = () => {
    this.storageService.remove(StorageType.localStorage, STORED_LOCALE_KEY);
    this._locale = this.getStoredLocale();
  };
}
