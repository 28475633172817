import { createTheme, ThemeOptions } from "@mui/material/styles";
import { components } from "@shared/theme/overrides";
import baseTheme from "@shared/theme/base-theme";
import { typography } from "@shared/theme/typography";

const theme = createTheme({
  ...baseTheme,
  typography,
  components,
} as ThemeOptions);

export default theme;
