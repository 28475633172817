export const controlsFunctionsList = [
  "Analog control of central locking",
  "Analog hazard light control",
  "Central locking unlock",
  "Central locking lock",
  "Locking the central locking system using the factory security system",
  "Unlocking the central locking system using the factory security system",
  "Sequential unlocking of central locking",
  "Trunk opening",
  "Windows closure",
  "Mirrors folding",
  "Sunroof closure",
  "Control of hazard lights",
  "Autonomous heater control",
  "Horn activation",
  "Simulated driver door opening",
  "Engine blocking",
  "Engine start block",
  "Keyless access block",
  "Automatic transmission block",
  "Engine start",
  "Bypassing the factory immobilizer system",
  "Steering wheel heating control",
  "Seat heating control",
  "Mirrors and windows heating control",
  "Turning off the headlights",
  "DTC reading",
];
