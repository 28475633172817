import { BaseModel } from "@core/models/base-model";

export interface CarBrandListDto {
  brand: string;
}

export class CarBrandListModel extends BaseModel<CarBrandListDto> {
  private brand?: string;

  constructor(data: CarBrandListDto) {
    super();

    this.update(data);
  }

  get asJson(): CarBrandListDto {
    return {
      brand: this.brand || "",
    };
  }
}
