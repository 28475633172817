import { t } from "@lingui/macro";

export type MacosInstallationItem = {
  imageUrl: string;
  title: string;
  description: string;
  action: string;
};

export type MacosInstallationStep = Array<MacosInstallationItem>;

export const getMacosInstallationSteps = (): Array<MacosInstallationStep> => {
  return [
    [
      {
        title: "",
        description: t`On the application installation file, right-click and select`,
        action: "Open",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_1.png`,
      },
      {
        title: "",
        description: t`Select`,
        action: "Open",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_2.png`,
      },
    ],
    [
      {
        title: "Introduction:",
        description: t`Select`,
        action: "Continue",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_3.png`,
      },
      {
        title: "License:",
        description: t`Select`,
        action: "Continue",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_4.png`,
      },
    ],
    [
      {
        title: "License:",
        description: t`Select`,
        action: "Agree",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_5.png`,
      },
      {
        title: "Installation Type:",
        description: t`Select`,
        action: "Install",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_6.png`,
      },
    ],
    [
      {
        title: "Installation Type:",
        description: t`If necessary, enter your account password and select`,
        action: "Install Software",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_7.png`,
      },
      {
        title: "Installation:",
        description: t`If necessary, allow access to the folder where the installation file is located by selecting`,
        action: "OK",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_8.png`,
      },
    ],
    [
      {
        title: "Installation:",
        description: t`If necessary, allow access to modify applications by selecting`,
        action: "OK",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_9.png`,
      },
      {
        title: "Driver/Introduction:",
        description: t`In the opened driver installation window, select`,
        action: "OK",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_10.png`,
      },
    ],
    [
      {
        title: "Driver/License:",
        description: t`Select`,
        action: "Continue",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_11.png`,
      },
      {
        title: "Driver/License:",
        description: t`Select`,
        action: "Agree",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_12.png`,
      },
    ],
    [
      {
        title: "Driver/Info:",
        description: t`Select`,
        action: "Continue",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_13.png`,
      },
      {
        title: "Driver/Install:",
        description: t`If necessary, enter your account password and select`,
        action: "Install Software",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_14.png`,
      },
    ],
    [
      {
        title: "Driver/Install:",
        description: t`If a security warning appears, go to settings by selecting`,
        action: "Open System Settings",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_15.png`,
      },
      {
        title: "Driver/Install:",
        description: t`In the row of the blocked application, select`,
        action: "Allow",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_16.png`,
      },
    ],
    [
      {
        title: "Driver/Install:",
        description: t`If necessary, enter your account password and select`,
        action: "Modify Settings",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_17.png`,
      },
      {
        title: "Driver/Install:",
        description: t`Close or minimize the settings window`,
        action: "",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_18.png`,
      },
    ],
    [
      {
        title: "Driver/Summary:",
        description: t`Complete the driver installation by selecting`,
        action: "Close",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_19.png`,
      },
      {
        title: "Summary:",
        description: t`Complete the application installation by selecting`,
        action: "Close",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_20.png`,
      },
    ],
    [
      {
        title: "",
        description: t`To open the installed application, open`,
        action: "Launchpad",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_21.png`,
      },
      {
        title: "Summary:",
        description: t`Find and open the`,
        action: "Gazer Installer",
        imageUrl: `${window.location.origin}/assets/installation-mac/img_22.png`,
      },
    ],
  ] as Array<MacosInstallationStep>;
};
