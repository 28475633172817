import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    buttons: {
      height: "65vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiButton-root + .MuiButton-root": {
        marginTop: "15px",
      },
    },
  });
}
