import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { appInject } from "@core/di/utils";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { SortOrder } from "@shared/enum/sort-order.enum";
import { UseQueryResult } from "react-query";
import { PaginationResponseModel } from "@shared/models/pagination-response.model";
import { UserDetailsDto } from "@shared/models/users/user-details.dto";
import { Role } from "@shared/enum/role.enum";
import { UserStatus } from "@shared/enum/user-status.enum";
import { UpdateUserByAdminRequestDto } from "@shared/dto/admin/update-user-by-admin-request.dto";
import { UpdateUserPermissionRequestDto } from "@shared/dto/admin/update-user-permission-request.dto";

export class UsersManagementViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _query: UseQueryResult<
    PaginationResponseModel<UserDetailsDto>,
    unknown
  > | null = null;

  private _sortOrder = SortOrder.ASC;
  private _sortField = "";

  private _pageNumber: number = 1;
  private _pageSize: number = 25;

  private _filterRole: Role | string = "";
  private _filterStatus: UserStatus | string = "";

  private _searchString: string = "";
  private _timeout: NodeJS.Timeout | null = null;

  private _selectedUser: UserDetailsDto | null = null;
  private _isOpenUserDialog: boolean = false;

  constructor() {
    appMakeObservable(this, {
      _sortOrder: appObservable,
      _sortField: appObservable,
      _pageNumber: appObservable,
      _pageSize: appObservable,
      _filterRole: appObservable,
      _filterStatus: appObservable,
      _searchString: appObservable,
      _selectedUser: appObservable,
      _isOpenUserDialog: appObservable,
    });
  }

  setQuery(
    query: UseQueryResult<PaginationResponseModel<UserDetailsDto>, unknown>,
  ) {
    this._query = query;
    return this;
  }

  get sortOrder(): SortOrder {
    return this._sortOrder;
  }

  get sortField(): string {
    return this._sortField;
  }

  setSortField(sortField: string) {
    const isAsc =
      sortField === this.sortField && this.sortOrder === SortOrder.ASC;
    this._sortOrder = isAsc ? SortOrder.DESC : SortOrder.ASC;
    this._sortField = sortField;
    this._query?.refetch();
  }

  get pageSize(): number {
    return this._pageSize;
  }

  setPageSize(value: number) {
    this._pageSize = value;
    this._pageNumber = 1;
    this._query?.refetch();
  }

  get pageNumber(): number {
    return this._pageNumber;
  }

  setPageNumber(value: number) {
    this._pageNumber = value;
    this._query?.refetch();
  }

  get filterStatus(): UserStatus | string {
    return this._filterStatus;
  }

  setFilterStatus(value: UserStatus | string) {
    this._filterStatus = value;
    this._pageNumber = 1;
    this._query?.refetch();
  }

  get filterRole(): Role | string {
    return this._filterRole;
  }

  setFilterRole(value: Role | string) {
    this._filterRole = value;
    this._pageNumber = 1;
    this._query?.refetch();
  }

  get searchString(): string {
    return this._searchString;
  }

  setSearchString(value: string) {
    this._searchString = value;
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    if (value.length > 2 || value.length === 0) {
      this._timeout = setTimeout(() => {
        this._pageNumber = 1;
        this._query?.refetch();
        this._timeout = null;
      }, 2000);
    }
  }

  get selectedUser(): UserDetailsDto | null {
    return this._selectedUser;
  }

  setSelectedUser(value: UserDetailsDto | null) {
    this._selectedUser = value;
    setTimeout(() => {
      this._isOpenUserDialog = true;
    }, 0);
  }

  get isOpenUserDialog(): boolean {
    return this._isOpenUserDialog;
  }

  closeUserDialog() {
    this._isOpenUserDialog = false;
    this._selectedUser = null;
  }

  async getUsersList() {
    return this.usersService.getUsersList({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      role: this._filterRole === "all" ? undefined : (this.filterRole as Role),
      status:
        this._filterStatus === "all"
          ? undefined
          : (this.filterStatus as UserStatus),
      search: this.searchString,
    });
  }

  async updateUser(data: { id: string } & UpdateUserByAdminRequestDto) {
    const options = {} as UpdateUserByAdminRequestDto;
    if (data.status) {
      options.status = data.status;
    }
    if (data.role) {
      options.role = data.role;
    }
    this._isOpenUserDialog = false;
    this._selectedUser = null;
    return this.usersService.updateUserByAdmin(data.id, options);
  }

  async updatePermission(
    data: { id: string } & UpdateUserPermissionRequestDto,
  ) {
    const options = {
      permission: data.permission,
      isAdding: data.isAdding,
    } as UpdateUserPermissionRequestDto;
    this._isOpenUserDialog = false;
    this._selectedUser = null;
    return this.usersService.updatePermissionsByAdmin(data.id, options);
  }
}
