import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { appInject } from "@core/di/utils";
import { UseQueryResult } from "react-query";
import { ICarsService } from "@shared/interfaces/cars-service.interface";
import { Version } from "@shared/enum/version.enum";
import { CarModelListModel } from "@shared/models/cars/car-model-list.model";
import { IScrollService } from "@shared/interfaces/scroll-service.interface";

export class ModelsViewModel {
  private carsService = appInject<ICarsService>(DI_TOKENS.carsService);
  private scrollService = appInject<IScrollService>(DI_TOKENS.scrollService);
  private _query: UseQueryResult<CarModelListModel[], unknown> | null = null;
  private _pathname: string = "";
  private _version: string = "";
  private _brand: string = "";
  private _searchString: string = "";
  private _timeout: NodeJS.Timeout | null = null;

  constructor({
    version,
    brand,
    pathname,
  }: {
    version: string;
    brand: string;
    pathname: string;
  }) {
    appMakeObservable(this, {
      _searchString: appObservable,
    });
    this._brand = brand;
    this._version = version;
    this._pathname = pathname;
  }

  setQuery(
    query: UseQueryResult<CarModelListModel[], unknown>,
  ): ModelsViewModel {
    this._query = query;
    return this;
  }

  get searchString(): string {
    return this._searchString || "";
  }

  setSearchString(value: string) {
    this._searchString = value;
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._query?.refetch();
      this._timeout = null;
    }, 2000);
  }

  async getModelsList() {
    if (!this.searchString) {
      this._searchString = this.getDefaultSearchString();
    }
    return await this.carsService.getModelsList({
      version: this._version as Version,
      brand: this._brand || "",
      search: this.searchString,
    });
  }

  private getDefaultSearchString() {
    const { conditions } = this.scrollService.getStoredPosition(this._pathname);
    return conditions || "";
  }
}
