import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  FormControl,
  InputAdornment,
  Menu,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Flag } from "./components/flag";
import { PhoneConverter } from "@shared/utils/phone-converter";
import { UseFormRegisterReturn } from "react-hook-form";
import { styles } from "./input-phone.styles";
import { appWithStyles, AppWithStyles } from "@shared/material";
import { rawAllCountries } from "@shared/components/input-phone/all-countries";
import { CountryListItem } from "./components/country-list-item";
import classNames from "classnames";
import { t } from "@lingui/macro";

export type InputPhoneFieldProps = AppWithStyles<typeof styles> &
  TextFieldProps & {
    resetValidation?: () => void;
    children?: React.ReactNode;
    controls: UseFormRegisterReturn;
    defaultValue?: string;
    hasError?: boolean;
    errors?: string;
    label?: string;
    boldLabel?: string;
    withoutFocus?: boolean;
  };

const InputPhoneComponent: React.FC<InputPhoneFieldProps> = ({
  classes,
  controls,
  resetValidation,
  errors,
  autoFocus,
  maxRows,
  defaultValue,
  hasError,
  label,
  boldLabel,
  withoutFocus,
}) => {
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>("+");

  const [anchorEl, setAnchorEl] = useState(null);

  const phoneConverter = useMemo(() => new PhoneConverter(), []);

  const phoneInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (defaultValue) {
      handleUpdatePhone(defaultValue);
    }
    if (!withoutFocus) {
      focusToPhoneInput();
    }
  }, [defaultValue]);

  const selectCountry = (iso2: string, code: string) => {
    setCountryCode(iso2);
    setFullPhoneNumber(`+${code}`);
    setFormattedPhoneNumber(`+${code}`);
    setAnchorEl(null);
    focusToPhoneInput();
  };

  const focusToPhoneInput = () => {
    setTimeout(() => {
      phoneInputRef.current?.focus();
      const len = phoneInputRef.current?.value.length || 0;
      phoneInputRef.current?.setSelectionRange(len, len);
    }, 0);
  };

  const isSelectedCountry = (iso2: string): boolean => {
    return iso2 === countryCode;
  };

  const handleUpdatePhone = (phoneNumberValue: string) => {
    const { countryCode, phoneCode, phoneNumber } =
      phoneConverter.parsePhoneNumber(phoneNumberValue);

    setCountryCode(countryCode || "");
    setFullPhoneNumber(phoneNumberValue);
    controls.onChange({
      target: { name: "phoneNumber", value: phoneNumberValue },
      type: "text",
    });

    if (phoneCode && phoneNumber) {
      setFormattedPhoneNumber(`+${phoneCode} ${phoneNumber}`);
    } else {
      setFormattedPhoneNumber(phoneNumberValue);
    }
  };

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (resetValidation) {
      resetValidation();
    }
    handleUpdatePhone(`+${e.target.value.replace(/[^\d.]/g, "")}`);
  };

  const getFlagAndronment = () => {
    return (
      <InputAdornment
        sx={{
          cursor: "pointer",
        }}
        position="start"
        onClick={() => setAnchorEl(phoneInputRef.current as any)}
      >
        <Flag code={countryCode} />
      </InputAdornment>
    );
  };

  return (
    <FormControl
      fullWidth
      className={classNames(classes.root, { [classes.error]: hasError })}
    >
      {label && !boldLabel && !hasError ? (
        <div className={classes.label}>{label}</div>
      ) : (
        <></>
      )}
      {boldLabel && !hasError ? (
        <div className={classes.boldLabel}>{boldLabel}</div>
      ) : (
        <></>
      )}
      {hasError ? (
        <div className={classes.errorText}>
          {errors || t`Invalid phone number entered`}
        </div>
      ) : (
        <></>
      )}
      <div className={classes.input}>
        <TextField
          className={classes.inputPhone}
          autoFocus={autoFocus}
          onChange={onChangePhone}
          variant={"outlined"}
          inputProps={{
            inputMode: "numeric",
          }}
          type={"tel"}
          multiline={false}
          maxRows={maxRows || 0}
          inputRef={phoneInputRef}
          value={formattedPhoneNumber}
          InputProps={{
            startAdornment: getFlagAndronment(),
          }}
          placeholder={""}
        />
      </div>
      <input
        {...controls}
        className={classes.hidden}
        type={"text"}
        name={"phoneNumber"}
        value={fullPhoneNumber}
      />
      <Menu
        id="country-menu"
        className={classes.dropdown}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {rawAllCountries.map((country, index) => {
          return (
            <CountryListItem
              key={`preferred_${country[2]}_${index}`}
              selected={isSelectedCountry(country[2] as string) || false}
              onSelectItem={(iso: string, code: string) =>
                selectCountry(iso, code)
              }
              name={country[0] as string}
              iso2={country[2] as string}
              dialCode={country[3] as string}
              localization={""}
            />
          );
        })}
      </Menu>
    </FormControl>
  );
};

export default appWithStyles(styles)(InputPhoneComponent);
