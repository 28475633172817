import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./add-new-section-button.styles";

export interface AddNewSectionButtonProps extends AppWithStyles<typeof styles> {
  onClick: () => void;
  label: string;
}

const AddNewSectionButton: FC<AddNewSectionButtonProps> = ({
  classes,
  onClick,
  label,
}: AddNewSectionButtonProps) => {
  return (
    <Box
      sx={{
        height: "50px",
        width: "100%",
        border: "1px dashed #515A6C",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {label}
    </Box>
  );
};

export default appWithStyles(styles)(AddNewSectionButton);
