import { BaseModel } from "@core/models/base-model";

export interface CarModelListDto {
  model: string;
  years: string;
  imageUrl: string;
}

export class CarModelListModel extends BaseModel<CarModelListDto> {
  private model?: string;
  private years?: string;
  private imageUrl?: string;

  constructor(data: CarModelListDto) {
    super();

    this.update(data);
  }

  get asJson(): CarModelListDto {
    return {
      model: this.model || "",
      years: this.years || "",
      imageUrl: this.imageUrl || "",
    };
  }
}
