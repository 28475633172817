import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles, Box } from "@shared/material";
import { styles } from "./registration.styles";
import { PhoneNumber } from "ui/auth/registration/components/phone-form";
import { OTPView } from "ui/auth/login/components/otp-view";
import { AuthLayout } from "@shared/components/layouts/auth-layout";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { RegistrationViewModel } from "./registration.vm";
import { IPhoneNumberForm } from "@ui/auth/login/components/phone-form/phone-form.validator";
import { IOTPView } from "@ui/auth/login/components/otp-view/otp-view.validator";
import { Loader } from "@shared/components/loader";
import { useMutation } from "react-query";
import { UseMutationResult } from "react-query/types/react/types";
import { useNavigate } from "react-router-dom";
import { RegistrationScreen } from "@ui/auth/registration/registration-screen.enum";
import { NameForm } from "./components/name-form";
import { INameForm } from "@ui/auth/registration/components/name-form/name-form.validator";
import { RegisterUserDto } from "@shared/types/register-user.dto";
import { ROUTES } from "../../../routing/routes";
import { t } from "@lingui/macro";
import { ServerHttpError } from "@shared/types/error";

export type RegistrationProps = AppWithStyles<typeof styles>;

const Registration: FC<RegistrationProps> = appObserver(
  ({ classes }: RegistrationProps) => {
    const navigate = useNavigate();

    const [lastLoginTryDate, setLastLoginTryDate] = useState<Date | null>(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otpError, setOtpError] = useState<string>("");
    const [phoneError, setPhoneError] = useState<string>("");

    const [isCheckedPrivacyPolicy, setIsCheckedPrivacyPolicy] = useState(false);
    const [isCheckedTermsOfUse, setIsCheckedTermsOfUse] = useState(false);

    const sendCodeMutation: UseMutationResult<
      void,
      unknown,
      IPhoneNumberForm,
      unknown
    > = useMutation(
      (data: IPhoneNumberForm): Promise<void> => {
        setPhoneNumber(data.phoneNumber || "");
        return $vm.sendCode(data.phoneNumber || "");
      },
      {
        onSuccess: () => {
          setOtpError("");
          setPhoneError("");
          setLastLoginTryDate(new Date());
          setIsCheckedPrivacyPolicy(true);
          setIsCheckedTermsOfUse(true);
          $vm.setActiveScreen(RegistrationScreen.OTP_VIEW);
        },
        onError: (err) => {
          $vm.setActiveScreen(RegistrationScreen.PHONE);
        },
      },
    );

    const registerMutation: UseMutationResult<
      void,
      unknown,
      INameForm & IPhoneNumberForm & IOTPView,
      unknown
    > = useMutation(
      (data: INameForm & IPhoneNumberForm & IOTPView): Promise<void> =>
        $vm.register(data as RegisterUserDto),
      {
        onSuccess: () => {
          navigate(ROUTES.home);
        },
        onError: (err: ServerHttpError) => {
          if (err.status === 401) {
            setOtpError(t`Invalid Code`);
          } else if (err.status === 409) {
            $vm.setActiveScreen(RegistrationScreen.PHONE);
            setPhoneError(t`You are already registered, please use login`);
            setTimeout(() => {
              navigate(ROUTES.login);
            }, 4000);
          } else {
            setOtpError(err.message);
          }
        },
      },
    );

    const $vm = useMemo(
      () => new RegistrationViewModel({ sendCodeMutation, registerMutation }),
      [],
    );

    const [_, render] = useState(0);
    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );
    appReaction(
      () => localesViewModel.locale,
      () => render(_ + 1),
    );

    const onResendCode = () => {
      sendCodeMutation.mutate({ phoneNumber });
    };

    const handleDisableOtp = () => {
      $vm.setActiveScreen(RegistrationScreen.PHONE);
    };

    const loader = (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

    let activeForm = <></>;
    switch ($vm.activeScreen) {
      case RegistrationScreen.NAME:
        activeForm = (
          <NameForm onSubmit={(data: INameForm) => $vm.onSubmitName(data)} />
        );
        break;
      case RegistrationScreen.PHONE:
        activeForm = (
          <PhoneNumber
            defaultValue={$vm.phoneNumber}
            onSubmit={(data: IPhoneNumberForm) => $vm.onSubmitPhoneNumber(data)}
            isCheckedPrivacyPolicy={isCheckedPrivacyPolicy}
            isCheckedTermsOfUse={isCheckedTermsOfUse}
            setIsCheckedPrivacyPolicy={(e: boolean) =>
              setIsCheckedPrivacyPolicy(e)
            }
            setIsCheckedTermsOfUse={(e: boolean) => setIsCheckedTermsOfUse(e)}
            error={phoneError}
          />
        );
        break;
      case RegistrationScreen.OTP_VIEW:
        activeForm = (
          <OTPView
            onSubmit={(data: IOTPView) => $vm.onSubmitCode(data)}
            isLoading={registerMutation.isLoading || sendCodeMutation.isLoading}
            timerDate={lastLoginTryDate as Date}
            timerDelayMinutes={2}
            error={otpError}
            onResend={onResendCode}
            onBack={handleDisableOtp}
          />
        );
        break;
      default:
        break;
    }

    if (sendCodeMutation.isLoading) {
      activeForm = loader;
    }

    return <AuthLayout>{activeForm}</AuthLayout>;
  },
);

export default appWithStyles(styles)(Registration);
