import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    title: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.36px",
      margin: "10px 20px",
    },
  });
}
