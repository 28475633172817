import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { DI_TOKENS } from "@shared/constants/di";

import { jwtDecode } from "jwt-decode";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { AuthTokens } from "@shared/types/auth-tokens";
import { LocalStorageKey } from "@shared/enum/local-storage-key.enum";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { RegisterUserDto } from "@shared/types/register-user.dto";
import { DecodedToken } from "@shared/types/decoded-tokens";
import { Subject } from "rxjs";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { Role } from "@shared/enum/role.enum";

@appInjectable()
export class AuthService extends BaseService implements IAuthService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private httpClientService = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );

  private _baseURL = "";
  private _userInfo: DecodedToken | null = null;
  private _userPermissions: Array<string> | null = null;
  private _authTokens: AuthTokens | null = null;
  private _onChangeAuthStatus: Subject<boolean> = new Subject<boolean>();

  constructor() {
    super();
    appMakeObservable(this, {
      _userInfo: appObservable,
      _userPermissions: appObservable,
      _authTokens: appObservable,
    });
    this._baseURL = this.configService.apiUrl;
  }

  get user() {
    return this.usersService.me;
  }

  get tokens(): AuthTokens | null {
    return this._authTokens;
  }

  get onChangeAuthStatus() {
    return this._onChangeAuthStatus.asObservable();
  }

  private emitChangeAuthStatus(isLoggedIn: boolean) {
    this._onChangeAuthStatus.next(isLoggedIn);
  }

  get isLoggedIn() {
    return !!this._userInfo && !!this._userInfo.sub;
  }

  get isAdmin() {
    return (
      !!this._userInfo &&
      !!this._userInfo.role &&
      this._userInfo.role === Role.ADMIN
    );
  }

  hasPermission(permission: string) {
    if (this.isAdmin) return true;
    if (this._userPermissions) {
      return Array.from(this._userPermissions).includes(permission);
    } else {
      return false;
    }
  }

  async checkAuthorization() {
    this._authTokens = this.getStoredAuthData();
    if (!this._authTokens) return;
    try {
      await this.refreshToken();
    } catch {
      this.logout();
      return;
    }
  }

  private getStoredAuthData(): AuthTokens | null {
    const dataJson = localStorage.getItem(LocalStorageKey.AUTH_KEY);
    if (!dataJson) return null;
    try {
      const data = JSON.parse(dataJson) as AuthTokens;
      if (data.accessToken && data.refreshToken) {
        return data;
      }
      return data as AuthTokens;
    } catch {
      /* empty */
    }
    return null;
  }

  private storeAuthData(data: AuthTokens) {
    localStorage.setItem(LocalStorageKey.AUTH_KEY, JSON.stringify(data));
  }

  private handleNewAuthData(data: AuthTokens) {
    this.storeAuthData(data);
    this._authTokens = data;
    const parsedData = jwtDecode<DecodedToken>(this._authTokens.accessToken);
    this._userInfo = parsedData;
    this._userPermissions = parsedData.permissions;
    this.emitChangeAuthStatus(true);
    setTimeout(async () => {
      await this.usersService.getMyUserDetails();
    }, 0);
  }

  async login(phoneNumber: string, code: string): Promise<void> {
    const { data } = await this.httpClientService.post<AuthTokens>(
      "/auth/sign-in",
      {
        phoneNumber,
        code,
      },
      {
        baseURL: this._baseURL,
      },
    );

    if (!data) return;
    this.handleNewAuthData(data);
  }

  async refreshToken(): Promise<void> {
    const { data } = await this.httpClientService.post<AuthTokens>(
      "/auth/refresh-token",
      {
        refreshToken: this._authTokens?.refreshToken,
      },
      {
        baseURL: this._baseURL,
      },
    );

    if (!data) return;
    this.handleNewAuthData(data);
  }

  async sendPhoneVerification(phoneNumber: string): Promise<void> {
    await this.httpClientService.post<AuthTokens>(
      "/auth/send-code",
      {
        phoneNumber: phoneNumber,
      },
      {
        baseURL: this._baseURL,
      },
    );
  }

  async register(registrationData: RegisterUserDto): Promise<void> {
    const { data } = await this.httpClientService.post<AuthTokens>(
      "/auth/sign-up",
      registrationData,
      {
        baseURL: this._baseURL,
      },
    );
    if (!data) return;
    this.handleNewAuthData(data);
  }

  logout() {
    this._authTokens = null;
    this._userInfo = null;
    this._userPermissions = null;
    this.usersService.onLogout();
    localStorage.removeItem(LocalStorageKey.AUTH_KEY);
    this.emitChangeAuthStatus(false);
  }
}
