import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function CoursesIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30234 4.11655C6.59116 3.83358 6.96276 3.62046 7.53966 3.46973V21.2732C7.37414 21.2977 7.21671 21.3289 7.06475 21.3689C6.43508 21.5341 5.85099 21.8041 5.33276 22.1589V10.7342C5.33276 8.66513 5.33511 7.22209 5.48452 6.1333C5.62967 5.07559 5.89515 4.5155 6.30234 4.11655ZM9.96417 23.3467H11.5858V27.3555C11.5858 27.7529 11.5858 27.9514 11.7254 28.0317C11.8649 28.1119 12.0558 28.0231 12.4375 27.8456L12.4375 27.8455L14.2652 26.995C14.3943 26.9349 14.4589 26.9049 14.5284 26.9049C14.5979 26.9049 14.6624 26.9349 14.7916 26.995L16.6192 27.8455L16.6195 27.8457C17.001 28.0232 17.1918 28.1119 17.3313 28.0317C17.4709 27.9514 17.4709 27.7529 17.4709 27.3556V27.3555V23.3467H26.6652C26.6604 24.8542 26.6364 25.9783 26.5145 26.8666C26.3693 27.9243 26.1039 28.4845 25.6967 28.8834C25.2895 29.2823 24.7179 29.5423 23.6383 29.6846C22.5269 29.831 21.054 29.8333 18.9421 29.8333H13.0571C10.9452 29.8333 9.47231 29.831 8.36099 29.6846C7.28141 29.5423 6.70973 29.2823 6.30253 28.8834C5.89534 28.4845 5.62986 27.9243 5.48471 26.8666C5.42974 26.4661 5.39469 26.0175 5.37231 25.5077C5.73413 24.5058 6.57781 23.7351 7.63611 23.4573C8.02171 23.3561 8.52489 23.3467 9.96417 23.3467Z"
        fill="#E8EAED"
      />
      <path
        d="M9.74658 21.1845C9.76221 21.1845 9.77789 21.1845 9.79364 21.1845H26.6661V10.7341C26.6661 8.665 26.6637 7.22196 26.5144 6.13316C26.3692 5.07546 26.1037 4.51537 25.6965 4.11642C25.2893 3.71749 24.7177 3.45738 23.6381 3.31518C22.5268 3.1688 21.0539 3.1665 18.942 3.1665H13.0569C11.7213 3.1665 10.6412 3.16742 9.74658 3.20513V21.1845Z"
        fill="#D52037"
      />
    </SvgIcon>
  );
}

export default CoursesIcon;
