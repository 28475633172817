import React, { FC, ReactNode } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./auth-layout.syles";
import { AppBar, Box, Toolbar } from "@mui/material";
import { PublicLocales } from "@shared/components/locales/public-locales";
import { appObserver } from "@core/state-management/utils";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { HeaderLogoIcon } from "shared/images/header-logo";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routing/routes";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import Light from "@shared/images/light.png";

export interface AuthLayoutProps extends AppWithStyles<typeof styles> {
  children: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = appObserver(
  ({ children }: AuthLayoutProps) => {
    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    const navigate = useNavigate();

    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          backgroundImage: `url(${Light})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 30%",
          backgroundPositionY: "100%",
          position: "relative",
        }}
      >
        <AppBar position="static" color={"transparent"}>
          <Toolbar>
            <HeaderLogoIcon
              onClick={() => navigate(ROUTES.welcomeScreen)}
            ></HeaderLogoIcon>
          </Toolbar>
        </AppBar>
        {children}
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
              top: "10px",
              right: "135px",
            },
          }}
        >
          <PublicLocales
            locale={localesViewModel.locale}
            onChange={(local) => localesViewModel.setLocale(local)}
          />
        </Box>
      </Box>
    );
  },
);

export default appWithStyles(styles)(AuthLayout);
