import React, { FC } from "react";
import {
  appWithStyles,
  AppWithStyles,
  Box,
  useMediaQuery,
} from "@shared/material";
import {
  styles,
  tableCellStyles,
  tableHeaderStyles,
} from "./can-functions.styles";
import baseTheme from "@shared/theme/base-theme";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { CanFunctionModel } from "@shared/models/cars/can-function.model";
import { t } from "@lingui/macro";

export interface CanFunctionsProps extends AppWithStyles<typeof styles> {
  data: Array<CanFunctionModel>;
}

const Empty = () => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        width: "10px",
        height: "3px",
        backgroundColor: "rgba(255,255,255,0.2)",
      }}
    ></Box>
  );
};

const CanFunctions: FC<CanFunctionsProps> = ({
  classes,
  data,
}: CanFunctionsProps) => {
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const isMobile = useMediaQuery(() =>
    baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
  );
  return (
    <Box
      className={classes.root}
      sx={{
        minHeight: "calc(100vh - 770px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 135px 40px 135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            padding: "0px 24px 20px 24px",
            width: "100%",
            overflowX: "auto",
          },
        }}
      >
        <Table
          aria-label="can functions table"
          sx={{
            width: "100%",
          }}
        >
          <TableHead
            sx={{
              marginBottom: "20px",
            }}
          >
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{
                  ...tableHeaderStyles,
                  borderRadius: "10px 0px 0px 10px",
                  paddingLeft: "10px",
                }}
              >{t`Functions`}</TableCell>
              <TableCell
                sx={{ ...tableHeaderStyles, textAlign: "center" }}
              >{t`Executable commands`}</TableCell>
              <TableCell
                sx={{ ...tableHeaderStyles, textAlign: "center" }}
              >{t`Readable parameters`}</TableCell>
              <TableCell
                sx={{ ...tableHeaderStyles, borderRadius: "0px 10px 10px 0px" }}
              >{t`Notes`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  width={isMobile ? "50px" : "30%"}
                  sx={{
                    ...tableCellStyles,
                    paddingLeft: "10px",
                    borderRadius: "10px 0px 0px 10px",
                    backgroundColor:
                      index % 2 !== 0
                        ? "rgba(255,255,255,0.05)"
                        : "transparent",
                  }}
                >
                  {row.asJson.functionName[localesViewModel.locale]}
                </TableCell>
                <TableCell
                  width={isMobile ? "50px" : "20%"}
                  sx={{
                    ...tableCellStyles,
                    fontWeight: 700,
                    textAlign: "center",
                    backgroundColor:
                      index % 2 !== 0
                        ? "rgba(255,255,255,0.05)"
                        : "transparent",
                  }}
                >
                  {row.asJson.executionCommand || <Empty />}
                </TableCell>
                <TableCell
                  width={isMobile ? "50px" : "20%"}
                  sx={{
                    ...tableCellStyles,
                    fontWeight: 700,
                    textAlign: "center",
                    backgroundColor:
                      index % 2 !== 0
                        ? "rgba(255,255,255,0.05)"
                        : "transparent",
                  }}
                >
                  {row.asJson.readableParams || <Empty />}
                </TableCell>
                <TableCell
                  width={isMobile ? "200px" : "30%"}
                  sx={{
                    ...tableCellStyles,
                    borderRadius: "0px 10px 10px 0px",
                    backgroundColor:
                      index % 2 !== 0
                        ? "rgba(255,255,255,0.05)"
                        : "transparent",
                  }}
                >
                  {row.asJson.notes[localesViewModel.locale] || <Empty />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(CanFunctions);
