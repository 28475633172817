import React, { FC, useEffect, useState } from "react";
import { AppWithStyles, appWithStyles, Box, Tabs } from "@shared/material";
import { styles } from "./modifications.styles";
import { t } from "@lingui/macro";
import { useParams } from "react-router-dom";
import { SectionHeader } from "@shared/components/section-header";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { StyledBreadcrumb } from "@shared/components/styled-breadcrumb";
import { NavItem } from "@shared/types/nav-item";
import { ROUTES } from "../../../routing/routes";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { ICarsService } from "@shared/interfaces/cars-service.interface";
import { useMutation, useQuery } from "react-query";
import { Version } from "@shared/enum/version.enum";
import { Loader } from "@shared/components/loader";
import { StyledButton } from "shared/components/buttons/styled-button";
import { ResourceSwitcher } from "@ui/private/modifications/components/resource-switcher";
import {
  ResourceItem,
  ResourceSwitcherItem,
} from "@ui/private/modifications/components/resource-switcher/resource-switcher-items";
import { DownloadingSoftware } from "@ui/private/modifications/components/downloading-software";
import { GetCarDetailsRequestDto } from "@shared/dto/cars/get-car-installation-request.dto";
import { ConnectionDiagram } from "./components/connection-diagram";
import { Header } from "@ui/private/modifications/components/header";
import { CanFunctions } from "@ui/private/modifications/components/can-functions";
import InstallationMap from "./components/installation-map/installation-map";
import { IInstallerViewModel } from "@shared/interfaces/installer-vm.interface";
import { ScrollComponent } from "@shared/components/scroll-component";

export type ModificationsProps = AppWithStyles<typeof styles>;

const Modifications: FC<ModificationsProps> = ({
  classes,
}: ModificationsProps) => {
  const { version, brand, model, years } = useParams();

  const carsService = appInject<ICarsService>(DI_TOKENS.carsService);
  const installerVM = appInject<IInstallerViewModel>(
    DI_TOKENS.installerViewModel,
  );
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const [isActiveDownloadSW, setIsActiveDownloadSW] = useState<boolean>(false);
  const [isActiveCanFunctions, setIsActiveCanFunctions] =
    useState<boolean>(false);
  const [isActiveInstallationsDiagram, setIsActiveInstallationsDiagram] =
    useState<boolean>(false);
  const [isActiveConnectionDiagram, setIsActiveConnectionDiagram] =
    useState<boolean>(false);

  const [activeResourceSwitcherItem, setActiveResourceSwitcherItem] =
    useState<ResourceItem>(ResourceItem.DOWNLOAD_SW);
  const [modificationValue, setModificationValue] = useState<number>(
    ResourceItem.DOWNLOAD_SW,
  );

  useEffect(() => {
    if (version === Version.V1) {
      setActiveResourceSwitcherItem(ResourceItem.DOWNLOAD_SW);
    } else {
      if (isActiveCanFunctions) {
        setActiveResourceSwitcherItem(ResourceItem.CAN_FUNCTIONS);
      } else if (isActiveInstallationsDiagram) {
        setActiveResourceSwitcherItem(ResourceItem.INSTALLATION_DIAGRAM);
      } else if (isActiveConnectionDiagram) {
        setActiveResourceSwitcherItem(ResourceItem.CONNECTION_DIAGRAM);
      }
    }
  }, [
    isActiveCanFunctions,
    isActiveConnectionDiagram,
    isActiveInstallationsDiagram,
  ]);

  const [_, render] = useState(0);
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const handleChangeModification = (event: unknown, newValue: number) => {
    if (modificationValue === newValue) {
      return;
    }
    setModificationValue(newValue);
    if (carsModificationQuery.data && carsModificationQuery.data[newValue]) {
      getCarInstallationMutation.mutate({
        version: version as Version,
        id: carsModificationQuery.data[newValue].asJson.id,
      });
      getCarLinksMutation.mutate({
        version: version as Version,
        id: carsModificationQuery.data[newValue].asJson.id,
      });
      getCanFunctionsMutation.mutate({
        version: version as Version,
        id: carsModificationQuery.data[newValue].asJson.id,
      });
    }
  };

  const resourceSwitcherItems: Array<ResourceSwitcherItem> = [
    {
      label: t`CAN functions`,
      id: ResourceItem.CAN_FUNCTIONS,
      disabled: !isActiveCanFunctions,
    },
    {
      label: t`Connection points`,
      id: ResourceItem.INSTALLATION_DIAGRAM,
      disabled: !isActiveInstallationsDiagram,
    },
    {
      label: t`Connection diagram`,
      id: ResourceItem.CONNECTION_DIAGRAM,
      disabled: !isActiveConnectionDiagram,
    },
  ];

  if (version === Version.V1) {
    resourceSwitcherItems.unshift(
      ...[
        {
          label: t`Download SW`,
          id: ResourceItem.DOWNLOAD_SW,
          disabled: !isActiveDownloadSW,
        },
      ],
    );
  }

  const carsModificationQuery = useQuery(
    ["download-modifications-list"],
    () =>
      carsService.getModificationsList({
        version: version as Version,
        brand: brand || "",
        model: model || "",
        years: years || "",
      }),
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        if (data && data[modificationValue]) {
          getCarInstallationMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
          getCarLinksMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
          getCanFunctionsMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
        }
      },
    },
  );

  const carsModificationMutation = useMutation(
    () =>
      carsService.getModificationsList({
        version: version as Version,
        brand: brand || "",
        model: model || "",
        years: years || "",
      }),
    {
      onSuccess: (data) => {
        if (data && data[modificationValue]) {
          getCarInstallationMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
          getCarLinksMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
          getCanFunctionsMutation.mutate({
            version: version as Version,
            id: data[modificationValue].asJson.id,
          });
        }
      },
    },
  );

  const getCarInstallationMutation = useMutation(
    (data: GetCarDetailsRequestDto) => carsService.getCarInstallation(data),
    {
      onSuccess: (data) => {
        let isActiveCD = false,
          isActiveIM = false;
        if (data.asJson.connectionPointsImg.length) {
          isActiveCD = true;
          setIsActiveInstallationsDiagram(true);
        } else {
          setIsActiveInstallationsDiagram(false);
        }

        if (
          data.asJson.connectionDescription.ru ||
          data.asJson.connectionDescription.en ||
          data.asJson.connectionDescription.uk
        ) {
          isActiveIM = true;
          setIsActiveConnectionDiagram(true);
        } else {
          setIsActiveConnectionDiagram(false);
        }
        if (version === Version.V1) {
          setActiveResourceSwitcherItem(ResourceItem.DOWNLOAD_SW);
        } else {
          if (isActiveCD) {
            setActiveResourceSwitcherItem(ResourceItem.CONNECTION_DIAGRAM);
          } else if (isActiveIM) {
            setActiveResourceSwitcherItem(ResourceItem.INSTALLATION_DIAGRAM);
          }
        }
      },
    },
  );

  const getCarLinksMutation = useMutation(
    (data: GetCarDetailsRequestDto) => carsService.getCarLinks(data),
    {
      onSuccess: (data) => {
        setIsActiveDownloadSW(true);
      },
    },
  );

  const getCanFunctionsMutation = useMutation(
    (data: GetCarDetailsRequestDto) => carsService.getCanFunctions(data),
    {
      onSuccess: (data) => {
        setIsActiveCanFunctions(!!data.length);
      },
    },
  );

  let imageUrl = "";
  if (Array.isArray(carsModificationQuery.data)) {
    carsModificationQuery.data.forEach((mod) => {
      if (mod.asJson.imageUrl) {
        imageUrl = mod.asJson.imageUrl;
      }
    });
  }

  let component = <></>;

  if (
    carsModificationMutation.isLoading ||
    getCarInstallationMutation.isLoading ||
    getCanFunctionsMutation.isLoading ||
    getCarLinksMutation.isLoading
  ) {
    component = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 795px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else if (
    isActiveCanFunctions &&
    activeResourceSwitcherItem === ResourceItem.CAN_FUNCTIONS
  ) {
    component = (
      <Box sx={{}}>
        <Box
          sx={{
            padding: "20px 135px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              padding: "20px 24px",
            },
          }}
        >
          <Header title={t`CAN functions`} />
        </Box>
        {Array.isArray(getCanFunctionsMutation.data) &&
          getCanFunctionsMutation.data.length > 0 && (
            <CanFunctions data={getCanFunctionsMutation.data} />
          )}
      </Box>
    );
  } else if (
    isActiveInstallationsDiagram &&
    activeResourceSwitcherItem === ResourceItem.INSTALLATION_DIAGRAM
  ) {
    component = (
      <Box
        sx={{
          padding: "20px 135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            padding: "20px 24px",
          },
        }}
      >
        <Box>
          <Header title={t`Connection points`} withLine={true} />
        </Box>
        {getCarInstallationMutation.data ? (
          <InstallationMap data={getCarInstallationMutation.data} />
        ) : (
          <></>
        )}
      </Box>
    );
  } else if (
    isActiveConnectionDiagram &&
    activeResourceSwitcherItem === ResourceItem.CONNECTION_DIAGRAM
  ) {
    component = (
      <Box
        sx={{
          padding: "20px 135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            padding: "20px 0px",
          },
        }}
      >
        <Box
          sx={{
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              padding: "0px 24px",
            },
          }}
        >
          <Header title={t`Connection diagram`} withLine={true} />
        </Box>
        {getCarInstallationMutation.data ? (
          <ConnectionDiagram data={getCarInstallationMutation.data} />
        ) : (
          <></>
        )}
      </Box>
    );
  } else {
    component = <></>;
  }

  let baseComponent = <></>;
  if (carsModificationQuery.isFetching) {
    baseComponent = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 300px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else {
    baseComponent = (
      <Box
        sx={{
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            padding: "0px 0px 80px 0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "20px 135px 0px 135px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              flexDirection: "column",
              padding: "20px 24px 0px 24px",
            },
          }}
        >
          <Box>
            {imageUrl ? (
              <img src={imageUrl} className={classes.image} alt={""} />
            ) : (
              <Box
                sx={{
                  height: "200px",
                  width: "370px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #fff",
                  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                    width: "100%",
                  },
                }}
              >{`${brand} ${model} ${years}`}</Box>
            )}
          </Box>
          <Tabs
            className={classes.tabs}
            sx={{
              width: "387px",
              height: "auto",
              marginLeft: "10px",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                marginTop: "10px",
                marginLeft: "0px",
              },
            }}
            value={modificationValue}
            onChange={handleChangeModification}
            aria-label="tabs"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            {carsModificationQuery.data?.map((mod, index) => {
              return (
                <StyledButton
                  key={index}
                  sx={{ width: "100%" }}
                  label={mod.asJson.type}
                  color={modificationValue === index ? "info" : "primary"}
                  onClick={() => handleChangeModification({}, index)}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box
          sx={{
            margin: "30px 0px",
            padding: "20px 135px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              padding: "20px 24px",
            },
          }}
        >
          <ResourceSwitcher
            activeValue={activeResourceSwitcherItem}
            items={resourceSwitcherItems}
            onChange={(id: ResourceItem) => setActiveResourceSwitcherItem(id)}
          />
        </Box>
        {isActiveDownloadSW &&
        activeResourceSwitcherItem === ResourceItem.DOWNLOAD_SW ? (
          <Box
            sx={{
              padding: "00px 135px",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                padding: "00px 24px 20px 24px",
              },
            }}
          >
            <DownloadingSoftware
              installerLink={installerVM.macosLink}
              data={getCarLinksMutation.data}
            />
          </Box>
        ) : (
          <></>
        )}
        {component}
      </Box>
    );
  }

  const breadcrumbs: NavItem[] = [
    { label: t`Product`, path: ROUTES.catalog },
    {
      label: `S5 (${version?.toUpperCase() || ""})`,
      path: ROUTES.brands(version),
    },
    { label: brand || "", path: ROUTES.models(version, brand) },
    { label: `${model} ${years}`, path: "" },
  ];

  return (
    <Box>
      <ScrollComponent />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <StyledBreadcrumb paths={breadcrumbs} />
      </Box>
      <SectionHeader title={`${brand} ${model} ${years}`} />
      {baseComponent}
    </Box>
  );
};

export default appWithStyles(styles)(Modifications);
