import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "reflect-metadata";
import reportWebVitals from "./reportWebVitals";
import { router } from "./routing/router";
import { AppRouterProvider } from "@shared/router";
import { appInject } from "@core/di/utils";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { AppI18nProvider } from "@core/translations/i18n";
import { appI18n } from "@core/translations/i18n-provider";
import { initializeStateManagement } from "@core/state-management/setup";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { QueryClient, QueryClientProvider } from "react-query";
import { IAppInitializationVM } from "@shared/interfaces/app-initialization-vm.interface";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  },
});

async function initializeApp() {
  const container = document.getElementById("root") as HTMLElement;
  const root = ReactDOM.createRoot(container);

  initializeStateManagement();
  const config = appInject<IConfigService>(DI_TOKENS.configService);
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  try {
    await config.initialize();
    localesViewModel.initialize();
    const authService = appInject<IAuthService>(DI_TOKENS.authService);
    await authService.checkAuthorization();
    const appInitializationViewModel = appInject<IAppInitializationVM>(
      DI_TOKENS.appInitializationViewModel,
    );
    await appInitializationViewModel.init();
    root.render(
      <QueryClientProvider client={queryClient}>
        <AppI18nProvider i18n={appI18n}>
          <AppRouterProvider router={router}></AppRouterProvider>
        </AppI18nProvider>
      </QueryClientProvider>,
    );
  } catch (err) {
    root.render(<div>Error Page</div>);
    throw err;
  }
}

initializeApp();

reportWebVitals();
