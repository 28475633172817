import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    root: {
      "& .MuiSvgIcon-root": {
        height: "25px",
      },
      "& .MuiTablePagination-selectIcon": {
        visibility: "hidden",
      },
      "& .MuiTableCell-head": {
        fontWeight: 600,
        fontSize: "18px",
      },
    },
    filters: {
      "& .MuiInputBase-root": {
        width: "fit-content",
      },
    },
  });
}
