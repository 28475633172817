import { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./user-role-label.styles";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { Role } from "@shared/enum/role.enum";

export interface UserRoleLabelProps extends AppWithStyles<typeof styles> {
  role: Role;
}

const UserRoleLabel: FC<UserRoleLabelProps> = ({
  role,
}: UserRoleLabelProps) => {
  let text = t`User`;
  let backgroundColor = "transparent";
  if (role === Role.ADMIN) {
    text = t`Admin`;
    backgroundColor = "#D52037";
  }

  return (
    <Box
      sx={{
        padding: "5px",
        backgroundColor: backgroundColor,
        color: "#fff",
        fontWeight: 600,
        borderRadius: "5px",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {text}
    </Box>
  );
};

export default appWithStyles(styles)(UserRoleLabel);
