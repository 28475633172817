import { IsNotEmpty, IsNumberString, Length } from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

export interface IOTPView {
  code?: string;
}

export class OTPViewFields implements IOTPView {
  @Length(4)
  @IsNotEmpty()
  @IsNumberString()
  code?: string;
}

export const OTPViewFieldsResolver = classValidatorResolver(OTPViewFields);
