import React, { FC, useCallback, useEffect, useMemo } from "react";
import {
  appWithStyles,
  AppWithStyles,
  Box,
  List,
  ListItemText,
  useMediaQuery,
} from "@shared/material";
import { styles } from "./connection-diagram.styles";
import { CarInstallationModel } from "@shared/models/cars/car-installation.dto";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Locale } from "@shared/enum/locale.enum";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { ConnectionDiagramVM } from "@ui/private/modifications/components/connection-diagram/connection-diagram.vm";
import { appObserver } from "@core/state-management/utils";

export interface ConnectionDiagramProps extends AppWithStyles<typeof styles> {
  data: CarInstallationModel;
}

const ConnectionDiagram: FC<ConnectionDiagramProps> = appObserver(
  ({ data }: ConnectionDiagramProps) => {
    const $vm = useMemo(() => new ConnectionDiagramVM(), []);

    const localesViewModel = appInject<ILocalesViewModel>(
      DI_TOKENS.localesViewModel,
    );

    const isMobile = useMediaQuery(() =>
      baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
    );

    const hasConnectionDiagramAsPDFDocument = useCallback(
      (item: CarInstallationModel) => {
        return (
          item.asJson.connectionDiagram &&
          /.pdf$/.test(item.asJson.connectionDiagram)
        );
      },
      [data],
    );

    const descriptions = useMemo(
      () => ({
        [Locale.English]: data.asJson.connectionDescription[Locale.English]
          .split("\n")
          .filter((text) => !!text),
        [Locale.Russian]: data.asJson.connectionDescription[Locale.Russian]
          .split("\n")
          .filter((text) => !!text),
        [Locale.Ukrainian]: data.asJson.connectionDescription[Locale.Ukrainian]
          .split("\n")
          .filter((text) => !!text),
      }),
      [data],
    );

    useEffect(() => {
      if (!isMobile) {
        $vm.setAsLoaded();
      }
      return () => {
        $vm.setAsLoaded();
      };
    }, []);

    const handleLoadPdf = () => {
      $vm.setAsLoaded();
    };

    const mobilePdfComponent = $vm.isVisiblePDF ? (
      <iframe
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(data.asJson.connectionDiagram)}&embedded=true`}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="PDF Viewer"
        onLoad={handleLoadPdf}
      />
    ) : (
      <></>
    );

    const desktopPdfComponent = (
      <object
        data={data.asJson.connectionDiagram}
        type="application/pdf"
        width="100%"
        height="100%"
        title="PDF Preview"
      ></object>
    );

    return (
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
        }}
      >
        <List>
          {descriptions[localesViewModel.locale].map((text, index) => {
            return (
              <ListItemText
                key={index}
                sx={{
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "19.2px",
                }}
                primary={text}
              />
            );
          })}
        </List>
        {hasConnectionDiagramAsPDFDocument(data) ? (
          <Box
            sx={{
              padding: "20px 0px",
              width: "100%",
              height: "calc(100vh - 367px)",
              minHeight: "700px",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                minHeight: "unset",
                height: "calc(100vh - 268px)",
              },
            }}
          >
            {isMobile ? mobilePdfComponent : desktopPdfComponent}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

export default appWithStyles(styles)(ConnectionDiagram);
