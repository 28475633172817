import { BaseModel } from "@core/models/base-model";

export interface CourseListDto {
  id: string;
  title: string;
  url: string;
}

export class CourseListModel extends BaseModel<CourseListDto> {
  private id?: string;
  private title?: string;
  private url?: string;

  constructor(data: CourseListDto) {
    super();

    this.update(data);
  }

  get asJson(): CourseListDto {
    return {
      id: this.id || "",
      title: this.title || "",
      url: this.url || "",
    };
  }
}
