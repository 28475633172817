import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export const MuiContainer = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};
