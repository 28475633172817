import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    root: {
      display: "flex",
      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
        display: "none",
      },
    },
    link: {
      padding: "8px 16px",
      borderRadius: "7px",
      textTransform: "none",
      cursor: "pointer",
      textDecoration: "none",
    },
    text: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.2px",
      color: baseTheme.palette.text.primary,
    },
    active: {
      "&$link": {
        backgroundColor: baseTheme.palette.primary.main,
      },
    },
  });
}
