import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C8.8203 3 3 8.8203 3 16C3 23.1798 8.8203 29 16 29C23.1798 29 29 23.1798 29 16C29 8.8203 23.1798 3 16 3ZM1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71574 31 16C31 24.2843 24.2843 31 16 31C7.71574 31 1 24.2843 1 16Z"
        fill="#E8EAED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3475 14H16.6525C17.3967 14 18 14.6033 18 15.3475V23.6525C18 24.3967 17.3967 25 16.6525 25H15.3475C14.6033 25 14 24.3967 14 23.6525V15.3475C14 14.6033 14.6033 14 15.3475 14ZM15.3475 7H16.6525C17.3967 7 18 7.60328 18 8.34746V9.65254C18 10.3967 17.3967 11 16.6525 11H15.3475C14.6033 11 14 10.3967 14 9.65254V8.34746C14 7.60328 14.6033 7 15.3475 7Z"
        fill="#E8EAED"
      />
    </SvgIcon>
  );
}
