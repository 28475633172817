import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    label: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14.4px",
    },
  });
}
