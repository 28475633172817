import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    root: {
      "& .MuiButton-root.Mui-disabled": {
        backgroundColor: "inherit",
        color: "#585858",
      },

      "& .MuiTabs-flexContainer": {
        gap: "10px",
        [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
          flexDirection: "column",
        },
      },
      "& .MuiDivider-root": {
        borderWidth: "1px",
        borderColor: baseTheme.palette.background.paper,
      },
    },
  });
}
