import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    loader: {
      width: "300px",
      height: "300px",
    },
  });
}
