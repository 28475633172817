import { SvgIcon, SvgIconProps } from "@shared/material";

function NotFoundIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="264"
      height="111"
      viewBox="0 0 264 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.3617 108.972V84.6806H0V68.4861L52.2472 2.02778H69.5154V67.5694H83.9793V84.6806H69.5154V108.972H51.3617ZM20.8103 67.5694H51.3617V28.9167L20.8103 67.5694Z"
        fill="#D52037"
      />
      <path
        d="M131.92 110.5C106.239 110.5 91.0371 88.8056 91.0371 55.5C91.0371 23.2639 107.125 0.5 131.92 0.5C157.748 0.5 172.95 22.3472 172.95 55.5C172.95 88.0417 156.715 110.5 131.92 110.5ZM109.929 55.5C109.929 79.6389 117.751 92.7778 131.92 92.7778C146.236 92.7778 154.059 79.6389 154.059 55.5C154.059 31.5139 146.236 18.375 131.92 18.375C117.751 18.375 109.929 31.5139 109.929 55.5Z"
        fill="#D52037"
      />
      <path
        d="M231.382 108.972V84.6806H180.021V68.4861L232.268 2.02778H249.536V67.5694H264V84.6806H249.536V108.972H231.382ZM200.831 67.5694H231.382V28.9167L200.831 67.5694Z"
        fill="#D52037"
      />
    </SvgIcon>
  );
}

export default NotFoundIcon;
