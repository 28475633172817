import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    root: {
      "& *::-webkit-scrollbar": {
        width: "12px",
        height: "12px",
      },

      "& *::-webkit-scrollbar-thumb": {
        backgroundColor: baseTheme.palette.secondary.main,
        borderRadius: "24px",
        border: "1px solid transparent",
      },

      "& *::-webkit-scrollbar-track": {
        backgroundColor: "#414856",
        borderRadius: "24px",
      },

      "& *": {
        scrollbarColor: `${baseTheme.palette.secondary.main} #414856`,
        scrollbarWidth: "thin",
      },
    },
  });
}

export const tableHeaderStyles = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "20px",
  border: "none",
  padding: "16px 16px",
  backgroundColor: "#414856",
  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
    fontSize: "14px",
  },
};

export const tableCellStyles = {
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "23.4px",
  border: "none",
  padding: "0px",
  height: "55px",
  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
    fontSize: "14px",
  },
};
