import { IAppInitializationVM } from "@shared/interfaces/app-initialization-vm.interface";
import { appInject, appInjectable } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { IInstallerViewModel } from "@shared/interfaces/installer-vm.interface";

@appInjectable()
export class AppInitializationViewModel implements IAppInitializationVM {
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private installerViewModel = appInject<IInstallerViewModel>(
    DI_TOKENS.installerViewModel,
  );

  constructor() {
    this.authService.onChangeAuthStatus.subscribe(async (status) => {
      this.httpClient.setConfig({
        refreshToken: () => this.authService.refreshToken(),
        getAccessToken: () => this.authService.tokens?.accessToken,
        getUserLoginStatus: () => this.authService.isLoggedIn,
      });
      await this.installerViewModel.fetchData();
    });
  }

  async init() {
    this.httpClient.setConfig({
      refreshToken: () => this.authService.refreshToken(),
      getAccessToken: () => this.authService.tokens?.accessToken,
      getUserLoginStatus: () => this.authService.isLoggedIn,
    });
    if (this.authService.isLoggedIn) {
      await this.usersService.getMyUserDetails();
    }
    await this.installerViewModel.fetchData();
  }
}
