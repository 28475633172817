import { appCreateStyles } from "@shared/material";

export function styles() {
  return appCreateStyles({
    menuItem: {
      cursor: "pointer",
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    label: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.8px",
    },
  });
}
