import { appInject, appInjectable } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { Config } from "@shared/types/config";

@appInjectable()
export class ConfigService implements IConfigService {
  private config: Config | null = null;
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );

  async initialize() {
    return await this.setConfig();
  }

  get() {
    return this.config;
  }

  private async setConfig() {
    this.httpClient.setConfig({
      defaults: {
        baseURL: window.location.origin,
      },
    });

    const { data } = await this.httpClient.get<Config>("./config.json");
    this.config = data;
  }

  get apiUrl() {
    return this.config?.apiUrl || "";
  }

  get defaultLocale() {
    return this.config?.defaultLocale || "";
  }
}
