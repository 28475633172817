import React, { ChangeEvent, FC } from "react";
import {
  AppWithStyles,
  appWithStyles,
  Checkbox,
  FormControl,
} from "shared/material";
import { styles } from "./styled-checkbox.styles";

export interface StyledCheckboxdProps extends AppWithStyles<typeof styles> {
  checked: boolean;
  label: string;
  onChange: (value: string) => void;
  link?: React.ReactNode;
}

const StyledCheckbox: FC<StyledCheckboxdProps> = ({
  classes,
  checked,
  label,
  onChange,
  link,
}: StyledCheckboxdProps) => {
  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(e.target.value as string);
  };

  return (
    <FormControl
      fullWidth
      className={classes.root}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className={classes.label}>
        {label} {link ? link : <></>}
      </div>
      <Checkbox checked={checked} onChange={handleOnChange} color={"info"} />
    </FormControl>
  );
};

export default appWithStyles(styles)(StyledCheckbox);
