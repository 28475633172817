import { appCreateStyles } from "@shared/material";
import { Theme } from "@mui/material";

export function styles({ palette }: Theme) {
  return appCreateStyles({
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiSelect-select": {
        backgroundColor: "transparent",
        border: "1px solid #747B89",
        borderRadius: "6px",
        paddingRight: "10px !important",
        padding: "10px 10px 10px 10px",
        width: "170px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "19.2px",
        color: "#BDC1C6",
      },
      "& .MuiOutlinedInput-notchedOutline span": {
        color: "red",
      },
      "& .MuiSelect-icon": {
        right: "15px",
        top: "20px",
      },
    },
  });
}
