import { PaletteOptions } from "@mui/material";

export const palette: PaletteOptions = {
  primary: {
    main: "#202020",
  },
  secondary: {
    main: "#D52037",
  },
  error: {
    main: "#f44336",
  },
  warning: {
    main: "#ffa726",
  },
  info: {
    main: "#D1D3D9",
  },
  success: {
    main: "#4caf50",
  },
  background: {
    default: "#000",
    paper: "#202020",
  },
  text: {
    primary: "#fff",
    secondary: "#5F5F5F",
    disabled: "#4E4E4E",
  },
};
