import React, { FC } from "react";
import { AppWithStyles, appWithStyles, Box } from "shared/material";
import { styles } from "./section-header.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export interface SectionHeaderProps extends AppWithStyles<typeof styles> {
  title: string;
  searchField?: React.ReactNode;
  button?: React.ReactNode;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  classes,
  title,
  searchField,
  button,
}: SectionHeaderProps) => {
  return (
    <Box
      sx={{
        paddingLeft: "135px",
        paddingRight: "135px",
        position: "relative",
        [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
          paddingLeft: "24px",
          paddingRight: "24px",
        },
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${baseTheme.palette.secondary.main}`,
          padding: "20px 0px",
          display: "flex",
          justifyContent: "space-between",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            padding: "10px 0px",
            position: "relative",
            marginBottom: searchField ? "95px" : "0px",
          },
        }}
      >
        <div className={classes.title}>{title}</div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {searchField ? searchField : <></>}
          {button ? button : <></>}
        </Box>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(SectionHeader);
