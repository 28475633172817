import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./brands.styles";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "@shared/components/section-header";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { StyledBreadcrumb } from "@shared/components/styled-breadcrumb";
import { NavItem } from "@shared/types/nav-item";
import { ROUTES } from "../../../routing/routes";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { useQuery } from "react-query";
import { Loader } from "@shared/components/loader";
import { alphabet } from "@shared/resources/alphabet";
import { CarBrandListModel } from "@shared/models/cars/car-brand-list.dto";
import BrandsMap from "./brands-list";
import { StyledSearchField } from "@shared/components/styled-search-field";
import { BrandsViewModel } from "@ui/private/brands/brands.vm";
import { ScrollComponent } from "@shared/components/scroll-component";
import { IScrollService } from "@shared/interfaces/scroll-service.interface";

export type BrandsProps = AppWithStyles<typeof styles>;

const Brands: FC<BrandsProps> = appObserver(({ classes }: BrandsProps) => {
  const { version } = useParams();
  const location = useLocation();

  const scrollService = useMemo(
    () => appInject<IScrollService>(DI_TOKENS.scrollService),
    [],
  );

  useEffect(() => {
    scrollService.setScrollPosition(0);
  }, []);

  const carsBrandsQuery = useQuery(
    ["download-brands-list"],
    () => $vm.getBrandsList(),
    {
      refetchOnMount: true,
      onSuccess: () => {
        setTimeout(() => {
          const positionData = scrollService.getStoredPosition(
            location.pathname,
          );
          if (positionData.position) {
            scrollService.setScrollPosition(positionData.position);
          }
          scrollService.deleteStoredPosition(location.pathname);
        }, 100);
      },
    },
  );

  const $vm: BrandsViewModel = useMemo(
    () =>
      new BrandsViewModel({
        pathname: location.pathname,
        version: version || "",
      }).setQuery(carsBrandsQuery),
    [version],
  );

  const navigateTo = useCallback((path: string) => {
    scrollService.storePosition(
      location.pathname,
      scrollService.currentScrollPosition,
      $vm.searchString,
    );
    return navigate(path);
  }, []);

  const [_, render] = useState(0);
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  let component = <></>;

  if (carsBrandsQuery.isFetching) {
    component = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 367px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else {
    component = (
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "80px",
          },
        }}
      >
        {alphabet
          .map((letter) => ({
            letter,
            brands: (carsBrandsQuery.data || []).filter(
              (brand: CarBrandListModel) =>
                (brand.asJson.brand || " ")[0].toUpperCase() === letter,
            ),
          }))
          .filter((data) => data.brands.length > 0)
          .map((data) => {
            return (
              <>
                <Box
                  sx={{
                    margin: "20px 0px",
                    fontSize: "24px",
                    fontWeight: 700,
                    padding: "3px 20px",
                    lineHeight: "28.8px",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(90deg, #D52037 0%, rgba(213, 32, 55, 0) 59.81%)",
                    [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                      fontSize: "18px",
                    },
                  }}
                >
                  {data.letter}
                </Box>
                <Box
                  sx={{
                    paddingBottom: "40px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                      gap: "10px",
                    },
                  }}
                >
                  {data.brands.map((brand, index) => {
                    return (
                      <Box
                        onClick={() =>
                          navigateTo(
                            ROUTES.models(
                              version,
                              encodeURI(brand.asJson.brand || ""),
                            ),
                          )
                        }
                        key={index}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "280px",
                          height: "80px",
                          fontWeight: 400,
                          fontSize: "20px",
                          paddingLeft: "20px",
                          lineHeight: "24.2px",
                          borderRadius: "10px",
                          backgroundColor: baseTheme.palette.background.paper,
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            backgroundColor: baseTheme.palette.secondary.main,
                          },
                          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]:
                            {
                              width: "100%",
                            },
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                        >
                          {brand.asJson.brand || ""}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {BrandsMap.get(brand.asJson.brand)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            );
          })}
      </Box>
    );
  }

  const navigate = useNavigate();

  const breadcrumbs: NavItem[] = [
    { label: t`Product`, path: ROUTES.catalog },
    { label: `S5 (${version?.toUpperCase() || ""})`, path: "" },
  ];

  return (
    <Box>
      <ScrollComponent />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <StyledBreadcrumb paths={breadcrumbs} />
      </Box>
      <SectionHeader
        title={t`Catalog`}
        searchField={
          <Box
            sx={{
              width: "30vw",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                position: "absolute",
                bottom: "-80px",
                left: "0px",
              },
            }}
          >
            <StyledSearchField
              placeholder={t`Search`}
              onSearch={(e) => $vm.setSearchString(e)}
              value={$vm.searchString}
            />
          </Box>
        }
      />
      {component}
    </Box>
  );
});

export default appWithStyles(styles)(Brands);
