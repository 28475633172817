import React, { FC } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./public-locales.styles";
import { Box } from "@mui/material";
import StyledSelect from "@shared/components/styled-select/styled-select";
import { localesList } from "@shared/constants/locales";
import { Locale } from "@shared/enum/locale.enum";

export interface LocalesProps extends AppWithStyles<typeof styles> {
  onChange: (e: Locale) => void;
  locale: Locale;
}

const PublicLocales: FC<LocalesProps> = ({
  classes,
  onChange,
  locale,
}: LocalesProps) => {
  const handleChangeLocale = (e: string) => {
    onChange(e as Locale);
  };

  return (
    <Box className={classes.root}>
      <StyledSelect
        items={localesList}
        value={locale as string}
        label={locale as string}
        onChange={handleChangeLocale}
      />
    </Box>
  );
};

export default appWithStyles(styles)(PublicLocales);
