import React, { FC } from "react";
import { Box } from "@shared/material";
import { Typography } from "@mui/material";

export interface HeaderProps {
  title: string;
  withLine?: boolean;
}

const Header: FC<HeaderProps> = ({ title, withLine }: HeaderProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: withLine ? "1px solid #D1D3D9" : "none",
        padding: "10px 0px",
        marginBottom: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "28px",
          fontWeight: 700,
          lineHeight: "33.6px",
        }}
        component={"h4"}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Header;
