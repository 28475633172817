import React, { FC, ReactNode } from "react";
import { AppWithStyles, appWithStyles, Container } from "@shared/material";
import { styles } from "./auth-form-layout.styles";
import { Box, Typography } from "@mui/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export interface AuthFormLayoutProps extends AppWithStyles<typeof styles> {
  title: string;
  children: ReactNode;
}

const AuthFormLayout: FC<AuthFormLayoutProps> = ({
  classes,
  title,
  children,
}: AuthFormLayoutProps) => {
  return (
    <Container
      sx={{
        width: "334px",
        maxWidth: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "calc(100vh - 56px)",
        padding: "20px 0px 20px 0px",
        [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
          justifyContent: "center",
          height: "calc(100vh - 135px)",
        },
      }}
    >
      <Box className={classes.title}>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
      </Box>
      {children}
    </Container>
  );
};

export default appWithStyles(styles)(AuthFormLayout);
