import React, { FC } from "react";
import { AppWithStyles, appWithStyles, useMediaQuery } from "@shared/material";
import { FaWhatsapp, FaViber, FaTelegram } from "react-icons/fa";
import { styles } from "./socials.styles";
import { Box, Link } from "@mui/material";
import { t } from "@lingui/macro";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export type SocialsProps = AppWithStyles<typeof styles>;

const Socials: FC<SocialsProps> = ({}: SocialsProps) => {
  const isMobile = useMediaQuery(() =>
    baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
  );
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
        }}
      >
        {isMobile ? (
          <></>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "14px",
              width: "150px",
              textAlign: "end",
              marginRight: "10px",
            }}
          >{t`Installer technical support`}</Box>
        )}
        <Link
          sx={{
            color: "#fff",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "14px",
          }}
          target={"_blank"}
          href={"https://wa.me/971504978808"}
        >
          <FaWhatsapp size={33} />
          <Box>WhatsApp</Box>
        </Link>
        <Link
          sx={{
            color: "#fff",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "14px",
          }}
          target={"_blank"}
          href={"viber://pa?chatURI=gazerpartnerssupport"}
        >
          <FaViber size={31} />
          <Box>Viber</Box>
        </Link>
        <Link
          sx={{
            color: "#fff",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "14px",
          }}
          target={"_blank"}
          href={"https://t.me/GazerPartnersSupportBot"}
        >
          <FaTelegram size={31} />
          <Box>Telegram</Box>
        </Link>
      </Box>
      {isMobile ? (
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "14px",
            width: "300px",
            textAlign: "end",
            marginRight: "10px",
          }}
        >{t`Installer technical support`}</Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default appWithStyles(styles)(Socials);
