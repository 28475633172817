import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./profile.styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { t } from "@lingui/macro";
import { SectionHeader } from "@shared/components/section-header";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import { useMutation, useQuery } from "react-query";
import { Loader } from "@shared/components/loader";
import { IUsersService } from "@shared/interfaces/users-service.interface";
import { UserDataForm } from "@ui/private/profile/components/user-data-form";
import { UpdateUserDto } from "@shared/models/users/update-user.dto";
import { IUserDataForm } from "@ui/private/profile/components/user-data-form/user-data-form.validator";
import { StyledButton } from "@shared/components/buttons/styled-button";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";

export type ProfileProps = AppWithStyles<typeof styles>;

const Profile: FC<ProfileProps> = ({ classes }: ProfileProps) => {
  const navigate = useNavigate();
  const [_, render] = useState(0);
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );
  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const [userPhoto, setUserPhoto] = useState<File | null>(null);

  const onFile = (file: File | null) => {
    setUserPhoto(file);
  };

  const usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const [openDialog, setOpenDialog] = useState(false);

  const handleLogoutClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmLogout = () => {
    setOpenDialog(false);
    authService.logout();
    setTimeout(() => {
      navigate(ROUTES.welcomeScreen);
    }, 0);
  };

  const myUserDetailsQuery = useQuery(
    ["download-my-user-details"],
    () => usersService.getMyUserDetails(),
    {
      refetchOnMount: true,
    },
  );

  const updateUserDetailMutation = useMutation(
    (data: IUserDataForm) => {
      const promises: Array<Promise<unknown>> = [
        usersService.updateMyUserDetails(data as UpdateUserDto),
      ];
      if (userPhoto) {
        promises.push(usersService.uploadMyProfilePhoto(userPhoto));
      }
      return Promise.all(promises);
    },

    {
      onSuccess: () => {
        myUserDetailsQuery.refetch();
      },
    },
  );
  let component = <></>;

  if (myUserDetailsQuery.isFetching || updateUserDetailMutation.isLoading) {
    component = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 367px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else {
    component = (
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        {!myUserDetailsQuery.isFetching &&
        !updateUserDetailMutation.isLoading &&
        myUserDetailsQuery.data ? (
          <UserDataForm
            userData={myUserDetailsQuery.data}
            onFile={onFile}
            onSubmit={(data: IUserDataForm) => {
              updateUserDetailMutation.mutate(data);
            }}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      ></Box>
      <SectionHeader
        title={t`Profile`}
        button={
          <StyledButton
            color={"secondary"}
            onClick={handleLogoutClick}
            label={t`Logout`}
          />
        }
      />
      {component}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        className={classes.dialog}
      >
        <DialogTitle>{t`Sign out`}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            {t`Are you sure you want to sign out?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            sx={{ color: "#fff" }}
            label={t`Cancel`}
            onClick={handleDialogClose}
            variant={"text"}
            color="primary"
          />
          <StyledButton
            label={t`Sign out`}
            onClick={handleConfirmLogout}
            color="secondary"
            autoFocus
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default appWithStyles(styles)(Profile);
