import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./catalog.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import classNames from "classnames";
import { Version } from "@shared/enum/version.enum";
import { IAuthService } from "@shared/interfaces/auth-service.interface";

export interface CatalogProps extends AppWithStyles<typeof styles> {}

const Catalog: FC<CatalogProps> = ({ classes }: CatalogProps) => {
  const navigate = useNavigate();

  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "auto",
        padding: "36px 24px 116px 24px",
        gap: "30px",
        position: "relative",
        [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
          height: "calc(100vh - 260px)",
          padding: "0px 135px 0px 135px",
          flexDirection: "row",
          gap: "20px",
          justifyContent: "space-evenly",
        },
      }}
    >
      {authService.hasPermission(Version.V1) ? (
        <div
          className={classNames(classes.item, classes.red)}
          onClick={() => navigate(ROUTES.brands(Version.V1))}
        >
          <div className={classes.label}>S5 (V1)</div>
          <div
            className={classes.description}
          >{t`Choose version 1 security system`}</div>
          <img
            className={classes.image}
            src={`${window.location.origin}/assets/v1.png`}
            alt={""}
          />
        </div>
      ) : (
        <></>
      )}
      {authService.hasPermission(Version.V2) ? (
        <div
          className={classNames(classes.item, classes.grey)}
          onClick={() => navigate(ROUTES.brands(Version.V2))}
        >
          <div className={classes.label}>S5 (V2)</div>
          <div
            className={classes.description}
          >{t`Choose version 2 security system`}</div>
          <img
            className={classes.image}
            src={`${window.location.origin}/assets/v2.png`}
            alt={""}
          />
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default appWithStyles(styles)(Catalog);
