export const Countries = {
  Afghanistan: {
    code: "AF",
    cities: [
      "Aibak",
      "Anar Darah",
      "Andkhoy",
      "Art Khwajah",
      "Asadabad",
      "Ashkasham",
      "Asmar",
      "Baghlan",
      "Balkh",
      "Bamyan",
      "Baraki Barak",
      "Bazar-e Yakawlang",
      "Bazarak",
      "Chahar Burj",
      "Charikar",
      "Chiras",
      "Eslam Qaleh",
      "Farah",
      "Farkhar",
      "Fayroz Koh",
      "Fayzabad",
      "Gardez",
      "Gereshk",
      "Ghazni",
      "Ghormach",
      "Herat",
      "Hukumati Azrah",
      "Jabal os Saraj",
      "Jalalabad",
      "Jurm",
      "Kabul",
      "Kandahar",
      "Karukh",
      "Khan Neshin",
      "Khanabad",
      "Khash",
      "Khost",
      "Khulm",
      "Kuhsan",
      "Kunduz",
      "Kushk",
      "Larkird",
      "Lashkar Gah",
      "Markaz-e Woluswali-ye Achin",
      "Maymana",
      "Mazar-e Sharif",
      "Mehtar Lam",
      "Mirabad",
      "Nahrin",
      "Paghman",
      "Panjab",
      "Pul-e 'Alam",
      "Pul-e Khumri",
      "Qalat",
      "Qarah Bagh",
      "Qarawul",
      "Qarqin",
      "Rudbar",
      "Rustaq",
      "Sang-e Charak",
      "Sangin",
      "Sar-e Pul",
      "Shahrak",
      "Shibirghan",
      "Shindand",
      "Taloqan",
      "Tukzar",
      "Uruzgan",
      "Zaranj",
      "Zarghun Shahr",
      "Zindah Jan",
    ],
  },
  Albania: {
    code: "AL",
    cities: [
      "Ballsh",
      "Berat",
      "Bulqize",
      "Burrel",
      "Cerrik",
      "Durres",
      "Elbasan",
      "Fier",
      "Fier-Cifci",
      "Fushe-Kruje",
      "Gjirokaster",
      "Gramsh",
      "Kamez",
      "Kavaje",
      "Korce",
      "Kruje",
      "Kucove",
      "Kukes",
      "Lac",
      "Lezhe",
      "Librazhd-Qender",
      "Lushnje",
      "Patos",
      "Patos Fshat",
      "Permet",
      "Peshkopi",
      "Pogradec",
      "Rreshen",
      "Sarande",
      "Shijak",
      "Shkoder",
      "Tepelene",
      "Tirana",
      "Vlore",
    ],
  },
  Algeria: {
    code: "DZ",
    cities: [
      "'Ain Abid",
      "'Ain Azel",
      "'Ain Benian",
      "'Ain Boucif",
      "'Ain Deheb",
      "'Ain Merane",
      "'Ain el Bell",
      "'Ain el Berd",
      "'Ain el Hadjar",
      "'Ain el Hammam",
      "'Ain el Melh",
      "'Ain el Turk",
      "Abadla",
      "Abou el Hassan",
      "Adrar",
      "Aflou",
      "Ahmed Bel Hadj",
      "Ain Arnat",
      "Ain Beida",
      "Ain Bessem",
      "Ain Defla",
      "Ain Fakroun",
      "Ain Kercha",
      "Ain M'Lila",
      "Ain Oulmene",
      "Ain Oussera",
      "Ain Sefra",
      "Ain Smara",
      "Ain Taya",
      "Ain Temouchent",
      "Ain Touta",
      "Ain el Bya",
      "Ain el Hadjel",
      "Akbou",
      "Algiers",
      "Ali Mendjeli",
      "Amizour",
      "Ammi Moussa",
      "Annaba",
      "Aoulef",
      "Arbatache",
      "Arhribs",
      "Arris",
      "Arzew",
      "Assi Bou Nif",
      "Azazga",
      "Azzaba",
      "BABOR - VILLE",
      "Bab Ezzouar",
      "Baraki",
      "Barbacha",
      "Barika",
      "Batna City",
      "Bechar",
      "Bejaia",
      "Ben Mehidi",
      "Beni Abbes",
      "Beni Amrane",
      "Beni Douala",
      "Beni Mered",
      "Beni Mester",
      "Beni Saf",
      "Beni Tamou",
      "Bensekrane",
      "Berrahal",
      "Berriane",
      "Berrouaghia",
      "Besbes",
      "Bir el Ater",
      "Bir el Djir",
      "Birine",
      "Birkhadem",
      "Biskra",
      "Blida",
      "Boghni",
      "Bordj Bou Arreridj",
      "Bordj Ghdir",
      "Bordj Menaiel",
      "Bordj Zemoura",
      "Bordj el Kiffan",
      "Bou Arfa",
      "Bou Hanifia el Hamamat",
      "Bou Ismail",
      "Bou Saada",
      "Bou Tlelis",
      "Boudjima",
      "Boudouaou",
      "Boufarik",
      "Bougaa",
      "Bougara",
      "Bouinan",
      "Bouira",
      "Boukadir",
      "Boumahra Ahmed",
      "Boumerdas",
      "Brezina",
      "Chabet el Ameur",
      "Charef",
      "Chebli",
      "Chelghoum el Aid",
      "Chellalat el Adhaouara",
      "Chemini",
      "Cheraga",
      "Cherchell",
      "Cheria",
      "Chetouane",
      "Chiffa",
      "Chlef",
      "Chorfa",
      "Collo",
      "Constantine",
      "Dar Chioukh",
      "Dar el Beida",
      "Debila",
      "Dellys",
      "Didouche Mourad",
      "District of Taher",
      "Djamaa",
      "Djelfa",
      "Djidiouia",
      "Douera",
      "Draa Ben Khedda",
      "Draa el Mizan",
      "Drean",
      "Ech Chettia",
      "El Abadia",
      "El Abiodh Sidi Cheikh",
      "El Achir",
      "El Affroun",
      "El Amria",
      "El Aouinet",
      "El Arrouch",
      "El Attaf",
      "El Bayadh",
      "El Eulma",
      "El Hadjar",
      "El Hadjira",
      "El Idrissia",
      "El Kala",
      "El Khroub",
      "El Kseur",
      "El Malah",
      "El Meghaier",
      "El Menia",
      "El Milia",
      "El Oued",
      "El Tarf",
      "En Nedjma",
      "Es Senia",
      "Fedj M'Zala",
      "Feidh el Botma",
      "Feraoun",
      "Freha",
      "Frenda",
      "Gdyel",
      "Ghardaia",
      "Ghazaouet",
      "Grarem",
      "Guelma",
      "Guemar",
      "Guerara",
      "Hadjout",
      "Hamma Bouziane",
      "Hammam Bou Hadjar",
      "Hammamet",
      "Hassi Bahbah",
      "Hassi Messaoud",
      "Heliopolis",
      "Hennaya",
      "Ighram",
      "Illizi",
      "In Salah",
      "Jijel",
      "Kais",
      "Kerkera",
      "Khemis Miliana",
      "Khemis el Khechna",
      "Khenchela",
      "Kolea",
      "Ksar Chellala",
      "Ksar el Boukhari",
      "L'Arbaa Nait Irathen",
      "Laghouat",
      "Lakhdaria",
      "Larbaa",
      "Lardjem",
      "M'Daourouch",
      "M'Sila",
      "Maatkas",
      "Maghnia",
      "Makouda",
      "Mansoura",
      "Mansourah",
      "Mascara",
      "Mazouna",
      "Mecheria",
      "Medea",
      "Meftah",
      "Megarine",
      "Mehdia daira de meghila",
      "Mekla",
      "Melouza",
      "Merouana",
      "Mers el Kebir",
      "Meskiana",
      "Messaad",
      "Metlili Chaamba",
      "Mila",
      "Miliana",
      "Mohammadia",
      "Mostaganem",
      "Mouzaia",
      "N'Gaous",
      "Naama",
      "Naciria",
      "Nedroma",
      "Oran",
      "Ouargla",
      "Oued Fodda",
      "Oued Rhiou",
      "Oued Sly",
      "Oued Zenati",
      "Oued el Abtal",
      "Oued el Alleug",
      "Ouenza",
      "Ouled Djellal",
      "Ouled Haddaj",
      "Ouled Mimoun",
      "Ouled Moussa",
      "Ouled Slama Fouaga",
      "Oum el Bouaghi",
      "Oumache",
      "Ras el Aioun",
      "Ras el Oued",
      "Reggane",
      "Reghaia",
      "Reguiba",
      "Relizane",
      "Remchi",
      "Robbah",
      "Rouached",
      "Rouiba",
      "Rouissat",
      "Saida",
      "Salah Bey",
      "Saoula",
      "Sebdou",
      "Seddouk",
      "Sedrata",
      "Setif",
      "Sfizef",
      "Sidi Abdelli",
      "Sidi Aissa",
      "Sidi Akkacha",
      "Sidi Amar",
      "Sidi Amrane",
      "Sidi Bel Abbes",
      "Sidi Khaled",
      "Sidi Merouane",
      "Sidi Moussa",
      "Sidi Okba",
      "Sidi ech Chahmi",
      "Sig",
      "Skikda",
      "Sougueur",
      "Souk Ahras",
      "Souma",
      "Sour el Ghozlane",
      "Tadjenanet",
      "Tadmait",
      "Taibet",
      "Tamalous",
      "Tamanghasset",
      "Tazoult-Lambese",
      "Tebesbest",
      "Tebessa",
      "Telerghma",
      "Tenes",
      "Thenia",
      "Theniet el Had",
      "Tiaret",
      "Tighenif",
      "Timimoun",
      "Timizart",
      "Tindouf",
      "Tipasa",
      "Tirmitine",
      "Tissemsilt",
      "Tizi Gheniff",
      "Tizi Ouzou",
      "Tizi Rached",
      "Tizi-n-Tleta",
      "Tlemcen",
      "Tolga",
      "Touggourt",
      "Zemoura",
      "Zeralda",
      "Zeribet el Oued",
      "Zighout Youcef",
      "el hed",
    ],
  },
  "American Samoa": {
    code: "AS",
    cities: ["Pago Pago"],
  },
  Andorra: {
    code: "AD",
    cities: ["Andorra la Vella", "Encamp", "les Escaldes"],
  },
  Angola: {
    code: "AO",
    cities: [
      "Alto Hama",
      "Ambriz",
      "Ambrizette",
      "Andulo",
      "Baia Farta",
      "Bailundo",
      "Balombo",
      "Banza Damba",
      "Barra do Dande",
      "Benguela",
      "Bibala",
      "Bocoio",
      "Buco",
      "Caala",
      "Cabinda",
      "Cacolo",
      "Caconda",
      "Cacongo",
      "Cacuso",
      "Cafunfo",
      "Cahama",
      "Caimbambo",
      "Calonamba",
      "Calucinga",
      "Calulo",
      "Caluquembe",
      "Camabatela",
      "Camacupa",
      "Camanongue",
      "Camboio",
      "Cambundi",
      "Cambundi",
      "Cambundi Catembo",
      "Cameia",
      "Cangandala",
      "Capunda",
      "Cassanguidi",
      "Catabola",
      "Catchiungo",
      "Catumbela",
      "Caxito",
      "Cazombo",
      "Cazombo",
      "Cela",
      "Chinguar",
      "Chitembo",
      "Chongoroi",
      "Conda",
      "Cuango-Luzamba",
      "Cubal",
      "Cuchi",
      "Cuemba",
      "Cuimba",
      "Cuito",
      "Cunhinga",
      "Damba",
      "Dombe Grande",
      "Dondo",
      "Dondo",
      "Dundo",
      "Ecunna",
      "Gabela",
      "Gama",
      "Ganda",
      "Golungo Alto",
      "Huambo",
      "Jamba",
      "Lobito",
      "Londuimbali",
      "Longonjo",
      "Luanda",
      "Luau",
      "Lubango",
      "Lucala",
      "Lucapa",
      "Luena",
      "Lumbala",
      "Malanje",
      "Maquela do Zombo",
      "Matala",
      "Mbanza Kongo",
      "Menongue",
      "Mossamedes",
      "Mungo",
      "Mussende",
      "N'dalatando",
      "Negage",
      "Nharea",
      "Nzagi",
      "Ondjiva",
      "Porto Amboim",
      "Quibala",
      "Quilenda",
      "Quipungo",
      "Quissecula",
      "Santa Clara",
      "Sanza Pombo",
      "Saurimo",
      "Songo",
      "Soyo",
      "Sumbe",
      "Tomboco",
      "Tombua",
      "Uacu Cungo",
      "Ucu Seles",
      "Ucuma",
      "Uige",
      "Xangongo",
    ],
  },
  Argentina: {
    code: "AR",
    cities: [
      "Adrogue",
      "Aguilares",
      "Albardon",
      "Alderetes",
      "Allen",
      "Almafuerte",
      "Alta Gracia",
      "Anatuya",
      "Andalgala",
      "Arauco",
      "Aristobulo del Valle",
      "Armstrong",
      "Arroyito",
      "Arroyo Seco",
      "Avellaneda",
      "Azul",
      "Bahia Blanca",
      "Balcarce",
      "Balvanera",
      "Barracas",
      "Barranqueras",
      "Belgrano",
      "Bell Ville",
      "Bella Vista",
      "Bella Vista",
      "Berazategui",
      "Bernardo de Irigoyen",
      "Boedo",
      "Buenos Aires",
      "Cafayate",
      "Calchaqui",
      "Caleta Olivia",
      "Campana",
      "Campo Grande",
      "Campo Largo",
      "Campo Ramon",
      "Canada de Gomez",
      "Candelaria",
      "Capitan Bermudez",
      "Carcarana",
      "Casilda",
      "Castelar",
      "Castelli",
      "Catamarca",
      "Catriel",
      "Caucete",
      "Centenario",
      "Ceres",
      "Chacabuco",
      "Chajari",
      "Chamical",
      "Charata",
      "Chilecito",
      "Chimbas",
      "Chivilcoy",
      "Chos Malal",
      "Cinco Saltos",
      "Cipolletti",
      "Clorinda",
      "Colegiales",
      "Colonia Wanda",
      "Comodoro Rivadavia",
      "Concepcion del Uruguay",
      "Concordia",
      "Cordoba",
      "Coronda",
      "Coronel Dorrego",
      "Coronel Suarez",
      "Corrientes",
      "Corzuela",
      "Cosquin",
      "Crespo",
      "Cruz del Eje",
      "Curuzu Cuatia",
      "Cutral-Co",
      "Dean Funes",
      "Diamante",
      "Dolores",
      "Don Torcuato",
      "Dos de Mayo",
      "El Bolson",
      "El Colorado",
      "El Palomar",
      "El Quebrachal",
      "El Soberbio",
      "El Trebol",
      "Embalse",
      "Embarcacion",
      "Esperanza",
      "Esquel",
      "Esquina",
      "Famailla",
      "Federacion",
      "Federal",
      "Firmat",
      "Fontana",
      "Formosa",
      "Fraile Pintado",
      "Fray Luis A. Beltran",
      "Funes",
      "Galvez",
      "Garupa",
      "General Acha",
      "General Cabrera",
      "General Jose de San Martin",
      "General Mosconi",
      "General Pacheco",
      "General Pico",
      "General Pinedo",
      "General Roca",
      "General Villegas",
      "Gobernador Galvez",
      "Gobernador Virasora",
      "Goya",
      "Granadero Baigorria",
      "Gualeguay",
      "Gualeguaychu",
      "Haedo",
      "Hernando",
      "Humahuaca",
      "Hurlingham",
      "Ingeniero Guillermo N. Juarez",
      "Ingeniero Pablo Nogues",
      "Jardin America",
      "Jesus Maria",
      "Joaquin V. Gonzalez",
      "Jose C. Paz",
      "Junin",
      "Junin de los Andes",
      "La Calera",
      "La Carlota",
      "La Falda",
      "La Leonesa",
      "La Paz",
      "La Plata",
      "La Punta",
      "La Quiaca",
      "La Rioja",
      "Laboulaye",
      "Laguna Paiva",
      "Las Brenas",
      "Las Lomitas",
      "Las Parejas",
      "Las Rosas",
      "Las Toscas",
      "Las Varillas",
      "Libertador General San Martin",
      "Lincoln",
      "Los Polvorines",
      "Lujan",
      "Machagai",
      "Mar del Plata",
      "Marcos Juarez",
      "Mendoza",
      "Mercedes",
      "Mercedes",
      "Merlo",
      "Merlo",
      "Monte Caseros",
      "Montecarlo",
      "Monteros",
      "Moron",
      "Morteros",
      "Muniz",
      "Necochea",
      "Neuquen",
      "Nueve de Julio",
      "Obera",
      "Olavarria",
      "Oliva",
      "Oncativo",
      "Palpala",
      "Pampa del Indio",
      "Parana",
      "Paso de los Libres",
      "Perez",
      "Pergamino",
      "Pico Truncado",
      "Pilar",
      "Pilar",
      "Pirane",
      "Plaza Huincul",
      "Plottier",
      "Pocito",
      "Pontevedra",
      "Posadas",
      "Presidencia Roque Saenz Pena",
      "Presidencia de la Plaza",
      "Puerto Deseado",
      "Puerto Eldorado",
      "Puerto Esperanza",
      "Puerto Iguazu",
      "Puerto Madryn",
      "Puerto Rico",
      "Punta Alta",
      "Quilmes",
      "Quimili",
      "Quitilipi",
      "Rafaela",
      "Rawson",
      "Reconquista",
      "Recreo",
      "Recreo",
      "Resistencia",
      "Retiro",
      "Rio Ceballos",
      "Rio Cuarto",
      "Rio Gallegos",
      "Rio Grande",
      "Rio Segundo",
      "Rio Tercero",
      "Roldan",
      "Rosario",
      "Rosario del Tala",
      "Rufino",
      "Saladas",
      "Salta",
      "San Antonio Oeste",
      "San Bernardo",
      "San Carlos Centro",
      "San Carlos de Bariloche",
      "San Clemente del Tuyu",
      "San Cristobal",
      "San Francisco",
      "San Isidro",
      "San Javier",
      "San Jorge",
      "San Jose de Feliciano",
      "San Jose de Jachal",
      "San Juan",
      "San Justo",
      "San Lorenzo",
      "San Luis",
      "San Luis del Palmar",
      "San Martin",
      "San Martin de los Andes",
      "San Miguel",
      "San Miguel de Tucuman",
      "San Nicolas de los Arroyos",
      "San Pedro",
      "San Pedro",
      "San Pedro de Jujuy",
      "San Rafael",
      "San Ramon de la Nueva Oran",
      "San Salvador",
      "San Salvador de Jujuy",
      "San Vicente",
      "Santa Elena",
      "Santa Fe",
      "Santa Lucia",
      "Santa Lucia",
      "Santa Rosa",
      "Santiago del Estero",
      "Santo Tome",
      "Santo Tome",
      "Santos Lugares",
      "Sunchales",
      "Tafi Viejo",
      "Tandil",
      "Tartagal",
      "Termas de Rio Hondo",
      "Tigre",
      "Tinogasta",
      "Tortuguitas",
      "Tostado",
      "Trelew",
      "Tres Arroyos",
      "Tres Isletas",
      "Unquillo",
      "Ushuaia",
      "Veinticinco de Mayo",
      "Veinticinco de Mayo",
      "Venado Tuerto",
      "Vera",
      "Victoria",
      "Viedma",
      "Villa Allende",
      "Villa Angela",
      "Villa Berthet",
      "Villa Carlos Paz",
      "Villa Constitucion",
      "Villa Dolores",
      "Villa General San Martin",
      "Villa Gesell",
      "Villa Lugano",
      "Villa Maria",
      "Villa Mercedes",
      "Villa Nueva",
      "Villa Ocampo",
      "Villa Ortuzar",
      "Villa Paula de Sarmiento",
      "Villa Regina",
      "Villa Santa Rita",
      "Villa Sarmiento",
      "Villa de Mayo",
      "Villaguay",
      "Yerba Buena",
      "Zapala",
      "Zarate",
    ],
  },
  Armenia: {
    code: "AM",
    cities: [
      "Abovyan",
      "Alaverdi",
      "Ararat",
      "Armavir",
      "Artashat",
      "Ashtarak",
      "Ch'arents'avan",
      "Dilijan",
      "Gavar",
      "Goris",
      "Gyumri",
      "Hats'avan",
      "Hrazdan",
      "Ijevan",
      "Kapan",
      "Martuni",
      "Masis",
      "Sevan",
      "Spitak",
      "Stepanavan",
      "Vagharshapat",
      "Vanadzor",
      "Vardenis",
      "Vedi",
      "Yeghvard",
      "Yerevan",
    ],
  },
  Aruba: {
    code: "AW",
    cities: [
      "Oranjestad",
      "Paradera",
      "San Nicolas",
      "Santa Cruz",
      "Savaneta",
      "Tanki Leendert",
    ],
  },
  Australia: {
    code: "AU",
    cities: [
      "Aberfoyle Park",
      "Adelaide",
      "Adelaide Hills",
      "Adelaide city centre",
      "Albany",
      "Albany Creek",
      "Albion Park",
      "Aldinga Beach",
      "Alexandra Hills",
      "Alice Springs",
      "Altona",
      "Altona Meadows",
      "Altona North",
      "Annerley",
      "Armadale",
      "Armidale",
      "Arncliffe",
      "Arundel",
      "Ascot Vale",
      "Ashfield",
      "Ashgrove",
      "Ashmore",
      "Aspley",
      "Auburn",
      "Australind",
      "Avondale Heights",
      "Bairnsdale",
      "Balcatta",
      "Baldivis",
      "Balga",
      "Ballajura",
      "Ballarat",
      "Balmain",
      "Balwyn",
      "Balwyn North",
      "Bankstown",
      "Banora Point",
      "Bassendean",
      "Bateau Bay",
      "Bathurst",
      "Baulkham Hills",
      "Bayswater",
      "Bayswater",
      "Beaumaris",
      "Bellevue Hill",
      "Belmont",
      "Belmore",
      "Benalla",
      "Bendigo",
      "Bentleigh",
      "Bentleigh East",
      "Berwick",
      "Beverly Hills",
      "Bexley",
      "Birkdale",
      "Blackburn",
      "Blackburn South",
      "Blacktown",
      "Bondi Beach",
      "Boronia",
      "Bossley Park",
      "Botany",
      "Bowen",
      "Bowral",
      "Box Hill",
      "Box Hill North",
      "Bracken Ridge",
      "Brassall",
      "Bray Park",
      "Brighton",
      "Brighton East",
      "Brisbane",
      "Broadmeadows",
      "Broken Hill",
      "Brookfield",
      "Brunswick",
      "Brunswick East",
      "Brunswick West",
      "Buderim",
      "Bulleen",
      "Bunbury",
      "Bundaberg",
      "Bundoora",
      "Burleigh Heads",
      "Burleigh Waters",
      "Burnie",
      "Burpengary",
      "Burwood",
      "Burwood",
      "Burwood East",
      "Busselton",
      "Butler",
      "Byford",
      "Caboolture",
      "Cabramatta",
      "Cairns",
      "Calamvale",
      "Camberwell",
      "Camp Hill",
      "Campbelltown",
      "Camperdown",
      "Campsie",
      "Canberra",
      "Canley Heights",
      "Canley Vale",
      "Canning Vale",
      "Capalaba",
      "Carindale",
      "Caringbah",
      "Caringbah South",
      "Carlingford",
      "Carlton",
      "Carlton",
      "Carnegie",
      "Caroline Springs",
      "Carrara",
      "Carrum Downs",
      "Casino",
      "Castle Hill",
      "Casula",
      "Caulfield North",
      "Caulfield South",
      "Cessnock",
      "Charlestown",
      "Chatswood",
      "Cheltenham",
      "Chermside",
      "Cherrybrook",
      "Chester Hill",
      "Clarkson",
      "Clayfield",
      "Clayton",
      "Clayton South",
      "Cleveland",
      "Clyde",
      "Coburg",
      "Coffs Harbour",
      "Como",
      "Concord",
      "Condell Park",
      "Coogee",
      "Coombabah",
      "Coomera",
      "Coorparoo",
      "Corio",
      "Craigieburn",
      "Craigmore",
      "Cranbourne",
      "Cranbourne East",
      "Cranbourne North",
      "Cranbourne West",
      "Cranebrook",
      "Cremorne",
      "Crestmead",
      "Cronulla",
      "Croydon",
      "Croydon",
      "Dalby",
      "Dandenong",
      "Dandenong North",
      "Dapto",
      "Darlinghurst",
      "Darwin",
      "Deception Bay",
      "Dee Why",
      "Deer Park",
      "Devonport",
      "Diamond Creek",
      "Dianella",
      "Dingley Village",
      "Docklands",
      "Doncaster",
      "Doncaster East",
      "Donvale",
      "Doonside",
      "Doreen",
      "Drouin",
      "Drummoyne",
      "Dubbo",
      "Dulwich Hill",
      "Duncraig",
      "Eagleby",
      "Earlwood",
      "East Maitland",
      "East Perth",
      "Eastwood",
      "Echuca",
      "Edmonton",
      "Eight Mile Plains",
      "Elanora",
      "Ellenbrook",
      "Elsternwick",
      "Eltham",
      "Elwood",
      "Emerald",
      "Endeavour Hills",
      "Engadine",
      "Epping",
      "Epping",
      "Ermington",
      "Essendon",
      "Fairfield",
      "Fairfield West",
      "Fawkner",
      "Ferntree Gully",
      "Figtree",
      "Fitzroy",
      "Fitzroy North",
      "Footscray",
      "Forest Hill",
      "Forest Lake",
      "Forrestfield",
      "Forster",
      "Frankston",
      "Frankston East",
      "Frankston South",
      "Frenchs Forest",
      "Gawler",
      "Geelong",
      "Geraldton",
      "Gladesville",
      "Gladstone",
      "Glebe",
      "Glen Iris",
      "Glen Waverley",
      "Glenferrie",
      "Glenmore Park",
      "Glenorchy",
      "Glenroy",
      "Glenwood",
      "Gold Coast",
      "Golden Grove",
      "Goodna",
      "Goonellabah",
      "Gosnells",
      "Goulburn",
      "Gracemere",
      "Grafton",
      "Granville",
      "Green Valley",
      "Greenacre",
      "Greensborough",
      "Greenvale",
      "Greystanes",
      "Griffith",
      "Grovedale",
      "Gympie",
      "Hallam",
      "Hallett Cove",
      "Halls Head",
      "Hamilton Hill",
      "Hampton",
      "Hampton Park",
      "Happy Valley",
      "Harrington Park",
      "Hawthorn",
      "Hawthorn East",
      "Hawthorn South",
      "Helensvale",
      "Hervey Bay",
      "High Wycombe",
      "Highett",
      "Highton",
      "Hillarys",
      "Hillside",
      "Hinchinbrook",
      "Hobart",
      "Hope Island",
      "Hoppers Crossing",
      "Hornsby",
      "Horsham",
      "Hurstville",
      "Inala",
      "Indooroopilly",
      "Ingleburn",
      "Inverell",
      "Ivanhoe",
      "Jimboomba",
      "Kallangur",
      "Kambah",
      "Kangaroo Flat",
      "Karratha",
      "Keilor East",
      "Kellyville",
      "Kellyville Ridge",
      "Kelmscott",
      "Kelso",
      "Kensington",
      "Kew",
      "Keysborough",
      "Killara",
      "Kilsyth",
      "Kingaroy",
      "Kingsford",
      "Kingsgrove",
      "Kingsley",
      "Kingston",
      "Kingston",
      "Kingswood Park",
      "Kirwan",
      "Kogarah",
      "Kurunjang",
      "Kwinana",
      "Labrador",
      "Lakemba",
      "Lalor",
      "Landsdale",
      "Lane Cove",
      "Lane Cove North",
      "Langwarrin",
      "Lara",
      "Launceston",
      "Lavington",
      "Leeming",
      "Leichhardt",
      "Leopold",
      "Lidcombe",
      "Lilydale",
      "Liverpool",
      "Logan City",
      "Mackay",
      "Macquarie Fields",
      "Maddington",
      "Maitland",
      "Malvern East",
      "Mandurah",
      "Manly",
      "Manly West",
      "Marangaroo",
      "Mareeba",
      "Maribyrnong",
      "Maroochydore",
      "Maroubra",
      "Marrickville",
      "Marsden",
      "Marsfield",
      "Maryborough",
      "Mascot",
      "Mawson Lakes",
      "Maylands",
      "Meadow Heights",
      "Melbourne",
      "Melbourne City Centre",
      "Melton South",
      "Menai",
      "Mentone",
      "Merewether",
      "Mermaid Waters",
      "Mernda",
      "Merrylands",
      "Mildura",
      "Mill Park",
      "Minto",
      "Miranda",
      "Mitcham",
      "Mona Vale",
      "Moonee Ponds",
      "Moorooka",
      "Mooroolbark",
      "Moranbah",
      "Morayfield",
      "Morley",
      "Morningside",
      "Mornington",
      "Morphett Vale",
      "Mortdale",
      "Morwell",
      "Mosman",
      "Mount Annan",
      "Mount Barker",
      "Mount Druitt",
      "Mount Eliza",
      "Mount Gambier",
      "Mount Gravatt East",
      "Mount Isa",
      "Mount Lawley",
      "Mount Martha",
      "Mount Waverley",
      "Mountain Creek",
      "Mudgee",
      "Mudgeeraba",
      "Mulgrave",
      "Murray Bridge",
      "Murrumba Downs",
      "Muswellbrook",
      "Nambour",
      "Narangba",
      "Narre Warren",
      "Narre Warren South",
      "Nedlands",
      "Nerang",
      "Neutral Bay",
      "New Farm",
      "New Lambton",
      "Newcastle",
      "Newman",
      "Newport",
      "Newtown",
      "Ngunnawal",
      "Noble Park",
      "Nollamara",
      "North Lakes",
      "North Melbourne",
      "North Parramatta",
      "North Ryde",
      "Northcote",
      "Northmead",
      "Nunawading",
      "Nundah",
      "Oatley",
      "Ocean Grove",
      "Officer",
      "Orange",
      "Ormeau",
      "Oxenford",
      "Pacific Pines",
      "Paddington",
      "Padstow",
      "Pakenham",
      "Palm Beach",
      "Palmerston",
      "Panania",
      "Parafield Gardens",
      "Paralowie",
      "Parkdale",
      "Parkes",
      "Parkinson",
      "Parramatta",
      "Pascoe Vale",
      "Peakhurst",
      "Penrith",
      "Penshurst",
      "Perth",
      "Perth city centre",
      "Point Cook",
      "Port Kennedy",
      "Port Lincoln",
      "Port Macquarie",
      "Port Melbourne",
      "Port Pirie",
      "Potts Point",
      "Prahran",
      "Preston",
      "Prestons",
      "Prospect",
      "Punchbowl",
      "Pymble",
      "Pyrmont",
      "Quakers Hill",
      "Randwick",
      "Raymond Terrace",
      "Redbank Plains",
      "Redfern",
      "Redland Bay",
      "Regents Park",
      "Reservoir",
      "Revesby",
      "Rhodes",
      "Richmond",
      "Ringwood",
      "Ringwood East",
      "Rivervale",
      "Riverwood",
      "Robina",
      "Rochedale South",
      "Rockdale",
      "Rockhampton",
      "Rockingham",
      "Rockingham city centre",
      "Rooty Hill",
      "Rosebud",
      "Roselands",
      "Rowville",
      "Roxburgh Park",
      "Runcorn",
      "Rutherford",
      "Ryde",
      "Saint Albans",
      "Saint Ives",
      "Saint Kilda",
      "Sale",
      "Sandringham",
      "Sandy Bay",
      "Sans Souci",
      "Scarborough",
      "Seaford",
      "Seaton",
      "Secret Harbour",
      "Seven Hills",
      "Seville Grove",
      "Shailer Park",
      "Shepparton",
      "Sippy Downs",
      "Slacks Creek",
      "Smithfield",
      "Somerville",
      "South Hedland",
      "South Melbourne",
      "South Morang",
      "South Penrith",
      "South Perth",
      "South Yarra",
      "Southbank",
      "Southern River",
      "Southport",
      "Springfield Lakes",
      "Springvale",
      "Springvale South",
      "St Albans",
      "St Clair",
      "St Kilda East",
      "Strathfield",
      "Sunbury",
      "Sunnybank Hills",
      "Sunshine North",
      "Sunshine West",
      "Surfers Paradise",
      "Surrey Hills",
      "Surry Hills",
      "Sutherland",
      "Swan Hill",
      "Sydenham",
      "Sydney",
      "Sydney Central Business District",
      "Sylvania",
      "Tamworth",
      "Taree",
      "Tarneit",
      "Tarragindi",
      "Taylors Hill",
      "Taylors Lakes",
      "Templestowe",
      "Templestowe Lower",
      "Terrigal",
      "Tewantin",
      "The Gap",
      "The Ponds",
      "Thomastown",
      "Thornbury",
      "Thornlands",
      "Thornlie",
      "Toongabbie",
      "Toorak",
      "Toowong",
      "Toowoomba",
      "Torquay",
      "Townsville",
      "Traralgon",
      "Truganina",
      "Turramurra",
      "Umina Beach",
      "Upper Coomera",
      "Urangan",
      "Varsity Lakes",
      "Vermont",
      "Vermont South",
      "Victoria Point",
      "Wahroonga",
      "Waikiki",
      "Wallan",
      "Wallsend",
      "Wangaratta",
      "Wanneroo",
      "Wantirna",
      "Wantirna South",
      "Warnbro",
      "Warner",
      "Warragul",
      "Warrnambool",
      "Warwick",
      "Wellington Point",
      "Wembley",
      "Wendouree",
      "Wentworthville",
      "Werribee",
      "West Footscray",
      "West Pennant Hills",
      "West Ryde",
      "West Wodonga",
      "Westmead",
      "Wheelers Hill",
      "Wiley Park",
      "Willetton",
      "Williamstown",
      "Winston Hills",
      "Wishart",
      "Wodonga",
      "Wollert",
      "Wollongong",
      "Wollongong city centre",
      "Woodcroft",
      "Woodridge",
      "Woonona",
      "Woy Woy",
      "Wyndham Vale",
      "Wynnum",
      "Wynnum West",
      "Yagoona",
      "Yarraville",
      "Yokine",
      "Young",
      "Zetland",
    ],
  },
  Austria: {
    code: "AT",
    cities: [
      "Amstetten",
      "Andritz",
      "Annabichl",
      "Arzl",
      "Baden",
      "Bludenz",
      "Bregenz",
      "Brunn am Gebirge",
      "Donaustadt",
      "Dornbirn",
      "Eggenberg",
      "Favoriten",
      "Feldkirch",
      "Floridsdorf",
      "Gaenserndorf",
      "Geidorf",
      "Gmunden",
      "Goesting",
      "Goetzis",
      "Graz",
      "Gries",
      "Hall in Tirol",
      "Hard",
      "Hernals",
      "Hietzing",
      "Hoetting",
      "Hohenems",
      "Imst",
      "Innere Stadt",
      "Innsbruck",
      "Jakomini",
      "Klagenfurt am Woerthersee",
      "Klosterneuburg",
      "Knittelfeld",
      "Korneuburg",
      "Krems an der Donau",
      "Lauterach",
      "Lend",
      "Liebenau",
      "Lienz",
      "Linz",
      "Lustenau",
      "Meidling",
      "Moedling",
      "Neunkirchen",
      "Ottakring",
      "Perchtoldsdorf",
      "Pradl",
      "Rankweil",
      "Ried im Innkreis",
      "Saalfelden am Steinernen Meer",
      "Salzburg",
      "Sankt Leonhard",
      "Sankt Martin",
      "Sankt Peter",
      "Sankt Peter",
      "Sankt Poelten",
      "Sankt Veit an der Glan",
      "Schwaz",
      "Schwechat",
      "Simmering",
      "Spittal an der Drau",
      "Steyr",
      "Stockerau",
      "Strassgang",
      "Strasshof an der Nordbahn",
      "Telfs",
      "Traun",
      "Tulln",
      "Vienna",
      "Villach",
      "Waltendorf",
      "Wels",
      "Wetzelsdorf",
      "Wiener Neustadt",
      "Wilten",
      "Woergl",
    ],
  },
  Azerbaijan: {
    code: "AZ",
    cities: [
      "Agdam",
      "Agdas",
      "Agdzhabedy",
      "Aghstafa",
      "Aghsu",
      "Ahmadbayli",
      "Amirdzhan",
      "Astara",
      "Badamdar",
      "Bakixanov",
      "Baku",
      "Balakhani",
      "Barda",
      "Belokany",
      "Beylagan",
      "Bilajari",
      "Binagadi",
      "Biny Selo",
      "Buzovna",
      "Culfa",
      "Divichibazar",
      "Fizuli",
      "Ganja",
      "Geytepe",
      "Goeycay",
      "Goranboy",
      "Goygol",
      "Haci Zeynalabdin",
      "Haciqabul",
      "Hovsan",
      "Imishli",
      "Ismayilli",
      "Jalilabad",
      "Khirdalan",
      "Kyurdarmir",
      "Lankaran",
      "Lerik",
      "Lokbatan",
      "Mardakan",
      "Martakert",
      "Mastaga",
      "Mingelchaur",
      "Naxcivan",
      "Neftcala",
      "Pirallahi",
      "Pushkino",
      "Qabala",
      "Qaracuxur",
      "Qax",
      "Qazax",
      "Qobustan",
      "Quba",
      "Qusar",
      "Saatli",
      "Sabirabad",
      "Sabuncu",
      "Salyan",
      "Saray",
      "Shamakhi",
      "Shamkhor",
      "Sheki",
      "Sirvan",
      "Sumqayit",
      "Susa",
      "Terter",
      "Tovuz",
      "Ujar",
      "Xacmaz",
      "Xankandi",
      "Xudat",
      "Yeni Suraxani",
      "Yevlakh",
      "Zabrat",
      "Zaqatala",
      "Zardob",
      "Zyrya",
    ],
  },
  Bahamas: {
    code: "BS",
    cities: ["Freeport", "Lucaya", "Nassau", "West End"],
  },
  Bahrain: {
    code: "BH",
    cities: [
      "Al Hadd",
      "Al Muharraq",
      "Ar Rifa'",
      "Dar Kulayb",
      "Jidd Hafs",
      "Madinat Hamad",
      "Madinat `Isa",
      "Manama",
      "Sitrah",
    ],
  },
  Bangladesh: {
    code: "BD",
    cities: [
      "Azimpur",
      "Badarganj",
      "Bagerhat",
      "Bajitpur",
      "Bandarban",
      "Baniachang",
      "Barishal",
      "Bera",
      "Bhairab Bazar",
      "Bhandaria",
      "Bhatpara Abhaynagar",
      "Bheramara",
      "Bhola",
      "Bibir Hat",
      "Bogra",
      "Burhanuddin",
      "Char Bhadrasan",
      "Chattogram",
      "Chhagalnaiya",
      "Chhatak",
      "Chilmari",
      "Comilla",
      "Cox's Bazar",
      "Dhaka",
      "Dinajpur",
      "Dohar",
      "Faridpur",
      "Feni",
      "Gafargaon",
      "Gaurnadi",
      "Habiganj",
      "Hajiganj",
      "Ishwardi",
      "Jamalpur",
      "Jessore",
      "Jhingergacha",
      "Joypur Hat",
      "Kalia",
      "Kaliganj",
      "Kesabpur",
      "Khagrachhari",
      "Khulna",
      "Kishorganj",
      "Kushtia",
      "Laksham",
      "Lakshmipur",
      "Lalmohan",
      "Lalmonirhat",
      "Madaripur",
      "Manikchari",
      "Mathba",
      "Maulavi Bazar",
      "Mehendiganj",
      "Mirzapur",
      "Morrelgonj",
      "Muktagacha",
      "Mymensingh",
      "Nabinagar",
      "Nagarpur",
      "Nageswari",
      "Nalchiti",
      "Narail",
      "Narayanganj",
      "Narsingdi",
      "Natore",
      "Nawabganj",
      "Netrakona",
      "Pabna",
      "Palang",
      "Paltan",
      "Panchagarh",
      "Paotana Hat",
      "Par Naogaon",
      "Parbatipur",
      "Patiya",
      "Phultala",
      "Pirgaaj",
      "Pirojpur",
      "Puthia",
      "Raipur",
      "Rajshahi",
      "Ramganj",
      "Rangpur",
      "Raojan",
      "Saidpur",
      "Sakhipur",
      "Sandwip",
      "Sarankhola",
      "Sarishabari",
      "Satkania",
      "Satkhira",
      "Shahzadpur",
      "Sherpur",
      "Shibganj",
      "Shibganj",
      "Sirajganj",
      "Sonargaon",
      "Sylhet",
      "Tangail",
      "Teknaf",
      "Thakurgaon",
      "Tungi",
      "Tungipara",
      "Uttar Char Fasson",
    ],
  },
  Barbados: {
    code: "BB",
    cities: ["Bridgetown"],
  },
  Belarus: {
    code: "BY",
    cities: [
      "Ashmyany",
      "Asipovichy",
      "Astravyets",
      "Babruysk",
      "Baran'",
      "Baranovichi",
      "Barysaw",
      "Bierazino",
      "Brest",
      "Byaroza",
      "Byarozawka",
      "Byelaazyorsk",
      "Bykhaw",
      "Chashniki",
      "Chavusy",
      "Dobrush",
      "Drahichyn",
      "Dzyarzhynsk",
      "Fanipol",
      "Gorodok",
      "Hantsavichy",
      "Hlybokaye",
      "Homyel'",
      "Horad Zhodzina",
      "Horki",
      "Hrodna",
      "Ivanovo",
      "Ivatsevichi",
      "Kalinkavichy",
      "Kalodzishchy",
      "Kapyl'",
      "Kastsyukowka",
      "Khoyniki",
      "Klimavichy",
      "Kobryn",
      "Kostyukovichi",
      "Krychaw",
      "Lahoysk",
      "Lida",
      "Luninyets",
      "Lyakhovichi",
      "Lyasny",
      "Lyepyel'",
      "Lyuban'",
      "Mahilyow",
      "Maladziecna",
      "Mar''ina Horka",
      "Mazyr",
      "Mikashevichy",
      "Minsk",
      "Miory",
      "Mosty",
      "Mscislau",
      "Navapolatsk",
      "Novogrudok",
      "Novolukoml'",
      "Nyasvizh",
      "Orsha",
      "Pastavy",
      "Pinsk",
      "Polatsk",
      "Pruzhany",
      "Pyetrykaw",
      "Rahachow",
      "Rechytsa",
      "Salihorsk",
      "Shchuchyn",
      "Shklow",
      "Skidel'",
      "Slonim",
      "Slutsk",
      "Smaliavicy",
      "Smarhon",
      "Staryya Darohi",
      "Stolin",
      "Stowbtsy",
      "Svyetlahorsk",
      "Valozhyn",
      "Vilyeyka",
      "Vitebsk",
      "Volkovysk",
      "Zaslawye",
      "Zhabinka",
      "Zhlobin",
      "Zytkavicy",
    ],
  },
  Belgium: {
    code: "BE",
    cities: [
      "Aalst",
      "Aalter",
      "Aarschot",
      "Aartselaar",
      "Aiseau",
      "Alken",
      "Amay",
      "Andenne",
      "Anderlecht",
      "Anderlues",
      "Angleur",
      "Ans",
      "Antwerpen",
      "Anzegem",
      "Arendonk",
      "Arlon",
      "Asse",
      "Assebroek",
      "Assenede",
      "Ath",
      "Aubange",
      "Auderghem",
      "Aywaille",
      "Balen",
      "Basse Lasne",
      "Bastogne",
      "Beernem",
      "Beerse",
      "Beersel",
      "Beloeil",
      "Belsele",
      "Berchem",
      "Berchem-Sainte-Agathe",
      "Beringen",
      "Berlaar",
      "Berlare",
      "Bernissart",
      "Bevere",
      "Beveren",
      "Beyne-Heusay",
      "Bilzen",
      "Binche",
      "Blankenberge",
      "Blauwput",
      "Blegny",
      "Bocholt",
      "Boechout",
      "Boom",
      "Boortmeerbeek",
      "Borgerhout",
      "Bornem",
      "Borsbeek",
      "Boussu",
      "Braine-l'Alleud",
      "Braine-le-Comte",
      "Brasschaat",
      "Brecht",
      "Bredene",
      "Bree",
      "Bressoux",
      "Brugge",
      "Brussels",
      "Buggenhout",
      "Chapelle-lez-Herlaimont",
      "Charleroi",
      "Chatelet",
      "Chatelineau",
      "Chaudfontaine",
      "Chaumont-Gistoux",
      "Ciney",
      "Colfontaine",
      "Couillet",
      "Courcelles",
      "Couvin",
      "Damme",
      "De Haan",
      "De Pinte",
      "Deerlijk",
      "Deinze",
      "Denderleeuw",
      "Dendermonde",
      "Destelbergen",
      "Deurne",
      "Diepenbeek",
      "Diest",
      "Diksmuide",
      "Dilbeek",
      "Dinant",
      "Dison",
      "Dour",
      "Drongen",
      "Duffel",
      "Durbuy",
      "Edegem",
      "Eeklo",
      "Eghezee",
      "Eisden",
      "Ekeren",
      "Enghien",
      "Erembodegem",
      "Ertvelde",
      "Esneux",
      "Etterbeek",
      "Eupen",
      "Evere",
      "Evergem",
      "Farciennes",
      "Flemalle-Grande",
      "Flemalle-Haute",
      "Fleron",
      "Fleurus",
      "Florennes",
      "Forest",
      "Frameries",
      "Frasnes-lez-Buissenal",
      "Ganshoren",
      "Gavere",
      "Geel",
      "Gembloux",
      "Genappe",
      "Genk",
      "Gent",
      "Gentbrugge",
      "Geraardsbergen",
      "Gilly",
      "Gistel",
      "Gosselies",
      "Grace-Berleur",
      "Grez-Doiceau",
      "Grimbergen",
      "Grivegnee",
      "Grobbendonk",
      "Haaltert",
      "Halle",
      "Hamme",
      "Hannut",
      "Harelbeke",
      "Hasselt",
      "Heist",
      "Heist-op-den-Berg",
      "Helchteren",
      "Herent",
      "Herentals",
      "Herk-de-Stad",
      "Herselt",
      "Herstal",
      "Herve",
      "Herzele",
      "Heule",
      "Heusden",
      "Heusy",
      "Heverlee",
      "Hoboken",
      "Hoeilaart",
      "Hollogne-aux-Pierres",
      "Hoogstraten",
      "Houdeng-Goegnies",
      "Houthalen",
      "Huy",
      "Ichtegem",
      "Ieper",
      "Ingelmunster",
      "Ixelles",
      "Izegem",
      "Jabbeke",
      "Jambes",
      "Jemappes",
      "Jemeppe-sur-Meuse",
      "Jette",
      "Jodoigne",
      "Jumet",
      "Jupille-sur-Meuse",
      "Kalmthout",
      "Kapellen",
      "Kasterlee",
      "Kessel-Lo",
      "Kinrooi",
      "Knokke",
      "Knokke-Heist",
      "Koekelberg",
      "Koersel",
      "Koksijde",
      "Kontich",
      "Kortemark",
      "Kortenberg",
      "Kortrijk",
      "Kraainem",
      "Kruibeke",
      "Kuringen",
      "Kuurne",
      "La Calamine",
      "La Louviere",
      "Laarne",
      "Lanaken",
      "Landen",
      "Lebbeke",
      "Lede",
      "Leopoldsburg",
      "Lessines",
      "Leuven",
      "Liedekerke",
      "Liege",
      "Lier",
      "Lille",
      "Lochristi",
      "Lokeren",
      "Lommel",
      "Louvain-la-Neuve",
      "Lubbeek",
      "Lummen",
      "Maaseik",
      "Maasmechelen",
      "Machelen",
      "Maldegem",
      "Malmedy",
      "Manage",
      "Marche-en-Famenne",
      "Marchienne-au-Pont",
      "Marcinelle",
      "Mariakerke",
      "Mechelen",
      "Mechelen-aan-de-Maas",
      "Meise",
      "Melle",
      "Melsele",
      "Menen",
      "Merchtem",
      "Merelbeke",
      "Merksem",
      "Mettet",
      "Meulebeke",
      "Middelkerke",
      "Mol",
      "Molenbeek-Saint-Jean",
      "Mons",
      "Mont-sur-Marchienne",
      "Montegnee",
      "Montignies-sur-Sambre",
      "Moorslede",
      "Morlanwelz-Mariemont",
      "Mortsel",
      "Mouscron",
      "Namur",
      "Nazareth",
      "Neerpelt",
      "Nevele",
      "Nieuwpoort",
      "Nijlen",
      "Ninove",
      "Nivelles",
      "Oosterzele",
      "Oostkamp",
      "Opwijk",
      "Ostend",
      "Oud-Heverlee",
      "Oud-Turnhout",
      "Oudenaarde",
      "Ougree",
      "Oupeye",
      "Overijse",
      "Overpelt",
      "Paal",
      "Peer",
      "Peruwelz",
      "Petegem-aan-de-Leie",
      "Pont-a-Celles",
      "Poperinge",
      "Profondeville",
      "Putte",
      "Puurs",
      "Quaregnon",
      "Ranst",
      "Ravels",
      "Rebecq-Rognon",
      "Riemst",
      "Rijkevorsel",
      "Rixensart",
      "Rochefort",
      "Roeselare",
      "Ronse",
      "Rotselaar",
      "Rumbeke",
      "Rumst",
      "Saint-Ghislain",
      "Saint-Gilles",
      "Saint-Josse-ten-Noode",
      "Saint-Nicolas",
      "Schaerbeek",
      "Schilde",
      "Schoten",
      "Seneffe",
      "Seraing",
      "Sint-Amandsberg",
      "Sint-Andries",
      "Sint-Genesius-Rode",
      "Sint-Gillis-Waas",
      "Sint-Gillis-bij-Dendermonde",
      "Sint-Katelijne-Waver",
      "Sint-Kruis",
      "Sint-Michiels",
      "Sint-Niklaas",
      "Sint-Pieters-Leeuw",
      "Sint-Truiden",
      "Soignies",
      "Soumagne",
      "Spa",
      "Sprimont",
      "Stabroek",
      "Staden",
      "Steenokkerzeel",
      "Stekene",
      "Strombeek-Bever",
      "Temse",
      "Ternat",
      "Tervuren",
      "Tessenderlo",
      "Theux",
      "Thuin",
      "Tielt",
      "Tienen",
      "Tongeren",
      "Torhout",
      "Tournai",
      "Tremelo",
      "Tubize",
      "Turnhout",
      "Uccle",
      "Verviers",
      "Veurne",
      "Vilvoorde",
      "Virton",
      "Vise",
      "Vosselaar",
      "Waasmunster",
      "Walcourt",
      "Wanze",
      "Waregem",
      "Waremme",
      "Wasmes",
      "Waterloo",
      "Watermael-Boitsfort",
      "Wavre",
      "Wemmel",
      "Wervik",
      "Westerlo",
      "Wetteren",
      "Wevelgem",
      "Wezembeek-Oppem",
      "Wichelen",
      "Willebroek",
      "Wilrijk",
      "Wingene",
      "Woluwe-Saint-Lambert",
      "Wommelgem",
      "Wondelgem",
      "Wuustwezel",
      "Zandhoven",
      "Zaventem",
      "Zedelgem",
      "Zele",
      "Zellik",
      "Zelzate",
      "Zemst",
      "Zolder",
      "Zonhoven",
      "Zonnebeke",
      "Zottegem",
      "Zulte",
      "Zwevegem",
      "Zwijndrecht",
    ],
  },
  Belize: {
    code: "BZ",
    cities: [
      "Belize City",
      "Belmopan",
      "Corozal",
      "Dangriga",
      "Orange Walk",
      "San Ignacio",
      "San Pedro",
    ],
  },
  Benin: {
    code: "BJ",
    cities: [
      "Abomey",
      "Abomey-Calavi",
      "Adjaouere",
      "Adjarra",
      "Allada",
      "Aplahoue",
      "Athieme",
      "Avrankou",
      "Banigbe",
      "Banikoara",
      "Bante",
      "Bassila",
      "Bembereke",
      "Beterou",
      "Bohicon",
      "Boukoumbe",
      "Cobly",
      "Come",
      "Cotonou",
      "Cove",
      "Dassa-Zoume",
      "Djakotome",
      "Djidja",
      "Djougou",
      "Dogbo",
      "Ekpe",
      "Glazoue",
      "Godome",
      "Gogounou",
      "Hevie",
      "Kalale",
      "Kandi",
      "Kerou",
      "Ketou",
      "Klouekanme",
      "Kopargo",
      "Kouande",
      "Kpozoun",
      "Lalo",
      "Lokossa",
      "Malanville",
      "Materi",
      "Misserete",
      "Natitingou",
      "Ndali",
      "Nikki",
      "Ouake",
      "Ouesse",
      "Ouidah",
      "Ouinhi",
      "Parakou",
      "Pehonko",
      "Perere",
      "Pobe",
      "Porto-Novo",
      "Sakete",
      "Savalou",
      "Save",
      "Se",
      "Segbana",
      "Semi",
      "Sinende",
      "Tanguieta",
      "Tchaourou",
      "Tori-Bossito",
      "Toukountouna",
      "Toviklin",
      "Ze",
      "Zinvie",
    ],
  },
  Bhutan: {
    code: "BT",
    cities: [
      "Paro",
      "Pemagatshel",
      "Phuntsholing",
      "Punakha",
      "Sarpang",
      "Thimphu",
      "Tsirang",
    ],
  },
  Bolivia: {
    code: "BO",
    cities: [
      "Achocalla",
      "Ascencion de Guarayos",
      "Ascension",
      "Bermejo",
      "Camiri",
      "Caranavi",
      "Cobija",
      "Cochabamba",
      "Colcapirhua",
      "Colchani",
      "Cotoca",
      "El Torno",
      "Guayaramerin",
      "Huanuni",
      "La Paz",
      "Laja",
      "Llallagua",
      "Mecapaca",
      "Mineros",
      "Mizque",
      "Montero",
      "Okinawa Uno",
      "Oruro",
      "Patacamaya",
      "Portachuelo",
      "Potosi",
      "Puerto Quijarro",
      "Puerto Suarez",
      "Punata",
      "Quillacollo",
      "Riberalta",
      "Rurrenabaque",
      "Sacaba",
      "San Borja",
      "San Ignacio de Velasco",
      "Santa Ana de Yacuma",
      "Santa Cruz de la Sierra",
      "Sipe Sipe",
      "Sucre",
      "Tarija",
      "Tiquipaya",
      "Trinidad",
      "Tupiza",
      "Uyuni",
      "Viacha",
      "Villa Yapacani",
      "Villamontes",
      "Villazon",
      "Vinto",
      "Warnes",
      "Yacuiba",
    ],
  },
  "Bosnia and Herzegovina": {
    code: "BA",
    cities: [
      "Banja Luka",
      "Bihac",
      "Bijeljina",
      "Bileca",
      "Bosanska Krupa",
      "Brcko",
      "Bugojno",
      "Cazin",
      "Doboj",
      "Foca",
      "Gorazde",
      "Gracanica",
      "Gradacac",
      "Gradiska",
      "Kakanj",
      "Konjic",
      "Livno",
      "Lukavac",
      "Mostar",
      "Mrkonjic Grad",
      "Novi Travnik",
      "Odzak",
      "Prijedor",
      "Prozor",
      "Sanski Most",
      "Sarajevo",
      "Sipovo",
      "Stijena",
      "Travnik",
      "Trebinje",
      "Tuzla",
      "Velika Kladusa",
      "Visoko",
      "Zavidovici",
      "Zenica",
      "Zivinice",
    ],
  },
  Botswana: {
    code: "BW",
    cities: [
      "Bobonong",
      "Francistown",
      "Gabane",
      "Gaborone",
      "Ghanzi",
      "Gumare",
      "Janeng",
      "Jwaneng",
      "Kanye",
      "Kopong",
      "Letlhakane",
      "Lobatse",
      "Mahalapye",
      "Maun",
      "Metsemotlhaba",
      "Mmadinare",
      "Mmopone",
      "Mochudi",
      "Mogoditshane",
      "Molepolole",
      "Mosopa",
      "Oodi",
      "Palapye",
      "Ramotswa",
      "Selebi-Phikwe",
      "Serowe",
      "Shakawe",
      "Tati Siding",
      "Thamaga",
      "Tlokweng",
      "Tonota",
      "Tshabong",
      "Tutume",
    ],
  },
  Brazil: {
    code: "BR",
    cities: [
      "Abaete",
      "Abaetetuba",
      "Abreu e Lima",
      "Acajutiba",
      "Acara",
      "Acarau",
      "Acopiara",
      "Acu",
      "Acucena",
      "Adamantina",
      "Afogados da Ingazeira",
      "Afonso Claudio",
      "Agrestina",
      "Agua Branca",
      "Agua Preta",
      "Aguai",
      "Aguas Belas",
      "Aguas Formosas",
      "Aguas Vermelhas",
      "Aguas de Lindoia",
      "Agudos",
      "Aimores",
      "Alagoa Grande",
      "Alagoinhas",
      "Alegre",
      "Alegrete",
      "Alem Paraiba",
      "Alenquer",
      "Alfenas",
      "Alhandra",
      "Almeirim",
      "Almenara",
      "Almirante Tamandare",
      "Alpinopolis",
      "Altamira",
      "Altania",
      "Altinho",
      "Altinopolis",
      "Alto Parana",
      "Alto Piquiri",
      "Altos",
      "Alvares Machado",
      "Alvinopolis",
      "Alvorada",
      "Alvorada",
      "Amaraji",
      "Amarante do Maranhao",
      "Amargosa",
      "America Dourada",
      "Americana",
      "Americo Brasiliense",
      "Amontada",
      "Amparo",
      "Ampere",
      "Ananindeua",
      "Anapolis",
      "Anastacio",
      "Andradas",
      "Andradina",
      "Andrelandia",
      "Angatuba",
      "Angra dos Reis",
      "Anguera",
      "Anicuns",
      "Antonina",
      "Aparecida",
      "Aparecida de Goiania",
      "Aparecida do Taboado",
      "Apiai",
      "Apodi",
      "Apucarana",
      "Aquidaba",
      "Aquidauana",
      "Aquiraz",
      "Aracaju",
      "Aracati",
      "Aracatuba",
      "Araci",
      "Aracoiaba",
      "Aracoiaba",
      "Aracoiaba da Serra",
      "Aracruz",
      "Aracuai",
      "Aragarcas",
      "Araguaina",
      "Araguari",
      "Arapiraca",
      "Arapongas",
      "Ararangua",
      "Araraquara",
      "Araras",
      "Arari",
      "Araripe",
      "Araripina",
      "Araruama",
      "Araucaria",
      "Araxa",
      "Arcos",
      "Arcoverde",
      "Areado",
      "Areal",
      "Areia",
      "Areia Branca",
      "Arinos",
      "Aripuana",
      "Ariquemes",
      "Armacao dos Buzios",
      "Arraial do Cabo",
      "Arraias",
      "Arroio Grande",
      "Arroio do Meio",
      "Arroio dos Ratos",
      "Artur Nogueira",
      "Aruja",
      "Assai",
      "Assare",
      "Assis",
      "Astolfo Dutra",
      "Astorga",
      "Atalaia",
      "Atibaia",
      "Augusto Correa",
      "Auriflama",
      "Aurora",
      "Avare",
      "Bacabal",
      "Bady Bassitt",
      "Baependi",
      "Bage",
      "Baiao",
      "Baixo Guandu",
      "Balneario Camboriu",
      "Balsas",
      "Bambui",
      "Bandeirantes",
      "Barao de Cocais",
      "Barauna",
      "Barbacena",
      "Barbalha",
      "Barcarena",
      "Bariri",
      "Barra",
      "Barra Bonita",
      "Barra Mansa",
      "Barra Velha",
      "Barra da Estiva",
      "Barra de Santo Antonio",
      "Barra de Sao Francisco",
      "Barra do Bugres",
      "Barra do Corda",
      "Barra do Garcas",
      "Barra do Pirai",
      "Barra dos Coqueiros",
      "Barras",
      "Barreiras",
      "Barreirinha",
      "Barreirinhas",
      "Barreiro do Jaiba",
      "Barreiros",
      "Barretos",
      "Barrinha",
      "Barro",
      "Barro Alto",
      "Barroquinha",
      "Barroso",
      "Barueri",
      "Bastos",
      "Batalha",
      "Batatais",
      "Baturite",
      "Bauru",
      "Bayeux",
      "Bebedouro",
      "Beberibe",
      "Bela Cruz",
      "Bela Vista",
      "Bela Vista de Goias",
      "Bela Vista do Paraiso",
      "Belem",
      "Belem",
      "Belem de Sao Francisco",
      "Belford Roxo",
      "Belo Horizonte",
      "Belo Jardim",
      "Belo Oriente",
      "Benevides",
      "Benjamin Constant",
      "Bento Goncalves",
      "Bertioga",
      "Betim",
      "Bezerros",
      "Bicas",
      "Biguacu",
      "Birigui",
      "Biritiba Mirim",
      "Blumenau",
      "Boa Esperanca",
      "Boa Esperanca do Sul",
      "Boa Viagem",
      "Boa Vista",
      "Boca do Acre",
      "Bocaiuva",
      "Boituva",
      "Bom Conselho",
      "Bom Despacho",
      "Bom Jardim",
      "Bom Jardim",
      "Bom Jesus",
      "Bom Jesus da Lapa",
      "Bom Jesus do Galho",
      "Bom Jesus do Itabapoana",
      "Bom Jesus dos Perdoes",
      "Bom Sucesso",
      "Bonito",
      "Boqueirao",
      "Boquim",
      "Borba",
      "Borborema",
      "Borda da Mata",
      "Botelhos",
      "Botucatu",
      "Braco do Norte",
      "Braganca",
      "Braganca Paulista",
      "Brasileia",
      "Brasilia",
      "Brasilia de Minas",
      "Brejo",
      "Brejo Santo",
      "Brejo da Madre de Deus",
      "Breves",
      "Brodosqui",
      "Brotas",
      "Brumadinho",
      "Brumado",
      "Brusque",
      "Buerarema",
      "Buique",
      "Buri",
      "Buritama",
      "Buriti Bravo",
      "Buritis",
      "Buritizeiro",
      "Butia",
      "Caapora",
      "Caarapo",
      "Cabedelo",
      "Cabo",
      "Cabo Frio",
      "Cabreuva",
      "Cabrobo",
      "Cacador",
      "Cacapava",
      "Cacapava do Sul",
      "Cacequi",
      "Cachoeira",
      "Cachoeira do Sul",
      "Cachoeiras de Macacu",
      "Cachoeirinha",
      "Cachoeirinha",
      "Cachoeiro de Itapemirim",
      "Cacoal",
      "Caconde",
      "Cacu",
      "Cacule",
      "Caete",
      "Caetite",
      "Cafarnaum",
      "Cafelandia",
      "Caiaponia",
      "Caico",
      "Caieiras",
      "Cajamar",
      "Cajati",
      "Cajazeiras",
      "Cajueiro",
      "Cajuru",
      "Caldas Novas",
      "Camacari",
      "Camamu",
      "Camanducaia",
      "Camapua",
      "Camaqua",
      "Cambara",
      "Cambe",
      "Cambui",
      "Cambuquira",
      "Cameta",
      "Camocim",
      "Camocim de Sao Felix",
      "Campanha",
      "Campestre",
      "Campina Grande",
      "Campina Grande do Sul",
      "Campina Verde",
      "Campinas",
      "Campinas",
      "Campo Alegre",
      "Campo Belo",
      "Campo Formoso",
      "Campo Grande",
      "Campo Largo",
      "Campo Maior",
      "Campo Mourao",
      "Campo Verde",
      "Campos Altos",
      "Campos Belos",
      "Campos Gerais",
      "Campos Novos",
      "Campos Sales",
      "Campos do Jordao",
      "Campos dos Goytacazes",
      "Cananeia",
      "Canarana",
      "Canavieiras",
      "Candelaria",
      "Candido Mota",
      "Canela",
      "Canguaretama",
      "Cangucu",
      "Canhotinho",
      "Caninde",
      "Caninde de Sao Francisco",
      "Canoas",
      "Canoinhas",
      "Cantagalo",
      "Canto do Buriti",
      "Capanema",
      "Capao Bonito",
      "Capao da Canoa",
      "Capela",
      "Capela",
      "Capela do Alto",
      "Capelinha",
      "Capim Grosso",
      "Capinopolis",
      "Capinzal",
      "Capitao Poco",
      "Capivari",
      "Caraguatatuba",
      "Carambei",
      "Carandai",
      "Carangola",
      "Carapicuiba",
      "Caratinga",
      "Carauari",
      "Caraubas",
      "Carazinho",
      "Cardoso",
      "Carinhanha",
      "Carira",
      "Caririacu",
      "Carlos Barbosa",
      "Carlos Chagas",
      "Carmo",
      "Carmo do Cajuru",
      "Carmo do Paranaiba",
      "Carmo do Rio Claro",
      "Carolina",
      "Carpina",
      "Caruaru",
      "Carutapera",
      "Casa Branca",
      "Cascavel",
      "Cascavel",
      "Casimiro de Abreu",
      "Cassia",
      "Cassilandia",
      "Castanhal",
      "Castelo",
      "Castilho",
      "Castro",
      "Castro Alves",
      "Cataguases",
      "Catalao",
      "Catanduva",
      "Catende",
      "Catole do Rocha",
      "Catu",
      "Caucaia",
      "Caxambu",
      "Caxias",
      "Caxias do Sul",
      "Ceara Mirim",
      "Cedro",
      "Celso Ramos",
      "Ceres",
      "Cerqueira Cesar",
      "Cerquilho",
      "Cha Grande",
      "Chapada dos Guimaraes",
      "Chapadinha",
      "Chapeco",
      "Charqueada",
      "Charqueadas",
      "Chavantes",
      "Chopinzinho",
      "Cianorte",
      "Cicero Dantas",
      "Cipo",
      "Claudio",
      "Clevelandia",
      "Coaraci",
      "Coari",
      "Cocal",
      "Cocal",
      "Codajas",
      "Codo",
      "Coelho Neto",
      "Colatina",
      "Colinas",
      "Colombo",
      "Colonia Leopoldina",
      "Colorado",
      "Conceicao da Barra",
      "Conceicao da Feira",
      "Conceicao das Alagoas",
      "Conceicao do Araguaia",
      "Conceicao do Coite",
      "Conceicao do Jacuipe",
      "Conceicao do Mato Dentro",
      "Conceicao do Rio Verde",
      "Conchal",
      "Conchas",
      "Concordia",
      "Condado",
      "Conde",
      "Conde",
      "Congonhas",
      "Conselheiro Lafaiete",
      "Conselheiro Pena",
      "Contagem",
      "Coracao de Jesus",
      "Corbelia",
      "Cordeiro",
      "Cordeiropolis",
      "Coreau",
      "Coremas",
      "Corinto",
      "Cornelio Procopio",
      "Coroata",
      "Coromandel",
      "Coronel Fabriciano",
      "Coronel Vivida",
      "Correia Pinto",
      "Correntina",
      "Corumba",
      "Corupa",
      "Coruripe",
      "Cosmopolis",
      "Costa Rica",
      "Cotia",
      "Coxim",
      "Crateus",
      "Crato",
      "Cravinhos",
      "Criciuma",
      "Cristalina",
      "Crixas",
      "Cruz Alta",
      "Cruz das Almas",
      "Cruzeiro",
      "Cruzeiro do Oeste",
      "Cruzeiro do Sul",
      "Cruzilia",
      "Cubatao",
      "Cuiaba",
      "Cuite",
      "Cunha",
      "Cupira",
      "Curaca",
      "Curitiba",
      "Curitibanos",
      "Currais Novos",
      "Curuca",
      "Cururupu",
      "Curvelo",
      "Custodia",
      "Delmiro Gouveia",
      "Demerval Lobao",
      "Descalvado",
      "Diadema",
      "Diamantina",
      "Diamantino",
      "Divinopolis",
      "Dois Corregos",
      "Dois Vizinhos",
      "Dom Pedrito",
      "Dom Pedro",
      "Dores do Indaia",
      "Dourados",
      "Duartina",
      "Duque de Caxias",
      "Ecoporanga",
      "Eirunepe",
      "Elesbao Veloso",
      "Elias Fausto",
      "Eloi Mendes",
      "Embu Guacu",
      "Embu das Artes",
      "Encantado",
      "Encruzilhada do Sul",
      "Engenheiro Beltrao",
      "Entre Rios",
      "Erechim",
      "Escada",
      "Esmeraldas",
      "Espera Feliz",
      "Esperanca",
      "Esperantina",
      "Esperantinopolis",
      "Espinosa",
      "Espirito Santo do Pinhal",
      "Esplanada",
      "Espumoso",
      "Estancia",
      "Estancia Velha",
      "Esteio",
      "Estreito",
      "Estrela",
      "Euclides da Cunha",
      "Eunapolis",
      "Eusebio",
      "Extrema",
      "Extremoz",
      "Exu",
      "Farias Brito",
      "Farroupilha",
      "Fartura",
      "Faxinal",
      "Feijo",
      "Feira Nova",
      "Feira de Santana",
      "Fernandopolis",
      "Ferraz de Vasconcelos",
      "Flores",
      "Flores da Cunha",
      "Floresta",
      "Florestopolis",
      "Floriano",
      "Florianopolis",
      "Fonte Boa",
      "Formiga",
      "Formosa",
      "Formosa do Rio Preto",
      "Forquilha",
      "Forquilhinha",
      "Fortaleza",
      "Foz do Iguacu",
      "Franca",
      "Francisco Beltrao",
      "Francisco Morato",
      "Francisco Sa",
      "Franco da Rocha",
      "Frederico Westphalen",
      "Freguesia do Ribeirao da Ilha",
      "Frutal",
      "Fundao",
      "Gameleira",
      "Gandu",
      "Garanhuns",
      "Garca",
      "Garibaldi",
      "Gaspar",
      "Girua",
      "Gloria do Goita",
      "Goiana",
      "Goianapolis",
      "Goianesia",
      "Goiania",
      "Goianinha",
      "Goianira",
      "Goias",
      "Goiatuba",
      "Governador Valadares",
      "Grajau",
      "Granja",
      "Gravata",
      "Gravatai",
      "Guacui",
      "Guaiba",
      "Guaira",
      "Guaiuba",
      "Guajara Mirim",
      "Guanambi",
      "Guanhaes",
      "Guapiacu",
      "Guapimirim",
      "Guapo",
      "Guapore",
      "Guara",
      "Guarabira",
      "Guaraciaba do Norte",
      "Guaramirim",
      "Guaranesia",
      "Guarapari",
      "Guarapuava",
      "Guararapes",
      "Guararema",
      "Guaratingueta",
      "Guaratuba",
      "Guariba",
      "Guaruja",
      "Guarulhos",
      "Guaxupe",
      "Guiratinga",
      "Gurupi",
      "Herval",
      "Hidrolandia",
      "Horizonte",
      "Horizontina",
      "Hortolandia",
      "Humaita",
      "Iacu",
      "Ibaiti",
      "Ibate",
      "Ibatiba",
      "Ibia",
      "Ibicarai",
      "Ibicui",
      "Ibimirim",
      "Ibipeba",
      "Ibipora",
      "Ibirama",
      "Ibirataia",
      "Ibirite",
      "Ibiruba",
      "Ibitinga",
      "Ibiuna",
      "Ibotirama",
      "Icara",
      "Ico",
      "Igaracu do Tiete",
      "Igarapava",
      "Igarape",
      "Igarape Acu",
      "Igarape Miri",
      "Igarassu",
      "Igrejinha",
      "Iguaba Grande",
      "Iguai",
      "Iguape",
      "Iguatu",
      "Ijui",
      "Ilha Solteira",
      "Ilhabela",
      "Ilheus",
      "Imbituba",
      "Imbituva",
      "Imperatriz",
      "Indaial",
      "Indaiatuba",
      "Independencia",
      "Inhambupe",
      "Inhapim",
      "Inhumas",
      "Ipaba",
      "Ipameri",
      "Ipatinga",
      "Ipaucu",
      "Ipero",
      "Ipiau",
      "Ipira",
      "Ipojuca",
      "Ipora",
      "Ipora",
      "Ipu",
      "Ipua",
      "Ipubi",
      "Ipueiras",
      "Iracemapolis",
      "Iranduba",
      "Irati",
      "Iraucuba",
      "Irece",
      "Itabaiana",
      "Itabaiana",
      "Itabaianinha",
      "Itabera",
      "Itaberaba",
      "Itaberai",
      "Itabira",
      "Itabirito",
      "Itaborai",
      "Itabuna",
      "Itacarambi",
      "Itacare",
      "Itacoatiara",
      "Itacorubi",
      "Itagi",
      "Itaguai",
      "Itai",
      "Itaiba",
      "Itaitinga",
      "Itaituba",
      "Itajai",
      "Itajobi",
      "Itajuba",
      "Itajuipe",
      "Itamaraca",
      "Itamaraju",
      "Itamarandiba",
      "Itambacuri",
      "Itambe",
      "Itanhaem",
      "Itanhandu",
      "Itanhem",
      "Itaocara",
      "Itapaci",
      "Itapage",
      "Itaparica",
      "Itapecerica",
      "Itapecerica da Serra",
      "Itapecuru Mirim",
      "Itapema",
      "Itapemirim",
      "Itaperucu",
      "Itaperuna",
      "Itapetinga",
      "Itapetininga",
      "Itapeva",
      "Itapevi",
      "Itapipoca",
      "Itapira",
      "Itapissuma",
      "Itapolis",
      "Itaporanga",
      "Itaporanga",
      "Itapui",
      "Itapuranga",
      "Itaquaquecetuba",
      "Itaqui",
      "Itaquitinga",
      "Itarantim",
      "Itarare",
      "Itatiaia",
      "Itatiba",
      "Itatinga",
      "Itau de Minas",
      "Itauna",
      "Itirapina",
      "Itirucu",
      "Itororo",
      "Itu",
      "Itubera",
      "Ituiutaba",
      "Itumbiara",
      "Itupeva",
      "Itupiranga",
      "Iturama",
      "Ituverava",
      "Iuna",
      "Ivoti",
      "Jaboatao",
      "Jaboatao dos Guararapes",
      "Jaboticabal",
      "Jacarei",
      "Jacarezinho",
      "Jaciara",
      "Jacobina",
      "Jacupiranga",
      "Jacutinga",
      "Jaguaquara",
      "Jaguarao",
      "Jaguarari",
      "Jaguariaiva",
      "Jaguaribe",
      "Jaguariuna",
      "Jaguaruana",
      "Jaguaruna",
      "Jales",
      "Janauba",
      "Jandaia do Sul",
      "Jandira",
      "Januaria",
      "Japeri",
      "Jaragua",
      "Jaragua do Sul",
      "Jardim",
      "Jardim Paulista",
      "Jardim de Piranhas",
      "Jardinopolis",
      "Jarinu",
      "Jaru",
      "Jatai",
      "Jataizinho",
      "Jatoba",
      "Jau",
      "Jequie",
      "Jequitinhonha",
      "Jeremoabo",
      "Ji Parana",
      "Jitauna",
      "Joacaba",
      "Joanopolis",
      "Joao Alfredo",
      "Joao Camara",
      "Joao Monlevade",
      "Joao Neiva",
      "Joao Pessoa",
      "Joao Pinheiro",
      "Joinville",
      "Jose Bonifacio",
      "Jose de Freitas",
      "Juatuba",
      "Juazeiro do Norte",
      "Jucas",
      "Jucurutu",
      "Juiz de Fora",
      "Julio de Castilhos",
      "Jundiai",
      "Junqueiropolis",
      "Juquia",
      "Juquitiba",
      "Juruti",
      "Ladario",
      "Lagarto",
      "Lages",
      "Lago da Pedra",
      "Lagoa Formosa",
      "Lagoa Santa",
      "Lagoa Vermelha",
      "Lagoa da Canoa",
      "Lagoa da Prata",
      "Lagoa do Itaenga",
      "Laguna",
      "Lajeado",
      "Lajedo",
      "Lajinha",
      "Lambari",
      "Lapa",
      "Lapao",
      "Laranjal Paulista",
      "Laranjeiras",
      "Laranjeiras do Sul",
      "Lauro Muller",
      "Lauro de Freitas",
      "Lavras",
      "Lavras da Mangabeira",
      "Leme",
      "Lencois Paulista",
      "Leopoldina",
      "Lima Duarte",
      "Limeira",
      "Limoeiro",
      "Limoeiro do Norte",
      "Linhares",
      "Lins",
      "Livramento do Brumado",
      "Loanda",
      "Londrina",
      "Lorena",
      "Louveira",
      "Lucas",
      "Lucelia",
      "Luis Correia",
      "Luz",
      "Luziania",
      "Luzilandia",
      "Macae",
      "Macaiba",
      "Macapa",
      "Macaparana",
      "Macatuba",
      "Macau",
      "Macaubas",
      "Maceio",
      "Machado",
      "Madre de Deus",
      "Mafra",
      "Mairi",
      "Mairinque",
      "Mairipora",
      "Mamanguape",
      "Manacapuru",
      "Manaus",
      "Mandaguacu",
      "Mandaguari",
      "Manga",
      "Mangaratiba",
      "Manhuacu",
      "Manhumirim",
      "Manicore",
      "Maraba",
      "Maracacume",
      "Maracai",
      "Maracaju",
      "Maracana",
      "Maracanau",
      "Maracas",
      "Maragogi",
      "Maragogipe",
      "Maraial",
      "Marapanim",
      "Marataizes",
      "Marau",
      "Marco",
      "Marechal Candido Rondon",
      "Marechal Deodoro",
      "Mari",
      "Marialva",
      "Mariana",
      "Maribondo",
      "Marica",
      "Marilia",
      "Maringa",
      "Martinopolis",
      "Maruim",
      "Mascote",
      "Mata de Sao Joao",
      "Matao",
      "Matelandia",
      "Mateus Leme",
      "Matias Barbosa",
      "Matipo",
      "Matozinhos",
      "Matriz de Camaragibe",
      "Maua",
      "Maues",
      "Medeiros Neto",
      "Medianeira",
      "Medina",
      "Mendes",
      "Messias",
      "Miguel Alves",
      "Miguel Calmon",
      "Miguel Pereira",
      "Miguelopolis",
      "Milagres",
      "Mimoso do Sul",
      "Minacu",
      "Mineiros",
      "Miracatu",
      "Miracema",
      "Miracema do Tocantins",
      "Miranda",
      "Mirandopolis",
      "Missao Velha",
      "Mocajuba",
      "Mococa",
      "Mogi Guacu",
      "Mogi Mirim",
      "Mogi das Cruzes",
      "Moju",
      "Mombaca",
      "Moncao",
      "Mongagua",
      "Montanha",
      "Monte Alegre",
      "Monte Alegre de Minas",
      "Monte Alto",
      "Monte Aprazivel",
      "Monte Azul",
      "Monte Azul Paulista",
      "Monte Carmelo",
      "Monte Mor",
      "Monte Santo de Minas",
      "Monte Siao",
      "Monteiro",
      "Montenegro",
      "Montes Claros",
      "Morada Nova",
      "Moreno",
      "Morrinhos",
      "Morro Agudo",
      "Morro da Fumaca",
      "Morro do Chapeu",
      "Mossoro",
      "Mozarlandia",
      "Mucuri",
      "Mundo Novo",
      "Muriae",
      "Murici",
      "Muritiba",
      "Mutuipe",
      "Mutum",
      "Muzambinho",
      "Nanuque",
      "Nao Me Toque",
      "Natal",
      "Natividade",
      "Navegantes",
      "Navirai",
      "Nazare",
      "Nazare da Mata",
      "Neopolis",
      "Nepomuceno",
      "Neropolis",
      "Nilopolis",
      "Niquelandia",
      "Nisia Floresta",
      "Niteroi",
      "Nobres",
      "Nossa Senhora da Gloria",
      "Nossa Senhora das Dores",
      "Nossa Senhora do Socorro",
      "Nova Cruz",
      "Nova Era",
      "Nova Friburgo",
      "Nova Granada",
      "Nova Iguacu",
      "Nova Lima",
      "Nova Londrina",
      "Nova Odessa",
      "Nova Olimpia",
      "Nova Olinda do Norte",
      "Nova Petropolis",
      "Nova Prata",
      "Nova Russas",
      "Nova Soure",
      "Nova Venecia",
      "Nova Vicosa",
      "Nova Xavantina",
      "Novo Aripuana",
      "Novo Cruzeiro",
      "Novo Hamburgo",
      "Novo Horizonte",
      "Novo Oriente",
      "Obidos",
      "Oeiras",
      "Oeiras do Para",
      "Oiapoque",
      "Olimpia",
      "Olinda",
      "Olindina",
      "Oliveira",
      "Oriximina",
      "Orlandia",
      "Orleans",
      "Oros",
      "Ortigueira",
      "Osasco",
      "Osorio",
      "Osvaldo Cruz",
      "Otacilio Costa",
      "Ouricuri",
      "Ourilandia do Norte",
      "Ourinhos",
      "Ouro Branco",
      "Ouro Preto",
      "Ouro Preto do Oeste",
      "Pacaembu",
      "Pacajus",
      "Pacatuba",
      "Padre Bernardo",
      "Padre Paraiso",
      "Paicandu",
      "Palhoca",
      "Palmares",
      "Palmas",
      "Palmas",
      "Palmeira",
      "Palmeira das Missoes",
      "Palmeiras de Goias",
      "Palmital",
      "Palotina",
      "Panambi",
      "Panorama",
      "Pao de Acucar",
      "Papagaios",
      "Para de Minas",
      "Paracambi",
      "Paracatu",
      "Paracuru",
      "Paragominas",
      "Paraguacu",
      "Paraguacu Paulista",
      "Paraiba do Sul",
      "Paraibano",
      "Paraipaba",
      "Paraisopolis",
      "Parambu",
      "Paranagua",
      "Paranaiba",
      "Paranapanema",
      "Paranavai",
      "Paratinga",
      "Paraty",
      "Parauapebas",
      "Parelhas",
      "Parintins",
      "Pariquera Acu",
      "Parnaiba",
      "Parnamirim",
      "Parnarama",
      "Parobe",
      "Passa Quatro",
      "Passira",
      "Passo Fundo",
      "Passos",
      "Pastos Bons",
      "Pato Branco",
      "Patos",
      "Patos de Minas",
      "Patrocinio",
      "Paty do Alferes",
      "Paulinia",
      "Paulista",
      "Paulo Afonso",
      "Peabiru",
      "Pederneiras",
      "Pedra",
      "Pedra Azul",
      "Pedra Branca",
      "Pedras de Fogo",
      "Pedregulho",
      "Pedreira",
      "Pedro II",
      "Pedro Leopoldo",
      "Pelotas",
      "Penalva",
      "Penapolis",
      "Penedo",
      "Penha",
      "Pentecoste",
      "Perdoes",
      "Pereira Barreto",
      "Peruibe",
      "Pesqueira",
      "Petrolina",
      "Petropolis",
      "Pianco",
      "Picos",
      "Picui",
      "Piedade",
      "Pilar",
      "Pilar do Sul",
      "Pimenta Bueno",
      "Pindamonhangaba",
      "Pindare Mirim",
      "Pindobacu",
      "Pindorama",
      "Pinhais",
      "Pinhao",
      "Pinheiral",
      "Pinheiro",
      "Pinheiro Machado",
      "Pio XII",
      "Piquete",
      "Piracaia",
      "Piracanjuba",
      "Piracicaba",
      "Piracuruca",
      "Pirai",
      "Pirai do Sul",
      "Piraju",
      "Pirajui",
      "Pirapora",
      "Pirapora do Bom Jesus",
      "Pirapozinho",
      "Piraquara",
      "Pirassununga",
      "Pirenopolis",
      "Pires do Rio",
      "Piripiri",
      "Piritiba",
      "Pitanga",
      "Pitangueiras",
      "Pitangui",
      "Piui",
      "Piuma",
      "Placido de Castro",
      "Planaltina",
      "Planaltina",
      "Poa",
      "Pocao de Pedras",
      "Poco Verde",
      "Pocoes",
      "Pocone",
      "Pocos de Caldas",
      "Pombal",
      "Pombos",
      "Pomerode",
      "Pompeia",
      "Pompeu",
      "Ponta Grossa",
      "Ponta Pora",
      "Pontal",
      "Pontal do Parana",
      "Pontalina",
      "Ponte Nova",
      "Pontes e Lacerda",
      "Porangatu",
      "Porciuncula",
      "Porecatu",
      "Portao",
      "Porteirinha",
      "Portel",
      "Porto Alegre",
      "Porto Barra do Ivinheima",
      "Porto Belo",
      "Porto Calvo",
      "Porto Feliz",
      "Porto Ferreira",
      "Porto Franco",
      "Porto Murtinho",
      "Porto Real",
      "Porto Seguro",
      "Porto Uniao",
      "Porto Velho",
      "Posse",
      "Posto Fiscal Rolim de Moura",
      "Posto da Mata",
      "Pouso Alegre",
      "Poxoreo",
      "Prado",
      "Pradopolis",
      "Praia Grande",
      "Prata",
      "Presidente Bernardes",
      "Presidente Dutra",
      "Presidente Epitacio",
      "Presidente Medici",
      "Presidente Olegario",
      "Presidente Prudente",
      "Presidente Venceslau",
      "Princesa Isabel",
      "Promissao",
      "Propria",
      "Prudentopolis",
      "Quarai",
      "Quata",
      "Quatis",
      "Quatro Barras",
      "Queimadas",
      "Queimados",
      "Quipapa",
      "Quirinopolis",
      "Quixada",
      "Quixeramobim",
      "Quixere",
      "Rancharia",
      "Raposa",
      "Raposos",
      "Realeza",
      "Recife",
      "Redencao",
      "Redencao",
      "Regeneracao",
      "Regente Feijo",
      "Registro",
      "Reriutaba",
      "Resende",
      "Reserva",
      "Resplendor",
      "Riachao",
      "Riachao das Neves",
      "Riachao do Jacuipe",
      "Riacho de Santana",
      "Ribas do Rio Pardo",
      "Ribeira do Pombal",
      "Ribeirao",
      "Ribeirao Bonito",
      "Ribeirao Pires",
      "Ribeirao Preto",
      "Ribeirao da Ilha",
      "Ribeirao das Neves",
      "Ribeiropolis",
      "Rio Bonito",
      "Rio Branco",
      "Rio Branco do Sul",
      "Rio Brilhante",
      "Rio Casca",
      "Rio Claro",
      "Rio Claro",
      "Rio Formoso",
      "Rio Grande",
      "Rio Grande da Serra",
      "Rio Largo",
      "Rio Negrinho",
      "Rio Negro",
      "Rio Pardo",
      "Rio Piracicaba",
      "Rio Pomba",
      "Rio Preto da Eva",
      "Rio Real",
      "Rio Tinto",
      "Rio Verde",
      "Rio Verde de Mato Grosso",
      "Rio das Ostras",
      "Rio das Pedras",
      "Rio de Janeiro",
      "Rio do Sul",
      "Riolandia",
      "Rolandia",
      "Rolante",
      "Rondonopolis",
      "Rosario Oeste",
      "Rosario do Sul",
      "Rubiataba",
      "Russas",
      "Ruy Barbosa",
      "Saco dos Limoes",
      "Sacramento",
      "Salgueiro",
      "Salinas",
      "Salinopolis",
      "Salto",
      "Salto de Pirapora",
      "Salvador",
      "Santa Adelia",
      "Santa Barbara d'Oeste",
      "Santa Cecilia",
      "Santa Cruz",
      "Santa Cruz Cabralia",
      "Santa Cruz das Palmeiras",
      "Santa Cruz do Capibaribe",
      "Santa Cruz do Rio Pardo",
      "Santa Cruz do Sul",
      "Santa Fe do Sul",
      "Santa Gertrudes",
      "Santa Helena",
      "Santa Helena de Goias",
      "Santa Ines",
      "Santa Ines",
      "Santa Isabel",
      "Santa Luzia",
      "Santa Luzia",
      "Santa Luzia",
      "Santa Maria",
      "Santa Maria da Boa Vista",
      "Santa Maria da Vitoria",
      "Santa Maria de Jetiba",
      "Santa Maria do Para",
      "Santa Quiteria",
      "Santa Quiteria do Maranhao",
      "Santa Rita",
      "Santa Rita",
      "Santa Rita do Passa Quatro",
      "Santa Rita do Sapucai",
      "Santa Rosa",
      "Santa Rosa de Viterbo",
      "Santa Vitoria",
      "Santa Vitoria do Palmar",
      "Santaluz",
      "Santana",
      "Santana",
      "Santana de Parnaiba",
      "Santana do Acarau",
      "Santana do Ipanema",
      "Santana do Livramento",
      "Santana do Paraiso",
      "Santarem",
      "Santiago",
      "Santo Amaro",
      "Santo Amaro da Imperatriz",
      "Santo Anastacio",
      "Santo Andre",
      "Santo Angelo",
      "Santo Antonio",
      "Santo Antonio da Platina",
      "Santo Antonio de Jesus",
      "Santo Antonio de Padua",
      "Santo Antonio de Posse",
      "Santo Antonio do Amparo",
      "Santo Antonio do Monte",
      "Santo Antonio do Sudoeste",
      "Santo Augusto",
      "Santo Estevao",
      "Santos",
      "Santos Dumont",
      "Sao Benedito do Rio Preto",
      "Sao Bento",
      "Sao Bento",
      "Sao Bento do Sul",
      "Sao Bernardo",
      "Sao Bernardo do Campo",
      "Sao Borja",
      "Sao Caetano do Sul",
      "Sao Carlos",
      "Sao Cristovao",
      "Sao Domingos do Maranhao",
      "Sao Felix do Xingu",
      "Sao Fidelis",
      "Sao Francisco",
      "Sao Francisco de Assis",
      "Sao Francisco de Paula",
      "Sao Francisco do Conde",
      "Sao Francisco do Sul",
      "Sao Gabriel",
      "Sao Gabriel da Cachoeira",
      "Sao Geraldo do Araguaia",
      "Sao Goncalo do Amarante",
      "Sao Goncalo do Amarante",
      "Sao Goncalo do Sapucai",
      "Sao Goncalo dos Campos",
      "Sao Gotardo",
      "Sao Jeronimo",
      "Sao Joao Batista",
      "Sao Joao Evangelista",
      "Sao Joao Nepomuceno",
      "Sao Joao da Barra",
      "Sao Joao da Boa Vista",
      "Sao Joao de Meriti",
      "Sao Joao de Pirabas",
      "Sao Joao del Rei",
      "Sao Joao do Piaui",
      "Sao Joao dos Inhamuns",
      "Sao Joao dos Patos",
      "Sao Joaquim",
      "Sao Joaquim da Barra",
      "Sao Joaquim de Bicas",
      "Sao Joaquim do Monte",
      "Sao Jose",
      "Sao Jose da Coroa Grande",
      "Sao Jose da Laje",
      "Sao Jose da Tapera",
      "Sao Jose de Mipibu",
      "Sao Jose de Ribamar",
      "Sao Jose do Belmonte",
      "Sao Jose do Campestre",
      "Sao Jose do Egito",
      "Sao Jose do Rio Pardo",
      "Sao Jose do Rio Preto",
      "Sao Jose dos Campos",
      "Sao Jose dos Pinhais",
      "Sao Leopoldo",
      "Sao Lourenco",
      "Sao Lourenco da Mata",
      "Sao Lourenco da Serra",
      "Sao Lourenco do Oeste",
      "Sao Lourenco do Sul",
      "Sao Luis",
      "Sao Luis de Montes Belos",
      "Sao Luis do Quitunde",
      "Sao Luiz Gonzaga",
      "Sao Manuel",
      "Sao Marcos",
      "Sao Mateus",
      "Sao Mateus do Maranhao",
      "Sao Mateus do Sul",
      "Sao Miguel",
      "Sao Miguel do Araguaia",
      "Sao Miguel do Guama",
      "Sao Miguel do Iguacu",
      "Sao Miguel dos Campos",
      "Sao Paulo",
      "Sao Paulo de Olivenca",
      "Sao Pedro",
      "Sao Pedro",
      "Sao Pedro da Aldeia",
      "Sao Pedro do Sul",
      "Sao Raimundo Nonato",
      "Sao Raimundo das Mangabeiras",
      "Sao Roque",
      "Sao Sebastiao",
      "Sao Sebastiao do Cai",
      "Sao Sebastiao do Paraiso",
      "Sao Sebastiao do Passe",
      "Sao Sepe",
      "Sao Simao",
      "Sao Vicente",
      "Sapiranga",
      "Sapucaia",
      "Sapucaia",
      "Saquarema",
      "Sarandi",
      "Sarandi",
      "Sarzedo",
      "Satuba",
      "Saubara",
      "Schroeder",
      "Seabra",
      "Sena Madureira",
      "Senador Canedo",
      "Senador Guiomard",
      "Senador Pompeu",
      "Senges",
      "Senhor do Bonfim",
      "Seropedica",
      "Serra",
      "Serra Negra",
      "Serra Talhada",
      "Serrana",
      "Serrinha",
      "Serro",
      "Sertania",
      "Sertanopolis",
      "Sertaozinho",
      "Sete Lagoas",
      "Severinia",
      "Sidrolandia",
      "Silva Jardim",
      "Silvania",
      "Simao Dias",
      "Simoes Filho",
      "Sinop",
      "Siqueira Campos",
      "Sirinhaem",
      "Sobradinho",
      "Sobral",
      "Socorro",
      "Solanea",
      "Soledade",
      "Sombrio",
      "Sorocaba",
      "Soure",
      "Sousa",
      "Sumare",
      "Sume",
      "Surubim",
      "Suzano",
      "Tabatinga",
      "Tabatinga",
      "Tabira",
      "Taboao da Serra",
      "Taguatinga",
      "Taiobeiras",
      "Tamandare",
      "Tambau",
      "Tamboril",
      "Tanabi",
      "Tangua",
      "Tapejara",
      "Tapejara",
      "Tapes",
      "Tapiramuta",
      "Taquara",
      "Taquari",
      "Taquaritinga",
      "Taquaritinga do Norte",
      "Taquarituba",
      "Tarauaca",
      "Taruma",
      "Tatui",
      "Taubate",
      "Tefe",
      "Telemaco Borba",
      "Teodoro Sampaio",
      "Teofilo Otoni",
      "Teresina",
      "Teresopolis",
      "Terra Boa",
      "Terra Nova",
      "Terra Rica",
      "Terra Roxa",
      "Terra Santa",
      "Teutonia",
      "Tiangua",
      "Tibagi",
      "Tiete",
      "Tijucas",
      "Timbauba",
      "Timbiras",
      "Timbo",
      "Timon",
      "Timoteo",
      "Tobias Barreto",
      "Tocantins",
      "Toledo",
      "Tome Acu",
      "Toritama",
      "Torres",
      "Trairi",
      "Tramandai",
      "Tremembe",
      "Tres Barras",
      "Tres Coracoes",
      "Tres Coroas",
      "Tres Lagoas",
      "Tres Passos",
      "Tres Pontas",
      "Tres Rios",
      "Tres de Maio",
      "Trindade",
      "Trindade",
      "Trindade",
      "Triunfo",
      "Tubarao",
      "Tucano",
      "Tucuma",
      "Tucuma",
      "Tucurui",
      "Tuntum",
      "Tupa",
      "Tupaciguara",
      "Tupancireta",
      "Tupi Paulista",
      "Turiacu",
      "Tutoia",
      "Uaua",
      "Uba",
      "Ubaitaba",
      "Ubajara",
      "Ubata",
      "Ubatuba",
      "Uberaba",
      "Uberlandia",
      "Umbauba",
      "Umirim",
      "Umuarama",
      "Una",
      "Unai",
      "Uniao",
      "Uniao da Vitoria",
      "Uniao dos Palmares",
      "Urbano Santos",
      "Uruacu",
      "Uruana",
      "Uruburetama",
      "Urucuca",
      "Urucui",
      "Urucurituba",
      "Uruguaiana",
      "Urupes",
      "Urussanga",
      "Vacaria",
      "Valenca",
      "Valenca",
      "Valenca do Piaui",
      "Valente",
      "Valinhos",
      "Valparaiso",
      "Vargem Grande",
      "Vargem Grande do Sul",
      "Varginha",
      "Varjota",
      "Varzea Alegre",
      "Varzea Grande",
      "Varzea Paulista",
      "Varzea da Palma",
      "Vassouras",
      "Vazante",
      "Venancio Aires",
      "Vera Cruz",
      "Veranopolis",
      "Vespasiano",
      "Viamao",
      "Viana",
      "Viana",
      "Vicencia",
      "Vicosa",
      "Vicosa",
      "Vicosa do Ceara",
      "Videira",
      "Vigia",
      "Vila Velha",
      "Vilhena",
      "Vinhedo",
      "Viradouro",
      "Visconde do Rio Branco",
      "Viseu",
      "Vitoria",
      "Vitoria da Conquista",
      "Vitoria de Santo Antao",
      "Vitoria do Mearim",
      "Vitorino Freire",
      "Volta Redonda",
      "Votorantim",
      "Votuporanga",
      "Wenceslau Braz",
      "Xanxere",
      "Xinguara",
      "Xique Xique",
    ],
  },
  "Cape Verde": {
    code: "CV",
    cities: [
      "Assomada",
      "Espargos",
      "Mindelo",
      "Pedra Badejo",
      "Porto Novo",
      "Praia",
      "Sal Rei",
      "Sao Filipe",
      "Tarrafal",
    ],
  },
  "Brunei Darussalam": {
    code: "BN",
    cities: ["Bandar Seri Begawan", "Kuala Belait", "Seria", "Tutong"],
  },
  Bulgaria: {
    code: "BG",
    cities: [
      "Asenovgrad",
      "Asparuhovo",
      "Aytos",
      "Balchik",
      "Berkovitsa",
      "Blagoevgrad",
      "Botevgrad",
      "Burgas",
      "Byala Slatina",
      "Cherven Bryag",
      "Chirpan",
      "Dimitrovgrad",
      "Dobrich",
      "Dupnitsa",
      "Elhovo",
      "Etropole",
      "Gabrovo",
      "Gorna Oryahovitsa",
      "Gotse Delchev",
      "Harmanli",
      "Haskovo",
      "Ihtiman",
      "Kardzhali",
      "Karlovo",
      "Karnobat",
      "Kavarna",
      "Kazanlak",
      "Kostinbrod",
      "Kozloduy",
      "Kyustendil",
      "Levski",
      "Lom",
      "Lovech",
      "Mezdra",
      "Montana",
      "Nesebar",
      "Nova Zagora",
      "Novi Pazar",
      "Panagyurishte",
      "Parvomay",
      "Pavlikeni",
      "Pazardzhik",
      "Pernik",
      "Peshtera",
      "Petrich",
      "Pleven",
      "Plovdiv",
      "Pomorie",
      "Popovo",
      "Provadia",
      "Radnevo",
      "Radomir",
      "Rakovski",
      "Razgrad",
      "Razlog",
      "Ruse",
      "Samokov",
      "Sandanski",
      "Sevlievo",
      "Shumen",
      "Silistra",
      "Sliven",
      "Smolyan",
      "Sofia",
      "Stamboliyski",
      "Stara Zagora",
      "Svilengrad",
      "Svishtov",
      "Targovishte",
      "Teteven",
      "Troyan",
      "Tryavna",
      "Tutrakan",
      "Varna",
      "Veliko Turnovo",
      "Velingrad",
      "Vidin",
      "Vratsa",
      "Yambol",
    ],
  },
  "Burkina Faso": {
    code: "BF",
    cities: [
      "Aribinda",
      "Bama",
      "Banfora",
      "Barogo",
      "Batie",
      "Beguedo",
      "Bittou",
      "Bobo-Dioulasso",
      "Bogande",
      "Boromo",
      "Boulsa",
      "Bourzanga",
      "Bousse",
      "Cinkanse",
      "Dano",
      "Dedougou",
      "Diapaga",
      "Diebougou",
      "Djibo",
      "Dori",
      "Fada N'gourma",
      "Fara Department",
      "Gaoua",
      "Garango",
      "Gayeri",
      "Gomboussougou",
      "Gourcy",
      "Hounde",
      "Karankasso",
      "Kaya",
      "Kindi",
      "Kokologo",
      "Kombissiri",
      "Kompienga",
      "Kongoussi",
      "Korsimoro",
      "Koudougou",
      "Kouka",
      "Koupela",
      "Leo",
      "Manga",
      "Mani",
      "Mogtedo",
      "Namponkore",
      "Natiaboani",
      "Niangoloko",
      "Nioko I",
      "Nouna",
      "Orodara",
      "Ouagadougou",
      "Ouahigouya",
      "Ouargaye",
      "Pa",
      "Pama",
      "Pissila",
      "Po",
      "Pouytenga",
      "Reo",
      "Saaba",
      "Sabou",
      "Saonre",
      "Sapouy",
      "Sebba",
      "Solenzo",
      "Tenkodogo",
      "Tita",
      "Titao",
      "To",
      "Toma",
      "Tougan",
      "Yako",
      "Yalgo",
      "Zabre",
      "Zinguedesse",
      "Ziniare",
      "Zorgo",
    ],
  },
  Burundi: {
    code: "BI",
    cities: [
      "Bubanza",
      "Bujumbura",
      "Cibitoke",
      "Gatumba",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muyinga",
      "Ngozi",
      "Rumonge",
      "Ruyigi",
    ],
  },
  Cambodia: {
    code: "KH",
    cities: [
      "Banlung",
      "Battambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Speu",
      "Kampong Thom",
      "Kampot",
      "Koh Kong",
      "Kratie",
      "Krong Kep",
      "Lumphat",
      "Pailin",
      "Paoy Paet",
      "Phnom Penh",
      "Phumi Veal Sre",
      "Prey Veng",
      "Pursat",
      "Samraong",
      "Siem Reap",
      "Sihanoukville",
      "Sisophon",
      "Smach Mean Chey",
      "Stung Treng",
      "Suong",
      "Svay Rieng",
      "Ta Khmau",
      "Takeo",
      "Tbeng Meanchey",
    ],
  },
  Cameroon: {
    code: "CM",
    cities: [
      "Abong Mbang",
      "Akonolinga",
      "Ambam",
      "Bafang",
      "Bafia",
      "Bafoussam",
      "Bafut",
      "Bali",
      "Bamenda",
      "Bamusso",
      "Bandjoun",
      "Bangangte",
      "Banyo",
      "Batouri",
      "Bazou",
      "Belabo",
      "Bertoua",
      "Betare Oya",
      "Blangwa",
      "Bogo",
      "Buea",
      "Dizangue",
      "Douala",
      "Dschang",
      "Ebolowa",
      "Edea",
      "Ekondo Titi",
      "Eseka",
      "Figuil",
      "Fontem",
      "Foumban",
      "Foumbot",
      "Fundong",
      "Garoua",
      "Garoua Boulai",
      "Guider",
      "Guidiguis",
      "Kaele",
      "Kekem",
      "Kousseri",
      "Kribi",
      "Kumba",
      "Kumbo",
      "Lagdo",
      "Limbe",
      "Lolodorf",
      "Loum",
      "Maga",
      "Magba",
      "Mamfe",
      "Manjo",
      "Maroua",
      "Mbalmayo",
      "Mbandjok",
      "Mbanga",
      "Mbouda",
      "Meiganga",
      "Melong",
      "Mindif",
      "Mokolo",
      "Mora",
      "Mundemba",
      "Mutengene",
      "Muyuka",
      "Nanga Eboko",
      "Ndop",
      "Ngaoundal",
      "Ngaoundere",
      "Njombe",
      "Nkambe",
      "Nkongsamba",
      "Nkoteng",
      "Obala",
      "Okoa",
      "Oku",
      "Penja",
      "Pitoa",
      "Rey Bouba",
      "Sangmelima",
      "Souza Gare",
      "Tcheboa",
      "Tchollire",
      "Tibati",
      "Tiko",
      "Tombel",
      "Tonga",
      "Touboro",
      "Wum",
      "Yagoua",
      "Yaounde",
      "Yokadouma",
    ],
  },
  Canada: {
    code: "CA",
    cities: [
      "Abbotsford",
      "Agincourt North",
      "Agincourt South-Malvern West",
      "Ahuntsic-Cartierville",
      "Airdrie",
      "Ajax",
      "Aldergrove",
      "Alderwood",
      "Alliston",
      "Alma",
      "Amherstburg",
      "Amos",
      "Ancaster",
      "Angus",
      "Annex",
      "Aurora",
      "Aylmer",
      "Baie-Comeau",
      "Banbury-Don Mills",
      "Barrie",
      "Bathurst",
      "Bathurst Manor",
      "Bay Street Corridor",
      "Bayview Village",
      "Bayview Woods-Steeles",
      "Beaconsfield",
      "Beauharnois",
      "Becancour",
      "Bedford Park-Nortown",
      "Belleville",
      "Beloeil",
      "Bendale",
      "Birchcliffe-Cliffside",
      "Black Creek",
      "Blainville",
      "Boisbriand",
      "Boucherville",
      "Bracebridge",
      "Brampton",
      "Brandon",
      "Brant",
      "Brantford",
      "Briar Hill-Belgravia",
      "Broadview North",
      "Brockville",
      "Brookhaven-Amesbury",
      "Brooks",
      "Brossard",
      "Buckingham",
      "Burlington",
      "Burnaby",
      "Cabbagetown-South St.James Town",
      "Caledon",
      "Calgary",
      "Cambridge",
      "Campbell River",
      "Camrose",
      "Candiac",
      "Canmore",
      "Cantley",
      "Carleton Place",
      "Casa Loma",
      "Centennial Scarborough",
      "Chambly",
      "Charlottetown",
      "Chateauguay",
      "Chatham",
      "Chilliwack",
      "Church-Yonge Corridor",
      "Clairlea-Birchmount",
      "Clanton Park",
      "Clarence-Rockland",
      "Cliffcrest",
      "Cobourg",
      "Cochrane",
      "Cold Lake",
      "Cole Harbour",
      "Collingwood",
      "Colwood",
      "Conception Bay South",
      "Coquitlam",
      "Corner Brook",
      "Cornwall",
      "Corso Italia-Davenport",
      "Cote-Saint-Luc",
      "Courtenay",
      "Cowansville",
      "Cranbrook",
      "Danforth East York",
      "Dartmouth",
      "Dawson Creek",
      "Delta",
      "Deux-Montagnes",
      "Dieppe",
      "Dolbeau-Mistassini",
      "Dollard-Des Ormeaux",
      "Don Valley Village",
      "Dorset Park",
      "Dorval",
      "Dovercourt-Wallace Emerson-Junction",
      "Downsview-Roding-CFB",
      "Drummondville",
      "Dufferin Grove",
      "Duncan",
      "East End-Danforth",
      "East Gwillimbury",
      "Edenbridge-Humber Valley",
      "Edmonton",
      "Edmundston",
      "Eglinton East",
      "Elliot Lake",
      "Englemount-Lawrence",
      "Eringate-Centennial-West Deane",
      "Esquimalt",
      "Estevan",
      "Etobicoke",
      "Etobicoke West Mall",
      "Fall River",
      "Fallingbrook",
      "Fergus",
      "Flemingdon Park",
      "Forest Hill North",
      "Forest Hill South",
      "Fort Erie",
      "Fort McMurray",
      "Fort Saskatchewan",
      "Fort St. John",
      "Fredericton",
      "Gander",
      "Gaspe",
      "Gatineau",
      "Glace Bay",
      "Glenfield-Jane Heights",
      "Granby",
      "Grand Falls-Windsor",
      "Grande Prairie",
      "Gravenhurst",
      "Greater Napanee",
      "Greater Sudbury",
      "Greenwood-Coxwell",
      "Grimsby",
      "Guelph",
      "Halifax",
      "Hamilton",
      "Hauterive",
      "Hawkesbury",
      "Henry Farm",
      "High Park North",
      "High Park-Swansea",
      "Highland Creek",
      "Hillcrest Village",
      "Humber Heights-Westmount",
      "Humber Summit",
      "Humbermede",
      "Humewood-Cedarvale",
      "Huntsville",
      "Ingersoll",
      "Ionview",
      "Islington-City Centre West",
      "Joliette",
      "Jonquiere",
      "Junction Area",
      "Kamloops",
      "Kanata",
      "Keelesdale-Eglinton West",
      "Kelowna",
      "Kennedy Park",
      "Kenora",
      "Kensington-Chinatown",
      "Kentville",
      "Keswick",
      "Kingston",
      "Kingsview Village-The Westway",
      "Kirkland",
      "Kitchener",
      "L'Amoreaux",
      "L'Ancienne-Lorette",
      "L'Assomption",
      "La Haute-Saint-Charles",
      "La Prairie",
      "La Tuque",
      "LaSalle",
      "Lacombe",
      "Ladner",
      "Lambton Shores",
      "Langford",
      "Langley",
      "Lansing-Westgate",
      "Laurentides",
      "Laval",
      "Lawrence Park North",
      "Lawrence Park South",
      "Leamington",
      "Leaside-Bennington",
      "Leduc",
      "Les Coteaux",
      "Lethbridge",
      "Levis",
      "Lindsay",
      "Little Portugal",
      "Lloydminster",
      "London",
      "Long Branch",
      "Longueuil",
      "Lower Sackville",
      "Lutes Mountain",
      "Magog",
      "Malvern",
      "Maple Leaf",
      "Maple Ridge",
      "Markham",
      "Markland Wood",
      "Mascouche",
      "Matane",
      "Medicine Hat",
      "Mercier",
      "Midland",
      "Milliken",
      "Milton",
      "Mimico",
      "Mirabel",
      "Miramichi",
      "Mission",
      "Mississauga",
      "Moncton",
      "Mont-Laurier",
      "Mont-Royal",
      "Mont-Saint-Hilaire",
      "Montmagny",
      "Montreal",
      "Moose Jaw",
      "Morningside",
      "Moss Park",
      "Mount Dennis",
      "Mount Olive-Silverstone-Jamestown",
      "Mount Pearl",
      "Mount Pleasant East",
      "Mount Pleasant West",
      "Nanaimo",
      "Nelson",
      "Nepean",
      "New Glasgow",
      "New Hamburg",
      "New Toronto",
      "New Westminster",
      "Newmarket",
      "Newtonbrook East",
      "Newtonbrook West",
      "Niagara",
      "Niagara Falls",
      "Niagara-on-the-Lake",
      "Norfolk County",
      "North Battleford",
      "North Bay",
      "North Cowichan",
      "North Perth",
      "North Riverdale",
      "North Saanich",
      "North St.James Town",
      "North Vancouver",
      "Notre-Dame-de-Grace",
      "O'Connor-Parkview",
      "Oak Bay",
      "Oakridge",
      "Oakville",
      "Oakwood Village",
      "Okanagan",
      "Okotoks",
      "Orangeville",
      "Orillia",
      "Oshawa",
      "Ottawa",
      "Owen Sound",
      "Palmerston-Little Italy",
      "Paris",
      "Parksville",
      "Parkwoods-Donalda",
      "Pelmo Park-Humberlea",
      "Pembroke",
      "Penticton",
      "Petawawa",
      "Peterborough",
      "Pickering",
      "Pincourt",
      "Pitt Meadows",
      "Pleasant View",
      "Pointe-Claire",
      "Port Alberni",
      "Port Colborne",
      "Port Coquitlam",
      "Port Hope",
      "Port Moody",
      "Portage la Prairie",
      "Powell River",
      "Prevost",
      "Prince Albert",
      "Prince Edward",
      "Prince George",
      "Prince Rupert",
      "Princess-Rosethorn",
      "Quebec",
      "Queenswood Heights",
      "Quinte West",
      "Rayside-Balfour",
      "Red Deer",
      "Regent Park",
      "Regina",
      "Repentigny",
      "Rexdale-Kipling",
      "Richmond",
      "Richmond Hill",
      "Rimouski",
      "Riviere-du-Loup",
      "Roberval",
      "Rock Forest",
      "Rockcliffe-Smythe",
      "Roncesvalles",
      "Rosedale-Moore Park",
      "Rosemere",
      "Rouge",
      "Rouyn-Noranda",
      "Runnymede-Bloor West Village",
      "Saguenay",
      "Saint John",
      "Saint-Augustin-de-Desmaures",
      "Saint-Basile-le-Grand",
      "Saint-Bruno-de-Montarville",
      "Saint-Canut",
      "Saint-Constant",
      "Saint-Eustache",
      "Saint-Felicien",
      "Saint-Georges",
      "Saint-Hyacinthe",
      "Saint-Jean-sur-Richelieu",
      "Saint-Jerome",
      "Saint-Laurent",
      "Saint-Lazare",
      "Saint-Leonard",
      "Saint-Lin-Laurentides",
      "Sainte-Adele",
      "Sainte-Agathe-des-Monts",
      "Sainte-Anne-des-Plaines",
      "Sainte-Catherine",
      "Sainte-Catherine",
      "Sainte-Julie",
      "Sainte-Marie",
      "Sainte-Marthe-sur-le-Lac",
      "Sainte-Therese",
      "Salaberry-de-Valleyfield",
      "Salmon Arm",
      "Salt Spring Island",
      "Sarnia",
      "Saskatoon",
      "Sault Ste. Marie",
      "Scarborough Village",
      "Selkirk",
      "Sept-Iles",
      "Shawinigan",
      "Sherbrooke",
      "Sherwood Park",
      "Simcoe",
      "Sorel-Tracy",
      "South Parkdale",
      "South Riverdale",
      "Spruce Grove",
      "Squamish",
      "St. Albert",
      "St. Catharines",
      "St. John's",
      "St. Thomas",
      "St.Andrew-Windfields",
      "Steeles",
      "Steinbach",
      "Stonegate-Queensway",
      "Stouffville",
      "Stratford",
      "Strathmore",
      "Summerland",
      "Summerside",
      "Surrey",
      "Swift Current",
      "Sydney",
      "Sylvan Lake",
      "Tam O'Shanter-Sullivan",
      "Taylor-Massey",
      "Tecumseh",
      "Temiskaming Shores",
      "Terrace",
      "Terrebonne",
      "The Beaches",
      "Thetford-Mines",
      "Thistletown-Beaumond Heights",
      "Thompson",
      "Thorncliffe Park",
      "Thorold",
      "Thunder Bay",
      "Timmins",
      "Toronto",
      "Trinity-Bellwoods",
      "Trois-Rivieres",
      "Truro",
      "Tsawwassen",
      "Uxbridge",
      "Val-d'Or",
      "Valley East",
      "Vancouver",
      "Vanier",
      "Varennes",
      "Vaudreuil-Dorion",
      "Vaughan",
      "Vernon",
      "Victoria",
      "Victoria Village",
      "Victoriaville",
      "Walnut Grove",
      "Warman",
      "Wasaga Beach",
      "Waterfront Communities-The Island",
      "Waterloo",
      "Welland",
      "West End",
      "West Hill",
      "West Humber-Clairville",
      "West Kelowna",
      "West Vancouver",
      "Westminster-Branson",
      "Westmount",
      "Weston",
      "Weston-Pellam Park",
      "Wetaskiwin",
      "Wexford/Maryvale",
      "Weyburn",
      "White Rock",
      "Whitehorse",
      "Williams Lake",
      "Willowdale",
      "Willowdale East",
      "Willowdale West",
      "Willowridge-Martingrove-Richview",
      "Windsor",
      "Winkler",
      "Winnipeg",
      "Woburn",
      "Woodbine Corridor",
      "Woodstock",
      "Wychwood",
      "Yellowknife",
      "Yonge-Eglinton",
      "Yonge-St.Clair",
      "York University Heights",
      "Yorkdale-Glen Park",
      "Yorkton",
      "le Plateau",
    ],
  },
  "Cayman Islands": {
    code: "KY",
    cities: ["Bodden Town", "George Town", "West Bay"],
  },
  "Central African Republic": {
    code: "CF",
    cities: [
      "Alindao",
      "Baboua",
      "Bambari",
      "Bangassou",
      "Bangui",
      "Baoro",
      "Batangafo",
      "Begoua",
      "Berberati",
      "Bimbo",
      "Boali",
      "Bocaranga",
      "Boda",
      "Bossangoa",
      "Bossembele",
      "Bossemtele I",
      "Bouar",
      "Bouca",
      "Bozoum",
      "Bria",
      "Carnot",
      "Dekoa",
      "Gamboula",
      "Grimari",
      "Ippy",
      "Kabo",
      "Kaga-Bandoro",
      "Kouango",
      "Mbaiki",
      "Mbres",
      "Ndele",
      "Nola",
      "Obo",
      "Paoua",
      "Sibut",
      "Yaloke",
      "Zemio",
    ],
  },
  Chad: {
    code: "TD",
    cities: [
      "Abeche",
      "Abou Deia",
      "Adre",
      "Am Timan",
      "Amdjarass",
      "Ati",
      "Baibokoum",
      "Bebedja",
      "Bediondo",
      "Beinamar",
      "Benoy",
      "Bere",
      "Biltine",
      "Bitkine",
      "Bokoro",
      "Bol",
      "Bongor",
      "Bousso",
      "Doba",
      "Dourbali",
      "Fada",
      "Faya-Largeau",
      "Fianga",
      "Gore",
      "Goundi",
      "Gounou Gaya",
      "Goz Beida",
      "Guelendeng",
      "Iriba",
      "Kelo",
      "Koumra",
      "Krim Krim",
      "Kyabe",
      "Lai",
      "Lere",
      "Mandelia",
      "Mangalme",
      "Mao",
      "Maro",
      "Massaguet",
      "Massakory",
      "Mboursou Lere",
      "Melfi",
      "Moissala",
      "Mongo",
      "Moundou",
      "Moussoro",
      "N'Djamena",
      "Ngama",
      "Ngama",
      "Ngouri",
      "Pala",
      "Sarh",
      "Umm Hajar",
    ],
  },
  Chile: {
    code: "CL",
    cities: [
      "Alto Hospicio",
      "Ancud",
      "Angol",
      "Antofagasta",
      "Arauco",
      "Arica",
      "Arturo Prat",
      "Barrio Industrial",
      "Buin",
      "Bulnes",
      "Cabrero",
      "Calama",
      "Calbuco",
      "Canete",
      "Carahue",
      "Cartagena",
      "Castro",
      "Caupolican",
      "Cauquenes",
      "Cavancha",
      "Chicureo",
      "Chiguayante",
      "Chillan",
      "Chimbarongo",
      "Chonchi",
      "Collipulli",
      "Concepcion",
      "Constitucion",
      "Copiapo",
      "Coquimbo",
      "Coronel",
      "Coyhaique",
      "Curanilahue",
      "Curico",
      "Dalcahue",
      "Diego de Almagro",
      "El Monte",
      "Gomez Carreno",
      "Graneros",
      "Hacienda La Calera",
      "Hospital",
      "Illapel",
      "Iquique",
      "La Ligua",
      "La Pintana",
      "La Serena",
      "La Tirana",
      "La Union",
      "Laja",
      "Lampa",
      "Las Animas",
      "Lautaro",
      "Lebu",
      "Limache",
      "Linares",
      "Llaillay",
      "Lo Prado",
      "Loncoche",
      "Los Andes",
      "Los Angeles",
      "Lota",
      "Machali",
      "Mejillones",
      "Melipilla",
      "Molina",
      "Monte Patria",
      "Mulchen",
      "Nacimiento",
      "Nueva Imperial",
      "Osorno",
      "Ovalle",
      "Paine",
      "Panguipulli",
      "Parque Balmaceda",
      "Parral",
      "Penaflor",
      "Penco",
      "Pitrufquen",
      "Playa Brava",
      "Pozo Almonte",
      "Pucon",
      "Puente Alto",
      "Puerto Aysen",
      "Puerto Montt",
      "Puerto Natales",
      "Puerto Varas",
      "Punta Arenas",
      "Purranque",
      "Quellon",
      "Quillota",
      "Quilpue",
      "Rancagua",
      "Rengo",
      "Rio Bueno",
      "Salamanca",
      "San Antonio",
      "San Bernardo",
      "San Carlos",
      "San Clemente",
      "San Felipe",
      "San Javier",
      "San Vicente",
      "San Vicente de Tagua Tagua",
      "Santa Cruz",
      "Santiago",
      "Talagante",
      "Talca",
      "Talcahuano",
      "Taltal",
      "Temuco",
      "Tocopilla",
      "Tome",
      "Traiguen",
      "Valdivia",
      "Vallenar",
      "Valparaiso",
      "Victoria",
      "Vicuna",
      "Villa Alemana",
      "Villa Presidente Frei",
      "Villarrica",
      "Vina del Mar",
      "Yumbel",
    ],
  },
  China: {
    code: "CN",
    cities: [
      "Acheng",
      "Ahu",
      "Aikou",
      "Akesalayi",
      "Aksu",
      "Alamaiti",
      "Altay",
      "Amuquhu",
      "Anbu",
      "Anda",
      "Anfu",
      "Anfu",
      "Anguo",
      "Anhua",
      "Anjiang",
      "Anju",
      "Ankang",
      "Ankang",
      "Anlan",
      "Anliu",
      "Anlong",
      "Anlu",
      "Anning",
      "Anping",
      "Anqing",
      "Anqiu",
      "Anshan",
      "Anshun",
      "Antu",
      "Anwen",
      "Anxiang",
      "Anyang",
      "Anyuan",
      "Anzi",
      "Aoshi",
      "Aoxi",
      "Apengjiang",
      "Aral",
      "Arele",
      "Arele",
      "Arewusitang",
      "Arix",
      "Atush",
      "Awati",
      "Awuliya",
      "Aykol",
      "Azhatebage",
      "Babu",
      "Bachuan",
      "Bage'awati",
      "Bageqi",
      "Bai'anba",
      "Baicheng",
      "Baicheng",
      "Baichihe",
      "Baidi",
      "Baiguan",
      "Baihe",
      "Baihe",
      "Baihecun",
      "Baihua",
      "Baijia",
      "Baijia",
      "Baijiantan",
      "Baijiawan",
      "Bailin",
      "Bailin",
      "Bailu",
      "Baima",
      "Baimajing",
      "Baiqiao",
      "Baiquan",
      "Baise",
      "Baisha",
      "Baisha",
      "Baisha",
      "Baishan",
      "Baisheng",
      "Baishi",
      "Baishikante",
      "Baishishan",
      "Baitao",
      "Baitu",
      "Baituo",
      "Baixi",
      "Baiyang",
      "Baiyin",
      "Baizi",
      "Bake",
      "Baliwan",
      "Balu",
      "Banan",
      "Banqiao",
      "Banxi",
      "Baoding",
      "Baoding",
      "Baofeng",
      "Baohe",
      "Baoji",
      "Baojia",
      "Baojia",
      "Baolong",
      "Baolong",
      "Baoluan",
      "Baoqing",
      "Baoshan",
      "Baoshan",
      "Baotou",
      "Baoxing",
      "Baoying",
      "Baoziba",
      "Bashan",
      "Bashan",
      "Basuo",
      "Batang",
      "Bayan",
      "Bayan Hot",
      "Bayan Nur",
      "Bayandai",
      "Bayiji",
      "Baytokay",
      "Bei'an",
      "Beibei",
      "Beichengqu",
      "Beidaihehaibin",
      "Beidao",
      "Beihai",
      "Beihai",
      "Beijing",
      "Beiliu",
      "Beimeng",
      "Beipiao",
      "Beisu",
      "Beitanzhuang",
      "Beiwangli",
      "Beizheng",
      "Bengbu",
      "Benxi",
      "Biantang",
      "Bianzhuang",
      "Bibo",
      "Bijie",
      "Bikou",
      "Bin Xian",
      "Binhe",
      "Binzhou",
      "Bishan",
      "Bishan",
      "Biyang",
      "Bojia",
      "Bole",
      "Boli",
      "Bonan",
      "Boshan",
      "Botou",
      "Bowangshan",
      "Bozhou",
      "Buhe",
      "Burqin",
      "Buzhake",
      "Buzhuang",
      "Caidian",
      "Caijia",
      "Caijiagang",
      "Cangling",
      "Cangzhou",
      "Caohe",
      "Caohui",
      "Caojie",
      "Caoqiao",
      "Caotang",
      "Ceyu",
      "Chahe",
      "Chaigou",
      "Chaihe",
      "Chang'an",
      "Changba",
      "Changba",
      "Changcheng",
      "Changchuan",
      "Changchun",
      "Changdao",
      "Changde",
      "Changdian",
      "Changji",
      "Changle",
      "Changle",
      "Changleng",
      "Changli",
      "Changling",
      "Changling",
      "Changlong",
      "Changning",
      "Changping",
      "Changqing",
      "Changsha",
      "Changsha",
      "Changshouhu",
      "Changshu",
      "Changtan",
      "Changtan",
      "Changtu",
      "Changxin",
      "Changyi",
      "Changyuan",
      "Changzhi",
      "Changzhi",
      "Changzhou",
      "Chaohu",
      "Chaotian",
      "Chaoyang",
      "Chaoyang",
      "Chaoyang",
      "Chaozhou",
      "Chefushan",
      "Chela",
      "Chengbei",
      "Chengde",
      "Chengdu",
      "Chenggu",
      "Chenghai",
      "Chenghua",
      "Chengjiang",
      "Chengjiao",
      "Chengtangcun",
      "Chengxi",
      "Chengxian Chengguanzhen",
      "Chengxiang",
      "Chengyang",
      "Chengzhong",
      "Chengzihe",
      "Chenjiaba",
      "Chenlou",
      "Chenshi",
      "Chentuan",
      "Chenzhou",
      "Chifeng",
      "Chizhou",
      "Chongkan",
      "Chonglong",
      "Chongqing",
      "Chongxing",
      "Chongxing",
      "Chongzuo",
      "Chuanwang",
      "Chuzhou",
      "Cixi",
      "Ciyun",
      "Cizhu",
      "Conglin",
      "Cuijiaji",
      "Cuiyun",
      "Da'an",
      "Dachang",
      "Dachang Shandao",
      "Dacheng",
      "Dadamtu",
      "Dade",
      "Dafengdong",
      "Daguan",
      "Daguan",
      "Dahuangshan",
      "Daizhuang",
      "Dajin",
      "Dalai",
      "Dalain Hob",
      "Dali",
      "Dali Old Town",
      "Dalian",
      "Daliang",
      "Dalianwan",
      "Dalu",
      "Damiao",
      "Damiao",
      "Damoujia",
      "Dandong",
      "Dandu",
      "Dangjiang",
      "Danjiangkou",
      "Danshui",
      "Danzi",
      "Daocheng",
      "Daokou",
      "Daotian",
      "Dapeng",
      "Daqing",
      "Dasha",
      "Dashahe",
      "Dashan",
      "Dasheng",
      "Dasheng",
      "Dashi",
      "Dashi",
      "Dashiqiao",
      "Dashitou",
      "Dashu",
      "Dashun",
      "Datong",
      "Datong",
      "Datong",
      "Datun",
      "Dawan",
      "Dawukou",
      "Daxi",
      "Daxie",
      "Daxing",
      "Daxing",
      "Daxing",
      "Daxu",
      "Dayang",
      "Daye",
      "Dayou",
      "Dazeshan",
      "Dazhong",
      "Dazhou",
      "Dazhou",
      "Dazhuang",
      "Degan",
      "Dehui",
      "Dengbu",
      "Dengzhou",
      "Dengzhou",
      "Deqen",
      "Deqing",
      "Deshun",
      "Deyang",
      "Dezhou",
      "Dianbu",
      "Diancun",
      "Dianga",
      "Dianshui",
      "Dianzi",
      "Diaoyucheng",
      "Didao",
      "Dingcheng",
      "Dingjia",
      "Dingshi",
      "Dingtao",
      "Dingxi",
      "Dingzhou",
      "Diping",
      "Dongcheng",
      "Dongchuan",
      "Dongcun",
      "Dongdu",
      "Dongfeng",
      "Dongguan",
      "Donghai",
      "Donghe",
      "Donghe",
      "Donghui",
      "Dongjia",
      "Dongjiang",
      "Dongkan",
      "Dongling",
      "Dongmazar",
      "Dongning",
      "Dongshan",
      "Dongsheng",
      "Dongta",
      "Dongtai",
      "Dongwenquan",
      "Dongxi",
      "Dongxia",
      "Dongxing",
      "Dongyang",
      "Dongyang",
      "Dongying",
      "Dorbod",
      "Douping",
      "Ducheng",
      "Dunbage",
      "Dunhao",
      "Dunhou",
      "Dunhua",
      "Dunhuang",
      "Duoba",
      "Duobao",
      "Duping",
      "Dure",
      "Dushan",
      "Dushanzi",
      "Dushi",
      "Duyun",
      "Duzhou",
      "E'zhou",
      "Ehen Hudag",
      "Elixku",
      "Eman",
      "Emin",
      "Encheng",
      "Enshi",
      "Erdaojiang",
      "Erenhot",
      "Erlang",
      "Erong",
      "Ersheng",
      "Ezhou",
      "Fanba",
      "Fangcheng Chengguanzhen",
      "Fangchenggang",
      "Fangcun",
      "Fangshan",
      "Fanlou",
      "Fazhanhe",
      "Feicheng",
      "Feicheng",
      "Fendou",
      "Fengcheng",
      "Fengcheng",
      "Fengcheng",
      "Fengdie",
      "Fenggang",
      "Fenggao",
      "Fenghua",
      "Fenghuang",
      "Fenghuang",
      "Fengjia",
      "Fengkou",
      "Fenglai",
      "Fengle",
      "Fengling",
      "Fengping",
      "Fengping",
      "Fengrun",
      "Fengsheng",
      "Fengxiang",
      "Fengyi",
      "Fenhe",
      "Fenshui",
      "Fenyi",
      "Foshan",
      "Foya",
      "Fu'an",
      "Fubang",
      "Fuding",
      "Fudong",
      "Fuguo",
      "Fuhuan",
      "Fuji",
      "Fujia",
      "Fujin",
      "Fuling",
      "Fulu",
      "Fulu",
      "Fuqing",
      "Fushun",
      "Futian",
      "Fuxin",
      "Fuxing",
      "Fuyang",
      "Fuyang",
      "Fuyu",
      "Fuyu",
      "Fuyuan",
      "Fuyun",
      "Fuzhou",
      "Fuzhou",
      "Gangjia",
      "Gangshang",
      "Gangtou",
      "Gangu Chengguanzhen",
      "Gannan",
      "Ganning",
      "Ganshui",
      "Gantang",
      "Gantang",
      "Ganxi",
      "Ganzhou",
      "Gao'an",
      "Gaofeng",
      "Gaofeng",
      "Gaogou",
      "Gaogu",
      "Gaojia",
      "Gaoliang",
      "Gaoliu",
      "Gaoliu",
      "Gaolou",
      "Gaomi",
      "Gaoping",
      "Gaoping",
      "Gaoqiao",
      "Gaosheng",
      "Gaotian",
      "Gaoyan",
      "Gaoyou",
      "Gaozhou",
      "Gaozuo",
      "Gecheng",
      "Gejiu",
      "Gelan",
      "Genhe",
      "Gongchangling",
      "Gongguan",
      "Gongmen",
      "Gongping",
      "Gongqingcheng",
      "Gongtan",
      "Gongyi",
      "Gongzhuling",
      "Guanba",
      "Guandu",
      "Guandu",
      "Guang'an",
      "Guangcun",
      "Guanglu",
      "Guangpu",
      "Guangshui",
      "Guangshun",
      "Guangxing",
      "Guangyuan",
      "Guangzhou",
      "Guanhu",
      "Guankou",
      "Guanshan",
      "Guansheng",
      "Guanting",
      "Guanyang",
      "Guanyin",
      "Guanzhuang",
      "Guanzhuang",
      "Guchang",
      "Gucheng",
      "Gucheng",
      "Gucheng Chengguanzhen",
      "Gudong",
      "Guigang",
      "Guilin",
      "Guiping",
      "Guiren",
      "Guixi",
      "Guixi",
      "Guiyang",
      "Gulebage",
      "Guli",
      "Gulin",
      "Gulou",
      "Gulu",
      "Gulu",
      "Gunan",
      "Guochuan",
      "Guocun",
      "Guofu",
      "Guohe",
      "Guojia",
      "Guojia",
      "Guoliang",
      "Guozhen",
      "Gupi",
      "Gushu",
      "Gusong",
      "Gutao",
      "Guxi",
      "Guxian",
      "Guye",
      "Guyuan",
      "Ha'ergai Dadui",
      "Hadapu Zhen",
      "Haicheng",
      "Haidong Zhen",
      "Haikou",
      "Haikou",
      "Hailar",
      "Hailin",
      "Hailun",
      "Haimen",
      "Haining",
      "Haitang",
      "Haitou",
      "Haiyang",
      "Haizhou",
      "Hami",
      "Han'airike",
      "Hanbin",
      "Hancheng",
      "Hancheng",
      "Hanchuan",
      "Handan",
      "Hanfeng",
      "Hangu",
      "Hangzhou",
      "Hanjia",
      "Hanlin",
      "Hanting",
      "Hanwang",
      "Hanwang",
      "Hanyin Chengguanzhen",
      "Hanyuan",
      "Hanyuan",
      "Hanyuan",
      "Hanzhong",
      "Haojiaqiao",
      "Haomen",
      "Harbin",
      "Heba",
      "Hebao",
      "Hebi",
      "Hebian",
      "Hechi",
      "Hechuan",
      "Hecun",
      "Hede",
      "Hedong",
      "Hefei",
      "Hefeng",
      "Hegang",
      "Hegeng",
      "Hegou",
      "Heguan",
      "Hehua",
      "Heihe",
      "Heishan",
      "Heishui",
      "Heixi",
      "Hejiang",
      "Hekou",
      "Helin",
      "Helong",
      "Hengbei",
      "Henghe",
      "Hengshan",
      "Hengshan",
      "Hengshui",
      "Hengyang",
      "Hepingjie",
      "Hepo",
      "Hepu",
      "Heqian",
      "Heqiao",
      "Heqing",
      "Heshan",
      "Heshun",
      "Hetoudian",
      "Hexing",
      "Heyan",
      "Heyou",
      "Heyuan",
      "Heze",
      "Hezhou",
      "Hezuo",
      "Hohhot",
      "Hong'an",
      "Hongbao",
      "Hongchuan",
      "Honggang",
      "Hongguang Qidui",
      "Honghe",
      "Honghe",
      "Honghu",
      "Hongjiang",
      "Honglu",
      "Hongqiao",
      "Hongqiao",
      "Hongtu",
      "Hongzhou",
      "Hotan",
      "Houba",
      "Houqiao",
      "Houshan",
      "Houxi",
      "Houzhen",
      "Hoxtolgay",
      "Huacheng",
      "Huadian",
      "Huai'an",
      "Huaibei",
      "Huaicheng",
      "Huaidian",
      "Huaihua",
      "Huainan",
      "Huaiyang",
      "Huaiyuan Chengguanzhen",
      "Hualin",
      "Hualong",
      "Huanan",
      "Huangbai",
      "Huangchen",
      "Huangdi",
      "Huanggang",
      "Huanggang",
      "Huanghua",
      "Huangji",
      "Huangjia",
      "Huanglou",
      "Huangmei",
      "Huangmen",
      "Huangnihe",
      "Huangpi",
      "Huangsha",
      "Huangshan",
      "Huangshan",
      "Huangshi",
      "Huangshui",
      "Huangzhou",
      "Huankou",
      "Huanren",
      "Huashan",
      "Huatugou",
      "Huayin",
      "Huazhou",
      "Hucheng",
      "Huchuan",
      "Hudiyuzi",
      "Hufeng",
      "Huguo",
      "Huicheng",
      "Huicheng",
      "Huilong",
      "Huilong",
      "Huilong",
      "Huimin",
      "Huimin",
      "Huinan",
      "Huiqu",
      "Huixian Chengguanzhen",
      "Huixing",
      "Huizhou",
      "Hulan",
      "Hulan Ergi",
      "Hulunbuir",
      "Humen",
      "Hunchun",
      "Huocheng",
      "Huolu",
      "Huoqiu Chengguanzhen",
      "Huoshilafu",
      "Hushitai",
      "Hutang",
      "Huwei",
      "Huzhai",
      "Huzhou",
      "Jalai Nur",
      "Jelilyuezi",
      "Ji'an",
      "Ji'an",
      "Jiachuan",
      "Jiachuan",
      "Jiagao",
      "Jiagedaqi",
      "Jiahanbage",
      "Jiamusi",
      "Jian'ou",
      "Jianchang",
      "Jiang'an",
      "Jiangbei",
      "Jiangchi",
      "Jiangjia",
      "Jiangkou",
      "Jiangkou",
      "Jiangluo",
      "Jiangmen",
      "Jiangnan",
      "Jiangping",
      "Jiangshan",
      "Jianguang",
      "Jiangxi",
      "Jiangyan",
      "Jiangyin",
      "Jiangyou",
      "Jiangzhuang",
      "Jiangzhuang",
      "Jianlong",
      "Jianping",
      "Jianshan",
      "Jianshui",
      "Jiaogong",
      "Jiaohe",
      "Jiaojiang",
      "Jiaoshi",
      "Jiaozhou",
      "Jiaozuo",
      "Jiaping",
      "Jiashan",
      "Jiasi",
      "Jiawang",
      "Jiawang Zhen",
      "Jiaxing",
      "Jiayi",
      "Jiayue",
      "Jiayuguan",
      "Jiazi",
      "Jidong",
      "Jiehu",
      "Jielong",
      "Jieshi",
      "Jieshi",
      "Jieshou",
      "Jietou",
      "Jiexiu",
      "Jieyang",
      "Jifeng",
      "Jijia",
      "Jijiang",
      "Jilin",
      "Jimo",
      "Jinan",
      "Jinchang",
      "Jincheng",
      "Jindai",
      "Jindaoxia",
      "Jinding",
      "Jinfeng",
      "Jing'an",
      "Jingdezhen",
      "Jinggou",
      "Jingguan",
      "Jinghong",
      "Jingling",
      "Jingmen",
      "Jingui",
      "Jingzhi",
      "Jingzhou",
      "Jinhua",
      "Jinhua",
      "Jining",
      "Jining",
      "Jinji",
      "Jinji",
      "Jinjiang",
      "Jinjiang",
      "Jinjiang",
      "Jinlong",
      "Jinniu",
      "Jinqiao",
      "Jinsha",
      "Jinshan",
      "Jinshan",
      "Jinshan",
      "Jinshi",
      "Jinxiang",
      "Jinzhong",
      "Jinzhongzi",
      "Jinzhou",
      "Jinzhou",
      "Jishu",
      "Jishui",
      "Jitai",
      "Jiuchao",
      "Jiuchi",
      "Jiudian",
      "Jiuhe",
      "Jiujiang",
      "Jiujing",
      "Jiulongshan",
      "Jiupu",
      "Jiuquan",
      "Jiushan",
      "Jiutai",
      "Jiuxian",
      "Jixi",
      "Jixian",
      "Jiyuan",
      "Jizhou",
      "Judian",
      "Juegang",
      "Juexi",
      "Jukui",
      "Junlian",
      "Jutou",
      "Juye",
      "Ka'erdun",
      "Ka'erqin",
      "Ka'ersai",
      "Kaifeng",
      "Kaihua",
      "Kaili",
      "Kaitang",
      "Kaitong",
      "Kaiyuan",
      "Kaiyuan",
      "Kalasu",
      "Kalayagaqi",
      "Kangding",
      "Kangle",
      "Kanjia",
      "Kaqun",
      "Karaki",
      "Karamay",
      "Kashgar",
      "Kawake",
      "Kax",
      "Keda",
      "Kezilexilike",
      "Kokyar",
      "Kongtan",
      "Korla",
      "Kuanchuan",
      "Kuandian",
      "Kuangshi",
      "Kuerti",
      "Kuiya",
      "Kunming",
      "Kunshan",
      "Kunyang",
      "Kuoshi'airike",
      "Kuoyiqi",
      "Kuqa",
      "Laba",
      "Labuleng",
      "Laibin",
      "Laisu",
      "Laitan",
      "Laiwu",
      "Laixi",
      "Laiyang",
      "Laizhou",
      "Langao Chengguanzhen",
      "Langfang",
      "Langgar",
      "Langru",
      "Langtoucun",
      "Langxiang",
      "Langzhong",
      "Lanqiao",
      "Lanshan",
      "Lanxi",
      "Lanxi",
      "Lanyang",
      "Lanzhou",
      "Laohekou",
      "Laojunmiao",
      "Layka",
      "Lecheng",
      "Leiwang",
      "Leiyang",
      "Lengshuijiang",
      "Lengshuitan",
      "Leshan",
      "Lhasa",
      "Liancheng",
      "Liangdang Chengguanzhen",
      "Lianghekou",
      "Lianghu",
      "Liangji",
      "Liangping",
      "Liangping",
      "Liangshan",
      "Liangshui",
      "Liangxiang",
      "Liangzhai",
      "Lianhe",
      "Lianhe",
      "Lianhu",
      "Lianhua",
      "Lianjiang",
      "Lianshan",
      "Lianwu",
      "Lianyuan",
      "Lianyungang",
      "Lianzhou",
      "Liaocheng",
      "Liaolan",
      "Liaoyang",
      "Liaozhong",
      "Licha",
      "Licheng",
      "Licheng",
      "Lichuan",
      "Lichuan",
      "Lichuan Zhen",
      "Lidu",
      "Ligang",
      "Ligezhuang",
      "Liguo",
      "Lihe",
      "Liji",
      "Lijia",
      "Lijiang",
      "Lijun",
      "Lincang",
      "Linfen",
      "Linfeng",
      "Lingao",
      "Lingcheng",
      "Lingdong",
      "Linghai",
      "Linghe",
      "Lingjiao",
      "Lingquan",
      "Lingwu",
      "Lingyuan",
      "Linhe",
      "Linjiacun",
      "Linjiang",
      "Linjiang",
      "Linjiang",
      "Linkou",
      "Linping",
      "Linqiong",
      "Linqu",
      "Linshi",
      "Linshui",
      "Lintan Chengguanzhen",
      "Lintong",
      "Linxi",
      "Linxi",
      "Linxia Chengguanzhen",
      "Linyi",
      "Liping",
      "Liping",
      "Lirang",
      "Lishi",
      "Lishu",
      "Lishui",
      "Liubao",
      "Liufeng",
      "Liuhe",
      "Liuji",
      "Liuku",
      "Liulin",
      "Liupanshui",
      "Liuping",
      "Liuquan",
      "Liushan",
      "Liutuan",
      "Liuxin",
      "Liuyin",
      "Liuzhi",
      "Liuzhou",
      "Lixi",
      "Lixian",
      "Lixin",
      "Lizhi",
      "Long'e",
      "Longchang",
      "Longcheng",
      "Longchi",
      "Longchi",
      "Longdong",
      "Longfeng",
      "Longfeng",
      "Longfeng",
      "Longfeng",
      "Longfeng",
      "Longgang",
      "Longgang",
      "Longgu",
      "Longhe",
      "Longhe",
      "Longhua",
      "Longji",
      "Longjiang",
      "Longjing",
      "Longjing",
      "Longju",
      "Longkong",
      "Longlin",
      "Longling County",
      "Longmen",
      "Longnan",
      "Longqiao",
      "Longquan",
      "Longsha",
      "Longshan",
      "Longshan",
      "Longshe",
      "Longsheng",
      "Longshi",
      "Longshi",
      "Longshui",
      "Longtai",
      "Longtan",
      "Longtan",
      "Longtanzi",
      "Longxi",
      "Longxi",
      "Longxing",
      "Longxing",
      "Longxing",
      "Longyan",
      "Loudi",
      "Loushanguan",
      "Lu'an",
      "Luancheng",
      "Lubu",
      "Lucheng",
      "Lueeyang Chengguanzhen",
      "Luefeng",
      "Lueshun",
      "Luhe",
      "Lulou",
      "Luming",
      "Luobei",
      "Luocheng",
      "Luohe",
      "Luohuang",
      "Luojiang",
      "Luoli",
      "Luolong",
      "Luomen",
      "Luoping",
      "Luoqi",
      "Luorong",
      "Luotang",
      "Luotian",
      "Luoyang",
      "Luoyang",
      "Luoyang",
      "Luoyu",
      "Luoyun",
      "Lushan",
      "Lushar",
      "Luzhou",
      "Ma'ai",
      "Ma'anshan",
      "Maba",
      "Mabai",
      "Macheng",
      "Maguan",
      "Mahe",
      "Majie",
      "Majie",
      "Mala",
      "Mali",
      "Malianzhuang",
      "Malingshan",
      "Maliu",
      "Maliuzui",
      "Malu",
      "Malu",
      "Mangbang",
      "Manglai",
      "Mangnai Zhen",
      "Mangshi",
      "Manyue",
      "Manzhouli",
      "Maocun",
      "Maogong",
      "Maoming",
      "Mapo",
      "Mawang",
      "Mawu",
      "Mawu",
      "Maying",
      "Mayuan",
      "Mazha",
      "Mazhan",
      "Meihekou",
      "Meijiang",
      "Meishan",
      "Meizhou",
      "Mengcheng Chengguanzhen",
      "Menghuan",
      "Menglang",
      "Mengmao",
      "Mengyan",
      "Mengyin",
      "Mentougou",
      "Mianyang",
      "Mianyang",
      "Mianzhu, Deyang, Sichuan",
      "Miaoba",
      "Miaojie",
      "Miaoxi",
      "Miaoyu",
      "Miaozi",
      "Micheng",
      "Mihe",
      "Mingcun",
      "Mingda",
      "Minggang",
      "Mingguang",
      "Mingguang",
      "Mingshan",
      "Mingshui",
      "Mingshui",
      "Mingyu",
      "Minning",
      "Mishan",
      "Mixia",
      "Mixin",
      "Miyang",
      "Mu'er",
      "Mudanjiang",
      "Mudong",
      "Mudu",
      "Mugala",
      "Muhe",
      "Mujia",
      "Mujiayingzi",
      "Muling",
      "Mutang",
      "Nada",
      "Nagqu",
      "Naliang",
      "Nanbin",
      "Nanchang",
      "Nanchong",
      "Nanchuan",
      "Nancun",
      "Nandajie",
      "Nanding",
      "Nandu",
      "Nanfeng",
      "Nanfeng",
      "Nangong",
      "Nanhu",
      "Nanjian",
      "Nanjin",
      "Nanjing",
      "Nanling",
      "Nanlong",
      "Nanma",
      "Nanmen",
      "Nanning",
      "Nanpeng",
      "Nanpiao",
      "Nanping",
      "Nanping",
      "Nanshu",
      "Nantai",
      "Nantianhu",
      "Nantong",
      "Nantong",
      "Nantuo",
      "Nanxi",
      "Nanya",
      "Nanyang",
      "Nanyang",
      "Nanyaojie",
      "Nanzhang Chengguanzhen",
      "Nanzhou",
      "Naxi",
      "Nehe",
      "Neijiang",
      "Nenjiang",
      "Nianbo",
      "Nianzhuang",
      "Nianzishan",
      "Nima",
      "Ningbo",
      "Ningde",
      "Ningdong",
      "Ninghai",
      "Ninghai",
      "Ningyang",
      "Niyang",
      "Nongzhang",
      "Nuofu",
      "Nuozhadu",
      "Onyar",
      "Ordos",
      "Oroqen Zizhiqi",
      "Oymak",
      "Oytograk",
      "Paikeqi",
      "Pailou",
      "Paipu",
      "Pan'an",
      "Panghai",
      "Panjim",
      "Panlong",
      "Panlong",
      "Panshan",
      "Panshi",
      "Panzhihua",
      "Paosha",
      "Peicheng",
      "Peixing",
      "Pengcheng",
      "Pengze",
      "Pianbai",
      "Pianjiao",
      "Picheng",
      "Ping'an",
      "Pingba",
      "Pingdingshan",
      "Pingdu",
      "Pingjin",
      "Pingkai",
      "Pingli",
      "Pingliang",
      "Pingnan",
      "Pingqiao",
      "Pingshan",
      "Pingshan",
      "Pingtan",
      "Pingwu County",
      "Pingxiang",
      "Pingyi",
      "Pingyin",
      "Pingzhuang",
      "Pipa",
      "Pizhou",
      "Poyang",
      "Pu'an",
      "Pucheng",
      "Puchi",
      "Puchuan",
      "Puji",
      "Pulandian",
      "Pulue",
      "Puning",
      "Puqi",
      "Puqiakeqi",
      "Pushun",
      "Putian",
      "Puyang",
      "Puyang",
      "Puyang Chengguanzhen",
      "Puzi",
      "Qamdo",
      "Qapqal",
      "Qaraqash",
      "Qarek",
      "Qia'erbage",
      "Qianhu",
      "Qianjiang",
      "Qianjiang",
      "Qianliu",
      "Qiantang",
      "Qianzhou",
      "Qiaoguan",
      "Qiaotou",
      "Qiaotou",
      "Qiaotou",
      "Qiemo",
      "Qilian",
      "Qina",
      "Qincheng",
      "Qing'an",
      "Qingdao",
      "Qingfeng",
      "Qingfu",
      "Qinggang",
      "Qinggang",
      "Qinglian",
      "Qinglong",
      "Qingnian",
      "Qingnian",
      "Qingping",
      "Qingquan",
      "Qingshanquan",
      "Qingsheng",
      "Qingshizui",
      "Qingshui",
      "Qingshuping",
      "Qingxi",
      "Qingxichang",
      "Qingyang",
      "Qingyang",
      "Qingyang",
      "Qingyuan",
      "Qingzhou",
      "Qinhuangdao",
      "Qinnan",
      "Qinting",
      "Qinzhou",
      "Qionghai",
      "Qionghu",
      "Qiongshan",
      "Qipan",
      "Qiqihar",
      "Qishan",
      "Qishan",
      "Qishan",
      "Qitaihe",
      "Qitang",
      "Qiuji",
      "Qiushan",
      "Qongkur",
      "Quankou",
      "Quanzhou",
      "Quchi",
      "Qufu",
      "Qugaona",
      "Qujing",
      "Qukou",
      "Qulukay",
      "Qunli",
      "Qushi",
      "Qushui",
      "Quzhang",
      "Quzhou",
      "Rangdu",
      "Renhe",
      "Renqiu",
      "Rensha",
      "Renxian",
      "Renyi",
      "Renzhao",
      "Rikaze",
      "Rizhao",
      "Rizhuang",
      "Rongjiang",
      "Ronglong",
      "Rongxi",
      "Rugao",
      "Rui'an",
      "Ruidian",
      "Runing",
      "Salaqi",
      "Samuyuzi",
      "Sanba",
      "Sancang",
      "Sanchazi",
      "Sanchuan",
      "Sandaohezi",
      "Sandaoling Lutiankuang Wuqi Nongchang",
      "Sandu",
      "Sangzhe",
      "Sanhe",
      "Sanhe",
      "Sanhe",
      "Sanhe",
      "Sanhui",
      "Sanhuikou",
      "Sanjiang",
      "Sanjiao",
      "Sanjiao",
      "Sanjiaocheng",
      "Sankeshu",
      "Sanmenxia",
      "Sanmiao",
      "Sanming",
      "Sanqu",
      "Sanquan",
      "Sansheng",
      "Sanshui",
      "Santangpu",
      "Sanxi",
      "Sanxi",
      "Sanya",
      "Sanyuan",
      "Sanzhuang",
      "Saybag",
      "Shaba",
      "Shaba",
      "Shache",
      "Shagang",
      "Shahe",
      "Shahecheng",
      "Shaji",
      "Shaliuhe",
      "Shancheng",
      "Shandan",
      "Shangchong",
      "Shanghai",
      "Shanghe",
      "Shanghuang",
      "Shangkou",
      "Shangluo",
      "Shangmei",
      "Shangpa",
      "Shangqiu",
      "Shangrao",
      "Shangri-La",
      "Shangsi",
      "Shangyu",
      "Shangyun",
      "Shangzhi",
      "Shanhaiguan",
      "Shanhecun",
      "Shanji",
      "Shankou",
      "Shanting",
      "Shantou",
      "Shanwang",
      "Shanwei",
      "Shaodian",
      "Shaoguan",
      "Shaoshan",
      "Shaowu",
      "Shaoxing",
      "Shaoyang",
      "Shaoyu",
      "Shaoyun",
      "Shaozhuang",
      "Shaping",
      "Shaping",
      "Shatian",
      "Shawan",
      "Shayu",
      "Shazi",
      "Shenglilu",
      "Shenjiamen",
      "Shenliu",
      "Shenyang",
      "Shenzhen",
      "Shetan",
      "Shi'an",
      "Shibao",
      "Shibuzi",
      "Shichuan",
      "Shidi",
      "Shidui",
      "Shigang",
      "Shigu",
      "Shiguai",
      "Shihao",
      "Shihezi",
      "Shihui",
      "Shiji",
      "Shijiao",
      "Shijiazhuang",
      "Shijiba",
      "Shikang",
      "Shili",
      "Shilin",
      "Shilong",
      "Shilong",
      "Shilongba",
      "Shima",
      "Shima",
      "Shima",
      "Shimen",
      "Shimen",
      "Shiqi",
      "Shiqiao",
      "Shiqiao",
      "Shiqiaozi",
      "Shiquan",
      "Shitan",
      "Shitan",
      "Shitanjing",
      "Shituo",
      "Shiwan",
      "Shiwan",
      "Shiwan",
      "Shixia",
      "Shixing",
      "Shiyan",
      "Shiyan",
      "Shiyu",
      "Shizhai",
      "Shizhi",
      "Shizilu",
      "Shizuishan",
      "Shizuishan",
      "Shouguang",
      "Shouxian",
      "Shuangcheng",
      "Shuangfeng",
      "Shuangfengqiao",
      "Shuangfu",
      "Shuanggou",
      "Shuanghe",
      "Shuanghekou",
      "Shuanghekou",
      "Shuanghuai",
      "Shuangjiang",
      "Shuangjiang",
      "Shuangliao",
      "Shuanglong",
      "Shuanglong",
      "Shuanglong",
      "Shuanglongchang",
      "Shuanglonghu",
      "Shuanglu",
      "Shuanglu",
      "Shuangshi",
      "Shuangtang",
      "Shuangyang",
      "Shuangyashan",
      "Shuijiang",
      "Shuikou",
      "Shuikou",
      "Shuitu",
      "Shuiyang",
      "Shuizhai",
      "Shulan",
      "Shunhe",
      "Shunyi",
      "Shunzhou",
      "Shuren",
      "Sihu",
      "Simao",
      "Simen",
      "Siping",
      "Sishui",
      "Sitou",
      "Siwei",
      "Siyeke",
      "Songbai",
      "Songcheng",
      "Songgai",
      "Songjiang",
      "Songjianghe",
      "Songling",
      "Songling",
      "Songlou",
      "Songshu",
      "Songyang",
      "Songyuan",
      "Suhe",
      "Suicheng",
      "Suifenhe",
      "Suihua",
      "Suiling",
      "Suining",
      "Suixi",
      "Suizhou",
      "Sujiatun",
      "Sunlou",
      "Suozhen",
      "Suqian",
      "Suyangshan",
      "Suzhou",
      "Suzhou",
      "Tacheng",
      "Tage'erqi",
      "Tahe",
      "Tai'an",
      "Tai'an",
      "Tai'an",
      "Taibai",
      "Taicang",
      "Taifu",
      "Taihe",
      "Tailai",
      "Tailong",
      "Taiping",
      "Taiping",
      "Taiping",
      "Taipingchang",
      "Taishan",
      "Taitou",
      "Taixing",
      "Taiyuan",
      "Taizhou",
      "Taizhou",
      "Tanchang Chengguanzhen",
      "Tanfang",
      "Tangba",
      "Tange",
      "Tangfang",
      "Tanggu",
      "Tanghe",
      "Tangjiazhuang",
      "Tangping",
      "Tangshan",
      "Tangwu",
      "Tangxiang",
      "Tangzhai",
      "Tangzhang",
      "Tanjia",
      "Tanping",
      "Tantou",
      "Taolin",
      "Taoluo",
      "Taonan",
      "Taopingxiang",
      "Taoyuan",
      "Taoyuan",
      "Taozhuang",
      "Tashan",
      "Tawakule",
      "Taxkowruek",
      "Tengyue",
      "Tianchang",
      "Tiancheng",
      "Tianfu",
      "Tianfu",
      "Tianhe",
      "Tianjia",
      "Tianjin",
      "Tianliu",
      "Tianpeng",
      "Tianshui",
      "Tianzhuang",
      "Tiaoshi",
      "Tiefu",
      "Tieli",
      "Tieling",
      "Tieqiao",
      "Tieshan",
      "Tongcheng",
      "Tongchuan",
      "Tongchuan",
      "Tongchuanshi",
      "Tonggu",
      "Tonggu",
      "Tonggu",
      "Tonghua",
      "Tongjiaxi",
      "Tongjing",
      "Tongle",
      "Tongliao",
      "Tongling",
      "Tongqiao",
      "Tongren",
      "Tongshan",
      "Tongxi",
      "Tongzhou",
      "Tuanbao",
      "Tuantian",
      "Tuchang",
      "Tumen",
      "Tumen",
      "Tumxuk",
      "Tuogerigaizi",
      "Tuohula",
      "Tuomuwusitang",
      "Tuqiao",
      "Turgun",
      "Turpan",
      "Turpanyuezi",
      "Tushan",
      "Tushi",
      "Tuwaite",
      "Tuxiang",
      "UEruemqi",
      "Ulanhot",
      "Wacheng Neighborhood",
      "Wafangdian",
      "Waina",
      "Wanchao",
      "Wanchao",
      "Wangba",
      "Wangchang",
      "Wangfen",
      "Wanggou",
      "Wanghe",
      "Wanghong",
      "Wangji",
      "Wangjia",
      "Wangkui",
      "Wangpu",
      "Wangqi",
      "Wangqing",
      "Wangu",
      "Wangwu",
      "Wangyao",
      "Wangyin",
      "Wangyuan",
      "Wanling",
      "Wanmu",
      "Wanning",
      "Wanqiao Zhen",
      "Wansheng",
      "Wanshui",
      "Wanshun",
      "Wanxian",
      "Wanzhou",
      "Wayao",
      "Wayaobu",
      "Weidian",
      "Weifang",
      "Weihai",
      "Weiji",
      "Weilong",
      "Weimiao",
      "Weinan",
      "Weining",
      "Weituo",
      "Weiwu'eryuqiwen",
      "Weixin",
      "Weixinghu",
      "Wenchang",
      "Wendong",
      "Wenfeng",
      "Wenheng",
      "Wenhua",
      "Wenjiang",
      "Wenling",
      "Wenquan",
      "Wenquan",
      "Wenshan City",
      "Wenshang",
      "Wenxian Chengguanzhen",
      "Wenxing",
      "Wenzhou",
      "Wofo",
      "Wu'erqi",
      "Wuchang",
      "Wucheng",
      "Wuchuan",
      "Wuda",
      "Wudalike",
      "Wudong",
      "Wuduan",
      "Wugang",
      "Wuhai",
      "Wuhan",
      "Wuhe",
      "Wuhu",
      "Wujia",
      "Wujia",
      "Wujiahe",
      "Wujian",
      "Wujing",
      "Wuku",
      "Wuling",
      "Wulingyuan",
      "Wulong",
      "Wuma",
      "Wuping",
      "Wuqiao",
      "Wushan",
      "Wushan",
      "Wushan",
      "Wushan",
      "Wusu",
      "Wutan",
      "Wutongshu",
      "Wuwei",
      "Wuxi",
      "Wuxi",
      "Wuxue",
      "Wuyang",
      "Wuying",
      "Wuyishan",
      "Wuzhen",
      "Wuzhishan",
      "Wuzhou",
      "Xambabazar",
      "Xi'an",
      "Xiaba",
      "Xiabao",
      "Xiagezhuang",
      "Xialu",
      "Xiamen",
      "Xianfeng",
      "Xiangcheng",
      "Xiangcheng Chengguanzhen",
      "Xiangjiaba",
      "Xianglong",
      "Xiangshui",
      "Xiangtan",
      "Xiangxiang",
      "Xiangyang",
      "Xiangzhou",
      "Xianju",
      "Xianlong",
      "Xianning",
      "Xiannue",
      "Xiannueshan",
      "Xianshuigu",
      "Xiantao",
      "Xianyang",
      "Xiaochangshan",
      "Xiaochuan",
      "Xiaodu",
      "Xiaogan",
      "Xiaogang",
      "Xiaohe",
      "Xiaojia",
      "Xiaolingwei",
      "Xiaomian",
      "Xiaoshan",
      "Xiaoshi",
      "Xiaoweizhai",
      "Xiasi",
      "Xiasi",
      "Xiaying",
      "Xiazhen",
      "Xiazhuang",
      "Xiazhuang",
      "Xiazhuang",
      "Xichang",
      "Xichang",
      "Xichuan",
      "Xiejiawan",
      "Xiema",
      "Xifeng",
      "Xigang",
      "Xigaoshan",
      "Xihai",
      "Xihe",
      "Xihe",
      "Xihu",
      "Xihu",
      "Xikou",
      "Xilin Hot",
      "Ximeicun",
      "Xincheng",
      "Xincheng",
      "Xincheng",
      "Xindi",
      "Xindian",
      "Xindian",
      "Xingcheng",
      "Xingdaohu",
      "Xingfeng",
      "Xingguo",
      "Xinghua",
      "Xinghua",
      "Xinglong",
      "Xinglong",
      "Xinglong",
      "Xinglong",
      "Xinglong",
      "Xinglongshan",
      "Xinglou",
      "Xingning",
      "Xingren",
      "Xingtai",
      "Xingyi",
      "Xingyi",
      "Xinhe",
      "Xinhe",
      "Xinhua",
      "Xining",
      "Xinji",
      "Xinle",
      "Xinmiao",
      "Xinmin",
      "Xinmin",
      "Xinmin",
      "Xinqing",
      "Xinsheng",
      "Xinsheng",
      "Xinsheng",
      "Xinshi",
      "Xinshi",
      "Xintai",
      "Xintian",
      "Xintian",
      "Xinxiang",
      "Xinxing",
      "Xinxing",
      "Xinyang",
      "Xinyi",
      "Xinyi",
      "Xinyingpan",
      "Xinyu",
      "Xinyuan",
      "Xinzhai",
      "Xinzhai",
      "Xinzhi",
      "Xinzhou",
      "Xinzhou",
      "Xinzhou",
      "Xiongjia",
      "Xiongzhou",
      "Xiping",
      "Xishan",
      "Xituo",
      "Xiugu",
      "Xiulin",
      "Xiuqi",
      "Xiuyan",
      "Xiuying",
      "Xixiang",
      "Xixiang",
      "Xiyu",
      "Xuanhua",
      "Xuantan",
      "Xuanzhou",
      "Xuchang",
      "Xucheng",
      "Xuelin",
      "Xujia",
      "Xujiang",
      "Xumingsi",
      "Xunchang",
      "Xunsi",
      "Xunyang",
      "Xuyong",
      "Xuzhou",
      "Xuzhuang",
      "Ya'an",
      "Yacheng",
      "Yajiang",
      "Yajiang",
      "Yak'airike",
      "Yakeshi",
      "Yan'an",
      "Yan'anxiang",
      "Yancheng",
      "Yandong",
      "Yangba",
      "Yangcheng",
      "Yangchun",
      "Yangcun",
      "Yanggu",
      "Yanghe",
      "Yanghe",
      "Yanghe",
      "Yangjiang",
      "Yangjiao",
      "Yangliujie",
      "Yangliuqing",
      "Yangquan",
      "Yangri",
      "Yangshi",
      "Yangshuo",
      "Yangtun",
      "Yanguan",
      "Yangzhou",
      "Yangzhou",
      "Yanji",
      "Yanjia",
      "Yanjing",
      "Yanliang",
      "Yanta",
      "Yantai",
      "Yantai",
      "Yantongshan",
      "Yanwo",
      "Yanxi",
      "Yanzhou",
      "Yanzibu",
      "Yaoji",
      "Yaowan",
      "Yashan",
      "Yatou",
      "Yawa",
      "Yaxing",
      "Yebaishou",
      "Yebao",
      "Yeliguan",
      "Yengitam",
      "Yeyuan",
      "Yezhou",
      "Yibin",
      "Yichang",
      "Yicheng",
      "Yichun",
      "Yichun",
      "Yidu",
      "Yigai'erqi",
      "Yigou",
      "Yihe",
      "Yihe",
      "Yiju",
      "Yilan",
      "Yili",
      "Yima",
      "Yinchuan",
      "Ying'airike",
      "Ying'awati",
      "Yingchuan",
      "Yingjiang",
      "Yingkou",
      "Yingli",
      "Yingpan",
      "Yingqiu",
      "Yingshang Chengguanzhen",
      "Yingtan",
      "Yingwusitang",
      "Yingye'er",
      "Yinma",
      "Yinping",
      "Yinqiao Zhen",
      "Yintai",
      "Yinxingshu",
      "Yinzhu",
      "Yishan",
      "Yishikuli",
      "Yishui",
      "Yisilamu'awati",
      "Yitang",
      "Yiwu",
      "Yixing",
      "Yiyang",
      "Yizhou",
      "Yizhuang",
      "Yong'an",
      "Yong'an",
      "Yongbei",
      "Yongcheng",
      "Yongchuan",
      "Yongcong",
      "Yongfeng",
      "Yongjia",
      "Yongjian",
      "Yongle",
      "Yongning",
      "Yongning",
      "Yongping",
      "Yongping",
      "Yongqing",
      "Yongrong",
      "Yongxi",
      "Yongxin",
      "Yongxing",
      "Yongxing",
      "Yongzhou",
      "Youchou",
      "Youhao",
      "Yousuo",
      "Youting",
      "Youxi",
      "Yuanping",
      "Yuanshang",
      "Yuanyang",
      "Yuanyang",
      "Yucheng",
      "Yuchi",
      "Yuci",
      "Yudong",
      "Yuelai",
      "Yuelai",
      "Yuexi",
      "Yueyang",
      "Yufengshan",
      "Yujia",
      "Yulin",
      "Yulinshi",
      "Yulong",
      "Yulong",
      "Yuncheng",
      "Yunfu",
      "Yunji",
      "Yunjin",
      "Yunlong",
      "Yunlong",
      "Yunmen",
      "Yunmeng Chengguanzhen",
      "Yunnanyi",
      "Yunshan",
      "Yunshan",
      "Yuntai",
      "Yuntai",
      "Yunyang",
      "Yupan",
      "Yuqunweng",
      "Yushan",
      "Yushu",
      "Yutan",
      "Yuxi",
      "Yuxi",
      "Yuxia",
      "Yuyao",
      "Zangguy",
      "Zaoyang",
      "Zaozhuang",
      "Zengfu",
      "Zepu",
      "Zhabei",
      "Zhakou",
      "Zhalantun",
      "Zhancheng",
      "Zhangfeng",
      "Zhangji",
      "Zhangjiachuan",
      "Zhangjiagang",
      "Zhangjiajie",
      "Zhangjiakou",
      "Zhangye",
      "Zhangzhai",
      "Zhangzhou",
      "Zhangzhuang",
      "Zhangzi",
      "Zhanhe",
      "Zhanjiang",
      "Zhaobaoshan",
      "Zhaodong",
      "Zhaodun",
      "Zhaogezhuang",
      "Zhaojia",
      "Zhaoqing",
      "Zhaotong",
      "Zhaoxing",
      "Zhaoyuan",
      "Zhaoyuan",
      "Zhaozhou",
      "Zhaozhuang",
      "Zhawa",
      "Zhefang",
      "Zhelou",
      "Zhen'an",
      "Zhendong",
      "Zhengding",
      "Zhengji",
      "Zhengxing",
      "Zhengyang",
      "Zhengzhou",
      "Zhenjiang",
      "Zhenlai",
      "Zhenping Chengguanzhen",
      "Zhenxi",
      "Zhenzhou",
      "Zheshan",
      "Zhicheng",
      "Zhicheng",
      "Zhifang",
      "Zhifeng",
      "Zhigou",
      "Zhijiang",
      "Zhiping",
      "Zhisheng",
      "Zhong'ao",
      "Zhongba",
      "Zhongchao",
      "Zhongduo",
      "Zhongfeng",
      "Zhonggang",
      "Zhonggulou",
      "Zhonghe",
      "Zhonghe",
      "Zhonghe",
      "Zhonghe",
      "Zhongling",
      "Zhongmiao",
      "Zhongshan",
      "Zhongshan",
      "Zhongshan",
      "Zhongshu",
      "Zhongtang",
      "Zhongxiang",
      "Zhongxin",
      "Zhongxing",
      "Zhongzhai",
      "Zhoubai",
      "Zhoucheng",
      "Zhoucun",
      "Zhoujia",
      "Zhoujiaba",
      "Zhoukou",
      "Zhouqu Chengguanzhen",
      "Zhoushan",
      "Zhouxi",
      "Zhouzhuang",
      "Zhu Cheng City",
      "Zhuanghe",
      "Zhuangyuan",
      "Zhuantang",
      "Zhucheng",
      "Zhufo",
      "Zhuhai",
      "Zhuhai",
      "Zhuji",
      "Zhujiajiao",
      "Zhumadian",
      "Zhuoshui",
      "Zhushan",
      "Zhutang",
      "Zhutuo",
      "Zhuxi",
      "Zhuxi",
      "Zhuyang",
      "Zhuyi",
      "Zhuyuan",
      "Zhuzhai",
      "Zhuzhou",
      "Zibihu",
      "Zibo",
      "Zigong",
      "Zirefuxiati",
      "Zishui",
      "Zitong",
      "Ziyang",
      "Ziyang Chengguanzhen",
      "Zizhuang",
      "Zoucheng",
      "Zouma",
      "Zouma",
      "Zouzhuang",
      "Zuitai",
      "Zunyi",
    ],
  },
  Colombia: {
    code: "CO",
    cities: [
      "Abejorral",
      "Abrego",
      "Acacias",
      "Agua de Dios",
      "Aguachica",
      "Aguadas",
      "Aguazul",
      "Agustin Codazzi",
      "Albania",
      "Algarrobo",
      "Algeciras",
      "Amaga",
      "Anaime",
      "Andalucia",
      "Andes",
      "Anolaima",
      "Anserma",
      "Ansermanuevo",
      "Apartado",
      "Aracataca",
      "Arauca",
      "Ariguani",
      "Arjona",
      "Armenia",
      "Armero-Guyabal",
      "Astrea",
      "Ataco",
      "Ayapel",
      "Balboa",
      "Baranoa",
      "Barbosa",
      "Barbosa",
      "Barrancabermeja",
      "Barrancas",
      "Barranquilla",
      "Becerril",
      "Belalcazar",
      "Belen de Umbria",
      "Bello",
      "Betulia",
      "Bogota",
      "Bosconia",
      "Bosconia",
      "Bucaramanga",
      "Buenaventura",
      "Buenaventura",
      "Buesaco",
      "Bugalagrande",
      "Caicedonia",
      "Cajica",
      "Calarca",
      "Caldas",
      "Cali",
      "Campo de la Cruz",
      "Campoalegre",
      "Canalete",
      "Canasgordas",
      "Candelaria",
      "Candelaria",
      "Caparrapi",
      "Carepa",
      "Carmen de Viboral",
      "Cartagena",
      "Cartago",
      "Caucasia",
      "Cerete",
      "Chaparral",
      "Chia",
      "Chicoral",
      "Chigorodo",
      "Chima",
      "Chimichagua",
      "Chinchina",
      "Chinu",
      "Chiquinquira",
      "Chiriguana",
      "Chivolo",
      "Cienaga",
      "Cienaga de Oro",
      "Cimitarra",
      "Circasia",
      "Ciudad Bolivar",
      "Clemencia",
      "Cocorna",
      "Concordia",
      "Copacabana",
      "Corinto",
      "Corozal",
      "Cotorra",
      "Cucuta",
      "Cumaral",
      "Cumaribo",
      "Curiti",
      "Curumani",
      "Dabeiba",
      "Dagua",
      "Darien",
      "Distraccion",
      "Donmatias",
      "Dosquebradas",
      "Duitama",
      "Ebejico",
      "El Bagre",
      "El Banco",
      "El Bordo",
      "El Carmen",
      "El Carmen de Bolivar",
      "El Carmen de Chucuri",
      "El Cerrito",
      "El Charco",
      "El Copey",
      "El Doncello",
      "El Playon",
      "El Reten",
      "El Retorno",
      "El Tambo",
      "El Zulia",
      "Envigado",
      "Espinal",
      "Facatativa",
      "Flandes",
      "Florencia",
      "Florida",
      "Floridablanca",
      "Fonseca",
      "Fredonia",
      "Fresno",
      "Frontino",
      "Fundacion",
      "Funza",
      "Fusagasuga",
      "Gaitania",
      "Galapa",
      "Gamarra",
      "Garagoa",
      "Garzon",
      "Gigante",
      "Girardot City",
      "Giron",
      "Granada",
      "Guacari",
      "Guadalajara de Buga",
      "Guadalupe",
      "Guaduas",
      "Guamal",
      "Guamal",
      "Guamo",
      "Guapi",
      "Guarne",
      "Honda",
      "Ibague",
      "Icononzo",
      "Ipiales",
      "Isnos",
      "Istmina",
      "Itaguei",
      "Ituango",
      "Jamundi",
      "Juan de Acosta",
      "Junin",
      "La Calera",
      "La Ceja",
      "La Dorada",
      "La Estrella",
      "La Gloria",
      "La Jagua de Ibirico",
      "La Mesa",
      "La Paz",
      "La Plata",
      "La Tebaida",
      "La Union",
      "La Union",
      "La Union",
      "La Victoria",
      "La Virginia",
      "Lejanias",
      "Lerida",
      "Leticia",
      "Libano",
      "Lorica",
      "Los Palmitos",
      "Los Patios",
      "Luruaco",
      "Madrid",
      "Magangue",
      "Mahates",
      "Maicao",
      "Majagual",
      "Malaga",
      "Malambo",
      "Manati",
      "Mani",
      "Manizales",
      "Manzanares",
      "Maria la Baja",
      "Marinilla",
      "Marquetalia",
      "Marsella",
      "Medellin",
      "Melgar",
      "Mercaderes",
      "Miranda",
      "Mitu",
      "Mocoa",
      "Mogotes",
      "Momil",
      "Mompos",
      "Moniquira",
      "Montecristo",
      "Montelibano",
      "Montenegro",
      "Monteria",
      "Monterrey",
      "Morales",
      "Morales",
      "Mosquera",
      "Mosquera",
      "Municipio Hato Corozal",
      "Mutata",
      "Natagaima",
      "Nechi",
      "Necocli",
      "Neira",
      "Neiva",
      "Nilo",
      "Nueva Granada",
      "Nueva Granada",
      "Obando",
      "Ocana",
      "Oporapa",
      "Orito",
      "Ovejas",
      "Pacho",
      "Pacora",
      "Padua",
      "Pailitas",
      "Paipa",
      "Palestina",
      "Palestina",
      "Palmar de Varela",
      "Palmira",
      "Pamplona",
      "Pasto",
      "Patia",
      "Payande",
      "Pelaya",
      "Pereira",
      "Piedecuesta",
      "Piendamo",
      "Pijino del Carmen",
      "Pinillos",
      "Pital",
      "Pitalito",
      "Pivijay",
      "Pizarro",
      "Planadas",
      "Planeta Rica",
      "Plato",
      "Polonuevo",
      "Ponedera",
      "Popayan",
      "Potosi",
      "Pradera",
      "Pueblo Rico",
      "Puebloviejo",
      "Puente Nacional",
      "Puerto Asis",
      "Puerto Berrio",
      "Puerto Boyaca",
      "Puerto Carreno",
      "Puerto Colombia",
      "Puerto Leguizamo",
      "Puerto Lopez",
      "Puerto Rico",
      "Puerto Salgar",
      "Puerto Santander",
      "Puerto Tejada",
      "Puerto Triunfo",
      "Puerto Wilches",
      "Pupiales",
      "Purificacion",
      "Purisima de la Concepcion",
      "Quibdo",
      "Quimbaya",
      "Quinchia",
      "Repelon",
      "Restrepo",
      "Retiro",
      "Ricaurte",
      "Rio de Oro",
      "Rioblanco",
      "Riohacha",
      "Rionegro",
      "Riosucio",
      "Rivera",
      "Roldanillo",
      "Rovira",
      "Sabana de Torres",
      "Sabanagrande",
      "Sabanalarga",
      "Sabaneta",
      "Sahagun",
      "Saladoblanco",
      "Salamina",
      "Salgar",
      "Samaniego",
      "Sampues",
      "San Alberto",
      "San Andres",
      "San Antero",
      "San Benito Abad",
      "San Carlos",
      "San Carlos",
      "San Carlos de Guaroa",
      "San Diego",
      "San Estanislao",
      "San Gil",
      "San Jacinto",
      "San Jeronimo",
      "San Jose del Guaviare",
      "San Juan Nepomuceno",
      "San Juan de Uraba",
      "San Juan del Cesar",
      "San Lorenzo",
      "San Luis de Since",
      "San Marcos",
      "San Martin",
      "San Martin",
      "San Onofre",
      "San Pablo",
      "San Pedro",
      "San Pedro de Uraba",
      "San Rafael",
      "San Sebastian de Mariquita",
      "San Vicente",
      "San Vicente de Chucuri",
      "Sandona",
      "Santa Ana",
      "Santa Barbara",
      "Santa Catalina",
      "Santa Fe de Antioquia",
      "Santa Lucia",
      "Santa Marta",
      "Santa Rosa",
      "Santa Rosa de Cabal",
      "Santa Rosa de Osos",
      "Santa Rosa de Viterbo",
      "Santander de Quilichao",
      "Santiago de Tolu",
      "Santo Domingo",
      "Santo Tomas",
      "Santuario",
      "Santuario",
      "Segovia",
      "Sevilla",
      "Sibate",
      "Silvania",
      "Simiti",
      "Sincelejo",
      "Sitionuevo",
      "Soacha",
      "Soata",
      "Socorro",
      "Sogamoso",
      "Solano",
      "Soledad",
      "Sonson",
      "Suan",
      "Suarez",
      "Sucre",
      "Supia",
      "Tado",
      "Tame",
      "Tarqui",
      "Tauramena",
      "Tello",
      "Tibu",
      "Tierralta",
      "Timbiqui",
      "Tiquisio",
      "Tocaima",
      "Tolu Viejo",
      "Toro",
      "Trinidad",
      "Tulua",
      "Tumaco",
      "Tunja",
      "Tuquerres",
      "Turbaco",
      "Turbana",
      "Turbo",
      "Unguia",
      "Urrao",
      "Valdivia",
      "Valencia",
      "Valledupar",
      "Velez",
      "Venadillo",
      "Venecia",
      "Villa Rica",
      "Villa de San Diego de Ubate",
      "Villa del Rosario",
      "Villamaria",
      "Villanueva",
      "Villanueva",
      "Villanueva",
      "Villavicencio",
      "Villeta",
      "Viota",
      "Viterbo",
      "Yacopi",
      "Yacuanquer",
      "Yarumal",
      "Yondo",
      "Yopal",
      "Yumbo",
      "Zaragoza",
      "Zarzal",
      "Zipaquira",
    ],
  },
  Comoros: {
    code: "KM",
    cities: [
      "Adda-Doueni",
      "Domoni",
      "Fomboni",
      "Mirontsi",
      "Moroni",
      "Moutsamoudou",
      "Ouani",
      "Tsimbeo",
    ],
  },
  Congo: {
    code: "CG",
    cities: [
      "Betou",
      "Bouansa",
      "Boundji",
      "Brazzaville",
      "Djambala",
      "Dolisie",
      "Dongou",
      "Etoumbi",
      "Ewo",
      "Gamboma",
      "Impfondo",
      "Kayes",
      "Kinkala",
      "Loandjili",
      "Loudima",
      "Loutete",
      "Makabana",
      "Makoua",
      "Mindouli",
      "Mossaka",
      "Mossendjo",
      "Mouyondzi",
      "Ngo",
      "Nkayi",
      "Ouesso",
      "Owando",
      "Oyo",
      "Pointe-Noire",
      "Pokola",
      "Sibiti",
    ],
  },
  "Congo, Democratic Republic of the": {
    code: "CD",
    cities: [
      "Aketi",
      "Aru",
      "Babamba",
      "Bakwa",
      "Bandundu Province",
      "Basankusu",
      "Basoko",
      "Baudhuinville",
      "Beni",
      "Binga",
      "Boende",
      "Bolobo",
      "Boma",
      "Bondo",
      "Bosobolo",
      "Bukama",
      "Bukavu",
      "Bulungu",
      "Bumba",
      "Bunia",
      "Businga",
      "Buta",
      "Butembo",
      "Demba",
      "Dibaya-Lubwe",
      "Djugu",
      "Dungu",
      "Fungurume",
      "Gandajika",
      "Gbadolite",
      "Gemena",
      "Goma",
      "Hyosha",
      "Idiofa",
      "Ilebo",
      "Inkisi",
      "Inongo",
      "Isiro",
      "Kabalo",
      "Kabare",
      "Kabeya-Kamwanga",
      "Kabinda",
      "Kalemie",
      "Kalima",
      "Kambove",
      "Kamina",
      "Kampene",
      "Kananga",
      "Kanyama",
      "Kanyobagonga",
      "Kasangulu",
      "Kasongo",
      "Kasongo-Lunda",
      "Katanda",
      "Katwa",
      "Kayna",
      "Kenge",
      "Kikwit",
      "Kimpese",
      "Kindu",
      "Kinshasa",
      "Kipamba",
      "Kipushi",
      "Kirumba",
      "Kisangani",
      "Kituku",
      "Kolwezi",
      "Kongolo",
      "Kutu",
      "Libenge",
      "Likasi",
      "Lisala",
      "Lodja",
      "Lubao",
      "Lubero",
      "Lubumbashi",
      "Luebo",
      "Lukolela",
      "Lukula",
      "Luputa",
      "Lusambo",
      "Malemba",
      "Mangai",
      "Mangbwalu",
      "Mangina",
      "Manono",
      "Masi-Manimba",
      "Masina",
      "Matadi",
      "Mbandaka",
      "Mbanza-Ngungu",
      "Mbuji-Mayi",
      "Miabi",
      "Moanda",
      "Mokambo",
      "Mulongo",
      "Mushie",
      "Mweka",
      "Mwene",
      "Mwene-Ditu",
      "Nioki",
      "Nyunzu",
      "Pweto",
      "Rutshuru",
      "Sake",
      "Tshela",
      "Tshikapa",
      "Tshilenge",
      "Uvira",
      "Wamba",
      "Watsa",
      "Yangambi",
      "Zongo",
    ],
  },
  "Cook Islands": {
    code: "CK",
    cities: ["Avarua"],
  },
  "Costa Rica": {
    code: "CR",
    cities: [
      "Alajuela",
      "Alajuelita",
      "Aserri",
      "Buenos Aires",
      "Calle Blancos",
      "Canas",
      "Cartago",
      "Chacarita",
      "Colima",
      "Concepcion",
      "Curridabat",
      "Daniel Flores",
      "Desamparados",
      "Escazu",
      "Esparza",
      "Granadilla",
      "Guadalupe",
      "Guapiles",
      "Heredia",
      "Ipis",
      "Liberia",
      "Limon",
      "Mercedes Norte",
      "Naranjo",
      "Nicoya",
      "Paraiso",
      "Patarra",
      "Puntarenas",
      "Purral",
      "Quesada",
      "Sabanilla",
      "San Antonio",
      "San Diego",
      "San Felipe",
      "San Francisco",
      "San Isidro",
      "San Jose",
      "San Jose",
      "San Josecito",
      "San Juan",
      "San Juan de Dios",
      "San Miguel",
      "San Pablo",
      "San Pedro",
      "San Rafael",
      "San Rafael Abajo",
      "San Rafael Arriba",
      "San Ramon",
      "San Vicente",
      "San Vicente de Moravia",
      "Santa Cruz",
      "Siquirres",
      "Tejar",
      "Tres Rios",
      "Turrialba",
    ],
  },
  Croatia: {
    code: "HR",
    cities: [
      "Bjelovar",
      "Cakovec",
      "Centar",
      "Dubrovnik",
      "Dugo Selo",
      "GJakovo",
      "Karlovac",
      "Kastav",
      "Koprivnica",
      "Krizevci",
      "Kutina",
      "Makarska",
      "Metkovic",
      "Osijek",
      "Petrinja",
      "Pozega",
      "Pula",
      "Rijeka",
      "Rovinj",
      "Samobor",
      "Sesvete",
      "Sibenik",
      "Sinj",
      "Sisak",
      "Slavonski Brod",
      "Solin",
      "Split",
      "Trogir",
      "Varazdin",
      "Velika Gorica",
      "Vinkovci",
      "Virovitica",
      "Vukovar",
      "Zadar",
      "Zagreb",
      "Zapresic",
    ],
  },
  Cuba: {
    code: "CU",
    cities: [
      "Abreus",
      "Aguada de Pasajeros",
      "Alamar",
      "Alquizar",
      "Amancio",
      "Arroyo Naranjo",
      "Artemisa",
      "Bahia Honda",
      "Banes",
      "Baracoa",
      "Baragua",
      "Bartolome Maso",
      "Batabano",
      "Bauta",
      "Bayamo",
      "Bejucal",
      "Boyeros",
      "Cabaiguan",
      "Cacocum",
      "Caibarien",
      "Calabazar de Sagua",
      "Calimete",
      "Camagueey",
      "Camajuani",
      "Campechuela",
      "Candelaria",
      "Cardenas",
      "Cauto Cristo",
      "Centro Habana",
      "Cerro",
      "Chambas",
      "Ciego de Avila",
      "Cienfuegos",
      "Cifuentes",
      "Ciro Redondo",
      "Ciudad Camilo Cienfuegos",
      "Colombia",
      "Colon",
      "Condado",
      "Consolacion del Sur",
      "Contramaestre",
      "Corralillo",
      "Cruces",
      "Cueto",
      "Cumanayagua",
      "Diez de Octubre",
      "Encrucijada",
      "Esmeralda",
      "Esperanza",
      "Florencia",
      "Florida",
      "Fomento",
      "Gibara",
      "Guaimaro",
      "Guanabacoa",
      "Guanajay",
      "Guane",
      "Guantanamo",
      "Guayos",
      "Gueines",
      "Gueira de Melena",
      "Guisa",
      "Havana",
      "Holguin",
      "Jagueey Grande",
      "Jamaica",
      "Jaruco",
      "Jatibonico",
      "Jesus Menendez",
      "Jiguani",
      "Jimaguayu",
      "Jobabo",
      "Jobabo",
      "Jovellanos",
      "La Habana Vieja",
      "La Salud",
      "La Sierpe",
      "Lajas",
      "Las Tunas",
      "Limonar",
      "Los Arabos",
      "Los Palacios",
      "Madruga",
      "Maisi",
      "Manati",
      "Manicaragua",
      "Mantua",
      "Manzanillo",
      "Mariel",
      "Marti",
      "Matanzas",
      "Media Luna",
      "Minas",
      "Minas de Matahambre",
      "Moa",
      "Moron",
      "Niquero",
      "Nueva Gerona",
      "Nuevitas",
      "Palma Soriano",
      "Palmira",
      "Pedro Betancourt",
      "Perico",
      "Pinar del Rio",
      "Placetas",
      "Primero de Enero",
      "Puerto Padre",
      "Quemado de Gueines",
      "Quivican",
      "Ranchuelo",
      "Regla",
      "Remedios",
      "Rio Cauto",
      "Rio Guayabal de Yateras",
      "Rodas",
      "Sagua de Tanamo",
      "Sagua la Grande",
      "San Antonio de los Banos",
      "San Cristobal",
      "San German",
      "San Jose de las Lajas",
      "San Luis",
      "San Miguel del Padron",
      "San Nicolas de Bari",
      "Sancti Spiritus",
      "Santa Clara",
      "Santa Cruz del Norte",
      "Santa Cruz del Sur",
      "Santiago de Cuba",
      "Santiago de las Vegas",
      "Santo Domingo",
      "Sibanicu",
      "Trinidad",
      "Union de Reyes",
      "Varadero",
      "Venezuela",
      "Vertientes",
      "Vinales",
      "Yaguajay",
      "Yara",
    ],
  },
  Cyprus: {
    code: "CY",
    cities: [
      "Agios Dometios",
      "Aglantzia",
      "Aradippou",
      "Dali",
      "Egkomi",
      "Famagusta",
      "Germasogeia",
      "Kato Lakatameia",
      "Kato Polemidia",
      "Kyrenia",
      "Larnaca",
      "Limassol",
      "Morfou",
      "Nicosia",
      "Paphos",
      "Paralimni",
      "Pergamos",
      "Protaras",
      "Strovolos",
    ],
  },
  "Czech Republic": {
    code: "CZ",
    cities: [
      "As",
      "Benesov",
      "Beroun",
      "Bilina",
      "Blansko",
      "Bohumin",
      "Boskovice",
      "Brandys nad Labem-Stara Boleslav",
      "Branik",
      "Breclav",
      "Brno",
      "Bruntal",
      "Caslav",
      "Celakovice",
      "Cerny Most",
      "Ceska Lipa",
      "Ceska Trebova",
      "Ceske Budejovice",
      "Cesky Krumlov",
      "Cesky Tesin",
      "Cheb",
      "Chodov",
      "Chomutov",
      "Chrudim",
      "Decin",
      "Domazlice",
      "Dvur Kralove nad Labem",
      "Frenstat pod Radhostem",
      "Frydek-Mistek",
      "Havirov",
      "Havlickuv Brod",
      "Hlinsko",
      "Hlucin",
      "Hodonin",
      "Holesov",
      "Horni Pocernice",
      "Hradec Kralove",
      "Hranice",
      "Humpolec",
      "Jablonec nad Nisou",
      "Jaromer",
      "Jesenik",
      "Jicin",
      "Jihlava",
      "Jindrichuv Hradec",
      "Jirkov",
      "Kadan",
      "Karlin",
      "Karlovy Vary",
      "Karvina",
      "Kladno",
      "Klasterec nad Ohri",
      "Klatovy",
      "Kolin",
      "Koprivnice",
      "Kralupy nad Vltavou",
      "Krnov",
      "Kromeriz",
      "Krupka",
      "Kutna Hora",
      "Kyjov",
      "Letnany",
      "Liben",
      "Liberec",
      "Litomerice",
      "Litomysl",
      "Litovel",
      "Litvinov",
      "Louny",
      "Marianske Lazne",
      "Melnik",
      "Mlada Boleslav",
      "Modrany",
      "Moravska Trebova",
      "Most",
      "Nachod",
      "Neratovice",
      "Nove Mesto na Morave",
      "Nove Mesto nad Metuji",
      "Novy Bor",
      "Novy Jicin",
      "Nymburk",
      "Olomouc",
      "Opava",
      "Orlova",
      "Ostrava",
      "Ostrov",
      "Otrokovice",
      "Pardubice",
      "Pelhrimov",
      "Pilsen",
      "Pisek",
      "Podebrady",
      "Prachatice",
      "Prague",
      "Prerov",
      "Pribram",
      "Prosek",
      "Prostejov",
      "Rakovnik",
      "Ricany",
      "Rokycany",
      "Roudnice nad Labem",
      "Roznov pod Radhostem",
      "Rumburk",
      "Rychnov nad Kneznou",
      "Slany",
      "Sokolov",
      "Stary Bohumin",
      "Sternberk",
      "Strakonice",
      "Strizkov",
      "Studenka",
      "Sumperk",
      "Susice",
      "Svitavy",
      "Tabor",
      "Tachov",
      "Teplice",
      "Trebic",
      "Trinec",
      "Trutnov",
      "Turnov",
      "Uherske Hradiste",
      "Uhersky Brod",
      "Unicov",
      "Usti nad Labem",
      "Usti nad Orlici",
      "Valasske Mezirici",
      "Varnsdorf",
      "Velke Mezirici",
      "Veseli nad Moravou",
      "Vlasim",
      "Vrchlabi",
      "Vsetin",
      "Vyskov",
      "Vysocany",
      "Vysoke Myto",
      "Zabreh",
      "Zatec",
      "Zgar nad Sazavou",
      "Zizkov",
      "Zlin",
      "Znojmo",
    ],
  },
  "Côte d'Ivoire": {
    code: "CI",
    cities: [
      "Abengourou",
      "Abidjan",
      "Abobo",
      "Aboisso",
      "Aboisso Comoe",
      "Aboka",
      "Abongoua",
      "Abongoua",
      "Aboude",
      "Adahidoukou",
      "Adiake",
      "Adjamene",
      "Adzope",
      "Affery",
      "Agbaou",
      "Agboville",
      "Agnibilekrou",
      "Ahigbe Koffikro",
      "Ahouanou",
      "Akoupe",
      "Alepe",
      "Amelekia",
      "Ananguie",
      "Ancien-Prozi",
      "Ande",
      "Aniassue",
      "Anyama",
      "Appouessou",
      "Arrah",
      "Assie koumassi",
      "Assuefri",
      "Atrobou",
      "Ayame",
      "Ayaou-Sokpa",
      "Azaguie",
      "Bacanda",
      "Bacon",
      "Bagohouo",
      "Bahe-Blaon",
      "Baibli",
      "Balakouya",
      "Bamo",
      "Bangolo",
      "Bangolo Tahouake",
      "Baoutifla",
      "Bayota",
      "Bazre",
      "Becedi-Brignan",
      "Becouefin",
      "Bediala",
      "Bedigoazon",
      "Befla",
      "Belleville",
      "Belleville",
      "Beoumi",
      "Bettie",
      "Biankouma",
      "Bianouan",
      "Bieby",
      "Bingerville",
      "Blahou",
      "Blenimeouin",
      "Blolequin",
      "Boboniessoko",
      "Bobosso-Tienigbe",
      "Bocanda",
      "Bogbam",
      "Bognonzra",
      "Bonaouin",
      "Bondoukou",
      "Bongouanou",
      "Bonon",
      "Bonoua",
      "Bonoufla",
      "Borotou-Koro",
      "Botro",
      "Bouafle",
      "Bouake",
      "Bouandougou",
      "Bouna",
      "Boundiali",
      "Brobo",
      "Brou akpaousso",
      "Broudoukou-Kpenda",
      "Butubre I",
      "Buyo",
      "Complexe",
      "Dabakala",
      "Dabou",
      "Dafrahinou Darinsala",
      "Dagadji",
      "Dagbao I",
      "Dahiri",
      "Dakpadou",
      "Daloa",
      "Dame",
      "Danane",
      "Danguira",
      "Daoukro",
      "Dapeoua",
      "Datta",
      "Diabo",
      "Diangobo",
      "Dianra",
      "Dianra-Village",
      "Diarabana",
      "Diawala",
      "Dibobli",
      "Didievi",
      "Diedrou",
      "Diegonefla",
      "Diehiba",
      "Dignago",
      "Dikodougou",
      "Dimbokro",
      "Diourouzon",
      "Divo",
      "Djapadji",
      "Dokpodon",
      "Doropo",
      "Douague",
      "Doukouya",
      "Duekoue",
      "Ebilassokro",
      "Eremankono",
      "Fengolo",
      "Ferkessedougou",
      "Flakiedougou",
      "Fronan",
      "Gabiadji",
      "Gadouan",
      "Gagnoa",
      "Gagny",
      "Galebre",
      "Gaoulou",
      "Gbabam",
      "Gbabo",
      "Gbapleu",
      "Gbetogo",
      "Gbletia",
      "Gbliglo",
      "Gbolouville",
      "Gbon",
      "Gligbeuadji",
      "Gnago",
      "Gnatroa",
      "Gnepasso",
      "Gnititoigui",
      "Gnouboyo",
      "Goh",
      "Gohitafla",
      "Gohouo",
      "Gomeneberi",
      "Gomon",
      "Gonate",
      "Goudi",
      "Gourane",
      "Goya",
      "Goya",
      "Grabo",
      "Grand Berebi",
      "Grand-Bassam",
      "Grand-Lahou",
      "Grand-Zattry",
      "Grebouo",
      "Guepaouo",
      "Guessabo",
      "Gueyo",
      "Guezon",
      "Guezon",
      "Guezon-Tahouake",
      "Guiberoua",
      "Guiglo",
      "Guitry",
      "Hannie",
      "Hire",
      "Ipouagui",
      "Issia",
      "Jacqueville",
      "Johin",
      "Kacoukro",
      "Kagbe",
      "Kahin",
      "Kamala",
      "Kanakono",
      "Kani",
      "Kaouara",
      "Kassere",
      "Katiola",
      "Ketesso",
      "Ketro",
      "Kokoumbo",
      "Kolia",
      "Konedougou",
      "Konefla",
      "Kong",
      "Korhogo",
      "Korokaha",
      "Kouamefra",
      "Kouassi-Kouassikro",
      "Kouetinfla",
      "Kouibly",
      "Kouta",
      "Kouto",
      "Kranzadougou",
      "Lakota",
      "Lessiri",
      "Lobakuya",
      "Lobogba",
      "Loboville",
      "Logouale",
      "Luehouan",
      "Luenoufla",
      "M'Bahiakro",
      "M'Batto",
      "M'Bengue",
      "Mabehiri",
      "Madoge",
      "Mafere",
      "Mahapleu",
      "Man",
      "Manfla",
      "Mankono",
      "Manzanoua",
      "Marahui",
      "Marcory",
      "Meagui",
      "Mignoure",
      "Momiraso",
      "Monoko Zohi Deux",
      "Moussadougou",
      "Nambingue",
      "Ndouci",
      "Nekede",
      "Nguinou",
      "Niable",
      "Niakaramandougou",
      "Niakoblonyoa",
      "Niapidou",
      "Nielle",
      "Nizahon II",
      "Noe",
      "Nzianouan",
      "Odienne",
      "Offumpo",
      "Ogoudou",
      "Okromodou",
      "Okrouyo",
      "Ottawa",
      "Ouamelhoro",
      "Ouangolodougou",
      "Ouattaradougou",
      "Oume",
      "Oupoyo",
      "Ouragahio",
      "Para",
      "Pelezi",
      "Petit Grabo",
      "Petitgoa I",
      "Pinhou",
      "Pogo",
      "Rubino",
      "Sahoua",
      "Saioua",
      "Sakassou",
      "San-Pedro",
      "Sangouine",
      "Sankadiokro",
      "Saph",
      "Sarakagui",
      "Sassandra",
      "Scierie",
      "Sechi",
      "Seguela",
      "Seitifla",
      "Semien",
      "Sietinfla",
      "Sinematiali",
      "Sinfra",
      "Sirasso",
      "Songan",
      "Songon",
      "Soubre",
      "Tabou",
      "Tacourably",
      "Tafire",
      "Tanda",
      "Tchetaly",
      "Teiri",
      "Tengrela",
      "Tiassale",
      "Tiebissou",
      "Tiegba",
      "Tiemelekro",
      "Tieupleu",
      "Tobly Bangolo",
      "Tomono",
      "Tortiya",
      "Touagui",
      "Touba",
      "Touih",
      "Toulepleu Guere",
      "Toumodi",
      "Toumoukro",
      "Trahe",
      "Vaou",
      "Vavoua",
      "Vrouo",
      "Walebo",
      "Watte",
      "Yabayo",
      "Yakasse-Attobrou",
      "Yakasseme",
      "Yamoussoukro",
      "Yaoude",
      "Zagne",
      "Zagoreta",
      "Zaguieta",
      "Zaibo",
      "Zaliohouan",
      "Zambakro",
      "Zaranou",
      "Zata",
      "Zeaglo",
      "Zega",
      "Zegban",
      "Zeregbo",
      "Zoroyo",
      "Zou",
      "Zouan",
      "Zouan-Hounien",
      "Zoukougbeu",
      "Zuenoula",
    ],
  },
  Denmark: {
    code: "DK",
    cities: [
      "Aabenraa",
      "Aalborg",
      "Albertslund",
      "Allerod",
      "Arhus",
      "Ballerup",
      "Birkerod",
      "Brondbyvester",
      "Bronderslev",
      "Charlottenlund",
      "Christianshavn",
      "Copenhagen",
      "Dragor",
      "Esbjerg",
      "Espergaerde",
      "Farum",
      "Flong",
      "Fredericia",
      "Frederiksberg",
      "Frederikshavn",
      "Frederikssund",
      "Frederiksvaerk",
      "Gjellerup",
      "Glostrup",
      "Grenaa",
      "Greve",
      "Haderslev",
      "Haslev",
      "Hedehusene",
      "Hedensted",
      "Helsingor",
      "Herning",
      "Hillerod",
      "Hjorring",
      "Hobro",
      "Holbaek",
      "Holstebro",
      "Horsens",
      "Horsholm",
      "Hvidovre",
      "Ikast",
      "Ishoj",
      "Jyllinge",
      "Kalundborg",
      "Koge",
      "Kolding",
      "Kongens Lyngby",
      "Korsor",
      "Lillerod",
      "Losning",
      "Lystrup",
      "Lystrup",
      "Middelfart",
      "Naestved",
      "Nakskov",
      "Norresundby",
      "Nyborg",
      "Nykobing Falster",
      "Odder",
      "Odense",
      "Randers",
      "Ringsted",
      "Rodovre",
      "Ronne",
      "Roskilde",
      "Silkeborg",
      "Skanderborg",
      "Skive",
      "Slagelse",
      "Solrod Strand",
      "Sonderborg",
      "Stenlose",
      "Struer",
      "Svendborg",
      "Taastrup",
      "Thisted",
      "Tonder",
      "Tranekaer",
      "Vaerlose",
      "Valby",
      "Vallensbaek",
      "Vanlose",
      "Varde",
      "Vejle",
      "Viborg",
      "Vordingborg",
    ],
  },
  Djibouti: {
    code: "DJ",
    cities: ["Ali Sabih", "Arta", "Dikhil", "Djibouti", "Obock", "Tadjoura"],
  },
  Dominica: {
    code: "DM",
    cities: ["Roseau"],
  },
  "Dominican Republic": {
    code: "DO",
    cities: [
      "Azua",
      "Bajos de Haina",
      "Bani",
      "Bayaguana",
      "Bella Vista",
      "Boca Chica",
      "Bonao",
      "Cabral",
      "Cambita Garabitos",
      "Ciudad Nueva",
      "Comendador",
      "Concepcion de La Vega",
      "Constanza",
      "Cotui",
      "Cristo Rey",
      "Dajabon",
      "Duverge",
      "Esperanza",
      "Fantino",
      "Hato Mayor del Rey",
      "Jarabacoa",
      "La Agustina",
      "La Julia",
      "La Romana",
      "Las Matas de Farfan",
      "Las Matas de Santa Cruz",
      "Mao",
      "Moca",
      "Monte Llano",
      "Monte Plata",
      "Nagua",
      "Neiba",
      "Pedernales",
      "Puerto Plata",
      "Punta Cana",
      "Quisqueya",
      "Rio Grande",
      "Sabana Grande de Boya",
      "Sabana Yegua",
      "Sabana de la Mar",
      "Sabaneta",
      "Salcedo",
      "Salsipuedes",
      "Salvaleon de Higueey",
      "Samana",
      "San Carlos",
      "San Cristobal",
      "San Fernando de Monte Cristi",
      "San Francisco de Macoris",
      "San Gregorio de Nigua",
      "San Jose de Ocoa",
      "San Juan de la Maguana",
      "San Pedro de Macoris",
      "Sanchez",
      "Santa Cruz de Barahona",
      "Santa Cruz de El Seibo",
      "Santiago de los Caballeros",
      "Santo Domingo",
      "Santo Domingo Este",
      "Santo Domingo Oeste",
      "Tamboril",
      "Vicente Noble",
      "Villa Altagracia",
      "Villa Bisono",
      "Villa Consuelo",
      "Villa Francisca",
      "Villa Gonzalez",
      "Villa Vasquez",
      "Yamasa",
    ],
  },
  Ecuador: {
    code: "EC",
    cities: [
      "Alausi",
      "Alfredo Baquerizo Moreno",
      "Ambato",
      "Atuntaqui",
      "Azogues",
      "Babahoyo",
      "Bahia de Caraquez",
      "Balao",
      "Balzar",
      "Boca Suno",
      "Calceta",
      "Cariamanga",
      "Catacocha",
      "Catamayo",
      "Cayambe",
      "Chone",
      "Cuenca",
      "Daule",
      "El Triunfo",
      "Eloy Alfaro",
      "Esmeraldas",
      "Gualaceo",
      "Guano",
      "Guaranda",
      "Guayaquil",
      "Huaquillas",
      "Ibarra",
      "Jipijapa",
      "La Libertad",
      "La Mana",
      "La Troncal",
      "Latacunga",
      "Loja",
      "Macara",
      "Macas",
      "Machachi",
      "Machala",
      "Manta",
      "Milagro",
      "Montalvo",
      "Montecristi",
      "Muisne",
      "Naranjal",
      "Naranjito",
      "Nueva Loja",
      "Otavalo",
      "Pasaje",
      "Pedro Carbo",
      "Pelileo",
      "Pinas",
      "Playas",
      "Portoviejo",
      "Puerto Francisco de Orellana",
      "Pujili",
      "Puyo",
      "Quevedo",
      "Quito",
      "Riobamba",
      "Rocafuerte",
      "Rosa Zarate",
      "Salinas",
      "Samborondon",
      "San Gabriel",
      "San Lorenzo de Esmeraldas",
      "San Miguel",
      "San Miguel de Salcedo",
      "Santa Ana",
      "Santa Elena",
      "Santa Lucia",
      "Santa Rosa",
      "Santo Domingo de los Colorados",
      "Sucre",
      "Tena",
      "Tosagua",
      "Tulcan",
      "Valdez",
      "Velasco Ibarra",
      "Ventanas",
      "Vinces",
      "Yaguachi Nuevo",
      "Zamora",
      "Zaruma",
    ],
  },
  Egypt: {
    code: "EG",
    cities: [
      "'Izbat 'Ali as Sayyid",
      "6th of October City",
      "Abnub",
      "Abu Hammad",
      "Abu Hummus",
      "Abu Kabir",
      "Abu Qurqas",
      "Abu Suweir-el-Mahatta",
      "Abu Tij",
      "Abu Tisht",
      "Abu al Matamir",
      "Abu an Numrus",
      "Ad Dilinjat",
      "Aga",
      "Ain Sukhna",
      "Akhmim",
      "Al 'Ashir min Ramadan",
      "Al 'Ayyat",
      "Al 'Idwah",
      "Al Badari",
      "Al Badrashayn",
      "Al Bajur",
      "Al Balyana",
      "Al Basaliyah Bahri",
      "Al Bawiti",
      "Al Bayadiyah",
      "Al Burj",
      "Al Fashn",
      "Al Fayyum",
      "Al Ghanayim",
      "Al Hammam",
      "Al Hamul",
      "Al Hawamidiyah",
      "Al Husayniyah",
      "Al Ibrahimiyah",
      "Al Jammaliyah",
      "Al Khankah",
      "Al Kharjah",
      "Al Khusus",
      "Al Mahallah al Kubra",
      "Al Mahmudiyah",
      "Al Mansurah",
      "Al Manzalah",
      "Al Maraghah",
      "Al Matariyah",
      "Al Minshah",
      "Al Qahirah al Jadidah",
      "Al Qanatir al Khayriyah",
      "Al Qanayat",
      "Al Qantarah",
      "Al Qassasin",
      "Al Qurayn",
      "Al Qusayr",
      "Al Qusiyah",
      "Al Waqf",
      "Al Wasitah",
      "Al-'Ubur",
      "Al-Kharijah",
      "Al-Minya al-Jadidah",
      "Alexandria",
      "An Nasiriyah",
      "An Nubariyah",
      "An-Najaylah",
      "Ar Radisiyah Qibli",
      "Ar Rahmaniyah",
      "Ar Riyad",
      "Ar-Rawdah",
      "Arish",
      "Armant",
      "As Saff",
      "As Sahil",
      "As Salihiyah al Jadidah",
      "As Sallum",
      "As Santah",
      "As Sarw",
      "As Siba'iyah",
      "As Sinbillawayn",
      "Ash Shaykh Zuwayd",
      "Ash Shuhada'",
      "Ash-Shaykh Zayid",
      "Ashmun",
      "Assiut",
      "Aswan",
      "At Tall al Kabir",
      "At Tarif",
      "Atfih",
      "Awlad Saqr",
      "Awsim",
      "Az Zarqa",
      "Az Zayniyah Qibli",
      "Badr",
      "Badr",
      "Baltim",
      "Banha",
      "Bani 'Ubayd",
      "Bani Mazar",
      "Bani Suwayf",
      "Bani Suwayf al Jadidah",
      "Basyun",
      "Bi'r al 'Abd",
      "Biba",
      "Bilbeis",
      "Bilqas",
      "Birkat as Sab'",
      "Biyala",
      "Burj al 'Arab",
      "Burj al 'Arab al Jadidah",
      "Bush",
      "Cairo",
      "Damanhur",
      "Damietta",
      "Dar as Salam",
      "Daraw",
      "Dayr Mawas",
      "Dayrut",
      "Dikirnis",
      "Dishna",
      "Disuq",
      "Diyarb Najm",
      "Dumyat al Jadidah",
      "Edfu",
      "El Dabaa",
      "El Gouna",
      "El Shorouk",
      "El-Tor",
      "Esna",
      "Faqus",
      "Faraskur",
      "Farshut",
      "Fayid",
      "Fuwwah",
      "Girga",
      "Giza",
      "Halwan",
      "Hawsh 'Isa",
      "Hihya",
      "Hurghada",
      "Ibshaway",
      "Idku",
      "Ihnasiyah",
      "Ismailia",
      "Itay al Barud",
      "Itsa",
      "Juhaynah",
      "Kafr Sa'd",
      "Kafr Saqr",
      "Kafr Shukr",
      "Kafr ad Dawwar",
      "Kafr al Battikh",
      "Kafr al Kurdi",
      "Kafr ash Shaykh",
      "Kafr az Zayyat",
      "Kawm Hamadah",
      "Kirdasah",
      "Kom Ombo",
      "Luxor",
      "Madinat Wadi an Natrun",
      "Madinat an Nasr",
      "Madinat as Sadat",
      "Maghaghah",
      "Mahallat Damanah",
      "Mallawi",
      "Manfalut",
      "Manshiyat al Qanatir",
      "Marsa Matruh",
      "Mashtul as Suq",
      "Matay",
      "Minya",
      "Minya al Qamh",
      "Minyat an Nasr",
      "Mit Abu Ghalib",
      "Mit Ghamr",
      "Mit Salsil",
      "Munsha'at Abu 'Umar",
      "Munuf",
      "Mut",
      "Mutubas",
      "Nabaruh",
      "Nag Hammadi",
      "Naqadah",
      "New Cairo",
      "Port Said",
      "Qaha",
      "Qalyub",
      "Qift",
      "Qillin",
      "Qina",
      "Qus",
      "Qutur",
      "Quwaysina",
      "Rafah",
      "Ras Gharib",
      "Ras el-Barr",
      "Rosetta",
      "Safaga",
      "Samalut",
      "Samannud",
      "San al Hajar al Qibliyah",
      "Saqultah",
      "Shalatin",
      "Sharm el-Sheikh",
      "Shibin al Kawm",
      "Shibin al Qanatir",
      "Shirbin",
      "Shubra al Khaymah",
      "Shubrakhit",
      "Sidfa",
      "Sidi Barani",
      "Sidi Ghazi",
      "Sidi Salim",
      "Sinnuris",
      "Sirs al Layyanah",
      "Siwah",
      "Sohag",
      "Suez",
      "Sumusta al Waqf",
      "Sumusta as Sultani",
      "Tahta",
      "Tala",
      "Talkha",
      "Tamiyah",
      "Tanta",
      "Tima",
      "Timayy al Imdid",
      "Tud",
      "Tukh",
      "Yusuf as-Siddiq",
      "Zagazig",
      "Zefta",
      "`Izbat al Burj",
    ],
  },
  "El Salvador": {
    code: "SV",
    cities: [
      "Acajutla",
      "Aguilares",
      "Ahuachapan",
      "Antiguo Cuscatlan",
      "Apopa",
      "Armenia",
      "Ayutuxtepeque",
      "Berlin",
      "Chalatenango",
      "Chalchuapa",
      "Ciudad Arce",
      "Cojutepeque",
      "Cuscatancingo",
      "Delgado",
      "Guazapa",
      "Ilopango",
      "Izalco",
      "Jucuapa",
      "La Libertad",
      "La Union",
      "Mejicanos",
      "Metapan",
      "Puerto El Triunfo",
      "Quezaltepeque",
      "San Francisco",
      "San Marcos",
      "San Martin",
      "San Miguel",
      "San Rafael Oriente",
      "San Salvador",
      "San Vicente",
      "Santa Ana",
      "Santa Rosa de Lima",
      "Santa Tecla",
      "Santiago de Maria",
      "Santo Tomas",
      "Sensuntepeque",
      "Sonsonate",
      "Sonzacate",
      "Soyapango",
      "Usulutan",
      "Zacatecoluca",
      "Zaragoza",
    ],
  },
  "Equatorial Guinea": {
    code: "GQ",
    cities: ["Aconibe", "Anisoc", "Bata", "Ebebiyin", "Malabo"],
  },
  Eritrea: {
    code: "ER",
    cities: [
      "Adi Keyh",
      "Asmara",
      "Assab",
      "Barentu",
      "Dek'emhare",
      "Edd",
      "Himora",
      "Keren",
      "Massawa",
      "Mendefera",
    ],
  },
  Estonia: {
    code: "EE",
    cities: [
      "Haapsalu",
      "Johvi",
      "Keila",
      "Kohtla-Jaerve",
      "Kuressaare",
      "Maardu",
      "Narva",
      "Nomme",
      "Paernu",
      "Rakvere",
      "Sillamaee",
      "Tallinn",
      "Tartu",
      "Tori",
      "Valga",
      "Viljandi",
      "Voru",
    ],
  },
  Ethiopia: {
    code: "ET",
    cities: [
      "'Alem Ketema",
      "'Alem T'ena",
      "'Alemaya",
      "Abala",
      "Abderafi",
      "Abiy Adi",
      "Abomsa",
      "Addis Ababa",
      "Adebaye",
      "Adet",
      "Adigrat",
      "Adis 'Alem",
      "Adis K'idame",
      "Adis Zemen",
      "Adwa",
      "Agaro",
      "Aksum",
      "Alamata",
      "Amba Giorgis",
      "Ara Ali",
      "Arabi",
      "Arba Minch",
      "Areka",
      "Arerti",
      "Arsi Negele",
      "Asaita",
      "Asasa",
      "Asbe Teferi",
      "Asela",
      "Asosa",
      "At'aye",
      "Awasa",
      "Awash",
      "Ayana",
      "Aykel",
      "Babile",
      "Bahir Dar",
      "Bako",
      "Bale Robe",
      "Bambesi",
      "Bati",
      "Bedele",
      "Bedesa",
      "Bedesa",
      "Bek'oji",
      "Bichena",
      "Bishoftu",
      "Boditi",
      "Bonga",
      "Burayu",
      "Bure",
      "Butajira",
      "Ch'elenk'o",
      "Ch'ench'a",
      "Ch'iko",
      "Ch'ina Hasen",
      "Chagni",
      "Ciro",
      "Dabat",
      "Damboya",
      "Dangila",
      "Daye",
      "Debark'",
      "Debre Birhan",
      "Debre Mark'os",
      "Debre Tabor",
      "Debre Werk'",
      "Deder",
      "Degeh Bur",
      "Dejen",
      "Dembech'a",
      "Dembi Dolo",
      "Dera",
      "Dessie",
      "Dibate",
      "Digih Habar Es",
      "Dila",
      "Dire Dawa",
      "Dodola",
      "Dolo",
      "Dubti",
      "Durame",
      "Duuban",
      "El Bahay",
      "Fadhigaradle",
      "Felana",
      "Felege Neway",
      "Fiche",
      "Filakit",
      "Finote Selam",
      "Funyan Bira",
      "Gambela",
      "Gebre Guracha",
      "Gedeb",
      "Gedo",
      "Gelemso",
      "Genet",
      "Gewane",
      "Ghinnir",
      "Gidole",
      "Gimbi",
      "Gimbicho",
      "Gimja Bet Maryam",
      "Ginch'i",
      "Ginja Bet",
      "Goba",
      "Gobesa",
      "Gode",
      "Golwayn",
      "Gonder",
      "Guder",
      "Gurbete",
      "Gutin",
      "Hadero",
      "Hagere Hiywet",
      "Hagere Maryam",
      "Harar",
      "Harbu",
      "Hartishek",
      "Hayk'",
      "Hirna",
      "Hosa'ina",
      "Huruta",
      "Ibnat",
      "Idaga Hamus",
      "Inda Silase",
      "Indabaguna",
      "Injibara",
      "Inseno",
      "Intich'o",
      "Iteya",
      "Jijiga",
      "Jimma",
      "Jinka",
      "K'ola Diba",
      "K'olito",
      "Kachisi",
      "Kahandhale",
      "Kebri Dahar",
      "Kele",
      "Kemise",
      "Kercha",
      "Kibre Mengist",
      "Kobo",
      "Kofele",
      "Kombolcha",
      "Komtikas",
      "Korem",
      "Kuyera",
      "Lalibela",
      "Lasoano",
      "Leku",
      "Logiya",
      "Lumame",
      "Maksenyit",
      "Mambuk",
      "May Cadera",
      "Maych'ew",
      "Mehal Meda",
      "Mehoni",
      "Mek'ele",
      "Mek'i",
      "Mena",
      "Mendi",
      "Mer'awi",
      "Mersa",
      "Mertule Maryam",
      "Metahara",
      "Metemma",
      "Metu",
      "Mi'eso",
      "Mile",
      "Mizan Teferi",
      "Mojo",
      "Mot'a",
      "Nazret",
      "Neefkuceliye",
      "Nefas Mewch'a",
      "Negele",
      "Nejo",
      "Nek'emte",
      "Qorof",
      "Raqo",
      "Robit",
      "Robit",
      "Sagure",
      "Sebeta",
      "Sek'ot'a",
      "Sendafa",
      "Shakiso",
      "Shambu",
      "Shashamane",
      "Shendi",
      "Sheno",
      "Sheraro",
      "Shinshicho",
      "Shone",
      "Sifra",
      "Sire",
      "Sodo",
      "Suntu",
      "T'is Isat",
      "Tarch'a Sodo",
      "Tepi",
      "Tikil Dingay",
      "Tilili",
      "Tulu Bolo",
      "Waal",
      "Wacha",
      "Waka",
      "Waliso",
      "Wegeda",
      "Weldiya",
      "Welench'iti",
      "Welk'it'e",
      "Wenago",
      "Wendo",
      "Wenji",
      "Were Ilu",
      "Werota",
      "Wik'ro",
      "Wonji",
      "Worabe",
      "Yabelo",
      "Yamarugley",
      "Yirga 'Alem",
      "Yirga Ch'efe",
      "Ziway",
    ],
  },
  "Faeroe Islands": {
    code: "FO",
    cities: ["Torshavn"],
  },
  Fiji: {
    code: "FJ",
    cities: ["Ba", "Labasa", "Lautoka", "Nadi", "Nasinu", "Sigatoka", "Suva"],
  },
  Finland: {
    code: "FI",
    cities: [
      "AEaenekoski",
      "AElajaervi",
      "Anjala",
      "Ekenaes",
      "Espoo",
      "Forssa",
      "Haemeenlinna",
      "Hamina",
      "Haukipudas",
      "Heinola",
      "Helsinki",
      "Hollola",
      "Hyvinge",
      "Iisalmi",
      "Ilmajoki",
      "Imatra",
      "Jaemsae",
      "Jaervenpaeae",
      "Jakobstad",
      "Janakkala",
      "Joensuu",
      "Joutseno",
      "Jyvaeskylae",
      "Kaarela",
      "Kaarina",
      "Kajaani",
      "Kallio",
      "Kangasala",
      "Kankaanpaeae",
      "Karhula",
      "Kauhajoki",
      "Kemi",
      "Kempele",
      "Kerava",
      "Keuruu",
      "Kiiminki",
      "Kirkkonummi",
      "Klaukkala",
      "Kokkola",
      "Kontiolahti",
      "Korsholm",
      "Kotka",
      "Kouvola",
      "Kuhmo",
      "Kuopio",
      "Kurikka",
      "Kuusamo",
      "Lahti",
      "Lappeenranta",
      "Lapua",
      "Laukaa",
      "Lauttasaari",
      "Lempaeaelae",
      "Leppaevirta",
      "Lieksa",
      "Lieto",
      "Liperi",
      "Lohja",
      "Loimaa",
      "Lovisa",
      "Maentsaelae",
      "Mellunkylae",
      "Mikkeli",
      "Munkkiniemi",
      "Naantali",
      "Nastola",
      "Nivala",
      "Nokia",
      "Nurmijaervi",
      "Orimattila",
      "Oulu",
      "Paimio",
      "Pargas",
      "Pedersoere",
      "Pieksaemaeki",
      "Pirkkala",
      "Pori",
      "Porvoo",
      "Raahe",
      "Raisio",
      "Rauma",
      "Riihimaeki",
      "Rovaniemi",
      "Saarijaervi",
      "Salo",
      "Savonlinna",
      "Seinaejoki",
      "Sibbo",
      "Siilinjaervi",
      "Sotkamo",
      "Tampere",
      "Tornio",
      "Turku",
      "Tuusula",
      "Ulvila",
      "Uusikaupunki",
      "Vaasa",
      "Vaestaboland",
      "Valkeakoski",
      "Vammala",
      "Vantaa",
      "Varkaus",
      "Vihti",
      "Vuosaari",
      "Ylivieska",
      "Yloejaervi",
    ],
  },
  France: {
    code: "FR",
    cities: [
      "Abbeville",
      "Acheres",
      "Agde",
      "Agen",
      "Aire-sur-la-Lys",
      "Aix-en-Provence",
      "Aix-les-Bains",
      "Ajaccio",
      "Albert",
      "Albertville",
      "Albi",
      "Alencon",
      "Ales",
      "Alfortville",
      "Allauch",
      "Allonnes",
      "Ambares-et-Lagrave",
      "Amberieu-en-Bugey",
      "Amboise",
      "Amiens",
      "Amilly",
      "Amneville",
      "Andernos-les-Bains",
      "Andresy",
      "Angers",
      "Anglet",
      "Angouleme",
      "Aniche",
      "Annecy",
      "Annecy-le-Vieux",
      "Annemasse",
      "Annoeullin",
      "Annonay",
      "Antibes",
      "Antony",
      "Anzin",
      "Apt",
      "Arcachon",
      "Arcueil",
      "Argeles",
      "Argentan",
      "Argenteuil",
      "Arles",
      "Armentieres",
      "Arnouville",
      "Arras",
      "Asnieres-sur-Seine",
      "Athis-Mons",
      "Aubagne",
      "Aubenas",
      "Aubergenville",
      "Aubervilliers",
      "Aubiere",
      "Auch",
      "Auchel",
      "Audincourt",
      "Aulnay-sous-Bois",
      "Auray",
      "Aurillac",
      "Autun",
      "Auxerre",
      "Avignon",
      "Avion",
      "Avon",
      "Avrille",
      "Bagneux",
      "Bagnolet",
      "Bagnols-sur-Ceze",
      "Baille",
      "Bailleul",
      "Balma",
      "Bar-le-Duc",
      "Barentin",
      "Bastia",
      "Bayeux",
      "Bayonne",
      "Beaucaire",
      "Beaumont",
      "Beaune",
      "Beausoleil",
      "Beauvais",
      "Begles",
      "Behren-les-Forbach",
      "Belfort",
      "Belle de Mai",
      "Bellegarde-sur-Valserine",
      "Berck",
      "Berck-Plage",
      "Bergerac",
      "Bernay",
      "Berre-l'Etang",
      "Besancon",
      "Bethune",
      "Beziers",
      "Bezons",
      "Biarritz",
      "Billere",
      "Biscarrosse",
      "Bischheim",
      "Bischwiller",
      "Blagnac",
      "Blanquefort",
      "Blois",
      "Bobigny",
      "Bois-Colombes",
      "Bois-Guillaume",
      "Bois-d'Arcy",
      "Boissy-Saint-Leger",
      "Bolbec",
      "Bollene",
      "Bon-Secours",
      "Bondues",
      "Bondy",
      "Bonneuil-sur-Marne",
      "Bonneville",
      "Bordeaux",
      "Bouc-Bel-Air",
      "Bouguenais",
      "Boulogne-Billancourt",
      "Boulogne-sur-Mer",
      "Bourg-de-Peage",
      "Bourg-en-Bresse",
      "Bourg-la-Reine",
      "Bourg-les-Valence",
      "Bourges",
      "Bourgoin-Jallieu",
      "Bressuire",
      "Brest",
      "Bretigny-sur-Orge",
      "Briancon",
      "Brie-Comte-Robert",
      "Brignais",
      "Brignoles",
      "Brive-la-Gaillarde",
      "Bron",
      "Bruay-la-Buissiere",
      "Bruay-sur-l'Escaut",
      "Bruges",
      "Brunoy",
      "Bruz",
      "Bry-sur-Marne",
      "Bully-les-Mines",
      "Bures-sur-Yvette",
      "Bussy-Saint-Georges",
      "Cachan",
      "Caen",
      "Cagnes-sur-Mer",
      "Cahors",
      "Calais",
      "Caluire-et-Cuire",
      "Cambrai",
      "Canet-en-Roussillon",
      "Cannes",
      "Canteleu",
      "Carcassonne",
      "Carmaux",
      "Carpentras",
      "Carquefou",
      "Carrieres-sous-Poissy",
      "Carrieres-sur-Seine",
      "Carros",
      "Carvin",
      "Castanet-Tolosan",
      "Castelnau-le-Lez",
      "Castelnaudary",
      "Castelsarrasin",
      "Castres",
      "Caudebec-les-Elbeuf",
      "Caudry",
      "Cavaillon",
      "Cenon",
      "Cergy",
      "Cergy-Pontoise",
      "Cernay",
      "Cesson-Sevigne",
      "Cestas",
      "Chalette-sur-Loing",
      "Challans",
      "Chalon-sur-Saone",
      "Chalons-en-Champagne",
      "Chamalieres",
      "Chambery",
      "Chambray-les-Tours",
      "Chamonix-Mont-Blanc",
      "Champigny-sur-Marne",
      "Champs-sur-Marne",
      "Chantilly",
      "Charenton-le-Pont",
      "Charleville-Mezieres",
      "Chartres",
      "Chateau-Gontier",
      "Chateau-Thierry",
      "Chateau-d'Olonne",
      "Chateaubriant",
      "Chateaudun",
      "Chateauneuf-les-Martigues",
      "Chateaurenard",
      "Chateauroux",
      "Chatellerault",
      "Chatenay-Malabry",
      "Chatillon",
      "Chatou",
      "Chaumont",
      "Chauny",
      "Chaville",
      "Chelles",
      "Chennevieres-sur-Marne",
      "Chenove",
      "Cherbourg-Octeville",
      "Chevigny-Saint-Sauveur",
      "Chevilly-Larue",
      "Chilly-Mazarin",
      "Choisy-le-Roi",
      "Cholet",
      "Cinq Avenues",
      "Clamart",
      "Claye-Souilly",
      "Clermont",
      "Clermont-Ferrand",
      "Clichy",
      "Clichy-sous-Bois",
      "Cluses",
      "Cognac",
      "Colmar",
      "Colombes",
      "Colomiers",
      "Combs-la-Ville",
      "Comines",
      "Compiegne",
      "Concarneau",
      "Conde-sur-l'Escaut",
      "Conflans-Sainte-Honorine",
      "Corbeil-Essonnes",
      "Cormeilles-en-Parisis",
      "Cosne-Cours-sur-Loire",
      "Coudekerque-Branche",
      "Coueron",
      "Coulommiers",
      "Courbevoie",
      "Courcouronnes",
      "Cournon-d'Auvergne",
      "Courrieres",
      "Cran-Gevrier",
      "Creil",
      "Crepy-en-Valois",
      "Creteil",
      "Creutzwald",
      "Croissy-sur-Seine",
      "Croix",
      "Cugnaux",
      "Cusset",
      "Dammarie-les-Lys",
      "Dax",
      "Decines-Charpieu",
      "Denain",
      "Deuil-la-Barre",
      "Deville-les-Rouen",
      "Dieppe",
      "Digne-les-Bains",
      "Dijon",
      "Dinan",
      "Dinard",
      "Dole",
      "Domont",
      "Douai",
      "Douarnenez",
      "Douchy-les-Mines",
      "Dourdan",
      "Draguignan",
      "Drancy",
      "Draveil",
      "Dreux",
      "Dugny",
      "Dunkerque",
      "Eaubonne",
      "Echirolles",
      "Ecully",
      "Elancourt",
      "Elbeuf",
      "Enghien-les-Bains",
      "Epernay",
      "Epinal",
      "Epinay-sous-Senart",
      "Epinay-sur-Seine",
      "Equeurdreville-Hainneville",
      "Eragny",
      "Ermont",
      "Etampes",
      "Etaples",
      "Evreux",
      "Evry",
      "Eybens",
      "Eysines",
      "Faches-Thumesnil",
      "Fameck",
      "Fecamp",
      "Figeac",
      "Firminy",
      "Flers",
      "Fleury-les-Aubrais",
      "Floirac",
      "Florange",
      "Fontaine",
      "Fontainebleau",
      "Fontenay-aux-Roses",
      "Fontenay-le-Comte",
      "Fontenay-le-Fleury",
      "Fontenay-sous-Bois",
      "Forbach",
      "Fos-sur-Mer",
      "Fosses",
      "Fougeres",
      "Fourmies",
      "Francheville",
      "Franconville",
      "Frejus",
      "Fresnes",
      "Freyming-Merlebach",
      "Frontignan",
      "Gagny",
      "Gaillac",
      "Gaillard",
      "Gap",
      "Garches",
      "Gardanne",
      "Garges-les-Gonesse",
      "Genas",
      "Gennevilliers",
      "Gentilly",
      "Gerardmer",
      "Gien",
      "Gif-sur-Yvette",
      "Gisors",
      "Givors",
      "Gonesse",
      "Goussainville",
      "Gradignan",
      "Grande-Synthe",
      "Granville",
      "Grasse",
      "Graulhet",
      "Gravelines",
      "Grenoble",
      "Grigny",
      "Guebwiller",
      "Guerande",
      "Gueret",
      "Guilherand-Granges",
      "Guipavas",
      "Gujan-Mestras",
      "Guyancourt",
      "Haguenau",
      "Halluin",
      "Harnes",
      "Haubourdin",
      "Hautmont",
      "Hayange",
      "Hazebrouck",
      "Hem",
      "Hendaye",
      "Henin-Beaumont",
      "Hennebont",
      "Herblay",
      "Hericourt",
      "Herouville-Saint-Clair",
      "Hirson",
      "Houilles",
      "Hyeres",
      "Hœnheim",
      "Illkirch-Graffenstaden",
      "Illzach",
      "Isbergues",
      "Issoire",
      "Issoudun",
      "Issy-les-Moulineaux",
      "Istres",
      "Ivry-sur-Seine",
      "Jarville-la-Malgrange",
      "Jeumont",
      "Joigny",
      "Joinville-le-Pont",
      "Joue-les-Tours",
      "Jouy-le-Moutier",
      "Juvisy-sur-Orge",
      "Kingersheim",
      "L'Hay-les-Roses",
      "L'Isle-Adam",
      "L'Isle-d'Abeau",
      "L'Isle-sur-la-Sorgue",
      "L'Union",
      "La Baule-Escoublac",
      "La Blancarde",
      "La Capelette",
      "La Celle-Saint-Cloud",
      "La Chapelle-Saint-Luc",
      "La Chapelle-sur-Erdre",
      "La Ciotat",
      "La Courneuve",
      "La Crau",
      "La Defense",
      "La Ferte-Bernard",
      "La Fleche",
      "La Garde",
      "La Garenne-Colombes",
      "La Madeleine",
      "La Motte-Servolex",
      "La Pomme",
      "La Queue-en-Brie",
      "La Roche-sur-Foron",
      "La Roche-sur-Yon",
      "La Rochelle",
      "La Rose",
      "La Seyne-sur-Mer",
      "La Teste-de-Buch",
      "La Trinite",
      "La Valette-du-Var",
      "Lagny-sur-Marne",
      "Lamballe",
      "Lambersart",
      "Landerneau",
      "Lanester",
      "Lannion",
      "Laon",
      "Lattes",
      "Laval",
      "Lavaur",
      "Laxou",
      "Le Blanc-Mesnil",
      "Le Bourget",
      "Le Bouscat",
      "Le Cabot",
      "Le Camas",
      "Le Cannet",
      "Le Chambon-Feugerolles",
      "Le Chesnay",
      "Le Creusot",
      "Le Grand-Quevilly",
      "Le Havre",
      "Le Kremlin-Bicetre",
      "Le Mans",
      "Le Mee-sur-Seine",
      "Le Pecq",
      "Le Perreux-sur-Marne",
      "Le Petit-Quevilly",
      "Le Plessis-Robinson",
      "Le Plessis-Trevise",
      "Le Pont-de-Claix",
      "Le Pontet",
      "Le Portel",
      "Le Pradet",
      "Le Pre-Saint-Gervais",
      "Le Puy-en-Velay",
      "Le Raincy",
      "Le Relecq-Kerhuon",
      "Le Vesinet",
      "Lens",
      "Les Clayes-sous-Bois",
      "Les Herbiers",
      "Les Lilas",
      "Les Mureaux",
      "Les Olives",
      "Les Pavillons-sous-Bois",
      "Les Pennes-Mirabeau",
      "Les Ponts-de-Ce",
      "Les Sables-d'Olonne",
      "Les Ulis",
      "Levallois-Perret",
      "Libourne",
      "Lievin",
      "Lille",
      "Lillers",
      "Limay",
      "Limeil-Brevannes",
      "Limoges",
      "Limoux",
      "Lingolsheim",
      "Lisieux",
      "Livry-Gargan",
      "Lognes",
      "Lomme",
      "Longjumeau",
      "Longuenesse",
      "Longwy",
      "Lons",
      "Lons-le-Saunier",
      "Loos",
      "Lorient",
      "Lormont",
      "Loudeac",
      "Lourdes",
      "Louviers",
      "Luce",
      "Lucon",
      "Lunel",
      "Luneville",
      "Lyon",
      "Lyon 01",
      "Lyon 02",
      "Lyon 03",
      "Lyon 04",
      "Lyon 05",
      "Lyon 06",
      "Lyon 07",
      "Lyon 08",
      "Lyon 09",
      "Lys-lez-Lannoy",
      "Macon",
      "Mainvilliers",
      "Maisons-Alfort",
      "Maisons-Laffitte",
      "Malakoff",
      "Malpasse",
      "Mandelieu-la-Napoule",
      "Manosque",
      "Mantes-la-Jolie",
      "Mantes-la-Ville",
      "Marcq-en-Baroeul",
      "Marignane",
      "Marly",
      "Marly",
      "Marly-le-Roi",
      "Marmande",
      "Marne La Vallee",
      "Maromme",
      "Marquette-lez-Lille",
      "Marseille",
      "Marseille 01",
      "Marseille 02",
      "Marseille 03",
      "Marseille 04",
      "Marseille 05",
      "Marseille 06",
      "Marseille 07",
      "Marseille 08",
      "Marseille 09",
      "Marseille 10",
      "Marseille 11",
      "Marseille 12",
      "Marseille 13",
      "Marseille 14",
      "Marseille 15",
      "Marseille 16",
      "Martigues",
      "Massy",
      "Maubeuge",
      "Mauguio",
      "Maurepas",
      "Mayenne",
      "Mazamet",
      "Mazargues",
      "Meaux",
      "Melun",
      "Mende",
      "Mennecy",
      "Menton",
      "Mericourt",
      "Merignac",
      "Meru",
      "Metz",
      "Meudon",
      "Meylan",
      "Meyzieu",
      "Millau",
      "Mions",
      "Miramas",
      "Mitry-Mory",
      "Moissac",
      "Moissy-Cramayel",
      "Mondeville",
      "Mons-en-Baroeul",
      "Mont-Saint-Aignan",
      "Mont-de-Marsan",
      "Montargis",
      "Montataire",
      "Montauban",
      "Montbeliard",
      "Montbrison",
      "Montceau-les-Mines",
      "Montelimar",
      "Montereau-Fault-Yonne",
      "Montesson",
      "Monteux",
      "Montfavet",
      "Montfermeil",
      "Montgeron",
      "Montigny-en-Gohelle",
      "Montigny-le-Bretonneux",
      "Montigny-les-Cormeilles",
      "Montigny-les-Metz",
      "Montivilliers",
      "Montlouis-sur-Loire",
      "Montlucon",
      "Montmagny",
      "Montmorency",
      "Montolivet",
      "Montpellier",
      "Montreuil",
      "Montrouge",
      "Morangis",
      "Morlaix",
      "Morsang-sur-Orge",
      "Mougins",
      "Moulins",
      "Mouvaux",
      "Mulhouse",
      "Muret",
      "Nancy",
      "Nanterre",
      "Nantes",
      "Narbonne",
      "Nemours",
      "Neuilly-Plaisance",
      "Neuilly-sur-Marne",
      "Neuilly-sur-Seine",
      "Nevers",
      "Nice",
      "Nimes",
      "Niort",
      "Noeux-les-Mines",
      "Nogent-le-Rotrou",
      "Nogent-sur-Marne",
      "Nogent-sur-Oise",
      "Noisiel",
      "Noisy-le-Grand",
      "Noisy-le-Sec",
      "Notre-Dame Limite",
      "Noyon",
      "Obernai",
      "Octeville",
      "Oignies",
      "Oissel",
      "Olivet",
      "Ollioules",
      "Olonne-sur-Mer",
      "Oloron-Sainte-Marie",
      "Orange",
      "Orleans",
      "Orly",
      "Orsay",
      "Orthez",
      "Orvault",
      "Osny",
      "Ostwald",
      "Oullins",
      "Outreau",
      "Oyonnax",
      "Ozoir-la-Ferriere",
      "Palaiseau",
      "Pamiers",
      "Panazol",
      "Pantin",
      "Paris",
      "Parthenay",
      "Passy",
      "Pau",
      "Perier",
      "Perigueux",
      "Pernes-les-Fontaines",
      "Perpignan",
      "Pertuis",
      "Pessac",
      "Pierrefitte-sur-Seine",
      "Pierrelatte",
      "Plaisance-du-Touch",
      "Plaisir",
      "Plan-de-Cuques",
      "Plerin",
      "Ploemeur",
      "Ploufragan",
      "Plougastel-Daoulas",
      "Plouzane",
      "Poissy",
      "Poitiers",
      "Pont-Saint-Esprit",
      "Pont-Sainte-Maxence",
      "Pont-a-Mousson",
      "Pontarlier",
      "Pontault-Combault",
      "Ponthierry",
      "Pontivy",
      "Pontoise",
      "Pornic",
      "Pornichet",
      "Port-de-Bouc",
      "Porto-Vecchio",
      "Provins",
      "Puteaux",
      "Quetigny",
      "Quimper",
      "Quimperle",
      "Raismes",
      "Rambouillet",
      "Ramonville-Saint-Agne",
      "Reims",
      "Rennes",
      "Reze",
      "Riedisheim",
      "Rillieux-la-Pape",
      "Riom",
      "Riorges",
      "Ris-Orangis",
      "Rive-de-Gier",
      "Rixheim",
      "Roanne",
      "Roche-la-Moliere",
      "Rochefort",
      "Rodez",
      "Rognac",
      "Roissy-en-Brie",
      "Romainville",
      "Romans-sur-Isere",
      "Rombas",
      "Romilly-sur-Seine",
      "Romorantin-Lanthenay",
      "Ronchin",
      "Roncq",
      "Roquebrune-Cap-Martin",
      "Roquebrune-sur-Argens",
      "Rosny-sous-Bois",
      "Roubaix",
      "Rouen",
      "Rouet",
      "Royan",
      "Rueil-Malmaison",
      "Rumilly",
      "Sable-sur-Sarthe",
      "Saint-Amand-Montrond",
      "Saint-Amand-les-Eaux",
      "Saint-Andre-les-Vergers",
      "Saint-Andre-lez-Lille",
      "Saint-Avertin",
      "Saint-Avold",
      "Saint-Barnabe",
      "Saint-Barthelemy",
      "Saint-Brevin-les-Pins",
      "Saint-Brice-sous-Foret",
      "Saint-Brieuc",
      "Saint-Chamond",
      "Saint-Claude",
      "Saint-Cloud",
      "Saint-Cyr-l'Ecole",
      "Saint-Cyr-sur-Loire",
      "Saint-Cyr-sur-Mer",
      "Saint-Denis",
      "Saint-Die-des-Vosges",
      "Saint-Dizier",
      "Saint-Egreve",
      "Saint-Esteve",
      "Saint-Etienne",
      "Saint-Etienne-du-Rouvray",
      "Saint-Fargeau-Ponthierry",
      "Saint-Fons",
      "Saint-Francois",
      "Saint-Gaudens",
      "Saint-Genis-Laval",
      "Saint-Germain-en-Laye",
      "Saint-Gilles",
      "Saint-Giniez",
      "Saint-Gratien",
      "Saint-Herblain",
      "Saint-Jean-de-Braye",
      "Saint-Jean-de-Luz",
      "Saint-Jean-de-la-Ruelle",
      "Saint-Jerome",
      "Saint-Julien-en-Genevois",
      "Saint-Junien",
      "Saint-Just",
      "Saint-Just-Saint-Rambert",
      "Saint-Laurent-du-Var",
      "Saint-Lazare",
      "Saint-Leu",
      "Saint-Leu-la-Foret",
      "Saint-Lo",
      "Saint-Louis",
      "Saint-Loup",
      "Saint-Malo",
      "Saint-Mande",
      "Saint-Marcel",
      "Saint-Martin-Boulogne",
      "Saint-Martin-d'Heres",
      "Saint-Martin-de-Crau",
      "Saint-Maur-des-Fosses",
      "Saint-Maurice",
      "Saint-Mauront",
      "Saint-Max",
      "Saint-Maximin-la-Sainte-Baume",
      "Saint-Medard-en-Jalles",
      "Saint-Michel-sur-Orge",
      "Saint-Nazaire",
      "Saint-Omer",
      "Saint-Orens-de-Gameville",
      "Saint-Ouen",
      "Saint-Ouen-l'Aumone",
      "Saint-Paul-les-Dax",
      "Saint-Pierre-des-Corps",
      "Saint-Pol-sur-Mer",
      "Saint-Priest",
      "Saint-Quentin",
      "Saint-Quentin-en-Yvelines",
      "Saint-Raphael",
      "Saint-Remy-de-Provence",
      "Saint-Saulve",
      "Saint-Sebastien-sur-Loire",
      "Saint-Tronc",
      "Sainte-Foy-les-Lyon",
      "Sainte-Genevieve-des-Bois",
      "Sainte-Luce-sur-Loire",
      "Sainte-Marguerite",
      "Sainte-Maxime",
      "Sainte-Savine",
      "Saintes",
      "Sallanches",
      "Sallaumines",
      "Salon-de-Provence",
      "Sanary-sur-Mer",
      "Sannois",
      "Saran",
      "Sarcelles",
      "Sarlat-la-Caneda",
      "Sarrebourg",
      "Sarreguemines",
      "Sartrouville",
      "Sassenage",
      "Saumur",
      "Saverne",
      "Savigny-le-Temple",
      "Savigny-sur-Orge",
      "Sceaux",
      "Schiltigheim",
      "Seclin",
      "Sedan",
      "Selestat",
      "Senlis",
      "Sens",
      "Septemes-les-Vallons",
      "Sete",
      "Sevran",
      "Sevres",
      "Seynod",
      "Seyssinet-Pariset",
      "Sin-le-Noble",
      "Six-Fours-les-Plages",
      "Soissons",
      "Soisy-sous-Montmorency",
      "Sollies-Pont",
      "Somain",
      "Sorgues",
      "Sotteville-les-Rouen",
      "Soyaux",
      "Stains",
      "Stiring-Wendel",
      "Strasbourg",
      "Sucy-en-Brie",
      "Suresnes",
      "Talant",
      "Talence",
      "Tarare",
      "Tarascon",
      "Tarbes",
      "Tarnos",
      "Tassin-la-Demi-Lune",
      "Taverny",
      "Tergnier",
      "Thiais",
      "Thiers",
      "Thionville",
      "Thonon-les-Bains",
      "Thouars",
      "Tinqueux",
      "Torcy",
      "Toul",
      "Toulon",
      "Toulouse",
      "Tourcoing",
      "Tourlaville",
      "Tournefeuille",
      "Tournon-sur-Rhone",
      "Tours",
      "Trappes",
      "Trelaze",
      "Tremblay-en-France",
      "Triel-sur-Seine",
      "Troyes",
      "Tulle",
      "Ussel",
      "Vaires-sur-Marne",
      "Val-de-Reuil",
      "Valbonne",
      "Valence",
      "Valenciennes",
      "Valentigney",
      "Valenton",
      "Valfin-les-Saint-Claude",
      "Vallauris",
      "Valreas",
      "Vandoeuvre-les-Nancy",
      "Vannes",
      "Vanves",
      "Varennes-Vauzelles",
      "Vaulx-en-Velin",
      "Vaureal",
      "Vauvert",
      "Vaux-le-Penil",
      "Velizy-Villacoublay",
      "Vence",
      "Vendome",
      "Venissieux",
      "Verdun",
      "Verneuil-sur-Seine",
      "Vernon",
      "Vernouillet",
      "Verrieres-le-Buisson",
      "Versailles",
      "Vertou",
      "Vesoul",
      "Vichy",
      "Vienne",
      "Vierzon",
      "Vieux-Conde",
      "Vigneux-sur-Seine",
      "Ville-d'Avray",
      "Villefontaine",
      "Villefranche-de-Rouergue",
      "Villefranche-sur-Saone",
      "Villejuif",
      "Villemomble",
      "Villenave-d'Ornon",
      "Villeneuve-Loubet",
      "Villeneuve-Saint-Georges",
      "Villeneuve-d'Ascq",
      "Villeneuve-la-Garenne",
      "Villeneuve-le-Roi",
      "Villeneuve-les-Avignon",
      "Villeneuve-sur-Lot",
      "Villeparisis",
      "Villepinte",
      "Villers-Cotterets",
      "Villers-les-Nancy",
      "Villetaneuse",
      "Villeurbanne",
      "Villiers-le-Bel",
      "Villiers-sur-Marne",
      "Vincennes",
      "Vire",
      "Viroflay",
      "Viry-Chatillon",
      "Vitre",
      "Vitrolles",
      "Vitry-le-Francois",
      "Vitry-sur-Seine",
      "Voiron",
      "Voisins-le-Bretonneux",
      "Voreppe",
      "Wasquehal",
      "Wattignies",
      "Wattrelos",
      "Wittelsheim",
      "Wittenheim",
      "Woippy",
      "Yerres",
      "Yutz",
      "Yvetot",
      "Yzeure",
    ],
  },
  "French Guiana": {
    code: "GF",
    cities: [
      "Cayenne",
      "Kourou",
      "Matoury",
      "Remire-Montjoly",
      "Saint-Laurent-du-Maroni",
    ],
  },
  "French Polynesia": {
    code: "PF",
    cities: ["Faaa", "Mahina", "Paea", "Papao", "Papeete", "Pirae", "Punaauia"],
  },
  Gabon: {
    code: "GA",
    cities: [
      "Akanda",
      "Bitam",
      "Franceville",
      "Gamba",
      "Koulamoutou",
      "Lambarene",
      "Lastoursville",
      "Libreville",
      "Makokou",
      "Mitzic",
      "Moanda",
      "Mouila",
      "Ntoum",
      "Okondja",
      "Owendo",
      "Oyem",
      "Port-Gentil",
      "Tchibanga",
    ],
  },
  Gambia: {
    code: "GM",
    cities: [
      "Abuko",
      "Bakau",
      "Bakau New Town",
      "Bakoti",
      "Banjul",
      "Basse Santa Su",
      "Brikama",
      "Brufut",
      "Bununka Kunda",
      "Busumbala",
      "Dippa Kunda",
      "Essau",
      "Faji Kunda",
      "Farafenni",
      "Farato",
      "Gambissar",
      "Garawol",
      "Gunjur",
      "Ibo Town",
      "Kanifing",
      "Kerr Serign",
      "Kotu",
      "Kunkujang",
      "Lamin",
      "Latri Kunda",
      "Latri Sabiji",
      "Mandinari",
      "Manjai Kunda",
      "Nema Kunku",
      "New Jeshwang",
      "Old Yundum",
      "Sanchaba",
      "Sanyang",
      "Serekunda",
      "Sinchu Alagi",
      "Soma",
      "Sukuta",
      "Talinding",
      "Tanji",
      "Welingara",
      "Yundum",
    ],
  },
  Georgia: {
    code: "GE",
    cities: [
      "Akhaltsikhe",
      "Batumi",
      "Bolnisi",
      "Borjomi",
      "Chiat'ura",
      "Gali",
      "Gardabani",
      "Gori",
      "K'asp'i",
      "Khashuri",
      "Kobuleti",
      "Kutaisi",
      "Lent'ekhi",
      "Marneuli",
      "Och'amch'ire",
      "Ozurgeti",
      "P'ot'i",
      "Rustavi",
      "Sagarejo",
      "Samtredia",
      "Senak'i",
      "Sokhumi",
      "Stantsiya Novyy Afon",
      "T'q'ibuli",
      "Tbilisi",
      "Telavi",
      "Tqvarch'eli",
      "Ts'khinvali",
      "Ts'q'alt'ubo",
      "Zest'aponi",
      "Zugdidi",
    ],
  },
  Germany: {
    code: "DE",
    cities: [
      "AEussere Neustadt",
      "Aachen",
      "Aalen",
      "Abensberg",
      "Achern",
      "Achim",
      "Adlershof",
      "Aerzen",
      "Ahaus",
      "Ahlen",
      "Ahrensburg",
      "Aichach",
      "Ainring",
      "Albstadt",
      "Alfeld",
      "Alfter",
      "Alpen",
      "Alsdorf",
      "Alsfeld",
      "Alsterdorf",
      "Alt-Hohenschoenhausen",
      "Alt-Treptow",
      "Altdorf",
      "Altdorf bei Nurnberg",
      "Altena",
      "Altenburg",
      "Altenstadt",
      "Altensteig",
      "Altes Lager",
      "Altglienicke",
      "Altoetting",
      "Altona",
      "Altona-Altstadt",
      "Altona-Nord",
      "Altstadt",
      "Altstadt Nord",
      "Altstadt Sud",
      "Alzenau in Unterfranken",
      "Alzey",
      "Am Hagenring",
      "Amberg",
      "Andernach",
      "Angermuende",
      "Anklam",
      "Annaberg-Buchholz",
      "Anroechte",
      "Ansbach",
      "Apen",
      "Aplerbeck",
      "Apolda",
      "Arnsberg",
      "Arnstadt",
      "Aschaffenburg",
      "Ascheberg",
      "Aschersleben",
      "Asperg",
      "Asslar",
      "Attendorn",
      "Aue",
      "Auerbach",
      "Augsburg",
      "Aulendorf",
      "Aurich",
      "Babenhausen",
      "Backnang",
      "Bad Abbach",
      "Bad Aibling",
      "Bad Arolsen",
      "Bad Belzig",
      "Bad Bentheim",
      "Bad Berleburg",
      "Bad Bramstedt",
      "Bad Camberg",
      "Bad Doberan",
      "Bad Driburg",
      "Bad Duerkheim",
      "Bad Duerrenberg",
      "Bad Durrheim",
      "Bad Essen",
      "Bad Fallingbostel",
      "Bad Frankenhausen",
      "Bad Freienwalde",
      "Bad Gandersheim",
      "Bad Harzburg",
      "Bad Hersfeld",
      "Bad Homburg vor der Hoehe",
      "Bad Honnef",
      "Bad Iburg",
      "Bad Kissingen",
      "Bad Kreuznach",
      "Bad Laasphe",
      "Bad Langensalza",
      "Bad Lauterberg im Harz",
      "Bad Liebenwerda",
      "Bad Lippspringe",
      "Bad Mergentheim",
      "Bad Muender am Deister",
      "Bad Muenstereifel",
      "Bad Nauheim",
      "Bad Nenndorf",
      "Bad Neuenahr-Ahrweiler",
      "Bad Neustadt an der Saale",
      "Bad Oeynhausen",
      "Bad Oldesloe",
      "Bad Orb",
      "Bad Pyrmont",
      "Bad Rappenau",
      "Bad Reichenhall",
      "Bad Sackingen",
      "Bad Salzdetfurth",
      "Bad Salzuflen",
      "Bad Salzungen",
      "Bad Sassendorf",
      "Bad Saulgau",
      "Bad Schwalbach",
      "Bad Schwartau",
      "Bad Segeberg",
      "Bad Soden am Taunus",
      "Bad Soden-Salmuenster",
      "Bad Staffelstein",
      "Bad Toelz",
      "Bad Urach",
      "Bad Vilbel",
      "Bad Waldsee",
      "Bad Wildungen",
      "Bad Windsheim",
      "Bad Worishofen",
      "Bad Wuennenberg",
      "Bad Wurzach",
      "Bad Zwischenahn",
      "Baden-Baden",
      "Baesweiler",
      "Baiersbronn",
      "Balingen",
      "Balve",
      "Bamberg",
      "Bannewitz",
      "Bargteheide",
      "Barmbek-Nord",
      "Barmbek-Sued",
      "Barsbuettel",
      "Barsinghausen",
      "Barssel",
      "Bassum",
      "Baumschulenweg",
      "Baunatal",
      "Bautzen",
      "Bayreuth",
      "Bebra",
      "Beckingen",
      "Beckum",
      "Bedburg",
      "Beelitz",
      "Belm",
      "Bendorf",
      "Bensheim",
      "Bergedorf",
      "Bergen",
      "Bergen auf Ruegen",
      "Bergheim",
      "Bergisch Gladbach",
      "Bergkamen",
      "Bergneustadt",
      "Berlin",
      "Berlin Koepenick",
      "Berlin Treptow",
      "Bernau bei Berlin",
      "Bernburg",
      "Besigheim",
      "Bestwig",
      "Betzdorf",
      "Beverungen",
      "Bexbach",
      "Biberach an der Riss",
      "Biebrich",
      "Biedenkopf",
      "Bielefeld",
      "Bierstadt",
      "Biesdorf",
      "Bietigheim-Bissingen",
      "Bilderstoeckchen",
      "Billerbeck",
      "Billstedt",
      "Bingen am Rhein",
      "Birkenau",
      "Birkenfeld",
      "Bischofsheim",
      "Bischofswerda",
      "Bissendorf",
      "Bitburg",
      "Bitterfeld-Wolfen",
      "Blankenburg",
      "Blankenese",
      "Blasewitz",
      "Blaubeuren",
      "Blieskastel",
      "Blomberg",
      "Blumberg",
      "Bobenheim-Roxheim",
      "Bobingen",
      "Bocholt",
      "Bochum",
      "Bochum-Hordel",
      "Bockenem",
      "Bockum",
      "Boeblingen",
      "Bogen",
      "Bogenhausen",
      "Bohl-Iggelheim",
      "Bohmte",
      "Bohnsdorf",
      "Boizenburg",
      "Bonen",
      "Bonn",
      "Bopfingen",
      "Boppard",
      "Borken",
      "Borken",
      "Borna",
      "Bornheim",
      "Bottrop",
      "Bovenden",
      "Brachenfeld-Ruthenberg",
      "Brackenheim",
      "Brake (Unterweser)",
      "Brakel",
      "Bramsche",
      "Brand-Erbisdorf",
      "Brandenburg an der Havel",
      "Braunfels",
      "Braunschweig",
      "Breisach am Rhein",
      "Bremen",
      "Bremerhaven",
      "Bremervoerde",
      "Bretten",
      "Bretzfeld",
      "Brieselang",
      "Brilon",
      "Britz",
      "Bruchkoebel",
      "Bruchsal",
      "Bruckmuhl",
      "Bruehl",
      "Bruggen",
      "Bruhl",
      "Brunsbuettel",
      "Buch",
      "Buchen in Odenwald",
      "Buchheim",
      "Buchholz in der Nordheide",
      "Buchloe",
      "Buckow",
      "Bueckeburg",
      "Buedelsdorf",
      "Buedingen",
      "Buende",
      "Bueren",
      "Buerstadt",
      "Buhl",
      "Burbach",
      "Burg bei Magdeburg",
      "Burgdorf",
      "Burghausen",
      "Burgkirchen an der Alz",
      "Burglengenfeld",
      "Burglesum",
      "Burgstaedt",
      "Burgthann",
      "Burladingen",
      "Burscheid",
      "Buttelborn",
      "Butzbach",
      "Buxtehude",
      "Cadolzburg",
      "Calbe",
      "Calw",
      "Castrop-Rauxel",
      "Celle",
      "Cham",
      "Charlottenburg",
      "Charlottenburg-Nord",
      "Chemnitz",
      "Clausthal-Zellerfeld",
      "Cloppenburg",
      "Coburg",
      "Coesfeld",
      "Coswig",
      "Cottbus",
      "Crailsheim",
      "Cremlingen",
      "Crimmitschau",
      "Cuxhaven",
      "Dachau",
      "Dahlem",
      "Damme",
      "Darmstadt",
      "Dassel",
      "Datteln",
      "Deggendorf",
      "Delbrueck",
      "Delitzsch",
      "Delmenhorst",
      "Demmin",
      "Denkendorf",
      "Denzlingen",
      "Dessau",
      "Detmold",
      "Deutz",
      "Dieburg",
      "Diedorf",
      "Diepholz",
      "Dierkow-Neu",
      "Diessen am Ammersee",
      "Dietzenbach",
      "Diez",
      "Dillenburg",
      "Dillingen",
      "Dillingen an der Donau",
      "Dingolfing",
      "Dinkelsbuehl",
      "Dinklage",
      "Dinslaken",
      "Dippoldiswalde",
      "Ditzingen",
      "Doberlug-Kirchhain",
      "Doebeln",
      "Donaueschingen",
      "Donauwoerth",
      "Donzdorf",
      "Dorfen",
      "Dormagen",
      "Dorsten",
      "Dortmund",
      "Dossenheim",
      "Dotzheim",
      "Dreieich",
      "Drensteinfurt",
      "Dresden",
      "Drochtersen",
      "Drolshagen",
      "Duderstadt",
      "Duelmen",
      "Dueren",
      "Duesseldorf",
      "Duesseldorf-Pempelfort",
      "Duisburg",
      "Dulsberg",
      "Durmersheim",
      "Eberbach",
      "Ebersbach an der Fils",
      "Ebersberg",
      "Eberswalde",
      "Eching",
      "Eckernfoerde",
      "Edewecht",
      "Edingen-Neckarhausen",
      "Eggenfelden",
      "Eggenstein-Leopoldshafen",
      "Ehingen",
      "Eichenau",
      "Eichenzell",
      "Eichstaett",
      "Eidelstedt",
      "Eilbek",
      "Eilenburg",
      "Eimsbuettel",
      "Einbeck",
      "Eisenach",
      "Eisenberg",
      "Eisenberg",
      "Eisenhuettenstadt",
      "Eisleben Lutherstadt",
      "Eislingen",
      "Eissendorf",
      "Eitorf",
      "Ellwangen",
      "Elmshorn",
      "Elsdorf",
      "Eltville",
      "Emden",
      "Emmendingen",
      "Emmerich",
      "Emsdetten",
      "Emstek",
      "Endenich",
      "Engelskirchen",
      "Engen",
      "Enger",
      "Eningen unter Achalm",
      "Ennepetal",
      "Ennigerloh",
      "Eppelborn",
      "Eppelheim",
      "Eppendorf",
      "Eppingen",
      "Eppstein",
      "Erbach",
      "Erbach",
      "Erding",
      "Erftstadt",
      "Erfurt",
      "Ergolding",
      "Erkelenz",
      "Erkner",
      "Erkrath",
      "Erlangen",
      "Erlenbach am Main",
      "Erlensee",
      "Erwitte",
      "Eschborn",
      "Eschwege",
      "Eschweiler",
      "Espelkamp",
      "Essen",
      "Essenbach",
      "Esslingen",
      "Ettenheim",
      "Ettlingen",
      "Euskirchen",
      "Eutin",
      "Eving",
      "Falkenhagener Feld",
      "Falkensee",
      "Farmsen-Berne",
      "Fehmarn",
      "Feldkirchen-Westerham",
      "Fellbach",
      "Felsberg",
      "Fennpfuhl",
      "Feucht",
      "Feuchtwangen",
      "Filderstadt",
      "Finsterwalde",
      "Fischeln",
      "Flensburg",
      "Floeha",
      "Floersheim",
      "Forchheim",
      "Forst",
      "Frankenberg",
      "Frankenberg",
      "Frankenthal",
      "Frankfurt (Oder)",
      "Frankfurt am Main",
      "Franzoesisch Buchholz",
      "Frechen",
      "Freiberg",
      "Freiberg am Neckar",
      "Freiburg",
      "Freilassing",
      "Freising",
      "Freital",
      "Freudenberg",
      "Freudenstadt",
      "Friedberg",
      "Friedberg",
      "Friedeburg",
      "Friedenau",
      "Friedland",
      "Friedrichsdorf",
      "Friedrichsfelde",
      "Friedrichshafen",
      "Friedrichshagen",
      "Friedrichshain Bezirk",
      "Friedrichsthal",
      "Friesoythe",
      "Fritzlar",
      "Froendenberg",
      "Frohnau",
      "Fuerstenfeldbruck",
      "Fuerstenwalde",
      "Fuessen",
      "Fuhlsbuettel",
      "Fulda",
      "Fuldatal",
      "Furth",
      "Furth",
      "Gaggenau",
      "Gaildorf",
      "Gaimersheim",
      "Gallus",
      "Ganderkesee",
      "Gangelt",
      "Garbsen",
      "Garching",
      "Gardelegen",
      "Garmisch-Partenkirchen",
      "Garrel",
      "Gartenstadt",
      "Gartringen",
      "Gauting",
      "Geeste",
      "Geesthacht",
      "Gehrden",
      "Geilenkirchen",
      "Geisenheim",
      "Geislingen an der Steige",
      "Geldern",
      "Gelnhausen",
      "Gelsenkirchen",
      "Gemeinde Friedland",
      "Gemuenden am Main",
      "Gengenbach",
      "Genthin",
      "Georgsmarienhuette",
      "Gera",
      "Geretsried",
      "Gerlingen",
      "Germering",
      "Germersheim",
      "Gernsbach",
      "Gernsheim",
      "Gerstetten",
      "Gersthofen",
      "Gescher",
      "Geseke",
      "Gesundbrunnen",
      "Gevelsberg",
      "Giengen an der Brenz",
      "Giessen",
      "Gifhorn",
      "Gilching",
      "Ginsheim-Gustavsburg",
      "Gladbeck",
      "Gladenbach",
      "Glauchau",
      "Glinde",
      "Glueckstadt",
      "Goch",
      "Goeppingen",
      "Goerlitz",
      "Goettingen",
      "Gohlis",
      "Goldbach",
      "Gorbitz",
      "Goslar",
      "Gotha",
      "Gottmadingen",
      "Graben-Neudorf",
      "Grafelfing",
      "Grafing bei Munchen",
      "Grefrath",
      "Greifswald",
      "Greiz",
      "Grenzach-Wyhlen",
      "Greven",
      "Grevenbroich",
      "Grevesmuehlen",
      "Griesheim",
      "Grimma",
      "Grimmen",
      "Grobenzell",
      "Gronau",
      "Gropiusstadt",
      "Gross Flottbek",
      "Gross-Gerau",
      "Gross-Umstadt",
      "Gross-Zimmern",
      "Grossauheim",
      "Grossenhain",
      "Grossenkneten",
      "Grossostheim",
      "Grossraeschen",
      "Grosszschocher",
      "Gruenberg",
      "Gruenstadt",
      "Grunewald",
      "Grunwald",
      "Guben",
      "Guenzburg",
      "Guestrow",
      "Guetersloh",
      "Gummersbach",
      "Gundelfingen",
      "Gunzenhausen",
      "Haan",
      "Haar",
      "Hadamar",
      "Hagen",
      "Hagen",
      "Hagenow",
      "Haiger",
      "Haigerloch",
      "Hakenfelde",
      "Halberstadt",
      "Haldensleben I",
      "Halensee",
      "Halle",
      "Halle (Saale)",
      "Halle Neustadt",
      "Halstenbek",
      "Haltern am See",
      "Halver",
      "Hambuhren",
      "Hamburg",
      "Hamburg-Mitte",
      "Hamburg-Nord",
      "Hameln",
      "Hamm",
      "Hamm",
      "Hammelburg",
      "Hamminkeln",
      "Hanau am Main",
      "Hannover",
      "Hannoversch Muenden",
      "Harburg",
      "Haren",
      "Harrislee",
      "Harsefeld",
      "Harsewinkel",
      "Harsum",
      "Harvestehude",
      "Hasbergen",
      "Haselhorst",
      "Haseluenne",
      "Hassfurt",
      "Hassloch",
      "Hatten",
      "Hattersheim",
      "Hattingen",
      "Hausbruch",
      "Hauzenberg",
      "Havixbeck",
      "Hechingen",
      "Heddesheim",
      "Heide",
      "Heidelberg",
      "Heidenau",
      "Heidenheim an der Brenz",
      "Heilbad Heiligenstadt",
      "Heilbronn",
      "Heiligenhaus",
      "Heiligensee",
      "Heinsberg",
      "Hellersdorf",
      "Helmstedt",
      "Hemer",
      "Hemmingen",
      "Hemsbach",
      "Hennef (Sieg)",
      "Hennigsdorf",
      "Heppenheim an der Bergstrasse",
      "Herborn",
      "Herbrechtingen",
      "Herdecke",
      "Herford",
      "Hermsdorf",
      "Herne",
      "Herrenberg",
      "Hersbruck",
      "Herten",
      "Herxheim am Berg",
      "Herzberg",
      "Herzberg am Harz",
      "Herzogenaurach",
      "Herzogenrath",
      "Hessisch Lichtenau",
      "Hessisch Oldendorf",
      "Hettstedt",
      "Heubach",
      "Heusenstamm",
      "Heusweiler",
      "Hiddenhausen",
      "Hilchenbach",
      "Hildburghausen",
      "Hilden",
      "Hildesheim",
      "Hille",
      "Hilpoltstein",
      "Hilter",
      "Hirschaid",
      "Hochfeld",
      "Hochheim am Main",
      "Hochstadt an der Aisch",
      "Hockenheim",
      "Hoechst im Odenwald",
      "Hoehenberg",
      "Hoerstel",
      "Hoexter",
      "Hof",
      "Hofgeismar",
      "Hofheim am Taunus",
      "Hoheluft-Ost",
      "Hoheluft-West",
      "Hohen Neuendorf",
      "Hohenstein-Ernstthal",
      "Holzgerlingen",
      "Holzkirchen",
      "Holzminden",
      "Holzwickede",
      "Homberg",
      "Homburg",
      "Horb am Neckar",
      "Horn",
      "Hosbach",
      "Hovelhof",
      "Hoyerswerda",
      "Hude",
      "Hueckelhoven",
      "Hueckeswagen",
      "Huellhorst",
      "Huenfeld",
      "Huenxe",
      "Huerth",
      "Humboldtkolonie",
      "Hummelsbuettel",
      "Husum",
      "Ibbenbueren",
      "Idar-Oberstein",
      "Idstein",
      "Illertissen",
      "Illingen",
      "Ilmenau",
      "Ilsede",
      "Immenstadt im Allgau",
      "Ingelheim am Rhein",
      "Ingolstadt",
      "Iserlohn",
      "Isernhagen Farster Bauerschaft",
      "Ismaning",
      "Isny",
      "Isselburg",
      "Issum",
      "Itzehoe",
      "Jena",
      "Jessen",
      "Jever",
      "Johannisthal",
      "Jork",
      "Juchen",
      "Juelich",
      "Jueterbog",
      "Kaarst",
      "Kaiserslautern",
      "Kalk",
      "Kalkar",
      "Kall",
      "Kaltenkirchen",
      "Kamen",
      "Kamenz",
      "Kamp-Lintfort",
      "Karben",
      "Karlsfeld",
      "Karlshorst",
      "Karlsruhe",
      "Karlstadt",
      "Karow",
      "Kassel",
      "Kastel",
      "Kaufbeuren",
      "Kaufungen",
      "Kaulsdorf",
      "Kehl",
      "Kelheim",
      "Kelkheim",
      "Kelsterbach",
      "Kempen",
      "Kempten (Allgaeu)",
      "Kerpen",
      "Kessenich",
      "Ketsch",
      "Kevelaer",
      "Kiel",
      "Kierspe",
      "Kirchhain",
      "Kirchheim bei Muenchen",
      "Kirchheim unter Teck",
      "Kirchhundem",
      "Kirchlengern",
      "Kirchlinteln",
      "Kirchwerder",
      "Kirkel",
      "Kissing",
      "Kitzingen",
      "Kladow",
      "Kleinblittersdorf",
      "Kleinmachnow",
      "Kleinzschocher",
      "Kleve",
      "Klingenstein",
      "Klotzsche",
      "Koblenz",
      "Koeln",
      "Koenigs Wusterhausen",
      "Koenigslutter am Elm",
      "Koenigstein im Taunus",
      "Koenigswinter",
      "Koepenick",
      "Koethen",
      "Kolbermoor",
      "Kolkwitz",
      "Konigsbrunn",
      "Konstanz",
      "Konz",
      "Korb",
      "Korbach",
      "Korntal",
      "Kornwestheim",
      "Korschenbroich",
      "Kraichtal",
      "Kranichstein",
      "Krefeld",
      "Kreuzau",
      "Kreuzberg",
      "Kreuztal",
      "Kriftel",
      "Kroepeliner-Tor-Vorstadt",
      "Kronach",
      "Kronberg",
      "Kronberg Tal",
      "Kronshagen",
      "Krumbach",
      "Kuenzelsau",
      "Kulmbach",
      "Kummersbruck",
      "Kunzell",
      "Kurten",
      "Kyritz",
      "Laatzen",
      "Ladenburg",
      "Lage",
      "Lahnstein",
      "Lahr",
      "Laichingen",
      "Lampertheim",
      "Landau an der Isar",
      "Landau in der Pfalz",
      "Landsberg am Lech",
      "Landshut",
      "Langelsheim",
      "Langen",
      "Langen",
      "Langenau",
      "Langenfeld",
      "Langenhagen",
      "Langenhorn",
      "Langenselbold",
      "Langenzenn",
      "Langerwehe",
      "Langgons",
      "Langwedel",
      "Lankow",
      "Lankwitz",
      "Lappersdorf",
      "Laubach",
      "Lauchhammer",
      "Lauda-Konigshofen",
      "Lauenburg",
      "Lauf an der Pegnitz",
      "Lauffen am Neckar",
      "Lauingen",
      "Laupheim",
      "Lauterbach",
      "Lebach",
      "Leer",
      "Lehre",
      "Lehrte",
      "Leichlingen",
      "Leimen",
      "Leinefelde-Worbis",
      "Leinfelden-Echterdingen",
      "Leingarten",
      "Leipzig",
      "Lemgo",
      "Lengede",
      "Lengerich",
      "Lennestadt",
      "Leonberg",
      "Leopoldshohe",
      "Leuben",
      "Leutenbach",
      "Leutkirch",
      "Leverkusen",
      "Lich",
      "Lichtenau",
      "Lichtenberg",
      "Lichtenfels",
      "Lichtenrade",
      "Lichtenstein",
      "Lichterfelde",
      "Lilienthal",
      "Limbach-Oberfrohna",
      "Limburg an der Lahn",
      "Limburgerhof",
      "Lindau",
      "Lindlar",
      "Lingen",
      "Linkenheim-Hochstetten",
      "Linnich",
      "Lippstadt",
      "Loebau",
      "Loebtau",
      "Loehne",
      "Loeningen",
      "Loerrach",
      "Loessnitz",
      "Lohfelden",
      "Lohmar",
      "Lohne",
      "Lohr am Main",
      "Lollar",
      "Lorch",
      "Lorsch",
      "Loschwitz",
      "Losheim",
      "Lotte",
      "Loxstedt",
      "Lubin",
      "Lubnjow",
      "Luckau",
      "Luckenwalde",
      "Ludwigsburg",
      "Ludwigsfelde",
      "Ludwigshafen am Rhein",
      "Ludwigslust",
      "Luebbecke",
      "Luebeck",
      "Luedenscheid",
      "Luedinghausen",
      "Luegde",
      "Lueneburg",
      "Luenen",
      "Lurup",
      "Maerkisches Viertel",
      "Magdeburg",
      "Mahlsdorf",
      "Mainburg",
      "Maintal",
      "Mainz",
      "Maisach",
      "Malente",
      "Malsch",
      "Manching",
      "Mannheim",
      "Marbach am Neckar",
      "Marburg an der Lahn",
      "Marienberg",
      "Mariendorf",
      "Marienfelde",
      "Marienheide",
      "Marienthal",
      "Markdorf",
      "Markgroningen",
      "Markkleeberg",
      "Markranstaedt",
      "Markt Schwaben",
      "Marktheidenfeld",
      "Marktoberdorf",
      "Marktredwitz",
      "Marl",
      "Marpingen",
      "Marsberg",
      "Marzahn",
      "Maxhutte-Haidhof",
      "Mayen",
      "Mechernich",
      "Meckenbeuren",
      "Meckenheim",
      "Meerane",
      "Meerbusch",
      "Meiderich",
      "Meinerzhagen",
      "Meiningen",
      "Meissen",
      "Meitingen",
      "Melle",
      "Melsungen",
      "Memmingen",
      "Menden",
      "Mengen",
      "Mengenich",
      "Meppen",
      "Merchweiler",
      "Mering",
      "Merseburg",
      "Merzig",
      "Meschede",
      "Messstetten",
      "Mettingen",
      "Mettmann",
      "Metzingen",
      "Michelstadt",
      "Michendorf",
      "Mickten",
      "Miesbach",
      "Mindelheim",
      "Minden",
      "Mitte",
      "Mittegrossefehn",
      "Mittweida",
      "Moabit",
      "Moelln",
      "Moenchengladbach",
      "Moerfelden-Walldorf",
      "Moers",
      "Moglingen",
      "Mohnesee",
      "Mombris",
      "Monheim am Rhein",
      "Monschau",
      "Montabaur",
      "Moosburg",
      "Morbach",
      "Morlenbach",
      "Morsbach",
      "Mosbach",
      "Mossingen",
      "Much",
      "Muehldorf",
      "Muehlhausen",
      "Muehlheim am Main",
      "Muelheim",
      "Muelheim",
      "Muelheim-Kaerlich",
      "Muenster",
      "Muhlacker",
      "Mullheim",
      "Mulsen",
      "Munchberg",
      "Munich",
      "Munsingen",
      "Munster",
      "Munster",
      "Murnau am Staffelsee",
      "Murrhardt",
      "Mutterstadt",
      "Nagold",
      "Nauen",
      "Naumburg",
      "Neckargemund",
      "Neckarsulm",
      "Netphen",
      "Nettetal",
      "Neu Wulmstorf",
      "Neu-Anspach",
      "Neu-Hohenschoenhausen",
      "Neu-Isenburg",
      "Neu-Ulm",
      "Neubiberg",
      "Neubrandenburg",
      "Neubrueck",
      "Neuburg an der Donau",
      "Neue Neustadt",
      "Neuehrenfeld",
      "Neuenburg am Rhein",
      "Neuenhagen",
      "Neuenkirchen",
      "Neuenrade",
      "Neufahrn bei Freising",
      "Neugraben-Fischbek",
      "Neuhausen auf den Fildern",
      "Neuhof",
      "Neukoelln",
      "Neumarkt in der Oberpfalz",
      "Neumuenster",
      "Neunkirchen",
      "Neunkirchen",
      "Neuruppin",
      "Neuss",
      "Neustadt",
      "Neustadt am Ruebenberge",
      "Neustadt an der Aisch",
      "Neustadt an der Donau",
      "Neustadt an der Weinstrasse",
      "Neustadt bei Coburg",
      "Neustadt in Holstein",
      "Neustadt in Sachsen",
      "Neustadt/Nord",
      "Neustadt/Sued",
      "Neustrelitz",
      "Neutraubling",
      "Neuwied",
      "Nidda",
      "Nidderau",
      "Nideggen",
      "Nieder-Ingelheim",
      "Niederkassel",
      "Niederkruchten",
      "Niedernhausen",
      "Niederrad",
      "Niederschoeneweide",
      "Niederschoenhausen",
      "Niederzier",
      "Niefern-Oschelbronn",
      "Nienburg",
      "Niendorf",
      "Niesky",
      "Nikolassee",
      "Nippes",
      "Noerdlingen",
      "Nohfelden",
      "Norden",
      "Nordenham",
      "Norderstedt",
      "Nordhausen",
      "Nordhorn",
      "Nordkirchen",
      "Nordstemmen",
      "Northeim",
      "Norvenich",
      "Nottuln",
      "Nuembrecht",
      "Nuernberg",
      "Nurtingen",
      "Nussloch",
      "Ober-Ramstadt",
      "Oberasbach",
      "Oberderdingen",
      "Oberhaching",
      "Oberhausen",
      "Oberkirch",
      "Oberndorf",
      "Obernkirchen",
      "Oberschleissheim",
      "Oberschoeneweide",
      "Obertshausen",
      "Oberursel",
      "Oberzent",
      "Ochsenfurt",
      "Ochtrup",
      "Odenthal",
      "Oelde",
      "Oelsnitz",
      "Oelsnitz",
      "Oer-Erkenschwick",
      "Oerlinghausen",
      "Offenbach",
      "Offenburg",
      "Oftersheim",
      "Ohlsdorf",
      "Ohringen",
      "Olbernhau",
      "Olching",
      "Oldenburg",
      "Olfen",
      "Olpe",
      "Olsberg",
      "Opladen",
      "Oppum",
      "Oranienburg",
      "Oschatz",
      "Oschersleben",
      "Osdorf",
      "Osnabrueck",
      "Ossendorf",
      "Ostbevern",
      "Osterhofen",
      "Osterholz-Scharmbeck",
      "Ostfildern",
      "Ostheim",
      "Ostrhauderfehn",
      "Ostringen",
      "Othmarschen",
      "Ottendorf-Okrilla",
      "Ottensen",
      "Ottersberg",
      "Ottobrunn bei Muenchen",
      "Ottweiler",
      "Overath",
      "Oyten",
      "Paderborn",
      "Pankow",
      "Papenburg",
      "Parchim",
      "Pasewalk",
      "Pasing",
      "Passau",
      "Pattensen",
      "Pegnitz",
      "Peine",
      "Peissenberg",
      "Peiting",
      "Penig",
      "Penzberg",
      "Perleberg",
      "Petershagen",
      "Petershagen",
      "Petershausen-West",
      "Pfaffenhofen an der Ilm",
      "Pfarrkirchen",
      "Pforzheim",
      "Pfullendorf",
      "Pfullingen",
      "Pfungstadt",
      "Philippsburg",
      "Pinneberg",
      "Pirmasens",
      "Pirna",
      "Plaenterwald",
      "Planegg",
      "Plattling",
      "Plauen",
      "Plettenberg",
      "Plochingen",
      "Ploen",
      "Pocking",
      "Poessneck",
      "Poing",
      "Poll",
      "Poppenbuettel",
      "Porta Westfalica",
      "Porz am Rhein",
      "Potsdam",
      "Preetz",
      "Premnitz",
      "Prenzlau",
      "Prenzlauer Berg Bezirk",
      "Preussisch Oldendorf",
      "Prien am Chiemsee",
      "Prinzenpark",
      "Pritzwalk",
      "Puchheim",
      "Puettlingen",
      "Pulheim",
      "Quakenbrueck",
      "Quedlinburg",
      "Quickborn",
      "Quierschied",
      "Radeberg",
      "Radebeul",
      "Radevormwald",
      "Radolfzell",
      "Raesfeld",
      "Rahden",
      "Rahlstedt",
      "Rastatt",
      "Rastede",
      "Ratekau",
      "Rath",
      "Rathenow",
      "Ratingen",
      "Ratzeburg",
      "Raubling",
      "Raunheim",
      "Ravensburg",
      "Recke",
      "Recklinghausen",
      "Rees",
      "Regen",
      "Regensburg",
      "Regenstauf",
      "Rehau",
      "Rehburg-Loccum",
      "Reichenbach/Vogtland",
      "Reinbek",
      "Reinheim",
      "Reinickendorf",
      "Reiskirchen",
      "Rellingen",
      "Remagen",
      "Remscheid",
      "Remseck am Neckar",
      "Rendsburg",
      "Renningen",
      "Reutlingen",
      "Rheda-Wiedenbrueck",
      "Rhede",
      "Rheinau",
      "Rheinau",
      "Rheinbach",
      "Rheinberg",
      "Rheine",
      "Rheinfelden",
      "Rheinhausen",
      "Rheinstetten",
      "Ribnitz-Damgarten",
      "Riedlingen",
      "Riedstadt",
      "Riegelsberg",
      "Riehl",
      "Rielasingen-Worblingen",
      "Riesa",
      "Rietberg",
      "Rinteln",
      "Rissen",
      "Ritterhude",
      "Rodenbach",
      "Rodenkirchen",
      "Rodental",
      "Rodgau",
      "Roding",
      "Rodinghausen",
      "Roesrath",
      "Rommerskirchen",
      "Ronnenberg",
      "Rosbach vor der Hoehe",
      "Rosdorf",
      "Rosenheim",
      "Rossdorf",
      "Rosslau",
      "Rosstal",
      "Rostock",
      "Rotenburg",
      "Rotenburg an der Fulda",
      "Roth",
      "Rothenbach an der Pegnitz",
      "Rothenburg ob der Tauber",
      "Rotherbaum",
      "Rottenburg",
      "Rottweil",
      "Rudersberg",
      "Rudolstadt",
      "Rudow",
      "Ruesselsheim",
      "Ruethen",
      "Rummelsburg",
      "Ruppichteroth",
      "Rutesheim",
      "Saalfeld",
      "Saarbruecken",
      "Saarlouis",
      "Saarwellingen",
      "Sachsenheim",
      "Salem",
      "Salzgitter",
      "Salzhemmendorf",
      "Salzkotten",
      "Salzwedel",
      "Sandhausen",
      "Sangerhausen",
      "Sankt Augustin",
      "Sankt Georgen im Schwarzwald",
      "Sankt Ingbert",
      "Sankt Leon-Rot",
      "Sankt Wendel",
      "Sarstedt",
      "Sasel",
      "Sassenberg",
      "Sassenburg",
      "Sassnitz",
      "Schalksmuehle",
      "Scharbeutz",
      "Scheessel",
      "Schermbeck",
      "Schierstein",
      "Schiffdorf",
      "Schifferstadt",
      "Schiffweiler",
      "Schkeuditz",
      "Schleiden",
      "Schleswig",
      "Schlitz",
      "Schluechtern",
      "Schmalkalden",
      "Schmallenberg",
      "Schmargendorf",
      "Schmelz",
      "Schmoelln",
      "Schneeberg",
      "Schnelsen",
      "Schneverdingen",
      "Schoenebeck",
      "Schoeneberg",
      "Schoenefeld",
      "Schoeneiche",
      "Schoeningen",
      "Schonaich",
      "Schongau",
      "Schopfheim",
      "Schorndorf",
      "Schortens",
      "Schotten",
      "Schramberg",
      "Schriesheim",
      "Schrobenhausen",
      "Schuettorf",
      "Schwabach",
      "Schwabmunchen",
      "Schwaebisch Gmuend",
      "Schwaebisch Hall",
      "Schwaigern",
      "Schwalbach",
      "Schwalbach am Taunus",
      "Schwalmstadt",
      "Schwalmtal",
      "Schwandorf in Bayern",
      "Schwanewede",
      "Schwarzenbach an der Saale",
      "Schwarzenbek",
      "Schwarzenberg",
      "Schwedt (Oder)",
      "Schweinfurt",
      "Schwelm",
      "Schwerin",
      "Schwerte",
      "Schwetzingen",
      "Schwieberdingen",
      "Seeheim-Jugenheim",
      "Seelze",
      "Seesen",
      "Seevetal",
      "Sehnde",
      "Selb",
      "Seligenstadt",
      "Selm",
      "Senden",
      "Senden",
      "Sendenhorst",
      "Senftenberg",
      "Siegburg",
      "Siegen",
      "Siemensstadt",
      "Sigmaringen",
      "Simmerath",
      "Sindelfingen",
      "Singen",
      "Sinsheim",
      "Sinzheim",
      "Sinzig",
      "Soemmerda",
      "Soest",
      "Solingen",
      "Solms",
      "Soltau",
      "Sondershausen",
      "Sonneberg",
      "Sonthofen",
      "Spaichingen",
      "Spandau",
      "Spenge",
      "Speyer",
      "Spiesen-Elversberg",
      "Springe",
      "Sprockhoevel",
      "St. Georg",
      "St. Pauli",
      "Staaken",
      "Stade",
      "Stadtallendorf",
      "Stadtbergen",
      "Stadthagen",
      "Stadtlohn",
      "Stahnsdorf",
      "Starnberg",
      "Stassfurt",
      "Steglitz Bezirk",
      "Steilshoop",
      "Stein",
      "Steinau an der Strasse",
      "Steinbach am Taunus",
      "Steinen",
      "Steinfurt",
      "Steinhagen",
      "Steinheim",
      "Steinheim an der Murr",
      "Stelle",
      "Stellingen",
      "Stendal",
      "Stockach",
      "Stockelsdorf",
      "Stolberg",
      "Stollberg",
      "Straelen",
      "Stralsund",
      "Straubing",
      "Strausberg",
      "Stuhr",
      "Stuttgart",
      "Stuttgart Feuerbach",
      "Stuttgart Muehlhausen",
      "Stuttgart-Ost",
      "Suhl",
      "Sulingen",
      "Sulz am Neckar",
      "Sulzbach",
      "Sulzbach-Rosenberg",
      "Sundern",
      "Sussen",
      "Syke",
      "Tamm",
      "Tauberbischofsheim",
      "Taucha",
      "Taufkirchen",
      "Taunusstein",
      "Tegel",
      "Telgte",
      "Teltow",
      "Tempelhof",
      "Templin",
      "Teningen",
      "Tettnang",
      "Thale",
      "Tholey",
      "Tiergarten",
      "Titisee-Neustadt",
      "Toenisvorst",
      "Torgau",
      "Torgelow",
      "Tornesch",
      "Tostedt",
      "Traunreut",
      "Traunstein",
      "Travemuende",
      "Trebur",
      "Treuchtlingen",
      "Trier",
      "Troisdorf",
      "Trossingen",
      "Trostberg an der Alz",
      "Tuebingen",
      "Tuttlingen",
      "Twistringen",
      "UEbach-Palenberg",
      "Uberherrn",
      "Uberlingen",
      "Ubstadt-Weiher",
      "Ueckermuende",
      "Uelzen",
      "Uerdingen",
      "Uetersen",
      "Uhingen",
      "Ulm",
      "Unna",
      "Unterfoehring",
      "Unterhaching",
      "Unterkrozingen",
      "Unterschleissheim",
      "Usingen",
      "Uslar",
      "Vaihingen an der Enz",
      "Varel",
      "Vaterstetten",
      "Vechelde",
      "Vechta",
      "Vegesack",
      "Veitshochheim",
      "Velbert",
      "Velen",
      "Vellmar",
      "Velten",
      "Verden",
      "Verl",
      "Versmold",
      "Vienenburg",
      "Viernheim",
      "Viersen",
      "Villingen-Schwenningen",
      "Vilsbiburg",
      "Vilshofen",
      "Visselhoevede",
      "Vlotho",
      "Voelklingen",
      "Voerde",
      "Vohringen",
      "Volksdorf",
      "Vreden",
      "Wachtberg",
      "Wadern",
      "Wadersloh",
      "Wadgassen",
      "Waechtersbach",
      "Waghausel",
      "Waiblingen",
      "Waidmannslust",
      "Wald-Michelbach",
      "Waldbroel",
      "Waldkirch",
      "Waldkirchen",
      "Waldkraiburg",
      "Waldshut-Tiengen",
      "Walldorf",
      "Walldurn",
      "Wallenhorst",
      "Walsrode",
      "Waltershausen",
      "Waltrop",
      "Wandlitz",
      "Wandsbek",
      "Wangen",
      "Wannsee",
      "Warburg",
      "Wardenburg",
      "Waren",
      "Warendorf",
      "Warstein",
      "Wassenberg",
      "Wasserburg am Inn",
      "Wedding",
      "Wedel",
      "Weener",
      "Weeze",
      "Wegberg",
      "Wehr",
      "Weiden",
      "Weil am Rhein",
      "Weil der Stadt",
      "Weil im Schonbuch",
      "Weilburg",
      "Weilerswist",
      "Weilheim",
      "Weimar",
      "Weinbohla",
      "Weingarten",
      "Weinheim",
      "Weinsberg",
      "Weinstadt-Endersbach",
      "Weissenburg in Bayern",
      "Weissenfels",
      "Weissenhorn",
      "Weissensee",
      "Weisswasser",
      "Weiterstadt",
      "Wellingsbuettel",
      "Welver",
      "Welzheim",
      "Wendeburg",
      "Wendelstein",
      "Wenden",
      "Wendlingen am Neckar",
      "Wennigsen",
      "Wentorf bei Hamburg",
      "Werdau",
      "Werder",
      "Werdohl",
      "Werl",
      "Wermelskirchen",
      "Wernau",
      "Werne",
      "Werneck",
      "Wernigerode",
      "Wersten",
      "Wertheim",
      "Werther",
      "Wesel",
      "Wesseling",
      "Westend",
      "Westerkappeln",
      "Westerland",
      "Westerstede",
      "Weststadt",
      "Wetter",
      "Wetzlar",
      "Wickede",
      "Wiefelstede",
      "Wiehl",
      "Wiesbaden",
      "Wiesloch",
      "Wiesmoor",
      "Wietmarschen",
      "Wildberg",
      "Wildeshausen",
      "Wilhelmsburg",
      "Wilhelmshaven",
      "Wilhelmstadt",
      "Wilkau-Hasslau",
      "Willich",
      "Wilmersdorf",
      "Wilnsdorf",
      "Wilsdruff",
      "Winnenden",
      "Winsen",
      "Winsen",
      "Winterberg",
      "Winterhude",
      "Wipperfuerth",
      "Wismar",
      "Witten",
      "Wittenau",
      "Wittenberg",
      "Wittenberge",
      "Wittingen",
      "Wittlich",
      "Wittmund",
      "Wittstock",
      "Witzenhausen",
      "Woerth am Rhein",
      "Wolfen",
      "Wolfenbuettel",
      "Wolfhagen",
      "Wolfratshausen",
      "Wolfsburg",
      "Wolgast",
      "Wolmirstedt",
      "Wolnzach",
      "Worms",
      "Wuelfrath",
      "Wuerselen",
      "Wuerzburg",
      "Wunsiedel",
      "Wunstorf",
      "Wuppertal",
      "Wurzen",
      "Xanten",
      "Zehdenick",
      "Zehlendorf",
      "Zeitz",
      "Zella-Mehlis",
      "Zerbst",
      "Zetel",
      "Zeulenroda",
      "Zeuthen",
      "Zeven",
      "Zirndorf",
      "Zittau",
      "Zossen",
      "Zschopau",
      "Zuelpich",
      "Zweibruecken",
      "Zwickau",
      "Zwiesel",
      "Zwoenitz",
    ],
  },
  Ghana: {
    code: "GH",
    cities: [
      "Aburi",
      "Accra",
      "Adenta",
      "Aflao",
      "Agogo",
      "Ahwiaa",
      "Akatsi",
      "Akim Oda",
      "Akropong",
      "Akwatia",
      "Amanfrom",
      "Anloga",
      "Apam",
      "Asamankese",
      "Ashaiman",
      "Assin Foso",
      "Atebubu",
      "Atsiaman",
      "Awoshi",
      "Bawku",
      "Bechem",
      "Begoro",
      "Bekwai",
      "Berekum",
      "Bibiani",
      "Bimbila",
      "Bolgatanga",
      "Buduburam",
      "Cape Coast",
      "Dambai",
      "Damongo",
      "Domaa-Ahenkro",
      "Dome",
      "Duayaw-Nkwanta",
      "Dunkwa",
      "Dzodze",
      "Effiduase",
      "Ejura",
      "Elmina",
      "Esim",
      "Foso",
      "Gbawe",
      "Goaso",
      "Ho",
      "Hohoe",
      "Japekrom",
      "Kade",
      "Kasoa",
      "Keta",
      "Kete Krachi",
      "Kintampo",
      "Koforidua",
      "Konongo",
      "Kpandu",
      "Kumasi",
      "Lashibi",
      "Mampong",
      "Mandela",
      "Mankesim",
      "Medina Estates",
      "Mim",
      "Mumford",
      "Nalerigu",
      "Navrongo",
      "New Achimota",
      "New Tafo",
      "Nkawkaw",
      "Nkoranza",
      "Nsawam",
      "Nsoatre",
      "Nungua",
      "Obuase",
      "Prestea",
      "Sakumona",
      "Salaga",
      "Saltpond",
      "Savelugu",
      "Sefwi",
      "Sekondi",
      "Sekondi-Takoradi",
      "Shama Junction",
      "Suhum",
      "Sunyani",
      "Swedru",
      "Tafo",
      "Taifa",
      "Takoradi",
      "Tamale",
      "Tarkwa",
      "Techiman",
      "Tema",
      "Tema New Town",
      "Teshi Old Town",
      "Wa",
      "Wankyi",
      "Winneba",
      "Yegyi",
      "Yendi",
    ],
  },
  Gibraltar: {
    code: "GI",
    cities: ["Gibraltar"],
  },
  Greece: {
    code: "GR",
    cities: [
      "Acharnes",
      "Agia Paraskevi",
      "Agia Varvara",
      "Agioi Anargyroi",
      "Agios Athanasios",
      "Agios Dimitrios",
      "Agios Ioannis Rentis",
      "Agios Nikolaos",
      "Agrinio",
      "Aigaleo",
      "Aigio",
      "Alexandreia",
      "Alexandroupoli",
      "Alimos",
      "Amaliada",
      "Ano Liosia",
      "Argos",
      "Argyroupoli",
      "Arta",
      "Artemida",
      "Aspropyrgos",
      "Athens",
      "Chaidari",
      "Chalkida",
      "Chania",
      "Chios",
      "Cholargos",
      "Corfu",
      "Dhafni",
      "Diavata",
      "Drama",
      "Drapetsona",
      "Edessa",
      "Efkarpia",
      "Elefsina",
      "Eleftherio - Kordelio",
      "Elliniko",
      "Ermoupolis",
      "Evosmos",
      "Farsala",
      "Florina",
      "Galatsi",
      "Gazi",
      "Gerakas",
      "Giannitsa",
      "Glyfada",
      "Grevena",
      "Ialysos",
      "Ierapetra",
      "Ilion",
      "Ilioupoli",
      "Ioannina",
      "Irakleio",
      "Irakleion",
      "Kaisariani",
      "Kalamaria",
      "Kalamata",
      "Kallithea",
      "Kalymnos",
      "Kalyvia Thorikou",
      "Kamateron",
      "Karditsa",
      "Kastoria",
      "Katerini",
      "Kavala",
      "Keratsini",
      "Khalandrion",
      "Kifisia",
      "Kilkis",
      "Komotini",
      "Korinthos",
      "Koropi",
      "Korydallos",
      "Kos",
      "Kozani",
      "Lamia",
      "Larisa",
      "Livadeia",
      "Loutraki",
      "Mandra",
      "Marousi",
      "Megara",
      "Melissia",
      "Menemeni",
      "Mesolongi",
      "Metamorfosi",
      "Moskhaton",
      "Mytilene",
      "Nafpaktos",
      "Nafplio",
      "Naousa",
      "Nea Alikarnassos",
      "Nea Erythraia",
      "Nea Filadelfeia",
      "Nea Ionia",
      "Nea Ionia",
      "Nea Makri",
      "Nea Smyrni",
      "Neapoli",
      "Neo Psychiko",
      "Nikaia",
      "Oraiokastro",
      "Orestiada",
      "Paiania",
      "Palaio Faliro",
      "Pallini",
      "Panorama",
      "Papagou",
      "Patra",
      "Pefka",
      "Pefki",
      "Peraia",
      "Perama",
      "Peristeri",
      "Petroupolis",
      "Piraeus",
      "Polichni",
      "Preveza",
      "Ptolemaida",
      "Pylaia",
      "Pyrgos",
      "Rafina",
      "Rethymno",
      "Rodos",
      "Salamina",
      "Serres",
      "Sparta",
      "Stavroupoli",
      "Sykies",
      "Tavros",
      "Thermi",
      "Thessaloniki",
      "Thivai",
      "Trikala",
      "Tripoli",
      "Tyrnavos",
      "Vari",
      "Veroia",
      "Volos",
      "Voula",
      "Vrilissia",
      "Vyronas",
      "Xanthi",
      "Ymittos",
      "Zakynthos",
      "Zografos",
    ],
  },
  Greenland: {
    code: "GL",
    cities: ["Nuuk"],
  },
  Guadeloupe: {
    code: "GP",
    cities: [
      "Baie-Mahault",
      "Basse-Terre",
      "Capesterre-Belle-Eau",
      "Lamentin",
      "Le Gosier",
      "Le Moule",
      "Les Abymes",
      "Petit-Bourg",
      "Pointe-a-Pitre",
      "Saint-Claude",
      "Saint-Francois",
      "Sainte-Anne",
      "Sainte-Rose",
    ],
  },
  Guam: {
    code: "GU",
    cities: [
      "Dededo Village",
      "Mangilao Village",
      "Tamuning",
      "Tamuning-Tumon-Harmon Village",
      "Yigo Village",
    ],
  },
  Guatemala: {
    code: "GT",
    cities: [
      "Acatenango",
      "Almolonga",
      "Alotenango",
      "Amatitlan",
      "Antigua Guatemala",
      "Asuncion Mita",
      "Atescatempa",
      "Barberena",
      "Barillas",
      "Cabrican",
      "Cantel",
      "Chajul",
      "Chicacao",
      "Chichicastenango",
      "Chimaltenango",
      "Chinautla",
      "Chiquimula",
      "Chiquimulilla",
      "Chisec",
      "Ciudad Tecun Uman",
      "Ciudad Vieja",
      "Coatepeque",
      "Coban",
      "Colomba",
      "Comalapa",
      "Comitancillo",
      "Cubulco",
      "Cuilapa",
      "Cuyotenango",
      "Dolores",
      "El Asintal",
      "El Estor",
      "El Palmar",
      "El Quetzal",
      "El Tejar",
      "Escuintla",
      "Esquipulas",
      "Flores",
      "Flores Costa Cuca",
      "Fraijanes",
      "Gualan",
      "Guastatoya",
      "Guatemala City",
      "Guazacapan",
      "Huehuetenango",
      "Jacaltenango",
      "Jalapa",
      "Jalpatagua",
      "Jocotenango",
      "Joyabaj",
      "Jutiapa",
      "La Democracia",
      "La Esperanza",
      "La Gomera",
      "Livingston",
      "Magdalena Milpas Altas",
      "Malacatan",
      "Masagua",
      "Mazatenango",
      "Melchor de Mencos",
      "Mixco",
      "Momostenango",
      "Monjas",
      "Morales",
      "Nahuala",
      "Nebaj",
      "Nueva Concepcion",
      "Nueva Santa Rosa",
      "Nuevo San Carlos",
      "Oratorio",
      "Ostuncalco",
      "Palencia",
      "Palin",
      "Panajachel",
      "Panzos",
      "Pastores",
      "Patulul",
      "Patzicia",
      "Patzun",
      "Petapa",
      "Poptun",
      "Puerto Barrios",
      "Puerto San Jose",
      "Quetzaltenango",
      "Rabinal",
      "Retalhuleu",
      "Sacapulas",
      "Salama",
      "Salcaja",
      "San Agustin Acasaguastlan",
      "San Andres Itzapa",
      "San Andres Xecul",
      "San Antonio Suchitepequez",
      "San Benito",
      "San Cristobal Verapaz",
      "San Felipe",
      "San Francisco El Alto",
      "San Francisco Zapotitlan",
      "San Jose Pinula",
      "San Juan Chamelco",
      "San Juan Cotzal",
      "San Juan Sacatepequez",
      "San Lucas Sacatepequez",
      "San Lucas Toliman",
      "San Luis Jilotepeque",
      "San Marcos",
      "San Mateo Ixtatan",
      "San Miguel Chicaj",
      "San Pablo",
      "San Pablo Jocopilas",
      "San Pedro Ayampuc",
      "San Pedro Carcha",
      "San Pedro Sacatepequez",
      "San Pedro Sacatepequez",
      "San Sebastian",
      "Sanarate",
      "Santa Barbara",
      "Santa Catarina Ixtahuacan",
      "Santa Catarina Mita",
      "Santa Catarina Pinula",
      "Santa Cruz del Quiche",
      "Santa Lucia Cotzumalguapa",
      "Santa Lucia Milpas Altas",
      "Santa Maria de Jesus",
      "Santiago Atitlan",
      "Santiago Sacatepequez",
      "Senahu",
      "Solola",
      "Soloma",
      "Sumpango",
      "Tecpan Guatemala",
      "Tiquisate",
      "Totonicapan",
      "Villa Canales",
      "Villa Nueva",
      "Yepocapa",
      "Zacapa",
      "Zaragoza",
      "Zunil",
    ],
  },
  Guernsey: {
    code: "GG",
    cities: ["Saint Peter Port"],
  },
  Guinea: {
    code: "GN",
    cities: [
      "Beyla",
      "Boffa",
      "Boke",
      "Camayenne",
      "Conakry",
      "Coyah",
      "Dabola",
      "Dinguiraye",
      "Dubreka",
      "Faranah",
      "Forecariah",
      "Fria",
      "Gueckedou",
      "Kamsar",
      "Kankan",
      "Kerouane",
      "Kindia",
      "Kintinian",
      "Kissidougou",
      "Koundara",
      "Kouroussa",
      "Labe",
      "Lola",
      "Macenta",
      "Mamou",
      "Mandiana",
      "Maneah",
      "Moussadou",
      "Nzerekore",
      "Pita",
      "Sangaredi",
      "Siguiri",
      "Telimele",
      "Tomba kanssa",
      "Tondon",
      "Tougue",
    ],
  },
  "Guinea-Bissau": {
    code: "GW",
    cities: [
      "Bafata",
      "Bissau",
      "Bissora",
      "Bolama",
      "Bula",
      "Cacheu",
      "Canchungo",
      "Farim",
      "Gabu",
    ],
  },
  Guyana: {
    code: "GY",
    cities: ["Anna Regina", "Bartica", "Georgetown", "Linden", "New Amsterdam"],
  },
  Haiti: {
    code: "HT",
    cities: [
      "Cap-Haitien",
      "Carrefour",
      "Croix-des-Bouquets",
      "Delmas 73",
      "Desarmes",
      "Dessalines",
      "Fond Parisien",
      "Fort Liberte",
      "Gonaives",
      "Grangwav",
      "Gressier",
      "Hinche",
      "Jacmel",
      "Jeremie",
      "Kenscoff",
      "Lenbe",
      "Leogane",
      "Les Cayes",
      "Miragoane",
      "Ouanaminthe",
      "Petionville",
      "Port-au-Prince",
      "Port-de-Paix",
      "Saint-Louis du Nord",
      "Saint-Marc",
      "Saint-Michel de l'Atalaye",
      "Saint-Raphael",
      "Thomazeau",
      "Ti Port-de-Paix",
      "Tigwav",
      "Trou du Nord",
      "Verrettes",
    ],
  },
  Honduras: {
    code: "HN",
    cities: [
      "Choloma",
      "Ciudad Choluteca",
      "Cofradia",
      "Comayagua",
      "Danli",
      "El Paraiso",
      "El Progreso",
      "Guaimaca",
      "Intibuca",
      "Juticalpa",
      "La Ceiba",
      "La Entrada",
      "La Lima",
      "La Paz",
      "Marcala",
      "Morazan",
      "Nacaome",
      "Olanchito",
      "Potrerillos",
      "Puerto Cortez",
      "San Lorenzo",
      "San Pedro Sula",
      "Santa Barbara",
      "Santa Cruz de Yojoa",
      "Santa Rita",
      "Santa Rosa de Copan",
      "Siguatepeque",
      "Talanga",
      "Tegucigalpa",
      "Tela",
      "Tocoa",
      "Villanueva",
      "Yoro",
    ],
  },
  "Hong Kong, China": {
    code: "HK",
    cities: [
      "Aberdeen",
      "Aldrich Bay",
      "Ap Lei Chau Estate",
      "Braemar Hill",
      "Butterfly",
      "Causeway Bay",
      "Central",
      "Charming Garden",
      "Chek Chue",
      "Cheung Chau",
      "Cheung Sha Wan",
      "Choi Hung",
      "Choi Hung Estate",
      "Choi Wan",
      "Choi Wan Estate (I & II)",
      "Chuk Yuen",
      "Chuk Yuen North Estate",
      "Chuk Yuen South Estate",
      "City Garden",
      "City One",
      "Discovery Bay",
      "Discovery Park",
      "Fairview Park",
      "Fanling",
      "Fo Tan",
      "Fortress Hill",
      "Fu Cheong Estate",
      "Fuk Loi",
      "Fung Tak Estate",
      "Ha Tsuen",
      "Hang Hau",
      "Happy Valley",
      "Heng Fa Chuen",
      "Hing Wah Estate (I & II)",
      "Ho Man Tin",
      "Ho Man Tin Estate",
      "Hoi Lai Estate",
      "Hok Yuen",
      "Hong Kong",
      "Hong Lok Yuen",
      "Hung Hom",
      "Hung Shui Kiu",
      "Jardine's Lookout",
      "Jordan",
      "Kadoorie",
      "Kai Ching Estate",
      "Kai Tak",
      "Kam Tin",
      "Kam Ying",
      "Kau Wa Keng",
      "Keng Hau",
      "Kornhill",
      "Kowloon",
      "Kowloon Bay",
      "Kowloon Tong",
      "Kwai Chung",
      "Kwong Yuen",
      "Kwun Tong",
      "LOHAS Park",
      "Laguna City",
      "Laguna Verde",
      "Laguna Verde (Estate)",
      "Lai Tsui Court",
      "Lam Tin",
      "Lee On",
      "Lei Cheng Uk Estate",
      "Lei Tung",
      "Lei Tung Estate",
      "Lok Fu",
      "Lower Wong Tai Sin Estate (I & II)",
      "Luk Yeung",
      "Ma On Shan",
      "Ma Tau Kok",
      "Ma Tau Wai",
      "Ma Wan",
      "Mei Foo",
      "Mei Foo Sun Chuen",
      "Mid Levels",
      "Mong Kok",
      "Mount Davis",
      "Mount Parker",
      "Nam Cheong",
      "Oi Man Estate",
      "Olympic",
      "Pak Shek Kok",
      "Pak Tin Estate",
      "Pat Heung",
      "Ping Shan",
      "Pok Fu Lam",
      "Pok Hong",
      "Prince Edward",
      "Quarry Bay",
      "Sai Kung",
      "Sai Wan",
      "Sai Wan Ho",
      "Sai Ying Pun",
      "Sam Shing",
      "San Po Kong",
      "San Tin",
      "San Tin",
      "San Tung Chung Hang",
      "Sau Mau Ping",
      "Sha Tin",
      "Sha Tin Wai",
      "Sham Shui Po",
      "Sham Tseng",
      "Shap Pat Heung",
      "Shau Kei Wan",
      "Shek Kip Mei",
      "Shek Kip Mei Estate",
      "Shek Lei",
      "Shek O",
      "Shek Pai Wan Estate",
      "Shek Wai Kok",
      "Sheung Shui",
      "Shuen Wan",
      "Shui Chuen O",
      "Siu Hei",
      "Siu Lek Yuen",
      "Siu Sai Wan Estate",
      "So Kwun Wat",
      "So Uk",
      "So Uk Estate",
      "South Horizons",
      "South Horizons (Estate)",
      "Sun Tin Wai",
      "Sung Wong Toi",
      "Tai Hang",
      "Tai Kok Tsui",
      "Tai Koo",
      "Tai Mei Tuk",
      "Tai Mong Tsai",
      "Tai Po",
      "Tai Shui Hang",
      "Tai Wai",
      "Tai Wo",
      "Tai Wo Hau",
      "Tai Yuen Estate",
      "Taikoo Shing",
      "Tak Long Estate",
      "The Peak",
      "Tin Hau",
      "Tin Shui Wai",
      "Tin Wan",
      "Ting Kau",
      "Tiu Keng Leng",
      "To Kwa Wan",
      "Tseung Kwan O",
      "Tsim Sha Tsui",
      "Tsing Yi Town",
      "Tsuen Wan",
      "Tsui Chuk Garden",
      "Tsz Ching Estate",
      "Tsz Wan Shan",
      "Tuen Mun",
      "Tuen Mun San Hui",
      "Tung Chung",
      "Tung Tau Estate",
      "Tung Tau Tsuen",
      "Un Chau Estate",
      "Upper Wong Tai Sin Estate",
      "Victoria",
      "Victoria Park",
      "Wah Fu",
      "Wah Fu Estate (I & II)",
      "Wah Kwai",
      "Wan Chai",
      "Wang Tau Hom",
      "Wang Tau Hom Estate",
      "Whampoa",
      "Whampoa Garden",
      "Wo Che",
      "Wong Chuk Hang",
      "Wong Tai Sin",
      "Wu Kai Sha",
      "Wu King",
      "Yau Ma Tei",
      "Yau Tong",
      "Yau Yat Chuen",
      "Yeung Uk Tsuen",
      "Yiu Tung Estate",
      "Yu Chui",
      "Yuen Long",
      "Yuen Long Kau Hui",
      "Yuen Long San Hui",
      "Yuet Wu",
    ],
  },
  Hungary: {
    code: "HU",
    cities: [
      "Abony",
      "Ajka",
      "Albertirsa",
      "Angyalfoeld",
      "Baja",
      "Balassagyarmat",
      "Balatonfuered",
      "Balmazujvaros",
      "Barcs",
      "Batonyterenye",
      "Bekes",
      "Bekescsaba",
      "Berettyoujfalu",
      "Bicske",
      "Bonyhad",
      "Budakeszi",
      "Budaors",
      "Budapest",
      "Budapest I. keruelet",
      "Budapest II. keruelet",
      "Budapest III. keruelet",
      "Budapest IV. keruelet",
      "Budapest VI. keruelet",
      "Budapest VIII. keruelet",
      "Budapest XI. keruelet",
      "Budapest XII. keruelet",
      "Budapest XIII. keruelet",
      "Budapest XIX. keruelet",
      "Budapest XV. keruelet",
      "Budapest XVI. keruelet",
      "Budapest XVII. keruelet",
      "Budapest XVIII. keruelet",
      "Budapest XX. keruelet",
      "Budapest XXI. keruelet",
      "Budapest XXII. keruelet",
      "Budapest XXIII. keruelet",
      "Cegled",
      "Celldomolk",
      "Csongrad",
      "Csorna",
      "Dabas",
      "Debrecen",
      "Dombovar",
      "Dorog",
      "Dunaharaszti",
      "Dunakeszi",
      "Dunaujvaros",
      "Edeleny",
      "Eger",
      "Erd",
      "Erzsebetvaros",
      "Esztergom",
      "Fot",
      "God",
      "Godollo",
      "Gyal",
      "Gyomaendrod",
      "Gyomro",
      "Gyongyos",
      "Gyor",
      "Gyula",
      "Hajduboszormeny",
      "Hajduhadhaz",
      "Hajdunanas",
      "Hajdusamson",
      "Hajduszoboszlo",
      "Hatvan",
      "Heves",
      "Hodmezovasarhely",
      "Isaszeg",
      "Jaszbereny",
      "Jozsefvaros",
      "Kalocsa",
      "Kaposvar",
      "Kapuvar",
      "Karcag",
      "Kazincbarcika",
      "Kecskemet",
      "Keszthely",
      "Kiskoros",
      "Kiskunfelegyhaza",
      "Kiskunhalas",
      "Kiskunmajsa",
      "Kispest",
      "Kistarcsa",
      "Kisujszallas",
      "Kisvarda",
      "Kobanya",
      "Komarom",
      "Komlo",
      "Kormend",
      "Koszeg",
      "Lajosmizse",
      "Maglod",
      "Mako",
      "Marcali",
      "Mateszalka",
      "Mezobereny",
      "Mezokovesd",
      "Mezotur",
      "Miskolc",
      "Mohacs",
      "Monor",
      "Mor",
      "Mosonmagyarovar",
      "Nagyatad",
      "Nagykallo",
      "Nagykanizsa",
      "Nagykata",
      "Nagykoroes",
      "Nyirbator",
      "Nyiregyhaza",
      "Oroshaza",
      "Oroszlany",
      "Ozd",
      "Paks",
      "Papa",
      "Paradsasvar",
      "Paszto",
      "Pecel",
      "Pecs",
      "Pilis",
      "Pilisvoeroesvar",
      "Pomaz",
      "Puspokladany",
      "Sajoszentpeter",
      "Salgotarjan",
      "Sarbogard",
      "Sarkad",
      "Sarospatak",
      "Sarvar",
      "Satoraljaujhely",
      "Siklos",
      "Siofok",
      "Sopron",
      "Szarvas",
      "Szazhalombatta",
      "Szeged",
      "Szekesfehervar",
      "Szekszard",
      "Szentendre",
      "Szentes",
      "Szerencs",
      "Szigethalom",
      "Szigetszentmiklos",
      "Szigetvar",
      "Szolnok",
      "Szombathely",
      "Tapolca",
      "Tata",
      "Tatabanya",
      "Tiszafoldvar",
      "Tiszafuered",
      "Tiszakecske",
      "Tiszaujvaros",
      "Tiszavasvari",
      "Toekoel",
      "Toeroekbalint",
      "Tolna",
      "Torokszentmiklos",
      "Turkeve",
      "UEllo",
      "Ujfeherto",
      "Vac",
      "Varpalota",
      "Vecses",
      "Veresegyhaz",
      "Veszprem",
      "Zalaegerszeg",
      "Zuglo",
    ],
  },
  Iceland: {
    code: "IS",
    cities: [
      "Akureyri",
      "Gardabaer",
      "Hafnarfjoerdur",
      "Keflavik",
      "Kopavogur",
      "Mosfellsbaer",
      "Reykjanesbaer",
      "Reykjavik",
    ],
  },
  India: {
    code: "IN",
    cities: [
      "Abhayapuri",
      "Abohar",
      "Abrama",
      "Abu",
      "Abu Road",
      "Achalpur",
      "Achhnera",
      "Adalaj",
      "Adampur",
      "Addanki",
      "Adilabad",
      "Adirampattinam",
      "Adoni",
      "Adra",
      "Adur",
      "Aduthurai",
      "Afzalgarh",
      "Afzalpur",
      "Agar",
      "Agartala",
      "Agra",
      "Ahiri",
      "Ahmadnagar",
      "Ahmadpur",
      "Ahmedabad",
      "Ahraura",
      "Ahwa",
      "Airoli",
      "Aistala",
      "Aizawl",
      "Ajaigarh",
      "Ajjampur",
      "Ajmer",
      "Ajnala",
      "Ajra",
      "Akalkot",
      "Akaltara",
      "Akbarpur",
      "Akbarpur",
      "Akhnur",
      "Akividu",
      "Akkarampalle",
      "Aklera",
      "Akodia",
      "Akola",
      "Akot",
      "Alampur",
      "Alampur",
      "Aland",
      "Alandi",
      "Alandur",
      "Alangad",
      "Alanganallur",
      "Alangayam",
      "Alangudi",
      "Alangulam",
      "Alappuzha",
      "Alibag",
      "Aliganj",
      "Aligarh",
      "Alipur",
      "Alipur Duar",
      "Allahganj",
      "Allapalli",
      "Almora",
      "Alnavar",
      "Along",
      "Alot",
      "Aluva",
      "Alwar",
      "Alwaye",
      "Amalapuram",
      "Amalner",
      "Amanganj",
      "Amanpur",
      "Amaravati",
      "Amarnath",
      "Amarpatan",
      "Amarpur",
      "Amarpur",
      "Amarwara",
      "Ambad",
      "Ambah",
      "Ambahta",
      "Ambajogai",
      "Ambala",
      "Ambasamudram",
      "Ambattur",
      "Ambikapur",
      "Ambur",
      "Amet",
      "Amethi",
      "Amethi",
      "Amla",
      "Amlagora",
      "Amli",
      "Amloh",
      "Ammapettai",
      "Amod",
      "Amravati",
      "Amreli",
      "Amritsar",
      "Amroha",
      "Amroli",
      "Amta",
      "Amudalavalasa",
      "Anakapalle",
      "Anamalais",
      "Anand",
      "Anandnagar",
      "Anandpur",
      "Anantapur",
      "Anantnag",
      "Andippatti",
      "Andol",
      "Andrott",
      "Anekal",
      "Angamali",
      "Angul",
      "Anjad",
      "Anjangaon",
      "Anjar",
      "Ankleshwar",
      "Ankola",
      "Annigeri",
      "Annur",
      "Anshing",
      "Anta",
      "Anthiyur",
      "Anupgarh",
      "Anuppur",
      "Anupshahr",
      "Aonla",
      "Arakkonam",
      "Arambagh",
      "Arang",
      "Arangaon",
      "Arantangi",
      "Araria",
      "Arcot",
      "Ariyalur",
      "Arkalgud",
      "Arni",
      "Aron",
      "Aroor",
      "Arrah",
      "Arsikere",
      "Arukutti",
      "Arumbavur",
      "Arumuganeri",
      "Aruppukkottai",
      "Arvi",
      "Asandh",
      "Asansol",
      "Ashoknagar",
      "Ashoknagar Kalyangarh",
      "Ashta",
      "Ashta",
      "Ashti",
      "Asifabad",
      "Asika",
      "Asind",
      "Atarra",
      "Athagarh",
      "Athni",
      "Atmakur",
      "Atrauli",
      "Atraulia",
      "Attayyampatti",
      "Attili",
      "Attingal",
      "Attur",
      "Aurad",
      "Auraiya",
      "Aurangabad",
      "Ausa",
      "Avadi",
      "Avanigadda",
      "Avinashi",
      "Ayakudi",
      "Ayodhya",
      "Ayyampettai",
      "Azamgarh",
      "Azhiyur",
      "Babai",
      "Baberu",
      "Babina",
      "Babra",
      "Babrala",
      "Bachhraon",
      "Bachhrawan",
      "Bada Barabil",
      "Badagara",
      "Badami",
      "Badarpur",
      "Badarwas",
      "Baddi",
      "Badlapur",
      "Badnawar",
      "Baduria",
      "Badvel",
      "Bagaha",
      "Bagaha",
      "Bagalkot",
      "Bagar",
      "Bagasra",
      "Bagdogra",
      "Bagepalli",
      "Bagha Purana",
      "Baghpat",
      "Bagli",
      "Bagra",
      "Bagula",
      "Bah",
      "Bahadurganj",
      "Bahadurgarh",
      "Baharampur",
      "Baheri",
      "Bahjoi",
      "Bahraigh",
      "Bahsuma",
      "Bahua",
      "Bahula",
      "Baidyabati",
      "Baihar",
      "Baikunthpur",
      "Baikunthpur",
      "Bail-Hongal",
      "Bairagnia",
      "Bakewar",
      "Bakhtiyarpur",
      "Balachor",
      "Balaghat",
      "Balangir",
      "Balapur",
      "Balarampur",
      "Balasore",
      "Baldev",
      "Bali",
      "Bali",
      "Bali Chak",
      "Balimila",
      "Ballarpur",
      "Balod",
      "Baloda",
      "Baloda Bazar",
      "Balotra",
      "Balrampur",
      "Balugaon",
      "Balurghat",
      "Bamor Kalan",
      "Banapur",
      "Banat",
      "Banda",
      "Banda",
      "Bandikui",
      "Bandipura",
      "Bandora",
      "Banga",
      "Banganapalle",
      "Bangaon",
      "Bangaon",
      "Bangarapet",
      "Bangarmau",
      "Banka",
      "Banki",
      "Bankra",
      "Bankura",
      "Banmankhi",
      "Bannur",
      "Banposh",
      "Bansbaria",
      "Bansdih",
      "Bansgaon",
      "Bansi",
      "Banswada",
      "Banswara",
      "Bantva",
      "Bantval",
      "Banur",
      "Bapatla",
      "Bar Bigha",
      "Bara Uchana",
      "Baragaon",
      "Barakpur",
      "Baramati",
      "Baramula",
      "Baran",
      "Baranagar",
      "Barasat",
      "Barauli",
      "Baraut",
      "Barbil",
      "Barddhaman",
      "Bardoli",
      "Bareilly",
      "Barela",
      "Bargarh",
      "Barghat",
      "Bargi",
      "Barh",
      "Barhi",
      "Barhiya",
      "Bari",
      "Bari Sadri",
      "Bariarpur",
      "Barjala",
      "Barjora",
      "Barka Kana",
      "Barkhera Kalan",
      "Barki Saria",
      "Barmer",
      "Barnala",
      "Barpali",
      "Barpeta",
      "Barpeta Road",
      "Barsi",
      "Baruipur",
      "Baruni",
      "Barwala",
      "Barwani",
      "Basavakalyan",
      "Basavana Bagevadi",
      "Basi",
      "Basi",
      "Basi",
      "Basmat",
      "Basni",
      "Basoda",
      "Basti",
      "Basudebpur",
      "Basugaon",
      "Baswa",
      "Batala",
      "Bathinda",
      "Baud",
      "Bawal",
      "Bawana",
      "Bayana",
      "Bazpur",
      "Beawar",
      "Bedi",
      "Begamganj",
      "Begowal",
      "Begun",
      "Begusarai",
      "Behat",
      "Behror",
      "Bela",
      "Belagavi",
      "Belaguntha",
      "Beldanga",
      "Bellampalli",
      "Bellary",
      "Belonia",
      "Belsand",
      "Belur",
      "Bemetara",
      "Benaulim",
      "Bengaluru",
      "Beniganj",
      "Beohari",
      "Berasia",
      "Beri Khas",
      "Betamcherla",
      "Betma",
      "Bettiah",
      "Betul",
      "Bewar",
      "Beypore",
      "Bhabhua",
      "Bhachau",
      "Bhadarwah",
      "Bhadasar",
      "Bhadaur",
      "Bhadohi",
      "Bhadra",
      "Bhadrachalam",
      "Bhadrakh",
      "Bhadravati",
      "Bhadreswar",
      "Bhagalpur",
      "Bhainsdehi",
      "Bhaisa",
      "Bhalki",
      "Bhandara",
      "Bhander",
      "Bhanjanagar",
      "Bhanpura",
      "Bhanpuri",
      "Bhanvad",
      "Bharatpur",
      "Bharthana",
      "Bharuch",
      "Bharwari",
      "Bhasawar",
      "Bhatapara",
      "Bhatkal",
      "Bhatpara",
      "Bhattiprolu",
      "Bhavani",
      "Bhavnagar",
      "Bhawani Mandi",
      "Bhawanigarh",
      "Bhawanipatna",
      "Bhawanipur",
      "Bhayandar",
      "Bhayavadar",
      "Bhikangaon",
      "Bhikhi",
      "Bhilai",
      "Bhilwara",
      "Bhimavaram",
      "Bhimunipatnam",
      "Bhind",
      "Bhindar",
      "Bhinga",
      "Bhinmal",
      "Bhitarwar",
      "Bhiwadi",
      "Bhiwandi",
      "Bhiwani",
      "Bhogpur",
      "Bhongaon",
      "Bhongir",
      "Bhopal",
      "Bhor",
      "Bhuban",
      "Bhubaneswar",
      "Bhudgaon",
      "Bhuj",
      "Bhulath Gharbi",
      "Bhum",
      "Bhusaval",
      "Biaora",
      "Bidar",
      "Bidhuna",
      "Bihar Sharif",
      "Bihariganj",
      "Bihpuriagaon",
      "Bijapur",
      "Bijawar",
      "Bijbehara",
      "Bijni",
      "Bijnor",
      "Bikaner",
      "Bikapur",
      "Bikramganj",
      "Bilara",
      "Bilari",
      "Bilariaganj",
      "Bilasipara",
      "Bilaspur",
      "Bilaspur",
      "Bilaspur",
      "Bilaspur",
      "Bilgi",
      "Bilgram",
      "Bilhaur",
      "Bilimora",
      "Bilkha",
      "Biloli",
      "Bilsanda",
      "Bilsi",
      "Bilthra",
      "Bindki",
      "Binka",
      "Birbhaddar",
      "Birmitrapur",
      "Birpara",
      "Birpur",
      "Birur",
      "Bisalpur",
      "Bisauli",
      "Bisenda Buzurg",
      "Bishnah",
      "Bishnupur",
      "Bissau",
      "Biswan",
      "Bithur",
      "Bobbili",
      "Bodhan",
      "Bodinayakkanur",
      "Bodri",
      "Boisar",
      "Bokajan",
      "Bokaro",
      "Bolanikhodan",
      "Bolpur",
      "Bongaigaon",
      "Borivli",
      "Borsad",
      "Botad",
      "Brahmapur",
      "Brajarajnagar",
      "Budaun",
      "Buddh Gaya",
      "Budge Budge",
      "Budhana",
      "Budhlada",
      "Buguda",
      "Bulandshahr",
      "Buldana",
      "Bundi",
      "Bundu",
      "Burhanpur",
      "Burhar",
      "Buriya",
      "Burla",
      "Buxar",
      "Byadgi",
      "Byndoor",
      "Calangute",
      "Canacona",
      "Canning",
      "Captainganj",
      "Cavelossim",
      "Chaibasa",
      "Chakan",
      "Chakapara",
      "Chakia",
      "Chakia",
      "Chaklasi",
      "Chakradharpur",
      "Chaksu",
      "Chakulia",
      "Chalala",
      "Chalisgaon",
      "Challakere",
      "Challapalle",
      "Chamba",
      "Champa",
      "Chamrajnagar",
      "Chanasma",
      "Chanda",
      "Chandannagar",
      "Chandauli",
      "Chanderi",
      "Chandia",
      "Chandigarh",
      "Chandla",
      "Chandor",
      "Chandpur",
      "Chandrakona",
      "Chanduasi",
      "Chandur",
      "Chandur",
      "Chandur",
      "Chandur Bazar",
      "Changanacheri",
      "Channagiri",
      "Channapatna",
      "Channarayapatna",
      "Chapar",
      "Chapra",
      "Charkhari",
      "Charkhi Dadri",
      "Charthawal",
      "Chas",
      "Chatra",
      "Chatrapur",
      "Chechat",
      "Chemmumiahpet",
      "Chengalpattu",
      "Chengam",
      "Chengannur",
      "Chennai",
      "Chennimalai",
      "Cherpulassery",
      "Cherrapunji",
      "Cherthala",
      "Chetput",
      "Chettipalaiyam",
      "Chetwayi",
      "Cheyyar",
      "Cheyyur",
      "Chhabra",
      "Chhachhrauli",
      "Chhala",
      "Chhapar",
      "Chhaprauli",
      "Chharra",
      "Chhata",
      "Chhatapur",
      "Chhatarpur",
      "Chhibramau",
      "Chhindwara",
      "Chhota Udepur",
      "Chhoti Sadri",
      "Chhutmalpur",
      "Chichli",
      "Chicholi",
      "Chidambaram",
      "Chidawa",
      "Chik Ballapur",
      "Chikhli",
      "Chikitigarh",
      "Chikmagalur",
      "Chiknayakanhalli",
      "Chikodi",
      "Chilakalurupet",
      "Chillupar",
      "Chinchani",
      "Chincholi",
      "Chinna Salem",
      "Chinnachowk",
      "Chinnamanur",
      "Chinnasekkadu",
      "Chintamani",
      "Chiplun",
      "Chipurupalle",
      "Chirala",
      "Chirgaon",
      "Chirmiri",
      "Chitapur",
      "Chitradurga",
      "Chittaranjan",
      "Chittaurgarh",
      "Chittur",
      "Chodavaram",
      "Chopan",
      "Chopda",
      "Chorhat",
      "Chotila",
      "Chunar",
      "Churachandpur",
      "Churu",
      "Clement Town",
      "Closepet",
      "Cochin",
      "Coimbatore",
      "Colachel",
      "Colgong",
      "Colonelganj",
      "Colva",
      "Contai",
      "Coondapoor",
      "Cuddalore",
      "Cumbum",
      "Cumbum",
      "Cuncolim",
      "Curchorem",
      "Curti",
      "Cuttack",
      "Dabhoi",
      "Daboh",
      "Dabra",
      "Dabwali",
      "Dadri",
      "Dahanu",
      "Dahegam",
      "Dakor",
      "Dalkola",
      "Dalmau",
      "Dalsingh Sarai",
      "Daltonganj",
      "Dam Dam",
      "Daman",
      "Damnagar",
      "Damoh",
      "Dandeli",
      "Dankaur",
      "Darbhanga",
      "Darjiling",
      "Darsi",
      "Darwha",
      "Daryapur",
      "Dasna",
      "Dasnapur",
      "Dasuya",
      "Dataganj",
      "Datia",
      "Dattapur",
      "Daudnagar",
      "Daund",
      "Daurala",
      "Dausa",
      "Davangere",
      "Davorlim",
      "Dayapar",
      "Dehra Dun",
      "Dehri",
      "Delhi",
      "Delvada",
      "Denkanikota",
      "Deoband",
      "Deogarh",
      "Deoghar",
      "Deolali",
      "Deoli",
      "Deoli",
      "Deoli",
      "Deoranian",
      "Deori",
      "Deori Khas",
      "Deoria",
      "Depalpur",
      "Dergaon",
      "Deshnoke",
      "Deulgaon Raja",
      "Devadanappatti",
      "Devakottai",
      "Devanhalli",
      "Devarkonda",
      "Devgadh Bariya",
      "Devgarh",
      "Dewa",
      "Dewas",
      "Dhaka",
      "Dhamnod",
      "Dhampur",
      "Dhamtari",
      "Dhana",
      "Dhanaula",
      "Dhanaura",
      "Dhanbad",
      "Dhandhuka",
      "Dhanera",
      "Dhar",
      "Dharampur",
      "Dharampuri",
      "Dharamsala",
      "Dharangaon",
      "Dharapuram",
      "Dharashiv",
      "Dhari",
      "Dhariwal",
      "Dharmabad",
      "Dharmadam",
      "Dharmanagar",
      "Dharmapuri",
      "Dharmavaram",
      "Dharuhera",
      "Dharur",
      "Dhaulpur",
      "Dhaurahra",
      "Dhekiajuli",
      "Dhemaji",
      "Dhenkanal",
      "Dhing",
      "Dholka",
      "Dhone",
      "Dhoraji",
      "Dhrangadhra",
      "Dhrol",
      "Dhuburi",
      "Dhulagari",
      "Dhulia",
      "Dhulian",
      "Dhupgari",
      "Dhuri",
      "Dhuwaran",
      "Diamond Harbour",
      "Dibai",
      "Dibrugarh",
      "Dicholi",
      "Didwana",
      "Dig",
      "Digapahandi",
      "Digboi",
      "Dighwara",
      "Diglur",
      "Digras",
      "Dimapur",
      "Dinanagar",
      "Dinapore",
      "Dindigul",
      "Dindori",
      "Dinhata",
      "Diphu",
      "Dirba",
      "Disa",
      "Dispur",
      "Diu",
      "Doda",
      "Doddaballapura",
      "Dohad",
      "Dohrighat",
      "Dombivli",
      "Dondaicha",
      "Dongargaon",
      "Dongargarh",
      "Doraha",
      "Dornakal",
      "Dostpur",
      "Dubrajpur",
      "Dudhani",
      "Dudhi",
      "Dugda",
      "Duliajan",
      "Dum Duma",
      "Dumjor",
      "Dumka",
      "Dumra",
      "Dumraon",
      "Dungarpur",
      "Durg",
      "Durgapur",
      "Durgapur",
      "Dwarka",
      "Egra",
      "Elamanchili",
      "Ellenabad",
      "Ellore",
      "Elumalai",
      "Elur",
      "Emmiganur",
      "Erandol",
      "Erattupetta",
      "Erode",
      "Erraguntla",
      "Etawa",
      "Etawah",
      "Etikoppaka",
      "Ettaiyapuram",
      "Faizpur",
      "Falakata",
      "Farakka",
      "Faridabad",
      "Faridkot",
      "Faridnagar",
      "Faridpur",
      "Farrukhabad",
      "Farrukhnagar",
      "Farrukhnagar",
      "Fatehabad",
      "Fatehabad",
      "Fatehganj West",
      "Fatehgarh",
      "Fatehgarh Churian",
      "Fatehpur",
      "Fatehpur",
      "Fatehpur",
      "Fatehpur Sikri",
      "Fatwa",
      "Fazilka",
      "Ferokh",
      "Ferozepore",
      "Firozabad",
      "Firozpur Jhirka",
      "Forbesganj",
      "Fort Gloster",
      "French Rocks",
      "Fyzabad",
      "Gadag",
      "Gadag-Betageri",
      "Gadarwara",
      "Gaddi Annaram",
      "Gadhada",
      "Gadhinglaj",
      "Gadwal",
      "Gajendragarh",
      "Gajraula",
      "Gajuwaka",
      "Gandai",
      "Gandarbal",
      "Gandevi",
      "Gandhidham",
      "Gandhinagar",
      "Gangaikondan",
      "Gangakher",
      "Ganganagar",
      "Gangapur",
      "Gangapur",
      "Gangapur",
      "Gangarampur",
      "Gangavalli",
      "Gangawati",
      "Gangoh",
      "Gangolli",
      "Gangtok",
      "Ganj Dundwara",
      "Ganjam",
      "Gannavaram",
      "Garhakota",
      "Garhi Pukhta",
      "Garhmuktesar",
      "Garhshankar",
      "Garhwa",
      "Gariaband",
      "Gariadhar",
      "Garui",
      "Gaurela",
      "Gauribidanur",
      "Gauripur",
      "Gautampura",
      "Gaya",
      "Gevrai",
      "Gharaunda",
      "Ghatal",
      "Ghatampur",
      "Ghatanji",
      "Ghatkesar",
      "Ghatsila",
      "Ghaziabad",
      "Ghazipur",
      "Ghiror",
      "Ghogha",
      "Ghosi",
      "Ghoti Budrukh",
      "Ghugus",
      "Giddalur",
      "Giddarbaha",
      "Gingee",
      "Giridih",
      "Goalpara",
      "Gobardanga",
      "Gobichettipalayam",
      "Gobindapur",
      "Gobindpur",
      "Godda",
      "Godhra",
      "Gohadi",
      "Gohana",
      "Gokak",
      "Gokarna",
      "Gokavaram",
      "Gola Bazar",
      "Gola Gokarannath",
      "Golaghat",
      "Gomoh",
      "Gonda City",
      "Gondal",
      "Gondia",
      "Gopalganj",
      "Gopamau",
      "Gorakhpur",
      "Gorakhpur",
      "Gorantla",
      "Gosaba",
      "Goshainganj",
      "Goshaingaon",
      "Govardhan",
      "Govindgarh",
      "Govindgarh",
      "Goyerkata",
      "Greater Noida",
      "Gua",
      "Gubbi",
      "Gudalur",
      "Gudivada",
      "Gudiyatham",
      "Gudlavalleru",
      "Gudur",
      "Guduvancheri",
      "Gulabpura",
      "Gulaothi",
      "Guledagudda",
      "Gumia",
      "Gumla",
      "Gummidipundi",
      "Guna",
      "Gundlupet",
      "Gunnaur",
      "Guntakal Junction",
      "Guntur",
      "Gunupur",
      "Gurdaha",
      "Gurgaon",
      "Gurh",
      "Gurmatkal",
      "Gursahaiganj",
      "Gursarai",
      "Guru Har Sahai",
      "Guruvayur",
      "Guskhara",
      "Guwahati",
      "Gwalior",
      "Gyanpur",
      "Habra",
      "Hadagalli",
      "Hadgaon",
      "Haflong",
      "Hailakandi",
      "Hajan",
      "Hajipur",
      "Hajo",
      "Haldaur",
      "Haldia",
      "Haldibari",
      "Haldwani",
      "Halisahar",
      "Haliyal",
      "Halol",
      "Halvad",
      "Hamirpur",
      "Hamirpur",
      "Handia",
      "Hangal",
      "Hansi",
      "Hansot",
      "Hanumangarh",
      "Haora",
      "Hapur",
      "Harda Khas",
      "Hardoi",
      "Harduaganj",
      "Haridwar",
      "Harihar",
      "Harij",
      "Harpalpur",
      "Harpanahalli",
      "Harrai",
      "Harsud",
      "Harur",
      "Hasanpur",
      "Hasimara",
      "Hassan",
      "Hastinapur",
      "Hata",
      "Hathras",
      "Hatta",
      "Haveri",
      "Hazaribagh",
      "Heggadadevankote",
      "Hilsa",
      "Himatnagar",
      "Hindaun",
      "Hindoria",
      "Hindupur",
      "Hinganghat",
      "Hingoli",
      "Hinjilikatu",
      "Hirakud",
      "Hirekerur",
      "Hiriyur",
      "Hisar",
      "Hisua",
      "Hodal",
      "Hojai",
      "Holalkere",
      "Hole Narsipur",
      "Homnabad",
      "Honavar",
      "Honnali",
      "Hosakote",
      "Hosdurga",
      "Hoshiarpur",
      "Hoskote",
      "Hospet",
      "Hosur",
      "Howli",
      "Hubli",
      "Hugli",
      "Hukeri",
      "Hungund",
      "Hunsur",
      "Husainabad",
      "Hyderabad",
      "Ichalkaranji",
      "Ichchapuram",
      "Ichhawar",
      "Idappadi",
      "Idukki",
      "Igatpuri",
      "Iglas",
      "Ikauna",
      "Ilampillai",
      "Ilkal",
      "Iluppur",
      "Imphal",
      "Indapur",
      "Indergarh",
      "Indi",
      "Indore",
      "Indri",
      "Ingraj Bazar",
      "Injambakkam",
      "Iringal",
      "Irinjalakuda",
      "Irugur",
      "Isagarh",
      "Islamnagar",
      "Islampur",
      "Islampur",
      "Itanagar",
      "Itarsi",
      "Itimadpur",
      "Jabalpur",
      "Jagadhri",
      "Jagalur",
      "Jagatsinghapur",
      "Jagdalpur",
      "Jagdishpur",
      "Jagdispur",
      "Jaggayyapeta",
      "Jagnair",
      "Jagraon",
      "Jagtial",
      "Jahanabad",
      "Jahanabad",
      "Jahangirabad",
      "Jahangirpur",
      "Jahazpur",
      "Jaigaon",
      "Jainagar",
      "Jainpur",
      "Jaipur",
      "Jais",
      "Jaisalmer",
      "Jaisingpur",
      "Jaitaran",
      "Jaito",
      "Jajpur",
      "Jalakandapuram",
      "Jalalabad",
      "Jalalabad",
      "Jalalabad",
      "Jalali",
      "Jalalpore",
      "Jalalpur",
      "Jalandhar",
      "Jalarpet",
      "Jalaun",
      "Jalesar",
      "Jaleshwar",
      "Jalgaon",
      "Jalgaon Jamod",
      "Jalna",
      "Jalor",
      "Jalpaiguri",
      "Jamadoba",
      "Jamai",
      "Jamalpur",
      "Jambusar",
      "Jamkhandi",
      "Jammalamadugu",
      "Jammu",
      "Jamnagar",
      "Jamshedpur",
      "Jamtara",
      "Jamui",
      "Jamuria",
      "Jandiala",
      "Jangaon",
      "Jangipur",
      "Janjgir",
      "Jansath",
      "Jaora",
      "Jarwal",
      "Jasdan",
      "Jashpurnagar",
      "Jasidih",
      "Jaspur",
      "Jaswantnagar",
      "Jatani",
      "Jatara",
      "Jaunpur",
      "Jawad",
      "Jawhar",
      "Jayamkondacholapuram",
      "Jaynagar Majilpur",
      "Jejuri",
      "Jetalsar",
      "Jetpur",
      "Jevargi",
      "Jewar",
      "Jeypore",
      "Jha-Jha",
      "Jhabua",
      "Jhajjar",
      "Jhalawar",
      "Jhalida",
      "Jhalrapatan",
      "Jhalu",
      "Jhanjharpur",
      "Jhansi",
      "Jhargram",
      "Jharia",
      "Jharsuguda",
      "Jhinjhak",
      "Jhinjhana",
      "Jhumri Telaiya",
      "Jhunjhunun",
      "Jhusi",
      "Jind",
      "Jintur",
      "Jiran",
      "Jobat",
      "Jobner",
      "Jodhpur",
      "Jodhpur",
      "Jodiya Bandar",
      "Jogbani",
      "Jora",
      "Jorhat",
      "Joshimath",
      "Jugsalai",
      "Junagadh",
      "Junagarh",
      "Junnar",
      "Kabrai",
      "Kachhwa",
      "Kadakkavoor",
      "Kadapa",
      "Kadaura",
      "Kadayanallur",
      "Kadi",
      "Kadiri",
      "Kadod",
      "Kadur",
      "Kagal",
      "Kaikalur",
      "Kailaras",
      "Kailashahar",
      "Kaimganj",
      "Kaimori",
      "Kaintragarh",
      "Kairana",
      "Kaithal",
      "Kakching",
      "Kakdwip",
      "Kakinada",
      "Kakori",
      "Kakrala",
      "Kalaburagi",
      "Kalagarh Project Colony",
      "Kalakkadu",
      "Kalamassery",
      "Kalamb",
      "Kalamnuri",
      "Kalanaur",
      "Kalanaur",
      "Kalanwali",
      "Kalas",
      "Kalavad",
      "Kalavai",
      "Kalavoor",
      "Kalghatgi",
      "Kalimpong",
      "Kalinagar",
      "Kaliyaganj",
      "Kalka",
      "Kallakkurichchi",
      "Kallakurichi",
      "Kallidaikurichi",
      "Kalmeshwar",
      "Kalna",
      "Kalol",
      "Kalpatta",
      "Kalpi",
      "Kalugumalai",
      "Kalyan",
      "Kalyandurg",
      "Kalyani",
      "Kamakhyanagar",
      "Kamalapuram",
      "Kamalganj",
      "Kaman",
      "Kamareddi",
      "Kamarhati",
      "Kampli",
      "Kamthi",
      "Kamuthi",
      "Kanayannur",
      "Kanchipuram",
      "Kanchrapara",
      "Kandhla",
      "Kandi",
      "Kandla",
      "Kandukur",
      "Kangayam",
      "Kanigiri",
      "Kanina Khas",
      "Kankanhalli",
      "Kankauli",
      "Kanke",
      "Kanker",
      "Kankipadu",
      "Kankon",
      "Kannad",
      "Kannangad",
      "Kannauj",
      "Kanniyakumari",
      "Kannod",
      "Kannur",
      "Kanodar",
      "Kanor",
      "Kanpur",
      "Kant",
      "Kantabanji",
      "Kanth",
      "Kanuru",
      "Kapadvanj",
      "Kapren",
      "Kapurthala",
      "Karad",
      "Karaikal",
      "Karaikkudi",
      "Karamadai",
      "Karambakkudi",
      "Karamsad",
      "Karanja",
      "Karanpur",
      "Karari",
      "Karauli",
      "Kareli",
      "Karera",
      "Kargil",
      "Karhal",
      "Kariapatti",
      "Karimganj",
      "Karimnagar",
      "Karjat",
      "Karkala",
      "Karmala",
      "Karnal",
      "Karol Bagh",
      "Kartarpur",
      "Karumbakkam",
      "Karur",
      "Karwar",
      "Kasaragod",
      "Kasba",
      "Kasganj",
      "Kashipur",
      "Kasrawad",
      "Katangi",
      "Katangi",
      "Katghora",
      "Kathor",
      "Kathua",
      "Kati",
      "Katihar",
      "Katol",
      "Katoya",
      "Katpadi",
      "Katras",
      "Kattanam",
      "Kattivakkam",
      "Kattupputtur",
      "Kavali",
      "Kavaratti",
      "Kaveripatnam",
      "Kawardha",
      "Kayalpattinam",
      "Kayankulam",
      "Kayattar",
      "Keelakarai",
      "Kekri",
      "Kelamangalam",
      "Kemri",
      "Kenda",
      "Kendraparha",
      "Kerur",
      "Kesabpur",
      "Keshod",
      "Keshorai Patan",
      "Kesinga",
      "Khachrod",
      "Khada",
      "Khadki",
      "Khaga",
      "Khagaria",
      "Khagaul",
      "Khailar",
      "Khair",
      "Khairabad",
      "Khairagarh",
      "Khairagarh",
      "Khajuraho Group of Monuments",
      "Khalilabad",
      "Khallikot",
      "Khamaria",
      "Khambhaliya",
      "Khambhat",
      "Khamgaon",
      "Khammam",
      "Khanapur",
      "Khandela",
      "Khandwa",
      "Khanna",
      "Khanpur",
      "Khanpur",
      "Khapa",
      "Kharagpur",
      "Kharagpur",
      "Kharakvasla",
      "Kharar",
      "Kharar",
      "Khardah",
      "Kharela",
      "Khargapur",
      "Khargone",
      "Kharhial",
      "Kharkhauda",
      "Kharkhauda",
      "Kharsia",
      "Kharupatia",
      "Khatauli",
      "Khategaon",
      "Khatima",
      "Khatra",
      "Khed",
      "Kheda",
      "Khedbrahma",
      "Khekra",
      "Khem Karan",
      "Kheralu",
      "Kheri",
      "Kheri Sampla",
      "Khetia",
      "Khetri",
      "Khilchipur",
      "Khirkiyan",
      "Khonsa",
      "Khopoli",
      "Khowai",
      "Khudaganj",
      "Khuldabad",
      "Khunti",
      "Khurai",
      "Khurda",
      "Khurja",
      "Khusropur",
      "Khutar",
      "Kichha",
      "Kil Bhuvanagiri",
      "Kinwat",
      "Kirakat",
      "Kirandul",
      "Kiranur",
      "Kiraoli",
      "Kiratpur",
      "Kishanganj",
      "Kishangarh",
      "Kishangarh",
      "Kishtwar",
      "Kithor",
      "Kizhake Chalakudi",
      "Koath",
      "Koch Bihar",
      "Kodaikanal",
      "Kodala",
      "Kodar",
      "Kodarma",
      "Kodinar",
      "Kodoli",
      "Kodumudi",
      "Kodungallur",
      "Koelwar",
      "Kohima",
      "Kokrajhar",
      "Kolar",
      "Kolaras",
      "Kolasib",
      "Kolhapur",
      "Kolkata",
      "Kollam",
      "Kollegal",
      "Kombai",
      "Konarka",
      "Konch",
      "Kondagaon",
      "Kondalwadi",
      "Kondapalle",
      "Konnagar",
      "Konnur",
      "Koothanallur",
      "Kopaganj",
      "Kopargaon",
      "Koppal",
      "Koraput",
      "Koratagere",
      "Koratla",
      "Korba",
      "Koregaon",
      "Korwai",
      "Kosamba",
      "Kosi",
      "Kosigi",
      "Kota",
      "Kota",
      "Kota",
      "Kotagiri",
      "Kotamangalam",
      "Kotaparh",
      "Kotdwara",
      "Kotkapura",
      "Kotma",
      "Kotputli",
      "Kottagudem",
      "Kottaiyur",
      "Kottayam",
      "Kotturu",
      "Kotwa",
      "Kovilpatti",
      "Kovur",
      "Kovvur",
      "Koynanagar",
      "Kozhikode",
      "Krishnagiri",
      "Krishnanagar",
      "Krishnarajpet",
      "Kuchaiburi",
      "Kuchaman",
      "Kuchera",
      "Kuchinda",
      "Kudachi",
      "Kudal",
      "Kudligi",
      "Kuju",
      "Kukatpalli",
      "Kukshi",
      "Kulattur",
      "Kulgam",
      "Kulittalai",
      "Kulpahar",
      "Kulti",
      "Kulu",
      "Kumaralingam",
      "Kumarapalayam",
      "Kumbakonam",
      "Kumbalam",
      "Kumbhraj",
      "Kumhari",
      "Kumher",
      "Kumta",
      "Kunda",
      "Kundarkhi",
      "Kundgol",
      "Kundla",
      "Kunigal",
      "Kunnamangalam",
      "Kunnamkulam",
      "Kunnumma",
      "Kuppam",
      "Kupwara",
      "Kurandvad",
      "Kurara",
      "Kurduvadi",
      "Kurinjippadi",
      "Kurnool",
      "Kurseong",
      "Kurud",
      "Kushalgarh",
      "Kushalnagar",
      "Kushtagi",
      "Kutiatodu",
      "Kutiyana",
      "Kuttampuzha",
      "Kuzhithurai",
      "Kyathampalle",
      "Kyelang",
      "Lachhmangarh Sikar",
      "Ladnun",
      "Ladwa",
      "Lahar",
      "Laharpur",
      "Lakheri",
      "Lakhimpur",
      "Lakhipur",
      "Lakhipur",
      "Lakhna",
      "Lakhnadon",
      "Lakhtar",
      "Lakhyabad",
      "Laksar",
      "Lakshettipet",
      "Lakshmeshwar",
      "Lal Bahadur Nagar",
      "Lala",
      "Lalganj",
      "Lalganj",
      "Lalganj",
      "Lalgola",
      "Lalgudi",
      "Lalitpur",
      "Lalpur",
      "Lalsot",
      "Lanja",
      "Lar",
      "Lasalgaon",
      "Latehar",
      "Lathi",
      "Latur",
      "Laungowal",
      "Lawar Khas",
      "Leh",
      "Leteri",
      "Limbdi",
      "Lingsugur",
      "Lohardaga",
      "Loharu",
      "Lohogaon",
      "Lonar",
      "Lonavla",
      "Loni",
      "Lormi",
      "Losal",
      "Luckeesarai",
      "Lucknow",
      "Ludhiana",
      "Lumding Railway Colony",
      "Lunavada",
      "Lunglei",
      "Macherla",
      "Machhiwara",
      "Machhlishahr",
      "Machilipatnam",
      "Madambakkam",
      "Madanapalle",
      "Madanpur",
      "Maddur",
      "Madgaon",
      "Madhipura",
      "Madhoganj",
      "Madhogarh",
      "Madhubani",
      "Madhugiri",
      "Madhupur",
      "Madhyamgram",
      "Madikeri",
      "Madipakkam",
      "Madugula",
      "Madukkarai",
      "Madukkur",
      "Madurai",
      "Madurantakam",
      "Magadi",
      "Maghar",
      "Mahabaleshwar",
      "Mahad",
      "Mahalingpur",
      "Maham",
      "Maharaganj",
      "Maharajgani",
      "Mahasamund",
      "Mahbubabad",
      "Mahbubnagar",
      "Mahe",
      "Mahemdavad",
      "Mahendragarh",
      "Maheshtala",
      "Maheshwar",
      "Mahgawan",
      "Mahiari",
      "Mahishadal",
      "Mahmudabad",
      "Mahoba",
      "Maholi",
      "Mahudha",
      "Mahwah",
      "Maihar",
      "Mailani",
      "Mainaguri",
      "Maindargi",
      "Mainpuri",
      "Mairang",
      "Mairwa",
      "Majalgaon",
      "Majholi",
      "Majitha",
      "Makhu",
      "Makrana",
      "Maksi",
      "Makum",
      "Malakanagiri",
      "Malappuram",
      "Malaut",
      "Malavalli",
      "Malegaon",
      "Maler Kotla",
      "Malihabad",
      "Malkajgiri",
      "Malkapur",
      "Malkapur",
      "Malkapur",
      "Malkera",
      "Mallapuram",
      "Mallasamudram",
      "Mallur",
      "Malpe",
      "Malpura",
      "Malur",
      "Malvan",
      "Manali",
      "Manamadurai",
      "Manapparai",
      "Manasa",
      "Manavadar",
      "Manavalakurichi",
      "Manawar",
      "Manchar",
      "Mancheral",
      "Mandal",
      "Mandal",
      "Mandalgarh",
      "Mandamarri",
      "Mandapam",
      "Mandapeta",
      "Mandawar",
      "Mandawar",
      "Mandi",
      "Mandideep",
      "Mandla",
      "Mandleshwar",
      "Mandsaur",
      "Mandvi",
      "Mandvi",
      "Mandya",
      "Maner",
      "Mangalagiri",
      "Mangalam",
      "Mangaldai",
      "Mangalore",
      "Mangawan",
      "Manglaur",
      "Mangrol",
      "Mangrol",
      "Mangrul Pir",
      "Maniar",
      "Manihari",
      "Manikpur",
      "Manipal",
      "Manjeri",
      "Manjhanpur",
      "Mankachar",
      "Manmad",
      "Mannarakkat",
      "Mannargudi",
      "Manohar Thana",
      "Manoharpur",
      "Manoharpur",
      "Mansa",
      "Mansa",
      "Manthani",
      "Manuguru",
      "Manvi",
      "Manwat",
      "Mapuca",
      "Marahra",
      "Marakkanam",
      "Marandahalli",
      "Marayur",
      "Margherita",
      "Marhaura",
      "Mariahu",
      "Mariani",
      "Markapur",
      "Masaurhi Buzurg",
      "Masila",
      "Matabhanga",
      "Mathura",
      "Mattanur",
      "Mau",
      "Mau",
      "Mau Aimma",
      "Maudaha",
      "Mauganj",
      "Maur",
      "Mauranwan",
      "Mavelikara",
      "Mavoor",
      "Mawana",
      "Mayang Imphal",
      "Mayiladuthurai",
      "Medak",
      "Medinipur",
      "Meerut",
      "Meethari Marwar",
      "Meghraj",
      "Mehkar",
      "Mehnagar",
      "Mehndawal",
      "Melur",
      "Memari",
      "Mendarda",
      "Merta",
      "Mettupalayam",
      "Mettur",
      "Mhasvad",
      "Mihona",
      "Milak",
      "Minicoy",
      "Minjur",
      "Miranpur",
      "Miranpur Katra",
      "Mirganj",
      "Mirialguda",
      "Mirik",
      "Mirzapur",
      "Misrikh",
      "Modasa",
      "Moga",
      "Mohali",
      "Mohan",
      "Mohanur",
      "Mohgaon",
      "Mohiuddinnagar",
      "Moirang",
      "Mokameh",
      "Mokokchung",
      "Mon",
      "Monghyr",
      "Monoharpur",
      "Moradabad",
      "Moram",
      "Morar",
      "Morbi",
      "Morena",
      "Morigaon",
      "Morinda",
      "Mormugao",
      "Morsi",
      "Morwa",
      "Moth",
      "Mothihari",
      "Mubarakpur",
      "Mudbidri",
      "Muddebihal",
      "Mudgal",
      "Mudhol",
      "Mudkhed",
      "Mudukulattur",
      "Mughal Sarai",
      "Muhammadabad",
      "Muhammadabad",
      "Muhammadabad",
      "Mukerian",
      "Mukher",
      "Muktsar",
      "Mul",
      "Mulanur",
      "Mulbagal",
      "Mulgund",
      "Mulki",
      "Multai",
      "Mulugu",
      "Muluppilagadu",
      "Mumbai",
      "Mundargi",
      "Mundgod",
      "Mundi",
      "Mundra",
      "Mundwa",
      "Mungaoli",
      "Mungeli",
      "Munnar",
      "Muradnagar",
      "Muragacha",
      "Murbad",
      "Muri",
      "Murliganj",
      "Mursan",
      "Murshidabad",
      "Murtajapur",
      "Murud",
      "Murudeshwara",
      "Murwara",
      "Mushabani",
      "Musiri",
      "Mussoorie",
      "Muttupet",
      "Muvattupula",
      "Muvattupuzha",
      "Muzaffarnagar",
      "Muzaffarpur",
      "Mysore",
      "Nabha",
      "Nabinagar",
      "Nadapuram",
      "Nadbai",
      "Nadiad",
      "Naduvannur",
      "Naduvattam",
      "Nagamangala",
      "Nagar",
      "Nagar Karnul",
      "Nagari",
      "Nagarukhra City",
      "Nagaur",
      "Nagda",
      "Nagercoil",
      "Nagina",
      "Nagireddipalli",
      "Nagod",
      "Nagothana",
      "Nagpur",
      "Nahan",
      "Naharlagun",
      "Nahorkatiya",
      "Naihati",
      "Naini Tal",
      "Nainpur",
      "Nainwa",
      "Najafgarh",
      "Najibabad",
      "Nakodar",
      "Naksalbari",
      "Nakur",
      "Nalagarh",
      "Naldurg",
      "Nalgonda",
      "Nalhati",
      "Naliya",
      "Namagiripettai",
      "Namakkal",
      "Nambiyur",
      "Namrup",
      "Nanauta",
      "Nanded",
      "Nandgaon",
      "Nandgaon",
      "Nandigama",
      "Nandikotkur",
      "Nandura Buzurg",
      "Nandurbar",
      "Nandyal",
      "Nangal",
      "Nangloi Jat",
      "Nanjangud",
      "Nannilam",
      "Nanpara",
      "Napasar",
      "Naraina",
      "Naraini",
      "Narasannapeta",
      "Narasapur",
      "Narasaraopet",
      "Narasingapuram",
      "Narauli",
      "Naraura",
      "Naravarikuppam",
      "Narayanavanam",
      "Narayangarh",
      "Narayangarh",
      "Narayanpet",
      "Naregal",
      "Narela",
      "Nargund",
      "Narmadapuram",
      "Narnaul",
      "Narnaund",
      "Narsimhapur",
      "Narsinghgarh",
      "Narsipatnam",
      "Narwana",
      "Narwar",
      "Nashik",
      "Nasirabad",
      "Naspur",
      "Nasriganj",
      "Nasrullahganj",
      "Natham",
      "Nathdwara",
      "Naugachhia",
      "Nautanwa",
      "Navadwip",
      "Navalgund",
      "Navelim",
      "Navi Mumbai",
      "Navsari",
      "Nawa",
      "Nawabganj",
      "Nawabganj",
      "Nawabganj",
      "Nawabganj",
      "Nawada",
      "Nawalgarh",
      "Nawanshahr",
      "Nawanshahr",
      "Nayagarh",
      "Nayudupet",
      "Nazira",
      "Nedumangad",
      "Neelankarai",
      "Neem ka Thana",
      "Negapatam",
      "Nelamangala",
      "Nellikkuppam",
      "Nellore",
      "Nepanagar",
      "Neral",
      "New Delhi",
      "Neyveli",
      "Neyyattinkara",
      "Nichlaul",
      "Nidadavole",
      "Nihtaur",
      "Nilakottai",
      "Nilanga",
      "Nileshwar",
      "Nilgiri",
      "Nilokheri",
      "Nimaj",
      "Nimaparha",
      "Nimbahera",
      "Nipani",
      "Nirmal",
      "Nirmali",
      "Nirsa",
      "Niwai",
      "Niwari",
      "Nizamabad",
      "Nizamabad",
      "Noamundi",
      "Nohar",
      "Noida",
      "Nokha",
      "Nongpoh",
      "Nongstoin",
      "North Guwahati",
      "North Lakhimpur",
      "Nowrangapur",
      "Nuh",
      "Nurmahal",
      "Nurpur",
      "Nuzvid",
      "Nyamti",
      "Obra",
      "Odlabari",
      "Okha",
      "Olpad",
      "Omalur",
      "Ongole",
      "Ooty",
      "Orai",
      "Ottappalam",
      "Ozar",
      "Pachora",
      "Pachperwa",
      "Padam",
      "Padampur",
      "Padampur",
      "Padmanabhapuram",
      "Padra",
      "Padrauna",
      "Pahasu",
      "Paithan",
      "Pakala",
      "Pakur",
      "Palakkad",
      "Palakkodu",
      "Palakollu",
      "Palani",
      "Palanpur",
      "Palasa",
      "Palavakkam",
      "Palera",
      "Palghar",
      "Pali",
      "Pali",
      "Palia Kalan",
      "Palitana",
      "Paliyad",
      "Palkonda",
      "Palladam",
      "Pallappatti",
      "Pallavaram",
      "Pallikonda",
      "Pallippatti",
      "Palmaner",
      "Paloncha",
      "Palwal",
      "Palwancha",
      "Panagar",
      "Panaji",
      "Panamaram",
      "Panchgani",
      "Panchkula",
      "Panchla",
      "Pandaria",
      "Pandatarai",
      "Pandhana",
      "Pandharpur",
      "Pandhurna",
      "Pandua",
      "Panihati",
      "Panipat",
      "Panna",
      "Panruti",
      "Pansemal",
      "Panvel",
      "Paonta Sahib",
      "Papanasam",
      "Papparappatti",
      "Pappinissheri",
      "Paradip Garh",
      "Paramagudi",
      "Paramathi Velur",
      "Parasia",
      "Paravur Tekkumbhagam",
      "Parbhani",
      "Pardi",
      "Parichhatgarh",
      "Pariyapuram",
      "Parlakimidi",
      "Parli Vaijnath",
      "Parnera",
      "Parola",
      "Parshadepur",
      "Partapur",
      "Partur",
      "Parvatipuram",
      "Parvatsar",
      "Pasan",
      "Pasighat",
      "Patamundai",
      "Patan",
      "Patan",
      "Patan",
      "Patancheru",
      "Pataudi",
      "Pathalgaon",
      "Pathanamthitta",
      "Pathankot",
      "Pathardi",
      "Pathardih",
      "Patharia",
      "Pathri",
      "Patiala",
      "Patiali",
      "Patna",
      "Patnagarh",
      "Patrasaer",
      "Pattan",
      "Patti",
      "Pattukkottai",
      "Patur",
      "Pauri",
      "Pavugada",
      "Pavuluru",
      "Pawai",
      "Pawayan",
      "Pawni",
      "Payyannur",
      "Pedana",
      "Peddapalli",
      "Peddapuram",
      "Pehowa",
      "Pen",
      "Pendra",
      "Pennadam",
      "Pennagaram",
      "Penugonda",
      "Penukonda",
      "Perambalur",
      "Peranampattu",
      "Peravurani",
      "Periyakulam",
      "Periyanayakkanpalaiyam",
      "Periyapatti",
      "Perumbavoor",
      "Perumpavur",
      "Perundurai",
      "Perungudi",
      "Perya",
      "Petlad",
      "Petlawad",
      "Phagwara",
      "Phalauda",
      "Phalodi",
      "Phaltan",
      "Phaphund",
      "Phek",
      "Phillaur",
      "Phirangipuram",
      "Phulbani",
      "Phulera",
      "Phulpur",
      "Phusro",
      "Pihani",
      "Pilani",
      "Pilibangan",
      "Pilibhit",
      "Pilkhua",
      "Pimpri",
      "Pinahat",
      "Pindwara",
      "Pinjaur",
      "Pipar",
      "Pipili",
      "Pipraich",
      "Pipri",
      "Piravam",
      "Pirawa",
      "Piriyapatna",
      "Piro",
      "Pithampur",
      "Pithapuram",
      "Pithoragarh",
      "Podaturpet",
      "Pokaran",
      "Polasara",
      "Polavaram",
      "Pollachi",
      "Polur",
      "Ponda",
      "Ponmana",
      "Ponnamaravati",
      "Ponnani",
      "Ponneri",
      "Ponnur",
      "Ponnuru",
      "Poonamalle",
      "Porbandar",
      "Porsa",
      "Port Blair",
      "Porur",
      "Powai",
      "Pratapgarh",
      "Pratapgarh",
      "Prayagraj",
      "Proddatur",
      "Puducherry",
      "Pudukkottai",
      "Pudur",
      "Pujali",
      "Pukhrayan",
      "Pulgaon",
      "Pulivendla",
      "Puliyangudi",
      "Puliyur",
      "Pullambadi",
      "Pulwama",
      "Punahana",
      "Punalur",
      "Punasa",
      "Punch",
      "Pundri",
      "Pune",
      "Punganuru",
      "Punjai Puliyampatti",
      "Pupri",
      "Puranpur",
      "Puri",
      "Purna",
      "Purnia",
      "Puruliya",
      "Purushottampur",
      "Purwa",
      "Pusad",
      "Pushkar",
      "Puttur",
      "Puttur",
      "Qadian",
      "Quepem",
      "Quthbullapur",
      "Rabkavi",
      "Rabupura",
      "Radaur",
      "Radhanpur",
      "Raebareli",
      "Rafiganj",
      "Raghogarh",
      "Raghudebbati",
      "Raghunathpur",
      "Rahatgarh",
      "Rahimatpur",
      "Rahon",
      "Rahuri",
      "Raichur",
      "Raiganj",
      "Raigarh",
      "Raikot",
      "Raipur",
      "Raipur",
      "Raipur",
      "Raisen",
      "Raisinghnagar",
      "Raj-Nandgaon",
      "Raja Sansi",
      "Rajahmundry",
      "Rajakhera",
      "Rajaldesar",
      "Rajaori",
      "Rajapalaiyam",
      "Rajapur",
      "Rajapur",
      "Rajgarh",
      "Rajgarh",
      "Rajgarh",
      "Rajgarh",
      "Rajgir",
      "Rajgurunagar",
      "Rajkot",
      "Rajmahal",
      "Rajnagar",
      "Rajpipla",
      "Rajpur",
      "Rajpur",
      "Rajpur Sonarpur",
      "Rajpura",
      "Rajsamand",
      "Rajula",
      "Rajur",
      "Rajura",
      "Ramachandrapuram",
      "Ramagundam",
      "Ramanathapuram",
      "Ramanayyapeta",
      "Ramanuj Ganj",
      "Ramapuram",
      "Rambha",
      "Rameswaram",
      "Ramganj Mandi",
      "Ramgarh",
      "Ramgarh",
      "Ramgundam",
      "Ramjibanpur",
      "Ramkola",
      "Ramnagar",
      "Ramnagar",
      "Ramnagar",
      "Ramnagar",
      "Rampachodavaram",
      "Rampur",
      "Rampur",
      "Rampur Hat",
      "Rampura",
      "Rampura",
      "Rampura",
      "Ramtek",
      "Ranaghat",
      "Ranapur",
      "Ranavav",
      "Ranchi",
      "Rangapara",
      "Rangia",
      "Rani",
      "Rania",
      "Ranibennur",
      "Raniganj",
      "Ranikhet",
      "Ranipet",
      "Ranipur",
      "Ranir Bazar",
      "Rapar",
      "Rasipuram",
      "Rasra",
      "Ratangarh",
      "Ratanpur",
      "Rath",
      "Ratia",
      "Ratlam",
      "Ratnagiri",
      "Raver",
      "Rawatbhata",
      "Rawatsar",
      "Raxaul",
      "Raya",
      "Rayachoti",
      "Rayadrug",
      "Raybag",
      "Razam",
      "Razampeta",
      "Razole",
      "Rehli",
      "Rehti",
      "Remuna",
      "Renigunta",
      "Renukut",
      "Reoti",
      "Repalle",
      "Revadanda",
      "Revelganj",
      "Rewa",
      "Rewari",
      "Richha",
      "Ringas",
      "Rishikesh",
      "Rishra",
      "Risod",
      "Robertsganj",
      "Robertsonpet",
      "Roha",
      "Rohini",
      "Rohtak",
      "Ron",
      "Roorkee",
      "Ropar",
      "Rourkela",
      "Rudarpur",
      "Rura",
      "Rusera",
      "Sabalgarh",
      "Sachin",
      "Sadabad",
      "Sadalgi",
      "Sadashivpet",
      "Sadat",
      "Sadri",
      "Safidon",
      "Safipur",
      "Sagar",
      "Sagauli",
      "Saharanpur",
      "Saharsa",
      "Sahaspur",
      "Sahaswan",
      "Sahawar",
      "Sahibganj",
      "Saidpur",
      "Saidpur",
      "Saiha",
      "Sailana",
      "Saint Thomas Mount",
      "Sainthia",
      "Saitlaw",
      "Sakharkherda",
      "Sakleshpur",
      "Sakti",
      "Salaya",
      "Salem",
      "Salon",
      "Salumbar",
      "Salur",
      "Samalkha",
      "Samalkot",
      "Samastipur",
      "Samba",
      "Sambalpur",
      "Sambhaji Nagar",
      "Sambhal",
      "Sambhar",
      "Samdari",
      "Samrala",
      "Samthar",
      "Sanand",
      "Sanaur",
      "Sanawad",
      "Sanchor",
      "Sancoale",
      "Sandi",
      "Sandila",
      "Sandur",
      "Sangamner",
      "Sangareddi",
      "Sangaria",
      "Sangli",
      "Sangod",
      "Sangola",
      "Sangrur",
      "Sankeshwar",
      "Sankheda",
      "Sankrail",
      "Sanquelim",
      "Sanwer",
      "Saoner",
      "Sapatgram",
      "Sarai Akil",
      "Sarai Ekdil",
      "Sarai Mir",
      "Saraikela",
      "Saraipali",
      "Sarangarh",
      "Sarangpur",
      "Sarauli",
      "Sardarshahr",
      "Sardhana",
      "Sardulgarh",
      "Sargur",
      "Sarkhej",
      "Sarwar",
      "Sasni",
      "Sasvad",
      "Satana",
      "Satara",
      "Sathankulam",
      "Sathupalli",
      "Sathyamangalam",
      "Satna",
      "Satrikh",
      "Sattenapalle",
      "Sattur",
      "Satwas",
      "Saugor",
      "Saundatti",
      "Saurikh",
      "Sausar",
      "Savantvadi",
      "Savanur",
      "Savarkundla",
      "Savda",
      "Sawai Madhopur",
      "Sayalkudi",
      "Sayla",
      "Secunderabad",
      "Sehore",
      "Selu",
      "Sendhwa",
      "Seohara",
      "Seondha",
      "Seoni",
      "Seoni Malwa",
      "Seram",
      "Serchhip",
      "Serilingampalle",
      "Serula",
      "Seven Pagodas",
      "Shadipur Julana",
      "Shahabad",
      "Shahabad",
      "Shahabad",
      "Shahabad",
      "Shahada",
      "Shahapur",
      "Shahbazpur",
      "Shahdol",
      "Shahganj",
      "Shahgarh",
      "Shahi",
      "Shahjanpur",
      "Shahkot",
      "Shahpur",
      "Shahpur",
      "Shahpur",
      "Shahpur",
      "Shahpur",
      "Shahpur",
      "Shahpura",
      "Shahpura",
      "Shahpura",
      "Shahuwadi",
      "Shajapur",
      "Shamgarh",
      "Shamli",
      "Shamsabad",
      "Shamsabad",
      "Shankargarh",
      "Shantipur",
      "Shegaon",
      "Sheikhpura",
      "Sheoganj",
      "Sheohar",
      "Sheopur",
      "Shergarh",
      "Sherghati",
      "Sherkot",
      "Shertallai",
      "Shi Yomi",
      "Shiggaon",
      "Shikarpur",
      "Shikarpur",
      "Shikohabad",
      "Shillong",
      "Shimla",
      "Shimoga",
      "Shiraguppi",
      "Shirdi",
      "Shirhatti",
      "Shirpur",
      "Shirwal",
      "Shishgarh",
      "Shivaji Nagar",
      "Shivpuri",
      "Sholapur",
      "Sholinghur",
      "Shoranur",
      "Shorapur",
      "Shrigonda",
      "Shrirampur",
      "Shrirangapattana",
      "Shujalpur",
      "Shupiyan",
      "Shyamnagar",
      "Sibsagar",
      "Siddapur",
      "Siddhapur",
      "Siddipet",
      "Sidhauli",
      "Sidhi",
      "Sidhpura",
      "Sidlaghatta",
      "Sihor",
      "Sihora",
      "Sijua",
      "Sikandarabad",
      "Sikandarpur",
      "Sikandra",
      "Sikandra Rao",
      "Sikar",
      "Sikka",
      "Silao",
      "Silapathar",
      "Silchar",
      "Siliguri",
      "Sillod",
      "Silvassa",
      "Simaria",
      "Simdega",
      "Simga",
      "Sindgi",
      "Sindhnur",
      "Sindi",
      "Singanallur",
      "Singapur",
      "Singarayakonda",
      "Singrauli",
      "Singur",
      "Sinnar",
      "Sinor",
      "Sira",
      "Sirathu",
      "Sirhind",
      "Sirkazhi",
      "Sirmaur",
      "Sirohi",
      "Sironj",
      "Sirpur",
      "Sirsa",
      "Sirsa",
      "Sirsaganj",
      "Sirsi",
      "Sirsi",
      "Sirsilla",
      "Siruguppa",
      "Sirumugai",
      "Sirur",
      "Sisauli",
      "Siswa Bazar",
      "Sitamarhi",
      "Sitamau",
      "Sitapur",
      "Sitarganj",
      "Siuri",
      "Sivaganga",
      "Sivagiri",
      "Sivagiri",
      "Sivakasi",
      "Siwan",
      "Siwana",
      "Soalkuchi",
      "Sohagpur",
      "Sohna",
      "Sojat",
      "Sojitra",
      "Solan",
      "Solap",
      "Solim",
      "Someshwar",
      "Sompeta",
      "Sonamukhi",
      "Sonamura",
      "Sonari",
      "Sonegaon",
      "Sonepur",
      "Songadh",
      "Sonipat",
      "Sopur",
      "Sorada",
      "Soro",
      "Soron",
      "Soygaon",
      "Soyibug",
      "Sri Dungargarh",
      "Sri Madhopur",
      "Srikakulam",
      "Srimushnam",
      "Srinagar",
      "Srinagar",
      "Srinivaspur",
      "Sriperumbudur",
      "Sriramnagar",
      "Srirampur",
      "Srisailain",
      "Srivaikuntam",
      "Srivardhan",
      "Srivilliputhur",
      "Suar",
      "Suchindram",
      "Sujangarh",
      "Suket",
      "Sultanpur",
      "Sultanpur",
      "Sulur",
      "Suluru",
      "Sulya",
      "Sumbal",
      "Sunam",
      "Sundargarh",
      "Sundarnagar",
      "Sunel",
      "Supaul",
      "Surajgarh",
      "Surandai",
      "Surat",
      "Suratgarh",
      "Surendranagar",
      "Surianwan",
      "Suriapet",
      "Susner",
      "Tadepalle",
      "Tadepallegudem",
      "Tadpatri",
      "Tajpur",
      "Takhatgarh",
      "Takhatpur",
      "Taki",
      "Tal",
      "Talaja",
      "Talbahat",
      "Talcher",
      "Talegaon Dabhade",
      "Taleigao",
      "Talgram",
      "Talikota",
      "Talipparamba",
      "Taloda",
      "Talwandi Bhai",
      "Talwara",
      "Tambaram",
      "Tamluk",
      "Tanakpur",
      "Tanda",
      "Tanda",
      "Tandur",
      "Tankara",
      "Tanuku",
      "Taoru",
      "Tarakeswar",
      "Taramangalam",
      "Tarana",
      "Taranagar",
      "Tarikere",
      "Tarn Taran",
      "Tasgaon",
      "Tattayyangarpettai",
      "Teghra",
      "Tehri",
      "Tekanpur",
      "Tekari",
      "Tekkalakote",
      "Tekkali",
      "Telhara",
      "Tellicherry",
      "Tendukheda",
      "Teni",
      "Teonthar",
      "Terdal",
      "Tezpur",
      "Tezu",
      "Thakurdwara",
      "Thakurganj",
      "Than",
      "Thana Bhawan",
      "Thandla",
      "Thane",
      "Thanesar",
      "Thanjavur",
      "Tharad",
      "Tharangambadi",
      "Thasra",
      "Thenkasi",
      "Thirukattupalli",
      "Thiruthani",
      "Thiruvaiyaru",
      "Thiruvananthapuram",
      "Thiruvarur",
      "Thiruvidaimaruthur",
      "Thoothukudi",
      "Thoubal",
      "Thrissur",
      "Tijara",
      "Tikamgarh",
      "Tikri",
      "Tilhar",
      "Tindivanam",
      "Tindwari",
      "Tinnanur",
      "Tinsukia",
      "Tiptur",
      "Tirthahalli",
      "Tiruchchendur",
      "Tiruchengode",
      "Tiruchirappalli",
      "Tirukkoyilur",
      "Tirumala",
      "Tirumullaivasal",
      "Tirunelveli",
      "Tirupati",
      "Tirupparangunram",
      "Tiruppur",
      "Tiruppuvanam",
      "Tirur",
      "Tiruttangal",
      "Tiruvalla",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvottiyur",
      "Tisaiyanvilai",
      "Titagarh",
      "Titlagarh",
      "Titron",
      "Todabhim",
      "Todaraisingh",
      "Tohana",
      "Tondi",
      "Tonk",
      "Tori-Fatehpur",
      "Tosham",
      "Tral",
      "Trimbak",
      "Tuensang",
      "Tufanganj",
      "Tuljapur",
      "Tulsipur",
      "Tumkur",
      "Tumsar",
      "Tundla",
      "Tuni",
      "Tura",
      "Turaiyur",
      "Turuvekere",
      "Udaipur",
      "Udaipur",
      "Udaipur",
      "Udaipura",
      "Udalguri",
      "Udangudi",
      "Udayagiri",
      "Udgir",
      "Udhampur",
      "Udpura",
      "Udumalaippettai",
      "Udupi",
      "Ujhani",
      "Ujjain",
      "Uklana",
      "Ulhasnagar",
      "Ullal",
      "Umarga",
      "Umaria",
      "Umarkhed",
      "Umarkot",
      "Umrala",
      "Umred",
      "Umreth",
      "Un",
      "Un",
      "Una",
      "Una",
      "Unhel",
      "Uniara",
      "Unjha",
      "Unnao",
      "Upleta",
      "Uppal Kalan",
      "Uran",
      "Uravakonda",
      "Usehat",
      "Usilampatti",
      "Utran",
      "Utraula",
      "Uttamapalaiyam",
      "Uttarkashi",
      "Uttiramerur",
      "V.S.K.Valasai (Dindigul-Dist.)",
      "Vada",
      "Vadakku Valliyur",
      "Vadakku Viravanallur",
      "Vadamadurai",
      "Vadigenhalli",
      "Vadippatti",
      "Vadlapudi",
      "Vadnagar",
      "Vadodara",
      "Vagator",
      "Vaghodia",
      "Vaijapur",
      "Vaikam",
      "Valabhipur",
      "Valangaiman",
      "Valavanur",
      "Vallabh Vidyanagar",
      "Vallam",
      "Valparai",
      "Valsad",
      "Vandalur",
      "Vandavasi",
      "Vaniyambadi",
      "Vansada",
      "Vapi",
      "Varanasi",
      "Varangaon",
      "Varkala",
      "Vartej",
      "Vasa",
      "Vasco da Gama",
      "Vasind",
      "Vasudevanallur",
      "Vattalkundu",
      "Vayalar",
      "Vedaraniyam",
      "Vedasandur",
      "Vejalpur",
      "Vellore",
      "Velur",
      "Vemalwada",
      "Vengurla",
      "Venkatagiri",
      "Vepagunta",
      "Veraval",
      "Vetapalem",
      "Vettaikkaranpudur",
      "Vettavalam",
      "Vettur",
      "Vidisha",
      "Vijapur",
      "Vijayawada",
      "Vikarabad",
      "Vikasnagar",
      "Vikravandi",
      "Vilattikulam",
      "Villupuram",
      "Vinchia",
      "Vinukonda",
      "Viraganur",
      "Virajpet",
      "Virar",
      "Virudunagar",
      "Visakhapatnam",
      "Visavadar",
      "Visnagar",
      "Vite",
      "Vizianagaram",
      "Vriddhachalam",
      "Vrindavan",
      "Vuyyuru",
      "Vyara",
      "Wadgaon",
      "Wadi",
      "Wai",
      "Walajabad",
      "Walajapet",
      "Wani",
      "Wankaner",
      "Wanparti",
      "Warangal",
      "Waraseoni",
      "Wardha",
      "Waris Aliganj",
      "Warora",
      "Warud",
      "Washim",
      "Wazirganj",
      "Wellington",
      "Wer",
      "Wokha",
      "Yadgir",
      "Yamunanagar",
      "Yanam",
      "Yanamalakuduru",
      "Yaval",
      "Yavatmal",
      "Yelahanka",
      "Yelbarga",
      "Yellandu",
      "Yellapur",
      "Yeola",
      "Yol",
      "Zahirabad",
      "Zaidpur",
      "Zamania",
      "Zira",
      "Ziro",
      "Zunheboto",
      "patamda",
    ],
  },
  Indonesia: {
    code: "ID",
    cities: [
      "Abepura",
      "Adiwerna",
      "Agats",
      "Amahai",
      "Ambarawa",
      "Ambon",
      "Amlapura",
      "Amlapura city",
      "Amuntai",
      "Arjawinangun",
      "Astanajapura",
      "Atambua",
      "Babat",
      "Baekrajan",
      "Baki",
      "Balai Pungut",
      "Balapulang",
      "Balikpapan",
      "Balung",
      "Bambanglipuro",
      "Banda Aceh",
      "Bandar",
      "Bandar Lampung",
      "Bandung",
      "Bangil",
      "Bangkalan",
      "Banjar",
      "Banjar",
      "Banjaran",
      "Banjarmasin",
      "Bantul",
      "Banyumas",
      "Banyuwangi",
      "Barabai",
      "Batam",
      "Batang",
      "Batu",
      "Baturaden",
      "Baturaja",
      "Baubau",
      "Bedugul",
      "Bekasi",
      "Belawan",
      "Bengkulu",
      "Berastagi",
      "Besuki",
      "Bima",
      "Binjai",
      "Bintuni",
      "Bireun",
      "Bitung",
      "Blangpidie",
      "Blitar",
      "Blora",
      "Bogor",
      "Bojonegoro",
      "Bondowoso",
      "Bontang",
      "Boyolali",
      "Boyolangu",
      "Buaran",
      "Buduran",
      "Bukittinggi",
      "Bulakamba",
      "Candi Prambanan",
      "Caringin",
      "Ceper",
      "Cepu",
      "Ciamis",
      "Ciampea",
      "Cianjur",
      "Cibinong",
      "Cicurug",
      "Cikampek",
      "Cikarang",
      "Cikupa",
      "Cilacap",
      "Cileungsir",
      "Cileunyi",
      "Cimahi",
      "Ciputat",
      "Ciranjang-hilir",
      "Cirebon",
      "Citeureup",
      "Colomadu",
      "Comal",
      "Curug",
      "Curup",
      "Dampit",
      "Delanggu",
      "Deli Tua",
      "Demak",
      "Denpasar",
      "Depok",
      "Depok",
      "Diwek",
      "Dompu",
      "Driyorejo",
      "Dukuhturi",
      "Dumai",
      "Ende",
      "Fakfak",
      "Galesong",
      "Gambiran Satu",
      "Gampengrejo",
      "Gamping Lor",
      "Gatak",
      "Gebog",
      "Gedangan",
      "Genteng",
      "Godean",
      "Gombong",
      "Gongdanglegi Kulon",
      "Gorontalo",
      "Gresik",
      "Grogol",
      "Gunungsitoli",
      "Indramayu",
      "Jakarta",
      "Jambi City",
      "Jaten",
      "Jatibarang",
      "Jatiroto",
      "Jatiwangi",
      "Jayapura",
      "Jekulo",
      "Jember",
      "Jepara",
      "Jogonalan",
      "Jombang",
      "Juwana",
      "Kabanjahe",
      "Kalianget",
      "Kamal",
      "Karangampel",
      "Karanganom",
      "Karangsembung",
      "Kartasura",
      "Kasihan",
      "Katobu",
      "Kawalu",
      "Kebomas",
      "Kebonarun",
      "Kediri",
      "Kedungwaru",
      "Kedungwuni",
      "Kefamenanu",
      "Kencong",
      "Kendari",
      "Kepanjen",
      "Kertosono",
      "Ketanggungan",
      "Kisaran",
      "Klangenan",
      "Klaten",
      "Klungkung",
      "Kotabumi",
      "Kraksaan",
      "Kresek",
      "Krian",
      "Kroya",
      "Kuala Tungkal",
      "Kualakapuas",
      "Kudus",
      "Kuningan",
      "Kupang",
      "Kuta",
      "Kutoarjo",
      "Labuan",
      "Labuan Bajo",
      "Labuhan Deli",
      "Lahat",
      "Lamongan",
      "Langsa",
      "Lasem",
      "Lawang",
      "Lebaksiu",
      "Lembang",
      "Lhokseumawe",
      "Loa Janan",
      "Lubuklinggau",
      "Lumajang",
      "Luwuk",
      "Maba",
      "Madiun",
      "Magelang",
      "Majalengka",
      "Majenang",
      "Majene",
      "Makassar",
      "Malang",
      "Malinau",
      "Mamuju",
      "Manado",
      "Manggar",
      "Manismata",
      "Manokwari",
      "Margahayukencana",
      "Margasari",
      "Maros",
      "Martapura",
      "Masamba",
      "Masohi",
      "Mataram",
      "Maumere",
      "Medan",
      "Melati",
      "Mendaha",
      "Merauke",
      "Mertoyudan",
      "Metro",
      "Meulaboh",
      "Mlonggo",
      "Modisi",
      "Mojoagung",
      "Mojokerto",
      "Mranggen",
      "Muara Bungo",
      "Muara Teweh",
      "Muncar",
      "Muntilan",
      "Muntok",
      "Nabire",
      "Negara",
      "Nganjuk",
      "Ngawi",
      "Ngemplak",
      "Ngoro",
      "Ngunut",
      "Paciran",
      "Padalarang",
      "Padang",
      "Padangsidempuan",
      "Pagar Alam",
      "Pakisaji",
      "Palangkaraya",
      "Palembang",
      "Palimanan",
      "Palopo",
      "Palu",
      "Pamanukan",
      "Pamekasan",
      "Pameungpeuk",
      "Pamulang",
      "Panarukan",
      "Pandaan",
      "Pandak",
      "Pandeglang",
      "Pangkalan Brandan",
      "Pangkalanbuun",
      "Pangkalpinang",
      "Panji",
      "Pare",
      "Parepare",
      "Pariaman",
      "Parung",
      "Pasarkemis",
      "Paseh",
      "Pasuruan",
      "Pati",
      "Payakumbuh",
      "Pecangaan",
      "Pekalongan",
      "Pekan Bahapal",
      "Pekanbaru",
      "Pelabuhanratu",
      "Pemalang",
      "Pemangkat",
      "Pematangsiantar",
      "Perbaungan",
      "Percut",
      "Plumbon",
      "Polewali",
      "Ponorogo",
      "Pontianak",
      "Poso",
      "Prabumulih",
      "Praya",
      "Prigen",
      "Probolinggo",
      "Pundong",
      "Purbalingga",
      "Purwakarta",
      "Purwodadi",
      "Purwokerto",
      "Raha",
      "Rajapolah",
      "Randudongkal",
      "Rangkasbitung",
      "Ransiki",
      "Rantauprapat",
      "Rantepao",
      "Rembangan",
      "Rengasdengklok",
      "Reuleuet",
      "Ruteng",
      "Sabang",
      "Salatiga",
      "Samarinda",
      "Sampang",
      "Sampit",
      "Sanana",
      "Sanur",
      "Sawangan",
      "Selogiri",
      "Selong",
      "Semarang",
      "Sengkang",
      "Sepatan",
      "Serang",
      "Seririt",
      "Serpong",
      "Sewon",
      "Sibolga",
      "Sidareja",
      "Sidoarjo",
      "Sigli",
      "Sijunjung",
      "Simpang",
      "Sinabang",
      "Singaparna",
      "Singaraja",
      "Singkawang",
      "Singkil",
      "Singojuruh",
      "Singosari",
      "Sinjai",
      "Situbondo",
      "Slawi",
      "Sleman",
      "Soe",
      "Sofifi",
      "Sokaraja",
      "Soko",
      "Solok",
      "Soreang",
      "Sorong",
      "South Tangerang",
      "Sragen",
      "Srandakan",
      "Srono",
      "Stabat",
      "Sukabumi",
      "Sumbawa Besar",
      "Sumber",
      "Sumberpucung",
      "Sumedang",
      "Sumedang Utara",
      "Sumenep",
      "Sungai Penuh",
      "Sungai Raya",
      "Sungailiat",
      "Sunggal",
      "Surabaya",
      "Surakarta",
      "Tabanan",
      "Taliwang",
      "Tambolaka",
      "Tangerang",
      "Tanggulangin",
      "Tanjung",
      "Tanjung Balai",
      "Tanjung Pandan",
      "Tanjung Pinang",
      "Tanjung Selor",
      "Tanjungagung",
      "Tanjungbalai",
      "Tanjungtiram",
      "Tarakan",
      "Tarub",
      "Tasikmalaya",
      "Tayu",
      "Tebingtinggi",
      "Tegal",
      "Teluk Dalam",
      "Teluk Nibung",
      "Teluknaga",
      "Teminabuan",
      "Terbanggi Besar",
      "Ternate",
      "Tobelo",
      "Tomohon",
      "Tondano",
      "Tongging",
      "Trenggalek",
      "Trucuk",
      "Tual",
      "Tuban",
      "Tulangan Utara",
      "Tulungagung",
      "Ubud",
      "Ungaran",
      "Waingapu",
      "Waisai",
      "Wanaraja",
      "Wangon",
      "Watampone",
      "Wedi",
      "Welahan",
      "Weleri",
      "Weru",
      "Wiradesa",
      "Wongsorejo",
      "Wonopringgo",
      "Wonosari",
      "Wonosobo",
      "Yogyakarta",
    ],
  },
  "Iran, Islamic Rep. of": {
    code: "IR",
    cities: [
      "'Abas Abad",
      "Abadan",
      "Abadeh",
      "Abdanan",
      "Abhar",
      "Abrisham",
      "Abyek",
      "Aghajari",
      "Ahar",
      "Ahvaz",
      "Ajab Shir",
      "Akbarabad",
      "Alavijeh",
      "Aleshtar",
      "Aligudarz",
      "Alvand",
      "Alvand",
      "Amol",
      "Arak",
      "Ardabil",
      "Ardakan",
      "Ardestan",
      "Asadabad",
      "Astaneh-ye Ashrafiyeh",
      "Astara",
      "Azadshahr",
      "Azadshahr",
      "Azna",
      "Babol",
      "Babolsar",
      "Bafq",
      "Bahar",
      "Bam",
      "Bandar Abbas",
      "Bandar-e Anzali",
      "Bandar-e Genaveh",
      "Bandar-e Lengeh",
      "Baneh",
      "Bardaskan",
      "Bardsir",
      "Behbahan",
      "Behshahr",
      "Bijar",
      "Bileh Savar",
      "Birjand",
      "Bojnurd",
      "Bonab",
      "Borazjan",
      "Borujen",
      "Borujerd",
      "Boshruyeh",
      "Bukan",
      "Bushehr",
      "Chabahar",
      "Chalus",
      "Chenaran",
      "Damavand",
      "Damghan",
      "Darab",
      "Darreh Shahr",
      "Dehdasht",
      "Dehloran",
      "Delijan",
      "Dogonbadan",
      "Dorcheh Piaz",
      "Dowlatabad",
      "Eqbaliyeh",
      "Esfarayen",
      "Eslamshahr",
      "Falavarjan",
      "Fannuj",
      "Farrokh Shahr",
      "Farsan",
      "Fasa",
      "Fereydun Kenar",
      "Fereydunshahr",
      "Firuzabad",
      "Fuman",
      "Gerash",
      "Golestan",
      "Golpayegan",
      "Gonabad",
      "Gonbad-e Kavus",
      "Gorgan",
      "Hajjiabad",
      "Hamadan",
      "Harsin",
      "Hashtpar",
      "Hashtrud",
      "Ilam",
      "Iranshahr",
      "Isfahan",
      "Istgah-e Rah Ahan-e Garmsar",
      "Javanrud",
      "Juybar",
      "Kahriz",
      "Kalaleh",
      "Kamyaran",
      "Kangavar",
      "Karaj",
      "Kashmar",
      "Kazerun",
      "Kelishad va Sudarjan",
      "Kerman",
      "Kermanshah",
      "Khalkhal",
      "Khash",
      "Khomeyn",
      "Khomeyni Shahr",
      "Khorramabad",
      "Khorramdarreh",
      "Khorramshahr",
      "Khowy",
      "Khvansar",
      "Kish",
      "Kuhdasht",
      "Langarud",
      "Mahabad",
      "Mahdishahr",
      "Mahriz",
      "Malard",
      "Malayer",
      "Manjil",
      "Marand",
      "Marivan",
      "Marvdasht",
      "Mashhad",
      "Masjed Soleyman",
      "Mehran",
      "Meybod",
      "Miandoab",
      "Minab",
      "Mohr",
      "Nahavand",
      "Najafabad",
      "Naqadeh",
      "Nazarabad",
      "Neka",
      "Neyriz",
      "Neyshabur",
      "Nikshahr",
      "Nowshahr",
      "Nurabad",
      "Nurabad",
      "Omidiyeh",
      "Orumiyeh",
      "Oshnaviyeh",
      "Padegan-e Manjil",
      "Parsabad",
      "Pasargad",
      "Pasragad Branch",
      "Paveh",
      "Piranshahr",
      "Pishva",
      "Qa'en",
      "Qahderijan",
      "Qarah Zia' od Din",
      "Qarchak",
      "Qasr-e Qand",
      "Qazvin",
      "Qeshm",
      "Qom",
      "Qorveh",
      "Quchan",
      "Rafsanjan",
      "Ramhormoz",
      "Ramshir",
      "Rasht",
      "Ravar",
      "Rehnan",
      "Robat Karim",
      "Rudsar",
      "Sabzevar",
      "Salmas",
      "Sanandaj",
      "Saqqez",
      "Sarakhs",
      "Sari",
      "Sarpol-e Zahab",
      "Saveh",
      "Semirom",
      "Semnan",
      "Shadegan",
      "Shahin Dezh",
      "Shahr-e Babak",
      "Shahr-e Kord",
      "Shahre Jadide Andisheh",
      "Shahriar",
      "Shahrud",
      "Shiraz",
      "Shirvan",
      "Showt",
      "Shush",
      "Shushtar",
      "Sirjan",
      "Sonqor",
      "Surian",
      "Susangerd",
      "Tabas",
      "Tabriz",
      "Tafresh",
      "Taft",
      "Takab",
      "Takestan",
      "Taybad",
      "Tehran",
      "Tonekabon",
      "Torbat-e Heydariyeh",
      "Torbat-e Jam",
      "Varamin",
      "Yasuj",
      "Yazd",
      "Zabol",
      "Zahedan",
      "Zanjan",
      "Zarand",
      "Zehak",
    ],
  },
  Iraq: {
    code: "IQ",
    cities: [
      "'Afak",
      "'Ali al Gharbi",
      "'Anah",
      "'Anat al Qadimah",
      "'Aqrah",
      "Abu Ghurayb",
      "Ad Diwaniyah",
      "Ad Dujayl",
      "Al 'Amarah",
      "Al 'Aziziyah",
      "Al Basrah al Qadimah",
      "Al Fallujah",
      "Al Faw",
      "Al Harithah",
      "Al Hayy",
      "Al Hillah",
      "Al Hindiyah",
      "Al Kut",
      "Al Mawsil al Jadidah",
      "Al Miqdadiyah",
      "Al Mishkhab",
      "Al Musayyib",
      "Al-Hamdaniya",
      "AlZwya",
      "An Nu'maniyah",
      "Ar Rumaythah",
      "Ar Rutbah",
      "As Samawah",
      "As Sulaymaniyah",
      "As Suwayrah",
      "Ash Shamiyah",
      "Ash Shatrah",
      "Az Zubayr",
      "Baghdad",
      "Balad",
      "Baqubah",
      "Basrah",
      "Batifa",
      "Bayji",
      "Baynjiwayn",
      "Dihok",
      "Erbil",
      "Hadithah",
      "Hajiawa",
      "Halabja",
      "Hit",
      "Imam Qasim",
      "Jamjamal",
      "Karbala",
      "Khalis",
      "Kifri",
      "Kirkuk",
      "Koysinceq",
      "Kufa",
      "Mandali",
      "Mosul",
      "Nahiyat Ghammas",
      "Nahiyat Saddat al Hindiyah",
      "Nahiyat al Fuhud",
      "Nahiyat ash Shinafiyah",
      "Najaf",
      "Nasiriyah",
      "Ramadi",
      "Rawah",
      "Ruwandiz",
      "Samarra'",
      "Sinah",
      "Sinjar",
      "Soran",
      "Tallkayf",
      "Tikrit",
      "Tuz Khurmatu",
      "Umm Qasr",
      "Zaxo",
    ],
  },
  Ireland: {
    code: "IE",
    cities: [
      "An Muileann gCearr",
      "Arklow",
      "Ashbourne",
      "Athlone",
      "Balbriggan",
      "Ballina",
      "Ballincollig",
      "Blanchardstown",
      "Bray",
      "Cabinteely",
      "Carlow",
      "Carrigaline",
      "Castlebar",
      "Castletroy",
      "Celbridge",
      "Cill Airne",
      "Clondalkin",
      "Cluain Meala",
      "Cobh",
      "Cork",
      "Crumlin",
      "Donaghmede",
      "Donnybrook",
      "Douglas",
      "Drogheda",
      "Droichead Nua",
      "Dublin",
      "Dun Laoghaire",
      "Dundalk",
      "Dundrum",
      "Ennis",
      "Finglas",
      "Foxrock",
      "Galway",
      "Greystones",
      "Jobstown",
      "Kilkenny",
      "Kilquade",
      "Knocklyon",
      "Laytown",
      "Leixlip",
      "Letterkenny",
      "Limerick",
      "Lucan",
      "Malahide",
      "Mallow",
      "Marino",
      "Maynooth",
      "Midleton",
      "Naas",
      "Navan",
      "Palmerstown",
      "Portlaoise",
      "Rathfarnham",
      "Rathmines",
      "Sandyford",
      "Shankill",
      "Skerries",
      "Sligo",
      "South Dublin",
      "Swords",
      "Tallaght",
      "Tra Mhor",
      "Tralee",
      "Tullamore",
      "Waterford",
      "Wexford",
      "Wicklow",
    ],
  },
  "Isle of Man": {
    code: "IM",
    cities: ["Douglas"],
  },
  Israel: {
    code: "IL",
    cities: [
      "'Ar'ara",
      "'Ar'ara BaNegev",
      "'Ein Mahil",
      "'Isfiya",
      "Abu Sinan",
      "Acre",
      "Afula",
      "Arad",
      "Ariel",
      "Ashdod",
      "Ashkelon",
      "Azor",
      "Baqa el Gharbiya",
      "Bat Yam",
      "Be'er Ya'aqov",
      "Beersheba",
      "Beit Jann",
      "Bet She'an",
      "Bet Shemesh",
      "Binyamina",
      "Binyamina-Giv'at Ada",
      "Bnei Brak",
      "Daliyat al Karmel",
      "Deir Hanna",
      "Deir el Asad",
      "Dimona",
      "Eilat",
      "El Fureidis",
      "El'ad",
      "Er Reina",
      "Et Taiyiba",
      "Et Tira",
      "Even Yehuda",
      "Gan Yavne",
      "Ganei Tikva",
      "Gedera",
      "Giv'at Shmuel",
      "Givatayim",
      "Hadera",
      "Haifa",
      "Herzliya",
      "Hod HaSharon",
      "Holon",
      "Hura",
      "I'billin",
      "Iksal",
      "Jaffa",
      "Jaljulya",
      "Jatt",
      "Jerusalem",
      "Jisr ez Zarqa",
      "Judeida Makr",
      "Kabul",
      "Kadima Zoran",
      "Kafr Kanna",
      "Kafr Manda",
      "Kafr Qari'",
      "Kafr Qasim",
      "Karmi'el",
      "Kfar Saba",
      "Kfar Yasif",
      "KfarYona",
      "Kiryat Gat",
      "Kiryat Ono",
      "Kuseifa",
      "Laqiyya",
      "Lod",
      "Maale Iron",
      "Maghar",
      "Majd el Kurum",
      "Majdal Shams",
      "Mazkeret Batya",
      "MevasseretTsiyyon",
      "Migdal Ha'Emeq",
      "Modi'in Makkabbim Re'ut",
      "Modiin Ilit",
      "Nahariyya",
      "Nahf",
      "Nazareth",
      "Nazerat 'Illit",
      "Nesher",
      "Ness Ziona",
      "Netanya",
      "Netivot",
      "Ofaqim",
      "Or Akiva",
      "Or Yehuda",
      "Petah Tiqva",
      "Qalansuwa",
      "Qiryat 'Eqron",
      "Qiryat Ata",
      "Qiryat Bialik",
      "Qiryat Mal'akhi",
      "Qiryat Tiv'on",
      "Qiryat Yam",
      "QiryatMotsqin",
      "QiryatShmona",
      "Ra'anana",
      "Rahat",
      "Ramat Gan",
      "Ramat HaSharon",
      "Ramla",
      "Rehovot",
      "Rekhasim",
      "Rishon LeTsiyyon",
      "Rosh Ha'Ayin",
      "Safed",
      "Sakhnin",
      "Sderot",
      "Segev Shalom",
      "Shefar'am",
      "Shoham",
      "Tamra",
      "Tel Aviv",
      "Tel Mond",
      "Tel Sheva'",
      "Tiberias",
      "Tirat Karmel",
      "Umm el Fahm",
      "West Jerusalem",
      "Yafa",
      "Yavne",
      "Yehud",
      "Yehud-Monosson",
      "Yeroham",
      "Yirka",
      "Yoqne'am 'Illit",
      "Zikhron Ya'aqov",
      "maalot Tarshiha",
    ],
  },
  Italy: {
    code: "IT",
    cities: [
      "Abano Terme",
      "Abbiategrasso",
      "Acerra",
      "Aci Castello",
      "Aci Catena",
      "Aci Sant'Antonio",
      "Acilia-Castel Fusano-Ostia Antica",
      "Acireale",
      "Acquaviva delle Fonti",
      "Acqui Terme",
      "Adelfia",
      "Adrano",
      "Adria",
      "Afragola",
      "Agliana",
      "Agrate Brianza",
      "Agrigento",
      "Agropoli",
      "Alassio",
      "Alatri",
      "Alba",
      "Alba Adriatica",
      "Albano Laziale",
      "Albenga",
      "Albignasego",
      "Albino",
      "Alcamo",
      "Alessandria",
      "Alghero",
      "Alpignano",
      "Altamura",
      "Altavilla Vicentina",
      "Alzano Lombardo",
      "Ancona",
      "Andria",
      "Angri",
      "Anguillara Sabazia",
      "Anzio",
      "Aosta",
      "Apricena",
      "Aprilia",
      "Arcella",
      "Arco",
      "Arcore",
      "Ardea",
      "Arenella",
      "Arenzano",
      "Arese",
      "Arezzo",
      "Ariccia",
      "Arluno",
      "Arona",
      "Arpino",
      "Artena",
      "Arzano",
      "Arzignano",
      "Ascoli Piceno",
      "Assemini",
      "Asti",
      "Augusta",
      "Avellino",
      "Aversa",
      "Avezzano",
      "Avola",
      "Avvocata",
      "Azzano Decimo",
      "Bacoli",
      "Bagheria",
      "Bagnoli",
      "Bagnolo Mella",
      "Baranzate",
      "Barcellona Pozzo di Gotto",
      "Bareggio",
      "Bari",
      "Barletta",
      "Baronissi",
      "Barra",
      "Barrafranca",
      "Bassano del Grappa",
      "Bastia umbra",
      "Battipaglia",
      "Beccacivetta",
      "Bellaria-Igea Marina",
      "Bellizzi",
      "Belluno",
      "Belmonte Mezzagno",
      "Belpasso",
      "Benevento",
      "Bergamo",
      "Bernalda",
      "Bettola-Zeloforomagno",
      "Biancavilla",
      "Biassono",
      "Biella",
      "Bisceglie",
      "Bitetto",
      "Bitonto",
      "Bitritto",
      "Bollate",
      "Bologna",
      "Bolzano",
      "Borgaro Torinese",
      "Borgo San Dalmazzo",
      "Borgomanero",
      "Borgosesia",
      "Boscoreale",
      "Boscotrecase",
      "Bovisio-Masciago",
      "Bovolone",
      "Bra",
      "Bracciano",
      "Breo",
      "Brescia",
      "Bressanone",
      "Bresso",
      "Brindisi",
      "Bronte",
      "Brugherio",
      "Brunico",
      "Brusciano",
      "Bussolengo",
      "Busto Arsizio",
      "Busto Garolfo",
      "Cagliari",
      "Caivano",
      "Caldogno-Rettorgole-Cresole",
      "Calenzano",
      "Calolziocorte",
      "Caltagirone",
      "Caltanissetta",
      "Calvizzano",
      "Camaiore",
      "Cameri",
      "Campi Bisenzio",
      "Campi Salentina",
      "Campobasso",
      "Campobello di Licata",
      "Campodarsego",
      "Camponogara",
      "Canegrate",
      "Canicatti",
      "Canosa di Puglia",
      "Cantu",
      "Capannori",
      "Capo d'Orlando",
      "Capoterra",
      "Capua",
      "Capurso",
      "Carate Brianza",
      "Caravaggio",
      "Carbonia",
      "Cardano al Campo",
      "Cardito",
      "Carini",
      "Carlentini",
      "Carmagnola",
      "Caronno Pertusella",
      "Carovigno",
      "Carpenedolo",
      "Carpi Centro",
      "Carrara",
      "Carugate",
      "Casa Santa",
      "Casagiove",
      "Casal Bertone",
      "Casal Palocco",
      "Casal de' Pazzi",
      "Casal di Principe",
      "Casale Monferrato",
      "Casalecchio di Reno",
      "Casalmaggiore",
      "Casalnuovo di Napoli",
      "Casalpusterlengo",
      "Casamassima",
      "Casandrino",
      "Casarano",
      "Casavatore",
      "Cascina",
      "Caselle Torinese",
      "Caserta",
      "Casoria",
      "Cassano Allo Ionio",
      "Cassano Magnago",
      "Cassano d'Adda",
      "Cassano delle Murge",
      "Cassina de' Pecchi",
      "Cassino",
      "Castano Primo",
      "Castel Maggiore",
      "Castel Mella",
      "Castel San Giovanni",
      "Castel San Pietro Terme",
      "Castel Volturno",
      "Casteldaccia",
      "Castelfidardo",
      "Castelfiorentino",
      "Castelfranco Emilia",
      "Castelfranco Veneto",
      "Castellammare del Golfo",
      "Castellammare di Stabia",
      "Castellana",
      "Castellaneta",
      "Castellanza",
      "Castelnovo ne'Monti",
      "Castelvetrano",
      "Castiglione delle Stiviere",
      "Castrovillari",
      "Catania",
      "Catanzaro",
      "Cattolica",
      "Cava De Tirreni",
      "Ceccano",
      "Cecina",
      "Cefalu",
      "Ceglie Messapica",
      "Cento",
      "Cercola",
      "Cerea",
      "Cerignola",
      "Cernusco sul Naviglio",
      "Cerro Maggiore",
      "Certaldo",
      "Cerveteri",
      "Cervia",
      "Cervignano del Friuli",
      "Cervinara",
      "Cesano Boscone",
      "Cesano Maderno",
      "Cesate",
      "Cesena",
      "Cesenatico",
      "Chiaia",
      "Chiaiano",
      "Chiampo",
      "Chiaravalle",
      "Chiari",
      "Chiavari",
      "Chieri",
      "Chieti",
      "Chioggia",
      "Chiugiana-La Commenda",
      "Chivasso",
      "Ciampino",
      "Cicciano",
      "Cinisello Balsamo",
      "Cinisi",
      "Cirie",
      "Ciro Marina",
      "Cisterna di Latina",
      "Citta di Castello",
      "Cittadella",
      "Civita Castellana",
      "Civitanova Marche",
      "Civitavecchia",
      "Codogno",
      "Codroipo",
      "Colle di Val d'Elsa",
      "Colleferro",
      "Collegno",
      "Cologno Monzese",
      "Cologno al Serio",
      "Comiso",
      "Como",
      "Concesio",
      "Concorezzo",
      "Conegliano",
      "Conversano",
      "Copertino",
      "Corato",
      "Corbetta",
      "Cordenons",
      "Corigliano Scalo",
      "Corleone",
      "Cormano",
      "Cornaredo",
      "Correggio",
      "Corsico",
      "Cosenza",
      "Cossato",
      "Creazzo",
      "Crema",
      "Cremona",
      "Crispano",
      "Crispiano",
      "Crotone",
      "Cuneo",
      "Cusano",
      "Dalmine",
      "Darfo Boario Terme",
      "Desenzano del Garda",
      "Desio",
      "Domodossola",
      "Dueville",
      "Eboli",
      "Empoli",
      "Enna",
      "Erba",
      "Ercolano",
      "Este",
      "Fabriano",
      "Faenza",
      "Fagnano Olona-Bergoro",
      "Falconara Marittima",
      "Fano",
      "Fasano",
      "Favara",
      "Feltre",
      "Ferentino",
      "Fermo",
      "Ferrara",
      "Fiano Romano",
      "Ficarazzi",
      "Fidenza",
      "Figline Valdarno",
      "Fiorano",
      "Fiorenzuola d'Arda",
      "Fiumicino",
      "Florence",
      "Floridia",
      "Foggia",
      "Foligno",
      "Follonica",
      "Fondi",
      "Forio",
      "Forli",
      "Formia",
      "Formigine",
      "Fornacelle",
      "Fossano",
      "Francavilla Fontana",
      "Francavilla al Mare",
      "Francofonte",
      "Frascati",
      "Frattamaggiore",
      "Frattaminore",
      "Frosinone",
      "Fucecchio",
      "Fuorigrotta",
      "Gaeta",
      "Galatina",
      "Galatone",
      "Gallarate",
      "Galliate",
      "Gallipoli",
      "Gambettola",
      "Garbagnate Milanese",
      "Gardone Val Trompia",
      "Gela",
      "Gemona",
      "Genoa",
      "Genzano di Roma",
      "Ghedi",
      "Giardinetti-Tor Vergata",
      "Giarre",
      "Giaveno",
      "Giffoni Valle Piana",
      "Ginosa",
      "Gioia Tauro",
      "Gioia del Colle",
      "Giovinazzo",
      "Giugliano in Campania",
      "Giulianova",
      "Giussano",
      "Gorgonzola",
      "Gorizia",
      "Gragnano",
      "Grammichele",
      "Gravina di Catania",
      "Gravina in Puglia",
      "Gricignano di Aversa",
      "Grosseto",
      "Grottaferrata",
      "Grottaglie",
      "Grottammare",
      "Grugliasco",
      "Grumo Appula",
      "Grumo Nevano",
      "Guastalla",
      "Gubbio",
      "Guidonia",
      "Guidonia Montecelio",
      "Guspini",
      "Gussago",
      "Iglesias",
      "Imola",
      "Imperia",
      "Induno Olona",
      "Ischia",
      "Ischia Porto",
      "Isernia",
      "Isola del Liri",
      "Isola di Capo Rizzuto",
      "Ispica",
      "Ivrea",
      "Jesi",
      "L'Aquila",
      "La Maddalena",
      "La Massimina-Casal Lumbroso",
      "La Spezia",
      "Ladispoli",
      "Lainate",
      "Laives",
      "Lamezia Terme",
      "Lancenigo-Villorba",
      "Lanciano",
      "Lariano",
      "Lastra a Signa",
      "Laterza",
      "Latiano",
      "Latina",
      "Lavagna",
      "Lavello",
      "Lecce",
      "Lecco",
      "Legnago",
      "Legnano",
      "Leini",
      "Leno",
      "Lentate sul Seveso",
      "Lentini",
      "Leonforte",
      "Leverano",
      "Licata",
      "Lido",
      "Lido di Jesolo",
      "Lido di Ostia",
      "Limbiate",
      "Lissone",
      "Livorno",
      "Lizzano",
      "Loano",
      "Locri",
      "Lodi",
      "Lonate Pozzolo",
      "Lonigo",
      "Lucca",
      "Lucera",
      "Lugo",
      "Luino",
      "Lumezzane",
      "Lusciano",
      "Macerata",
      "Macerata Campania",
      "Macomer",
      "Maddaloni",
      "Magenta",
      "Maglie",
      "Malnate",
      "Malo",
      "Mandello del Lario",
      "Manduria",
      "Manerbio",
      "Manfredonia",
      "Maniago",
      "Mantova",
      "Marano di Napoli",
      "Marcianise",
      "Marcon-Gaggio-Colmello",
      "Margherita di Savoia",
      "Mariano Comense",
      "Marigliano",
      "Marina di Ardea-Tor San Lorenzo",
      "Marina di Carrara",
      "Marina di Massa",
      "Marino",
      "Marsala",
      "Martina Franca",
      "Martinsicuro",
      "Mascalucia",
      "Massa",
      "Massa Lubrense",
      "Massafra",
      "Massarosa",
      "Matera",
      "Matino",
      "Mazara del Vallo",
      "Mazzarino",
      "Meda",
      "Mejaniga",
      "Melegnano",
      "Melfi",
      "Melito di Napoli",
      "Melzo",
      "Menfi",
      "Mentana",
      "Merano",
      "Merate",
      "Mercato San Severino",
      "Mesagne",
      "Messina",
      "Mestre",
      "Miano",
      "Milan",
      "Milazzo",
      "Minturno",
      "Mira Taglio",
      "Mirandola",
      "Mirano",
      "Misilmeri",
      "Misterbianco",
      "Modena",
      "Modica",
      "Modugno",
      "Mogliano Veneto",
      "Mola di Bari",
      "Molfetta",
      "Moncalieri",
      "Mondovi",
      "Mondragone",
      "Monfalcone",
      "Monopoli",
      "Monreale",
      "Monselice",
      "Monserrato",
      "Monsummano Terme",
      "Monte Argentario",
      "Monte Rosello",
      "Monte Sant'Angelo",
      "Monte di Procida",
      "Montebelluna",
      "Montecalvario",
      "Montecatini-Terme",
      "Montecchio Maggiore-Alte Ceccato",
      "Montefiascone",
      "Montegranaro",
      "Montegrotto Terme",
      "Montelupo Fiorentino",
      "Montemurlo",
      "Monteroni di Lecce",
      "Monterotondo",
      "Monterusciello",
      "Montesarchio",
      "Montesilvano Marina",
      "Montevarchi",
      "Montichiari",
      "Montignoso",
      "Monza",
      "Morbegno",
      "Mortara",
      "Mottola",
      "Muggia",
      "Muggio",
      "Mugnano di Napoli",
      "Naples",
      "Nardo",
      "Nave",
      "Nerviano",
      "Nettuno",
      "Nicastro",
      "Nichelino",
      "Nicosia",
      "Niscemi",
      "Noale",
      "Nocera Inferiore",
      "Nocera Superiore",
      "Noci",
      "Noicattaro",
      "Nola",
      "Nonantola",
      "Noto",
      "Nova Milanese",
      "Novara",
      "Novate Milanese",
      "Novellara",
      "Novi Ligure",
      "Nuoro",
      "Oderzo",
      "Olbia",
      "Oleggio",
      "Olgiate Comasco",
      "Olgiate Olona",
      "Omegna",
      "Opera",
      "Orbassano",
      "Oria",
      "Oristano",
      "Orta Nova",
      "Orta di Atella",
      "Ortona",
      "Osimo",
      "Osio Sotto",
      "Ospitaletto",
      "Ostuni",
      "Ottaviano",
      "Pachino",
      "Paderno Dugnano",
      "Padova",
      "Pagani",
      "Palagiano",
      "Palagonia",
      "Palazzolo sull'Oglio",
      "Palermo",
      "Palestrina",
      "Pallanza-Intra-Suna",
      "Palma Campania",
      "Palma di Montechiaro",
      "Palmi",
      "Palo del Colle",
      "Paola",
      "Paolo VI",
      "Parabiago",
      "Parete",
      "Parma",
      "Partanna",
      "Partinico",
      "Paterno",
      "Paullo",
      "Pavia",
      "Pedara",
      "Pellaro",
      "Pendino",
      "Pergine Valsugana",
      "Perugia",
      "Pesaro",
      "Pescantina",
      "Pescara",
      "Pescia",
      "Piacenza",
      "Pianezza",
      "Piano di Sorrento",
      "Pianura",
      "Piazza",
      "Piazza Armerina",
      "Piedimonte Matese",
      "Pietrasanta",
      "Pieve Emanuele",
      "Pinerolo",
      "Pioltello",
      "Piombino",
      "Piossasco",
      "Piove di Sacco-Piovega",
      "Pisa",
      "Piscinola",
      "Pistoia",
      "Poggibonsi",
      "Poggiomarino",
      "Poggioreale",
      "Policoro",
      "Polignano a Mare",
      "Pollena Trocchia",
      "Pomezia",
      "Pomigliano d'Arco",
      "Pompei",
      "Ponsacco",
      "Ponte San Nicolo",
      "Ponte San Pietro",
      "Pontecagnano",
      "Pontedera",
      "Ponticelli",
      "Ponzano",
      "Porcia",
      "Pordenone",
      "Portici",
      "Porto Empedocle",
      "Porto Recanati",
      "Porto San Giorgio",
      "Porto Sant'Elpidio",
      "Porto Tolle",
      "Porto Torres",
      "Porto Viro",
      "Portogruaro",
      "Posillipo",
      "Potenza",
      "Pozzallo",
      "Pozzuoli",
      "Prato",
      "Priolo Gargallo",
      "Priverno",
      "Procida",
      "Progresso",
      "Putignano",
      "Qualiano",
      "Quarto",
      "Quartu Sant'Elena",
      "Quartucciu",
      "Quattromiglia",
      "Raffadali",
      "Ragusa",
      "Ramacca",
      "Rapallo",
      "Ravanusa",
      "Ravenna",
      "Recanati",
      "Reggio Calabria",
      "Reggio nell'Emilia",
      "Rescaldina",
      "Rezzato",
      "Rho",
      "Ribera",
      "Riccione",
      "Riesi",
      "Rieti",
      "Rimini",
      "Rionero in Vulture",
      "Riposto",
      "Riva del Garda",
      "Rivalta di Torino",
      "Rivarolo Canavese",
      "Rivazzurra",
      "Rivoli",
      "Rocca di Papa",
      "Romano Banco",
      "Romano d'Ezzelino",
      "Romano di Lombardia",
      "Rome",
      "Ronchi dei Legionari",
      "Rosa",
      "Rosa",
      "Rosarno",
      "Roseto degli Abruzzi",
      "Rosignano Solvay-Castiglioncello",
      "Rosolini",
      "Rossano Stazione",
      "Rovato",
      "Rovereto",
      "Rovigo",
      "Rozzano",
      "Rubiera",
      "Rutigliano",
      "Ruvo di Puglia",
      "Sacile",
      "Sala Consilina",
      "Salerno",
      "Salo",
      "Salsomaggiore Terme",
      "Saluzzo",
      "Samarate",
      "Sambiase",
      "San Benedetto del Tronto",
      "San Bonifacio",
      "San Carlo All'Arena",
      "San Cataldo",
      "San Cesareo",
      "San Cipriano d'Aversa",
      "San Dona di Piave",
      "San Donato Milanese",
      "San Felice A Cancello",
      "San Ferdinando",
      "San Ferdinando di Puglia",
      "San Gennaro Vesuviano",
      "San Giorgio Ionico",
      "San Giorgio a Cremano",
      "San Giovanni Lupatoto",
      "San Giovanni Rotondo",
      "San Giovanni Valdarno",
      "San Giovanni a Teduccio",
      "San Giovanni in Fiore",
      "San Giovanni in Persiceto",
      "San Giovanni la Punta",
      "San Giuliano Milanese",
      "San Giuseppe Vesuviano",
      "San Lazzaro",
      "San Leone Mose",
      "San Lorenzo",
      "San Marcellino",
      "San Marco in Lamis",
      "San Martino Buon Albergo",
      "San Martino di Lupari",
      "San Mauro Torinese",
      "San Miniato",
      "San Miniato Basso",
      "San Nicola la Strada",
      "San Pancrazio Salentino",
      "San Paolo",
      "San Pietro Vernotico",
      "San Pietro a Patierno",
      "San Prisco",
      "San Remo",
      "San Salvo",
      "San Sebastiano",
      "San Severo",
      "San Vito al Tagliamento",
      "San Vito dei Normanni",
      "Sannicandro Garganico",
      "Sansepolcro",
      "Sant'Agata de'Goti",
      "Sant'Ambrogio di Valpollicella",
      "Sant'Anastasia",
      "Sant'Angelo Lodigiano",
      "Sant'Antimo",
      "Sant'Antioco",
      "Sant'Antonio",
      "Sant'Antonio Abate",
      "Sant'Arpino",
      "Santa Croce sull'Arno",
      "Santa Maria A Vico",
      "Santa Maria Capua Vetere",
      "Santa Maria La Carita",
      "Santa Marinella",
      "Santarcangelo",
      "Santena",
      "Santeramo in Colle",
      "Sarezzo",
      "Sarno",
      "Saronno",
      "Sarzana",
      "Sassari",
      "Sassuolo",
      "Sava",
      "Saviano",
      "Savigliano",
      "Savignano sul Rubicone",
      "Savona",
      "Scafati",
      "Scampia",
      "Scandiano",
      "Scandicci",
      "Schio",
      "Sciacca",
      "Scicli",
      "Scordia",
      "Secondigliano",
      "Sedriano",
      "Segrate",
      "Selargius",
      "Senago",
      "Senigallia",
      "Seregno",
      "Seriate",
      "Sesto Fiorentino",
      "Sesto San Giovanni",
      "Sestri Levante",
      "Sestu",
      "Settimo Milanese",
      "Settimo Torinese",
      "Seveso",
      "Sezze",
      "Siderno",
      "Siena",
      "Signa",
      "Silvi",
      "Sinnai",
      "Siracusa",
      "Soccavo",
      "Solaro",
      "Solaro",
      "Solofra",
      "Somma Lombardo",
      "Somma Vesuviana",
      "Sondrio",
      "Sora",
      "Sorrento",
      "Sorso",
      "Spinea-Orgnano",
      "Spoleto",
      "Spresiano",
      "Squinzano",
      "Statte",
      "Stella",
      "Stezzano",
      "Stradella",
      "Sulmona",
      "Surbo",
      "Suzzara",
      "Taggia",
      "Taglio",
      "Taranto",
      "Tarquinia",
      "Taurianova",
      "Taurisano",
      "Tavagnacco",
      "Taviano",
      "Tempio Pausania",
      "Teramo",
      "Terlizzi",
      "Termini Imerese",
      "Termoli",
      "Terni",
      "Terracina",
      "Terzigno",
      "Teverola",
      "Thiene",
      "Tivoli",
      "Tolentino",
      "Tor Lupara",
      "Tor Tre Teste",
      "Torre Annunziata",
      "Torre del Greco",
      "Torre del Lago Puccini",
      "Torremaggiore",
      "Tortona",
      "Torvaianica",
      "Tradate",
      "Trani",
      "Trapani",
      "Travagliato",
      "Trecastagni",
      "Trecate",
      "Tremestieri Etneo",
      "Trento",
      "Trentola-Ducenta",
      "Trepuzzi",
      "Treviglio",
      "Treviso",
      "Trezzano sul Naviglio",
      "Trezzo sull'Adda",
      "Tricase",
      "Trieste",
      "Triggiano",
      "Trinitapoli",
      "Trofarello",
      "Turi",
      "Turin",
      "Udine",
      "Umbertide",
      "Valdagno",
      "Valenza",
      "Valenzano",
      "Valmadrera-Caserta",
      "Valmontone",
      "Varazze",
      "Varedo",
      "Varese",
      "Vasto",
      "Veglie",
      "Velletri",
      "Venaria Reale",
      "Venice",
      "Venosa",
      "Ventimiglia",
      "Verbania",
      "Vercelli",
      "Verona",
      "Vetralla",
      "Viadana",
      "Viareggio",
      "Vibo Valentia",
      "Vicaria",
      "Vicenza",
      "Vico Equense",
      "Vieste",
      "Vigevano",
      "Vignola",
      "Vigonza",
      "Villa Adriana",
      "Villa Carcina",
      "Villa Literno",
      "Villa San Giovanni",
      "Villabate",
      "Villacidro",
      "Villafranca di Verona",
      "Villanova",
      "Villaricca",
      "Villasanta",
      "Vimercate",
      "Vimodrone",
      "Vinovo",
      "Viterbo",
      "Vittoria",
      "Vittorio Veneto",
      "Voghera",
      "Volla",
      "Volpiano",
      "Vomero",
      "Zagarolo",
      "Zevio",
      "Zola Predosa",
    ],
  },
  Jamaica: {
    code: "JM",
    cities: [
      "Bog Walk",
      "Constant Spring",
      "Ewarton",
      "Half Way Tree",
      "Kingston",
      "Linstead",
      "Mandeville",
      "May Pen",
      "Montego Bay",
      "New Kingston",
      "Old Harbour",
      "Port Antonio",
      "Portmore",
      "Saint Ann's Bay",
      "Savanna-la-Mar",
      "Spanish Town",
    ],
  },
  Japan: {
    code: "JP",
    cities: [
      "Abashiri",
      "Abiko",
      "Agano",
      "Ageoshimo",
      "Aioi",
      "Akabira",
      "Akashi",
      "Aki",
      "Akishima",
      "Akita",
      "Akune",
      "Amagasaki",
      "Amagi",
      "Amakusa",
      "Ami",
      "Anamizu",
      "Anan",
      "Anjo",
      "Annaka",
      "Aomori",
      "Arai",
      "Arai",
      "Arao",
      "Arashiyama",
      "Arida",
      "Arita",
      "Asahi",
      "Asahikawa",
      "Asaka",
      "Ashibetsu",
      "Ashikaga",
      "Ashiya",
      "Ashiya",
      "Aso",
      "Atami",
      "Atsugi",
      "Ayabe",
      "Beppu",
      "Bibai",
      "Biei",
      "Bungo-Takada-shi",
      "Buzen",
      "Chatan",
      "Chiba",
      "Chichibu",
      "Chigasaki",
      "Chikuma",
      "Chikusei",
      "Chikushino-shi",
      "Chino",
      "Chiryu",
      "Chita",
      "Chitose",
      "Chofu",
      "Chuo",
      "Daigo",
      "Daito",
      "Date",
      "Date",
      "Dazaifu",
      "Ebetsu",
      "Ebina",
      "Edosaki",
      "Ena",
      "Enzan",
      "Fuchu",
      "Fuchucho",
      "Fuefuki",
      "Fuji",
      "Fujieda",
      "Fujikawaguchiko",
      "Fujimi",
      "Fujinomiya",
      "Fujioka",
      "Fujioka",
      "Fujisaki",
      "Fujisawa",
      "Fujishiro",
      "Fujiyoshida",
      "Fukagawa",
      "Fukayacho",
      "Fukiage-fujimi",
      "Fukuchiyama",
      "Fukuecho",
      "Fukui-shi",
      "Fukumitsu",
      "Fukuoka",
      "Fukura",
      "Fukuroi",
      "Fukushima",
      "Fukuyama",
      "Funaishikawa",
      "Funehikimachi-funehiki",
      "Furukawa",
      "Fussa",
      "Futtsu",
      "Gamagori",
      "Gero",
      "Gifu-shi",
      "Ginowan",
      "Gobo",
      "Godo",
      "Gojo",
      "Gose",
      "Gosen",
      "Goshogawara",
      "Gotenba",
      "Gotsucho",
      "Gujo",
      "Gushikawa",
      "Gyoda",
      "Habikino",
      "Hachimantai",
      "Hachinohe",
      "Hachioji",
      "Hadano",
      "Hagi",
      "Haibara-akanedai",
      "Hakodate",
      "Hakone",
      "Hakui",
      "Hamada",
      "Hamakita",
      "Hamamatsu",
      "Hamanoichi",
      "Hamura",
      "Hanamaki",
      "Hanawa",
      "Handa",
      "Hannan",
      "Hanno",
      "Hanyu",
      "Hasaki",
      "Hashima",
      "Hashimoto",
      "Hasuda",
      "Hatogaya-honcho",
      "Hatsukaichi",
      "Hayama",
      "Hayashima",
      "Hekinan",
      "Hidaka",
      "Higashi-osaka",
      "Higashikagawa",
      "Higashikurume",
      "Higashimatsushima",
      "Higashimurayama",
      "Higashine",
      "Hiji",
      "Hikari",
      "Hikone",
      "Himeji",
      "Himi",
      "Himimachi",
      "Hino",
      "Hino",
      "Hirado",
      "Hirakata",
      "Hirakawa",
      "Hiranai",
      "Hirara",
      "Hiratacho",
      "Hiratsuka",
      "Hirosaki",
      "Hiroshima",
      "Hisai-motomachi",
      "Hita",
      "Hitachi",
      "Hitachi-Naka",
      "Hitachi-ota",
      "Hitoyoshi",
      "Hobaramachi",
      "Hofu",
      "Hojo",
      "Hokuto",
      "Hokuto",
      "Honcho",
      "Honcho",
      "Hondomachi-hondo",
      "Honjo",
      "Honmachi",
      "Honmachi",
      "Horyuji",
      "Hotaka",
      "Hyuga",
      "Ibara",
      "Ibaraki",
      "Ibusuki",
      "Ichihara",
      "Ichinohe",
      "Ichinomiya",
      "Ichinomiya",
      "Ichinoseki",
      "Iga",
      "Iida",
      "Iiyama",
      "Iizuka",
      "Ijuin",
      "Ikeda",
      "Ikedacho",
      "Ikoma",
      "Imaichi",
      "Imaricho-ko",
      "Ina",
      "Inashiki",
      "Inawashiro",
      "Inazawa",
      "Inba",
      "Innoshima",
      "Ino",
      "Inuyama",
      "Inzai",
      "Iruma",
      "Isahaya",
      "Isawa",
      "Ise",
      "Isehara",
      "Isesaki",
      "Ishigaki",
      "Ishige",
      "Ishii",
      "Ishikari",
      "Ishikawa",
      "Ishikawa",
      "Ishiki",
      "Ishinomaki",
      "Ishioka",
      "Itako",
      "Itami",
      "Itayanagi",
      "Ito",
      "Itoigawa",
      "Itoman",
      "Itsukaichi",
      "Iwade",
      "Iwai",
      "Iwaki",
      "Iwakuni",
      "Iwakura",
      "Iwamizawa",
      "Iwanai",
      "Iwanuma",
      "Iwase",
      "Iwata",
      "Iwatsuki",
      "Iyo",
      "Izu",
      "Izumi",
      "Izumi",
      "Izumiotsu",
      "Izumisano",
      "Izumo",
      "Izunokuni",
      "Joetsu",
      "Kadena",
      "Kadogawa",
      "Kadoma",
      "Kaga",
      "Kagoshima",
      "Kai",
      "Kainan",
      "Kaizu",
      "Kaizuka",
      "Kajiki",
      "Kakamigahara",
      "Kakegawa",
      "Kakogawacho-honmachi",
      "Kakuda",
      "Kakunodatemachi",
      "Kamaishi",
      "Kamakura",
      "Kameda-honcho",
      "Kameoka",
      "Kameyama",
      "Kamifukuoka",
      "Kamigori",
      "Kamigyo-ku",
      "Kamiichi",
      "Kamiiso",
      "Kamikawa",
      "Kamimaruko",
      "Kaminokawa",
      "Kaminoyama",
      "Kamirenjaku",
      "Kamo",
      "Kamogatacho-kamogata",
      "Kamogawa",
      "Kamojimacho-jogejima",
      "Kan'onjicho",
      "Kanaya",
      "Kanazawa",
      "Kanbara",
      "Kanda",
      "Kanegasaki",
      "Kanekomachi",
      "Kani",
      "Kanie",
      "Kanmaki",
      "Kannabecho-yahiro",
      "Kanoya",
      "Kanuma",
      "Kanzakimachi-kanzaki",
      "Karasuyama",
      "Karatsu",
      "Kariya",
      "Kariya",
      "Kasai",
      "Kasama",
      "Kasamatsucho",
      "Kasaoka",
      "Kaseda-shirakame",
      "Kashihara",
      "Kashihara-shi",
      "Kashima",
      "Kashima-shi",
      "Kashiwa",
      "Kashiwazaki",
      "Kasugai",
      "Kasukabe",
      "Kato",
      "Katori-shi",
      "Katsuragi",
      "Katsuren-haebaru",
      "Katsuta",
      "Katsuura",
      "Katsuyama",
      "Kawage",
      "Kawagoe",
      "Kawaguchi",
      "Kawaminami",
      "Kawanishi",
      "Kawanoecho",
      "Kawara",
      "Kawasaki",
      "Kawasaki",
      "Kazo",
      "Kemigawa",
      "Kihoku-cho",
      "Kikuchi",
      "Kikugawa",
      "Kimitsu",
      "Kin",
      "Kinokawa",
      "Kirishima",
      "Kiryu",
      "Kisai",
      "Kisarazu",
      "Kishiwada",
      "Kita-Akita",
      "Kitagata",
      "Kitahama",
      "Kitahiroshima",
      "Kitaibaraki",
      "Kitakami",
      "Kitakata",
      "Kitakyushu",
      "Kitami",
      "Kitanagoya",
      "Kitsuki",
      "Kiyose",
      "Kobayashi",
      "Kobe",
      "Kochi",
      "Kodaira",
      "Kodamacho-kodamaminami",
      "Kofu",
      "Koga",
      "Koga",
      "Kogota",
      "Koka",
      "Kokubu-matsuki",
      "Kokubunji",
      "Komaki",
      "Komatsu",
      "Komatsushimacho",
      "Komono",
      "Komoro",
      "Konan",
      "Konan",
      "Konosu",
      "Koori",
      "Koriyama",
      "Kosai",
      "Koshi",
      "Koshigaya",
      "Koshu",
      "Kozakai-cho",
      "Kudamatsu",
      "Kuji",
      "Kukichuo",
      "Kumagaya",
      "Kumamoto",
      "Kumano",
      "Kurashiki",
      "Kurayoshi",
      "Kure",
      "Kurihara",
      "Kurihashi",
      "Kurobe-shi",
      "Kuroda",
      "Kuroishi",
      "Kuroiso",
      "Kurume",
      "Kusatsu",
      "Kushikino",
      "Kushima",
      "Kushimoto",
      "Kushiro",
      "Kutchan",
      "Kuwana",
      "Kyoto",
      "Machida",
      "Maebaru-chuo",
      "Maebashi",
      "Maibara",
      "Maizuru",
      "Makabe",
      "Maki",
      "Makubetsu",
      "Makurazaki",
      "Marugame",
      "Marumori",
      "Maruoka",
      "Masaki-cho",
      "Mashiko",
      "Masuda",
      "Matsubara",
      "Matsubase",
      "Matsubushi",
      "Matsudo",
      "Matsue",
      "Matsumoto",
      "Matsushima",
      "Matsuto",
      "Matsuura",
      "Matsuyama",
      "Menuma",
      "Mibu",
      "Midori",
      "Mifune",
      "Mihara",
      "Miharu",
      "Miki",
      "Mikuni",
      "Minabe",
      "Minakuchicho-matoba",
      "Minamata",
      "Minami-Alps",
      "Minami-Soma",
      "Minamiawaji",
      "Minamirinkan",
      "Minamishibetsucho",
      "Minamiuonuma",
      "Minato",
      "Mino",
      "Mino",
      "Minokamo",
      "Misawa",
      "Mishima",
      "Mitake",
      "Mito",
      "Mitoyo",
      "Mitsukaido",
      "Mitsuke",
      "Miura",
      "Miyako",
      "Miyakojima",
      "Miyakonojo",
      "Miyata",
      "Miyazaki",
      "Miyazu",
      "Miyoshi",
      "Miyota",
      "Mizuho",
      "Mizunami",
      "Mizusawa",
      "Mobara",
      "Mombetsu",
      "Mooka",
      "Mori",
      "Moriguchi",
      "Morioka",
      "Moriya",
      "Moriyama",
      "Morohongo",
      "Motegi",
      "Motobu",
      "Motomachi",
      "Motomiya",
      "Muikamachi",
      "Muko",
      "Murakami",
      "Muramatsu",
      "Murata",
      "Muroran",
      "Muroto-misakicho",
      "Musashino",
      "Mutsu",
      "Myoko",
      "Nabari",
      "Nagahama",
      "Nagai",
      "Nagano",
      "Nagaoka",
      "Nagareyama",
      "Nagasaki",
      "Nagasu",
      "Nagato",
      "Nago",
      "Nagoya",
      "Naha",
      "Naka",
      "Nakama",
      "Nakamura",
      "Nakano",
      "Nakano",
      "Nakanojomachi",
      "Nakashibetsu",
      "Nakatsu",
      "Nakatsugawa",
      "Namerikawa",
      "Namie",
      "Namioka",
      "Nanao",
      "Nanto",
      "Nanto-shi",
      "Nara-shi",
      "Narita",
      "Naruto",
      "Narutocho-mitsuishi",
      "Nasushiobara",
      "Nayoro",
      "Naze",
      "Nemuro",
      "Neyagawa",
      "Nichinan",
      "Nihommatsu",
      "Niigata",
      "Niihama",
      "Niimi",
      "Niitsu-honcho",
      "Niiza",
      "Nikko",
      "Ninomiya",
      "Nirasaki",
      "Nishi-Tokyo-shi",
      "Nishifukuma",
      "Nishinomiya-hama",
      "Nishinoomote",
      "Nishio",
      "Nishishinminato",
      "Nishiwaki",
      "Nisshin",
      "Nobeoka",
      "Noda",
      "Nogata",
      "Nonoichi",
      "Noshiro",
      "Numata",
      "Numazu",
      "Nyuzen",
      "Oami",
      "Oarai",
      "Obama",
      "Obanazawa",
      "Obihiro",
      "Obita",
      "Obu",
      "Odacho-oda",
      "Odate",
      "Odawara",
      "Ofunato",
      "Ogaki",
      "Ogano",
      "Ogawa",
      "Ogori-shimogo",
      "Ogose",
      "Ohara",
      "Oi",
      "Oiso",
      "Oita",
      "Ojiya",
      "Okawa",
      "Okawara",
      "Okaya",
      "Okayama",
      "Okazaki",
      "Okegawa",
      "Okinawa",
      "Okuchi-shinohara",
      "Okunoya",
      "Omachi",
      "Omagari",
      "Omamacho-omama",
      "Ome",
      "Omigawa",
      "Omihachiman",
      "Omiya",
      "Omura",
      "Omuta",
      "Onna",
      "Ono",
      "Ono",
      "Ono-hara",
      "Onoda",
      "Onojo",
      "Onomichi",
      "Osaka",
      "Osaki",
      "Osaki",
      "Ota",
      "Otake",
      "Otaru",
      "Otawara",
      "Otofuke",
      "Otsu",
      "Otsuchi",
      "Otsuki",
      "Owase",
      "Oyama",
      "Oyama",
      "Ozu",
      "Ozu",
      "Rifu",
      "Ritto",
      "Rumoi",
      "Ryotsu-minato",
      "Ryugasaki",
      "Ryuo",
      "Sabae",
      "Sado",
      "Saga",
      "Sagae",
      "Sagara",
      "Saijo",
      "Saiki",
      "Saitama",
      "Saito",
      "Saka",
      "Sakado",
      "Sakai",
      "Sakai",
      "Sakai",
      "Sakai-nakajima",
      "Sakaidecho",
      "Sakaiminato",
      "Sakaki",
      "Sakata",
      "Sakawa",
      "Saku",
      "Sakura",
      "Sakura",
      "Sakurai",
      "Sandacho",
      "Sanjo",
      "Sano",
      "Sapporo",
      "Sasaguri",
      "Sasayama",
      "Sasebo",
      "Satsumasendai",
      "Satte",
      "Sawara",
      "Sayama",
      "Seiro",
      "Semboku",
      "Sendai",
      "Setakamachi-takayanagi",
      "Seto",
      "Shibata",
      "Shibetsu",
      "Shibukawa",
      "Shibushi",
      "Shido",
      "Shiida",
      "Shiki",
      "Shima",
      "Shimabara",
      "Shimada",
      "Shimo-furano",
      "Shimoda",
      "Shimodate",
      "Shimokizukuri",
      "Shimonoseki",
      "Shimotoda",
      "Shimotsuke",
      "Shin'ichi",
      "Shingu",
      "Shingu",
      "Shinjo",
      "Shinshiro",
      "Shiogama",
      "Shiojiri",
      "Shiozawa",
      "Shirahama",
      "Shirahamacho-usazakiminami",
      "Shirakawa",
      "Shiraoi",
      "Shiraoka",
      "Shiroi",
      "Shiroishi",
      "Shirone",
      "Shiso",
      "Shisui",
      "Shizukuishi",
      "Shizunai-furukawacho",
      "Shizuoka",
      "Shobara",
      "Shobu",
      "Shonai",
      "Sobue",
      "Soja",
      "Soka",
      "Soma",
      "Sosa",
      "Sue",
      "Sueyoshicho-ninokata",
      "Sugito",
      "Suibara",
      "Suita",
      "Sukagawa",
      "Sukumo",
      "Sumoto",
      "Sunagawa",
      "Susaki",
      "Susono",
      "Suwa",
      "Suzaka",
      "Suzuka",
      "Tachiarai",
      "Tachikawa",
      "Tadotsu",
      "Tagami",
      "Tagawa",
      "Tahara",
      "Tainai",
      "Taishi",
      "Tajimi",
      "Takahagi",
      "Takahama",
      "Takahama",
      "Takahashi",
      "Takahata",
      "Takaishi",
      "Takamatsu",
      "Takanabe",
      "Takanosu",
      "Takaoka",
      "Takarazuka",
      "Takasaki",
      "Takashima",
      "Takatsuki",
      "Takayama",
      "Takedamachi",
      "Takefu",
      "Takehara",
      "Takeo",
      "Taketoyo",
      "Takikawa",
      "Takizawa",
      "Tako",
      "Tama",
      "Tamamura",
      "Tamana",
      "Tamano",
      "Tanabe",
      "Tanabe",
      "Tanagura",
      "Tanashicho",
      "Tanba",
      "Tanuma",
      "Tanushimarumachi-toyoki",
      "Tarui",
      "Tarumizu",
      "Tatebayashi",
      "Tateyama",
      "Tatsuno",
      "Tatsunocho-tominaga",
      "Tawaramoto",
      "Tendo",
      "Tenno",
      "Tenri",
      "Toba",
      "Tobetsu",
      "Tochigi",
      "Tochio-honcho",
      "Togane",
      "Togitsu",
      "Tokamachi",
      "Toki",
      "Tokoname",
      "Tokorozawa",
      "Tokushima",
      "Tokuyama",
      "Tokyo",
      "Tomakomai",
      "Tome",
      "Tomigusuku",
      "Tomioka",
      "Tomiya",
      "Tomobe",
      "Tonami",
      "Tondabayashicho",
      "Tono",
      "Tonosho",
      "Toride",
      "Tosu",
      "Tottori",
      "Towada",
      "Toyama",
      "Toyohama",
      "Toyohashi",
      "Toyokawa",
      "Toyonaka",
      "Toyooka",
      "Toyoshina",
      "Toyota",
      "Tsu",
      "Tsubame",
      "Tsubata",
      "Tsukawaki",
      "Tsukuba",
      "Tsukumiura",
      "Tsuma",
      "Tsuno",
      "Tsuruga",
      "Tsurugi-asahimachi",
      "Tsuruoka",
      "Tsurusaki",
      "Tsuruta",
      "Tsushima",
      "Tsuyama",
      "Ube",
      "Ueda",
      "Uekimachi-mono",
      "Ueno-ebisumachi",
      "Uenohara",
      "Uji",
      "Ujiie",
      "Uki",
      "Umi",
      "Uonuma",
      "Uozu",
      "Urakawa",
      "Urayasu",
      "Ureshinomachi-shimojuku",
      "Ushibukamachi",
      "Ushiku",
      "Usuki",
      "Utazu",
      "Uto",
      "Utsunomiya",
      "Uwajima",
      "Wakayama",
      "Wake",
      "Wakimachi",
      "Wakkanai",
      "Wako",
      "Wakuya",
      "Watari",
      "Yabu",
      "Yabuki",
      "Yachimata",
      "Yaita",
      "Yaizu",
      "Yakage",
      "Yamada",
      "Yamaga",
      "Yamagata",
      "Yamagata",
      "Yamaguchi",
      "Yamato",
      "Yamazakicho-nakabirose",
      "Yamoto",
      "Yanagawa",
      "Yanagawamachi-saiwaicho",
      "Yanai",
      "Yao",
      "Yaotsu",
      "Yashio",
      "Yashiro",
      "Yasu",
      "Yasugicho",
      "Yatsuomachi-higashikumisaka",
      "Yawata",
      "Yawatahama",
      "Yoichi",
      "Yokaichiba",
      "Yokkaichi",
      "Yokohama",
      "Yokoshiba",
      "Yokosuka",
      "Yokote",
      "Yomitan",
      "Yonabaru",
      "Yonago",
      "Yonezawa",
      "Yono",
      "Yorii",
      "Yoshida-kasugacho",
      "Yoshii",
      "Yoshikawa",
      "Yotsukaido",
      "Youkaichi",
      "Yuasa",
      "Yugawara",
      "Yuki",
      "Yukuhashi",
      "Yurihonjo",
      "Yuza",
      "Yuzawa",
      "Zama",
      "Zushi",
    ],
  },
  Jersey: {
    code: "JE",
    cities: ["Saint Helier"],
  },
  Jordan: {
    code: "JO",
    cities: [
      "'Ajlun",
      "Al Azraq ash Shamali",
      "Al Jubayhah",
      "Amman",
      "Aqaba",
      "Ar Ramtha",
      "As Salt",
      "Ash Shajarah",
      "At Tafilah",
      "At Tayyibah",
      "At Turrah",
      "Aydun",
      "Hayy al Quwaysimah",
      "Irbid",
      "Jarash",
      "Jawa",
      "Judita",
      "Karak City",
      "Kurayyimah",
      "Ma'an",
      "Madaba",
      "Mafraq",
      "Qir Moav",
      "Rukban",
      "Russeifa",
      "Safi",
      "Sahab",
      "Sakhrah",
      "Sakib",
      "Suf",
      "Umm as Summaq",
      "Wadi Musa",
      "Wadi as Sir",
      "Zarqa",
      "`Anjarah",
      "`Izra",
    ],
  },
  Kazakhstan: {
    code: "KZ",
    cities: [
      "Abay",
      "Akkol'",
      "Aksu",
      "Aksu",
      "Aktobe",
      "Almaty",
      "Altay",
      "Aqsay",
      "Aral",
      "Arkalyk",
      "Arys",
      "Astana",
      "Asyqata",
      "Atakent",
      "Atbasar",
      "Atyrau",
      "Ayagoz",
      "Baikonur",
      "Balpyk Bi",
      "Balqash",
      "Balykshi",
      "Beyneu",
      "Burunday",
      "Chemolgan",
      "Derzhavinsk",
      "Ekibastuz",
      "Embi",
      "Esik",
      "Esil",
      "Glubokoye",
      "Inderbor",
      "Kalbatau",
      "Kandyagash",
      "Kantagi",
      "Karagandy",
      "Karatau",
      "Kazygurt",
      "Kentau",
      "Khromtau",
      "Kokshetau",
      "Konayev",
      "Kostanay",
      "Kulan",
      "Kurchatov",
      "Kuryk",
      "Kyzylorda",
      "Lenger",
      "Lisakovsk",
      "Lugovoy",
      "Makinsk",
      "Maqat",
      "Merke",
      "Moyynkum",
      "Novokazalinsk",
      "Oral",
      "Otegen Batyra",
      "Pavlodar",
      "Petropavl",
      "Priozersk",
      "Qarazhal",
      "Qashar",
      "Qulsary",
      "Ridder",
      "Rudnyy",
      "Saran",
      "Sarqant",
      "Saryaghash",
      "Sarykemer",
      "Saryozek",
      "Semey",
      "Shakhan",
      "Shakhtinsk",
      "Shalkar",
      "Shalqar",
      "Shardara",
      "Shchuchinsk",
      "Shemonaikha",
      "Shetpe",
      "Shevchenko",
      "Shiyeli",
      "Sholakkorgan",
      "Shu",
      "Shubarkuduk",
      "Shymkent",
      "Stepnogorsk",
      "Taiynsha",
      "Taldykorgan",
      "Talghar",
      "Taraz",
      "Tasbuget",
      "Tekeli",
      "Temirlanovka",
      "Temirtau",
      "Turar Ryskulov",
      "Turgen",
      "Turkestan",
      "Tyul'kubas",
      "Urzhar",
      "Ushtobe",
      "Ust-Kamenogorsk",
      "Yereymentau",
      "Zaysan",
      "Zhalagash",
      "Zhanaozen",
      "Zhangaarqa",
      "Zhangaqorghan",
      "Zhangatas",
      "Zharkent",
      "Zhetybay",
      "Zhezqazghan",
      "Zhitikara",
      "Zhosaly",
    ],
  },
  Kenya: {
    code: "KE",
    cities: [
      "Ahero",
      "Athi River",
      "Awendo",
      "Awendo",
      "Banissa",
      "Bomet",
      "Bondo",
      "Bungoma",
      "Bura",
      "Busia",
      "Bute",
      "Butere",
      "Chavakali",
      "Chuka",
      "Dadaab",
      "El Wak",
      "Elburgon",
      "Eldama Ravine",
      "Eldas",
      "Eldoret",
      "Emali",
      "Embu",
      "Garba Tula",
      "Garissa",
      "Gilgil",
      "Githunguri",
      "Githunguri",
      "Gongoni",
      "Habaswein",
      "Hola",
      "Homa Bay",
      "Ijara",
      "Isebania",
      "Isinya",
      "Isiolo",
      "Iten",
      "Juja",
      "Kabarnet",
      "Kagio",
      "Kainuk",
      "Kajiado",
      "Kakamega",
      "Kakuma",
      "Kangundo",
      "Kapenguria",
      "Kapsabet",
      "Karatina",
      "Karuri",
      "Karuri",
      "Kawaida",
      "Kenol",
      "Kericho",
      "Keroka",
      "Kerugoya",
      "Kiambu",
      "Kihancha",
      "Kikuyu",
      "Kilgoris",
      "Kilifi",
      "Kimana",
      "Kimilili",
      "Kiminini",
      "Kinna",
      "Kinoi",
      "Kiserian",
      "Kisii",
      "Kisumu",
      "Kitale",
      "Kitengela",
      "Kitui",
      "Kwale",
      "Lafey",
      "Lamu",
      "Lang'ata",
      "Limuru",
      "Litein",
      "Lodwar",
      "Lokichar",
      "Lokichokio",
      "Luanda",
      "Luanda",
      "Lugulu",
      "Maai Mahiu",
      "Machakos",
      "Mado Gashi",
      "Magadi",
      "Mai",
      "Mairo Inya",
      "Majengo",
      "Makindu",
      "Makueni Boma",
      "Makutano",
      "Makutano",
      "Malaba",
      "Malindi",
      "Mandera",
      "Maralal",
      "Mariakani",
      "Marsabit",
      "Masalani",
      "Matunda",
      "Matuu",
      "Mau Narok",
      "Maua",
      "Mazeras",
      "Mbale",
      "Mbita",
      "Merti",
      "Meru",
      "Migori",
      "Mlolongo",
      "Mogotio",
      "Moi's Bridge",
      "Molo",
      "Mombasa",
      "Moyale",
      "Msambweni",
      "Mtwapa",
      "Muhoroni",
      "Mumias",
      "Murang'a",
      "Mwingi",
      "Nairobi",
      "Naivasha",
      "Nakuru",
      "Nanyuki",
      "Narok",
      "Ngong",
      "Njoro",
      "Nyahururu",
      "Nyambunwa",
      "Nyamira",
      "Nyeri",
      "Ogembo",
      "Ol Kalau South Settlement",
      "Ol Kalou",
      "Oloitokitok",
      "Ongata Rongai",
      "Oyugis",
      "Port Victoria",
      "Pumwani",
      "Ramu",
      "Rongai",
      "Rongo",
      "Ruiru",
      "Rumuruti",
      "Sagana",
      "Sawa Sawa",
      "Siaya",
      "Sindo",
      "Subukia",
      "Takaba",
      "Takaungu",
      "Tala",
      "Taveta",
      "Thika",
      "Timau",
      "Ukunda",
      "Upper Gilgil",
      "Vipingo",
      "Voi",
      "Wajir",
      "Wanguru",
      "Watamu",
      "Webuye",
      "Wote",
      "Wundanyi",
    ],
  },
  Kiribati: {
    code: "KI",
    cities: ["Betio Village", "Tarawa"],
  },
  "Korea, Dem. People's Rep. of": {
    code: "KP",
    cities: [
      "Anak",
      "Anbyon-up",
      "Anju",
      "Aoji",
      "Ayang-ni",
      "Chaeryong-up",
      "Changyon",
      "Chasong",
      "Chongjin",
      "Chongju",
      "Chunghwa",
      "Haeju",
      "Hamhung",
      "Hau-ri",
      "Hoemul-li",
      "Hoeryong",
      "Hoeyang",
      "Hongwon",
      "Hukkyo-ri",
      "Hungnam",
      "Hwangju-up",
      "Hyesan",
      "Hyesan-dong",
      "Iwon-up",
      "Kaesong",
      "Kangdong-up",
      "Kanggye",
      "Kapsan-up",
      "Kilju",
      "Koksan-up",
      "Komusan Il-tong",
      "Kosan",
      "Kowon-up",
      "Kujang-up",
      "Kusong",
      "Kwaksan",
      "Kyongsong",
      "Kyongwon",
      "Manp'o",
      "Musan-up",
      "Namp'o",
      "Namyang",
      "Ongjin",
      "Onsong",
      "P'yongsong",
      "Panghyon-dong",
      "Pukchil-lodongjagu",
      "Puryong",
      "Pyoksong-up",
      "Pyongyang",
      "Rajin",
      "Ranam",
      "Sakchu-up",
      "Samho-rodongjagu",
      "Sariwon",
      "Sil-li",
      "Sinanju",
      "Sinmak",
      "Sinsang-ni",
      "Sinuiju",
      "Sonbong",
      "Songnim-ni",
      "Sunan",
      "Sunch'on",
      "Sungam-nodongjagu",
      "Sungho 1-tong",
      "Sungjibaegam",
      "T'ongch'on-up",
      "Uiju",
      "Wonsan",
      "Yonan-up",
      "Yonggang-up",
      "Yonggwang-up",
      "Yuktae-dong",
    ],
  },
  "Korea, Republic of": {
    code: "KR",
    cities: [
      "Andong",
      "Ansan-si",
      "Anseong",
      "Anyang-si",
      "Asan",
      "Beolgyo",
      "Boryeong",
      "Bucheon-si",
      "Busan",
      "Buyeo",
      "Changnyeong",
      "Changsu",
      "Changwon",
      "Cheonan",
      "Cheongju-si",
      "Cheongsong gun",
      "Chinch'on",
      "Chinju",
      "Chuncheon",
      "Chungju",
      "Daegu",
      "Daejeon",
      "Donghae City",
      "Eisen",
      "Gaigeturi",
      "Ganghwa-gun",
      "Gangneung",
      "Gapyeong",
      "Gijang",
      "Gimcheon",
      "Gongju",
      "Goseong",
      "Goyang-si",
      "Gumi",
      "Gunpo",
      "Gunsan",
      "Guri-si",
      "Gwangju",
      "Gwangju",
      "Gwangmyeong",
      "Gyeongju",
      "Gyeongsan-si",
      "Haenam",
      "Hanam",
      "Hayang",
      "Heunghae",
      "Hongch'on",
      "Hongseong",
      "Hwacheon",
      "Hwado",
      "Hwaseong-si",
      "Hwasun",
      "Hwawon",
      "Icheon-si",
      "Iksan",
      "Imsil",
      "Incheon",
      "Jeju City",
      "Jenzan",
      "Jeongeup",
      "Jeonju",
      "Jinan-gun",
      "Jumunjin",
      "Kimhae",
      "Kimje",
      "Kinzan",
      "Koch'ang",
      "Koesan",
      "Kosong",
      "Kunwi",
      "Kurye",
      "Kwangyang",
      "Kyosai",
      "Masan",
      "Miryang",
      "Mokpo",
      "Muan",
      "Mungyeong",
      "Munsan",
      "Naeso",
      "Naju",
      "Namyangju",
      "Nangen",
      "Neietsu",
      "Nonsan",
      "Okcheon",
      "Osan",
      "Pohang",
      "Puan",
      "Pubal",
      "Pyeongtaek",
      "Sangju",
      "Santyoku",
      "Sejong",
      "Seogwipo",
      "Seonghwan",
      "Seongnam-si",
      "Seosan",
      "Seoul",
      "Sinan",
      "Sinhyeon",
      "Sintansin",
      "Sokcho",
      "Suncheon",
      "Suwon",
      "T'aebaek",
      "Taesal-li",
      "Tangjin",
      "Uijeongbu-si",
      "Ulsan",
      "Ungsang",
      "Wabu",
      "Waegwan",
      "Wanju",
      "Wonju",
      "Yanggu",
      "Yangju",
      "Yangp'yong",
      "Yangsan",
      "Yeoju",
      "Yeongam",
      "Yeongdong",
      "Yeonggwang",
      "Yeongju",
      "Yeonil",
      "Yeosu",
      "Yesan",
      "Yongsan-dong",
      "Yonmu",
    ],
  },
  Kuwait: {
    code: "KW",
    cities: [
      "Ad Dasmah",
      "Al Ahmadi",
      "Al Fahahil",
      "Al Farwaniyah",
      "Al Fintas",
      "Al Jahra'",
      "Al Mahbulah",
      "Al Manqaf",
      "Al Wafrah",
      "Ar Rabiyah",
      "Ar Riqqah",
      "Ar Rumaythiyah",
      "As Salimiyah",
      "Ash Shamiyah",
      "Bayan",
      "Hawalli",
      "Janub as Surrah",
      "Kuwait City",
      "Sabah as Salim",
      "Salwa",
    ],
  },
  Kyrgyzstan: {
    code: "KG",
    cities: [
      "Ak-Suu",
      "Ala-Buka",
      "At-Bashi",
      "Aydarken",
      "Baetovo",
      "Balykchy",
      "Batken",
      "Bazar-Korgon",
      "Belovodskoye",
      "Bishkek",
      "Bokombayevskoye",
      "Cholpon-Ata",
      "Gul'cha",
      "Iradan",
      "Ivanovka",
      "Jalal-Abad",
      "Kant",
      "Kara Suu",
      "Kara-Balta",
      "Kara-Kulja",
      "Karakol",
      "Kazarman",
      "Kemin",
      "Kerben",
      "Kochkor-Ata",
      "Kyzyl-Adyr",
      "Kyzyl-Kyya",
      "Kyzyl-Suu",
      "Lebedinovka",
      "Massy",
      "Mayevka",
      "Naryn",
      "Nizhnyaya Ala-Archa",
      "Nookat",
      "Osh",
      "Razzakov",
      "Suluktu",
      "Suzak",
      "Talas",
      "Tash-Kumyr",
      "Tokmok",
      "Toktogul",
      "Tyup",
      "Uch-Korgon",
      "Uzgen",
    ],
  },
  "Lao People's Dem. Rep.": {
    code: "LA",
    cities: [
      "Ban Houakhoua",
      "Ban Houayxay",
      "Champasak",
      "Luang Prabang",
      "Muang Khong",
      "Muang Phon-Hong",
      "Muang Phonsavan",
      "Muang Xay",
      "Pakse",
      "Pakxan",
      "Phongsali",
      "Sainyabuli",
      "Savannakhet",
      "Thakhek",
      "Vang Vieng",
      "Vientiane",
      "Xam Nua",
    ],
  },
  Latvia: {
    code: "LV",
    cities: [
      "Bauska",
      "Bolderaja",
      "Cesis",
      "Darzciems",
      "Daugavpils",
      "Dobele",
      "Jekabpils",
      "Jelgava",
      "Jurmala",
      "Kraslava",
      "Kuldiga",
      "Liepaja",
      "Livani",
      "Ludza",
      "Ogre",
      "Olaine",
      "Rezekne",
      "Riga",
      "Salaspils",
      "Saldus",
      "Sigulda",
      "Talsi",
      "Tukums",
      "Valmiera",
      "Ventspils",
    ],
  },
  Lebanon: {
    code: "LB",
    cities: [
      "Baalbek",
      "Batroun",
      "Bcharre",
      "Beirut",
      "En Naqoura",
      "Ghazieh",
      "Habbouch",
      "Jbail",
      "Jounieh",
      "Nabatiye et Tahta",
      "Ra's Bayrut",
      "Sidon",
      "Tripoli",
      "Tyre",
      "Zahle",
    ],
  },
  Lesotho: {
    code: "LS",
    cities: [
      "Butha-Buthe",
      "Hlotse",
      "Mafeteng",
      "Mapoteng",
      "Maputsoe",
      "Maseru",
      "Mazenod",
      "Mohale's Hoek",
      "Mokhotlong",
      "Nako",
      "Qacha's Nek",
      "Quthing",
      "Roma",
      "Teyateyaneng",
      "Thaba-Tseka",
    ],
  },
  Liberia: {
    code: "LR",
    cities: [
      "Buchanan",
      "Foya Kamara",
      "Ganta",
      "Ganta",
      "Gbarnga",
      "Greenville",
      "Harbel",
      "Harper",
      "Kakata",
      "Monrovia",
      "New Yekepa",
      "Pleebo City",
      "Saclepea",
      "Sanniquellie",
      "Tubmanburg",
      "Voinjama",
      "Zwedru",
    ],
  },
  "Libyan Arab Jamahiriya": {
    code: "LY",
    cities: [
      "Abu Zayyan",
      "Ajdabiya",
      "Al 'Aziziyah",
      "Al Abyar",
      "Al Ajaylat",
      "Al Asabi'ah",
      "Al Bayda'",
      "Al Burayqah",
      "Al Ghurayfah",
      "Al Hurshah",
      "Al Jadid",
      "Al Jawf",
      "Al Jumayl",
      "Al Khums",
      "Al Marj",
      "Al Mayah",
      "Al Qawasim",
      "Al Qubbah",
      "Al-'Aluas",
      "An Nasiriyah",
      "Ar Rayayinah",
      "As Sawani",
      "At Taj",
      "Az Zahra'",
      "Az Zawiyah",
      "Az Zuwaytinah",
      "Bani Walid",
      "Benghazi",
      "Bin Jawwad",
      "Brak",
      "Darnah",
      "Ghadames",
      "Gharyan",
      "Ghat",
      "Hun",
      "Jadu",
      "Jalu",
      "Janzur",
      "Kiklah",
      "Misratah",
      "Mizdah",
      "Msalatah",
      "Murzuk",
      "Nalut",
      "Qarquzah",
      "Qaryat Suluq",
      "Qasr Bin Ghashir",
      "Qasr Khiyar",
      "Qasr al Qarabulli",
      "Sabha",
      "Sabratah",
      "Shahhat",
      "Sidi Bin Zinah",
      "Sirte",
      "Suknah",
      "Surman",
      "Tajura'",
      "Tarhuna",
      "Tobruk",
      "Tripoli",
      "Tukrah",
      "Ubari",
      "Umm al Aranib",
      "Waddan",
      "Wadi 'Utbah",
      "Wardamah",
      "Yafran",
      "Zaltan",
      "Zawiya",
      "Zillah",
      "Zintan",
      "Zliten",
      "Zuwarah",
    ],
  },
  Lithuania: {
    code: "LT",
    cities: [
      "Aleksotas",
      "Alytus",
      "Antakalnis",
      "Anyksciai",
      "Birzai",
      "Dainava (Kaunas)",
      "Druskininkai",
      "Eiguliai",
      "Elektrenai",
      "Fabijoniskes",
      "Gargzdai",
      "Grigiskes",
      "Jonava",
      "Joniskis",
      "Jurbarkas",
      "Justiniskes",
      "Karoliniskes",
      "Kaunas",
      "Kedainiai",
      "Klaipeda",
      "Kretinga",
      "Kursenai",
      "Lazdynai",
      "Lentvaris",
      "Marijampole",
      "Mazeikiai",
      "Naujamiestis",
      "Naujininkai",
      "Naujoji Akmene",
      "Naujoji Vilnia",
      "Palanga",
      "Panevezys",
      "Pasilaiciai",
      "Pilaite",
      "Plunge",
      "Radviliskis",
      "Rasos",
      "Rokiskis",
      "Seskine",
      "Siauliai",
      "Silainiai",
      "Silute",
      "Taurage",
      "Telsiai",
      "Ukmerge",
      "Utena",
      "Vilkpede",
      "Vilnius",
      "Visaginas",
    ],
  },
  "Macau, China": {
    code: "MO",
    cities: ["Macau", "Taipa"],
  },
  "Macedonia, The former Yugoslav Rep. of": {
    code: "MK",
    cities: [
      "Arachinovo",
      "Berovo",
      "Bitola",
      "Bogovinje",
      "Bosilovo",
      "Brvenica",
      "Butel",
      "Cair",
      "Cegrane",
      "Centar Zupa",
      "Debar",
      "Delcevo",
      "Dolna Banjica",
      "Dolneni",
      "Dracevo",
      "Gevgelija",
      "Gjorce Petro",
      "Gostivar",
      "Ilinden",
      "Kamenjane",
      "Kavadarci",
      "Kicevo",
      "Kisela Voda",
      "Kochani",
      "Kondovo",
      "Kratovo",
      "Kriva Palanka",
      "Kumanovo",
      "Lipkovo",
      "Negotino",
      "Negotino",
      "Novo Selo",
      "Ohrid",
      "Prilep",
      "Probishtip",
      "Radovis",
      "Resen",
      "Saraj",
      "Shtip",
      "Skopje",
      "Struga",
      "Strumica",
      "Studenicani",
      "Suto Orizare",
      "Sveti Nikole",
      "Tearce",
      "Tetovo",
      "Vasilevo",
      "Veles",
      "Vinica",
      "Zajas",
      "Zelino",
    ],
  },
  Madagascar: {
    code: "MG",
    cities: [
      "Alarobia",
      "Ambalavao",
      "Ambanja",
      "Ambarakaraka",
      "Ambato Boeny",
      "Ambatofinandrahana",
      "Ambatolampy",
      "Ambatondrazaka",
      "Ambilobe",
      "Amboanjo",
      "Amboasary",
      "Ambodifotatra",
      "Ambohidratrimo",
      "Ambohimahasoa",
      "Ambohitrolomahitsy",
      "Ambositra",
      "Ambovombe",
      "Ampahana",
      "Ampanihy",
      "Amparafaravola",
      "Ampasimanolotra",
      "Andapa",
      "Andilamena",
      "Andovoranto",
      "Anjozorobe",
      "Ankazoabo",
      "Ankazobe",
      "Ankazondandy",
      "Antakotako",
      "Antalaha",
      "Antananarivo",
      "Antanifotsy",
      "Antsirabe",
      "Antsirabe Afovoany",
      "Antsiranana",
      "Antsohihy",
      "Antsohimbondrona",
      "Arivonimamo",
      "Bealanana",
      "Befandriana",
      "Bekily",
      "Belo sur Tsiribihina",
      "Beloha",
      "Beroroha",
      "Besalampy",
      "Betafo",
      "Betioky",
      "Betroka",
      "Boriziny",
      "Fandriana",
      "Farafangana",
      "Faratsiho",
      "Fenoarivo Atsinanana",
      "Fenoarivo Be",
      "Fianarantsoa",
      "Hell-Ville",
      "Ifanadiana",
      "Ifatsy",
      "Iharana",
      "Ihosy",
      "Ikalamavony",
      "Ikongo",
      "Imerintsiatosika",
      "Kopoky",
      "Maevatanana",
      "Mahajanga",
      "Mahanoro",
      "Maintirano",
      "Mampikony",
      "Manakara",
      "Mananara",
      "Mananjary",
      "Manjakandriana",
      "Maroantsetra",
      "Marolambo",
      "Marovoay",
      "Miandrarivo",
      "Miandrivazo",
      "Miarinarivo",
      "Moramanga",
      "Morombe",
      "Morondava",
      "Nosy Varika",
      "Sadabe",
      "Sahavato",
      "Sakaraha",
      "Sambava",
      "Sitampiky",
      "Soalala",
      "Soanierana Ivongo",
      "Soanindrariny",
      "Soavinandriana",
      "Toamasina",
      "Tolanaro",
      "Toliara",
      "Tranovaho",
      "Tsaratanana",
      "Tsiombe",
      "Tsiroanomandidy",
      "Vangaindrano",
      "Vatomandry",
      "Vavatenina",
      "Vohibinany",
      "Vohipaho",
      "Vohipeno",
      "Vondrozo",
    ],
  },
  Malawi: {
    code: "MW",
    cities: [
      "Balaka",
      "Blantyre",
      "Chipeni",
      "Chitipa",
      "Dedza",
      "Karonga",
      "Kasungu",
      "Lilongwe",
      "Liwonde",
      "Luchenza",
      "Mangochi",
      "Mchinji",
      "Monkey Bay",
      "Mponela",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Mzuzu",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntcheu",
      "Rumphi",
      "Salima",
      "Zomba",
    ],
  },
  Malaysia: {
    code: "MY",
    cities: [
      "Alor Gajah",
      "Alor Setar",
      "Ampang",
      "Bagan Serai",
      "Bahau",
      "Bakri",
      "Bandar Labuan",
      "Banting",
      "Batang Berjuntai",
      "Batu Arang",
      "Batu Berendam",
      "Batu Feringgi",
      "Batu Gajah",
      "Batu Pahat",
      "Beaufort",
      "Bedong",
      "Bemban",
      "Bentong Town",
      "Bidur",
      "Bintulu",
      "Bukit Mertajam",
      "Bukit Rambai",
      "Buloh Kasap",
      "Butterworth",
      "Chaah",
      "Cukai",
      "Donggongon",
      "George Town",
      "Gua Musang",
      "Gurun",
      "Ipoh",
      "Jenjarum",
      "Jerantut",
      "Jertih",
      "Jitra",
      "Johor Bahru",
      "Juru",
      "Kampar",
      "Kampong Dungun",
      "Kampong Kadok",
      "Kampong Masjid Tanah",
      "Kampong Pangkal Kalong",
      "Kampung Ayer Keroh",
      "Kampung Ayer Molek",
      "Kampung Baharu Nilai",
      "Kampung Baru Balakong",
      "Kampung Baru Subang",
      "Kampung Bukit Baharu",
      "Kampung Bukit Tinggi, Bentong",
      "Kampung Pasir Gudang Baru",
      "Kampung Simpang Renggam",
      "Kampung Sungai Ara",
      "Kampung Tanjung Karang",
      "Kangar",
      "Kapit",
      "Kelapa Sawit",
      "Keningau",
      "Kepala Batas",
      "Kertih",
      "Kinarut",
      "Klang",
      "Klebang Besar",
      "Kluang",
      "Kota Belud",
      "Kota Bharu",
      "Kota Kinabalu",
      "Kota Tinggi",
      "Kuah",
      "Kuala Kangsar",
      "Kuala Kedah",
      "Kuala Lipis",
      "Kuala Lumpur",
      "Kuala Perlis",
      "Kuala Pilah",
      "Kuala Selangor",
      "Kuala Sungai Baru",
      "Kuala Terengganu",
      "Kuang",
      "Kuantan",
      "Kuching",
      "Kudat",
      "Kulai",
      "Kulim",
      "Labis",
      "Labuan",
      "Ladang Seri Kundang",
      "Lahad Datu",
      "Lawas",
      "Limbang",
      "Lumut",
      "Malacca",
      "Marang",
      "Marudi",
      "Mentekab",
      "Mersing",
      "Miri",
      "Muar",
      "Mukah",
      "Nibong Tebal",
      "Paka",
      "Pantai Cenang",
      "Pantai Remis",
      "Papar",
      "Parit Buntar",
      "Parit Raja",
      "Pasir Mas",
      "Pekan",
      "Pekan Nenas",
      "Pelabuhan Klang",
      "Perai",
      "Peringat",
      "Permatang Kuching",
      "Petaling Jaya",
      "Pontian Kechil",
      "Port Dickson",
      "Pulau Sebang",
      "Putatan",
      "Putra Heights",
      "Putrajaya",
      "Ranau",
      "Raub",
      "Rawang",
      "Sabak Bernam",
      "Sandakan",
      "Sarikei",
      "Segamat",
      "Semenyih",
      "Semporna",
      "Sepang",
      "Seremban",
      "Serendah",
      "Shah Alam",
      "Sibu",
      "Simanggang",
      "Simpang Empat",
      "Skudai",
      "Subang Jaya",
      "Sungai Besar",
      "Sungai Pelek New Village",
      "Sungai Petani",
      "Sungai Udang",
      "Taiping",
      "Taman Senai",
      "Tampin",
      "Tanah Merah",
      "Tangkak",
      "Tanjung Sepat",
      "Tanjung Tokong",
      "Tapah Road",
      "Tasek Glugor",
      "Tawau",
      "Teluk Intan",
      "Temerluh",
      "Tumpat",
      "Ulu Tiram",
      "Yong Peng",
    ],
  },
  Maldives: {
    code: "MV",
    cities: ["Fuvahmulah", "Male"],
  },
  Mali: {
    code: "ML",
    cities: [
      "Bafoulabe",
      "Bamako",
      "Banamba",
      "Bandiagara",
      "Bla",
      "Bougouni",
      "Bourem",
      "Dialakorodji",
      "Dioila",
      "Diourou",
      "Dire",
      "Djenne",
      "Douentza",
      "Dougabougou",
      "Fana",
      "Gao",
      "Goundam",
      "Kabala",
      "Kadiolo",
      "Kalaban Koro",
      "Kangaba",
      "Kati",
      "Kayes",
      "Ke-Macina",
      "Kidal",
      "Kita",
      "Kokofata",
      "Kolokani",
      "Kolondieba",
      "Koro",
      "Koulikoro",
      "Kouri",
      "Koutiala",
      "Markala",
      "Massiogo",
      "Menaka",
      "Mopti",
      "Moribabougou",
      "Nara",
      "Niono",
      "Nioro",
      "Pelengana",
      "Sagalo",
      "San",
      "Sangarebougou",
      "Segou",
      "Sikasso",
      "Sirakoro Meguetana",
      "Siribala Coro",
      "Timbuktu",
      "Toubakoura",
      "Yanfolila",
      "Yorosso",
      "Zegoua",
    ],
  },
  Malta: {
    code: "MT",
    cities: [
      "Attard",
      "Birkirkara",
      "Birzebbuga",
      "Fgura",
      "Gzira",
      "Haz-Zebbug",
      "Imsida",
      "Marsaskala",
      "Mellieha",
      "Mosta",
      "Naxxar",
      "Qormi",
      "Rabat",
      "San Giljan",
      "San Gwann",
      "San Pawl il-Bahar",
      "Sliema",
      "Swieqi",
      "Zabbar",
      "Zejtun",
      "Zurrieq",
    ],
  },
  "Marshall Islands": {
    code: "MH",
    cities: ["Ebaye", "Majuro"],
  },
  Martinique: {
    code: "MQ",
    cities: [
      "Ducos",
      "Fort-de-France",
      "La Trinite",
      "Le Francois",
      "Le Gros-Morne",
      "Le Lamentin",
      "Le Robert",
      "Riviere-Pilote",
      "Saint-Joseph",
      "Sainte-Marie",
    ],
  },
  Mauritania: {
    code: "MR",
    cities: [
      "'Adel Bagrou",
      "Akjoujt",
      "Aleg",
      "Atar",
      "Ayoun El Atrous",
      "Bassikounou",
      "Bogue",
      "Boutilimit",
      "Dar Naim",
      "Fassale",
      "Guerou",
      "Kaedi",
      "Kiffa",
      "Lekseiba",
      "Maghama",
      "Magta' Lahjar",
      "Mbera",
      "Nema",
      "Nouadhibou",
      "Nouakchott",
      "Oualata",
      "R'kiz",
      "Rosso",
      "Selibaby",
      "Tekane",
      "Tevragh Zeina",
      "Tidjikja",
      "Timbedgha",
      "Tintane",
      "Zouerat",
    ],
  },
  Mauritius: {
    code: "MU",
    cities: [
      "Baie du Tombeau",
      "Bambous",
      "Beau Bassin-Rose Hill",
      "Bel Air Riviere Seche",
      "Centre de Flacq",
      "Chemin Grenier",
      "Curepipe",
      "Goodlands",
      "Grand Baie",
      "Lalmatie",
      "Le Hochet",
      "Mahebourg",
      "New Grove",
      "Pailles",
      "Plaine Magnien",
      "Port Louis",
      "Quatre Bornes",
      "Riviere du Rempart",
      "Rose Belle",
      "Saint Pierre",
      "Surinam",
      "Terre Rouge",
      "Triolet",
      "Vacoas",
    ],
  },
  Mexico: {
    code: "MX",
    cities: [
      "Abasolo",
      "Acajete",
      "Acala",
      "Acambaro",
      "Acanceh",
      "Acaponeta",
      "Acapulco de Juarez",
      "Acatic",
      "Acatlan de Juarez",
      "Acatlan de Osorio",
      "Acatzingo",
      "Acayucan",
      "Actopan",
      "Acuitlapilco",
      "Adolfo Ruiz Cortines",
      "Adolfo Ruiz Cortines",
      "Agua Dulce",
      "Agua Prieta",
      "Aguascalientes",
      "Ahome",
      "Ahualulco de Mercado",
      "Ajalpan",
      "Ajijic",
      "Akil",
      "Alamo",
      "Alborada Jaltenco",
      "Aldama",
      "Aldama",
      "Alfredo V. Bonfil",
      "Allende",
      "Allende",
      "Almecatla",
      "Altamira",
      "Altepexi",
      "Alto Lucero",
      "Altotonga",
      "Alvarado",
      "Alvaro Obregon",
      "Amatitan",
      "Ameca",
      "Amecameca",
      "Amozoc de Mota",
      "Ampliacion San Mateo",
      "Anahuac",
      "Anahuac",
      "Anenecuilco",
      "Angel R. Cabada",
      "Apan",
      "Apaseo el Alto",
      "Apaseo el Grande",
      "Apatzingan",
      "Apaxco de Ocampo",
      "Apizaco",
      "Arandas",
      "Arcelia",
      "Ario de Rosales",
      "Arriaga",
      "Arteaga",
      "Ascencion",
      "Ascension",
      "Asuncion Nochixtlan",
      "Atarasquillo",
      "Atencingo",
      "Atlacomulco de Fabela",
      "Atlautla",
      "Atlixco",
      "Atotonilco el Alto",
      "Atoyac de Alvarez",
      "Autlan de Navarro",
      "Axochiapan",
      "Ayotlan",
      "Ayutla de los Libres",
      "Azcapotzalco",
      "Bacalar",
      "Balancan",
      "Banderilla",
      "Benito Juarez",
      "Benito Juarez",
      "Benito Juarez",
      "Berriozabal",
      "Bochil",
      "Brisas Barra de Suchiate",
      "Bucerias",
      "Buenavista",
      "Buenavista Tomatlan",
      "Cabo San Lucas",
      "Cacahoatan",
      "Cacalomacan",
      "Cadereyta",
      "Cadereyta",
      "Cadereyta Jimenez",
      "Calimaya",
      "Calkini",
      "Calpulalpan",
      "Calvillo",
      "Camargo",
      "Campeche",
      "Cananea",
      "Canatlan",
      "Cancun",
      "Capilla de Guadalupe",
      "Capulhuac de Mirafuentes",
      "Cardenas",
      "Cardenas",
      "Carlos A. Carrillo",
      "Casa Blanca",
      "Castanos",
      "Catemaco",
      "Cedral",
      "Celaya",
      "Centro Familiar la Soledad",
      "Centro de Readaptacion Social",
      "Cerritos",
      "Cerro Azul",
      "Chahuites",
      "Chalco",
      "Champoton",
      "Chapala",
      "Charcas",
      "Chemax",
      "Cheran",
      "Chetumal",
      "Chiapa de Corzo",
      "Chiautla de Tapia",
      "Chichen-Itza",
      "Chichihualco",
      "Chicoloapan",
      "Chiconcuac",
      "Chignahuapan",
      "Chihuahua",
      "Chilapa de Alvarez",
      "Chilpancingo",
      "Chinautla",
      "Chocaman",
      "Cholula",
      "Cienega de Flores",
      "Cihuatlan",
      "Cintalapa de Figueroa",
      "Ciudad Acuna",
      "Ciudad Altamirano",
      "Ciudad Apodaca",
      "Ciudad Benito Juarez",
      "Ciudad Constitucion",
      "Ciudad Delicias",
      "Ciudad Fernandez",
      "Ciudad General Escobedo",
      "Ciudad Guadalupe Victoria",
      "Ciudad Gustavo Diaz Ordaz",
      "Ciudad Guzman",
      "Ciudad Hidalgo",
      "Ciudad Hidalgo",
      "Ciudad Juarez",
      "Ciudad Lazaro Cardenas",
      "Ciudad Lerdo",
      "Ciudad Lopez Mateos",
      "Ciudad Madero",
      "Ciudad Mante",
      "Ciudad Manuel Doblado",
      "Ciudad Melchor Muzquiz",
      "Ciudad Mendoza",
      "Ciudad Miguel Aleman",
      "Ciudad Nezahualcoyotl",
      "Ciudad Obregon",
      "Ciudad Rio Bravo",
      "Ciudad Sabinas Hidalgo",
      "Ciudad Sahagun",
      "Ciudad Serdan",
      "Ciudad Tula",
      "Ciudad Valles",
      "Ciudad Victoria",
      "Ciudad de Allende",
      "Ciudad de Armeria",
      "Ciudad de Chiautla de Tapia",
      "Ciudad de Huajuapan de Leon",
      "Ciudad de Huitzuco",
      "Ciudad de Nanacamilpa",
      "Ciudad de Villa de Alvarez",
      "Ciudad del Carmen",
      "Ciudad del Maiz",
      "Coacalco",
      "Coalcoman de Vazquez Pallares",
      "Coatepec",
      "Coatzacoalcos",
      "Coatzintla",
      "Cocula",
      "Col. Bosques de las Lomas",
      "Colima",
      "Colonia Lindavista",
      "Colonia Nativitas",
      "Colonia del Sol",
      "Colonia del Valle",
      "Colotlan",
      "Comalapa",
      "Comalcalco",
      "Comitan",
      "Comonfort",
      "Compostela",
      "Conjunto Habitacional Villas del Pedregal",
      "Contla",
      "Coquimatlan",
      "Cordoba",
      "Cordoba (Santa Leticia)",
      "Coronango",
      "Cortazar",
      "Cosamaloapan",
      "Coscomatepec de Bravo",
      "Cosoleacaque",
      "Cotija de la Paz",
      "Coyoacan",
      "Coyotepec",
      "Coyuca de Benitez",
      "Coyula",
      "Cozumel",
      "Crucecita",
      "Cruz Grande",
      "Cuajinicuilapa",
      "Cuatro Cienegas de Carranza",
      "Cuauhtemoc",
      "Cuauhtemoc",
      "Cuautepec de Hinojosa",
      "Cuautitlan",
      "Cuautitlan Izcalli",
      "Cuautla",
      "Cuautlancingo",
      "Cueramaro",
      "Cuernavaca",
      "Cuilapan de Guerrero",
      "Cuitlahuac",
      "Cuitzeo del Porvenir",
      "Culiacan",
      "Cunduacan",
      "Degollado",
      "Delegacion Cuajimalpa de Morelos",
      "Doctor Arroyo",
      "Dolores Hidalgo",
      "Don Antonio",
      "Dzitbalche",
      "Ebano",
      "Ecatepec de Morelos",
      "El Arenal",
      "El Colomo",
      "El Dorado",
      "El Fuerte",
      "El Grullo",
      "El Llano",
      "El Naranjo",
      "El Parral",
      "El Pueblito",
      "El Quince",
      "El Rosario",
      "El Rosario",
      "El Rosario",
      "El Salto",
      "El Salto",
      "El Salto",
      "El Tejar",
      "Eldorado",
      "Emiliano Zapata",
      "Emiliano Zapata",
      "Emiliano Zapata",
      "Empalme",
      "Empalme Escobedo",
      "Encarnacion de Diaz",
      "Ensenada",
      "Escarcega",
      "Escuinapa",
      "Esperanza",
      "Espita",
      "Etzatlan",
      "Ezequiel Montes",
      "Felipe Carrillo Puerto",
      "Felipe Carrillo Puerto",
      "Filomeno Mata",
      "Fortin de las Flores",
      "Fraccionamiento Ciudad Olmeca",
      "Fraccionamiento Geovillas los Pinos",
      "Fraccionamiento Real Palmas",
      "Fraccionamiento la Trinidad",
      "Francisco I. Madero",
      "Fresnillo",
      "Frontera",
      "Frontera",
      "Fuentes del Valle",
      "Galeana",
      "Garcia",
      "General Miguel Aleman",
      "Gomez Palacio",
      "Gonzalez",
      "Grajales",
      "Guacamayas",
      "Guachochi",
      "Guadalajara",
      "Guadalupe",
      "Guadalupe",
      "Guadalupe Victoria",
      "Guadalupe Victoria",
      "Guamuchil",
      "Guanajuato",
      "Guasave",
      "Guerrero Negro",
      "Gustavo Adolfo Madero",
      "Gutierrez Zamora",
      "Hacienda Santa Fe",
      "Hecelchakan",
      "Hermosillo",
      "Heroica Caborca",
      "Heroica Ciudad de Tlaxiaco",
      "Heroica Guaymas",
      "Heroica Matamoros",
      "Heroica Zitacuaro",
      "Hidalgo",
      "Huamantla",
      "Huatabampo",
      "Huatusco",
      "Huauchinango",
      "Huautla de Jimenez",
      "Huehuetoca",
      "Huejotzingo",
      "Huejutla de Reyes",
      "Huetamo de Nunez",
      "Huilango",
      "Huimanguillo",
      "Huixcolotla",
      "Huixquilucan",
      "Huixtla",
      "Hunucma",
      "Iguala de la Independencia",
      "Irapuato",
      "Isla",
      "Isla Mujeres",
      "Ixhuatlan del Sureste",
      "Ixmiquilpan",
      "Ixtaczoquitlan",
      "Ixtapa",
      "Ixtapa-Zihuatanejo",
      "Ixtapaluca",
      "Ixtapan de la Sal",
      "Ixtepec",
      "Ixtlan del Rio",
      "Izamal",
      "Iztacalco",
      "Iztapalapa",
      "Izucar de Matamoros",
      "Jacona de Plancarte",
      "Jalacingo",
      "Jalostotitlan",
      "Jalpa",
      "Jalpa de Mendez",
      "Jalpan",
      "Jaltenango de la Paz",
      "Jaltenco",
      "Jaltipan de Morelos",
      "Jamay",
      "Jamiltepec",
      "Jaral del Progreso",
      "Jardines de la Silla (Jardines)",
      "Jerez de Garcia Salinas",
      "Jesus Gomez Portugal (Margaritas)",
      "Jesus Maria",
      "Jesus del Monte",
      "Jilotepec de Molina Enriquez",
      "Jiquilpan de Juarez",
      "Jiutepec",
      "Jocotepec",
      "Jojutla",
      "Jose Cardel",
      "Jose Maria Morelos",
      "Jose Mariano Jimenez",
      "Juan Aldama",
      "Juan Jose Rios",
      "Juan Morales",
      "Juan Rodriguez Clara",
      "Juchitan de Zaragoza",
      "Juchitepec",
      "Juriquilla",
      "Juventino Rosas",
      "Kanasin",
      "La Barca",
      "La Canada",
      "La Cruz",
      "La Ermita",
      "La Isla",
      "La Joya",
      "La Joya",
      "La Joya",
      "La Magdalena Chichicaspa",
      "La Magdalena Tlaltelulco",
      "La Mira",
      "La Orilla",
      "La Paz",
      "La Piedad",
      "La Providencia Siglo XXI",
      "La Resolana",
      "La Tijera",
      "Lagos de Moreno",
      "Las Amapolas",
      "Las Choapas",
      "Las Delicias",
      "Las Margaritas",
      "Las Palmas",
      "Las Pintas de Arriba",
      "Las Pintitas",
      "Las Rosas",
      "Las Varas",
      "Las Veredas",
      "Lazaro Cardenas",
      "Leon de los Aldama",
      "Lerdo de Tejada",
      "Lerma de Villada",
      "Leyva Solano",
      "Libres",
      "Licenciado Benito Juarez",
      "Linares",
      "Loma Bonita",
      "Lomas de San Agustin",
      "Lomas de San Sebastian",
      "Lomas del Sur",
      "Lombardia",
      "Loreto",
      "Loreto",
      "Los Mochis",
      "Los Reyes Acaquilpan",
      "Los Reyes de Juarez",
      "Los Reyes de Salgado",
      "Macuspana",
      "Madera",
      "Magdalena",
      "Magdalena Atlicpac",
      "Magdalena Contreras",
      "Magdalena de Kino",
      "Maltrata",
      "Manuel Ojinaga",
      "Manzanillo",
      "Mapastepec",
      "Maravatio de Ocampo",
      "Marfil",
      "Martinez de la Torre",
      "Matamoros",
      "Matehuala",
      "Matias Romero",
      "Maxcanu",
      "Mazatlan",
      "Medina",
      "Melchor Ocampo",
      "Meoqui",
      "Merida",
      "Metepec",
      "Mexicali",
      "Mexico City",
      "Mezcales",
      "Miahuatlan",
      "Miahuatlan de Porfirio Diaz",
      "Miguel Aleman (La Doce)",
      "Miguel Auza",
      "Miguel Hidalgo",
      "Milpa Alta",
      "Minatitlan",
      "Mineral del Monte",
      "Miramar",
      "Misantla",
      "Mitras Poniente",
      "Mixquiahuala de Juarez",
      "Monclova",
      "Montemorelos",
      "Monterrey",
      "Moralillo",
      "Morelia",
      "Morelos",
      "Moroleon",
      "Motozintla",
      "Motul",
      "Moyotzingo",
      "Muna",
      "Nacajuca",
      "Nacozari Viejo",
      "Nacozari de Garcia",
      "Nahuatzen",
      "Nanchital de Lazaro Cardenas del Rio",
      "Naranjos",
      "Naucalpan de Juarez",
      "Nava",
      "Navojoa",
      "Navolato",
      "Nealtican",
      "Nicolas R Casillas",
      "Nicolas Romero",
      "Nochistlan de Mejia",
      "Nogales",
      "Nogales",
      "Nueva Italia de Ruiz",
      "Nueva Palestina",
      "Nueva Rosita",
      "Nuevo Casas Grandes",
      "Nuevo Ideal",
      "Nuevo Laredo",
      "Nuevo Mexico",
      "Nuevo Progreso",
      "Nuevo San Juan Parangaricutiro",
      "Oaxaca",
      "Ocosingo",
      "Ocotlan",
      "Ocotlan de Morelos",
      "Ocoyoacac",
      "Ocozocoautla de Espinosa",
      "Ojinaga",
      "Ojo de Agua",
      "Ojuelos de Jalisco",
      "Oluta",
      "Ometepec",
      "Opopeo",
      "Orizaba",
      "Oteapan",
      "Otumba",
      "Oxkutzkab",
      "Ozumba de Alzate",
      "Pabellon de Arteaga",
      "Pachuca de Soto",
      "Pajacuaran",
      "Palau",
      "Palenque",
      "Palmarito Tochapan",
      "Palmira",
      "Panuco",
      "Papalotla",
      "Papantla de Olarte",
      "Paracho de Verduzco",
      "Paraiso",
      "Parque Industrial Ciudad Mitras",
      "Parral",
      "Parras de la Fuente",
      "Parrilla II",
      "Paseos de San Juan",
      "Paso del Macho",
      "Patzcuaro",
      "Penjamo",
      "Periban de Ramos",
      "Perote",
      "Petatlan",
      "Peto",
      "Pichucalco",
      "Piedras Negras",
      "Pijijiapan",
      "Pinotepa Nacional",
      "Plan de Ayala",
      "Platon Sanchez",
      "Playa del Carmen",
      "Playas del Rosario",
      "Polanco",
      "Pomoca",
      "Poncitlan",
      "Porticos de San Antonio",
      "Poza Rica de Hidalgo",
      "Profesor Carlos Hank Gonzalez",
      "Progreso",
      "Progreso",
      "Progreso",
      "Progreso Industrial",
      "Progreso de Alvaro Obregon",
      "Puebla",
      "Puebla",
      "Pueblo Nuevo",
      "Pueblo Nuevo",
      "Pueblo Yaqui",
      "Puente de Ixtla",
      "Puerto Escondido",
      "Puerto Escondido (Tepeolulco Puerto Escondido)",
      "Puerto Penasco",
      "Puerto Vallarta",
      "Purepero de Echaiz",
      "Purisima de Bustos",
      "Puruandiro",
      "Putla Villa de Guerrero",
      "Quecholac",
      "Quiroga",
      "Ramos Arizpe",
      "Real del Valle (El Paraiso) [Fraccionamiento]",
      "Reforma",
      "Reyes Acozac",
      "Reynosa",
      "Rincon de Romos",
      "Rincon de Tamayo",
      "Rio Blanco",
      "Rio Grande",
      "Rio Grande",
      "Rio de Teapa",
      "Rioverde",
      "Rodolfo Sanchez Taboada",
      "Romita",
      "Rosarito",
      "Ruiz",
      "Sabinas",
      "Sahuayo de Morelos",
      "Salamanca",
      "Salina Cruz",
      "Salinas de Hidalgo",
      "Saltillo",
      "Salvatierra",
      "San Agustin Tlaxiaca",
      "San Andres Ahuashuatepec",
      "San Andres Cholula",
      "San Andres Cuexcontitlan",
      "San Andres Mixquic",
      "San Andres Tuxtla",
      "San Antonio Acahualco",
      "San Antonio Tecomitl",
      "San Antonio de la Cal",
      "San Antonio la Isla",
      "San Bartolo Cuautlalpan",
      "San Bartolo del Llano",
      "San Bartolome Tlaltelulco",
      "San Bernardino Tlaxcalancingo",
      "San Blas",
      "San Blas Atempa",
      "San Buenaventura",
      "San Buenaventura",
      "San Carlos",
      "San Cristobal de las Casas",
      "San Felipe",
      "San Felipe",
      "San Fernando",
      "San Francisco Acuautla",
      "San Francisco Cuaxusco",
      "San Francisco Ocotlan",
      "San Francisco Tecoxpa",
      "San Francisco Telixtlahuaca",
      "San Francisco Tlalcilalcalpan",
      "San Francisco de los Romo",
      "San Francisco del Rincon",
      "San Gabriel Chilac",
      "San Gregorio Cuautzingo",
      "San Isidro",
      "San Jacinto Amilpas",
      "San Jeronimo",
      "San Jeronimo Cuatro Vientos",
      "San Jorge Pueblo Nuevo",
      "San Jose Guadalupe Otzacatipan",
      "San Jose Iturbide",
      "San Jose de los Olvera",
      "San Jose del Cabo",
      "San Jose del Castillo",
      "San Jose del Valle",
      "San Jose el Alto",
      "San Juan Ixtayopan",
      "San Juan Zitlaltepec",
      "San Juan de Abajo",
      "San Juan de las Huertas",
      "San Juan de los Lagos",
      "San Juan del Rio",
      "San Juan y San Pedro Tezompa",
      "San Juanito",
      "San Julian",
      "San Lorenzo Acopilco",
      "San Luis Potosi",
      "San Luis Rio Colorado",
      "San Luis de la Paz",
      "San Marcos",
      "San Marcos",
      "San Marcos Huixtoco",
      "San Martin Azcatepec",
      "San Martin Cuautlalpan",
      "San Martin Texmelucan de Labastida",
      "San Martin de las Piramides",
      "San Mateo Atenco",
      "San Mateo Huitzilzingo",
      "San Mateo Otzacatipan",
      "San Matias Tlalancaleca",
      "San Miguel Ajusco",
      "San Miguel Canoa",
      "San Miguel Coatlinchan",
      "San Miguel Octopan",
      "San Miguel Topilejo",
      "San Miguel Xoxtla",
      "San Miguel de Allende",
      "San Miguel el Alto",
      "San Nicolas de los Garza",
      "San Pablo Atlazalpan",
      "San Pablo Autopan",
      "San Pablo Oztotepec",
      "San Pablo de las Salinas",
      "San Pedro",
      "San Pedro",
      "San Pedro Apatlaco",
      "San Pedro Garza Garcia",
      "San Pedro Los Banos",
      "San Pedro Martir",
      "San Pedro Pochutla",
      "San Pedro Tlaltizapan",
      "San Pedro Totoltepec",
      "San Pedro Tultepec",
      "San Pedro de los Banos",
      "San Rafael",
      "San Rafael Tlanalapan",
      "San Salvador Atenco",
      "San Salvador Cuauhtenco",
      "San Salvador El Seco",
      "San Salvador Tizatlalli",
      "San Sebastian Chimalpa",
      "San Sebastian el Grande",
      "San Vicente",
      "Sanctorum",
      "Santa Ana",
      "Santa Ana Chiautempan",
      "Santa Ana Nextlalpan",
      "Santa Ana Pacueco",
      "Santa Ana Tlacotenco",
      "Santa Ana Xalmimilulco",
      "Santa Anita",
      "Santa Catarina",
      "Santa Catarina Ayotzingo",
      "Santa Clara del Cobre",
      "Santa Cruz Amilpas",
      "Santa Cruz Xoxocotlan",
      "Santa Cruz de las Flores",
      "Santa Cruz del Valle",
      "Santa Isabel",
      "Santa Maria Ajoloapan",
      "Santa Maria Atzompa",
      "Santa Maria Chimalhuacan",
      "Santa Maria Huexoculco",
      "Santa Maria Totoltepec",
      "Santa Maria Xonacatepec",
      "Santa Maria Zacatepec",
      "Santa Maria Zolotepec",
      "Santa Maria del Rio",
      "Santa Rosa Jauregui",
      "Santa Rosa Treinta",
      "Santa Rosalia",
      "Santa Teresa",
      "Santiago",
      "Santiago Cuautlalpan",
      "Santiago Ixcuintla",
      "Santiago Momoxpan",
      "Santiago Papasquiaro",
      "Santiago Tangamandapio",
      "Santiago Teyahualco",
      "Santiago Tianguistenco de Galeana",
      "Santiago Tilapa",
      "Santiago Tlacotepec",
      "Santiago Tulantepec",
      "Santiago Tuxtla",
      "Santiago Yancuitlalpan",
      "Santiago de Queretaro",
      "Santo Domingo Tehuantepec",
      "Saucillo",
      "Sayula",
      "Sayula de Aleman",
      "Silao",
      "Simojovel de Allende",
      "Soledad de Doblado",
      "Soledad de Graciano Sanchez",
      "Sombrerete",
      "Sonoita",
      "Soto la Marina",
      "Suchiapa",
      "Tacambaro de Codallos",
      "Tala",
      "Tamazula de Gordiano",
      "Tamazunchale",
      "Tampico",
      "Tamuin",
      "Tangancicuaro de Arista",
      "Tanquian de Escobedo",
      "Tantoyuca",
      "Tapachula",
      "Tarimoro",
      "Taxco de Alarcon",
      "Teapa",
      "Tecalitlan",
      "Tecamac",
      "Tecamac de Felipe Villanueva",
      "Tecamachalco",
      "Tecate",
      "Tecax",
      "Tecoman",
      "Tecpan de Galeana",
      "Tecuala",
      "Tehuacan",
      "Tejupilco de Hidalgo",
      "Teloloapan",
      "Temascalcingo",
      "Temixco",
      "Tempoal de Sanchez",
      "Tenancingo",
      "Tenancingo de Degollado",
      "Tenango de Arista",
      "Tenosique",
      "Teocaltiche",
      "Teolocholco",
      "Teoloyucan",
      "Teopisca",
      "Teotihuacan de Arista",
      "Tepalcatepec",
      "Tepalcingo",
      "Tepatepec",
      "Tepatitlan de Morelos",
      "Tepatlaxco de Hidalgo",
      "Tepeaca",
      "Tepeapulco",
      "Tepeji del Rio de Ocampo",
      "Tepetlixpa",
      "Tepexpan",
      "Tepic",
      "Tepotzotlan",
      "Tepoztlan",
      "Tequila",
      "Tequisquiapan",
      "Tequixquiac",
      "Terrazas del Valle",
      "Tesistan",
      "Tetela del Volcan",
      "Tetla",
      "Texcoco de Mora",
      "Teziutlan",
      "Tezoyuca",
      "Ticul",
      "Tierra Blanca",
      "Tierra Colorada",
      "Tihuatlan",
      "Tijuana",
      "Tixkokob",
      "Tixtla de Guerrero",
      "Tizapan el Alto",
      "Tizayuca",
      "Tizimin",
      "Tlacolula de Matamoros",
      "Tlahuac",
      "Tlaixpan",
      "Tlajomulco de Zuniga",
      "Tlalmanalco",
      "Tlalnepantla",
      "Tlalpan",
      "Tlaltenango de Sanchez Roman",
      "Tlaltizapan",
      "Tlapa de Comonfort",
      "Tlapacoyan",
      "Tlaquepaque",
      "Tlaquiltenango",
      "Tlaxcala",
      "Tlaxco",
      "Tlaxcoapan",
      "Toluca",
      "Tonala",
      "Tonala",
      "Torreon",
      "Tototlan",
      "Trancoso",
      "Tres Valles",
      "Tres de Mayo",
      "Tula de Allende",
      "Tulancingo",
      "Tulantongo",
      "Tultepec",
      "Tultitlan de Mariano Escobedo",
      "Tulum",
      "Tuxpam de Rodriguez Cano",
      "Tuxpan",
      "Tuxpan",
      "Tuxtepec",
      "Tuxtla",
      "Tuzantla",
      "Uman",
      "Unidad Habitacional Jose Maria Morelos y Pavon",
      "Union Hidalgo",
      "Uriangato",
      "Ursulo Galvan",
      "Uruapan",
      "Valente Diaz",
      "Valladolid",
      "Valle Hermoso",
      "Valle de Bravo",
      "Valle de Santiago",
      "Valparaiso",
      "Valtierrilla",
      "Veintidos de Febrero",
      "Venceremos",
      "Venustiano Carranza",
      "Venustiano Carranza",
      "Venustiano Carranza",
      "Veracruz",
      "Vicente Guerrero",
      "Vicente Guerrero",
      "Vicente Guerrero",
      "Victor Rosales",
      "Victoria de Durango",
      "Villa Corzo",
      "Villa Cuauhtemoc",
      "Villa Hidalgo",
      "Villa Independencia",
      "Villa Juarez",
      "Villa Juarez",
      "Villa Lazaro Cardenas",
      "Villa Tecolutilla",
      "Villa Union",
      "Villa Union",
      "Villa Vicente Guerrero",
      "Villa de Costa Rica",
      "Villa de El Carmen Tequexquitla",
      "Villa de Reyes",
      "Villa de Zaachila",
      "Villa del Campo",
      "Villa del Prado 2da Seccion",
      "Villaflores",
      "Villagran",
      "Villahermosa",
      "Villanueva",
      "Villas de la Hacienda [Fraccionamiento]",
      "Vista Hermosa de Negrete",
      "Xalapa de Enriquez",
      "Xalatlaco",
      "Xalisco",
      "Xaloztoc",
      "Xico",
      "Xico",
      "Xicohtzinco",
      "Xicotepec de Juarez",
      "Xochimilco",
      "Xochitepec",
      "Xonacatlan",
      "Xoxocotla",
      "Yahualica de Gonzalez Gallo",
      "Yajalon",
      "Yautepec",
      "Yecapixtla",
      "Yurecuaro",
      "Yuriria",
      "Zacapu",
      "Zacapu",
      "Zacatecas",
      "Zacatelco",
      "Zacatepec",
      "Zacatepec",
      "Zacatlan",
      "Zacoalco",
      "Zacualtipan",
      "Zamora",
      "Zapopan",
      "Zapotiltic",
      "Zapotlanejo",
      "Zaragoza",
      "Zaragoza",
      "Zimapan",
      "Zimatlan de Alvarez",
      "Zinacantepec",
      "Zinacatepec",
      "Zinapecuaro",
      "Zumpango",
      "Zumpango del Rio",
    ],
  },
  "Moldova, Republic of": {
    code: "MD",
    cities: [
      "Balti",
      "Basarabeasca",
      "Bender",
      "Bilicenii Vechi",
      "Briceni",
      "Cahul",
      "Calarasi",
      "Camenca",
      "Causeni",
      "Ceadir-Lunga",
      "Chisinau",
      "Cimislia",
      "Codru",
      "Comrat",
      "Congaz",
      "Dnestrovsc",
      "Drochia",
      "Dubasari",
      "Edinet",
      "Falesti",
      "Floresti",
      "Glodeni",
      "Hincesti",
      "Ialoveni",
      "Leova",
      "Nisporeni",
      "Orhei",
      "Ribnita",
      "Riscani",
      "Singerei",
      "Slobozia",
      "Soroca",
      "Straseni",
      "Taraclia",
      "Tiraspol",
      "Ungheni",
      "Vulcanesti",
    ],
  },
  Monaco: {
    code: "MC",
    cities: ["La Condamine", "Monaco", "Monte-Carlo"],
  },
  Mongolia: {
    code: "MN",
    cities: [
      "Altai",
      "Arvayheer",
      "Baruun-Urt",
      "Bayanhongor",
      "Bulgan",
      "Choyr",
      "Dalandzadgad",
      "Darhan",
      "Dzuunharaa",
      "Dzuunmod",
      "Erdenet",
      "Hovd",
      "Khovd",
      "Mandalgovi",
      "Moeroen",
      "OElgii",
      "Saynshand",
      "Suehbaatar",
      "Tsetserleg",
      "Ulaangom",
      "Ulan Bator",
      "Uliastay",
      "Undurkhaan",
      "Zuunmod",
    ],
  },
  Montenegro: {
    code: "ME",
    cities: [
      "Bar",
      "Berane",
      "Bijelo Polje",
      "Budva",
      "Cetinje",
      "Herceg Novi",
      "Niksic",
      "Pljevlja",
      "Podgorica",
      "Ulcinj",
    ],
  },
  Morocco: {
    code: "MA",
    cities: [
      "Ad Darwa",
      "Afourer",
      "Agadir",
      "Agdz",
      "Agourai",
      "Aguelmous",
      "Ahfir",
      "Ain Attig",
      "Ain Beni Mathar",
      "Ain Cheggag",
      "Ain El Aouda",
      "Ain Harrouda",
      "Ain Taoujdat",
      "Ait Melloul",
      "Ait Ourir",
      "Ait Yazza",
      "Aklim",
      "Al Aaroui",
      "Al Brouj",
      "Al Fqih Ben Calah",
      "Al Hoceima",
      "Almis du Guigou",
      "Amizmiz",
      "Aoulouz",
      "Aourir",
      "Ar Rommani",
      "Arfoud",
      "Asilah",
      "Assa",
      "Azemmour",
      "Azilal",
      "Azrou",
      "Bejaad",
      "Ben Ahmed",
      "Ben Guerir",
      "Ben Jerrar",
      "Ben Taieb",
      "Beni Enzar",
      "Beni Mellal",
      "Beni Yakhlef",
      "Benslimane",
      "Berkane",
      "Berrechid",
      "Bhalil",
      "Bir Jdid",
      "Bni Bouayach",
      "Bni Drar",
      "Bou Fekrane",
      "Bou Izakarn",
      "Bouarfa",
      "Boudenib",
      "Bouizazarene Ihaddadene",
      "Boujniba",
      "Bouknadel",
      "Boumalne Dades",
      "Boumia",
      "Bouskoura",
      "Bouznika",
      "Casablanca",
      "Chefchaouen",
      "Chichaoua",
      "Dakhla",
      "Dar Bouazza",
      "Dar Ould Zidouh",
      "Dchira El Jihadia",
      "Demnate",
      "Douar Toulal",
      "Driouch",
      "Echemmaia Est",
      "El Aioun",
      "El Gara",
      "El Guerdane",
      "El Hajeb",
      "El Harhoura",
      "El Jadida",
      "El Kelaa des Srarhna",
      "El Ksiba",
      "El Mansouria",
      "El Menzel",
      "El Ouatia",
      "Errachidia",
      "Essaouira",
      "Fes",
      "Fes al Bali",
      "Figuig (Centre)",
      "Fnidek",
      "Goulmima",
      "Guelmim",
      "Guercif",
      "Gueznaia",
      "Had Soualem",
      "Hattane",
      "Ifrane",
      "Ifrane",
      "Imi-n-Tanout",
      "Imouzzer Kandar",
      "Imzouren",
      "Inezgane",
      "Jamaat Shaim",
      "Jem'at Oulad 'Abbou",
      "Jerada",
      "Jorf",
      "Karia Ba Mohamed",
      "Kasba Tadla",
      "Kelaat Mgouna",
      "Kenitra",
      "Khemisset",
      "Khenichet-sur Ouerrha",
      "Khenifra",
      "Khouribga",
      "Ksar El Kebir",
      "Laattaouia",
      "Lahraouyine",
      "Lalla Mimouna",
      "Larache",
      "Loudaya",
      "Lqoliaa",
      "Marrakesh",
      "Martil",
      "Mdiq",
      "Mechouar-Kasba",
      "Mechraa Bel Ksiri",
      "Mediouna",
      "Mehdya",
      "Meknes",
      "Mers El Kheir",
      "Midar",
      "Midelt",
      "Missour",
      "Mohammedia",
      "Moulay Abdallah",
      "Moulay Ali Cherif",
      "Moulay Driss Zerhoun",
      "Mrirt",
      "My Drarga",
      "Nador",
      "New Fes",
      "Oadar Temsia",
      "Ouaouizeght",
      "Ouarzazate",
      "Oued Amlil",
      "Oued Laou",
      "Oued Zem",
      "Ouezzane",
      "Ouislane",
      "Oujda",
      "Oulad Ayad",
      "Oulad Barhil",
      "Oulad Embarek",
      "Oulad Frej",
      "Oulad Tayeb",
      "Oulad Teima",
      "Oulad Yaich",
      "Oulmes",
      "Outat Oulad Al Haj",
      "Rabat",
      "Ras el Ma",
      "Recani",
      "Ribat Al Khayr",
      "Rich",
      "Sabaa Aiyoun",
      "Safi",
      "Sale",
      "Sale Al Jadida",
      "Sebt Gzoula",
      "Sefrou",
      "Selouane",
      "Settat",
      "Setti Fatma",
      "Sidi Allal El Bahraoui",
      "Sidi Bennour",
      "Sidi Bou Lanouar",
      "Sidi Bou Othmane",
      "Sidi Ifni",
      "Sidi Kacem",
      "Sidi Lmokhtar",
      "Sidi Rahal",
      "Sidi Rahhal",
      "Sidi Slimane",
      "Sidi Slimane Echcharaa",
      "Sidi Taibi",
      "Sidi Yahia El Gharb",
      "Sidi Yahya Zaer",
      "Sidi Zouine",
      "Skhirate",
      "Smara",
      "Souk El Arbaa",
      "Souk et Tnine Jorf el Mellah",
      "Souq Larb'a al Gharb",
      "Souq Sebt Oulad Nemma",
      "Tabount",
      "Tahannout",
      "Tahla",
      "Tamallalt",
      "Tamanar",
      "Tamansourt",
      "Tamesna",
      "Tan-Tan",
      "Tangier",
      "Taounate",
      "Taourirt",
      "Targuist",
      "Taroudant",
      "Tassila",
      "Tata",
      "Taza",
      "Temara",
      "Tetouan",
      "Tiflet",
      "Tine Biougra",
      "Tinghir",
      "Tirhanimine",
      "Tissa",
      "Tit Mellil",
      "Tiznit",
      "Youssoufia",
      "Zag",
      "Zagora",
      "Zaio",
      "Zaouia Ait Ishak",
      "Zawyat an Nwacer",
      "Zawyat ech Cheikh",
      "Zeghanghane",
      "Zemamra",
      "Zoualet",
    ],
  },
  Myanmar: {
    code: "MM",
    cities: [
      "Bago",
      "Bhamo",
      "Bogale",
      "Chauk",
      "Dawei",
      "Hakha",
      "Hinthada",
      "Hpa-An",
      "Kanbe",
      "Kawthoung",
      "Kayan",
      "Keng Tung",
      "Kyaikkami",
      "Kyaiklat",
      "Kyaikto",
      "Kyain Seikgyi Township",
      "Kyaukse",
      "Lashio",
      "Letpandan",
      "Loikaw",
      "Magway",
      "Mandalay",
      "Martaban",
      "Maubin",
      "Mawlaik",
      "Mawlamyine",
      "Mawlamyinegyunn",
      "Meiktila",
      "Minbu",
      "Mogok",
      "Monywa",
      "Mudon",
      "Myanaung",
      "Myawadi",
      "Myaydo",
      "Myeik",
      "Myingyan",
      "Myitkyina",
      "Nay Pyi Taw",
      "Nyaungdon",
      "Nyaunglebin",
      "Pakokku",
      "Pathein",
      "Paungde",
      "Pyapon",
      "Pyay",
      "Pyin Oo Lwin",
      "Pyinmana",
      "Pyu",
      "Sagaing",
      "Shwebo",
      "Sittwe",
      "Syriam",
      "Tachilek",
      "Taungdwingyi",
      "Taunggyi",
      "Taungoo",
      "Thanatpin",
      "Tharyarwady",
      "Thaton",
      "Thayetmyo",
      "Thongwa",
      "Twante",
      "Wakema",
      "Yamethin",
      "Yangon",
      "Yenangyaung",
    ],
  },
  Samoa: {
    code: "WS",
    cities: ["Apia"],
  },
  Mozambique: {
    code: "MZ",
    cities: [
      "Alto Molocue",
      "Angoche",
      "Beira",
      "Boane",
      "Buzi",
      "Caia",
      "Catandica",
      "Chibuto",
      "Chimoio",
      "Chinde",
      "Chiure",
      "Chokwe",
      "Cuamba",
      "Dondo",
      "Gondola",
      "Gorongosa",
      "Gurue",
      "Iapala",
      "Inhambane",
      "Inhaminga",
      "Inhassoro",
      "Lichinga",
      "Machipanda",
      "Macia",
      "Macomia",
      "Maganja",
      "Magude",
      "Malema",
      "Mandimba",
      "Manhica",
      "Manica",
      "Manjacaze",
      "Maputo",
      "Marracuene",
      "Marromeu",
      "Massinga",
      "Matola",
      "Maxixe",
      "Meconta",
      "Messica",
      "Metangula",
      "Micolene",
      "Milange",
      "Moamba",
      "Moatize",
      "Mocimboa",
      "Mocimboa da Praia",
      "Mocuba",
      "Monapo",
      "Montepuez",
      "Morrumbala",
      "Morrumbene",
      "Mossuril",
      "Mozambique",
      "Mueda",
      "Murrupula",
      "Mutuali",
      "Nacala",
      "Namaacha",
      "Namacurra",
      "Namapa-erati",
      "Nametil",
      "Namialo",
      "Nampula",
      "Nhamatanda",
      "Nhamayabue",
      "Pebane",
      "Pemba",
      "Quelimane",
      "Quissico",
      "Ressano Garcia",
      "Ribaue",
      "Songo",
      "Tete",
      "Ulongue",
      "Vilankulo",
      "Xai-Xai",
      "Xilembene",
      "Xinavane",
    ],
  },
  Namibia: {
    code: "NA",
    cities: [
      "Bethanie",
      "Gobabis",
      "Grootfontein",
      "Helao Nafidi",
      "Katima Mulilo",
      "Katutura",
      "Keetmanshoop",
      "Khorixas",
      "Luderitz",
      "Mariental",
      "Okahandja",
      "Omaruru",
      "Ondangwa",
      "Ongwediva",
      "Oshakati",
      "Otjiwarongo",
      "Outjo",
      "Rehoboth",
      "Rundu",
      "Swakopmund",
      "Tsumeb",
      "Walvis Bay",
      "Windhoek",
    ],
  },
  Nepal: {
    code: "NP",
    cities: [
      "Baglung",
      "Banepa",
      "Bhadrapur",
      "Bharatpur",
      "Bhimdatta",
      "Biratnagar",
      "Birendranagar",
      "Birganj",
      "Butwal",
      "Dadeldhura",
      "Dailekh",
      "Darchula",
      "Dhangadhi",
      "Dhankuta",
      "Dharan",
      "Dhulikhel",
      "Dipayal",
      "Gaur",
      "Gulariya",
      "Hetauda",
      "Ilam",
      "Inaruwa",
      "Itahari",
      "Jaleshwar",
      "Janakpur",
      "Kathmandu",
      "Khadbari",
      "Kirtipur",
      "Lahan",
      "Madhyapur Thimi",
      "Malangawa",
      "Nepalgunj",
      "Panauti",
      "Panauti",
      "Patan",
      "Pokhara",
      "Rajbiraj",
      "Siddharthanagar",
      "Siraha",
      "Tansen",
      "Tikapur",
      "Triyuga",
      "Tulsipur",
      "Walin",
    ],
  },
  Netherlands: {
    code: "NL",
    cities: [
      "'s-Gravenzande",
      "'s-Hertogenbosch",
      "Aalsmeer",
      "Aalten",
      "Alblasserdam",
      "Alkmaar",
      "Almelo",
      "Almere Stad",
      "Alphen aan den Rijn",
      "Amersfoort",
      "Amstelveen",
      "Amsterdam",
      "Amsterdam-Zuidoost",
      "Apeldoorn",
      "Appingedam",
      "Arnhem",
      "Assen",
      "Assendelft",
      "Baarn",
      "Barendrecht",
      "Barneveld",
      "Bemmel",
      "Bennekom",
      "Benthuizen",
      "Bergen",
      "Bergen op Zoom",
      "Bergeyk",
      "Bergschenhoek",
      "Berkel en Rodenrijs",
      "Best",
      "Beuningen",
      "Beverwijk",
      "Bleiswijk",
      "Bloemhof",
      "Bodegraven",
      "Borculo",
      "Borne",
      "Borssele",
      "Boskoop",
      "Boxmeer",
      "Boxtel",
      "Breda",
      "Breukelen",
      "Brummen",
      "Brunssum",
      "Bussum",
      "Camminghaburen",
      "Capelle aan den IJssel",
      "Carnisse",
      "Castricum",
      "Coevorden",
      "Cranendonck",
      "Culemborg",
      "Dalfsen",
      "De Lier",
      "De Meern",
      "Delft",
      "Delfzijl",
      "Den Helder",
      "Deventer",
      "Diemen",
      "Dieren",
      "Doesburg",
      "Doetinchem",
      "Dokkum",
      "Dongen",
      "Doorn",
      "Dordrecht",
      "Drachten",
      "Driebergen-Rijsenburg",
      "Dronten",
      "Duiven",
      "Ede",
      "Eerbeek",
      "Eersel",
      "Eibergen",
      "Eijsden",
      "Eindhoven",
      "Elburg",
      "Elst",
      "Emmeloord",
      "Emmen",
      "Enkhuizen",
      "Enschede",
      "Epe",
      "Ermelo",
      "Franeker",
      "Geertruidenberg",
      "Geldermalsen",
      "Geldrop",
      "Geleen",
      "Gemert",
      "Gennep",
      "Goedereede",
      "Goes",
      "Goirle",
      "Goor",
      "Gorinchem",
      "Gouda",
      "Grave",
      "Groesbeek",
      "Groningen",
      "Groot IJsselmonde",
      "Haaksbergen",
      "Haarlem",
      "Hardenberg",
      "Harderwijk",
      "Harenkarspel",
      "Harlingen",
      "Hatert",
      "Hattem",
      "Heemskerk",
      "Heemstede",
      "Heerenveen",
      "Heerhugowaard",
      "Heerlen",
      "Heesch",
      "Heiloo",
      "Hellevoetsluis",
      "Helmond",
      "Hendrik-Ido-Ambacht",
      "Hengelo",
      "Hillegom",
      "Hilversum",
      "Hoensbroek",
      "Hoge Vucht",
      "Hoofddorp",
      "Hoogeveen",
      "Hoogezand",
      "Hoogland",
      "Hoogvliet",
      "Hoorn",
      "Huizen",
      "IJmuiden",
      "IJsselstein",
      "Joure",
      "Kampen",
      "Katwijk aan Zee",
      "Kerkrade",
      "Klazienaveen",
      "Korrewegwijk",
      "Korvel",
      "Krimpen aan den IJssel",
      "Laren",
      "Leek",
      "Leerdam",
      "Leeuwarden",
      "Leiden",
      "Leiderdorp",
      "Lelystad",
      "Lemmer",
      "Leusden",
      "Lichtenvoorde",
      "Lindenholt",
      "Lisse",
      "Lochem",
      "Lombardijen",
      "Loon op Zand",
      "Lopik",
      "Losser",
      "Lunetten",
      "Lunteren",
      "Maarssen",
      "Maassluis",
      "Maastricht",
      "Made",
      "Marsdijk",
      "Medemblik",
      "Meppel",
      "Merenwijk",
      "Middelburg",
      "Middelharnis",
      "Mijdrecht",
      "Monster",
      "Naaldwijk",
      "Naarden",
      "Nieuwegein",
      "Nijkerk",
      "Nijmegen",
      "Noordwijk-Binnen",
      "Noordwijkerhout",
      "Nuenen",
      "Nunspeet",
      "Oegstgeest",
      "Oirschot",
      "Oisterwijk",
      "Oldenzaal",
      "Ommen",
      "Ommoord",
      "Oosterbeek",
      "Oosterhout",
      "Oosterpark",
      "Opmeer",
      "Oss",
      "Oud-Beijerland",
      "Papendrecht",
      "Pendrecht",
      "Pijnacker",
      "Prinsenbeek",
      "Purmerend",
      "Putten",
      "Raalte",
      "Reeuwijk",
      "Rhenen",
      "Rhoon",
      "Ridderkerk",
      "Rijen",
      "Rijswijk",
      "Roermond",
      "Roosendaal",
      "Rotterdam",
      "Rozenburg",
      "Sassenheim",
      "Scheveningen",
      "Schiebroek",
      "Schiedam",
      "Schijndel",
      "Schoonhoven",
      "Sint-Oedenrode",
      "Sittard",
      "Sliedrecht",
      "Sneek",
      "Soest",
      "Someren",
      "Spangen",
      "Spijkenisse",
      "Stadskanaal",
      "Staphorst",
      "Steenbergen",
      "Steenwijk",
      "Stein",
      "Tegelen",
      "Terneuzen",
      "The Hague",
      "Tiel",
      "Tilburg",
      "Tongelre",
      "Tubbergen",
      "Twello",
      "Uden",
      "Uitgeest",
      "Uithoorn",
      "Ulft",
      "Urk",
      "Utrecht",
      "Vaassen",
      "Valkenswaard",
      "Veendam",
      "Veenendaal",
      "Veghel",
      "Velp",
      "Velsen-Zuid",
      "Venlo",
      "Venray",
      "Vianen",
      "Vlaardingen",
      "Vlissingen",
      "Volendam",
      "Voorburg",
      "Voorschoten",
      "Vreewijk",
      "Vriezenveen",
      "Vught",
      "Waalwijk",
      "Waddinxveen",
      "Wageningen",
      "Wassenaar",
      "Weert",
      "Weesp",
      "Werkendam",
      "Wierden",
      "Wijchen",
      "Wijk bij Duurstede",
      "Winschoten",
      "Winterswijk",
      "Wisch",
      "Woerden",
      "Wolvega",
      "Woudenberg",
      "Ypenburg",
      "Zaandam",
      "Zaanstad",
      "Zaltbommel",
      "Zandvoort",
      "Zeewolde",
      "Zeist",
      "Zevenaar",
      "Zierikzee",
      "Zoetermeer",
      "Zuidwijk",
      "Zutphen",
      "Zwijndrecht",
      "Zwolle",
    ],
  },
  "New Caledonia": {
    code: "NC",
    cities: ["Dumbea", "Mont-Dore", "Noumea", "Paita", "We"],
  },
  "New Zealand": {
    code: "NZ",
    cities: [
      "Albany",
      "Ashburton",
      "Auckland",
      "Avondale",
      "Balmoral",
      "Beach Haven",
      "Birkenhead",
      "Blenheim",
      "Blockhouse Bay",
      "Browns Bay",
      "Cambridge",
      "Carterton",
      "Christchurch",
      "Dunedin",
      "Epsom",
      "Favona",
      "Flaxmere",
      "Forrest Hill",
      "Gisborne",
      "Gore",
      "Grey Lynn",
      "Hamilton",
      "Hamilton East",
      "Hastings",
      "Hawera",
      "Hillcrest",
      "Hillsborough",
      "Hokowhitu",
      "Homai",
      "Howick",
      "Invercargill",
      "Kaiapoi",
      "Karori",
      "Levin",
      "Lincoln",
      "Lower Hutt",
      "Mangere",
      "Mangere East",
      "Manukau City",
      "Manurewa",
      "Massey",
      "Massey East",
      "Masterton",
      "Napier",
      "Nelson",
      "New Lynn",
      "New Plymouth",
      "North Shore",
      "Oamaru",
      "Orewa",
      "Otahuhu",
      "Otara",
      "Palmerston North",
      "Papakura",
      "Papatoetoe",
      "Paraparaumu",
      "Parklands",
      "Porirua",
      "Pukekohe East",
      "Queenstown",
      "Rangiora",
      "Redwoodtown",
      "Remuera",
      "Riccarton",
      "Richmond",
      "Rolleston",
      "Rotorua",
      "Sandringham",
      "Stoke",
      "Stokes Valley",
      "Takanini",
      "Taradale",
      "Taupo",
      "Tauranga",
      "Te Aro",
      "Te Atatu Peninsula",
      "Te Atatu South",
      "Timaru",
      "Tokoroa",
      "Upper Hutt",
      "Waikanae",
      "Wainuiomata",
      "Wanaka",
      "Wellington",
      "West Harbour",
      "Western Heights",
      "Whakatane",
      "Whanganui",
      "Whangarei",
      "Whitby",
      "Wigram",
    ],
  },
  Nicaragua: {
    code: "NI",
    cities: [
      "Acoyapa",
      "Bluefields",
      "Boaco",
      "Camoapa",
      "Chichigalpa",
      "Chinandega",
      "Ciudad Dario",
      "Ciudad Sandino",
      "Condega",
      "Corinto",
      "Diriamba",
      "Diriomo",
      "El Crucero",
      "El Sauce",
      "El Viejo",
      "Esteli",
      "Granada",
      "Jalapa",
      "Jinotega",
      "Jinotepe",
      "Juigalpa",
      "La Paz Centro",
      "Leon",
      "Managua",
      "Masatepe",
      "Masaya",
      "Matagalpa",
      "Mateare",
      "Matiguas",
      "Nagarote",
      "Nandaime",
      "Nueva Guinea",
      "Ocotal",
      "Puerto Cabezas",
      "Quilali",
      "Rama",
      "Rio Blanco",
      "Rivas",
      "San Carlos",
      "San Marcos",
      "San Rafael del Sur",
      "Santo Tomas",
      "Siuna",
      "Somotillo",
      "Somoto",
      "Ticuantepe",
      "Tipitapa",
    ],
  },
  Niger: {
    code: "NE",
    cities: [
      "Abala",
      "Abalak",
      "Agadez",
      "Aguie",
      "Alaghsas",
      "Arlit",
      "Ayorou",
      "Bagaroua",
      "Baleyara",
      "Birni N Konni",
      "Birnin Gaoure",
      "Bouza",
      "Dakoro",
      "Diffa",
      "Dogondoutchi",
      "Dosso",
      "Dungas",
      "Filingue",
      "Gaya",
      "Gazaoua",
      "Gotheye",
      "Goure",
      "Guidan Roumdji",
      "I-n-Gall",
      "Illela",
      "Keita",
      "Kollo",
      "Madaoua",
      "Madarounfa",
      "Magaria",
      "Maine Soroa",
      "Malbaza",
      "Maradi",
      "Matamey",
      "Mayahi",
      "Mirriah",
      "Nguigmi",
      "Niamey",
      "Ouallam",
      "Say",
      "Tahoua",
      "Takieta",
      "Tanout",
      "Tchin Tabaraden",
      "Tchirozerine",
      "Tera",
      "Tessaoua",
      "Tibiri",
      "Tillaberi",
      "Torodi",
      "Zinder",
    ],
  },
  Nigeria: {
    code: "NG",
    cities: [
      "Aba",
      "Abak",
      "Abakaliki",
      "Abeokuta",
      "Abuja",
      "Ado-Ekiti",
      "Ado-Odo",
      "Afikpo",
      "Agaie",
      "Agbor",
      "Agenebode",
      "Ago-Are",
      "Agulu",
      "Ahoada",
      "Ajaokuta",
      "Ajasse Ipo",
      "Aku",
      "Akure",
      "Akwanga",
      "Aliade",
      "Amaigbo",
      "Amper",
      "Anchau",
      "Anka",
      "Ankpa",
      "Apomu",
      "Aramoko-Ekiti",
      "Argungu",
      "Arochukwu",
      "Asaba",
      "Atani",
      "Auchi",
      "Awgu",
      "Awka",
      "Azare",
      "Babana",
      "Badagry",
      "Bakori",
      "Bama",
      "Bara",
      "Baro",
      "Bauchi",
      "Bebeji",
      "Beli",
      "Bende",
      "Benin City",
      "Bida",
      "Biliri",
      "Bin Yauri",
      "Birnin Kebbi",
      "Birnin Kudu",
      "Biu",
      "Bode Saadu",
      "Boju",
      "Bokkos",
      "Bonny",
      "Bori",
      "Buguma",
      "Bukuru",
      "Burumburum",
      "Burutu",
      "Calabar",
      "Chakwama",
      "Dakingari",
      "Damasak",
      "Damaturu",
      "Damboa",
      "Dange",
      "Dapchi",
      "Darazo",
      "Daura",
      "Daura",
      "Deba",
      "Degema Hulk",
      "Dekina",
      "Dikwa",
      "Dindima",
      "Doma",
      "Dukku",
      "Dutse",
      "Dutsen Wai",
      "Dutsin-Ma",
      "Ebute Ikorodu",
      "Effium",
      "Efon-Alaaye",
      "Egbe",
      "Eha Amufu",
      "Ejigbo",
      "Eket",
      "Ekpoma",
      "Elele",
      "Emure-Ekiti",
      "Enugu",
      "Enugu-Ezike",
      "Enugu-Ukwu",
      "Epe",
      "Esuk Oron",
      "Etinan",
      "Ezza-Ohu",
      "Fiditi",
      "Funtua",
      "Gabarin",
      "Gagarawa",
      "Gamawa",
      "Gamboru",
      "Gandi",
      "Ganye",
      "Garko",
      "Gashua",
      "Gassol",
      "Gaya",
      "Gboko",
      "Gbongan",
      "Geidam",
      "Gembu",
      "Gombe",
      "Gombi",
      "Goniri",
      "Gorgoram",
      "Goronyo",
      "Gujba",
      "Gumel",
      "Gummi",
      "Gusau",
      "Gwadabawa",
      "Gwandu",
      "Gwaram",
      "Gwarzo",
      "Gwoza",
      "Hadejia",
      "Ibadan",
      "Ibeto",
      "Ibi",
      "Icheu",
      "Idah",
      "Idanre",
      "Ifo",
      "Igarra",
      "Igbara-Odo",
      "Igbeti",
      "Igbo-Ora",
      "Igbo-Ukwu",
      "Igboho",
      "Igbor",
      "Igede-Ekiti",
      "Ihiala",
      "Ijebu Ode",
      "Ijebu-Igbo",
      "Ijebu-Jesa",
      "Ijero-Ekiti",
      "Ikang",
      "Ikare",
      "Ikeja",
      "Ikere-Ekiti",
      "Ikire",
      "Ikirun",
      "Ikom",
      "Ikot Ekpene",
      "Ila Orangun",
      "Ilaro",
      "Ile-Ife",
      "Ilesa",
      "Illela",
      "Ilobu",
      "Ilorin",
      "Inisa",
      "Iperu",
      "Ipoti",
      "Isanlu-Itedoijowa",
      "Ise-Ekiti",
      "Isieke",
      "Itu",
      "Iwo",
      "Jalingo",
      "Jebba",
      "Jega",
      "Jibia",
      "Jimeta",
      "Jos",
      "Kabba",
      "Kachia",
      "Kaduna",
      "Kafanchan",
      "Kafarati",
      "Kafin Hausa",
      "Kagoro",
      "Kaiama",
      "Kajuru",
      "Kaltungo",
      "Kamba",
      "Kangiwa",
      "Kano",
      "Kari",
      "Katagum",
      "Katsina",
      "Katsina-Ala",
      "Kaura Namoda",
      "Kazaure",
      "Keffi",
      "Kisi",
      "Kiyawa",
      "Konduga",
      "Kontagora",
      "Koton-Karfe",
      "Kujama",
      "Kuje",
      "Kukawa",
      "Kumagunnam",
      "Kumo",
      "Kwale",
      "Lafia",
      "Lafiagi",
      "Lagos",
      "Lalupon",
      "Langtang",
      "Lapai",
      "Lau",
      "Lekki",
      "Lere",
      "Lere",
      "Lokoja",
      "Machina",
      "Madagali",
      "Madala",
      "Madara",
      "Magumeri",
      "Maiduguri",
      "Maiyama",
      "Makoko",
      "Makurdi",
      "Mallammaduri",
      "Malumfashi",
      "Marte",
      "Maru",
      "Minna",
      "Miringa",
      "Misau",
      "Modakeke",
      "Mokwa",
      "Monguno",
      "Moriki",
      "Mubi",
      "Mutum Biyu",
      "Nafada",
      "Nasarawa",
      "Ngala",
      "Nguru",
      "Nkpor",
      "Nkwerre",
      "Nnewi",
      "Nsukka",
      "Numan",
      "Obiaruku",
      "Obonoma",
      "Obubra",
      "Obudu",
      "Ode",
      "Offa",
      "Ogaminana",
      "Ogbomoso",
      "Ogoja",
      "Oguta",
      "Ogwashi-Uku",
      "Ohafia-Ifigh",
      "Oke Ila",
      "Oke Mesi",
      "Okeho",
      "Okene",
      "Okigwe",
      "Okrika",
      "Okuta",
      "Olupona",
      "Omoku",
      "Omu-Aran",
      "Ondo",
      "Onitsha",
      "Opi",
      "Ore",
      "Orita-Eruwa",
      "Osogbo",
      "Otan Ayegbaju",
      "Otukpa",
      "Owerri",
      "Owo",
      "Oyan",
      "Oyo",
      "Ozubulu",
      "Pankshin",
      "Panyam",
      "Patigi",
      "Pindiga",
      "Port Harcourt",
      "Potiskum",
      "Rano",
      "Rijau",
      "Ringim",
      "Runka",
      "Saki",
      "Sapele",
      "Sauri",
      "Shagamu",
      "Shani",
      "Soba",
      "Sofo-Birnin-Gwari",
      "Sokoto",
      "Suleja",
      "Takai",
      "Takum",
      "Talata Mafara",
      "Tambuwal",
      "Tegina",
      "Toungo",
      "Twon-Brass",
      "Uba",
      "Ubiaja",
      "Udi",
      "Uga",
      "Ugbokpo",
      "Ugep",
      "Ughelli",
      "Umuahia",
      "Uromi",
      "Uruobo-Okija",
      "Uyo",
      "Vom",
      "Wamba",
      "Warri",
      "Wawa",
      "Wudil",
      "Wukari",
      "Wurno",
      "Yashikira",
      "Yelwa",
      "Yenagoa",
      "Yola",
      "Zaki Biam",
      "Zalanga",
      "Zango",
      "Zaria",
      "Zungeru",
      "Zuru",
    ],
  },
  "Northern Mariana Islands": {
    code: "MP",
    cities: ["Saipan", "San Jose Village"],
  },
  Norway: {
    code: "NO",
    cities: [
      "Alesund",
      "Alta",
      "Arendal",
      "Asker",
      "Askim",
      "Bergen",
      "Bodo",
      "Brumunddal",
      "Bryne",
      "Drammen",
      "Drobak",
      "Egersund",
      "Elverum",
      "Forde",
      "Fredrikstad",
      "Gjovik",
      "Grimstad",
      "Halden",
      "Hamar",
      "Hammerfest",
      "Harstad",
      "Haugesund",
      "Honefoss",
      "Horten",
      "Jessheim",
      "Kongsberg",
      "Kongsvinger",
      "Kopervik",
      "Kristiansand",
      "Kristiansund",
      "Larvik",
      "Leirvik",
      "Levanger",
      "Lillehammer",
      "Lillestrom",
      "Mandal",
      "Mo i Rana",
      "Molde",
      "Moss",
      "Narvik",
      "Nesoddtangen",
      "Nesttun",
      "Notodden",
      "Oslo",
      "Porsgrunn",
      "Sandefjord",
      "Sandnes",
      "Sarpsborg",
      "Ski",
      "Skien",
      "Sortland",
      "Stavanger",
      "Steinkjer",
      "Stjordal",
      "Stjordalshalsen",
      "Strommen",
      "Tonsberg",
      "Tromso",
      "Trondheim",
      "Vennesla",
      "Volda",
      "Ytrebygda",
    ],
  },
  Oman: {
    code: "OM",
    cities: [
      "'Ibri",
      "Adam",
      "Al Buraymi",
      "Al Khaburah",
      "Al Qabil",
      "As Suwayq",
      "Badiyah",
      "Bahla'",
      "Barka'",
      "Bawshar",
      "Bayt al `Awabi",
      "Bidbid",
      "Ibra'",
      "Izki",
      "Khasab",
      "Liwa",
      "Muscat",
      "Nizwa",
      "Oman Smart Future City",
      "Rustaq",
      "Saham",
      "Salalah",
      "Seeb",
      "Shinas",
      "Sohar",
      "Sufalat Sama'il",
      "Sur",
      "Yanqul",
    ],
  },
  Pakistan: {
    code: "PK",
    cities: [
      "Abbottabad",
      "Ahmadpur East",
      "Ahmadpur Sial",
      "Akora",
      "Alipur",
      "Aman Garh",
      "Arifwala",
      "Attock City",
      "Baddomalhi",
      "Badin",
      "Baffa",
      "Bahawalnagar",
      "Bahawalnagar",
      "Bahawalpur",
      "Bannu",
      "Basirpur",
      "Bat Khela",
      "Battagram",
      "Bela",
      "Bhag",
      "Bhagwal",
      "Bhakkar",
      "Bhalwal",
      "Bhan",
      "Bhawana",
      "Bhera",
      "Bhimber",
      "Bhiria",
      "Bhit Shah",
      "Bhopalwala",
      "Burewala",
      "Chak",
      "Chak Azam Sahu",
      "Chak Five Hundred Seventy-five",
      "Chak Jhumra",
      "Chak One Hundred Thirty-eight Nine Left",
      "Chak One Hundred Twenty Nine Left",
      "Chak Thirty-one -Eleven Left",
      "Chakwal",
      "Chaman",
      "Chamber",
      "Charsadda",
      "Chawinda",
      "Chenab Nagar",
      "Chhor",
      "Chichawatni",
      "Chiniot",
      "Chishtian",
      "Choa Saidan Shah",
      "Chuhar Jamali",
      "Chuhar Kana",
      "Chunian",
      "Dadhar",
      "Dadu",
      "Daira Din Panah",
      "Dajal",
      "Dalbandin",
      "Daromehar",
      "Darya Khan",
      "Daska Kalan",
      "Daud Khel",
      "Daulatpur",
      "Daultala",
      "Daur",
      "Dera Bugti",
      "Dera Ghazi Khan",
      "Dera Ismail Khan",
      "Dhanot",
      "Dhaunkal",
      "Dhoro Naro",
      "Digri",
      "Dijkot",
      "Dinga",
      "Dipalpur",
      "Diplo",
      "Dokri",
      "Dullewala",
      "Dunga Bunga",
      "Dunyapur",
      "Eminabad",
      "Faisalabad",
      "Faqirwali",
      "Faruka",
      "Fatehpur",
      "Fazilpur",
      "Fort Abbas",
      "Gadani",
      "Gambat",
      "Garh Maharaja",
      "Garhi Khairo",
      "Garhiyasin",
      "Gharo",
      "Ghauspur",
      "Ghotki",
      "Gilgit",
      "Gojra",
      "Goth Radhan",
      "Gujar Khan",
      "Gujranwala",
      "Gujrat",
      "Gwadar",
      "Hadali",
      "Hafizabad",
      "Hala",
      "Hangu",
      "Harappa",
      "Haripur",
      "Harnai",
      "Harnoli",
      "Harunabad",
      "Hasilpur",
      "Haveli Lakha",
      "Havelian",
      "Hazro City",
      "Hingorja",
      "Hujra Shah Muqim",
      "Hyderabad",
      "Islamabad",
      "Islamkot",
      "Jacobabad",
      "Jahanian Shah",
      "Jalalpur Jattan",
      "Jalalpur Pirwala",
      "Jampur",
      "Jand",
      "Jandiala Sher Khan",
      "Jaranwala",
      "Jatoi Shimali",
      "Jauharabad",
      "Jhang Sadr",
      "Jhawarian",
      "Jhelum",
      "Jhol",
      "Jiwani",
      "Johi",
      "Kabirwala",
      "Kahna Nau",
      "Kahror Pakka",
      "Kahuta",
      "Kalabagh",
      "Kalaswala",
      "Kalat",
      "Kaleke Mandi",
      "Kallar Kahar",
      "Kalur Kot",
      "Kamalia",
      "Kamar Mushani",
      "Kambar",
      "Kamoke",
      "Kamra",
      "Kandhkot",
      "Kandiaro",
      "Kanganpur",
      "Karachi",
      "Karak",
      "Karor",
      "Kashmor",
      "Kasur",
      "Khairpur",
      "Khairpur Mir's",
      "Khairpur Nathan Shah",
      "Khairpur Tamewah",
      "Khalabat",
      "Khangah Dogran",
      "Khangarh",
      "Khanpur",
      "Khanpur Mahar",
      "Kharan",
      "Kharian",
      "Khewra",
      "Khurrianwala",
      "Khushab",
      "Khuzdar",
      "Kohat",
      "Kohlu",
      "Kot Addu",
      "Kot Diji",
      "Kot Ghulam Muhammad",
      "Kot Malik Barkhurdar",
      "Kot Mumin",
      "Kot Radha Kishan",
      "Kot Samaba",
      "Kotli",
      "Kotli Loharan",
      "Kotri",
      "Kulachi",
      "Kundian",
      "Kunjah",
      "Kunri",
      "Lachi",
      "Ladhewala Waraich",
      "Lahore",
      "Lakhi",
      "Lakki",
      "Lala Musa",
      "Lalian",
      "Larkana",
      "Layyah",
      "Liliani",
      "Lodhran",
      "Loralai",
      "Mach",
      "Madeji",
      "Mailsi",
      "Malakwal",
      "Malakwal City",
      "Malir Cantonment",
      "Mamu Kanjan",
      "Mananwala",
      "Mandi Bahauddin",
      "Mangla",
      "Mankera",
      "Mansehra",
      "Mardan",
      "Mastung",
      "Matiari",
      "Matli",
      "Mehar",
      "Mehrabpur",
      "Mian Channun",
      "Mian Sahib",
      "Mianke Mor",
      "Mianwali",
      "Minchianabad",
      "Mingora",
      "Miro Khan",
      "Mirpur Khas",
      "Mirpur Mathelo",
      "Mirwah Gorchani",
      "Mitha Tiwana",
      "Mithi",
      "Moro",
      "Muhammad Pur",
      "Multan",
      "Muridke",
      "Murree",
      "Mustafabad",
      "Muzaffarabad",
      "Muzaffargarh",
      "Nankana Sahib",
      "Narang Mandi",
      "Narowal",
      "Nasirabad",
      "Naudero",
      "Naukot",
      "Naushahra Virkan",
      "Naushahro Firoz",
      "Nawabshah",
      "New Badah",
      "New Mirpur",
      "Nowshera Cantonment",
      "Nushki",
      "Okara",
      "Ormara",
      "Pabbi",
      "Pad Idan",
      "Paharpur",
      "Pakpattan",
      "Pano Aqil",
      "Pasni",
      "Pasrur",
      "Pattoki",
      "Peshawar",
      "Phalia",
      "Pind Dadan Khan",
      "Pindi Bhattian",
      "Pindi Gheb",
      "Pir Jo Goth",
      "Pir Mahal",
      "Pishin",
      "Qadirpur Ran",
      "Quetta",
      "Rahim Yar Khan",
      "Raiwind",
      "Raja Jang",
      "Rajanpur",
      "Ranipur",
      "Rasulnagar",
      "Ratodero",
      "Rawalakot",
      "Rawalpindi",
      "Renala Khurd",
      "Risalpur Cantonment",
      "Rohri",
      "Rojhan",
      "Saddiqabad",
      "Sahiwal",
      "Sahiwal",
      "Sakrand",
      "Sambrial",
      "Sanghar",
      "Sangla Hill",
      "Sanjwal",
      "Sarai Alamgir",
      "Sarai Naurang",
      "Sarai Sidhu",
      "Sargodha",
      "Sehwan",
      "Setharja Old",
      "Shabqadar",
      "Shahdad Kot",
      "Shahdadpur",
      "Shahkot",
      "Shahpur",
      "Shahpur Chakar",
      "Shahr Sultan",
      "Shakargarh",
      "Sharqpur Sharif",
      "Shekhupura",
      "Shikarpur",
      "Shorkot",
      "Shujaabad",
      "Sialkot",
      "Sibi",
      "Sillanwali",
      "Sinjhoro",
      "Sita Road",
      "Sobhodero",
      "Sodhri",
      "Sook Kalan",
      "Sukheke Mandi",
      "Sukkur",
      "Surab",
      "Surkhpur",
      "Swabi",
      "Talagang",
      "Talamba",
      "Talhar",
      "Tandlianwala",
      "Tando Adam",
      "Tando Allahyar",
      "Tando Bago",
      "Tando Jam",
      "Tando Muhammad Khan",
      "Tangi",
      "Tank",
      "Taunsa",
      "Thal",
      "Tharu Shah",
      "Thatta",
      "Thul",
      "Toba Tek Singh",
      "Topi",
      "Tordher",
      "Turbat",
      "Ubauro",
      "Umarkot",
      "Upper Dir",
      "Usta Muhammad",
      "Uthal",
      "Utmanzai",
      "Vihari",
      "Warah",
      "Wazirabad",
      "Yazman",
      "Zafarwal",
      "Zahir Pir",
      "Zaida",
      "Zhob",
    ],
  },
  Panama: {
    code: "PA",
    cities: [
      "Alcalde Diaz",
      "Ancon",
      "Arraijan",
      "Cativa",
      "Changuinola",
      "Chepo",
      "Chilibre",
      "Colon",
      "Curundu",
      "David",
      "El Chorrillo",
      "El Coco",
      "El Empalme",
      "Juan Diaz",
      "La Cabima",
      "La Chorrera",
      "La Concepcion",
      "Las Cumbres",
      "Las Lomas",
      "Monagrillo",
      "Nuevo Arraijan",
      "Nuevo Belen",
      "Panama",
      "Parque Lefevre",
      "Pedregal",
      "Pedregal",
      "Penonome",
      "Pocri",
      "Puerto Armuelles",
      "Puerto Escondido",
      "Rio Abajo",
      "Sabanitas",
      "San Juan Bautista",
      "San Miguelito",
      "San Vicente",
      "Santiago de Veraguas",
      "Tocumen",
      "Veracruz",
      "Villa Unida",
      "Volcan",
    ],
  },
  "Papua New Guinea": {
    code: "PG",
    cities: [
      "Alotau",
      "Arawa",
      "Bulolo",
      "Daru",
      "Goroka",
      "Kavieng",
      "Kimbe",
      "Kiunga",
      "Kokopo",
      "Lae",
      "Madang",
      "Mendi",
      "Mount Hagen",
      "Popondetta",
      "Port Moresby",
      "Vanimo",
      "Wau",
      "Wewak",
    ],
  },
  Paraguay: {
    code: "PY",
    cities: [
      "Aregua",
      "Asuncion",
      "Ayolas",
      "Caacupe",
      "Caaguazu",
      "Caazapa",
      "Capiata",
      "Ciudad del Este",
      "Colonia General Alfredo Stroessner",
      "Concepcion",
      "Coronel Oviedo",
      "Encarnacion",
      "Fernando de la Mora",
      "Filadelfia",
      "Horqueta",
      "Ita",
      "Itaugua",
      "Lambare",
      "Lima",
      "Limpio",
      "Mariano Roque Alonso",
      "Nemby",
      "Nueva Esperanza",
      "Paraguari",
      "Pedro Juan Caballero",
      "Pilar",
      "Piribebuy",
      "Presidente Franco",
      "San Antonio",
      "San Isidro de Curuguaty",
      "San Juan Bautista",
      "San Lorenzo",
      "Santa Rita",
      "Villa Elisa",
      "Villa Hayes",
      "Villarrica",
    ],
  },
  Peru: {
    code: "PE",
    cities: [
      "Abancay",
      "Andahuaylas",
      "Arequipa",
      "Ascope",
      "Atuncolla",
      "Ayacucho",
      "Ayaviri",
      "Azangaro",
      "Bagua Grande",
      "Bambamarca",
      "Barranca",
      "Belen",
      "Bellavista",
      "Brena",
      "Cajabamba",
      "Cajamarca",
      "Callao",
      "Camana",
      "Caraz",
      "Carmen De La Legua Reynoso",
      "Catacaos",
      "Celendin",
      "Centenario",
      "Cerro de Pasco",
      "Chachapoyas",
      "Chancay",
      "Chaupimarca",
      "Chepen",
      "Chicama",
      "Chiclayo",
      "Chilca",
      "Chilca",
      "Chimbote",
      "Chincha Alta",
      "Chocope",
      "Chongoyape",
      "Chosica",
      "Chota",
      "Chulucanas",
      "Ciudad Satelite",
      "Coishco",
      "Concepcion",
      "Cono Norte",
      "Cono Norte",
      "Contamana",
      "Cusco",
      "El Pedregal",
      "Eten",
      "Ferrenafe",
      "Guadalupe",
      "Huacho",
      "Hualmay",
      "Huamachuco",
      "Huancavelica",
      "Huancayo",
      "Huanta",
      "Huanuco",
      "Huaral",
      "Huaraz",
      "Huarmey",
      "Huaura",
      "Ica",
      "Ilave",
      "Ilo",
      "Imperial",
      "Iquitos",
      "Jacobo Hunter",
      "Jaen",
      "Jauja",
      "Jesus Maria",
      "Juanjui",
      "Juliaca",
      "Junin",
      "La Banda",
      "La Breita",
      "La Oroya",
      "La Peca",
      "La Pradera",
      "La Rinconada",
      "La Rinconada Ana Maria (La Rinconada)",
      "La Union",
      "Lamas",
      "Lambayeque",
      "Laredo",
      "Las Nazarenas",
      "Lima",
      "Mala",
      "Marcavelica",
      "Minas de Marcona",
      "Moche",
      "Mollendo",
      "Monsefu",
      "Moquegua",
      "Morropon",
      "Motupe",
      "Moyobamba",
      "Nauta",
      "Nazca",
      "Nuevo Imperial",
      "Otuzco",
      "Oxapampa",
      "Pacasmayo",
      "Paijan",
      "Paita",
      "Paramonga",
      "Patapo",
      "Pativilca",
      "Picsi",
      "Pilcomayo",
      "Pimentel",
      "Pisco",
      "Piura",
      "Pucallpa",
      "Pueblo Nuevo",
      "Puerto Maldonado",
      "Puerto Santa",
      "Puno",
      "Puquio",
      "Querecotillo",
      "Rioja",
      "Salaverry",
      "Salinera Colan",
      "San Antonio",
      "San Clemente",
      "San Fernando",
      "San Francisco De Borja",
      "San Isidro",
      "San Juan",
      "San Juan",
      "San Martin",
      "San Pedro de Lloc",
      "San Ramon",
      "San Vicente de Canete",
      "Sana",
      "Santa Ana",
      "Santa Anita - Los Ficus",
      "Santa Maria",
      "Santa Rosa",
      "Santa Rosa",
      "Santa Rosa de Sacco",
      "Santiago",
      "Santiago",
      "Santiago de Cao",
      "Santiago de Surco",
      "Saposoa",
      "Satipo",
      "Sechura",
      "Selva Alegre",
      "Sicuani",
      "Soritor",
      "Sullana",
      "Supe",
      "Supe Puerto",
      "Tabalosos",
      "Tacna",
      "Talara",
      "Tambo Grande",
      "Tambopata",
      "Tarma",
      "Tingo Maria",
      "Tocache",
      "Trujillo",
      "Tumbes",
      "Tupac Amaru",
      "Uchiza",
      "Vice",
      "Viru",
      "Wanchaq",
      "Yanacancha",
      "Yanacancha",
      "Yauri",
      "Yunguyo",
      "Yurimaguas",
      "Zarumilla",
      "Zorritos",
    ],
  },
  Philippines: {
    code: "PH",
    cities: [
      "Abucay",
      "Abuyog",
      "Agoo",
      "Aguisan",
      "Agusan",
      "Alabel",
      "Alaminos",
      "Alaminos",
      "Aliaga",
      "Alicia",
      "Amadeo",
      "Amungan",
      "Angat",
      "Angeles City",
      "Angono",
      "Antipolo",
      "Anuling",
      "Apalit",
      "Aparri",
      "Apas",
      "Arayat",
      "Aringay",
      "Asia",
      "Astorga",
      "Atimonan",
      "Aurora",
      "Ayala Alabang",
      "Baao",
      "Babo-Pangulo",
      "Bacacay",
      "Baclaran",
      "Bacolod City",
      "Bacoor",
      "Baculongan",
      "Baganga",
      "Baggabag B",
      "Bago City",
      "Bagong Pagasa",
      "Bagong Silangan",
      "Baguio",
      "Bah-Bah",
      "Bais",
      "Balagtas",
      "Balamban",
      "Balanga",
      "Balayan",
      "Baliuag",
      "Baloc",
      "Bambang",
      "Banco Filipino Homes",
      "Banga",
      "Bangar",
      "Bansalan",
      "Bansud",
      "Bantay",
      "Bantayan",
      "Baras",
      "Barotac Nuevo",
      "Barra",
      "Batac City",
      "Batangas",
      "Bato",
      "Bauan",
      "Bauang",
      "Bay",
      "Bayambang",
      "Bayawan",
      "Baybay",
      "Bayombong",
      "Bayugan",
      "Bel Air",
      "Biao",
      "Bigaa",
      "Bignay Uno",
      "Bilog-Bilog",
      "Binalbagan",
      "Binan",
      "Binangonan",
      "Binmaley",
      "Binondo",
      "Binonga",
      "Bislig",
      "Bocaue",
      "Bogo",
      "Bongabon",
      "Bongao",
      "Bontoc",
      "Borongan",
      "Boroon",
      "Botolan",
      "Buanoy",
      "Buayan",
      "Buco",
      "Budta",
      "Buenavista",
      "Bugallon",
      "Bugo",
      "Buhi",
      "Bula",
      "Bulacan",
      "Bulalacao",
      "Bulan",
      "Bulaon",
      "Bulihan",
      "Bulog",
      "Buluan",
      "Buluangan",
      "Bunawan",
      "Burauen",
      "Burgos",
      "Bustos",
      "Butuan",
      "Cabadbaran",
      "Cabagan",
      "Cabanatuan City",
      "Cabayangan",
      "Cabiao",
      "Cabinuangan",
      "Caburan",
      "Cabuyao",
      "Cadiz",
      "Cagayan de Oro",
      "Cagsiay",
      "Cainta",
      "Calabanga",
      "Calaca",
      "Calamba",
      "Calapan",
      "Calasiao",
      "Calatagan",
      "Calauag",
      "Calauan",
      "Calbayog City",
      "Calintaan",
      "Caloocan City",
      "Calumpang",
      "Calumpit",
      "Cambanugoy",
      "Camiling",
      "Caminauit",
      "Candaba",
      "Candelaria",
      "Candon",
      "Canlaon",
      "Capas",
      "Carcar",
      "Cardona",
      "Carigara",
      "Carmen",
      "Carmona",
      "Carranglan",
      "Casisang",
      "Castillejos",
      "Catanauan",
      "Catarman",
      "Catbalogan",
      "Cavite City",
      "Cebu City",
      "City of Isabela",
      "Claveria",
      "Codcod",
      "Cogan",
      "Compostela",
      "Compostela",
      "Concepcion",
      "Concepcion Ibaba",
      "Consolacion",
      "Cordova",
      "Cotabato",
      "Cuenca",
      "Daanbantayan",
      "Daet",
      "Dagupan",
      "Dalican",
      "Dalipuga",
      "Damilag",
      "Danao",
      "Danao",
      "Dao",
      "Dapitan",
      "Dasmarinas",
      "Davao",
      "Del Monte",
      "Del Pilar",
      "Diadi",
      "Digos",
      "Dinalupihan",
      "Dipolog",
      "Dologon",
      "Domalanoan",
      "Don Carlos",
      "Dulag",
      "Dumaguete",
      "Escalante",
      "Estancia",
      "Eustaquio Lopez",
      "Gapan",
      "General Emilio Aguinaldo",
      "General Mamerto Natividad",
      "General Santos",
      "General Tinio",
      "General Trias",
      "Gerona",
      "Glan",
      "Goa",
      "Guadalupe",
      "Guadalupe",
      "Gubat",
      "Guiguinto",
      "Guihing Proper",
      "Guihulngan",
      "Guiljungan",
      "Guimba",
      "Guiset East",
      "Guiuan",
      "Gumaca",
      "Guyong",
      "Hagonoy",
      "Halapitan",
      "Halayhay",
      "Hermosa",
      "Himamaylan",
      "Hinatuan",
      "Hinigaran",
      "Iba",
      "Ilagan",
      "Iligan",
      "Iligan City",
      "Iloilo",
      "Imus",
      "Indang",
      "Ipil",
      "Iriga City",
      "Irosin",
      "Isabela",
      "Isulan",
      "Itogon",
      "Jaen",
      "Jagna",
      "Jalajala",
      "Janiuay",
      "Jasaan",
      "Jolo",
      "Jose Panganiban",
      "Kabacan",
      "Kabankalan",
      "Kalamansig",
      "Kalibo (poblacion)",
      "Kalilangan",
      "Kansipati",
      "Katipunan",
      "Kawit",
      "Kiamba",
      "Kidapawan",
      "Koronadal",
      "La Carlota",
      "La Castellana",
      "La Paz",
      "La Trinidad",
      "Labangan",
      "Labo",
      "Labuan",
      "Laguilayan",
      "Lake Sebu",
      "Lalig",
      "Lambayong",
      "Laoag",
      "Laoang",
      "Lapu-Lapu City",
      "Las Pinas",
      "Laur",
      "Legaspi",
      "Lianga",
      "Libertad",
      "Libog",
      "Libon",
      "Ligao",
      "Lilio",
      "Liloan",
      "Limay",
      "Lingayen",
      "Lipa City",
      "Loboc",
      "Loma de Gato",
      "Lopez",
      "Los Banos",
      "Lourdes",
      "Lubao",
      "Lucban",
      "Lucena",
      "Lumbang",
      "Lun Pequeno",
      "Luna",
      "Lupao",
      "Lupon",
      "Lusacan",
      "Lutayan",
      "Maao",
      "Maasin",
      "Mabalacat City",
      "Mabitac",
      "Maco",
      "Magalang",
      "Maganoy",
      "Magapit",
      "Magapit, Aguiguican",
      "Magarao",
      "Magsaysay",
      "Magugpo Poblacion",
      "Mahayag",
      "Majayjay",
      "Makati City",
      "Makilala",
      "Malabanban Norte",
      "Malandag",
      "Malanday",
      "Malanday",
      "Malapatan",
      "Malaya",
      "Malaybalay",
      "Malilipot",
      "Malingao",
      "Malita",
      "Malolos",
      "Malungun",
      "Maluso",
      "Malvar",
      "Mamatid",
      "Mambajao",
      "Mamburao",
      "Manaoag",
      "Manapla",
      "Manay",
      "Mandaluyong City",
      "Mandaue City",
      "Mangaldan",
      "Mangatarem",
      "Manggahan",
      "Manibaug Pasig",
      "Manila",
      "Mankayan",
      "Manolo Fortich",
      "Mansalay",
      "Mansilingan",
      "Mantampay",
      "Manukan",
      "Mapandan",
      "Maragondon",
      "Maramag",
      "Maranding",
      "Marawi City",
      "Margosatubig",
      "Mariana",
      "Mariano",
      "Marilao",
      "Mariveles",
      "Masalukot Uno",
      "Masantol",
      "Masbate",
      "Masinloc",
      "Mati",
      "Mauban",
      "Medellin",
      "Mendez-Nunez",
      "Mercedes",
      "Mexico",
      "Meycauayan",
      "Midsayap",
      "Minalin",
      "Minglanilla",
      "Molave",
      "Monkayo",
      "Montevista",
      "Morong",
      "Morong",
      "Munoz",
      "Murcia",
      "Muricay",
      "NIA Valencia",
      "Nabua",
      "Nabunturan",
      "Naga",
      "Naga",
      "Nagcarlan",
      "Naic",
      "Narra",
      "Nasugbu",
      "Nattapian",
      "Naval",
      "Navotas",
      "New Corella",
      "New Visayas",
      "Nibaliw Central",
      "Norala",
      "Norzagaray",
      "Noveleta",
      "Obando",
      "Ocana",
      "Olongapo",
      "Orani",
      "Orion",
      "Ormoc",
      "Oroquieta",
      "Oton",
      "Ozamiz City",
      "Paco",
      "Pacol",
      "Padada",
      "Paete",
      "Pagadian",
      "Pagalungan",
      "Pagbilao",
      "Pagsangahan",
      "Pakil",
      "Palayan City",
      "Palo",
      "Palompon",
      "Pambujan",
      "Pamplona",
      "Panabo",
      "Panacan",
      "Panalanoy",
      "Pandacaqui",
      "Pandan",
      "Pandi",
      "Pangil",
      "Paniqui",
      "Pantubig",
      "Paombong",
      "Papaya",
      "Paraiso",
      "Paranaque City",
      "Parang",
      "Pasacao",
      "Pasay",
      "Pasig City",
      "Pasil",
      "Passi",
      "Patuto",
      "Payocpoc Sur",
      "Penaranda",
      "Perrelos",
      "Pigcawayan",
      "Pikit",
      "Pila",
      "Pilar",
      "Pililla",
      "Pinamalayan",
      "Pinamungahan",
      "Pio",
      "Pio Duran",
      "Placer",
      "Plaridel",
      "Poblacion, San Felipe",
      "Polangui",
      "Polomolok",
      "Porac",
      "Port Area",
      "Pototan",
      "President Roxas",
      "Puerto Princesa",
      "Pulilan",
      "Pulo",
      "Pulong Santa Cruz",
      "Pulupandan",
      "Quezon",
      "Quezon",
      "Quezon",
      "Quezon City",
      "Quiapo",
      "Quisao",
      "Rajal Norte",
      "Ramon",
      "Ramos",
      "Recodo",
      "Rizal",
      "Rodriguez",
      "Romblon",
      "Rosales",
      "Roxas",
      "Roxas",
      "Roxas",
      "Roxas City",
      "Sablayan",
      "Sagay",
      "Samal",
      "Samal",
      "Sampaloc",
      "San Antonio",
      "San Antonio",
      "San Fernando",
      "San Fernando",
      "San Fernando",
      "San Francisco",
      "San Francisco",
      "San Ildefonso",
      "San Jacinto",
      "San Jose",
      "San Jose",
      "San Jose del Monte",
      "San Juan",
      "San Leonardo",
      "San Luis",
      "San Marcelino",
      "San Mariano",
      "San Mariano",
      "San Mateo",
      "San Mateo",
      "San Miguel",
      "San Miguel",
      "San Narciso",
      "San Narciso",
      "San Nicolas",
      "San Pablo",
      "San Pascual",
      "San Pedro",
      "San Simon",
      "San Vicente",
      "Sangali",
      "Santa Ana",
      "Santa Barbara",
      "Santa Catalina",
      "Santa Cruz",
      "Santa Cruz",
      "Santa Cruz",
      "Santa Cruz",
      "Santa Elena",
      "Santa Ignacia",
      "Santa Maria",
      "Santa Maria",
      "Santa Rosa",
      "Santa Rosa",
      "Santiago",
      "Santo Tomas",
      "Santol",
      "Sapang",
      "Saravia",
      "Sariaya",
      "Sexmoan",
      "Siasi",
      "Sibonga",
      "Sibulan",
      "Silang",
      "Sipalay",
      "Sipocot",
      "Siquijor",
      "Sitangkai",
      "Solana",
      "Solano",
      "Sorsogon",
      "South Upi",
      "Suay",
      "Subic",
      "Sumag",
      "Surallah",
      "Surigao",
      "Suyo",
      "Taal",
      "Tabaco",
      "Tabuk",
      "Tacloban",
      "Tacurong",
      "Tagas",
      "Tagaytay",
      "Tagbilaran City",
      "Tagbina",
      "Tagoloan",
      "Tagudin",
      "Taguig",
      "Talacogon",
      "Talavera",
      "Talibon",
      "Talisay",
      "Talisay",
      "Talisay",
      "Taloc",
      "Tanauan",
      "Tanauan",
      "Tanay",
      "Tandag",
      "Tangub",
      "Tanjay",
      "Tantangan",
      "Tanza",
      "Tarlac City",
      "Tayabas",
      "Taytay",
      "Tayug",
      "Telabastagan",
      "Teresa",
      "Ternate",
      "Tibigan",
      "Tigaon",
      "Tigbauan",
      "Tinambac",
      "Tinongan",
      "Titay",
      "Tiwi",
      "Toboso",
      "Toledo",
      "Trapiche",
      "Trento",
      "Tubod",
      "Tucuran",
      "Tuguegarao",
      "Tunasan",
      "Tupi",
      "Tuyum",
      "Ualog",
      "Upper Bicutan",
      "Urbiztondo",
      "Urdaneta",
      "Vallehermoso",
      "Veruela",
      "Victoria",
      "Victoria",
      "Victorias",
      "Vigan",
      "Villasis",
      "Vinzons",
      "Virac",
      "Wao",
      "Zamboanga",
    ],
  },
  Poland: {
    code: "PL",
    cities: [
      "Aleksandrow Kujawski",
      "Aleksandrow Lodzki",
      "Andrychow",
      "Augustow",
      "Barlinek",
      "Bartoszyce",
      "Bedzin",
      "Belchatow",
      "Bemowo",
      "Biala Podlaska",
      "Bialogard",
      "Bialoleka",
      "Bialystok",
      "Bielany",
      "Bielawa",
      "Bielsk Podlaski",
      "Bielsko-Biala",
      "Bierun",
      "Bilgoraj",
      "Biskupiec",
      "Biskupin",
      "Blonie",
      "Bochnia",
      "Bogatynia",
      "Boguszow-Gorce",
      "Boleslawiec",
      "Braniewo",
      "Brodnica",
      "Brwinow",
      "Brzeg",
      "Brzeg Dolny",
      "Brzesko",
      "Brzeszcze",
      "Brzeziny",
      "Bukowno",
      "Busko-Zdroj",
      "Bydgoszcz",
      "Bystrzyca Klodzka",
      "Bytom",
      "Bytow",
      "Chelm",
      "Chelmno",
      "Chelmza",
      "Chodziez",
      "Chojnice",
      "Chojnow",
      "Chorzow",
      "Choszczno",
      "Chrzanow",
      "Ciechanow",
      "Ciechocinek",
      "Cieplice Slaskie Zdroj",
      "Cieszyn",
      "Czarnkow",
      "Czechowice-Dziedzice",
      "Czeladz",
      "Czerwionka-Leszczyny",
      "Czestochowa",
      "Czluchow",
      "Dabrowa Gornicza",
      "Dabrowa Tarnowska",
      "Darlowo",
      "Debica",
      "Deblin",
      "Debno",
      "Dobre Miasto",
      "Drawsko Pomorskie",
      "Drezdenko",
      "Dzialdowo",
      "Dzierzoniow",
      "Elblag",
      "Elk",
      "Fordon",
      "Garwolin",
      "Gdansk",
      "Gdynia",
      "Gierloz",
      "Gizycko",
      "Gliwice",
      "Glogow",
      "Glowno",
      "Glubczyce",
      "Glucholazy",
      "Gniezno",
      "Goldap",
      "Goleniow",
      "Golub-Dobrzyn",
      "Gora",
      "Gora Kalwaria",
      "Gorlice",
      "Gorzow Wielkopolski",
      "Gostyn",
      "Gostynin",
      "Grabiszynek",
      "Grajewo",
      "Grodzisk Mazowiecki",
      "Grodzisk Wielkopolski",
      "Grojec",
      "Grudziadz",
      "Gryfice",
      "Gryfino",
      "Gubin",
      "Hajnowka",
      "Hrubieszow",
      "Ilawa",
      "Inowroclaw",
      "Janow Lubelski",
      "Jarocin",
      "Jaroslaw",
      "Jaslo",
      "Jastrzebie Zdroj",
      "Jawor",
      "Jaworzno",
      "Jedrzejow",
      "Jelcz",
      "Jelcz Laskowice",
      "Jelenia Gora",
      "Jozefow",
      "Kabaty",
      "Kalisz",
      "Kamienna Gora",
      "Karczew",
      "Karlowice",
      "Kartuzy",
      "Katowice",
      "Kedzierzyn-Kozle",
      "Kepno",
      "Ketrzyn",
      "Kety",
      "Kielce",
      "Klobuck",
      "Klodzko",
      "Kluczbork",
      "Knurow",
      "Kobylka",
      "Kolbuszowa",
      "Kolno",
      "Kolo",
      "Kolobrzeg",
      "Koluszki",
      "Konin",
      "Konskie",
      "Konstancin-Jeziorna",
      "Konstantynow Lodzki",
      "Koronowo",
      "Koscian",
      "Koscierzyna",
      "Kostrzyn nad Odra",
      "Koszalin",
      "Kowary",
      "Kozanow",
      "Kozienice",
      "Kozle",
      "Kozy",
      "Krakow",
      "Krapkowice",
      "Krasnik",
      "Krasnystaw",
      "Krosno",
      "Krosno Odrzanskie",
      "Krotoszyn",
      "Krynica-Zdroj",
      "Krzyki",
      "Kudowa-Zdroj",
      "Kutno",
      "Kwidzyn",
      "Lancut",
      "Lapy",
      "Lask",
      "Laziska Gorne",
      "Lebork",
      "Leczna",
      "Leczyca",
      "Ledziny",
      "Legionowo",
      "Legnica",
      "Leszno",
      "Lezajsk",
      "Libiaz",
      "Lidzbark Warminski",
      "Limanowa",
      "Lipno",
      "Lobez",
      "Lodz",
      "Lomianki",
      "Lomza",
      "Lowicz",
      "Lubaczow",
      "Luban",
      "Lubartow",
      "Lubin",
      "Lublin",
      "Lubliniec",
      "Lubon",
      "Lubsko",
      "Lukow",
      "Malbork",
      "Marki",
      "Miastko",
      "Miechow",
      "Miedzychod",
      "Miedzyrzec Podlaski",
      "Miedzyrzecz",
      "Mielec",
      "Mikolow",
      "Milanowek",
      "Milicz",
      "Minsk Mazowiecki",
      "Mlawa",
      "Mogilno",
      "Mokotow",
      "Monki",
      "Morag",
      "Mosina",
      "Mragowo",
      "Myslenice",
      "Mysliborz",
      "Myslowice",
      "Myszkow",
      "Naklo nad Notecia",
      "Namyslow",
      "Nidzica",
      "Niedobczyce",
      "Nisko",
      "Nowa Deba",
      "Nowa Ruda",
      "Nowa Sol",
      "Nowe Miasto Lubawskie",
      "Nowogard",
      "Nowy Dwor Mazowiecki",
      "Nowy Sacz",
      "Nowy Targ",
      "Nowy Tomysl",
      "Nysa",
      "Oborniki",
      "Ochota",
      "Olawa",
      "Olecko",
      "Olesnica",
      "Olesno",
      "Olkusz",
      "Olsztyn",
      "Opoczno",
      "Opole",
      "Orzesze",
      "Osiedle Kosmonautow",
      "Ostroda",
      "Ostroleka",
      "Ostrow Mazowiecka",
      "Ostrow Wielkopolski",
      "Ostrowiec Swietokrzyski",
      "Ostrzeszow",
      "Oswiecim",
      "Otwock",
      "Ozorkow",
      "Pabianice",
      "Parczew",
      "Paslek",
      "Piaseczno",
      "Piastow",
      "Piekary Slaskie",
      "Pila",
      "Pinczow",
      "Pionki",
      "Piotrkow Trybunalski",
      "Pisz",
      "Pleszew",
      "Plock",
      "Plonsk",
      "Police",
      "Polkowice",
      "Poniatowa",
      "Powstancow Slaskich Wschod",
      "Powstancow Slaskich Zachod-Centrum Poludniow",
      "Poznan",
      "Praga Polnoc",
      "Praga Poludnie",
      "Prudnik",
      "Pruszcz Gdanski",
      "Pruszkow",
      "Przasnysz",
      "Przemysl",
      "Przeworsk",
      "Pszczyna",
      "Pszow",
      "Puck",
      "Pulawy",
      "Pultusk",
      "Pyrzyce",
      "Pyskowice",
      "Rabka-Zdroj",
      "Raciborz",
      "Radlin",
      "Radom",
      "Radomsko",
      "Radzionkow",
      "Radzyn Podlaski",
      "Rawa Mazowiecka",
      "Rawicz",
      "Reda",
      "Rejon placu Grunwaldzkiego",
      "Rejon placu Swietego Macieja",
      "Rejon ulicy Traugutta",
      "Rembertow",
      "Rogozno",
      "Ropczyce",
      "Rozanka-Polanka",
      "Ruda Slaska",
      "Rumia",
      "Rybnik",
      "Rydultowy",
      "Rypin",
      "Rzeszow",
      "Sandomierz",
      "Sanok",
      "Siedlce",
      "Siemianowice Slaskie",
      "Siemiatycze",
      "Sieradz",
      "Sierpc",
      "Skarzysko-Kamienna",
      "Skawina",
      "Skierniewice",
      "Skoczow",
      "Skwierzyna",
      "Slawno",
      "Slubice",
      "Slupca",
      "Slupsk",
      "Sochaczew",
      "Sokolka",
      "Sokolow Podlaski",
      "Solec Kujawski",
      "Sopot",
      "Sosnowiec",
      "Srem",
      "Sroda Wielkopolska",
      "Srodmiescie",
      "Stablowice",
      "Stalowa Wola",
      "Starachowice",
      "Stargard",
      "Starogard Gdanski",
      "Staszow",
      "Strzegom",
      "Strzelce Opolskie",
      "Strzelin",
      "Sulechow",
      "Sulecin",
      "Sulejowek",
      "Suwalki",
      "Swarzedz",
      "Swidnica",
      "Swidnik",
      "Swidwin",
      "Swiebodzice",
      "Swiebodzin",
      "Swiecie",
      "Swietochlowice",
      "Swinoujscie",
      "Sycow",
      "Szamotuly",
      "Szczecin",
      "Szczecinek",
      "Szczytno",
      "Szprotawa",
      "Szydlowiec",
      "Targowek",
      "Tarnobrzeg",
      "Tarnow",
      "Tarnowskie Gory",
      "Tczew",
      "Tomaszow Lubelski",
      "Tomaszow Mazowiecki",
      "Torun",
      "Trzcianka",
      "Trzebiatow",
      "Trzebinia",
      "Trzebnica",
      "Tuchola",
      "Turek",
      "Tychy",
      "Ursus",
      "Ursynow",
      "Ustka",
      "Ustron",
      "Wabrzezno",
      "Wadowice",
      "Wagrowiec",
      "Walbrzych",
      "Walcz",
      "Warka",
      "Warsaw",
      "Wawer",
      "Wegorzewo",
      "Wegrow",
      "Wejherowo",
      "Wesola",
      "Wieliczka",
      "Wielki Kack",
      "Wielun",
      "Wilanow",
      "Wisla",
      "Wladyslawowo",
      "Wlochy",
      "Wloclawek",
      "Wlodawa",
      "Wloszczowa",
      "Wodzislaw Slaski",
      "Wola",
      "Wolin",
      "Wolomin",
      "Wolow",
      "Wolsztyn",
      "Wroclaw",
      "Wronki",
      "Wrzesnia",
      "Wschowa",
      "Wyszkow",
      "Zabki",
      "Zabkowice Slaskie",
      "Zabrze",
      "Zagan",
      "Zakopane",
      "Zakrzow",
      "Zambrow",
      "Zamosc",
      "Zary",
      "Zawiercie",
      "Zdunska Wola",
      "Zdzieszowice",
      "Zgierz",
      "Zgorzelec",
      "Zgorzelisko",
      "Zielona Gora",
      "Zielonka",
      "Zlocieniec",
      "Zlotoryja",
      "Zlotow",
      "Znin",
      "Zoliborz",
      "Zory",
      "Zyrardow",
      "Zywiec",
    ],
  },
  Portugal: {
    code: "PT",
    cities: [
      "Abrantes",
      "Aguas Santas",
      "Agueda",
      "Albufeira",
      "Alcabideche",
      "Alcobaca",
      "Aldeia de Paio Pires",
      "Alfena",
      "Alfragide",
      "Alges",
      "Algueirao",
      "Alhos Vedros",
      "Alijo",
      "Almada",
      "Almeirim",
      "Alvalade",
      "Amadora",
      "Amora",
      "Angra do Heroismo",
      "Anta",
      "Arcos de Valdevez",
      "Arcozelo",
      "Arrentela",
      "Aveiro",
      "Aver-o-Mar",
      "Avintes",
      "Azeitao",
      "Azenha",
      "Baguim do Monte",
      "Baiao",
      "Barcarena",
      "Barcelos",
      "Barreiro",
      "Beato Antonio",
      "Beja",
      "Belas",
      "Benfica",
      "Bougado",
      "Braga",
      "Braganca",
      "Cabeceiras de Basto",
      "Cacem",
      "Caldas da Rainha",
      "Caldas de Vizela",
      "Calendario",
      "Camara de Lobos",
      "Camarate",
      "Canecas",
      "Canelas",
      "Canico",
      "Canidelo",
      "Caparica",
      "Carcavelos",
      "Carnaxide",
      "Carregado",
      "Cartaxo",
      "Cascais",
      "Castelo Branco",
      "Charneca",
      "Charneca de Caparica",
      "Chaves",
      "Coimbra",
      "Condeixa-a-Nova",
      "Corroios",
      "Costa de Caparica",
      "Covilha",
      "Cucujaes",
      "Custoias",
      "Entroncamento",
      "Ermesinde",
      "Esgueira",
      "Esmoriz",
      "Espinho",
      "Esposende",
      "Estoril",
      "Evora",
      "Fafe",
      "Fanzeres",
      "Faro",
      "Fatima",
      "Feira",
      "Felgueiras",
      "Figueira da Foz",
      "Foz do Douro",
      "Funchal",
      "Gafanha",
      "Gondomar",
      "Grandola",
      "Grijo",
      "Guarda",
      "Guimaraes",
      "Gulpilhares",
      "Ilhavo",
      "Lagoa",
      "Lagos",
      "Lamego",
      "Laranjeiro",
      "Laranjeiro",
      "Lavradio",
      "Leca da Palmeira",
      "Leca do Bailio",
      "Leiria",
      "Linda-a-Velha",
      "Lisbon",
      "Lordelo",
      "Loule",
      "Loures",
      "Lousa",
      "Machico",
      "Mafra",
      "Maia",
      "Margaride",
      "Marinha Grande",
      "Massama",
      "Matosinhos",
      "Melres",
      "Mirandela",
      "Moimenta da Beira",
      "Moita",
      "Moncao",
      "Monsanto",
      "Monte Estoril",
      "Montemor-o-Novo",
      "Montijo",
      "Moreira",
      "Moreira",
      "Moscavide e Portela",
      "Obidos",
      "Odivelas",
      "Oeiras",
      "Olhao",
      "Olivais",
      "Oliveira de Azemeis",
      "Oliveira do Douro",
      "Ourem",
      "Ovar",
      "Paco de Arcos",
      "Palmela",
      "Parede",
      "Paredes",
      "Pedroso",
      "Pedroucos",
      "Peniche",
      "Perafita",
      "Piedade",
      "Pinhal Novo",
      "Pombal",
      "Ponta Delgada",
      "Ponte de Lima",
      "Ponte de Sor",
      "Pontinha",
      "Portalegre",
      "Portimao",
      "Porto",
      "Porto Salvo",
      "Povoa de Santa Iria",
      "Povoa de Santo Adriao",
      "Povoa de Varzim",
      "Quarteira",
      "Queluz",
      "Quinta Do Conde",
      "Ramada",
      "Real",
      "Rebordosa",
      "Rio Maior",
      "Rio Tinto",
      "Rio de Mouro",
      "Sacavem",
      "Samora Correia",
      "Santa Comba Dao",
      "Santa Iria da Azoia",
      "Santarem",
      "Santo Andre",
      "Santo Andre",
      "Santo Antonio da Charneca",
      "Santo Antonio dos Olivais",
      "Santo Tirso",
      "Sao Bras de Alportel",
      "Sao Domingos de Rana",
      "Sao Felix da Marinha",
      "Sao Joao da Madeira",
      "Sao Joao da Talha",
      "Sao Joao das Lampas",
      "Sao Mamede de Infesta",
      "Sao Martinho",
      "Sao Pedro da Cova",
      "Selho",
      "Semelhe",
      "Senhora da Hora",
      "Sequeira",
      "Sesimbra",
      "Setubal",
      "Silves",
      "Sines",
      "Sintra",
      "Sobreda",
      "Tabua",
      "Tavira",
      "Tomar",
      "Torres Novas",
      "Torres Vedras",
      "Trofa",
      "Unhos",
      "Valbom",
      "Vale da Amoreira",
      "Valenza",
      "Valongo",
      "Vendas Novas",
      "Vialonga",
      "Viana do Castelo",
      "Vila Franca de Xira",
      "Vila Nova de Gaia",
      "Vila Real",
      "Vila Real de Santo Antonio",
      "Vila do Conde",
      "Vilar de Andorinho",
      "Vilar do Paraiso",
      "Viseu",
    ],
  },
  "Puerto Rico": {
    code: "PR",
    cities: [
      "Aguadilla",
      "Arecibo",
      "Barceloneta",
      "Bayamon",
      "Cabo Rojo",
      "Caguas",
      "Candelaria",
      "Carolina",
      "Catano",
      "Cayey",
      "Coamo",
      "Corozal",
      "Dorado",
      "Fajardo",
      "Guayama",
      "Guaynabo",
      "Hormigueros",
      "Humacao",
      "Isabela",
      "Levittown",
      "Manati",
      "Mayagueez",
      "Ponce",
      "Rio Grande",
      "San German",
      "San Juan",
      "San Sebastian",
      "Trujillo Alto",
      "Vega Alta",
      "Vega Baja",
      "Yauco",
    ],
  },
  Qatar: {
    code: "QA",
    cities: [
      "Al Khawr",
      "Al Wakrah",
      "Ar Rayyan",
      "Doha",
      "Umm Salal Muhammad",
    ],
  },
  Romania: {
    code: "RO",
    cities: [
      "Adjud",
      "Agnita",
      "Aiud",
      "Alba Iulia",
      "Alesd",
      "Alexandria",
      "Arad",
      "Avrig",
      "Babadag",
      "Bacau",
      "Baia Mare",
      "Baia Sprie",
      "Baicoi",
      "Bailesti",
      "Bals",
      "Barlad",
      "Beclean",
      "Beius",
      "Belcesti",
      "Bistrita",
      "Blaj",
      "Bocsa",
      "Boldesti-Scaeni",
      "Bolintin Vale",
      "Borsa",
      "Botosani",
      "Brad",
      "Braila",
      "Brasov",
      "Breaza",
      "Breaza de Sus",
      "Bucharest",
      "Bucov",
      "Buftea",
      "Buhusi",
      "Bumbesti-Jiu",
      "Buzau",
      "Calafat",
      "Calan",
      "Calinesti",
      "Campia Turzii",
      "Campina",
      "Campulung Moldovenesc",
      "Caracal",
      "Caransebes",
      "Carei",
      "Cernavoda",
      "Chitila",
      "Cisnadie",
      "Ciurea",
      "Cluj-Napoca",
      "Codlea",
      "Comanesti",
      "Comarnic",
      "Constanta",
      "Corabia",
      "Cormaia",
      "Costesti",
      "Covasna",
      "Craiova",
      "Cugir",
      "Curtea de Arges",
      "Dabuleni",
      "Darabani",
      "Darmanesti",
      "Dej",
      "Deleni",
      "Deva",
      "Dofteana",
      "Dolhasca",
      "Dorohoi",
      "Draganesti-Olt",
      "Dragasani",
      "Drobeta-Turnu Severin",
      "Fagaras",
      "Falticeni",
      "Fetesti",
      "Fetesti-Gara",
      "Filiasi",
      "Filipestii de Padure",
      "Focsani",
      "Gaesti",
      "Galati",
      "Gheorgheni",
      "Gherla",
      "Giurgiu",
      "Gura Humorului",
      "Harlau",
      "Harsova",
      "Hateg",
      "Holboca",
      "Hunedoara",
      "Husi",
      "Ianca",
      "Iasi",
      "Ineu",
      "Jibou",
      "Jilava",
      "Jimbolia",
      "Liesti",
      "Lipova",
      "Ludus",
      "Lugoj",
      "Lupeni",
      "Macin",
      "Mangalia",
      "Marghita",
      "Matca",
      "Medgidia",
      "Medias",
      "Miercurea-Ciuc",
      "Mioveni",
      "Mizil",
      "Moinesti",
      "Moldova Noua",
      "Moreni",
      "Motru",
      "Murfatlar",
      "Nasaud",
      "Navodari",
      "Negresti",
      "Negresti-Oas",
      "Nehoiu",
      "Nicolae Balcescu",
      "Ocna Mures",
      "Odorheiu Secuiesc",
      "Oltenita",
      "Oradea",
      "Orastie",
      "Oravita",
      "Orsova",
      "Otelu Rosu",
      "Otopeni",
      "Ovidiu",
      "Pantelimon",
      "Pascani",
      "Pechea",
      "Pecica",
      "Petrila",
      "Petrosani",
      "Piatra Neamt",
      "Pitesti",
      "Ploiesti",
      "Poiana Mare",
      "Popesti-Leordeni",
      "Pucioasa",
      "Radauti",
      "Ramnicu Sarat",
      "Ramnicu Valcea",
      "Rasnov",
      "Reghin-Sat",
      "Resita",
      "Roman",
      "Rosiorii de Vede",
      "Rovinari",
      "Sabaoani",
      "Sacele",
      "Salonta",
      "Sannicolau Mare",
      "Sanpetru",
      "Santana",
      "Sascut",
      "Satu Mare",
      "Scornicesti",
      "Sebes",
      "Sector 1",
      "Sector 2",
      "Sector 3",
      "Sector 4",
      "Sector 5",
      "Sector 6",
      "Seini",
      "Sfantu Gheorghe",
      "Sibiu",
      "Sighetu Marmatiei",
      "Sighisoara",
      "Simeria",
      "Simleu Silvaniei",
      "Sinaia",
      "Slatina",
      "Slobozia",
      "Sovata",
      "Stefanesti",
      "Storesti",
      "Strehaia",
      "Suceava",
      "Tandarei",
      "Targoviste",
      "Targu Frumos",
      "Targu Jiu",
      "Targu Neamt",
      "Targu Ocna",
      "Targu Secuiesc",
      "Targu-Mures",
      "Tarnaveni",
      "Tecuci",
      "Timisoara",
      "Titu",
      "Tomesti",
      "Topoloveni",
      "Tulcea",
      "Turda",
      "Turnu Magurele",
      "Urlati",
      "Urziceni",
      "Valea Calugareasca",
      "Valea lui Mihai",
      "Valenii de Munte",
      "Vaslui",
      "Vatra Dornei",
      "Vicovu de Sus",
      "Victoria",
      "Videle",
      "Viseu de Sus",
      "Vladimirescu",
      "Voluntari",
      "Vulcan",
      "Zalau",
      "Zarnesti",
      "Zimnicea",
    ],
  },
  "Russian Federation": {
    code: "RU",
    cities: [
      "Abakan",
      "Abalak",
      "Abaza",
      "Abdulino",
      "Abinsk",
      "Achinsk",
      "Achkhoy-Martan",
      "Adler",
      "Admiralteisky",
      "Adygeysk",
      "Afipskiy",
      "Agidel'",
      "Aginskoye",
      "Agryz",
      "Ak-Dovurak",
      "Akademgorodok",
      "Akademicheskoe",
      "Akhtubinsk",
      "Akhty",
      "Akhtyrskiy",
      "Aksay",
      "Al'met'yevsk",
      "Alagir",
      "Alapayevsk",
      "Alatyr'",
      "Aldan",
      "Aleksandriyskaya",
      "Aleksandrov",
      "Aleksandrovsk",
      "Aleksandrovsk-Sakhalinskiy",
      "Aleksandrovskoye",
      "Alekseyevka",
      "Aleksin",
      "Alexeyevka",
      "Aleysk",
      "Alkhan-Kala",
      "Alleroy",
      "Altayskoye",
      "Altuf'yevskiy",
      "Amin'yevo",
      "Amursk",
      "Anadyr",
      "Anapa",
      "Anapskaya",
      "Anastasiyevskaya",
      "Andreyevskoye",
      "Angarsk",
      "Anna",
      "Annino",
      "Annino",
      "Anzhero-Sudzhensk",
      "Apatity",
      "Aprelevka",
      "Apsheronsk",
      "Aramil",
      "Ardon",
      "Argayash",
      "Argun",
      "Arkadak",
      "Arkhangel'sk",
      "Arkhara",
      "Armavir",
      "Arsen'yev",
      "Arsk",
      "Artem",
      "Artemovskiy",
      "Arti",
      "Arzamas",
      "Arzgir",
      "Asbest",
      "Asha",
      "Asino",
      "Assinovskaya",
      "Astrakhan",
      "Atkarsk",
      "Avtovo",
      "Avtury",
      "Aykhal",
      "Ayutinskiy",
      "Azov",
      "Babayevo",
      "Babayurt",
      "Babushkin",
      "Bachatskiy",
      "Bachi-Yurt",
      "Bagayevskaya",
      "Bakal",
      "Baksan",
      "Balabanovo",
      "Balakovo",
      "Balashikha",
      "Balashov",
      "Baley",
      "Balezino",
      "Baltiysk",
      "Barabinsk",
      "Baranchinskiy",
      "Barnaul",
      "Barysh",
      "Bataysk",
      "Bavly",
      "Baykal'sk",
      "Baymak",
      "Bazarnyy Karabulak",
      "Bazhovo",
      "Belaya Glina",
      "Belaya Kalitva",
      "Belaya Kholunitsa",
      "Belebey",
      "Belev",
      "Belgorod",
      "Belidzhi",
      "Belogorsk",
      "Belokurikha",
      "Belomorsk",
      "Beloozyorskiy",
      "Belorechensk",
      "Beloretsk",
      "Belovo",
      "Beloyarsk",
      "Beloyarskiy",
      "Beloyarskiy",
      "Belozersk",
      "Belyye Berega",
      "Berdsk",
      "Berezniki",
      "Berezovka",
      "Berezovskiy",
      "Beryozovsky",
      "Beslan",
      "Bezenchuk",
      "Bezhetsk",
      "Bibirevo",
      "Bikin",
      "Birobidzhan",
      "Birsk",
      "Biryulevo",
      "Biryulevo Zapadnoye",
      "Bisert'",
      "Biysk",
      "Blagodarnyy",
      "Blagoveshchenka",
      "Blagoveshchensk",
      "Blagoveshchensk",
      "Bobrov",
      "Bodaybo",
      "Bogdanovich",
      "Bogoroditsk",
      "Bogorodsk",
      "Bogorodskoye",
      "Bogotol",
      "Boguchany",
      "Boguchar",
      "Boksitogorsk",
      "Bol'shaya Setun'",
      "Bol'sherech'ye",
      "Bol'shoy Kamen'",
      "Bolkhov",
      "Bologoye",
      "Bologoye-4",
      "Bolokhovo",
      "Bolotnoye",
      "Bolshevo",
      "Bor",
      "Borisoglebsk",
      "Borisovka",
      "Borodino",
      "Borovichi",
      "Borovsk",
      "Borovskiy",
      "Borzya",
      "Brateyevo",
      "Bratsk",
      "Bredy",
      "Bronnitsy",
      "Bryansk",
      "Bryukhovetskaya",
      "Budyonnovsk",
      "Bugulma",
      "Buguruslan",
      "Buinsk",
      "Bulanash",
      "Buturlinovka",
      "Buy",
      "Buynaksk",
      "Buzuluk",
      "Bykovo",
      "Centralniy",
      "Chaltyr",
      "Chapayevsk",
      "Chaplygin",
      "Chaykovskiy",
      "Chebarkul'",
      "Cheboksary",
      "Chegdomyn",
      "Chegem",
      "Chegem Vtoroy",
      "Chekhov",
      "Chekmagush",
      "Chelyabinsk",
      "Cherdakly",
      "Cheremkhovo",
      "Cheremushki",
      "Cherepanovo",
      "Cherepovets",
      "Cherkessk",
      "Cherlak",
      "Chernaya Rechka",
      "Chernigovka",
      "Chernogolovka",
      "Chernogorsk",
      "Chernushka",
      "Chernyakhovsk",
      "Chernyanka",
      "Chernyshevsk",
      "Chertanovo Yuzhnoye",
      "Chertkovo",
      "Chishmy",
      "Chistopol'",
      "Chita",
      "Chkalovsk",
      "Chudovo",
      "Chuguyevka",
      "Chul'man",
      "Chulym",
      "Chunskiy",
      "Chusovoy",
      "Dachnoye",
      "Dagestanskiye Ogni",
      "Dagomys",
      "Dalmatovo",
      "Dalnegorsk",
      "Dalnerechensk",
      "Danilov",
      "Dankov",
      "Davlekanovo",
      "Davydkovo",
      "Davydovo",
      "Dedovsk",
      "Degtyarsk",
      "Derbent",
      "Desnogorsk",
      "Digora",
      "Dimitrovgrad",
      "Dinskaya",
      "Divnogorsk",
      "Divnoye",
      "Dmitrov",
      "Dobrinka",
      "Dobryanka",
      "Dolgoprudnyy",
      "Dolinsk",
      "Domodedovo",
      "Donetsk",
      "Donskoy",
      "Donskoy",
      "Donskoye",
      "Dorogobuzh",
      "Dorogomilovo",
      "Drezna",
      "Dubinino",
      "Dubna",
      "Dubovka",
      "Dudinka",
      "Dugulubgey",
      "Dvubratskiy",
      "Dyat'kovo",
      "Dyurtyuli",
      "Dzerzhinsk",
      "Dzerzhinskiy",
      "Dzerzhinsky",
      "Dzhalil'",
      "Ekazhevo",
      "Elektrogorsk",
      "Elektrostal'",
      "Elektrougli",
      "Elista",
      "Enem",
      "Engels",
      "Ertil'",
      "Ezhva",
      "Fedorovskiy",
      "Fili",
      "Finlyandskiy",
      "Fokino",
      "Frolovo",
      "Fryanovo",
      "Fryazevo",
      "Fryazino",
      "Furmanov",
      "Gadzhiyevo",
      "Gagarin",
      "Galich",
      "Gatchina",
      "Gavrilov-Yam",
      "Gay",
      "Gelendzhik",
      "Georgiyevsk",
      "Giaginskaya",
      "Gigant",
      "Glazov",
      "Glubokiy",
      "Gol'yanovo",
      "Golitsyno",
      "Golyshmanovo",
      "Gorelovo",
      "Gorno-Altaysk",
      "Gornozavodsk",
      "Gornyak",
      "Gornyak",
      "Gorodets",
      "Gorodishche",
      "Gorodoviki",
      "Gorokhovets",
      "Goryachevodskiy",
      "Goryachiy Klyuch",
      "Goyty",
      "Gramoteino",
      "Grazhdanka",
      "Gremyachinsk",
      "Gribanovskiy",
      "Groznyy",
      "Gryazi",
      "Gryazovets",
      "Gubakha",
      "Gubkin",
      "Gubkinskiy",
      "Gudermes",
      "Gukovo",
      "Gul'kevichi",
      "Gur'yevsk",
      "Gur'yevsk",
      "Gus'-Khrustal'nyy",
      "Gusev",
      "Gusinoozyorsk",
      "Gvardeysk",
      "Iglino",
      "Igra",
      "Il'skiy",
      "Ilanskiy",
      "Ilovlya",
      "Imeni Morozova",
      "Inozemtsevo",
      "Inskoy",
      "Inza",
      "Inzhavino",
      "Ipatovo",
      "Irbit",
      "Irkutsk",
      "Isakogorka",
      "Ishim",
      "Ishimbay",
      "Iskitim",
      "Islamey",
      "Istra",
      "Ivangorod",
      "Ivanovo",
      "Ivanovskoye",
      "Ivanteyevka",
      "Ivdel'",
      "Izberbash",
      "Izhevsk",
      "Izluchinsk",
      "Izmaylovo",
      "Izobil'nyy",
      "Kaa-Khem",
      "Kabanovo",
      "Kachkanar",
      "Kaduy",
      "Kalach",
      "Kalach-na-Donu",
      "Kalachinsk",
      "Kaliningrad",
      "Kalininsk",
      "Kalininskaya",
      "Kalininskiy",
      "Kaltan",
      "Kaluga",
      "Kalyazin",
      "Kambarka",
      "Kamen'-Rybolov",
      "Kamen'-na-Obi",
      "Kamenolomni",
      "Kamensk-Shakhtinsky",
      "Kamensk-Ural'skiy",
      "Kameshkovo",
      "Kamyshin",
      "Kamyshlov",
      "Kamyzyak",
      "Kanash",
      "Kandalaksha",
      "Kandry",
      "Kanevskaya",
      "Kansk",
      "Kantemirovka",
      "Kantyshevo",
      "Kapotnya",
      "Karabanovo",
      "Karabash",
      "Karabudakhkent",
      "Karabulak",
      "Karachayevsk",
      "Karachev",
      "Karasuk",
      "Kargat",
      "Kargopol'",
      "Karpinsk",
      "Kartaly",
      "Karymskoye",
      "Kashin",
      "Kashira",
      "Kasimov",
      "Kasli",
      "Kaspiysk",
      "Kaspiyskiy",
      "Kastanayevo",
      "Kasumkent",
      "Katav-Ivanovsk",
      "Kataysk",
      "Kavalerovo",
      "Kavkazskaya",
      "Kayakent",
      "Kayyerkan",
      "Kazan",
      "Kazanskaya",
      "Kedrovka",
      "Kem'",
      "Kemerovo",
      "Kenzhe",
      "Kez",
      "Khabarovsk",
      "Khabarovsk Vtoroy",
      "Khadyzhensk",
      "Khanskaya",
      "Khanty-Mansiysk",
      "Kharabali",
      "Kharovsk",
      "Khasavyurt",
      "Khilok",
      "Khimki",
      "Kholmsk",
      "Kholmskiy",
      "Khor",
      "Khorol'",
      "Khoroshevo-Mnevniki",
      "Khosta",
      "Khot'kovo",
      "Khvalynsk",
      "Kimovsk",
      "Kimry",
      "Kinel'",
      "Kinel'-Cherkassy",
      "Kineshma",
      "Kingisepp",
      "Kirensk",
      "Kireyevsk",
      "Kirishi",
      "Kirov",
      "Kirov",
      "Kirovgrad",
      "Kirovo-Chepetsk",
      "Kirovsk",
      "Kirovsk",
      "Kirs",
      "Kirsanov",
      "Kirzhach",
      "Kiselevsk",
      "Kislovodsk",
      "Kizel",
      "Kizilyurt",
      "Kizlyar",
      "Kletnya",
      "Klimovo",
      "Klimovsk",
      "Klin",
      "Klintsy",
      "Kochenevo",
      "Kochubeyevskoye",
      "Kodinsk",
      "Kogalym",
      "Kokhma",
      "Kol'chugino",
      "Kola",
      "Kolomenskoye",
      "Kolomna",
      "Kolomyagi",
      "Kolpashevo",
      "Kolpino",
      "Kolyvan'",
      "Komendantsky aerodrom",
      "Kommunar",
      "Komsomolsk-on-Amur",
      "Konakovo",
      "Kondopoga",
      "Kondrovo",
      "Konobeyevo",
      "Konosha",
      "Konstantinovsk",
      "Kopeysk",
      "Korablino",
      "Korenovsk",
      "Korkino",
      "Kormilovka",
      "Korolev",
      "Korsakov",
      "Koryazhma",
      "Kosaya Gora",
      "Kostomuksha",
      "Kostroma",
      "Kotel'nich",
      "Kotel'niki",
      "Kotel'nikovo",
      "Kotlas",
      "Kotlovka",
      "Kotovo",
      "Kotovsk",
      "Kovdor",
      "Kovrov",
      "Kovylkino",
      "Koz'modem'yansk",
      "Kozel'sk",
      "Kozeyevo",
      "Kozhukhovo",
      "Kozlovka",
      "Kraskovo",
      "Krasnaya Glinka",
      "Krasnoarmeysk",
      "Krasnoarmeysk",
      "Krasnoarmeyskaya",
      "Krasnobrodskiy",
      "Krasnodar",
      "Krasnogorsk",
      "Krasnogorskiy",
      "Krasnogvardeyskoye",
      "Krasnogvargeisky",
      "Krasnokamensk",
      "Krasnokamsk",
      "Krasnokumskoye",
      "Krasnoobsk",
      "Krasnoslobodsk",
      "Krasnoslobodsk",
      "Krasnoturinsk",
      "Krasnoufimsk",
      "Krasnoural'sk",
      "Krasnovishersk",
      "Krasnoyarsk",
      "Krasnoye Selo",
      "Krasnozavodsk",
      "Krasnozerskoye",
      "Krasnoznamensk",
      "Krasnyy Kut",
      "Krasnyy Sulin",
      "Krasnyy Yar",
      "Kremenki",
      "Krestovskiy ostrov",
      "Krivyanskaya",
      "Kronstadt",
      "Kropotkin",
      "Krylovskaya",
      "Krymsk",
      "Kstovo",
      "Kubinka",
      "Kudepsta",
      "Kudrovo",
      "Kudymkar",
      "Kugesi",
      "Kukmor",
      "Kulebaki",
      "Kuleshovka",
      "Kulunda",
      "Kumertau",
      "Kungur",
      "Kupavna",
      "Kupchino",
      "Kupino",
      "Kur'yanovo",
      "Kuragino",
      "Kurchaloy",
      "Kurchatov",
      "Kurgan",
      "Kurganinsk",
      "Kurortnyy",
      "Kurovskoye",
      "Kursavka",
      "Kursk",
      "Kurskaya",
      "Kurtamysh",
      "Kusa",
      "Kushchyovskaya",
      "Kushnarenkovo",
      "Kushva",
      "Kuskovo",
      "Kuvandyk",
      "Kuvshinovo",
      "Kuybyshev",
      "Kuytun",
      "Kuz'minki",
      "Kuznetsk",
      "Kyakhta",
      "Kyshtym",
      "Kyzyl",
      "L'govskiy",
      "L'vovskiy",
      "Labinsk",
      "Labytnangi",
      "Ladozhskaya",
      "Lakinsk",
      "Langepas",
      "Lazarevskoye",
      "Lebedyan'",
      "Lefortovo",
      "Leningradskaya",
      "Leninkent",
      "Leninogorsk",
      "Leninsk",
      "Leninsk-Kuznetsky",
      "Leninskiye Gory",
      "Lensk",
      "Leonovo",
      "Lermontov",
      "Lesnoy",
      "Lesnoy",
      "Lesosibirsk",
      "Lesozavodsk",
      "Levoberezhnyy",
      "Levokumskoye",
      "Lianozovo",
      "Likhobory",
      "Likhoslavl'",
      "Likhovskoy",
      "Likino-Dulevo",
      "Linevo",
      "Lipetsk",
      "Liski",
      "Livadiya",
      "Livny",
      "Lobnya",
      "Lodeynoye Pole",
      "Lokomotivnyy",
      "Lokot'",
      "Lomonosov",
      "Lopatinskiy",
      "Losino-Petrovskiy",
      "Luchegorsk",
      "Luga",
      "Lukhovitsy",
      "Lukoyanov",
      "Luza",
      "Luzhniki",
      "Lyangasovo",
      "Lyantor",
      "Lys'va",
      "Lyskovo",
      "Lysogorskaya",
      "Lytkarino",
      "Lyubertsy",
      "Lyubinskiy",
      "Lyublino",
      "Lyudinovo",
      "Magadan",
      "Magdagachi",
      "Magnitogorsk",
      "Makhachkala",
      "Malakhovka",
      "Malaya Vishera",
      "Malgobek",
      "Maloyaroslavets",
      "Mamadysh",
      "Manturovo",
      "Mar'ino",
      "Mariinsk",
      "Mariinskiy Posad",
      "Markha",
      "Markova",
      "Marks",
      "Maslyanino",
      "Matveyev Kurgan",
      "Matveyevskoye",
      "Maykop",
      "Mayma",
      "Mayrtup",
      "Mayskiy",
      "Mednogorsk",
      "Medvedevo",
      "Medvedovskaya",
      "Medvezh'yegorsk",
      "Megion",
      "Mel'nikovo",
      "Melenki",
      "Meleuz",
      "Mendeleyevsk",
      "Menzelinsk",
      "Mesker-Yurt",
      "Metallostroy",
      "Metrogorodok",
      "Mezgor'e",
      "Mezhdurechensk",
      "Mezhdurechenskiy",
      "Miass",
      "Michurinsk",
      "Mikhalkovo",
      "Mikhaylov",
      "Mikhaylovka",
      "Mikhaylovsk",
      "Mikhaylovsk",
      "Mikhaylovskoye",
      "Mikhaylovskoye",
      "Mikhnevo",
      "Mikun'",
      "Millerovo",
      "Mineralnye Vody",
      "Minusinsk",
      "Mirny",
      "Mirnyy",
      "Mogocha",
      "Mokshan",
      "Monchegorsk",
      "Monino",
      "Morozovsk",
      "Morshansk",
      "Moscow",
      "Moshkovo",
      "Moskovskiy",
      "Mostovskoy",
      "Mozdok",
      "Mozhaysk",
      "Mozhga",
      "Mtsensk",
      "Mulino",
      "Muravlenko",
      "Murmansk",
      "Murmashi",
      "Murom",
      "Muromtsevo",
      "Myski",
      "Mytishchi",
      "Naberezhnyye Chelny",
      "Nadvoitsy",
      "Nadym",
      "Nagornyy",
      "Nakhabino",
      "Nakhodka",
      "Nalchik",
      "Nar'yan-Mar",
      "Narimanov",
      "Naro-Fominsk",
      "Nartan",
      "Nartkala",
      "Navashino",
      "Navlya",
      "Navoloki",
      "Nazarovo",
      "Nazran'",
      "Nazyvayevsk",
      "Neftegorsk",
      "Neftekamsk",
      "Neftekumsk",
      "Nefteyugansk",
      "Nekrasovskiy",
      "Nelidovo",
      "Neman",
      "Nerchinsk",
      "Nerekhta",
      "Neryungri",
      "Nesterovskaya",
      "Nev'yansk",
      "Nevel'",
      "Nevel'sk",
      "Nevinnomyssk",
      "Neya",
      "Nezlobnaya",
      "Nikel",
      "Nikol'sk",
      "Nikol'skoye",
      "Nikol'skoye",
      "Nikolayevsk",
      "Nikolayevsk-on-Amure",
      "Nikulino",
      "Nizhnekamsk",
      "Nizhnesortymskiy",
      "Nizhneudinsk",
      "Nizhnevartovsk",
      "Nizhneye Kazanishche",
      "Nizhniy Lomov",
      "Nizhniy Novgorod",
      "Nizhniy Odes",
      "Nizhniye Sergi",
      "Nizhny Tagil",
      "Nizhnyaya Maktama",
      "Nizhnyaya Salda",
      "Nizhnyaya Tura",
      "Noginsk",
      "Nogir",
      "Nogliki",
      "Nolinsk",
      "Norilsk",
      "Novaya Balakhna",
      "Novaya Derevnya",
      "Novaya Igirma",
      "Novaya Lyalya",
      "Novaya Usman'",
      "Novo-Peredelkino",
      "Novoaleksandrovsk",
      "Novoaltaysk",
      "Novoanninskiy",
      "Novocheboksarsk",
      "Novocherkassk",
      "Novodvinsk",
      "Novogireyevo",
      "Novokhovrino",
      "Novokubansk",
      "Novokuybyshevsk",
      "Novokuz'minki",
      "Novokuznetsk",
      "Novomichurinsk",
      "Novomikhaylovskiy",
      "Novominskaya",
      "Novomoskovsk",
      "Novonikolayevskiy",
      "Novoorsk",
      "Novopavlovsk",
      "Novopodrezkovo",
      "Novopokrovskaya",
      "Novorossiysk",
      "Novosergiyevka",
      "Novoshakhtinsk",
      "Novosibirsk",
      "Novosilikatnyy",
      "Novosineglazovskiy",
      "Novospasskoye",
      "Novotitarovskaya",
      "Novotroitsk",
      "Novoul'yanovsk",
      "Novoural'sk",
      "Novouzensk",
      "Novovladykino",
      "Novovoronezh",
      "Novoye Devyatkino",
      "Novozybkov",
      "Novy Oskol",
      "Novyy Urengoy",
      "Novyye Cheremushki",
      "Novyye Kuz'minki",
      "Noyabrsk",
      "Nurlat",
      "Nyagan",
      "Nyandoma",
      "Nyazepetrovsk",
      "Nytva",
      "Ob'",
      "Obninsk",
      "Oboyan'",
      "Obruchevo",
      "Obukhovo",
      "Obukhovo",
      "Ochakovo-Matveyevskoye",
      "Ocher",
      "Odintsovo",
      "Okha",
      "Oktyabr'sk",
      "Oktyabr'skiy",
      "Oktyabrsky",
      "Okulovka",
      "Olenegorsk",
      "Olonets",
      "Omsk",
      "Omutninsk",
      "Onega",
      "Onokhoy",
      "Opochka",
      "Ordynskoye",
      "Orekhovo-Borisovo",
      "Orekhovo-Borisovo Severnoye",
      "Orekhovo-Zuyevo",
      "Orel",
      "Orenburg",
      "Orlovskiy",
      "Orsk",
      "Osa",
      "Osinniki",
      "Ostankinskiy",
      "Ostashkov",
      "Ostrogozhsk",
      "Ostrov",
      "Otradnaya",
      "Otradnoye",
      "Otradnoye",
      "Otradnyy",
      "Oyskhara",
      "Ozerki",
      "Ozersk",
      "Ozery",
      "Ozherel'ye",
      "Pallasovka",
      "Pangody",
      "Pargolovo",
      "Parnas",
      "Partizansk",
      "Pashkovskiy",
      "Pavlovo",
      "Pavlovsk",
      "Pavlovsk",
      "Pavlovsk",
      "Pavlovskaya",
      "Pavlovskiy Posad",
      "Pechora",
      "Pechory",
      "Penza",
      "Pereslavl'-Zalesskiy",
      "Peresvet",
      "Perm",
      "Persianovka",
      "Pervomaysk",
      "Pervomayskiy",
      "Pervomayskiy",
      "Pervomayskiy",
      "Pervouralsk",
      "Peschanokopskoye",
      "Pestovo",
      "Peterhof",
      "Petrodvorets",
      "Petrogradka",
      "Petropavlovsk-Kamchatsky",
      "Petrov Val",
      "Petrovsk",
      "Petrovsk-Zabaykal'skiy",
      "Petrovskaya",
      "Petrovskaya",
      "Petrozavodsk",
      "Petukhovo",
      "Petushki",
      "Pikalevo",
      "Pionerskiy",
      "Pitkyaranta",
      "Plast",
      "Platnirovskaya",
      "Plavsk",
      "Plesetsk",
      "Pochep",
      "Pochinki",
      "Podolsk",
      "Podporozh'ye",
      "Pogar",
      "Pogranichnyy",
      "Pokachi",
      "Pokhvistnevo",
      "Pokrov",
      "Pokrovka",
      "Pokrovskoye",
      "Pokrovskoye-Streshnevo",
      "Polazna",
      "Polevskoy",
      "Polyarnyy",
      "Polyarnyye Zori",
      "Polysayevo",
      "Porkhov",
      "Poronaysk",
      "Pospelikha",
      "Povorino",
      "Poykovskiy",
      "Praskoveya",
      "Pravdinskiy",
      "Presnenskiy",
      "Pribrezhnyy",
      "Pridonskoy",
      "Primorsko-Akhtarsk",
      "Priozersk",
      "Privolzhsk",
      "Privolzhskiy",
      "Priyutovo",
      "Prokhladnyy",
      "Prokhorovka",
      "Prokop'yevsk",
      "Proletarsk",
      "Promyshlennaya",
      "Protvino",
      "Psebay",
      "Pskov",
      "Pudozh",
      "Pugachev",
      "Pushchino",
      "Pushkin",
      "Pushkino",
      "Pyatigorsk",
      "Pyshma",
      "Pyt-Yakh",
      "Raduzhny",
      "Raduzhnyy",
      "Raduzhnyy",
      "Rakitnoye",
      "Ramenki",
      "Ramenskoye",
      "Rasskazovo",
      "Raychikhinsk",
      "Rayevskiy",
      "Razumnoye",
      "Redkino",
      "Reftinskiy",
      "Reutov",
      "Revda",
      "Rezh",
      "Rodniki",
      "Roschinskiy",
      "Roshal'",
      "Roslavl'",
      "Rossosh'",
      "Rossosh'",
      "Rostokino",
      "Rostov",
      "Rostov-na-Donu",
      "Roza",
      "Rtishchevo",
      "Rublevo",
      "Rubtsovsk",
      "Ruza",
      "Ruzayevka",
      "Ryazan'",
      "Ryazanskiy",
      "Ryazhsk",
      "Rybatskoye",
      "Rybinsk",
      "Rybnoye",
      "Ryl'sk",
      "Rzhev",
      "Safonovo",
      "Sagopshi",
      "Saint Petersburg",
      "Sal'sk",
      "Salavat",
      "Salekhard",
      "Samara",
      "Samarskoye",
      "Samashki",
      "Sampsonievskiy",
      "Saraktash",
      "Saransk",
      "Sarapul",
      "Saratov",
      "Sarov",
      "Sasovo",
      "Satka",
      "Sayanogorsk",
      "Sayansk",
      "Segezha",
      "Sel'tso",
      "Selyatino",
      "Semenov",
      "Semenovskoye",
      "Semikarakorsk",
      "Semiluki",
      "Serafimovskiy",
      "Serdobsk",
      "Sergach",
      "Sergiyev Posad",
      "Serov",
      "Serpukhov",
      "Sertolovo",
      "Seryshevo",
      "Sestroretsk",
      "Setun'",
      "Severnyy",
      "Severo-Zadonsk",
      "Severobaykal'sk",
      "Severodvinsk",
      "Severomorsk",
      "Severoural'sk",
      "Seversk",
      "Severskaya",
      "Shadrinsk",
      "Shagonar",
      "Shakhovskaya",
      "Shakhtersk",
      "Shakhty",
      "Shakhun'ya",
      "Shali",
      "Shalushka",
      "Shar'ya",
      "Sharypovo",
      "Shatura",
      "Shchekino",
      "Shcherbinka",
      "Shchigry",
      "Shchukino",
      "Shchyolkovo",
      "Shebekino",
      "Sheksna",
      "Shelekhov",
      "Sheregesh",
      "Shilka",
      "Shilovo",
      "Shimanovsk",
      "Shipunovo",
      "Shlissel'burg",
      "Sholokhovskiy",
      "Shumerlya",
      "Shumikha",
      "Shushary",
      "Shushenskoye",
      "Shuya",
      "Sibay",
      "Sibirskiy",
      "Sim",
      "Siverskiy",
      "Skhodnya",
      "Skopin",
      "Skovorodino",
      "Skuratovskiy",
      "Slantsy",
      "Slavgorod",
      "Slavyanka",
      "Slavyansk-na-Kubani",
      "Slobodka",
      "Slobodskoy",
      "Slyudyanka",
      "Smolensk",
      "Snezhinsk",
      "Snezhnogorsk",
      "Sobinka",
      "Sochi",
      "Sofrino",
      "Sokol",
      "Sokol",
      "Sokol'niki",
      "Sol'-Iletsk",
      "Sol'tsy",
      "Soldato-Aleksandrovskoye",
      "Solikamsk",
      "Solnechniy",
      "Solnechnogorsk",
      "Solnechnyy",
      "Solnechnyy",
      "Solnechnyy",
      "Solntsevo",
      "Somovo",
      "Sorochinsk",
      "Sorsk",
      "Sortavala",
      "Sosenskiy",
      "Sosnogorsk",
      "Sosnovaya Polyana",
      "Sosnovka",
      "Sosnovka",
      "Sosnovka",
      "Sosnovoborsk",
      "Sosnovyy Bor",
      "Sovetsk",
      "Sovetsk",
      "Sovetskaya",
      "Sovetskaya Gavan'",
      "Sovetskiy",
      "Sovetskiy",
      "Spassk-Dal'niy",
      "Sredneuralsk",
      "Srednyaya Akhtuba",
      "Staraya Derevnya",
      "Staraya Kupavna",
      "Staraya Russa",
      "Staroderevyankovskaya",
      "Starodub",
      "Starokamyshinsk",
      "Starokorsunskaya",
      "Starominskaya",
      "Staromyshastovskaya",
      "Staronizhestebliyevskaya",
      "Staroshcherbinovskaya",
      "Starotitarovskaya",
      "Starovelichkovskaya",
      "Staryy Malgobek",
      "Staryy Oskol",
      "Staryye Atagi",
      "Stavropol'",
      "Stepnoye",
      "Sterlitamak",
      "Strel'na",
      "Strezhevoy",
      "Strogino",
      "Stroitel",
      "Strunino",
      "Stupino",
      "Sudogda",
      "Sukhinichi",
      "Sukhodol",
      "Sukhoy Log",
      "Sunzha",
      "Sunzha",
      "Suoyarvi",
      "Surazh",
      "Surgut",
      "Surkhakhi",
      "Surovikino",
      "Suvorov",
      "Suvorovskaya",
      "Suzdal'",
      "Suzun",
      "Svetlanovskiy",
      "Svetlogorsk",
      "Svetlograd",
      "Svetlyy",
      "Svetlyy",
      "Svetlyy Yar",
      "Svetogorsk",
      "Sviblovo",
      "Svirsk",
      "Svobodnyy",
      "Svobody",
      "Syas'stroy",
      "Syktyvkar",
      "Sysert'",
      "Syzran",
      "Taganrog",
      "Taganskiy",
      "Taksimo",
      "Tal'menka",
      "Taldom",
      "Talitsa",
      "Talitsa",
      "Talnakh",
      "Talovskiy",
      "Tambov",
      "Tara",
      "Tarki",
      "Tarko-Sale",
      "Tarusa",
      "Tashtagol",
      "Tatarsk",
      "Tatsinskiy",
      "Tavda",
      "Tavricheskoye",
      "Tayga",
      "Tayshet",
      "Tbilisskaya",
      "Tekstil'shchiki",
      "Temryuk",
      "Terek",
      "Tetyushi",
      "Teykovo",
      "Tikhoretsk",
      "Tikhvin",
      "Timashyovsk",
      "Tobolsk",
      "Toguchin",
      "Tolbazy",
      "Tolyatti",
      "Tomilino",
      "Tomsk",
      "Topki",
      "Toropets",
      "Torzhok",
      "Tosno",
      "Tot'ma",
      "Tovarkovo",
      "Trekhgornyy",
      "Troitsk",
      "Troitsk",
      "Troitskaya",
      "Troitskiy",
      "Troitskoye",
      "Troitskoye",
      "Troparevo",
      "Trubchevsk",
      "Trudovoye",
      "Tsaritsyno",
      "Tselina",
      "Tsimlyansk",
      "Tsivil'sk",
      "Tsotsin-Yurt",
      "Tuapse",
      "Tuchkovo",
      "Tula",
      "Tulun",
      "Turinsk",
      "Tutayev",
      "Tuymazy",
      "Tver",
      "Tyazhinskiy",
      "Tynda",
      "Tyoply Stan",
      "Tyrnyauz",
      "Tyukalinsk",
      "Tyul'gan",
      "Tyumen",
      "Uchaly",
      "Uchkeken",
      "Udachny",
      "Udel'naya",
      "Udomlya",
      "Ufa",
      "Uglegorsk",
      "Uglich",
      "Uglovoye",
      "Ukhta",
      "Ulan-Ude",
      "Ulyanovsk",
      "Unecha",
      "Untolovo",
      "Uray",
      "Uren'",
      "Uritsk",
      "Urus-Martan",
      "Urussu",
      "Uryupinsk",
      "Urzhum",
      "Usinsk",
      "Usman'",
      "Usol'ye-Sibirskoye",
      "Uspenskoye",
      "Ussuriysk",
      "Ust'-Abakan",
      "Ust'-Donetskiy",
      "Ust'-Dzheguta",
      "Ust'-Ilimsk",
      "Ust'-Katav",
      "Ust'-Kut",
      "Ust'-Labinsk",
      "Ust'-Ordynskiy",
      "Ustyuzhna",
      "Uva",
      "Uvarovo",
      "Uvel'skiy",
      "Uyar",
      "Uzhur",
      "Uzlovaya",
      "Vagonoremont",
      "Vakhrushi",
      "Valday",
      "Valuyki",
      "Vanino",
      "Varenikovskaya",
      "Vasil'yevo",
      "Vaskelovo",
      "Vasyl'evsky Ostrov",
      "Vasyurinskaya",
      "Vatutino",
      "Vel'sk",
      "Velikiy Novgorod",
      "Velikiy Ustyug",
      "Velikiye Luki",
      "Venev",
      "Vereshchagino",
      "Verkhnedneprovskiy",
      "Verkhneural'sk",
      "Verkhniy Tagil",
      "Verkhniy Ufaley",
      "Verkhnyaya Pyshma",
      "Verkhnyaya Salda",
      "Verkhnyaya Sinyachikha",
      "Verkhnyaya Tura",
      "Veshnyaki",
      "Vetluzhskiy",
      "Vichuga",
      "Vidnoye",
      "Vikhorevka",
      "Vilyuchinsk",
      "Vinzili",
      "Vladikavkaz",
      "Vladimir",
      "Vladivostok",
      "Vnukovo",
      "Vol'sk",
      "Volchansk",
      "Volchikha",
      "Volgodonsk",
      "Volgograd",
      "Volgorechensk",
      "Volkhov",
      "Volodarsk",
      "Vologda",
      "Volokolamsk",
      "Volokonovka",
      "Volosovo",
      "Volzhsk",
      "Volzhsky",
      "Vorgashor",
      "Vorkuta",
      "Vorob'yovo",
      "Voronezh",
      "Vorotynsk",
      "Vorsma",
      "Voskresensk",
      "Vostochnoe Degunino",
      "Vostochnyy",
      "Vostryakovo",
      "Vostryakovo",
      "Votkinsk",
      "Vrangel'",
      "Vsevolozhsk",
      "Vuktyl",
      "Vurnary",
      "Vyatskiye Polyany",
      "Vyaz'ma",
      "Vyazemskiy",
      "Vyazniki",
      "Vyborg",
      "Vychegodskiy",
      "Vykhino-Zhulebino",
      "Vyksa",
      "Vyritsa",
      "Vyselki",
      "Vyshniy Volochek",
      "Vysokovsk",
      "Vytegra",
      "Yablonovskiy",
      "Yadrin",
      "Yagry",
      "Yakhroma",
      "Yakutsk",
      "Yalutorovsk",
      "Yanaul",
      "Yaransk",
      "Yaroslavl",
      "Yaroslavskiy",
      "Yaroslavskiy",
      "Yarovoye",
      "Yartsevo",
      "Yasenevo",
      "Yashkino",
      "Yasnogorsk",
      "Yasnyy",
      "Yaya",
      "Yayva",
      "Yefremov",
      "Yegor'yevsk",
      "Yegorlykskaya",
      "Yekaterinburg",
      "Yekaterinoslavka",
      "Yel'nya",
      "Yelabuga",
      "Yelan'",
      "Yelets",
      "Yelizavetinskaya",
      "Yelizovo",
      "Yemanzhelinsk",
      "Yemel'yanovo",
      "Yemetsk",
      "Yemva",
      "Yeniseysk",
      "Yermolino",
      "Yershov",
      "Yessentuki",
      "Yessentukskaya",
      "Yeysk",
      "Yoshkar-Ola",
      "Yubileyny",
      "Yugorsk",
      "Yur'yev-Pol'skiy",
      "Yur'yevets",
      "Yurga",
      "Yuryuzan'",
      "Yuzha",
      "Yuzhno-Sakhalinsk",
      "Yuzhno-Sukhokumsk",
      "Yuzhnoural'sk",
      "Yuzhnyy",
      "Zabaykal'sk",
      "Zadonsk",
      "Zagor'ye",
      "Zainsk",
      "Zakamensk",
      "Zamoskvorech'ye",
      "Zaozersk",
      "Zapolyarnyy",
      "Zaprudnya",
      "Zaraysk",
      "Zarechnyy",
      "Zarechnyy",
      "Zarechnyy",
      "Zarinsk",
      "Zarya",
      "Zavety Il'icha",
      "Zavitinsk",
      "Zavodoukovsk",
      "Zavodskoy",
      "Zavolzh'ye",
      "Zavolzhsk",
      "Zayukovo",
      "Zelenchukskaya",
      "Zelenodolsk",
      "Zelenogorsk",
      "Zelenogorsk",
      "Zelenograd",
      "Zelenogradsk",
      "Zelenokumsk",
      "Zemetchino",
      "Zernograd",
      "Zeya",
      "Zheleznodorozhnyy",
      "Zheleznogorsk",
      "Zheleznogorsk",
      "Zheleznogorsk-Ilimskiy",
      "Zheleznovodsk",
      "Zherdevka",
      "Zhigulevsk",
      "Zhirnovsk",
      "Zhukov",
      "Zhukovka",
      "Zhukovsky",
      "Zhulebino",
      "Zima",
      "Zimovniki",
      "Zlatoust",
      "Zmeinogorsk",
      "Znamenka",
      "Znamensk",
      "Znamenskoye",
      "Zubova Polyana",
      "Zuyevka",
      "Zvenigorod",
      "Zvenigovo",
      "Zverevo",
      "Zyablikovo",
      "Zyuzino",
    ],
  },
  Rwanda: {
    code: "RW",
    cities: [
      "Bugaragara",
      "Bugarama",
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kamuhanda / Ruyenzi",
      "Kayonza",
      "Kibungo",
      "Kibuye",
      "Kigali",
      "Kinigi",
      "Kora",
      "Mabanza",
      "Mugina",
      "Mukamira",
      "Mukingo",
      "Musanze",
      "Nyamata",
      "Nyanza",
      "Nzega",
      "Ruhango",
      "Rwamagana",
      "Umutara",
    ],
  },
  Réunion: {
    code: "RE",
    cities: [
      "Bras-Panon",
      "L'Etang-Sale",
      "La Possession",
      "Le Port",
      "Le Tampon",
      "Les Avirons",
      "Petite-Ile",
      "Piton Saint-Leu",
      "Saint-Andre",
      "Saint-Benoit",
      "Saint-Denis",
      "Saint-Joseph",
      "Saint-Leu",
      "Saint-Louis",
      "Saint-Paul",
      "Saint-Pierre",
      "Sainte-Marie",
      "Sainte-Suzanne",
    ],
  },
  "Saint Kitts and Nevis": {
    code: "KN",
    cities: ["Basseterre"],
  },
  "Saint Lucia": {
    code: "LC",
    cities: ["Bisee", "Castries"],
  },
  "Saint Vincent and the Grenadines": {
    code: "VC",
    cities: ["Kingstown"],
  },
  "Saudi Arabia": {
    code: "SA",
    cities: [
      "Abha",
      "Abqaiq",
      "Abu `Arish",
      "Ad Dawadimi",
      "Ad Dilam",
      "Afif",
      "Al Awjam",
      "Al Bahah",
      "Al Battaliyah",
      "Al Bukayriyah",
      "Al Hufuf",
      "Al Jubayl",
      "Al Jumum",
      "Al Khafji",
      "Al Kharj",
      "Al Lith",
      "Al Majaridah",
      "Al Mithnab",
      "Al Mubarraz",
      "Al Munayzilah",
      "Al Qatif",
      "Al Qurayn",
      "Al Wajh",
      "Al-`Ula",
      "An Nimas",
      "Ar Rass",
      "Arar",
      "As Sulayyil",
      "Ash Shafa",
      "At Taraf",
      "Az Zulfi",
      "Badr Hunayn",
      "Bariq",
      "Buraydah",
      "Dammam",
      "Dhahran",
      "Duba",
      "Farasan",
      "Fayfa'",
      "Ha'il",
      "Hafar Al-Batin",
      "Jeddah",
      "Jizan",
      "Khamis Mushait",
      "Khobar",
      "Mecca",
      "Medina",
      "Najran",
      "Qaisumah",
      "Qal`at Bishah",
      "Qurayyat",
      "Rabigh",
      "Rahimah",
      "Riyadh",
      "Sabt Alalayah",
      "Sabya",
      "Safwa",
      "Sajir",
      "Sakakah",
      "Samitah",
      "Sayhat",
      "Sultanah",
      "Ta'if",
      "Tabuk",
      "Tanumah",
      "Tarut",
      "Tubarjal",
      "Turabah",
      "Turaif",
      "Umluj",
      "Umm as Sahik",
      "Yanbu",
    ],
  },
  Senegal: {
    code: "SN",
    cities: [
      "Amady Ounare",
      "Bakel",
      "Bambey",
      "Bargny",
      "Bargny Guedj",
      "Bargny Ngoude",
      "Bignona",
      "Dagana",
      "Dakar",
      "Dara",
      "Diamniadio",
      "Diaoube",
      "Diofior",
      "Diourbel",
      "Dougnane",
      "Fatick",
      "Gandiaye",
      "Gossas Village",
      "Goudomp",
      "Guinguineo",
      "Joal-Fadiout",
      "Kaffrine",
      "Kahone",
      "Kanel",
      "Kaolack",
      "Karang",
      "Kayar",
      "Kbombole",
      "Kebemer",
      "Kedougou",
      "Keur Madiabel",
      "Khombole",
      "Kidira",
      "Kolda",
      "Koumpentoum",
      "Koungheul",
      "Koungneul Sosse",
      "Kounkane",
      "Linguere",
      "Louga",
      "Madina Wandifa",
      "Matam",
      "Mbake",
      "Mboro",
      "Mbour",
      "Mekhe",
      "Meri Mbake",
      "Mermoz Boabab",
      "N'diareme limamoulaye",
      "Ndibene Dahra",
      "Ndioum",
      "Ndofane",
      "Nganda",
      "Nguekhokh",
      "Nguidjlone",
      "Niakoul Rab",
      "Nioro du Rip",
      "Ouro Sogui",
      "Passi",
      "Pikine",
      "Podor",
      "Pourham",
      "Pout",
      "Richard-Toll",
      "Ross-Betio",
      "Rufisque",
      "Rufisque est",
      "Saint-Louis",
      "Sali",
      "Sangalkam",
      "Sebikhotane",
      "Sedhiou",
      "Sintiou Bamambe",
      "Sokone",
      "Tambacounda",
      "Thies",
      "Thies Nones",
      "Thilogne",
      "Tiadiaye",
      "Tiebo",
      "Tilogne Tokossel",
      "Touba",
      "Velingara",
      "Ziguinchor",
    ],
  },
  Serbia: {
    code: "RS",
    cities: [
      "Apatin",
      "Arangelovac",
      "Backa Palanka",
      "Backa Topola",
      "Becej",
      "Bela Crkva",
      "Belgrade",
      "Bor",
      "Cacak",
      "Cuprija",
      "Futog",
      "Gornji Milanovac",
      "Ingija",
      "Jagodina",
      "Kac",
      "Kanjiza",
      "Kikinda",
      "Knjazevac",
      "Kovin",
      "Kragujevac",
      "Kraljevo",
      "Krusevac",
      "Lazarevac",
      "Leskovac",
      "Loznica",
      "Majdanpek",
      "Negotin",
      "Nis",
      "Nova Pazova",
      "Novi Pazar",
      "Novi Sad",
      "Obrenovac",
      "Pancevo",
      "Petrovaradin",
      "Pirot",
      "Pozarevac",
      "Prokuplje",
      "Ripanj",
      "Ruma",
      "Sabac",
      "Senta",
      "Smederevo",
      "Smederevska Palanka",
      "Sombor",
      "Sremcica",
      "Sremska Kamenica",
      "Sremska Mitrovica",
      "Stara Pazova",
      "Subotica",
      "Surcin",
      "Trstenik",
      "Uzice",
      "Valjevo",
      "Veternik",
      "Vranje",
      "Vrbas",
      "Vrnjacka Banja",
      "Vrsac",
      "Zajecar",
      "Zemun",
      "Zrenjanin",
    ],
  },
  Seychelles: {
    code: "SC",
    cities: ["Victoria"],
  },
  "Sierra Leone": {
    code: "SL",
    cities: [
      "Binkolo",
      "Bo",
      "Bonthe",
      "Bumpe",
      "Freetown",
      "Gandorhun",
      "Gandorhun",
      "Goderich",
      "Kabala",
      "Kailahun",
      "Kambia",
      "Kenema",
      "Koidu",
      "Lunsar",
      "Magburaka",
      "Makeni",
      "Mile 91",
      "Port Loko",
      "Rokupr",
      "Segbwema",
      "Waterloo",
      "Yengema",
    ],
  },
  Singapore: {
    code: "SG",
    cities: [
      "Geylang",
      "Jurong Town",
      "Kampong Pasir Ris",
      "Marine Parade",
      "Punggol",
      "Queenstown Estate",
      "Singapore",
      "Woodlands",
    ],
  },
  Slovakia: {
    code: "SK",
    cities: [
      "Banovce nad Bebravou",
      "Banska Bystrica",
      "Banska Stiavnica",
      "Bardejov",
      "Bratislava",
      "Brezno",
      "Bytca",
      "Cadca",
      "Detva",
      "Dolny Kubin",
      "Dubnica nad Vahom",
      "Dunajska Streda",
      "Filakovo",
      "Galanta",
      "Handlova",
      "Hlohovec",
      "Holic",
      "Humenne",
      "Kezmarok",
      "Kolarovo",
      "Komarno",
      "Kosice",
      "Kysucke Nove Mesto",
      "Levice",
      "Levoca",
      "Liptovsky Mikulas",
      "Lucenec",
      "Malacky",
      "Martin",
      "Michalovce",
      "Moldava nad Bodvou",
      "Myjava",
      "Nitra",
      "Nova Dubnica",
      "Nove Mesto nad Vahom",
      "Nove Zamky",
      "Partizanske",
      "Pezinok",
      "Piestany",
      "Poprad",
      "Povazska Bystrica",
      "Presov",
      "Prievidza",
      "Puchov",
      "Revuca",
      "Rimavska Sobota",
      "Roznava",
      "Ruzomberok",
      "Sabinov",
      "Sala",
      "Samorin",
      "Senec",
      "Senica",
      "Skalica",
      "Snina",
      "Spisska Nova Ves",
      "Stara Lubovna",
      "Stare Mesto",
      "Stropkov",
      "Stupava",
      "Sturovo",
      "Svidnik",
      "Trebisov",
      "Trencin",
      "Trnava",
      "Velky Krtis",
      "Vranov nad Toplou",
      "Ziar nad Hronom",
      "Zilina",
      "Zlate Moravce",
      "Zvolen",
    ],
  },
  Slovenia: {
    code: "SI",
    cities: [
      "Celje",
      "Domzale",
      "Izola",
      "Jesenice",
      "Kamnik",
      "Koper",
      "Kranj",
      "Ljubljana",
      "Maribor",
      "Murska Sobota",
      "Nova Gorica",
      "Novo Mesto",
      "Ptuj",
      "Skofja Loka",
      "Trbovlje",
      "Velenje",
    ],
  },
  Somalia: {
    code: "SO",
    cities: [
      "Afgooye",
      "Baardheere",
      "Baidoa",
      "Baki",
      "Balanbale",
      "Bandarbeyla",
      "Beled Hawo",
      "Beledweyne",
      "Berbera",
      "Bereeda",
      "Borama",
      "Bosaso",
      "Burao",
      "Buulobarde",
      "Buurhakaba",
      "Cabudwaaq",
      "Ceeldheer",
      "Ceerigaabo",
      "Dhabad",
      "Diinsoor",
      "Eyl",
      "Gaalkacyo",
      "Garbahaarrey",
      "Garoowe",
      "Gebiley",
      "Hargeysa",
      "Hobyo",
      "Jamaame",
      "Jawhar",
      "Jilib",
      "Kismayo",
      "Laascaanood",
      "Luuq",
      "Marka",
      "Mogadishu",
      "Qandala",
      "Qoryooley",
      "Ruqi",
      "Saaxo",
      "Tog Wajaale",
      "Wanlaweyn",
      "Xuddur",
    ],
  },
  "South Africa": {
    code: "ZA",
    cities: [
      "Adelaide",
      "Alberton",
      "Alexandria",
      "Alice",
      "Aliwal North",
      "Allanridge",
      "Atlantis",
      "Bagale",
      "Balfour",
      "Ballito",
      "Barberton",
      "Barkly East",
      "Barkly West",
      "Beaufort West",
      "Bedford",
      "Bela Bela",
      "Belfast",
      "Benoni",
      "Berea",
      "Bethal",
      "Bethlehem",
      "Bhisho",
      "Bloemfontein",
      "Bloemhof",
      "Bochum",
      "Boksburg",
      "Bontrug",
      "Bothaville",
      "Botshabelo",
      "Brakpan",
      "Brandfort",
      "Bredasdorp",
      "Breyten",
      "Brits",
      "Bronkhorstspruit",
      "Burgersdorp",
      "Butterworth",
      "Cala",
      "Cape Town",
      "Carletonville",
      "Centurion",
      "Ceres",
      "Christiana",
      "Claremont",
      "Clocolan",
      "Cofimvaba",
      "Colesberg",
      "Cradock",
      "Craigieburn",
      "Cullinan",
      "Daggakraal",
      "Danielskuil",
      "De Aar",
      "Delmas",
      "Deneysville",
      "Diepsloot",
      "Dimbaza",
      "Dordrecht",
      "Driefontein",
      "Duiwelskloof",
      "Dundee",
      "Durban",
      "East London",
      "Edenvale",
      "Ekangala",
      "Ekuvukeni",
      "Elliot",
      "Emalahleni",
      "Empangeni",
      "Empuluzu",
      "Engcobo",
      "Ermelo",
      "Eshowe",
      "Fistantkraal",
      "Flagstaff",
      "Fochville",
      "Fort Beaufort",
      "Frischgewaagd",
      "Ga-Kgapane",
      "Ga-Rankuwa",
      "George",
      "Giyani",
      "Glencoe",
      "Gonubie",
      "Graaff Reinet",
      "Grabouw",
      "Grahamstown",
      "Greytown",
      "Hankey",
      "Harrismith",
      "Hazelmere",
      "Heidelberg",
      "Heilbron",
      "Hendrina",
      "Hennenman",
      "Hermanus",
      "Hoopstad",
      "Howick",
      "Humansdorp",
      "Idutywa",
      "Ilinge",
      "Jan Kempdorp",
      "Jeffreys Bay",
      "Johannesburg",
      "Kenton",
      "Kgwaripe",
      "Kimberley",
      "King William's Town",
      "Kirkwood",
      "Klerksdorp",
      "Knysna",
      "Kokstad",
      "Komatipoort",
      "Koppies",
      "Koster",
      "Kraaifontein",
      "Kriel",
      "Kroonstad",
      "Krugersdorp",
      "Kruisfontein",
      "Kutloanong",
      "KwaDukuza",
      "Lady Frere",
      "Ladybrand",
      "Lansdowne",
      "Le Roux",
      "Lebowakgomo",
      "Letlhabile",
      "Lichtenburg",
      "Lindley",
      "Louis Trichardt",
      "Lydenburg",
      "Mabopane",
      "Maclear",
      "Mahikeng",
      "Malmesbury",
      "Mankoeng",
      "Margate",
      "Marquard",
      "Matatiele",
      "Mdantsane",
      "Middelburg",
      "Middelburg",
      "Midrand",
      "Midstream",
      "Mmabatho",
      "Modimolle",
      "Mokopane",
      "Molteno",
      "Mondlo",
      "Montagu",
      "Mooirivier",
      "Mossel Bay",
      "Mount Fletcher",
      "Mount Frere",
      "Mpophomeni",
      "Mpumalanga",
      "Mthatha",
      "Ndevana",
      "Nelspruit",
      "Newcastle",
      "Nigel",
      "Nkowakowa",
      "Nomathamasanqa",
      "Orkney",
      "Oudtshoorn",
      "Paarl",
      "Pampierstad",
      "Parys",
      "Phalaborwa",
      "Phomolong",
      "Phuthaditjhaba",
      "Piet Retief",
      "Pietermaritzburg",
      "Pinetown",
      "Plettenberg Bay",
      "Polokwane",
      "Port Alfred",
      "Port Elizabeth",
      "Port Shepstone",
      "Postmasburg",
      "Potchefstroom",
      "Praktiseer",
      "Pretoria",
      "Prieska",
      "Prince Albert",
      "Queensdale",
      "Queenstown",
      "Randburg",
      "Randfontein",
      "Redhill",
      "Reitz",
      "Rethabiseng",
      "Retreat",
      "Richards Bay",
      "Richmond",
      "Riversdale",
      "Robertson",
      "Rondebosch",
      "Roodepoort",
      "Rustenburg",
      "Saldanha",
      "Sasolburg",
      "Schweizer-Reneke",
      "Scottburgh",
      "Secunda",
      "Senekal",
      "Siyabuswa",
      "Somerset East",
      "Soweto",
      "Springbok",
      "Springs",
      "Standerton",
      "Stellenbosch",
      "Stilfontein",
      "Stutterheim",
      "Sundumbili",
      "Swellendam",
      "Tekwane",
      "Tembisa",
      "Thaba Nchu",
      "Thabazimbi",
      "Theunissen",
      "Thohoyandou",
      "Thubelihle",
      "Thulamahashi",
      "Tshiame",
      "Tshitereke",
      "Tzaneen",
      "Ugie",
      "Uitenhage",
      "Ulundi",
      "Upington",
      "Vaalbank",
      "Vanderbijlpark",
      "Ventersburg",
      "Vereeniging",
      "Viljoenskroon",
      "Villiers",
      "Virginia",
      "Volksrust",
      "Vrede",
      "Vredefort",
      "Vredenburg",
      "Vredendal",
      "Vryburg",
      "Vryheid",
      "Warrenton",
      "Welkom",
      "Wellington",
      "Wesselsbron",
      "Westonaria",
      "White River",
      "Whittlesea",
      "Winburg",
      "Wolmaransstad",
      "Worcester",
      "Zastron",
      "Zeerust",
      "eMbalenhle",
      "eMkhomazi",
      "eMkhondo",
      "eSikhaleni",
    ],
  },
  "South Sudan, The Republic of": {
    code: "SS",
    cities: [
      "Aweil",
      "Bor",
      "Gogrial",
      "Juba",
      "Kafia Kingi",
      "Kuacjok",
      "Leer",
      "Malakal",
      "Maridi",
      "Pajok",
      "Rumbek",
      "Tonj",
      "Torit",
      "Wau",
      "Winejok",
      "Yambio",
      "Yei",
      "Yirol",
    ],
  },
  Spain: {
    code: "ES",
    cities: [
      "A Coruna",
      "A Estrada",
      "Abaran",
      "Abrera",
      "Adeje",
      "Adra",
      "Aguadulce",
      "Aguilar",
      "Aguilas",
      "Aguimes",
      "Alaquas",
      "Albacete",
      "Albal",
      "Albatera",
      "Alberic",
      "Albolote",
      "Alboraya",
      "Albox",
      "Alcala de Guadaira",
      "Alcala de Henares",
      "Alcala del Rio",
      "Alcala la Real",
      "Alcaniz",
      "Alcantarilla",
      "Alcaudete",
      "Alcazar de San Juan",
      "Alcobendas",
      "Alcorcon",
      "Alcoy",
      "Alcudia",
      "Aldaia",
      "Alfafar",
      "Algeciras",
      "Algemesi",
      "Algete",
      "Alginet",
      "Algorta",
      "Alhama de Murcia",
      "Alhaurin de la Torre",
      "Alhaurin el Grande",
      "Alicante",
      "Aljaraque",
      "Almansa",
      "Almassora",
      "Almendralejo",
      "Almeria",
      "Almonte",
      "Almoradi",
      "Almozara",
      "Almunecar",
      "Alora",
      "Alovera",
      "Alpedrete",
      "Altea",
      "Alzira",
      "Amorebieta",
      "Amposta",
      "Amurrio",
      "Andoain",
      "Andratx",
      "Andujar",
      "Antequera",
      "Antigua",
      "Aranda de Duero",
      "Aranjuez",
      "Archena",
      "Arcos de la Frontera",
      "Arenys de Mar",
      "Arganda",
      "Arganzuela",
      "Argentona",
      "Armilla",
      "Arnedo",
      "Arona",
      "Arrasate / Mondragon",
      "Arrecife",
      "Arrigorriaga",
      "Arroyo de la Encomienda",
      "Arroyo de la Miel",
      "Arroyomolinos",
      "Arteixo",
      "Arucas",
      "As Pontes de Garcia Rodriguez",
      "Aspe",
      "Astorga",
      "Atamaria",
      "Atarfe",
      "Atarrabia",
      "Avila",
      "Aviles",
      "Ayamonte",
      "Azkoitia",
      "Azpeitia",
      "Azuqueca de Henares",
      "Badajoz",
      "Badalona",
      "Baena",
      "Baeza",
      "Bailen",
      "Baiona",
      "Balaguer",
      "Banyoles",
      "Barajas de Madrid",
      "Barakaldo",
      "Baranain",
      "Barbastro",
      "Barbate",
      "Barbera del Valles",
      "Barcelona",
      "Barri Gotic",
      "Barri de Sant Andreu",
      "Barri de les Corts",
      "Basauri",
      "Baza",
      "Beasain",
      "Bejar",
      "Bembibre",
      "Benaguasil",
      "Benalmadena",
      "Benavente",
      "Benetusser",
      "Benicarlo",
      "Benicassim",
      "Benidorm",
      "Beniel",
      "Benifaio",
      "Benissa",
      "Berga",
      "Bergara",
      "Berja",
      "Bermeo",
      "Betanzos",
      "Betera",
      "Bilbao",
      "Blanes",
      "Boadilla del Monte",
      "Boiro",
      "Bolanos de Calatrava",
      "Bollullos par del Condado",
      "Bormujos",
      "Brenes",
      "Bueu",
      "Bullas",
      "Burgos",
      "Burjassot",
      "Burlata",
      "Burriana",
      "Cabra",
      "Caceres",
      "Cadiz",
      "Calafell",
      "Calahorra",
      "Calasparra",
      "Calatayud",
      "Caldas de Reis",
      "Caldes de Montbui",
      "Calella",
      "Callosa de Segura",
      "Calonge",
      "Calp",
      "Calvia",
      "Camargo",
      "Camas",
      "Cambados",
      "Cambre",
      "Cambrils",
      "Campina",
      "Campo de Criptana",
      "Canals",
      "Candelaria",
      "Canet de Mar",
      "Cangas del Narcea",
      "Cangas do Morrazo",
      "Canovelles",
      "Cantillana",
      "Capdepera",
      "Carabanchel",
      "Caravaca",
      "Carballo",
      "Carcaixent",
      "Cardedeu",
      "Carlet",
      "Carmona",
      "Carrizal",
      "Cartagena",
      "Cartama",
      "Cartaya",
      "Castalla",
      "Castell-Platja d'Aro",
      "Castellar del Valles",
      "Castellbisbal",
      "Castelldefels",
      "Castello d'Empuries",
      "Castello de la Plana",
      "Castilleja de la Cuesta",
      "Castro-Urdiales",
      "Catarroja",
      "Caudete",
      "Cehegin",
      "Cerdanyola del Valles",
      "Ceuta",
      "Ceuti",
      "Chamartin",
      "Chamberi",
      "Chiclana de la Frontera",
      "Chipiona",
      "Chiva",
      "Churriana de la Vega",
      "Ciempozuelos",
      "Cieza",
      "Ciudad Lineal",
      "Ciudad Real",
      "Ciudad Rodrigo",
      "Ciutadella",
      "Ciutat Meridiana",
      "Ciutat Vella",
      "Cocentaina",
      "Coin",
      "Coll d'en Rabassa",
      "Collado-Villalba",
      "Colmenar Viejo",
      "Conil de la Frontera",
      "Consuegra",
      "Corbera de Llobregat",
      "Cordoba",
      "Coria",
      "Coria del Rio",
      "Cornella de Llobregat",
      "Corralejo",
      "Corvera de Asturias",
      "Coslada",
      "Crevillente",
      "Cubelles",
      "Cuenca",
      "Cuevas del Almanzora",
      "Cullera",
      "Culleredo",
      "Cunit",
      "Daimiel",
      "Delicias",
      "Deltebre",
      "Denia",
      "Diagonal Mar",
      "Don Benito",
      "Dos Hermanas",
      "Dreta de l'Eixample",
      "Durango",
      "Ecija",
      "Eibar",
      "Eixample",
      "Ejea de los Caballeros",
      "El Arahal",
      "El Astillero",
      "El Campello",
      "El Carmel",
      "El Casar",
      "El Ejido",
      "El Escorial",
      "El Grao",
      "El Masnou",
      "El Prat de Llobregat",
      "El Puerto de Santa Maria",
      "El Vendrell",
      "El Viso del Alcor",
      "Elche",
      "Elda",
      "Elgoibar",
      "Erandio",
      "Ermitagana",
      "Ermua",
      "Errenteria",
      "Esparreguera",
      "Espartinas",
      "Esplugues de Llobregat",
      "Estella-Lizarra",
      "Estepa",
      "Estepona",
      "Felanitx",
      "Fene",
      "Ferrol",
      "Figueres",
      "Fort Pienc",
      "Fraga",
      "Fuencarral-El Pardo",
      "Fuengirola",
      "Fuenlabrada",
      "Fuensalida",
      "Fuente Palmera",
      "Fuenterrabia",
      "Galapagar",
      "Galdakao",
      "Galdar",
      "Gamonal",
      "Gandia",
      "Gasteiz / Vitoria",
      "Gava",
      "Gernika-Lumo",
      "Getafe",
      "Getxo",
      "Gibraleon",
      "Gijon",
      "Gines",
      "Girona",
      "Godella",
      "Gondomar",
      "Gracia",
      "Granada",
      "Granadilla de Abona",
      "Granollers",
      "Grao de Murviedro",
      "Guadalajara",
      "Guadarrama",
      "Guadix",
      "Guardamar del Segura",
      "Guia",
      "Guia de Isora",
      "Guillena",
      "Guimar",
      "Haro",
      "Hellin",
      "Hernani",
      "Horta",
      "Horta-Guinardo",
      "Hortaleza",
      "Hostafrancs",
      "Huelva",
      "Huercal de Almeria",
      "Huercal-Overa",
      "Huesca",
      "Huetor Vega",
      "Humanes de Madrid",
      "Ibi",
      "Ibiza",
      "Icod de los Vinos",
      "Igualada",
      "Illescas",
      "Illora",
      "Inca",
      "Ingenio",
      "Irun",
      "Isla Cristina",
      "Iturrama",
      "Jaca",
      "Jaen",
      "Javea",
      "Jerez de la Frontera",
      "Jerez de los Caballeros",
      "Jimena de la Frontera",
      "Jodar",
      "Jumilla",
      "L'Alcudia",
      "L'Eliana",
      "L'Hospitalet de Llobregat",
      "La Algaba",
      "La Baneza",
      "La Bonanova",
      "La Bordeta",
      "La Carlota",
      "La Carolina",
      "La Laguna",
      "La Linea de la Concepcion",
      "La Llagosta",
      "La Oliva",
      "La Orotava",
      "La Palma del Condado",
      "La Pineda",
      "La Pobla de Vallbona",
      "La Puebla de Cazalla",
      "La Puebla del Rio",
      "La Rinconada",
      "La Roca del Valles",
      "La Roda",
      "La Sagrera",
      "La Seu d'Urgell",
      "La Solana",
      "La Union",
      "Laguna de Duero",
      "Lalin",
      "Laracha",
      "Laredo",
      "Las Cabezas de San Juan",
      "Las Gabias",
      "Las Matas",
      "Las Palmas de Gran Canaria",
      "Las Rozas de Madrid",
      "Las Torres de Cotillas",
      "Las Tres Torres",
      "Lasarte",
      "Latina",
      "Lebrija",
      "Leganes",
      "Leioa",
      "Leon",
      "Lepe",
      "Les Corts",
      "Linares",
      "Llanera",
      "Llanes",
      "Llefia",
      "Lleida",
      "Llica d'Amunt",
      "Lliria",
      "Llodio",
      "Lloret de Mar",
      "Llucmajor",
      "Logrono",
      "Loja",
      "Lora del Rio",
      "Lorca",
      "Los Alcazares",
      "Los Barrios",
      "Los Corrales de Buelna",
      "Los Llanos de Aridane",
      "Los Palacios y Villafranca",
      "Los Realejos",
      "Luanco",
      "Lucena",
      "Lugo",
      "Lugones",
      "Madrid",
      "Madrid Centro",
      "Madridejos",
      "Mairena del Alcor",
      "Mairena del Aljarafe",
      "Majadahonda",
      "Malaga",
      "Malgrat de Mar",
      "Manacor",
      "Mancha Real",
      "Manilva",
      "Manises",
      "Manlleu",
      "Manresa",
      "Manzanares",
      "Mao",
      "Maracena",
      "Marbella",
      "Marchena",
      "Marin",
      "Marratxi",
      "Martorell",
      "Martos",
      "Maspalomas",
      "Massamagrell",
      "Mataro",
      "Mazarron",
      "Meco",
      "Medina Sidonia",
      "Medina del Campo",
      "Mejorada del Campo",
      "Meliana",
      "Melilla",
      "Merida",
      "Miajadas",
      "Mieres",
      "Miguelturra",
      "Mijas",
      "Miranda de Ebro",
      "Mislata",
      "Moana",
      "Mogan",
      "Moguer",
      "Molina de Segura",
      "Molins de Rei",
      "Mollerussa",
      "Mollet del Valles",
      "Moncada",
      "Moncloa-Aravaca",
      "Monforte de Lemos",
      "Monovar",
      "Mont-roig del Camp",
      "Montcada i Reixac",
      "Montecanal",
      "Montgat",
      "Montijo",
      "Montilla",
      "Montornes del Valles",
      "Monzon",
      "Mora",
      "Moralzarzal",
      "Moratalaz",
      "Moron de la Frontera",
      "Mos",
      "Mostoles",
      "Motril",
      "Mula",
      "Mungia",
      "Murcia",
      "Mutxamel",
      "Naron",
      "Natahoyo",
      "Navalcarnero",
      "Navalmoral de la Mata",
      "Navas",
      "Nerja",
      "Nigran",
      "Nijar",
      "Nou Barris",
      "Novelda",
      "Nules",
      "O Barco de Valdeorras",
      "O Carballino",
      "O Grove",
      "Ogijares",
      "Oleiros",
      "Olesa de Montserrat",
      "Oliva",
      "Olivenza",
      "Oliver-Valdefierro",
      "Olot",
      "Onate",
      "Onda",
      "Ontinyent",
      "Ordes",
      "Oria",
      "Orihuela",
      "Osuna",
      "Ourense",
      "Oviedo",
      "Paiporta",
      "Pajara",
      "Palafrugell",
      "Palamos",
      "Palencia",
      "Palleja",
      "Palma",
      "Palma del Rio",
      "Palos de la Frontera",
      "Pamplona",
      "Paracuellos de Jarama",
      "Parets del Valles",
      "Parla",
      "Pasaia",
      "Paterna",
      "Pedralbes",
      "Pego",
      "Peligros",
      "Penarroya-Pueblonuevo",
      "Picanya",
      "Picassent",
      "Piera",
      "Pilar de la Horadada",
      "Pilas",
      "Pinar de Chamartin",
      "Pineda de Mar",
      "Pinos Puente",
      "Pinto",
      "Plasencia",
      "Playa del Ingles",
      "Poble Sec",
      "Poio",
      "Pola de Lena",
      "Pola de Siero",
      "Pollenca",
      "Ponferrada",
      "Ponteareas",
      "Pontevedra",
      "Porrino",
      "Porta",
      "Portugalete",
      "Pozoblanco",
      "Pozuelo de Alarcon",
      "Premia de Dalt",
      "Premia de Mar",
      "Priego de Cordoba",
      "Primer Ensanche",
      "Provenals del Poblenou",
      "Pucol",
      "Puente de Vallecas",
      "Puente-Genil",
      "Puerto Lumbreras",
      "Puerto Real",
      "Puerto Rico",
      "Puerto de la Cruz",
      "Puerto del Carmen",
      "Puerto del Rosario",
      "Puertollano",
      "Punta Umbria",
      "Quart de Poblet",
      "Quintanar de la Orden",
      "Realejo Alto",
      "Redondela",
      "Reinosa",
      "Requena",
      "Retiro",
      "Reus",
      "Rianxo",
      "Ribarroja del Turia",
      "Ribeira",
      "Rincon de la Victoria",
      "Ripoll",
      "Ripollet",
      "Rivas-Vaciamadrid",
      "Rojales",
      "Ronda",
      "Roquetas de Mar",
      "Roses",
      "Rota",
      "Rubi",
      "Rute",
      "Sabadell",
      "Sabinanigo",
      "Sada",
      "Sagrada Familia",
      "Sagunto",
      "Salamanca",
      "Salamanca",
      "Salobrena",
      "Salou",
      "Salt",
      "Sama",
      "San Agustin del Guadalix",
      "San Andres del Rabanedo",
      "San Bartolome",
      "San Bartolome de Tirajana",
      "San Blas-Canillejas",
      "San Fernando",
      "San Fernando de Henares",
      "San Isidro",
      "San Javier",
      "San Juan de Aznalfarache",
      "San Lorenzo de El Escorial",
      "San Martin de la Vega",
      "San Miguel De Abona",
      "San Pedro Alcantara",
      "San Pedro del Pinatar",
      "San Roque",
      "San Sebastian",
      "San Sebastian de los Reyes",
      "San Vicent del Raspeig",
      "Sanlucar de Barrameda",
      "Sanlucar la Mayor",
      "Sant Adria de Besos",
      "Sant Andreu",
      "Sant Andreu de Llavaneres",
      "Sant Andreu de la Barca",
      "Sant Antoni",
      "Sant Antoni de Portmany",
      "Sant Boi de Llobregat",
      "Sant Carles de la Rapita",
      "Sant Celoni",
      "Sant Cugat del Valles",
      "Sant Feliu de Guixols",
      "Sant Feliu de Llobregat",
      "Sant Gervasi - Galvany",
      "Sant Joan Despi",
      "Sant Joan d'Alacant",
      "Sant Joan de Vilatorrada",
      "Sant Just Desvern",
      "Sant Marti",
      "Sant Marti de Provencals",
      "Sant Pere de Ribes",
      "Sant Pere, Santa Caterina i La Ribera",
      "Sant Quirze del Valles",
      "Sant Sadurni d'Anoia",
      "Sant Vicenc dels Horts",
      "Santa Brigida",
      "Santa Coloma de Farners",
      "Santa Coloma de Gramenet",
      "Santa Comba",
      "Santa Cruz de Bezana",
      "Santa Cruz de Tenerife",
      "Santa Cruz de la Palma",
      "Santa Eularia des Riu",
      "Santa Lucia",
      "Santa Margalida",
      "Santa Marta de Tormes",
      "Santa Perpetua de Mogoda",
      "Santa Pola",
      "Santa Ponsa",
      "Santa Ursula",
      "Santa Uxia de Ribeira",
      "Santafe",
      "Santander",
      "Santanyi",
      "Santiago de Compostela",
      "Santiago del Teide",
      "Santomera",
      "Santona",
      "Sants",
      "Sants - Badal",
      "Sants-Montjuic",
      "Santurtzi",
      "Santutxu",
      "Sanxenxo",
      "Sarria",
      "Sarria",
      "Sarria-Sant Gervasi",
      "Sarriguren",
      "Sax",
      "Segovia",
      "Segundo Ensanche",
      "Sesena",
      "Sestao",
      "Sevilla",
      "Silla",
      "Sitges",
      "Socuellamos",
      "Soller",
      "Son Servera",
      "Sonseca",
      "Sopela",
      "Soria",
      "Sueca",
      "Tacoronte",
      "Tafalla",
      "Talavera de la Reina",
      "Tarancon",
      "Tarazona",
      "Tarifa",
      "Tarragona",
      "Tarrega",
      "Tavernes de la Valldigna",
      "Tegueste",
      "Teguise",
      "Telde",
      "Teo",
      "Teror",
      "Terrassa",
      "Teruel",
      "Tetuan de las Victorias",
      "Teulada",
      "Tias",
      "Tineo",
      "Toledo",
      "Tolosa",
      "Tomares",
      "Tomelloso",
      "Tomino",
      "Tordera",
      "Torello",
      "Torre del Campo",
      "Torre del Mar",
      "Torre-Pacheco",
      "Torredembarra",
      "Torredonjimeno",
      "Torrejon de Ardoz",
      "Torrelavega",
      "Torrelodones",
      "Torremolinos",
      "Torrent",
      "Torrevieja",
      "Torrijos",
      "Torroella de Montgri",
      "Torrox",
      "Tortosa",
      "Totana",
      "Tres Cantos",
      "Tudela",
      "Tui",
      "Tuineje",
      "Ubeda",
      "Ubrique",
      "Usera",
      "Utebo",
      "Utiel",
      "Utrera",
      "Valdemorillo",
      "Valdemoro",
      "Valdepenas",
      "Valdes",
      "Valencia",
      "Valladolid",
      "Vallcarca",
      "Vallirana",
      "Valls",
      "Valverde del Camino",
      "Vecindario",
      "Vejer de la Frontera",
      "Velez-Malaga",
      "Velilla de San Antonio",
      "Vera",
      "Verdun",
      "Verin",
      "Vic",
      "Vicalvaro",
      "Vicar",
      "Vigo",
      "Vila-real",
      "Vila-seca",
      "Viladecans",
      "Vilafranca del Penedes",
      "Vilagarcia de Arousa",
      "Vilalba",
      "Vilanova de Arousa",
      "Vilanova del Cami",
      "Vilanova i la Geltru",
      "Vilapicina i la Torre Llobeta",
      "Vilaseca",
      "Vilassar de Mar",
      "Villa de Vallecas",
      "Villacanas",
      "Villacarrillo",
      "Villafranca de los Barros",
      "Villajoyosa",
      "Villamartin",
      "Villanueva de Cordoba",
      "Villanueva de la Canada",
      "Villanueva de la Serena",
      "Villanueva del Pardillo",
      "Villaquilambre",
      "Villarrobledo",
      "Villarrubia de los Ojos",
      "Villaverde",
      "Villaviciosa",
      "Villaviciosa de Odon",
      "Villena",
      "Vinaros",
      "Viveiro",
      "Xativa",
      "Xinzo de Limia",
      "Xirivella",
      "Yecla",
      "Zafra",
      "Zamora",
      "Zaragoza",
      "Zarautz",
      "Zizur Mayor",
      "Zubia",
      "Zumarraga",
      "el Baix Guinardo",
      "el Besos i el Maresme",
      "el Bon Pastor",
      "el Camp d'en Grassot i Gracia Nova",
      "el Camp de l'Arpa del Clot",
      "el Clot",
      "el Congres i els Indians",
      "el Guinardo",
      "el Parc i la Llacuna del Poblenou",
      "el Poblenou",
      "el Putxet i el Farro",
      "el Raval",
      "el Turo de la Peira",
      "l'Alcora",
      "l'Alfas del Pi",
      "l'Antiga Esquerra de l'Eixample",
      "l'Escala",
      "la Barceloneta",
      "la Bisbal d'Emporda",
      "la Font de la Guatlla",
      "la Garriga",
      "la Guineueta",
      "la Marina de Port",
      "la Maternitat i Sant Ramon",
      "la Nova Esquerra de l'Eixample",
      "la Nucia",
      "la Prosperitat",
      "la Salut",
      "la Teixonera",
      "la Trinitat Vella",
      "la Verneda i la Pau",
      "la Vila de Gracia",
      "les Roquetes",
      "s'Arenal",
      "sa Pobla",
    ],
  },
  "Sri Lanka": {
    code: "LK",
    cities: [
      "Ambalangoda",
      "Ampara",
      "Anuradhapura",
      "Badulla",
      "Battaramulla South",
      "Batticaloa",
      "Bentota",
      "Beruwala",
      "Chilaw",
      "Colombo",
      "Dambulla",
      "Dehiwala-Mount Lavinia",
      "Devinuwara",
      "Eravur Town",
      "Galle",
      "Gampola",
      "Hanwella Ihala",
      "Hatton",
      "Hendala",
      "Homagama",
      "Ja Ela",
      "Jaffna",
      "Kalmunai",
      "Kalutara",
      "Kandana",
      "Kandy",
      "Kataragama",
      "Katunayaka",
      "Kegalle",
      "Kelaniya",
      "Kilinochchi",
      "Kolonnawa",
      "Kotikawatta",
      "Kurunegala",
      "Maharagama",
      "Matale",
      "Matara",
      "Monaragala",
      "Moratuwa",
      "Mulleriyawa",
      "Negombo",
      "Nuwara Eliya",
      "Panadura",
      "Peliyagoda",
      "Pita Kotte",
      "Point Pedro",
      "Polonnaruwa",
      "Puttalam",
      "Ratnapura",
      "Sri Jayewardenepura Kotte",
      "Tangalle",
      "Trincomalee",
      "Vakarai",
      "Valvedditturai",
      "Vavuniya",
      "Wattala",
      "Weligama",
      "Welisara",
    ],
  },
  "Sudan, The Republic of": {
    code: "SD",
    cities: [
      "Abu Jibeha",
      "Abu Zabad",
      "Ad Dabbah",
      "Ad Dindar",
      "Ad Douiem",
      "Ad-Damazin",
      "Al Fulah",
      "Al Hasaheisa",
      "Al Hawatah",
      "Al Hilaliyya",
      "Al Kawa",
      "Al Lagowa",
      "Al Manaqil",
      "Al Mijlad",
      "Al Qadarif",
      "Al Qitena",
      "An Nuhud",
      "Ar Rahad",
      "Ar Ruseris",
      "Aroma",
      "As Suki",
      "Atbara",
      "Barah",
      "Berber",
      "Dilling",
      "Doka",
      "Dongola",
      "Ed Damer",
      "El Bauga",
      "El Daein",
      "El Fasher",
      "El Matama",
      "El Obeid",
      "Gebeit",
      "Geneina",
      "Gereida",
      "Kadugli",
      "Kassala",
      "Khartoum",
      "Kinana",
      "Kosti",
      "Kuraymah",
      "Kurmuk",
      "Maiurno",
      "Marabba",
      "Merowe",
      "Nyala",
      "Omdurman",
      "Port Sudan",
      "Rabak",
      "Sawakin",
      "Shendi",
      "Singa",
      "Sinnar",
      "Talodi",
      "Tandalti",
      "Tokar",
      "Umm Kaddadah",
      "Umm Ruwaba",
      "Wad Medani",
      "Wad Rawah",
      "Wagar",
      "Zalingei",
    ],
  },
  Suriname: {
    code: "SR",
    cities: [
      "Brokopondo",
      "Lelydorp",
      "Meerzorg",
      "Nieuw Nickerie",
      "Paramaribo",
    ],
  },
  Swaziland: {
    code: "SZ",
    cities: ["Big Bend", "Manzini", "Mbabane"],
  },
  Sweden: {
    code: "SE",
    cities: [
      "AEngelholm",
      "Akersberga",
      "Alby",
      "Alingsas",
      "Arboga",
      "Arsta",
      "Arvika",
      "Avesta",
      "Balsta",
      "Billdal",
      "Bjoerlanda",
      "Bjuv",
      "Boden",
      "Bollnaes",
      "Boo",
      "Boras",
      "Borlaenge",
      "Bromma",
      "Bunkeflostrand",
      "Ekeroe",
      "Eksjoe",
      "Enkoeping",
      "Eskilstuna",
      "Esloev",
      "Fagersta",
      "Falkenberg",
      "Falkoeping",
      "Falun",
      "Finspang",
      "Gaellivare",
      "Gaevle",
      "Gamla Uppsala",
      "Gislaved",
      "Goeteborg",
      "Gustavsberg",
      "Haernoesand",
      "Haessleholm",
      "Hallstahammar",
      "Halmstad",
      "Handen",
      "Haninge",
      "Helsingborg",
      "Hoeganaes",
      "Hoellviken",
      "Hoeoer",
      "Huddinge",
      "Hudiksvall",
      "Huskvarna",
      "Jakobsberg",
      "Joenkoeping",
      "Jordbro",
      "Kalmar",
      "Karlshamn",
      "Karlskoga",
      "Karlskrona",
      "Karlstad",
      "Katrineholm",
      "Kinna",
      "Kiruna",
      "Kista",
      "Koeping",
      "Kristianstad",
      "Kristinehamn",
      "Kumla",
      "Kungaelv",
      "Kungsaengen",
      "Kungsbacka",
      "Kungsholmen",
      "Landskrona",
      "Lerum",
      "Lidingoe",
      "Lidkoeping",
      "Lindome",
      "Linkoeping",
      "Ljungby",
      "Lomma",
      "Ludvika",
      "Lulea",
      "Lund",
      "Maersta",
      "Majorna",
      "Malmoe",
      "Mariestad",
      "Mjoelby",
      "Moelndal",
      "Moelnlycke",
      "Mora",
      "Motala",
      "Nacka",
      "Naessjoe",
      "Norrkoeping",
      "Norrtaelje",
      "Nybro",
      "Nykoeping",
      "Nynaeshamn",
      "OErebro",
      "OErnskoeldsvik",
      "OEstermalm",
      "OEstersund",
      "Onsala",
      "Oskarshamn",
      "Oxeloesund",
      "Oxie",
      "Partille",
      "Pitea",
      "Rasunda",
      "Ronneby",
      "Sala",
      "Sandviken",
      "Segeltorp",
      "Skara",
      "Skelleftea",
      "Skoevde",
      "Skoghall",
      "Soederhamn",
      "Soedermalm",
      "Soedertaelje",
      "Sollentuna",
      "Solna",
      "Staffanstorp",
      "Stenungsund",
      "Stockholm",
      "Straengnaes",
      "Sundbyberg",
      "Sundsvall",
      "Svedala",
      "Taby",
      "Tensta",
      "Timra",
      "Torslanda",
      "Tranas",
      "Trelleborg",
      "Trollhaettan",
      "Tullinge",
      "Tumba",
      "Uddevalla",
      "Ulricehamn",
      "Umea",
      "Upplands Vaesby",
      "Uppsala",
      "Vaenersborg",
      "Vaernamo",
      "Vaesteras",
      "Vaestervik",
      "Vaestra Froelunda",
      "Vaexjoe",
      "Vallentuna",
      "Varberg",
      "Varby",
      "Vasastan",
      "Vetlanda",
      "Visby",
      "Ystad",
    ],
  },
  Switzerland: {
    code: "CH",
    cities: [
      "Aarau",
      "Adliswil",
      "Aesch",
      "Allschwil",
      "Altstatten",
      "Amriswil",
      "Arbon",
      "Baar",
      "Baden",
      "Basel",
      "Bellinzona",
      "Bern",
      "Biel/Bienne",
      "Binningen",
      "Birsfelden",
      "Buchs",
      "Bulach",
      "Bulle",
      "Burgdorf",
      "Carouge",
      "Cham",
      "Chene-Bougeries",
      "Chur",
      "Crans-Montana",
      "Davos",
      "Delemont",
      "Dietikon",
      "Dubendorf",
      "Ebikon",
      "Ecublens",
      "Ecublens",
      "Effretikon",
      "Einsiedeln",
      "Emmen",
      "Frauenfeld",
      "Freienbach",
      "Fribourg",
      "Geneve",
      "Gland",
      "Gossau",
      "Grenchen",
      "Herisau",
      "Horgen",
      "Horw",
      "Jona",
      "Kloten",
      "Koeniz",
      "Kreuzlingen",
      "Kriens",
      "Kuesnacht",
      "Kussnacht",
      "La Chaux-de-Fonds",
      "La Tour-de-Peilz",
      "Lancy",
      "Langenthal",
      "Lausanne",
      "Le Locle",
      "Liestal",
      "Littau",
      "Locarno",
      "Lugano",
      "Luzern",
      "Lyss",
      "Martigny-Ville",
      "Mattenbach (Kreis 7)",
      "Meyrin",
      "Monthey",
      "Montreux",
      "Morges",
      "Munchenstein",
      "Munsingen",
      "Muri",
      "Muttenz",
      "Neuchatel",
      "Neuhausen",
      "Nyon",
      "Oberwil",
      "Oberwinterthur (Kreis 2)",
      "Oftringen",
      "Olten",
      "Onex",
      "Plan-les-Ouates",
      "Pratteln",
      "Prilly",
      "Pully",
      "Rapperswil",
      "Renens",
      "Rheinfelden",
      "Richterswil",
      "Riehen",
      "Rotkreuz",
      "Sankt Gallen",
      "Schaffhausen",
      "Schlieren",
      "Schwyz",
      "Seen (Kreis 3)",
      "Sierre",
      "Sitten",
      "Solothurn",
      "Spiez",
      "Spreitenbach",
      "Stadt Winterthur (Kreis 1)",
      "Steffisburg",
      "Thalwil",
      "Thonex",
      "Thun",
      "Uster",
      "Uster / Kirch-Uster",
      "Uzwil",
      "Vernier",
      "Versoix",
      "Vevey",
      "Villars-sur-Glane",
      "Waedenswil",
      "Wallisellen",
      "Wettingen",
      "Wetzikon",
      "Wil",
      "Winterthur",
      "Wohlen",
      "Worb",
      "Wuelflingen (Kreis 6)",
      "Yverdon-les-Bains",
      "Zofingen",
      "Zuerich (Kreis 10)",
      "Zuerich (Kreis 10) / Hoengg",
      "Zuerich (Kreis 10) / Wipkingen",
      "Zuerich (Kreis 11)",
      "Zuerich (Kreis 11) / Affoltern",
      "Zuerich (Kreis 11) / Oerlikon",
      "Zuerich (Kreis 11) / Seebach",
      "Zuerich (Kreis 12)",
      "Zuerich (Kreis 12) / Hirzenbach",
      "Zuerich (Kreis 12) / Schwamendingen-Mitte",
      "Zuerich (Kreis 2)",
      "Zuerich (Kreis 2) / Wollishofen",
      "Zuerich (Kreis 3)",
      "Zuerich (Kreis 3) / Alt-Wiedikon",
      "Zuerich (Kreis 3) / Sihlfeld",
      "Zuerich (Kreis 4) / Aussersihl",
      "Zuerich (Kreis 4) / Hard",
      "Zuerich (Kreis 4) / Langstrasse",
      "Zuerich (Kreis 5)",
      "Zuerich (Kreis 6)",
      "Zuerich (Kreis 6) / Unterstrass",
      "Zuerich (Kreis 7)",
      "Zuerich (Kreis 8)",
      "Zuerich (Kreis 9)",
      "Zuerich (Kreis 9) / Albisrieden",
      "Zuerich (Kreis 9) / Altstetten",
      "Zug",
      "Zurich",
    ],
  },
  "Syrian Arab Republic": {
    code: "SY",
    cities: [
      "'Amuda",
      "'Ayn al 'Arab",
      "Ad Dana",
      "Ad Darbasiyah",
      "Ad Duraykish",
      "Al Bab",
      "Al Hasakah",
      "Al Hirak",
      "Al Kiswah",
      "Al Mayadin",
      "Al Qaryatayn",
      "Al Qunaytirah",
      "Al Qusayr",
      "Al Qutayfah",
      "Albu Kamal",
      "Aleppo",
      "An Nabk",
      "Ar Raqqah",
      "Ar Rastan",
      "As Safirah",
      "As Salamiyah",
      "As Sanamayn",
      "As-Suwayda",
      "At Tall",
      "Ath Thawrah",
      "Az Zabadani",
      "Baniyas",
      "Batabo",
      "Binnish",
      "Damascus",
      "Dar'a",
      "Darayya",
      "Dayr Hafir",
      "Deir ez-Zor",
      "Duma",
      "Hajin",
      "Halfaya",
      "Hamah",
      "Harasta",
      "Homs",
      "I`zaz",
      "Idlib",
      "Inkhil",
      "Izra`",
      "Jablah",
      "Jarabulus",
      "Jasim",
      "Jayrud",
      "Jisr ash Shughur",
      "Kabbasin",
      "Kafr Laha",
      "Kafr Takharim",
      "Kafr Zayta",
      "Kafranbel",
      "Khan Shaykhun",
      "Latakia",
      "Ma`arratmisrin",
      "Manbij",
      "Masyaf",
      "Murak",
      "Nubl",
      "Qabbasin",
      "Qarah",
      "Qatana",
      "Safita",
      "Salakhid",
      "Salqin",
      "Saraqib",
      "Shahba",
      "Shaykh Miskin",
      "Subaykhan",
      "Suran",
      "Tadif",
      "Tadmur",
      "Tafas",
      "Tall Rif`at",
      "Tallbisah",
      "Tallkalakh",
      "Tartus",
      "Tayyibat al Imam",
      "Yabrud",
      "`Afrin",
      "`Irbin",
    ],
  },
  "Taiwan, China": {
    code: "TW",
    cities: [
      "Bade",
      "Banqiao",
      "Daxi",
      "Donggang",
      "Douliu",
      "Hengchun",
      "Hsinchu",
      "Hualien City",
      "Jincheng",
      "Kaohsiung",
      "Keelung",
      "Lugu",
      "Magong",
      "Nantou",
      "Neihu",
      "Puli",
      "Sanxia",
      "Sanzhi",
      "Shimen",
      "Shulin",
      "Taichung",
      "Tainan",
      "Taipei",
      "Taoyuan City",
      "Wufeng",
      "Xizhi",
      "Yilan",
      "Yingge",
      "Yongkang",
      "Yuanlin",
      "Yujing",
      "Zhongxing New Village",
    ],
  },
  Tajikistan: {
    code: "TJ",
    cities: [
      "Adrasmon",
      "Avzikent",
      "Bokhtar",
      "Boshkengash",
      "Buston",
      "Buston",
      "Chubek",
      "Danghara",
      "Dushanbe",
      "Farkhor",
      "Hisor",
      "Hulbuk",
      "Isfara",
      "Ishqoshim",
      "Istaravshan",
      "Khorugh",
      "Khujand",
      "Kolkhozobod",
      "Konibodom",
      "Kulob",
      "Moskovskiy",
      "Mu'minobod",
      "Murghob",
      "Norak",
      "Nov",
      "Panjakent",
      "Proletar",
      "Qayroqqum",
      "Rasht",
      "Shahritus",
      "Shaydon",
      "Taboshar",
      "Tursunzoda",
      "Vahdat",
      "Vakhsh",
      "Yovon",
    ],
  },
  "Tanzania, United Republic of": {
    code: "TZ",
    cities: [
      "Arusha",
      "Babati",
      "Bagamoyo",
      "Bariadi",
      "Bashanet",
      "Basotu",
      "Biharamulo",
      "Bugarama",
      "Bugene",
      "Bukoba",
      "Bunda",
      "Bungu",
      "Buseresere",
      "Butiama",
      "Chake Chake",
      "Chala",
      "Chalinze",
      "Chanika",
      "Chato",
      "Chimala",
      "Chiungutwa",
      "Dar es Salaam",
      "Dareda",
      "Dodoma",
      "Dongobesh",
      "Dunda",
      "Endasak",
      "Galappo",
      "Geiro",
      "Geita",
      "Hedaru",
      "Ifakara",
      "Igugunu",
      "Igunga",
      "Igurubi",
      "Igurusi",
      "Ikungi",
      "Ikwiriri",
      "Ilembula",
      "Ilongero",
      "Ilula",
      "Inyonga",
      "Ipinda",
      "Iringa",
      "Isaka",
      "Itigi",
      "Itumba",
      "Izazi",
      "Kabanga",
      "Kahama",
      "Kakonko",
      "Kaliua",
      "Kamachumu",
      "Kandete",
      "Karema",
      "Kasamwa",
      "Kasulu",
      "Katerero",
      "Katoro",
      "Katoro",
      "Katumba",
      "Kibaha",
      "Kibakwe",
      "Kibara",
      "Kibiti",
      "Kibondo",
      "Kidatu",
      "Kidodi",
      "Kigoma",
      "Kigonsera",
      "Kihangara",
      "Kilindoni",
      "Kilosa",
      "Kimamba",
      "Kingori",
      "Kiomboi",
      "Kirando",
      "Kiratu",
      "Kisanga",
      "Kisarawe",
      "Kisesa",
      "Kishapu",
      "Kitama",
      "Kitangari",
      "Kiwira",
      "Kondoa",
      "Kongwa",
      "Kyaka",
      "Kyela",
      "Laela",
      "Lalago",
      "Lembeni",
      "Lindi",
      "Liuli",
      "Liwale",
      "Luchingu",
      "Lugoba",
      "Lukuledi",
      "Lupiro",
      "Lushoto",
      "Mabama",
      "Mabamba",
      "Madimba",
      "Mafinga",
      "Magole",
      "Magomeni",
      "Magugu",
      "Mahanje",
      "Mahuta",
      "Makumbako",
      "Makungu",
      "Makuyuni",
      "Malampaka",
      "Malinyi",
      "Malya",
      "Maposeni",
      "Maramba",
      "Masasi",
      "Masoko",
      "Masumbwe",
      "Maswa",
      "Matai",
      "Matamba",
      "Matiri",
      "Matonga",
      "Matui",
      "Mazinde",
      "Mbekenyera",
      "Mbeya",
      "Mbinga",
      "Mbuguni",
      "Mbulu",
      "Merelani",
      "Mgandu",
      "Mhango",
      "Mikumi",
      "Mingoyo",
      "Misasi",
      "Misungwi",
      "Mkuranga",
      "Mlalo",
      "Mlandizi",
      "Mlangali",
      "Mlimba",
      "Mlowo",
      "Morogoro",
      "Moshi",
      "Mpanda",
      "Mpwapwa",
      "Msowero",
      "Mtama",
      "Mtimbira",
      "Mtinko",
      "Mto wa Mbu",
      "Mtwango",
      "Mtwara",
      "Mugango",
      "Mugumu",
      "Muheza",
      "Muleba",
      "Mungaa",
      "Muriti",
      "Musoma",
      "Mvomero",
      "Mwadui",
      "Mwandiga",
      "Mwanga",
      "Mwanza",
      "Naberera",
      "Nachingwea",
      "Namalenga",
      "Namanga",
      "Namanyere",
      "Namikupa",
      "Nanganga",
      "Nangomba",
      "Nangwa",
      "Nanhyanga",
      "Nanyamba",
      "Ndago",
      "Ndungu",
      "Newala Kisimani",
      "Ngara",
      "Ngerengere",
      "Ngorongoro",
      "Ngudu",
      "Nguruka",
      "Njombe",
      "Nkoaranga",
      "Nshamba",
      "Nsunga",
      "Nyakabindi",
      "Nyakahanga",
      "Nyalikungu",
      "Nyamuswa",
      "Nyangao",
      "Nyanguge",
      "Nzega",
      "Old Shinyanga",
      "Puma",
      "Ruangwa",
      "Rujewa",
      "Rulenge",
      "Same",
      "Sepuka",
      "Shelui",
      "Shinyanga",
      "Sikonge",
      "Singida",
      "Sirari",
      "Sokoni",
      "Somanda",
      "Songea",
      "Songwa",
      "Soni",
      "Sumbawanga",
      "Tabora",
      "Tandahimba",
      "Tanga",
      "Tarime",
      "Tinde",
      "Tingi",
      "Tukuyu",
      "Tumbi",
      "Tunduma",
      "Ulenje",
      "Urambo",
      "Usa River",
      "Usagara",
      "Usevia",
      "Ushirombo",
      "Utete",
      "Uvinza",
      "Uyovu",
      "Vikindu",
      "Vwawa",
      "Wete",
      "Zanzibar",
    ],
  },
  Thailand: {
    code: "TH",
    cities: [
      "Amnat Charoen",
      "Amphoe Sikhiu",
      "Ang Thong",
      "Aranyaprathet",
      "Bamnet Narong",
      "Ban Bang Kadi Pathum Thani",
      "Ban Bueng",
      "Ban Chalong",
      "Ban Chang",
      "Ban Dung",
      "Ban Huai Thalaeng",
      "Ban Khlong Bang Sao Thong",
      "Ban Ko Kaeo",
      "Ban Lam Luk Ka",
      "Ban Mai",
      "Ban Mo",
      "Ban Na",
      "Ban Na San",
      "Ban Nam Yuen",
      "Ban Nong Wua So",
      "Ban Phaeo",
      "Ban Phai",
      "Ban Phan Don",
      "Ban Phe",
      "Ban Pong",
      "Ban Ratsada",
      "Ban Sam Chuk",
      "Ban Selaphum",
      "Ban Su-ngai Pa Di",
      "Ban Tak",
      "Ban Talat Bueng",
      "Ban Talat Nua",
      "Ban Talat Yai",
      "Ban Thai Tan",
      "Bang Ban",
      "Bang Bo District",
      "Bang Bua Thong",
      "Bang Khla",
      "Bang Krathum",
      "Bang Kruai",
      "Bang Lamung",
      "Bang Len",
      "Bang Mun Nak",
      "Bang Pa-in",
      "Bang Pakong",
      "Bang Phae",
      "Bang Racham",
      "Bang Rakam",
      "Bang Saphan",
      "Bang Yai",
      "Bangkok",
      "Betong",
      "Bo Phloi",
      "Bo Rai",
      "Bua Yai",
      "Buri Ram",
      "Cha-am",
      "Chachoengsao",
      "Chai Badan",
      "Chai Nat",
      "Chai Wari",
      "Chaiya",
      "Chaiyaphum",
      "Chaiyo",
      "Chanthaburi",
      "Chawang",
      "Chiang Kham",
      "Chiang Khong",
      "Chiang Klang",
      "Chiang Mai",
      "Chiang Rai",
      "Chiang Saen",
      "Chok Chai",
      "Chom Bueng",
      "Chom Thong",
      "Chon Buri",
      "Chon Daen",
      "Chum Phae",
      "Chumphon",
      "Damnoen Saduak",
      "Dan Khun Thot",
      "Den Chai",
      "Det Udom",
      "Doembang Nangbuat",
      "Dok Kham Tai",
      "Don Sak",
      "Hang Dong",
      "Hat Yai",
      "Hua Hin",
      "Huai Mek",
      "Huai Yot",
      "Kabin Buri",
      "Kaeng Khoi",
      "Kaeng Khro",
      "Kalasin",
      "Kamalasai",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Kantang",
      "Kantharalak",
      "Kaset Sombun",
      "Kaset Wisai",
      "Kathu",
      "Kham Sakae Saeng",
      "Khanu Woralaksaburi",
      "Khao Wong",
      "Khao Yoi",
      "Khiri Mat",
      "Khlong Luang",
      "Khlung",
      "Khon Buri",
      "Khon Kaen",
      "Klaeng",
      "Ko Pha Ngan",
      "Ko Samui",
      "Kosum Phisai",
      "Krabi",
      "Kranuan",
      "Krathum Baen",
      "Kuchinarai",
      "Kui Buri",
      "Kut Chap",
      "Laem Sing",
      "Lampang",
      "Lamphun",
      "Lan Sak",
      "Lang Suan",
      "Lat Yao",
      "Loei",
      "Lom Sak",
      "Long",
      "Lop Buri",
      "Mae Chan",
      "Mae Ramat",
      "Mae Sai",
      "Mae Sot",
      "Mae Tha",
      "Maha Sarakham",
      "Makkasan",
      "Mueang Nonthaburi",
      "Mukdahan",
      "Na Klang",
      "Na Wa",
      "Nakhon Luang",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nakhon Thai",
      "Nam Som",
      "Nan",
      "Nang Rong",
      "Narathiwat",
      "Non Sang",
      "Non Sung",
      "Non Thai",
      "Nong Bua",
      "Nong Bua Lamphu",
      "Nong Chik",
      "Nong Khae",
      "Nong Khai",
      "Nong Ki",
      "Nong Kung Si",
      "Nong Phai",
      "Nuea Khlong",
      "Pa Daet",
      "Pa Sang",
      "Pak Chong",
      "Pak Kret",
      "Pak Phanang",
      "Pathum Thani",
      "Patong",
      "Pattani",
      "Pattaya",
      "Phai Sali",
      "Phak Hai",
      "Phan Thong",
      "Phanat Nikhom",
      "Phanom Sarakham",
      "Phanom Thuan",
      "Phasi Charoen",
      "Phatthalung",
      "Phayakkhaphum Phisai",
      "Phayao",
      "Phayuha Khiri",
      "Phetchabun",
      "Phetchaburi",
      "Phibun Mangsahan",
      "Phichit",
      "Phimai",
      "Phitsanulok",
      "Pho Thong",
      "Phon",
      "Phon Charoen",
      "Photharam",
      "Phra Nakhon Si Ayutthaya",
      "Phra Phutthabat",
      "Phra Pradaeng",
      "Phrae",
      "Phrai Bueng",
      "Phu Khiao",
      "Phu Kradueng",
      "Phuket",
      "Pong",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Prakhon Chai",
      "Pran Buri",
      "Ra-ngae",
      "Ranong",
      "Ranot",
      "Ratchaburi",
      "Rawai",
      "Rayong",
      "Roi Et",
      "Ron Phibun",
      "Rong Kwang",
      "Sa Kaeo",
      "Sadao",
      "Sai Buri",
      "Sakon Nakhon",
      "Sam Phran",
      "Sam Roi Yot",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "San Kamphaeng",
      "San Pa Tong",
      "Saraburi",
      "Sattahip",
      "Satuek",
      "Satun",
      "Sawang Daen Din",
      "Sawankhalok",
      "Seka",
      "Si Bun Rueang",
      "Si Chiang Mai",
      "Si Racha",
      "Si Sa Ket",
      "Si Satchanalai",
      "Sing Buri",
      "Soeng Sang",
      "Songkhla",
      "Su-ngai Kolok",
      "Sukhothai",
      "Sung Noen",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Suwannaphum",
      "Tak",
      "Tak Bai",
      "Taphan Hin",
      "Tha Bo",
      "Tha Kham",
      "Tha Mai",
      "Tha Maka",
      "Tha Muang",
      "Tha Ruea",
      "Tha Yang",
      "Thap Khlo",
      "Thap Than",
      "That Phanom",
      "Thoen",
      "Thung Song",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Waeng",
      "Wang Nam Yen",
      "Wang Noi",
      "Wang Saphung",
      "Wapi Pathum",
      "Warin Chamrap",
      "Wiang Sa",
      "Wichian Buri",
      "Wichit",
      "Wiset Chaichan",
      "Yala",
      "Yang Talat",
      "Yaring",
      "Yasothon",
    ],
  },
  "Timor-Leste": {
    code: "TL",
    cities: [
      "Aileu",
      "Ainaro",
      "Baukau",
      "Dili",
      "Likisa",
      "Lospalos",
      "Maliana",
      "Maubara",
      "Suai",
      "Venilale",
    ],
  },
  Togo: {
    code: "TG",
    cities: [
      "Aneho",
      "Atakpame",
      "Badou",
      "Bafilo",
      "Bassar",
      "Dapaong",
      "Kande",
      "Kara",
      "Kpalime",
      "Lome",
      "Mango",
      "Niamtougou",
      "Notse",
      "Sinkasse",
      "Sokode",
      "Sotouboua",
      "Tabligbo",
      "Tchamba",
      "Tsevie",
      "Vogan",
    ],
  },
  Tonga: {
    code: "TO",
    cities: ["Nuku'alofa"],
  },
  "Trinidad and Tobago": {
    code: "TT",
    cities: [
      "Arima",
      "Arouca",
      "Chaguanas",
      "Laventille",
      "Marabella",
      "Mon Repos",
      "Paradise",
      "Penal",
      "Point Fortin",
      "Port of Spain",
      "Rio Claro",
      "San Fernando",
      "Sangre Grande",
      "Scarborough",
      "Tunapuna",
    ],
  },
  Tunisia: {
    code: "TN",
    cities: [
      "Akouda",
      "Al Marsa",
      "Ar Rudayyif",
      "Ariana",
      "As Sars",
      "Banbalah",
      "Beja",
      "Bekalta",
      "Ben Arous",
      "Ben Gardane",
      "Beni Khalled",
      "Beni Khiar",
      "Bizerte",
      "Bou Arada",
      "Bou Arkoub",
      "Carthage",
      "Chebba",
      "Dar Chabanne",
      "Djemmal",
      "Douane",
      "Douar Tindja",
      "Douz",
      "El Alia",
      "El Fahs",
      "El Hamma",
      "El Jem",
      "El Kef",
      "Erriadh",
      "Ezzouhour",
      "Gabes",
      "Gafour",
      "Gafsa",
      "Galaat el Andeless",
      "Ghardimaou",
      "Gremda",
      "Hammam Sousse",
      "Hammam-Lif",
      "Hammamet",
      "Houmt El Souk",
      "Jendouba",
      "Kairouan",
      "Kasserine",
      "Kebili",
      "Kelibia",
      "Korba",
      "Ksar Hellal",
      "Ksibet el Mediouni",
      "Ksour Essaf",
      "La Goulette",
      "La Mohammedia",
      "La Sebala du Mornag",
      "Mahdia",
      "Maktar",
      "Manouba",
      "Mateur",
      "Medenine",
      "Medjez el Bab",
      "Mennzel Bou Zelfa",
      "Menzel Abderhaman",
      "Menzel Bourguiba",
      "Menzel Jemil",
      "Metlaoui",
      "Midoun",
      "Monastir",
      "Msaken",
      "Nabeul",
      "Nefta",
      "Ouardenine",
      "Oued Lill",
      "Rades",
      "Rhar el Melah",
      "Sahline",
      "Seiada",
      "Sfax",
      "Sidi Bouzid",
      "Siliana",
      "Skanes",
      "Skhira",
      "Sousse",
      "Tabarka",
      "Tabursuq",
      "Tajerouine",
      "Takelsa",
      "Tataouine",
      "Testour",
      "Thala",
      "Tozeur",
      "Tunis",
      "Zaghouan",
      "Zarzis",
      "Zouila",
    ],
  },
  Turkey: {
    code: "TR",
    cities: [
      "Acarlar",
      "Acipayam",
      "Adana",
      "Adapazari",
      "Adilcevaz",
      "Adiyaman",
      "Afsin",
      "Afyonkarahisar",
      "Agri",
      "Ahlat",
      "Ahmetli",
      "Akcaabat",
      "Akcadag",
      "Akcakale",
      "Akcakoca",
      "Akdagmadeni",
      "Akhisar",
      "Akkus",
      "Aksaray",
      "Aksehir",
      "Akseki",
      "Aksu",
      "Akyazi",
      "Akyurt",
      "Alaca",
      "Alanya",
      "Alapli",
      "Alasehir",
      "Aliaga",
      "Altinekin",
      "Altinoluk",
      "Altpinar",
      "Amasya",
      "Anamur",
      "Ankara",
      "Antakya",
      "Antalya",
      "Araban",
      "Arakli",
      "Arapgir",
      "Ardahan",
      "Ardesen",
      "Arhavi",
      "Arnavutkoey",
      "Arsin",
      "Artvin",
      "Askale",
      "Atabey",
      "Atasehir",
      "Avanos",
      "Avsallar",
      "Ayancik",
      "Aydin",
      "Ayvalik",
      "Babaeski",
      "Bafra",
      "Bagcilar",
      "Bahce",
      "Bahcelievler",
      "Balikesir",
      "Banaz",
      "Bandirma",
      "Bartin",
      "Basaksehir",
      "Baskale",
      "Baskil",
      "Batikent",
      "Batman",
      "Bayburt",
      "Bayindir",
      "Baykan",
      "Belek",
      "Belen",
      "Bergama",
      "Besikduzu",
      "Besni",
      "Beykonak",
      "Beylikduezue",
      "Beypazari",
      "Beysehir",
      "Biga",
      "Bigadic",
      "Bilecik",
      "Bingoel",
      "Birecik",
      "Bismil",
      "Bitlis",
      "Bodrum",
      "Bolu",
      "Bolvadin",
      "Bor",
      "Boyabat",
      "Boyalik",
      "Bozkir",
      "Bozova",
      "Bozuyuk",
      "Bozyazi",
      "Bucak",
      "Bueyuekcekmece",
      "Bulancak",
      "Bulanik",
      "Buldan",
      "Bunyan",
      "Burdur",
      "Burhaniye",
      "Bursa",
      "Caglayancerit",
      "Camas",
      "Camlidere",
      "Can",
      "Canakkale",
      "Cankaya",
      "Cankiri",
      "Carsamba",
      "Catalca",
      "Cay",
      "Caycuma",
      "Cayeli",
      "Cayiralan",
      "Caykara",
      "Cekerek",
      "Celikhan",
      "Cerkes",
      "Cerkezkoey",
      "Cermik",
      "Cesme",
      "Ceyhan",
      "Ceylanpinar",
      "Cide",
      "Cifteler",
      "Cihanbeyli",
      "Cimin",
      "Cine",
      "Civril",
      "Cizre",
      "Corlu",
      "Corum",
      "Cubuk",
      "Cumra",
      "Dalaman",
      "Darende",
      "Dargecit",
      "Datca",
      "Delice",
      "Demirci",
      "Demre",
      "Denizciler",
      "Denizli",
      "Derince",
      "Derinkuyu",
      "Develi",
      "Devrek",
      "Dicle",
      "Didim",
      "Dikili",
      "Dinar",
      "Divrigi",
      "Diyadin",
      "Diyarbakir",
      "Doertyol",
      "Dogansehir",
      "Dogubayazit",
      "Duezce",
      "Duragan",
      "Dursunbey",
      "Edirne",
      "Edremit",
      "Egirdir",
      "Elazig",
      "Elbistan",
      "Eleskirt",
      "Elmadag",
      "Elmali",
      "Emet",
      "Eminoenue",
      "Emirdag",
      "Erbaa",
      "Ercis",
      "Erdek",
      "Erdemli",
      "Eregli",
      "Eregli",
      "Ergani",
      "Ermenek",
      "Erzin",
      "Erzincan",
      "Erzurum",
      "Esenler",
      "Esenyurt",
      "Eskisehir",
      "Esme",
      "Espiye",
      "Eynesil",
      "Ezine",
      "Fatsa",
      "Ferizli",
      "Fethiye",
      "Foca",
      "Gaziantep",
      "Gazipasa",
      "Gebze",
      "Gediz",
      "Gelibolu",
      "Gemerek",
      "Gemlik",
      "Genc",
      "Gerede",
      "Germencik",
      "Gerze",
      "Gevas",
      "Geyve",
      "Giresun",
      "Goelcuek",
      "Goele",
      "Goksun",
      "Golbasi",
      "Golhisar",
      "Golmarmara",
      "Gonen",
      "Gonen",
      "Gordes",
      "Gorele",
      "Guemueshane",
      "Guergentepe",
      "Gulnar",
      "Gumushacikoy",
      "Guroymak",
      "Gurpinar",
      "Gursu",
      "Gurun",
      "Hacilar",
      "Hadim",
      "Hakkari",
      "Hani",
      "Harran",
      "Havran",
      "Havza",
      "Haymana",
      "Hayrabolu",
      "Hekimhan",
      "Hendek",
      "Hilvan",
      "Hinis",
      "Hizan",
      "Hopa",
      "Horasan",
      "Huyuk",
      "Idil",
      "Igdir",
      "Ikizce",
      "Ilgaz",
      "Ilgin",
      "Ilica",
      "Imamoglu",
      "Incirliova",
      "Inegol",
      "Iscehisar",
      "Iskenderun",
      "Iskilip",
      "Isparta",
      "Ispir",
      "Istanbul",
      "Izmir",
      "Izmit",
      "Iznik",
      "Kadinhani",
      "Kadirli",
      "Kagizman",
      "Kahramanmaras",
      "Kahta",
      "Kalecik",
      "Kaman",
      "Kandira",
      "Kangal",
      "Karabaglar",
      "Karabuek",
      "Karacabey",
      "Karacoban",
      "Karakocan",
      "Karaman",
      "Karamursel",
      "Karapinar",
      "Karasu",
      "Karasu Mahallesi",
      "Kars",
      "Kastamonu",
      "Kavakli",
      "Kayseri",
      "Kazan",
      "Keciborlu",
      "Kelkit",
      "Kemalpasa",
      "Kemer",
      "Kesan",
      "Kesap",
      "Keskin",
      "Kestel",
      "Kilis",
      "Kinik",
      "Kiraz",
      "Kirikhan",
      "Kirikkale",
      "Kirkagac",
      "Kirklareli",
      "Kirsehir",
      "Kizilcahamam",
      "Kiziltepe",
      "Kocaali",
      "Konya",
      "Korfez",
      "Korgan",
      "Korkuteli",
      "Kovancilar",
      "Kozan",
      "Kozluk",
      "Kula",
      "Kulp",
      "Kulu",
      "Kumburgaz",
      "Kumru",
      "Kursunlu",
      "Kurtalan",
      "Kusadasi",
      "Kutahya",
      "Lapseki",
      "Lice",
      "Luleburgaz",
      "Macka",
      "Mahmutlar",
      "Malatya",
      "Malazgirt",
      "Malkara",
      "Maltepe",
      "Manavgat",
      "Manisa",
      "Mardin",
      "Marmara Ereglisi",
      "Marmaris",
      "Mazidagi",
      "Menderes",
      "Menemen",
      "Merkezefendi",
      "Mersin",
      "Merter Keresteciler",
      "Merzifon",
      "Midyat",
      "Milas",
      "Mimarsinan",
      "Mucur",
      "Mudanya",
      "Mugla",
      "Muratpasa",
      "Mus",
      "Mustafakemalpasa",
      "Mut",
      "Nallihan",
      "Narman",
      "Nazilli",
      "Nevsehir",
      "Nigde",
      "Niksar",
      "Nizip",
      "Nurdagi",
      "Nusaybin",
      "OEdemis",
      "Of",
      "Oguzeli",
      "Oltu",
      "Ordu",
      "Orhangazi",
      "Ortaca",
      "Ortakoy",
      "Osmancik",
      "Osmaneli",
      "Osmaniye",
      "Ozdere",
      "Palu",
      "Pamukova",
      "Pasinler",
      "Patnos",
      "Pazarcik",
      "Persembe",
      "Pinarbasi",
      "Pinarbasi",
      "Pinarhisar",
      "Polatli",
      "Pozanti",
      "Reyhanli",
      "Rize",
      "Safranbolu",
      "Salihli",
      "Salpazari",
      "Samsun",
      "Sancaktepe",
      "Sandikli",
      "Sanliurfa",
      "Sapanca",
      "Saraykent",
      "Saraykoy",
      "Sarayonu",
      "Sarigerme",
      "Sarigol",
      "Sarikamis",
      "Sarkikaraagac",
      "Sarkisla",
      "Saruhanli",
      "Sason",
      "Savastepe",
      "Sebin Karahisar",
      "Sefaatli",
      "Seferihisar",
      "Selcuk",
      "Selimpasa",
      "Semdinli",
      "Senirkent",
      "Sereflikochisar",
      "Serik",
      "Serinhisar",
      "Serinyol",
      "Seydisehir",
      "Side",
      "Siirt",
      "Sile",
      "Silifke",
      "Silivri",
      "Silopi",
      "Silvan",
      "Simav",
      "Sindirgi",
      "Sinop",
      "Siran",
      "Sirnak",
      "Sisli",
      "Sivas",
      "Siverek",
      "Sivrihisar",
      "Soeke",
      "Solhan",
      "Soma",
      "Sorgun",
      "Suhut",
      "Sultanbeyli",
      "Sultangazi",
      "Suluova",
      "Sungurlu",
      "Surmene",
      "Suruc",
      "Susehri",
      "Susurluk",
      "Talas",
      "Tarsus",
      "Taskent",
      "Taskopru",
      "Tasova",
      "Tatvan",
      "Tavas",
      "Tavsanli",
      "Tekirdag",
      "Tekirova",
      "Tekkekoy",
      "Tepecik",
      "Tercan",
      "Terme",
      "Tire",
      "Tirebolu",
      "Tokat",
      "Tomarza",
      "Tonya",
      "Torbali",
      "Tosya",
      "Trabzon",
      "Tunceli",
      "Turgutlu",
      "Turgutreis",
      "Turhal",
      "UEnye",
      "UErguep",
      "UEskuedar",
      "Uluborlu",
      "Umraniye",
      "Urla",
      "Usak",
      "Uzunkoeprue",
      "Vakfikebir",
      "Van",
      "Varto",
      "Vezirkopru",
      "Viransehir",
      "Vize",
      "Yahyali",
      "Yakuplu",
      "Yalikavak",
      "Yalova",
      "Yalvac",
      "Yatagan",
      "Yenifakili",
      "Yenisehir",
      "Yerkoy",
      "Yesilhisar",
      "Yesilli",
      "Yesilyurt",
      "Yildizli",
      "Yomra",
      "Yozgat",
      "Yuksekova",
      "Yunak",
      "Zara",
      "Zeytinburnu",
      "Zile",
      "Zonguldak",
      "guengoeren merter",
    ],
  },
  Turkmenistan: {
    code: "TM",
    cities: [
      "Abadan",
      "Akdepe",
      "Anew",
      "Ashgabat",
      "Baharly",
      "Balkanabat",
      "Bayramaly",
      "Bereket",
      "Boldumsaz",
      "Dasoguz",
      "Farap",
      "Gazojak",
      "Gowurdak",
      "Gumdag",
      "Kaka",
      "Kerki",
      "Koeneuergench",
      "Mary",
      "Murgab",
      "Sayat",
      "Serdar",
      "Seydi",
      "Tagta",
      "Tejen",
      "Turkmenabat",
      "Turkmenbasy",
      "Yoloeten",
      "Yylanly",
    ],
  },
  Uganda: {
    code: "UG",
    cities: [
      "Adjumani",
      "Apac",
      "Arua",
      "Bugembe",
      "Bugiri",
      "Bundibugyo",
      "Busembatia",
      "Busia",
      "Buwenge",
      "Bweyogerere",
      "Bwizibwera",
      "Entebbe",
      "Fort Portal",
      "Gulu",
      "Hoima",
      "Ibanda",
      "Iganga",
      "Jinja",
      "Kabale",
      "Kampala",
      "Kamuli",
      "Kamwenge",
      "Kanungu",
      "Kapchorwa",
      "Kasese",
      "Kayunga",
      "Kiboga",
      "Kireka",
      "Kiruhura",
      "Kisoro",
      "Kitgum",
      "Kotido",
      "Kumi",
      "Kyenjojo",
      "Lira",
      "Lugazi",
      "Luwero",
      "Masaka",
      "Masindi",
      "Mayuge",
      "Mbale",
      "Mbarara",
      "Mityana",
      "Moroto",
      "Moyo",
      "Mpigi",
      "Mubende",
      "Mukono",
      "Namasuba",
      "Nebbi",
      "Njeru",
      "Ntungamo",
      "Ntungamo",
      "Nyachera",
      "Pader",
      "Pader Palwo",
      "Paidha",
      "Pallisa",
      "Rukungiri",
      "Sironko",
      "Soroti",
      "Tororo",
      "Wakiso",
      "Wobulenzi",
      "Yumbe",
    ],
  },
  Ukraine: {
    code: "UA",
    cities: [
      "Alchevsk",
      "Alushta",
      "Amvrosiivka",
      "Antonivka",
      "Antratsyt",
      "Apostolove",
      "Armyansk",
      "Artsyz",
      "Avdiivka",
      "Bakhchysarai",
      "Bakhmach",
      "Bakhmut",
      "Balaklava",
      "Balakliia",
      "Balta",
      "Bar",
      "Baranivka",
      "Baryshivka",
      "Bashtanka",
      "Belozyorskoye",
      "Berdyansk",
      "Berdychiv",
      "Berehove",
      "Berezan",
      "Berezhany",
      "Bershad",
      "Beryslav",
      "Bila Tserkva",
      "Bilhorod-Dnistrovskyi",
      "Bilohirsk",
      "Bilopillia",
      "Bilyayivka",
      "Bobrovytsia",
      "Bobrynets",
      "Bohodukhiv",
      "Bohuslav",
      "Boiarka",
      "Bolekhiv",
      "Bolhrad",
      "Borodianka",
      "Borshchiv",
      "Boryslav",
      "Boryspil",
      "Brianka",
      "Brody",
      "Brovary",
      "Bucha",
      "Buchach",
      "Bunhe",
      "Burshtyn",
      "Chasiv Yar",
      "Cherkasy",
      "Chernihiv",
      "Chernivtsi",
      "Chernomorskoye",
      "Chervonohrad",
      "Chervonopartyzansk",
      "Chornomors'k",
      "Chortkiv",
      "Chuhuiv",
      "Chystyakove",
      "Debaltseve",
      "Derazhnya",
      "Derhachi",
      "Dnipro",
      "Dniprorudne",
      "Dobropillia",
      "Dokuchaievsk",
      "Dolyna",
      "Dolynska",
      "Donetsk",
      "Dovzhansk",
      "Drohobych",
      "Druzhkivka",
      "Dubno",
      "Dunayivtsi",
      "Dzhankoi",
      "Enerhodar",
      "Fastiv",
      "Feodosiya",
      "Gaspra",
      "Hadyach",
      "Haysyn",
      "Hayvoron",
      "Henichesk",
      "Hirnyk",
      "Hlukhiv",
      "Hnivan",
      "Hola Prystan",
      "Holubivka",
      "Horishni Plavni",
      "Horlivka",
      "Horodnia",
      "Horodok",
      "Horodok",
      "Horodyshche",
      "Hostomel",
      "Hrebinka",
      "Hulyaypole",
      "Hvardiiske",
      "Ichnia",
      "Illintsi",
      "Ilovays'k",
      "Inkerman",
      "Irpin",
      "Ivano-Frankivsk",
      "Iziaslav",
      "Izium",
      "Izmail",
      "Kadiyivka",
      "Kaharlyk",
      "Kakhovka",
      "Kal'mius'ke",
      "Kalush",
      "Kalynivka",
      "Kamianets-Podilskyi",
      "Kamin-Kashyrskyi",
      "Kamyanka",
      "Kamyanka-Dniprovska",
      "Kamyanske",
      "Kaniv",
      "Karlivka",
      "Kerch",
      "Kharkiv",
      "Khartsyzk",
      "Kherson",
      "Khmelnytskyi",
      "Khmilnyk",
      "Khorol",
      "Khrestivka",
      "Khrustalnyi",
      "Khust",
      "Kiliya",
      "Kivertsi",
      "Kivsharivka",
      "Kolomyia",
      "Konotop",
      "Koriukivka",
      "Korolevo",
      "Korosten",
      "Korostyshiv",
      "Korsun-Shevchenkivskyy",
      "Kostiantynivka",
      "Kostiantynivka",
      "Kostopil",
      "Kotelva",
      "Kotsiubynske",
      "Kovel",
      "Kozyatyn",
      "Kramatorsk",
      "Krasnogvardeyskoye",
      "Krasnohorivka",
      "Krasnohrad",
      "Krasnoyilsk",
      "Krasyliv",
      "Kremenchuk",
      "Kremenets",
      "Kreminna",
      "Krolevets",
      "Kropyvnytskyy",
      "Kryvyy Rih",
      "Kupiansk",
      "Kurakhove",
      "Kurakhovo",
      "Kyiv",
      "Ladyzhyn",
      "Lebedyn",
      "Letychiv",
      "Liuboml",
      "Lokhvytsya",
      "Lozova",
      "Lubny",
      "Luhansk",
      "Lutsk",
      "Lutuhyne",
      "Lviv",
      "Lyman",
      "Lymanka",
      "Lysychansk",
      "Lyubotyn",
      "Makiivka",
      "Mala Vyska",
      "Malyn",
      "Marhanets",
      "Mariupol",
      "Melitopol",
      "Mena",
      "Merefa",
      "Mohyliv-Podilskyy",
      "Molodohvardiisk",
      "Mospyne",
      "Mostyska",
      "Mukachevo",
      "Mykhaylivka",
      "Mykolaiv",
      "Mykolaivka",
      "Mykolayiv",
      "Myrhorod",
      "Myrnohrad",
      "Myronivka",
      "Nadvirna",
      "Nemyriv",
      "Netishyn",
      "Nikopol",
      "Nizhyn",
      "Nosivka",
      "Nova Kakhovka",
      "Nova Odesa",
      "Nova Vodolaha",
      "Novhorod-Siverskyi",
      "Novoazovs'k",
      "Novodnistrovsk",
      "Novomoskovsk",
      "Novomyrhorod",
      "Novooleksiyivka",
      "Novotroyitske",
      "Novoukrayinka",
      "Novovolynsk",
      "Novoyavorivs'k",
      "Novyy Buh",
      "Nyzhnia Krynka",
      "Obukhiv",
      "Ochakiv",
      "Odesa",
      "Okhtyrka",
      "Oktyabr'skoye",
      "Oleksandriya",
      "Oleshky",
      "Orikhiv",
      "Ostroh",
      "Ovidiopol",
      "Ovruch",
      "Pavlohrad",
      "Perehinske",
      "Pereiaslav",
      "Perevalsk",
      "Pershotravensk",
      "Pervomaisk",
      "Pervomaiskyi",
      "Pervomaysk",
      "Petrovske",
      "Pidhorodne",
      "Podilsk",
      "Pokrov",
      "Pokrovsk",
      "Polohy",
      "Polonne",
      "Poltava",
      "Popasna",
      "Primorskiy",
      "Pryluky",
      "Prymorsk",
      "Putyvl",
      "Pyatykhatky",
      "Pyriatyn",
      "Radomyshl",
      "Radyvyliv",
      "Rakhiv",
      "Reni",
      "Rivne",
      "Romny",
      "Rovenky",
      "Rozdilna",
      "Rozhyshche",
      "Rubizhne",
      "Saki",
      "Sambir",
      "Sarny",
      "Sartana",
      "Selydove",
      "Sevastopol",
      "Shakhtarsk",
      "Shchastia",
      "Shchyolkino",
      "Shepetivka",
      "Shostka",
      "Shpola",
      "Sievierodonetsk",
      "Simferopol",
      "Siversk",
      "Skadovsk",
      "Skvyra",
      "Slavuta",
      "Slavutych",
      "Slobozhans'ke",
      "Slobozhanske",
      "Sloviansk",
      "Smila",
      "Snihurivka",
      "Snizhne",
      "Snovsk",
      "Sokal",
      "Soledar",
      "Solonytsivka",
      "Sorokyne",
      "Sovetskiy",
      "Stanytsia Luhanska",
      "Starobilsk",
      "Starokostyantyniv",
      "Stebnyk",
      "Storozhynets",
      "Stryi",
      "Sudak",
      "Sukhodilsk",
      "Sumy",
      "Svalyava",
      "Svatove",
      "Svetlodarsk",
      "Svitlovodsk",
      "Synelnykove",
      "Talne",
      "Tarashcha",
      "Tatarbunary",
      "Tavriysk",
      "Terebovlia",
      "Ternivka",
      "Ternopil",
      "Tetiiv",
      "Tokmak",
      "Toretsk",
      "Trostianets",
      "Truskavets",
      "Tulchyn",
      "Ukrainka",
      "Ukrainsk",
      "Uman",
      "Uzhhorod",
      "Uzyn",
      "Vakhrusheve",
      "Valky",
      "Varash",
      "Vasylivka",
      "Vasylkiv",
      "Vatutine",
      "Velykodolynske",
      "Verkhivtseve",
      "Verkhnodniprovsk",
      "Vilnohirsk",
      "Vilnyansk",
      "Vinnytsya",
      "Volnovakha",
      "Volochysk",
      "Volodymyr-Volynskyi",
      "Vovchansk",
      "Voznesensk",
      "Vynohradiv",
      "Vyshhorod",
      "Vyshneve",
      "Vysokyi",
      "Yahotyn",
      "Yakymivka",
      "Yalta",
      "Yampil",
      "Yany Kapu",
      "Yasynuvata",
      "Yavoriv",
      "Yenakiieve",
      "Yevpatoriya",
      "Yuzhne",
      "Yuzhnoukrayinsk",
      "Zaporizhzhya",
      "Zbarazh",
      "Zdolbuniv",
      "Zhashkiv",
      "Zhdanivka",
      "Zhmerynka",
      "Zhovkva",
      "Zhovti Vody",
      "Zhytomyr",
      "Zmiiv",
      "Znamyanka",
      "Zolochiv",
      "Zolote",
      "Zolotonosha",
      "Zuhres",
      "Zvenihorodka",
      "Zviahel",
    ],
  },
  "United Arab Emirates": {
    code: "AE",
    cities: [
      "Abu Dhabi",
      "Adh Dhayd",
      "Ajman City",
      "Al Ain City",
      "Al Fujairah City",
      "Al Shamkhah City",
      "Ar Ruways",
      "Bani Yas City",
      "Dibba Al-Fujairah",
      "Dibba Al-Hisn",
      "Dubai",
      "Khalifah A City",
      "Khawr Fakkan",
      "Musaffah",
      "Ras Al Khaimah City",
      "Reef Al Fujairah City",
      "Sharjah",
      "Umm Al Quwain City",
      "Zayed City",
    ],
  },
  "United Kingdom": {
    code: "GB",
    cities: [
      "Abbey Wood",
      "Abbots Langley",
      "Abercarn",
      "Aberdare",
      "Aberdeen",
      "Abergavenny",
      "Abergele",
      "Aberkenfig",
      "Abertillery",
      "Aberystwyth",
      "Abingdon",
      "Abram",
      "Accrington",
      "Acocks Green",
      "Acton",
      "Addlestone",
      "Adwick le Street",
      "Airdrie",
      "Aldershot",
      "Aldridge",
      "Alfreton",
      "Alloa",
      "Alsager",
      "Alton",
      "Altrincham",
      "Amersham",
      "Amersham on the Hill",
      "Amesbury",
      "Ammanford",
      "Ampthill",
      "Andover",
      "Annfield Plain",
      "Antrim",
      "Arbroath",
      "Archway",
      "Ardrossan",
      "Armadale",
      "Armagh",
      "Armthorpe",
      "Arnold",
      "Ascot",
      "Ashby-de-la-Zouch",
      "Ashford",
      "Ashford",
      "Ashington",
      "Ashtead",
      "Ashton in Makerfield",
      "Ashton-under-Lyne",
      "Aston",
      "Atherstone",
      "Atherton",
      "Attleborough",
      "Aylesbury",
      "Ayr",
      "Bacup",
      "Baildon",
      "Baldock",
      "Balham",
      "Ballymena",
      "Banbridge",
      "Banbury",
      "Bangor",
      "Bangor",
      "Banstead",
      "Bargoed",
      "Barking",
      "Barkingside",
      "Barnes",
      "Barnet",
      "Barnham",
      "Barnoldswick",
      "Barnsley",
      "Barnstaple",
      "Barrhead",
      "Barrow in Furness",
      "Barry",
      "Bartley Green",
      "Barton upon Humber",
      "Basford",
      "Basildon",
      "Basingstoke",
      "Bath",
      "Bathgate",
      "Batley",
      "Battersea",
      "Bayswater",
      "Beaconsfield",
      "Bearsden",
      "Bebington",
      "Beccles",
      "Beckenham",
      "Becontree",
      "Bedford",
      "Bedlington",
      "Bedworth",
      "Beighton",
      "Belfast",
      "Bellshill",
      "Belmont",
      "Belper",
      "Belsize Park",
      "Belvedere",
      "Bentley",
      "Berkhamsted",
      "Berwick-Upon-Tweed",
      "Bethnal Green",
      "Beverley",
      "Bexhill-on-Sea",
      "Bexley",
      "Bicester",
      "Bickley",
      "Biddulph",
      "Bideford",
      "Biggin Hill",
      "Biggleswade",
      "Billericay",
      "Billingham",
      "Bingley",
      "Birkenhead",
      "Birmingham",
      "Bishop Auckland",
      "Bishopbriggs",
      "Bishops Cleeve",
      "Bishops Stortford",
      "Bishopstoke",
      "Bishopsworth",
      "Blackburn",
      "Blackheath",
      "Blackley",
      "Blackpool",
      "Blackwall",
      "Blackwood",
      "Blacon",
      "Blandford Forum",
      "Blantyre",
      "Blaydon-on-Tyne",
      "Bletchley",
      "Bloxwich",
      "Blyth",
      "Bo'ness",
      "Bodmin",
      "Bognor Regis",
      "Bolsover",
      "Bolton",
      "Bolton upon Dearne",
      "Bonnyrigg",
      "Bootle",
      "Bordon",
      "Borehamwood",
      "Boston",
      "Boughton",
      "Bourne",
      "Bournemouth",
      "Bow",
      "Bowthorpe",
      "Brackley",
      "Bracknell",
      "Bradford",
      "Braintree",
      "Bramhall",
      "Bredbury",
      "Brent",
      "Brentford",
      "Brentwood",
      "Bridgend",
      "Bridgnorth",
      "Bridgwater",
      "Bridlington",
      "Bridport",
      "Brierfield",
      "Brierley Hill",
      "Brighouse",
      "Brighton",
      "Bristol",
      "Briton Ferry",
      "Brixham",
      "Brixton",
      "Brixton Hill",
      "Broadfield",
      "Broadstairs",
      "Broadstone",
      "Brockley",
      "Bromborough",
      "Bromsgrove",
      "Brough",
      "Brownhills",
      "Broxbourne",
      "Broxburn",
      "Brymbo",
      "Brynmawr",
      "Buckhurst Hill",
      "Buckingham",
      "Buckley",
      "Burgess Hill",
      "Burnage",
      "Burngreave",
      "Burnham-on-Sea",
      "Burnley",
      "Burntwood",
      "Burton upon Trent",
      "Bury",
      "Bury St Edmunds",
      "Bushey",
      "Buxton",
      "Caerphilly",
      "Caldicot",
      "Calne",
      "Camberley",
      "Camborne",
      "Cambridge",
      "Cambuslang",
      "Camden Town",
      "Canary Wharf",
      "Canford Heath",
      "Canning Town",
      "Cannock",
      "Canonbury",
      "Canterbury",
      "Canvey Island",
      "Cardiff",
      "Carlisle",
      "Carluke",
      "Carmarthen",
      "Carnoustie",
      "Carrickfergus",
      "Carshalton",
      "Carterton",
      "Castleford",
      "Castlereagh",
      "Caterham",
      "Catford",
      "Catterick Garrison",
      "Chafford Hundred",
      "Chalfont Saint Peter",
      "Chalk Farm",
      "Chapel Allerton",
      "Chapeltown",
      "Chard",
      "Charlton",
      "Charlton Kings",
      "Chatham",
      "Chatteris",
      "Cheadle",
      "Cheadle Hulme",
      "Cheam",
      "Cheetham Hill",
      "Chelmsford",
      "Chelmsley Wood",
      "Chelsea",
      "Chelsfield",
      "Cheltenham",
      "Chepstow",
      "Chertsey",
      "Chesham",
      "Cheshunt",
      "Chessington",
      "Chester",
      "Chester-le-Street",
      "Chesterfield",
      "Chichester",
      "Chigwell",
      "Chingford",
      "Chippenham",
      "Chipping Sodbury",
      "Chislehurst",
      "Chiswick",
      "Chorley",
      "Chorleywood",
      "Christchurch",
      "Churchdown",
      "Cinderford",
      "Cirencester",
      "City of Westminster",
      "Clacton-on-Sea",
      "Clayton-le-Woods",
      "Cleckheaton",
      "Cleethorpes",
      "Clevedon",
      "Cleveleys",
      "Clitheroe",
      "Clydach",
      "Clydebank",
      "Coalville",
      "Coatbridge",
      "Cobham",
      "Codicote",
      "Codsall",
      "Coity",
      "Colchester",
      "Coleford",
      "Coleraine",
      "Collier Row",
      "Colne",
      "Colwyn Bay",
      "Congleton",
      "Conisbrough",
      "Consett",
      "Cookstown",
      "Corby",
      "Corsham",
      "Cosham",
      "Coulsdon",
      "Coventry",
      "Cowdenbeath",
      "Cowes",
      "Cowley",
      "Craigavon",
      "Cramlington",
      "Cranford",
      "Cranleigh",
      "Crawley",
      "Crewe",
      "Cricklewood",
      "Crofton Park",
      "Crosby",
      "Cross Hills",
      "Crouch End",
      "Crowborough",
      "Crowthorne",
      "Croydon",
      "Crumpsall",
      "Crystal Palace",
      "Cudworth",
      "Cumbernauld",
      "Cwmbran",
      "Dagenham",
      "Dalkeith",
      "Dalserf",
      "Darlington",
      "Darras Hall",
      "Dartford",
      "Darton",
      "Darwen",
      "Daventry",
      "Dawlish",
      "Deal",
      "Deeside",
      "Denton",
      "Derby",
      "Derry",
      "Desborough",
      "Devizes",
      "Dewsbury",
      "Didcot",
      "Didsbury",
      "Dinnington",
      "Diss",
      "Doncaster",
      "Dorchester",
      "Dorking",
      "Dover",
      "Downham Market",
      "Downpatrick",
      "Driffield",
      "Droitwich",
      "Dronfield",
      "Droylsden",
      "Dudley",
      "Dukinfield",
      "Dumbarton",
      "Dumfries",
      "Dundee",
      "Dundonald",
      "Dunfermline",
      "Dungannon",
      "Dunstable",
      "Durham",
      "Dursley",
      "Earl Shilton",
      "Earlsfield",
      "East Dereham",
      "East Dulwich",
      "East Grinstead",
      "East Kilbride",
      "East Molesey",
      "East Sheen",
      "Eastbourne",
      "Eastleigh",
      "Eastwood",
      "Eaton Socon",
      "Ebbw Vale",
      "Eccles",
      "Edinburgh",
      "Edmonton",
      "Egham",
      "Elgin",
      "Elland",
      "Ellesmere Port Town",
      "Ellon",
      "Elm Park",
      "Eltham",
      "Ely",
      "Emerson Park",
      "Emsworth",
      "Enfield Lock",
      "Enfield Town",
      "Enniskillen",
      "Epping",
      "Epsom",
      "Erskine",
      "Esher",
      "Evesham",
      "Evington",
      "Ewell",
      "Exeter",
      "Exmouth",
      "Failsworth",
      "Fair Oak",
      "Falkirk",
      "Fallowfield",
      "Falmouth",
      "Fareham",
      "Farnborough",
      "Farnham",
      "Farnworth",
      "Faversham",
      "Featherstone",
      "Featherstone",
      "Felixstowe",
      "Felling",
      "Feltham",
      "Ferndown",
      "Finchley",
      "Fleet",
      "Fleetwood",
      "Flint",
      "Flitwick",
      "Folkestone",
      "Forest Hill",
      "Forfar",
      "Formby",
      "Fraserburgh",
      "Friern Barnet",
      "Frimley",
      "Frinton-on-Sea",
      "Frome",
      "Fulham",
      "Fulwood",
      "Furzedown",
      "Fylde",
      "Gainsborough",
      "Galashiels",
      "Garforth",
      "Gateshead",
      "Gelligaer",
      "Gerrards Cross",
      "Giffnock",
      "Gillingham",
      "Gipsy Hill",
      "Glasgow",
      "Glenrothes",
      "Glossop",
      "Gloucester",
      "Godalming",
      "Golborne",
      "Golders Green",
      "Goodmayes",
      "Goole",
      "Gorseinon",
      "Gosforth",
      "Gosport",
      "Gourock",
      "Grange Hill",
      "Grangemouth",
      "Grantham",
      "Gravesend",
      "Grays",
      "Great Bookham",
      "Great Harwood",
      "Great Malvern",
      "Great Sankey",
      "Great Wyrley",
      "Great Yarmouth",
      "Greenford",
      "Greenock",
      "Grimsby",
      "Grove",
      "Guildford",
      "Guisborough",
      "Guiseley",
      "Hadleigh",
      "Hadley",
      "Hadley Wood",
      "Hailsham",
      "Hale",
      "Hale",
      "Halesowen",
      "Halifax",
      "Halstead",
      "Hamilton",
      "Hampton",
      "Hampton Wick",
      "Hanworth",
      "Harlesden",
      "Harlow",
      "Harold Wood",
      "Harpenden",
      "Harringay",
      "Harrogate",
      "Harrow",
      "Harrow on the Hill",
      "Hartlepool",
      "Hartley",
      "Hartshill",
      "Harwich",
      "Haslemere",
      "Haslingden",
      "Hastings",
      "Hatfield",
      "Havant",
      "Haverfordwest",
      "Haverhill",
      "Hawarden",
      "Hawick",
      "Haxby",
      "Haydock",
      "Hayes",
      "Hayes",
      "Hayling Island",
      "Haywards Heath",
      "Hazel Grove",
      "Heanor",
      "Heavitree",
      "Hebburn",
      "Heckmondwike",
      "Hedge End",
      "Hedon",
      "Helensburgh",
      "Helston",
      "Hemel Hempstead",
      "Henley-on-Thames",
      "Henlow",
      "Hereford",
      "Herne Bay",
      "Hertford",
      "Heston",
      "Heswall",
      "Hetton-Le-Hole",
      "Hexham",
      "Heysham",
      "Heywood",
      "High Blantyre",
      "High Peak",
      "High Wycombe",
      "Highams Park",
      "Highbury",
      "Highgate",
      "Hillingdon",
      "Hinckley",
      "Hindley",
      "Hitchin",
      "Hockley",
      "Hoddesdon",
      "Holborn",
      "Holloway",
      "Holyhead",
      "Holywood",
      "Honiton",
      "Hook",
      "Horbury",
      "Horley",
      "Hornchurch",
      "Hornsey",
      "Horsforth",
      "Horsham",
      "Horwich",
      "Houghton-Le-Spring",
      "Hounslow",
      "Hove",
      "Hoyland Nether",
      "Hucknall",
      "Huddersfield",
      "Huntingdon",
      "Hurstpierpoint",
      "Huyton",
      "Hyde",
      "Hythe",
      "Hythe",
      "Ilford",
      "Ilfracombe",
      "Ilkeston",
      "Ilkley",
      "Immingham",
      "Ince-in-Makerfield",
      "Ingleby Greenhow",
      "Inverness",
      "Inverurie",
      "Ipswich",
      "Irlam",
      "Irvine",
      "Isle of Lewis",
      "Isleworth",
      "Islington",
      "Ivybridge",
      "Jarrow",
      "Johnstone",
      "Kearsley",
      "Keighley",
      "Kempston",
      "Kempston Hardwick",
      "Kendal",
      "Kenilworth",
      "Kenley",
      "Kensal Green",
      "Kesgrave",
      "Kettering",
      "Kew",
      "Keynsham",
      "Kidbrooke",
      "Kidderminster",
      "Kidlington",
      "Kidsgrove",
      "Kilburn",
      "Kilmarnock",
      "Kilwinning",
      "Kimberley",
      "King's Cross",
      "King's Lynn",
      "Kingsteignton",
      "Kingston upon Hull",
      "Kingston upon Thames",
      "Kingswinford",
      "Kingswood",
      "Kippax",
      "Kirk Sandall",
      "Kirkby",
      "Kirkby in Ashfield",
      "Kirkcaldy",
      "Kirkham",
      "Kirkintilloch",
      "Knaresborough",
      "Knottingley",
      "Knowle",
      "Knutsford",
      "Ladywell",
      "Lancaster",
      "Lancing",
      "Largs",
      "Larkhall",
      "Larne",
      "Leatherhead",
      "Lee",
      "Leeds",
      "Leek",
      "Leicester",
      "Leigh",
      "Leighton Buzzard",
      "Leominster",
      "Letchworth Garden City",
      "Lewes",
      "Leyland",
      "Leyton",
      "Leytonstone",
      "Lichfield",
      "Limavady",
      "Limehouse",
      "Lincoln",
      "Linlithgow",
      "Lisburn",
      "Litherland",
      "Little Hulton",
      "Little Lever",
      "Littleborough",
      "Littlehampton",
      "Liverpool",
      "Liversedge",
      "Livingston",
      "Llandudno",
      "Llanelli",
      "Llantrisant",
      "Llantwit Major",
      "Lofthouse",
      "London",
      "Londonderry County Borough",
      "Long Eaton",
      "Longfield",
      "Longsight",
      "Longton",
      "Loughborough",
      "Louth",
      "Lower Earley",
      "Lowestoft",
      "Ludlow",
      "Luton",
      "Lymington",
      "Lymm",
      "Lytham St Annes",
      "Mablethorpe",
      "Macclesfield",
      "Maesteg",
      "Maghull",
      "Maidenhead",
      "Maidstone",
      "Maldon",
      "Maltby",
      "Manchester",
      "Mangotsfield",
      "Manor Park",
      "Mansfield",
      "Mansfield Woodhouse",
      "March",
      "Margate",
      "Market Deeping",
      "Market Drayton",
      "Market Harborough",
      "Marlow",
      "Marple",
      "Matlock",
      "Mayfield",
      "Meikle Earnock",
      "Melksham",
      "Melton Mowbray",
      "Mendip",
      "Merthyr Tydfil",
      "Methil",
      "Mexborough",
      "Middlesbrough",
      "Middleton",
      "Middlewich",
      "Midsomer Norton",
      "Mildenhall",
      "Milford Haven",
      "Milngavie",
      "Milnrow",
      "Milton Keynes",
      "Minehead",
      "Mirfield",
      "Mitcham",
      "Mold",
      "Monkhams",
      "Monmouth",
      "Montrose",
      "Morden",
      "Morecambe",
      "Moreton",
      "Morley",
      "Morpeth",
      "Mortlake",
      "Mossend",
      "Motherwell",
      "Mountain Ash",
      "Mountsorrel",
      "Musselburgh",
      "Muswell Hill",
      "Nailsea",
      "Nantwich",
      "Neath",
      "Nelson",
      "Nelson",
      "Neston",
      "Neston",
      "New Cross",
      "New Malden",
      "New Mills",
      "New Milton",
      "Newark on Trent",
      "Newburn",
      "Newbury",
      "Newcastle under Lyme",
      "Newcastle upon Tyne",
      "Newhaven",
      "Newmarket",
      "Newport",
      "Newport",
      "Newport",
      "Newport Pagnell",
      "Newquay",
      "Newry",
      "Newton Abbot",
      "Newton Aycliffe",
      "Newton Mearns",
      "Newton-le-Willows",
      "Newtown",
      "Newtownabbey",
      "Newtownards",
      "Norbiton",
      "Norbury",
      "North Shields",
      "North Walsham",
      "Northallerton",
      "Northampton",
      "Northolt",
      "Northumberland Park",
      "Northwich",
      "Norwich",
      "Nottingham",
      "Nuneaton",
      "Oadby",
      "Oakham",
      "Oldbury",
      "Oldham",
      "Omagh",
      "Ormskirk",
      "Orpington",
      "Ossett",
      "Osterley",
      "Oswestry",
      "Otley",
      "Oxford",
      "Oxted",
      "Padiham",
      "Paignton",
      "Paisley",
      "Palmers Green",
      "Peacehaven",
      "Peckham",
      "Pelsall",
      "Penarth",
      "Penicuik",
      "Penistone",
      "Penrith",
      "Penzance",
      "Perry Vale",
      "Perth",
      "Peterborough",
      "Peterhead",
      "Peterlee",
      "Petersfield",
      "Pinner",
      "Pinxton",
      "Pitsea",
      "Plumstead",
      "Plymouth",
      "Plymstock",
      "Ponders End",
      "Pontefract",
      "Ponteland",
      "Pontypool",
      "Pontypridd",
      "Poole",
      "Port Glasgow",
      "Portadown",
      "Porthcawl",
      "Portishead",
      "Portland",
      "Portslade",
      "Portsmouth",
      "Portswood",
      "Potters Bar",
      "Poulton-le-Fylde",
      "Poynton",
      "Prenton",
      "Prescot",
      "Prestatyn",
      "Preston",
      "Prestwich",
      "Prestwick",
      "Prudhoe",
      "Pudsey",
      "Purfleet-on-Thames",
      "Purley",
      "Putney",
      "Pyle",
      "Radcliffe",
      "Rainham",
      "Rainhill",
      "Ramsbottom",
      "Ramsgate",
      "Rastrick",
      "Rawmarsh",
      "Rawtenstall",
      "Rayleigh",
      "Reading",
      "Redcar",
      "Redditch",
      "Redhill",
      "Redruth",
      "Reigate",
      "Renfrew",
      "Retford",
      "Rhondda",
      "Rhosllannerchrugog",
      "Rhyl",
      "Richmond",
      "Rickmansworth",
      "Ringwood",
      "Ripley",
      "Ripon",
      "Risca",
      "Rochdale",
      "Rochester",
      "Rochford",
      "Roehampton",
      "Romford",
      "Romsey",
      "Ross on Wye",
      "Rossendale",
      "Rosyth",
      "Rotherham",
      "Rotherhithe",
      "Rottingdean",
      "Royal Leamington Spa",
      "Royal Tunbridge Wells",
      "Royal Wootton Bassett",
      "Royston",
      "Royton",
      "Rugby",
      "Rugeley",
      "Ruislip",
      "Runcorn",
      "Rushden",
      "Rustington",
      "Rutherglen",
      "Ryde",
      "Ryhope",
      "Ryton",
      "Saffron Walden",
      "Saint Andrews",
      "Saint Leonards-on-Sea",
      "Saint Neots",
      "Saint Peters",
      "Sale",
      "Salford",
      "Salisbury",
      "Saltash",
      "Saltcoats",
      "Sandbach",
      "Sanderstead",
      "Sandhurst",
      "Sandown",
      "Sandy",
      "Scarborough",
      "Scunthorpe",
      "Seaford",
      "Seaham",
      "Selby",
      "Selsey",
      "Seven Sisters",
      "Sevenoaks",
      "Shadwell",
      "Sheerness",
      "Sheffield",
      "Shefford",
      "Shenfield",
      "Shepherds Bush",
      "Shepperton",
      "Shepshed",
      "Shepton Mallet",
      "Shipley",
      "Shirebrook",
      "Shirley",
      "Shirley",
      "Shoreham-by-Sea",
      "Shrewsbury",
      "Sidcup",
      "Sidmouth",
      "Sinfin",
      "Sittingbourne",
      "Skegness",
      "Skelmersdale",
      "Skipton",
      "Sleaford",
      "Slough",
      "Smethwick",
      "Snaresbrook",
      "Snodland",
      "Soho",
      "Solihull",
      "South Benfleet",
      "South Croydon",
      "South Elmsall",
      "South Hayling",
      "South Norwood",
      "South Ockendon",
      "South Shields",
      "Southall",
      "Southampton",
      "Southend-on-Sea",
      "Southgate",
      "Southport",
      "Southsea",
      "Spalding",
      "Spennymoor",
      "Spitalfields",
      "St Albans",
      "St Austell",
      "St Helens",
      "St. Ann's",
      "St. Helier",
      "Stafford",
      "Staines",
      "Stalybridge",
      "Stamford",
      "Stamford Hill",
      "Stanford-le-Hope",
      "Stanley",
      "Stanwell",
      "Staveley",
      "Stenhousemuir",
      "Stepney",
      "Stevenage",
      "Stirling",
      "Stockport",
      "Stocksbridge",
      "Stockton-on-Tees",
      "Stoke Gifford",
      "Stoke-on-Trent",
      "Stone",
      "Stonehaven",
      "Stourbridge",
      "Stourport-on-Severn",
      "Stowmarket",
      "Strabane",
      "Stranraer",
      "Stratford",
      "Stratford-upon-Avon",
      "Streatham",
      "Street",
      "Streetly",
      "Stretford",
      "Strood",
      "Stroud",
      "Stroud Green",
      "Sudbury",
      "Sudbury",
      "Sunbury-on-Thames",
      "Sunderland",
      "Sunninghill",
      "Surbiton",
      "Sutton",
      "Sutton Coldfield",
      "Sutton in Ashfield",
      "Swadlincote",
      "Swanage",
      "Swanley",
      "Swanscombe",
      "Swansea",
      "Swindon",
      "Swinton",
      "Syston",
      "Tadley",
      "Tamworth",
      "Taunton",
      "Tavistock",
      "Teignmouth",
      "Telford",
      "Tewkesbury",
      "Thame",
      "Thamesmead",
      "Thatcham",
      "The Boldons",
      "Thetford",
      "Thornaby-on-Tees",
      "Thornbury",
      "Thorne",
      "Thornton Heath",
      "Thornton-Cleveleys",
      "Tilbury",
      "Timperley",
      "Tipton",
      "Tiverton",
      "Todmorden",
      "Tonbridge",
      "Tonypandy",
      "Tooting",
      "Torquay",
      "Tottenham",
      "Tottenham Hale",
      "Totteridge",
      "Totton",
      "Tranent",
      "Tredegar",
      "Treharris",
      "Tring",
      "Troon",
      "Trowbridge",
      "Truro",
      "Tyldesley",
      "Tynemouth",
      "Uckfield",
      "Ulverston",
      "Upper Norwood",
      "Urmston",
      "Uttoxeter",
      "Uxbridge",
      "Vale of Leven",
      "Verwood",
      "Viewpark",
      "Wakefield",
      "Walkden",
      "Wallasey",
      "Wallingford",
      "Wallington",
      "Wallsend",
      "Walsall",
      "Waltham Abbey",
      "Walthamstow",
      "Walton-on-Thames",
      "Walton-on-the-Naze",
      "Wanstead",
      "Wantage",
      "Ware",
      "Warminster",
      "Warrington",
      "Warwick",
      "Washington",
      "Washwood Heath",
      "Waterlooville",
      "Watford",
      "Wath upon Dearne",
      "Wednesbury",
      "Wednesfield",
      "Welling",
      "Wellingborough",
      "Wellington",
      "Wells",
      "Welwyn Garden City",
      "Wembley",
      "West Bridgford",
      "West Bromwich",
      "West Drayton",
      "West End of London",
      "West Ham",
      "West Molesey",
      "West Wickham",
      "Westbury",
      "Westhill",
      "Westhoughton",
      "Weston-super-Mare",
      "Wetherby",
      "Weybridge",
      "Weymouth",
      "Whickham",
      "Whitburn",
      "Whitby",
      "Whitechapel",
      "Whitefield",
      "Whitehaven",
      "Whitley Bay",
      "Whitstable",
      "Whittlesey",
      "Wickford",
      "Widnes",
      "Wigan",
      "Wigston Magna",
      "Willenhall",
      "Willesden",
      "Wilmslow",
      "Wimbledon",
      "Wimbledon Park",
      "Wimborne Minster",
      "Winchester",
      "Windsor",
      "Winsford",
      "Wisbech",
      "Wishaw",
      "Witham",
      "Witney",
      "Woking",
      "Wokingham",
      "Wolverhampton",
      "Wombourne",
      "Wombwell",
      "Wood Green",
      "Woodbridge",
      "Woodford Green",
      "Woolston",
      "Worcester",
      "Worcester Park",
      "Workington",
      "Worksop",
      "Worthing",
      "Wrexham",
      "Yarm",
      "Yate",
      "Yateley",
      "Yatton",
      "Yeadon",
      "Yeovil",
      "York",
      "Ystalyfera",
    ],
  },
  "United States": {
    code: "US",
    cities: [
      "'Ewa Beach",
      "'Ewa Gentry",
      "Abbeville",
      "Aberdeen",
      "Aberdeen",
      "Aberdeen",
      "Abilene",
      "Abington",
      "Abington",
      "Accokeek",
      "Acton",
      "Acushnet",
      "Acworth",
      "Ada",
      "Adams Morgan",
      "Addison",
      "Addison",
      "Adelanto",
      "Adelphi",
      "Adrian",
      "Affton",
      "Agawam",
      "Agoura",
      "Agoura Hills",
      "Ahwatukee Foothills",
      "Aiken",
      "Akron",
      "Alabaster",
      "Alafaya",
      "Alameda",
      "Alamo",
      "Alamo",
      "Alamogordo",
      "Albany",
      "Albany",
      "Albany",
      "Albany",
      "Albany Park",
      "Albemarle",
      "Albert Lea",
      "Albertville",
      "Albuquerque",
      "Aldine",
      "Alexander City",
      "Alexandria",
      "Alexandria",
      "Alexandria",
      "Algonquin",
      "Alhambra",
      "Alhambra",
      "Alice",
      "Alief",
      "Aliso Viejo",
      "Allapattah",
      "Allen",
      "Allen Park",
      "Allendale",
      "Allentown",
      "Alliance",
      "Allison Park",
      "Allouez",
      "Aloha",
      "Alpena",
      "Alpharetta",
      "Alpine",
      "Alpine",
      "Alsip",
      "Altadena",
      "Altamont",
      "Altamonte Springs",
      "Alton",
      "Alton",
      "Altoona",
      "Altoona",
      "Altus",
      "Alum Rock",
      "Alvin",
      "Amarillo",
      "American Canyon",
      "American Fork",
      "Americus",
      "Ames",
      "Amesbury",
      "Amherst",
      "Amherst",
      "Amherst",
      "Amherst Center",
      "Ammon",
      "Amsterdam",
      "Anacortes",
      "Anaheim",
      "Anchorage",
      "Anderson",
      "Anderson",
      "Anderson",
      "Andover",
      "Andover",
      "Andrews",
      "Angleton",
      "Ankeny",
      "Ann Arbor",
      "Anna",
      "Annandale",
      "Annapolis",
      "Anniston",
      "Anoka",
      "Ansonia",
      "Antelope",
      "Anthem",
      "Antioch",
      "Antioch",
      "Apache Junction",
      "Apex",
      "Apollo Beach",
      "Apopka",
      "Apple Valley",
      "Apple Valley",
      "Appleton",
      "Arbutus",
      "Arcadia",
      "Arcata",
      "Archdale",
      "Arden-Arcade",
      "Ardmore",
      "Ardmore",
      "Arizona City",
      "Arkadelphia",
      "Arkansas City",
      "Arlington",
      "Arlington",
      "Arlington",
      "Arlington",
      "Arlington",
      "Arlington Heights",
      "Arnold",
      "Arnold",
      "Arrochar",
      "Arroyo Grande",
      "Artesia",
      "Artesia",
      "Artondale",
      "Arvada",
      "Arverne",
      "Arvin",
      "Asbury Park",
      "Ashburn",
      "Ashburn",
      "Asheboro",
      "Asheville",
      "Ashland",
      "Ashland",
      "Ashland",
      "Ashland",
      "Ashland",
      "Ashtabula",
      "Ashwaubenon",
      "Aspen Hill",
      "Astoria",
      "Atascadero",
      "Atascocita",
      "Atchison",
      "Atco",
      "Athens",
      "Athens",
      "Athens",
      "Athens",
      "Athens",
      "Atlanta",
      "Atlantic Beach",
      "Atlantic City",
      "Atmore",
      "Attleboro",
      "Atwater",
      "Auburn",
      "Auburn",
      "Auburn",
      "Auburn",
      "Auburn",
      "Auburn",
      "Auburn",
      "Auburn Gresham",
      "Auburn Hills",
      "Auburndale",
      "Augusta",
      "Augusta",
      "Aurora",
      "Aurora",
      "Aurora",
      "Austin",
      "Austin",
      "Austintown",
      "Avenal",
      "Avenel",
      "Aventura",
      "Avocado Heights",
      "Avon",
      "Avon",
      "Avon",
      "Avon Center",
      "Avon Lake",
      "Avon Park",
      "Avondale",
      "Avondale",
      "Azalea Park",
      "Azle",
      "Azusa",
      "Babylon",
      "Back Mountain",
      "Badger",
      "Baileys Crossroads",
      "Bainbridge",
      "Bainbridge Island",
      "Baker",
      "Bakersfield",
      "Balch Springs",
      "Baldwin",
      "Baldwin",
      "Baldwin Park",
      "Ballenger Creek",
      "Ballwin",
      "Baltimore",
      "Bangor",
      "Banning",
      "Baraboo",
      "Barberton",
      "Bardstown",
      "Barnstable",
      "Barrington",
      "Barrington",
      "Barstow",
      "Barstow Heights",
      "Bartlesville",
      "Bartlett",
      "Bartlett",
      "Bartow",
      "Basking Ridge",
      "Bastrop",
      "Batavia",
      "Batavia",
      "Batesville",
      "Bath Beach",
      "Baton Rouge",
      "Battle Creek",
      "Battle Ground",
      "Bay City",
      "Bay City",
      "Bay Point",
      "Bay Shore",
      "Bay Village",
      "Baychester",
      "Bayonet Point",
      "Bayonne",
      "Bayou Cane",
      "Bayshore Gardens",
      "Bayside",
      "Bayside",
      "Baytown",
      "Bayville",
      "Beach Park",
      "Beachwood",
      "Beachwood",
      "Beacon",
      "Bear",
      "Beatrice",
      "Beaufort",
      "Beaumont",
      "Beaumont",
      "Beaver Dam",
      "Beavercreek",
      "Beaverton",
      "Beckley",
      "Bedford",
      "Bedford",
      "Bedford",
      "Bedford",
      "Bedford",
      "Bedford Heights",
      "Beech Grove",
      "Beecher",
      "Beeville",
      "Bel Air",
      "Bel Air North",
      "Bel Air South",
      "Bell",
      "Bell Gardens",
      "Bella Vista",
      "Bellair-Meadowbrook Terrace",
      "Bellaire",
      "Belle Chasse",
      "Belle Glade",
      "Bellefontaine",
      "Bellefontaine Neighbors",
      "Belleville",
      "Belleville",
      "Bellevue",
      "Bellevue",
      "Bellevue",
      "Bellflower",
      "Bellingham",
      "Bellmawr",
      "Bellmead",
      "Bellmore",
      "Bellview",
      "Bellwood",
      "Belmont",
      "Belmont",
      "Belmont",
      "Belmont Cragin",
      "Beloit",
      "Belton",
      "Belton",
      "Beltsville",
      "Belvedere Park",
      "Belvidere",
      "Bemidji",
      "Benbrook",
      "Bend",
      "Benicia",
      "Bennsville",
      "Bensalem",
      "Bensenville",
      "Bensonhurst",
      "Benton",
      "Bentonville",
      "Berea",
      "Berea",
      "Berea",
      "Bergen Beach",
      "Bergenfield",
      "Berkeley",
      "Berkeley Heights",
      "Berkley",
      "Berkley",
      "Berwick",
      "Berwyn",
      "Bessemer",
      "Bethany",
      "Bethany",
      "Bethel Park",
      "Bethesda",
      "Bethlehem",
      "Bethpage",
      "Bettendorf",
      "Beverly",
      "Beverly Cove",
      "Beverly Hills",
      "Beverly Hills",
      "Bexley",
      "Biddeford",
      "Big Bear City",
      "Big Lake",
      "Big Rapids",
      "Big Spring",
      "Billerica",
      "Billings",
      "Biloxi",
      "Binghamton",
      "Birmingham",
      "Birmingham",
      "Bismarck",
      "Bixby",
      "Black Forest",
      "Blackfoot",
      "Blacksburg",
      "Blaine",
      "Bloomfield",
      "Bloomfield",
      "Bloomingdale",
      "Bloomingdale",
      "Bloomington",
      "Bloomington",
      "Bloomington",
      "Bloomington",
      "Bloomsburg",
      "Blue Ash",
      "Blue Island",
      "Blue Springs",
      "Bluefield",
      "Bluffdale",
      "Bluffton",
      "Bluffton",
      "Blythe",
      "Blytheville",
      "Boardman",
      "Boca Del Mar",
      "Boca Raton",
      "Boerne",
      "Bogalusa",
      "Bohemia",
      "Boise",
      "Bolingbrook",
      "Bolivar",
      "Bon Air",
      "Bonham",
      "Bonita",
      "Bonita Springs",
      "Bonney Lake",
      "Boone",
      "Boone",
      "Borger",
      "Borough Park",
      "Bossier City",
      "Boston",
      "Bostonia",
      "Bothell",
      "Bothell West",
      "Boulder",
      "Boulder City",
      "Bound Brook",
      "Bountiful",
      "Bourbonnais",
      "Bowie",
      "Bowling Green",
      "Bowling Green",
      "Boyle Heights",
      "Boynton Beach",
      "Bozeman",
      "Bradenton",
      "Bradley",
      "Bradley Gardens",
      "Brainerd",
      "Braintree",
      "Brandermill",
      "Brandon",
      "Brandon",
      "Branford",
      "Branson",
      "Brawley",
      "Brea",
      "Brecksville",
      "Bremerton",
      "Brenham",
      "Brent",
      "Brentwood",
      "Brentwood",
      "Brentwood",
      "Brentwood Estates",
      "Briarwood",
      "Bridgeport",
      "Bridgeport",
      "Bridgeton",
      "Bridgeton",
      "Bridgetown",
      "Bridgeview",
      "Bridgewater",
      "Brigham City",
      "Brighton",
      "Brighton",
      "Brighton Beach",
      "Brighton Park",
      "Bristol",
      "Bristol",
      "Bristol",
      "Bristol",
      "Broad Ripple",
      "Broadlands",
      "Broadview Heights",
      "Brockton",
      "Broken Arrow",
      "Brook Park",
      "Brookfield",
      "Brookfield",
      "Brookhaven",
      "Brookhaven",
      "Brookings",
      "Brookline",
      "Brooklyn",
      "Brooklyn",
      "Brooklyn",
      "Brooklyn Center",
      "Brooklyn Heights",
      "Brooklyn Park",
      "Brooklyn Park",
      "Brookside",
      "Broomall",
      "Broomfield",
      "Broussard",
      "Brown Deer",
      "Browns Mills",
      "Brownsburg",
      "Brownsville",
      "Brownsville",
      "Brownsville",
      "Brownwood",
      "Brunswick",
      "Brunswick",
      "Brunswick",
      "Brushy Creek",
      "Bryan",
      "Bryant",
      "Bryn Mawr-Skyway",
      "Buckeye",
      "Buckhall",
      "Bucyrus",
      "Buda",
      "Buena Park",
      "Buenaventura Lakes",
      "Buffalo",
      "Buffalo",
      "Buffalo Grove",
      "Buford",
      "Bull Run",
      "Bullhead City",
      "Burbank",
      "Burbank",
      "Burien",
      "Burkburnett",
      "Burke",
      "Burleson",
      "Burley",
      "Burlingame",
      "Burlington",
      "Burlington",
      "Burlington",
      "Burlington",
      "Burlington",
      "Burlington",
      "Burnsville",
      "Burr Ridge",
      "Burton",
      "Bushwick",
      "Butler",
      "Butte",
      "Byram",
      "Cabot",
      "Cadillac",
      "Cahokia",
      "Calabasas",
      "Caldwell",
      "Caledonia",
      "Calera",
      "Calexico",
      "Calhoun",
      "California",
      "California City",
      "Callaway",
      "Calumet City",
      "Calverton",
      "Camano",
      "Camarillo",
      "Camas",
      "Cambria Heights",
      "Cambridge",
      "Cambridge",
      "Cambridge",
      "Camden",
      "Camden",
      "Cameron Park",
      "Camp Pendleton South",
      "Camp Springs",
      "Camp Verde",
      "Campbell",
      "Campbellsville",
      "Canandaigua",
      "Canarsie",
      "Canby",
      "Candler-McAfee",
      "Canoga Park",
      "Canon City",
      "Canton",
      "Canton",
      "Canton",
      "Canton",
      "Canton",
      "Canton",
      "Cantonment",
      "Canyon",
      "Canyon Country",
      "Canyon Lake",
      "Canyon Lake",
      "Canyon Rim",
      "Cape Coral",
      "Cape Girardeau",
      "Capitola",
      "Carbondale",
      "Carlisle",
      "Carlsbad",
      "Carlsbad",
      "Carmel",
      "Carmichael",
      "Carney",
      "Carnot-Moon",
      "Carol City",
      "Carol Stream",
      "Carpentersville",
      "Carpinteria",
      "Carrboro",
      "Carriere",
      "Carrollton",
      "Carrollton",
      "Carrollwood",
      "Carrollwood Village",
      "Carson",
      "Carson City",
      "Carteret",
      "Cartersville",
      "Carthage",
      "Carver",
      "Cary",
      "Cary",
      "Casa Grande",
      "Casa de Oro-Mount Helix",
      "Casas Adobes",
      "Casper",
      "Casselberry",
      "Castaic",
      "Castle Pines North",
      "Castle Rock",
      "Castlewood",
      "Castro Valley",
      "Catalina Foothills",
      "Cathedral City",
      "Catonsville",
      "Cave Spring",
      "Cayce",
      "Cedar City",
      "Cedar Falls",
      "Cedar Grove",
      "Cedar Hill",
      "Cedar Hills",
      "Cedar Lake",
      "Cedar Mill",
      "Cedar Park",
      "Cedar Rapids",
      "Cedarburg",
      "Celina",
      "Centennial",
      "Center City",
      "Center Point",
      "Centereach",
      "Centerton",
      "Centerville",
      "Centerville",
      "Central",
      "Central City",
      "Central Falls",
      "Central Islip",
      "Central Point",
      "Centralia",
      "Centralia",
      "Centreville",
      "Ceres",
      "Cerritos",
      "Chalco",
      "Chalmette",
      "Chambersburg",
      "Chamblee",
      "Champaign",
      "Champlin",
      "Chandler",
      "Chanhassen",
      "Channahon",
      "Channelview",
      "Chantilly",
      "Chaparral",
      "Chapel Hill",
      "Charleston",
      "Charleston",
      "Charleston",
      "Charlotte",
      "Charlottesville",
      "Charlton",
      "Chaska",
      "Chatham",
      "Chatham",
      "Chatsworth",
      "Chattanooga",
      "Cheektowaga",
      "Chelmsford",
      "Chelsea",
      "Chelsea",
      "Cheney",
      "Cherry Creek",
      "Cherry Hill",
      "Cherry Hill",
      "Cherry Hill Mall",
      "Cherryland",
      "Chesapeake",
      "Chesapeake Ranch Estates",
      "Chesapeake Ranch Estates-Drum Point",
      "Cheshire",
      "Chester",
      "Chester",
      "Chesterfield",
      "Chesterton",
      "Cheval",
      "Cheyenne",
      "Chicago",
      "Chicago Heights",
      "Chicago Lawn",
      "Chicago Loop",
      "Chicago Ridge",
      "Chickasha",
      "Chico",
      "Chicopee",
      "Chillicothe",
      "Chillum",
      "Chinatown",
      "Chinatown",
      "Chino",
      "Chino Hills",
      "Chino Valley",
      "Chippewa Falls",
      "Choctaw",
      "Chowchilla",
      "Christiansburg",
      "Chubbuck",
      "Chula Vista",
      "Cibolo",
      "Cicero",
      "Cicero",
      "Cimarron Hills",
      "Cincinnati",
      "Cinco Ranch",
      "Cinnaminson",
      "Circleville",
      "Citrus",
      "Citrus Heights",
      "Citrus Park",
      "Citrus Ridge",
      "City of Milford (balance)",
      "City of Sammamish",
      "Claiborne",
      "Claremont",
      "Claremont",
      "Claremore",
      "Clark",
      "Clark-Fulton",
      "Clarksburg",
      "Clarksburg",
      "Clarksdale",
      "Clarkston",
      "Clarksville",
      "Clarksville",
      "Clawson",
      "Clay",
      "Clayton",
      "Clayton",
      "Clayton",
      "Clayton",
      "Clearfield",
      "Clearlake",
      "Clearwater",
      "Cleburne",
      "Clemmons",
      "Clemson",
      "Clermont",
      "Cleveland",
      "Cleveland",
      "Cleveland",
      "Cleveland Heights",
      "Cliffside Park",
      "Clifton",
      "Clifton",
      "Clifton Park",
      "Clinton",
      "Clinton",
      "Clinton",
      "Clinton",
      "Clinton",
      "Clinton Township",
      "Clive",
      "Cloquet",
      "Cloverleaf",
      "Cloverly",
      "Clovis",
      "Clovis",
      "Clute",
      "Coachella",
      "Coalinga",
      "Coatesville",
      "Cockeysville",
      "Cocoa",
      "Cocoa Beach",
      "Coconut Creek",
      "Coconut Grove",
      "Coeur d'Alene",
      "Cohoes",
      "Colchester",
      "Coldwater",
      "Colesville",
      "College",
      "College Park",
      "College Park",
      "College Point",
      "College Station",
      "Collegedale",
      "Colleyville",
      "Collierville",
      "Collingswood",
      "Collinsville",
      "Collinwood",
      "Colonia",
      "Colonial Heights",
      "Colonial Park",
      "Colorado Springs",
      "Colton",
      "Colts Neck",
      "Columbia",
      "Columbia",
      "Columbia",
      "Columbia",
      "Columbia",
      "Columbia",
      "Columbia Heights",
      "Columbine",
      "Columbus",
      "Columbus",
      "Columbus",
      "Columbus",
      "Columbus",
      "Commack",
      "Commerce",
      "Commerce City",
      "Communications Hill",
      "Compton",
      "Comstock Park",
      "Concord",
      "Concord",
      "Concord",
      "Concord",
      "Concord",
      "Conda",
      "Coney Island",
      "Conneaut",
      "Connersville",
      "Conroe",
      "Converse",
      "Conway",
      "Conway",
      "Conway",
      "Conyers",
      "Cookeville",
      "Coolidge",
      "Coon Rapids",
      "Cooper City",
      "Coos Bay",
      "Copiague",
      "Copley",
      "Coppell",
      "Copperas Cove",
      "Coral Gables",
      "Coral Springs",
      "Coral Terrace",
      "Coralville",
      "Coram",
      "Corcoran",
      "Cordele",
      "Cordova",
      "Corinth",
      "Corinth",
      "Cornelius",
      "Cornelius",
      "Corning",
      "Corona",
      "Corona",
      "Coronado",
      "Corpus Christi",
      "Corsicana",
      "Cortland",
      "Cortlandt Manor",
      "Corvallis",
      "Coshocton",
      "Costa Mesa",
      "Coto De Caza",
      "Cottage Grove",
      "Cottage Lake",
      "Cottonwood",
      "Cottonwood Heights",
      "Council Bluffs",
      "Country Club",
      "Country Club Hills",
      "Country Walk",
      "Countryside",
      "Coventry",
      "Covina",
      "Covington",
      "Covington",
      "Covington",
      "Cranberry Township",
      "Cranford",
      "Cranston",
      "Crawfordsville",
      "Crest Hill",
      "Crestline",
      "Crestview",
      "Crestwood",
      "Crestwood",
      "Creve Coeur",
      "Crofton",
      "Cromwell",
      "Crossville",
      "Crowley",
      "Crowley",
      "Crown Point",
      "Crystal",
      "Crystal Lake",
      "Cudahy",
      "Cudahy",
      "Cullman",
      "Culpeper",
      "Culver City",
      "Cumberland",
      "Cumberland",
      "Cupertino",
      "Cusseta",
      "Cutler",
      "Cutler Bay",
      "Cutler Ridge",
      "Cutlerville",
      "Cuyahoga Falls",
      "Cypress",
      "Cypress",
      "Cypress Hills",
      "Cypress Lake",
      "D'Iberville",
      "Dale City",
      "Dallas",
      "Dallas",
      "Dallas",
      "Dalton",
      "Daly City",
      "Damascus",
      "Damascus",
      "Dana Point",
      "Danbury",
      "Dania Beach",
      "Danvers",
      "Danville",
      "Danville",
      "Danville",
      "Danville",
      "Daphne",
      "Darby",
      "Dardenne Prairie",
      "Darien",
      "Darien",
      "Davenport",
      "Davidson",
      "Davie",
      "Davis",
      "Dayton",
      "Daytona Beach",
      "De Pere",
      "DeBary",
      "DeKalb",
      "DeLand",
      "DeRidder",
      "DeSoto",
      "Dearborn",
      "Dearborn Heights",
      "Decatur",
      "Decatur",
      "Decatur",
      "Dedham",
      "Deer Park",
      "Deer Park",
      "Deer Valley",
      "Deerfield",
      "Deerfield Beach",
      "Defiance",
      "Del Aire",
      "Del City",
      "Del Rio",
      "Delano",
      "Delaware",
      "Delhi",
      "Delray Beach",
      "Deltona",
      "Deming",
      "Denham Springs",
      "Denison",
      "Dent",
      "Denton",
      "Dentsville",
      "Denver",
      "Denville",
      "Depew",
      "Derby",
      "Derby",
      "Derry",
      "Derry Village",
      "Des Moines",
      "Des Moines",
      "Des Plaines",
      "Desert Hot Springs",
      "Destin",
      "Destrehan",
      "Detroit",
      "Detroit-Shoreway",
      "Diamond Bar",
      "Diamond Springs",
      "Dickinson",
      "Dickinson",
      "Dickson",
      "Dinuba",
      "Discovery Bay",
      "Dix Hills",
      "Dixiana",
      "Dixon",
      "Dixon",
      "Dobbs Ferry",
      "Doctor Phillips",
      "Dodge City",
      "Dolton",
      "Donna",
      "Doral",
      "Doraville",
      "Dothan",
      "Douglas",
      "Douglas",
      "Douglas",
      "Douglaston",
      "Douglasville",
      "Dover",
      "Dover",
      "Dover",
      "Dover",
      "Downers Grove",
      "Downey",
      "Dracut",
      "Dranesville",
      "Draper",
      "Drexel Heights",
      "Drexel Hill",
      "Druid Hills",
      "Duarte",
      "Dublin",
      "Dublin",
      "Dublin",
      "Dubuque",
      "Dudley",
      "Duluth",
      "Duluth",
      "Dumas",
      "Dumont",
      "Duncan",
      "Duncanville",
      "Dundalk",
      "Dunedin",
      "Dunkirk",
      "Dunmore",
      "Dunwoody",
      "Durango",
      "Durant",
      "Durham",
      "Durham",
      "Duxbury",
      "Dyer",
      "Dyersburg",
      "Dyker Heights",
      "Eagan",
      "Eagle",
      "Eagle Mountain",
      "Eagle Pass",
      "Eagle River",
      "Easley",
      "East Amherst",
      "East Bethel",
      "East Brainerd",
      "East Bridgewater",
      "East Brunswick",
      "East Chattanooga",
      "East Chicago",
      "East Cleveland",
      "East Concord",
      "East Elmhurst",
      "East Flatbush",
      "East Florence",
      "East Garfield Park",
      "East Grand Rapids",
      "East Greenwich",
      "East Hampton",
      "East Hanover",
      "East Harlem",
      "East Hartford",
      "East Haven",
      "East Hemet",
      "East Highland Park",
      "East Hill-Meridian",
      "East Honolulu",
      "East Independence",
      "East Islip",
      "East Lake",
      "East Lake-Orient Park",
      "East Lansing",
      "East Liverpool",
      "East Longmeadow",
      "East Los Angeles",
      "East Massapequa",
      "East Meadow",
      "East Millcreek",
      "East Milton",
      "East Moline",
      "East Naples",
      "East New York",
      "East Norriton",
      "East Northport",
      "East Norwalk",
      "East Orange",
      "East Palo Alto",
      "East Patchogue",
      "East Pensacola Heights",
      "East Peoria",
      "East Point",
      "East Providence",
      "East Rancho Dominguez",
      "East Renton Highlands",
      "East Ridge",
      "East Riverdale",
      "East Saint Louis",
      "East San Gabriel",
      "East Setauket",
      "East Stroudsburg",
      "East Tremont",
      "East Village",
      "East Wenatchee",
      "Eastchester",
      "Easthampton",
      "Eastlake",
      "Eastmont",
      "Easton",
      "Easton",
      "Easton",
      "Eastpointe",
      "Eastvale",
      "Eatontown",
      "Eau Claire",
      "Echelon",
      "Echo Park",
      "Eden",
      "Eden Prairie",
      "Edgewater",
      "Edgewater",
      "Edgewater",
      "Edgewood",
      "Edina",
      "Edinburg",
      "Edison",
      "Edmond",
      "Edmonds",
      "Edwards",
      "Edwardsville",
      "Effingham",
      "Eggertsville",
      "Egypt Lake-Leto",
      "El Cajon",
      "El Campo",
      "El Centro",
      "El Cerrito",
      "El Dorado",
      "El Dorado",
      "El Dorado Hills",
      "El Mirage",
      "El Monte",
      "El Paso",
      "El Reno",
      "El Segundo",
      "El Sobrante",
      "Eldersburg",
      "Elfers",
      "Elgin",
      "Elizabeth",
      "Elizabeth City",
      "Elizabethton",
      "Elizabethtown",
      "Elizabethtown",
      "Elk City",
      "Elk Grove",
      "Elk Grove Village",
      "Elk Plain",
      "Elk River",
      "Elkhart",
      "Elko",
      "Elkridge",
      "Elkton",
      "Ellendale",
      "Ellensburg",
      "Ellicott City",
      "Ellington",
      "Elmhurst",
      "Elmhurst",
      "Elmira",
      "Elmont",
      "Elmwood Park",
      "Elmwood Park",
      "Elon",
      "Eloy",
      "Eltingville",
      "Elwood",
      "Elyria",
      "Emerson Hill",
      "Emeryville",
      "Emmaus",
      "Emporia",
      "Encanto",
      "Enchanted Hills",
      "Encinitas",
      "Encino",
      "Endicott",
      "Endwell",
      "Enfield",
      "Englewood",
      "Englewood",
      "Englewood",
      "Englewood",
      "Englewood",
      "Enid",
      "Ennis",
      "Ensley",
      "Enterprise",
      "Enterprise",
      "Enumclaw",
      "Ephrata",
      "Erie",
      "Erie",
      "Erlanger",
      "Escanaba",
      "Escondido",
      "Espanola",
      "Essex",
      "Essex Junction",
      "Estelle",
      "Estero",
      "Euclid",
      "Eufaula",
      "Eugene",
      "Euless",
      "Eunice",
      "Eureka",
      "Eureka",
      "Eustis",
      "Evans",
      "Evans",
      "Evanston",
      "Evanston",
      "Evansville",
      "Everett",
      "Everett",
      "Evergreen Park",
      "Ewing",
      "Excelsior Springs",
      "Exeter",
      "Fair Lawn",
      "Fair Oaks",
      "Fairbanks",
      "Fairborn",
      "Fairburn",
      "Fairfax",
      "Fairfax Station",
      "Fairfield",
      "Fairfield",
      "Fairfield",
      "Fairfield",
      "Fairfield Heights",
      "Fairhaven",
      "Fairhope",
      "Fairland",
      "Fairmont",
      "Fairmont",
      "Fairmount",
      "Fairview",
      "Fairview",
      "Fairview Heights",
      "Fairview Park",
      "Fairview Shores",
      "Fairwood",
      "Fall River",
      "Fallbrook",
      "Falls Church",
      "Fallsburg",
      "Far Rockaway",
      "Fargo",
      "Faribault",
      "Farmers Branch",
      "Farmersville",
      "Farmington",
      "Farmington",
      "Farmington",
      "Farmington",
      "Farmington",
      "Farmington",
      "Farmington Hills",
      "Farmingville",
      "Farragut",
      "Fayetteville",
      "Fayetteville",
      "Fayetteville",
      "Federal Heights",
      "Federal Way",
      "Fenton",
      "Fergus Falls",
      "Ferguson",
      "Fern Creek",
      "Fernandina Beach",
      "Ferndale",
      "Ferndale",
      "Ferndale",
      "Fernley",
      "Fernway",
      "Ferry Pass",
      "Festus",
      "Fillmore",
      "Financial District",
      "Findlay",
      "Finneytown",
      "Firestone",
      "Fish Hawk",
      "Fishers",
      "Fitchburg",
      "Fitchburg",
      "Five Corners",
      "Five Forks",
      "Flagami",
      "Flagstaff",
      "Flatbush",
      "Flatlands",
      "Fleming Island",
      "Flint",
      "Floral Park",
      "Florence",
      "Florence",
      "Florence",
      "Florence",
      "Florence-Graham",
      "Florham Park",
      "Florida City",
      "Florida Ridge",
      "Florin",
      "Florissant",
      "Flower Mound",
      "Flowing Wells",
      "Foley",
      "Folsom",
      "Fond du Lac",
      "Fontana",
      "Foothill Farms",
      "Foothill Ranch",
      "Fordham",
      "Fords",
      "Forest Acres",
      "Forest City",
      "Forest Grove",
      "Forest Hill",
      "Forest Hills",
      "Forest Hills",
      "Forest Lake",
      "Forest Park",
      "Forest Park",
      "Forest Park",
      "Forestdale",
      "Forestville",
      "Forestville",
      "Forney",
      "Forrest City",
      "Fort Atkinson",
      "Fort Campbell North",
      "Fort Carson",
      "Fort Cavazos",
      "Fort Collins",
      "Fort Dodge",
      "Fort Drum",
      "Fort Hamilton",
      "Fort Hunt",
      "Fort Knox",
      "Fort Lauderdale",
      "Fort Lee",
      "Fort Leonard Wood",
      "Fort Liberty",
      "Fort Madison",
      "Fort Mill",
      "Fort Morgan",
      "Fort Myers",
      "Fort Payne",
      "Fort Pierce",
      "Fort Salonga",
      "Fort Smith",
      "Fort Thomas",
      "Fort Walton Beach",
      "Fort Washington",
      "Fort Wayne",
      "Fort Worth",
      "Fortuna",
      "Fortuna Foothills",
      "Foster City",
      "Fostoria",
      "Fountain",
      "Fountain Hills",
      "Fountain Valley",
      "Fountainebleau",
      "Four Corners",
      "Four Corners",
      "Four Corners",
      "Fox Lake",
      "Framingham",
      "Framingham Center",
      "Franconia",
      "Frankfort",
      "Frankfort",
      "Frankfort",
      "Franklin",
      "Franklin",
      "Franklin",
      "Franklin",
      "Franklin",
      "Franklin Lakes",
      "Franklin Park",
      "Franklin Park",
      "Franklin Park",
      "Franklin Square",
      "Fraser",
      "Frederick",
      "Frederick",
      "Fredericksburg",
      "Fredericksburg",
      "Frederickson",
      "Fredonia",
      "Freehold",
      "Freeport",
      "Freeport",
      "Freeport",
      "Fremont",
      "Fremont",
      "Fremont",
      "Fresh Meadows",
      "Fresno",
      "Fresno",
      "Fridley",
      "Friendswood",
      "Frisco",
      "Front Royal",
      "Fruit Cove",
      "Fruita",
      "Fruitville",
      "Fullerton",
      "Fullerton",
      "Fulton",
      "Fulton",
      "Fuquay-Varina",
      "Gadsden",
      "Gaffney",
      "Gage Park",
      "Gages Lake",
      "Gahanna",
      "Gainesville",
      "Gainesville",
      "Gainesville",
      "Gainesville",
      "Gaithersburg",
      "Galena Park",
      "Galesburg",
      "Galion",
      "Gallatin",
      "Gallup",
      "Galt",
      "Galveston",
      "Gantt",
      "Garden Acres",
      "Garden City",
      "Garden City",
      "Garden City",
      "Garden City",
      "Garden Grove",
      "Gardena",
      "Gardendale",
      "Gardere",
      "Gardner",
      "Gardner",
      "Gardnerville Ranchos",
      "Garfield",
      "Garfield Heights",
      "Garland",
      "Garner",
      "Gary",
      "Gastonia",
      "Gates-North Gates",
      "Gatesville",
      "Gautier",
      "Geneva",
      "Geneva",
      "Georgetown",
      "Georgetown",
      "Georgetown",
      "Germantown",
      "Germantown",
      "Germantown",
      "Gibsonton",
      "Gilbert",
      "Gillette",
      "Gilroy",
      "Gladeview",
      "Gladstone",
      "Gladstone",
      "Glasgow",
      "Glasgow",
      "Glassboro",
      "Glassmanor",
      "Glastonbury",
      "Glen Allen",
      "Glen Avon",
      "Glen Burnie",
      "Glen Carbon",
      "Glen Cove",
      "Glen Ellyn",
      "Glen Rock",
      "Glendale",
      "Glendale",
      "Glendale",
      "Glendale",
      "Glendale Heights",
      "Glendora",
      "Glenmont",
      "Glenn Dale",
      "Glenn Heights",
      "Glenpool",
      "Glens Falls",
      "Glenvar Heights",
      "Glenview",
      "Glenville",
      "Gloucester",
      "Gloucester City",
      "Gloversville",
      "Godfrey",
      "Gold Camp",
      "Gold Canyon",
      "Golden",
      "Golden Gate",
      "Golden Glades",
      "Golden Valley",
      "Goldenrod",
      "Goldsboro",
      "Goleta",
      "Gonzales",
      "Gonzalez",
      "Goodings Grove",
      "Goodlettsville",
      "Goodyear",
      "Goose Creek",
      "Goshen",
      "Goshen",
      "Goulds",
      "Grafton",
      "Grafton",
      "Graham",
      "Graham",
      "Grain Valley",
      "Gramercy Park",
      "Grand Boulevard",
      "Grand Forks",
      "Grand Haven",
      "Grand Island",
      "Grand Island",
      "Grand Junction",
      "Grand Prairie",
      "Grand Rapids",
      "Grand Rapids",
      "Grand Terrace",
      "Grandview",
      "Grandview",
      "Grandville",
      "Granger",
      "Granite Bay",
      "Granite City",
      "Graniteville",
      "Grants Pass",
      "Grantsville",
      "Grapevine",
      "Grass Valley",
      "Gravesend",
      "Grayslake",
      "Great Bend",
      "Great Falls",
      "Great Falls",
      "Great Kills",
      "Great Neck",
      "Greater Grand Crossing",
      "Greater Northdale",
      "Greater Upper Marlboro",
      "Greatwood",
      "Greece",
      "Greeley",
      "Green",
      "Green Bay",
      "Green Haven",
      "Green River",
      "Green Valley",
      "Green Valley",
      "Greenacres City",
      "Greenbelt",
      "Greenburgh",
      "Greencastle",
      "Greendale",
      "Greeneville",
      "Greenfield",
      "Greenfield",
      "Greenfield",
      "Greenfield",
      "Greenlawn",
      "Greenpoint",
      "Greensboro",
      "Greensburg",
      "Greensburg",
      "Greentree",
      "Greenville",
      "Greenville",
      "Greenville",
      "Greenville",
      "Greenville",
      "Greenwich",
      "Greenwood",
      "Greenwood",
      "Greenwood",
      "Greenwood",
      "Greenwood Village",
      "Greer",
      "Grenada",
      "Gresham",
      "Gretna",
      "Griffin",
      "Griffith",
      "Grimes",
      "Grosse Ile",
      "Grosse Pointe Park",
      "Grosse Pointe Woods",
      "Grove City",
      "Groveland",
      "Grover Beach",
      "Groves",
      "Groveton",
      "Grovetown",
      "Guilford",
      "Gulf Gate Estates",
      "Gulf Shores",
      "Gulfport",
      "Gulfport",
      "Gurnee",
      "Guthrie",
      "Guttenberg",
      "Guymon",
      "Gwynn Oak",
      "Hacienda Heights",
      "Hackensack",
      "Haddonfield",
      "Hagerstown",
      "Haines City",
      "Halawa",
      "Halawa Heights",
      "Half Moon Bay",
      "Halfway",
      "Hallandale Beach",
      "Haltom City",
      "Ham Lake",
      "Hamden",
      "Hamilton",
      "Hamilton Square",
      "Hammond",
      "Hammond",
      "Hammonton",
      "Hampton",
      "Hampton Bays",
      "Hamtramck",
      "Hanahan",
      "Hanford",
      "Hannibal",
      "Hanover",
      "Hanover",
      "Hanover",
      "Hanover",
      "Hanover Park",
      "Hanson",
      "Happy Valley",
      "Harker Heights",
      "Harlem",
      "Harlingen",
      "Harper Woods",
      "Harrisburg",
      "Harrisburg",
      "Harrison",
      "Harrison",
      "Harrison",
      "Harrisonburg",
      "Hartford",
      "Hartford",
      "Hartselle",
      "Harvey",
      "Harvey",
      "Harwich",
      "Hasbrouck Heights",
      "Haslett",
      "Hastings",
      "Hastings",
      "Hattiesburg",
      "Hauppauge",
      "Havelock",
      "Haverhill",
      "Haverstraw",
      "Havre de Grace",
      "Hawaiian Gardens",
      "Hawaiian Paradise Park",
      "Hawthorne",
      "Hawthorne",
      "Hayden",
      "Hayesville",
      "Hays",
      "Haysville",
      "Hayward",
      "Hazel Crest",
      "Hazel Dell",
      "Hazel Park",
      "Hazelwood",
      "Hazleton",
      "Head of Westport",
      "Healdsburg",
      "Heath",
      "Helena",
      "Helena",
      "Helena-West Helena",
      "Hell's Kitchen",
      "Hemet",
      "Hempstead",
      "Henderson",
      "Henderson",
      "Henderson",
      "Henderson",
      "Hendersonville",
      "Hendersonville",
      "Henrietta",
      "Hercules",
      "Hereford",
      "Hermiston",
      "Hermitage",
      "Hermitage",
      "Hermosa Beach",
      "Hernando",
      "Herndon",
      "Herriman",
      "Herrin",
      "Hershey",
      "Hesperia",
      "Hewitt",
      "Hialeah",
      "Hialeah Gardens",
      "Hibbing",
      "Hickory",
      "Hickory Hills",
      "Hicksville",
      "Hidalgo",
      "High Point",
      "Highland",
      "Highland",
      "Highland",
      "Highland City",
      "Highland Park",
      "Highland Park",
      "Highland Park",
      "Highland Springs",
      "Highland Village",
      "Highlands Ranch",
      "Highview",
      "Hillcrest Heights",
      "Hilliard",
      "Hillsboro",
      "Hillsborough",
      "Hillsborough",
      "Hillsdale",
      "Hillside",
      "Hillside",
      "Hilo",
      "Hilton Head",
      "Hilton Head Island",
      "Hinesville",
      "Hinsdale",
      "Hobart",
      "Hobbs",
      "Hobe Sound",
      "Hoboken",
      "Hockessin",
      "Hoffman Estates",
      "Holbrook",
      "Holbrook",
      "Holden",
      "Holiday",
      "Holiday City-Berkeley",
      "Holladay",
      "Holland",
      "Hollins",
      "Hollis",
      "Hollister",
      "Holliston",
      "Holly Hill",
      "Holly Springs",
      "Holly Springs",
      "Hollywood",
      "Hollywood",
      "Holt",
      "Holtsville",
      "Holyoke",
      "Home Gardens",
      "Homer Glen",
      "Homestead",
      "Homewood",
      "Homewood",
      "Homosassa Springs",
      "Honolulu",
      "Hoover",
      "Hopatcong",
      "Hopatcong Hills",
      "Hope Mills",
      "Hopewell",
      "Hopkins",
      "Hopkinsville",
      "Horizon City",
      "Horizon West",
      "Horn Lake",
      "Horsham",
      "Hot Springs",
      "Hot Springs Village",
      "Hough",
      "Houma",
      "Houston",
      "Howard",
      "Howard Beach",
      "Huber Heights",
      "Hudson",
      "Hudson",
      "Hudson",
      "Hudson",
      "Hueytown",
      "Hugo",
      "Hull",
      "Humble",
      "Hunt Valley",
      "Hunters Creek",
      "Huntersville",
      "Huntington",
      "Huntington",
      "Huntington",
      "Huntington",
      "Huntington Beach",
      "Huntington Park",
      "Huntington Station",
      "Huntley",
      "Hunts Point",
      "Huntsville",
      "Huntsville",
      "Huron",
      "Hurricane",
      "Hurst",
      "Hutchinson",
      "Hutchinson",
      "Hutto",
      "Hyannis",
      "Hyattsville",
      "Hybla Valley",
      "Hyde Park",
      "Idaho Falls",
      "Idylwood",
      "Ilchester",
      "Immokalee",
      "Imperial",
      "Imperial Beach",
      "Independence",
      "Independence",
      "Indian Trail",
      "Indiana",
      "Indianapolis",
      "Indianola",
      "Indio",
      "Inglewood",
      "Inglewood-Finn Hill",
      "Inkster",
      "Inver Grove Heights",
      "Inwood",
      "Iona",
      "Ionia",
      "Iowa City",
      "Irmo",
      "Irondale",
      "Irondequoit",
      "Ironton",
      "Ironville",
      "Irvine",
      "Irving",
      "Irving Park",
      "Irvington",
      "Iselin",
      "Isla Vista",
      "Islip",
      "Issaquah",
      "Ithaca",
      "Ives Estates",
      "Jacinto City",
      "Jackson",
      "Jackson",
      "Jackson",
      "Jackson",
      "Jackson",
      "Jackson",
      "Jackson Heights",
      "Jacksonville",
      "Jacksonville",
      "Jacksonville",
      "Jacksonville",
      "Jacksonville",
      "Jacksonville",
      "Jacksonville Beach",
      "Jamaica",
      "Jamaica Plain",
      "Jamestown",
      "Jamestown",
      "Janesville",
      "Jasmine Estates",
      "Jasper",
      "Jasper",
      "Jefferson",
      "Jefferson",
      "Jefferson City",
      "Jefferson Hills",
      "Jefferson Valley-Yorktown",
      "Jeffersontown",
      "Jeffersonville",
      "Jenison",
      "Jenks",
      "Jennings",
      "Jennings",
      "Jensen Beach",
      "Jericho",
      "Jerome",
      "Jersey City",
      "Johns Creek",
      "Johnson City",
      "Johnson City",
      "Johnston",
      "Johnston",
      "Johnstown",
      "Johnstown",
      "Joint Base Lewis McChord",
      "Joliet",
      "Jollyville",
      "Jonesboro",
      "Joplin",
      "Joppatowne",
      "Junction City",
      "Juneau",
      "Jupiter",
      "Jurupa Valley",
      "Justice",
      "Kahului",
      "Kailua",
      "Kailua-Kona",
      "Kalamazoo",
      "Kalispell",
      "Kaneohe",
      "Kankakee",
      "Kannapolis",
      "Kansas City",
      "Kansas City",
      "Kapa'a",
      "Kapolei",
      "Katy",
      "Kaukauna",
      "Kaysville",
      "Kearney",
      "Kearns",
      "Kearny",
      "Keene",
      "Keizer",
      "Keller",
      "Kelso",
      "Kemp Mill",
      "Ken Caryl",
      "Kendale Lakes",
      "Kendall",
      "Kendall West",
      "Kenmore",
      "Kenmore",
      "Kenner",
      "Kennesaw",
      "Kennett",
      "Kennewick",
      "Kenosha",
      "Kensington",
      "Kent",
      "Kent",
      "Kentwood",
      "Kenwood",
      "Keokuk",
      "Kerman",
      "Kernersville",
      "Kerrville",
      "Kettering",
      "Kettering",
      "Kew Gardens",
      "Kew Gardens Hills",
      "Kewanee",
      "Key Biscayne",
      "Key Largo",
      "Key West",
      "Keystone",
      "Kihei",
      "Kilgore",
      "Killeen",
      "Killingly Center",
      "King City",
      "King of Prussia",
      "Kingman",
      "Kings Bridge",
      "Kings Mountain",
      "Kings Park",
      "Kings Park West",
      "Kings Point",
      "Kingsburg",
      "Kingsgate",
      "Kingsland",
      "Kingsport",
      "Kingston",
      "Kingston",
      "Kingston",
      "Kingsville",
      "Kinnelon",
      "Kinston",
      "Kirkland",
      "Kirksville",
      "Kirkwood",
      "Kiryas Joel",
      "Kissimmee",
      "Klahanie",
      "Klamath Falls",
      "Knightdale",
      "Knik-Fairview",
      "Knoxville",
      "Kokomo",
      "Koreatown",
      "Kuna",
      "Kyle",
      "La Canada Flintridge",
      "La Crescenta-Montrose",
      "La Crosse",
      "La Grande",
      "La Grange",
      "La Grange Park",
      "La Habra",
      "La Homa",
      "La Jolla",
      "La Marque",
      "La Mesa",
      "La Mirada",
      "La Palma",
      "La Porte",
      "La Porte",
      "La Presa",
      "La Puente",
      "La Quinta",
      "La Riviera",
      "La Vergne",
      "La Verne",
      "La Vista",
      "LaGrange",
      "LaPorte",
      "Lacey",
      "Lackawanna",
      "Laconia",
      "Ladera Ranch",
      "Ladson",
      "Lady Lake",
      "Lafayette",
      "Lafayette",
      "Lafayette",
      "Lafayette",
      "Laguna",
      "Laguna Beach",
      "Laguna Hills",
      "Laguna Niguel",
      "Laguna Woods",
      "Lahaina",
      "Lake Arrowhead",
      "Lake Butler",
      "Lake Charles",
      "Lake City",
      "Lake Elsinore",
      "Lake Forest",
      "Lake Forest",
      "Lake Forest Park",
      "Lake Grove",
      "Lake Havasu City",
      "Lake Jackson",
      "Lake Los Angeles",
      "Lake Magdalene",
      "Lake Mary",
      "Lake Morton-Berrydale",
      "Lake Oswego",
      "Lake Ridge",
      "Lake Ronkonkoma",
      "Lake Saint Louis",
      "Lake Shore",
      "Lake Station",
      "Lake Stevens",
      "Lake Wales",
      "Lake Worth Beach",
      "Lake Worth Corridor",
      "Lake Zurich",
      "Lake in the Hills",
      "Lakeland",
      "Lakeland",
      "Lakeland Highlands",
      "Lakeland North",
      "Lakeland South",
      "Lakeland Village",
      "Lakes by the Bay",
      "Lakeside",
      "Lakeside",
      "Lakeside",
      "Lakeville",
      "Lakeway",
      "Lakewood",
      "Lakewood",
      "Lakewood",
      "Lakewood",
      "Lakewood",
      "Lakewood Park",
      "Lamont",
      "Lancaster",
      "Lancaster",
      "Lancaster",
      "Lancaster",
      "Lancaster",
      "Land O' Lakes",
      "Landover",
      "Langley Park",
      "Lanham",
      "Lanham-Seabrook",
      "Lansdale",
      "Lansdowne",
      "Lansing",
      "Lansing",
      "Lansing",
      "Lantana",
      "Laplace",
      "Laramie",
      "Laredo",
      "Largo",
      "Largo",
      "Larkspur",
      "Las Cruces",
      "Las Vegas",
      "Las Vegas",
      "Latham",
      "Lathrop",
      "Lauderdale Lakes",
      "Lauderhill",
      "Laurel",
      "Laurel",
      "Laurel",
      "Laurelton",
      "Laurinburg",
      "Lawndale",
      "Lawrence",
      "Lawrence",
      "Lawrence",
      "Lawrenceburg",
      "Lawrenceburg",
      "Lawrenceville",
      "Lawton",
      "Layton",
      "Lea Hill",
      "League City",
      "Lealman",
      "Leander",
      "Leavenworth",
      "Leawood",
      "Lebanon",
      "Lebanon",
      "Lebanon",
      "Lebanon",
      "Lebanon",
      "Lebanon",
      "Lebanon",
      "Ledyard",
      "Lee's Summit",
      "Leeds",
      "Leesburg",
      "Leesburg",
      "Lehi",
      "Lehigh Acres",
      "Leicester",
      "Leisure City",
      "Leland",
      "Lemay",
      "Lemon Grove",
      "Lemont",
      "Lemoore",
      "Lenexa",
      "Lennox",
      "Lenoir",
      "Lents",
      "Leominster",
      "Leon Valley",
      "Levelland",
      "Levittown",
      "Levittown",
      "Lewis Center",
      "Lewisburg",
      "Lewiston",
      "Lewiston",
      "Lewiston Orchards",
      "Lewisville",
      "Lewisville",
      "Lexington",
      "Lexington",
      "Lexington",
      "Lexington",
      "Lexington",
      "Lexington Park",
      "Lexington-Fayette",
      "Liberal",
      "Liberty",
      "Libertyville",
      "Lighthouse Point",
      "Lilburn",
      "Lima",
      "Limerick",
      "Lincoln",
      "Lincoln",
      "Lincoln",
      "Lincoln",
      "Lincoln Park",
      "Lincoln Park",
      "Lincoln Park",
      "Lincoln Square",
      "Lincolnia",
      "Lincolnton",
      "Lincolnwood",
      "Linda",
      "Linden",
      "Lindenhurst",
      "Lindenhurst",
      "Lindenwold",
      "Lindon",
      "Lindsay",
      "Lino Lakes",
      "Linthicum",
      "Linton Hall",
      "Lisle",
      "Lithia Springs",
      "Little Canada",
      "Little Chute",
      "Little Elm",
      "Little Falls",
      "Little Ferry",
      "Little Neck",
      "Little Rock",
      "Littleton",
      "Live Oak",
      "Live Oak",
      "Livermore",
      "Livingston",
      "Livingston",
      "Livonia",
      "Lochearn",
      "Lockhart",
      "Lockhart",
      "Lockport",
      "Lockport",
      "Lodi",
      "Lodi",
      "Logan",
      "Logan Square",
      "Logansport",
      "Loganville",
      "Loma Linda",
      "Lombard",
      "Lomita",
      "Lompoc",
      "London",
      "Londonderry",
      "Lone Tree",
      "Long Beach",
      "Long Beach",
      "Long Beach",
      "Long Branch",
      "Long Island City",
      "Longmeadow",
      "Longmont",
      "Longview",
      "Longview",
      "Longwood",
      "Lorain",
      "Lorton",
      "Los Alamitos",
      "Los Alamos",
      "Los Altos",
      "Los Angeles",
      "Los Banos",
      "Los Gatos",
      "Los Lunas",
      "Los Osos",
      "Louisville",
      "Louisville",
      "Loveland",
      "Loveland",
      "Loves Park",
      "Lovington",
      "Lowell",
      "Lower Burrell",
      "Lower West Side",
      "Lowes Island",
      "Lubbock",
      "Ludlow",
      "Lufkin",
      "Luling",
      "Lumberton",
      "Lumberton",
      "Lumberton",
      "Lutherville-Timonium",
      "Lutz",
      "Lynbrook",
      "Lynchburg",
      "Lynden",
      "Lyndhurst",
      "Lyndhurst",
      "Lyndon",
      "Lynn",
      "Lynn Haven",
      "Lynnfield",
      "Lynnwood",
      "Lynwood",
      "Lyons",
      "Mableton",
      "Macedonia",
      "Machesney Park",
      "Mack",
      "Macomb",
      "Macon",
      "Madera",
      "Madison",
      "Madison",
      "Madison",
      "Madison",
      "Madison",
      "Madison",
      "Madison Heights",
      "Madison Heights",
      "Madisonville",
      "Magalia",
      "Magna",
      "Magnolia",
      "Mahwah",
      "Maitland",
      "Makakilo",
      "Makakilo City",
      "Malden",
      "Malibu",
      "Maltby",
      "Malvern",
      "Mamaroneck",
      "Manassas",
      "Manassas Park",
      "Manchester",
      "Manchester",
      "Manchester",
      "Manchester",
      "Mandan",
      "Mandeville",
      "Mango",
      "Manhattan",
      "Manhattan",
      "Manhattan Beach",
      "Manitowoc",
      "Mankato",
      "Manorville",
      "Mansfield",
      "Mansfield",
      "Mansfield",
      "Mansfield City",
      "Manteca",
      "Manville",
      "Maple Grove",
      "Maple Heights",
      "Maple Shade",
      "Maple Valley",
      "Maplewood",
      "Maplewood",
      "Marana",
      "Marblehead",
      "Marco",
      "Marco Island",
      "Margate",
      "Maricopa",
      "Marietta",
      "Marietta",
      "Marina",
      "Mariners Harbor",
      "Marinette",
      "Marion",
      "Marion",
      "Marion",
      "Marion",
      "Marion",
      "Markham",
      "Marlboro",
      "Marlborough",
      "Marlton",
      "Marquette",
      "Marrero",
      "Marshall",
      "Marshall",
      "Marshall",
      "Marshalltown",
      "Marshfield",
      "Martha Lake",
      "Martin",
      "Martinez",
      "Martinez",
      "Martinsburg",
      "Martinsville",
      "Martinsville",
      "Martinsville",
      "Maryland City",
      "Maryland Heights",
      "Marysville",
      "Marysville",
      "Marysville",
      "Maryvale",
      "Maryville",
      "Maryville",
      "Mashpee",
      "Mason",
      "Mason City",
      "Masonboro",
      "Maspeth",
      "Massapequa",
      "Massapequa Park",
      "Massena",
      "Massillon",
      "Mastic",
      "Mastic Beach",
      "Matteson",
      "Matthews",
      "Mattoon",
      "Mauldin",
      "Maumee",
      "Maumelle",
      "Mayfield",
      "Mayfield Heights",
      "Maynard",
      "Mays Chapel",
      "Maywood",
      "Maywood",
      "McAlester",
      "McAllen",
      "McComb",
      "McDonough",
      "McFarland",
      "McHenry",
      "McKeesport",
      "McKinley Park",
      "McKinleyville",
      "McKinney",
      "McLean",
      "McMinnville",
      "McMinnville",
      "McPherson",
      "Mead Valley",
      "Meadow Woods",
      "Meadowbrook",
      "Meads",
      "Meadville",
      "Mebane",
      "Mechanicsville",
      "Medford",
      "Medford",
      "Medford",
      "Medina",
      "Medway",
      "Mehlville",
      "Melbourne",
      "Melrose",
      "Melrose",
      "Melrose Park",
      "Melville",
      "Melvindale",
      "Memphis",
      "Menasha",
      "Mendota",
      "Mendota Heights",
      "Menifee",
      "Menlo Park",
      "Menomonee Falls",
      "Menomonie",
      "Mentor",
      "Mequon",
      "Merced",
      "Mercedes",
      "Mercer Island",
      "Mercerville",
      "Mercerville-Hamilton Square",
      "Meriden",
      "Meridian",
      "Meridian",
      "Merriam",
      "Merrick",
      "Merrifield",
      "Merrillville",
      "Merrimack",
      "Merritt Island",
      "Mesa",
      "Mesquite",
      "Mesquite",
      "Metairie",
      "Metairie Terrace",
      "Methuen",
      "Metuchen",
      "Mexico",
      "Miami",
      "Miami",
      "Miami Beach",
      "Miami Gardens",
      "Miami Lakes",
      "Miami Shores",
      "Miami Springs",
      "Miamisburg",
      "Michigan City",
      "Middle Island",
      "Middle River",
      "Middle Valley",
      "Middle Village",
      "Middleborough",
      "Middleburg",
      "Middleburg Heights",
      "Middlesboro",
      "Middlesex",
      "Middleton",
      "Middletown",
      "Middletown",
      "Middletown",
      "Middletown",
      "Middletown",
      "Middletown",
      "Midland",
      "Midland",
      "Midlothian",
      "Midlothian",
      "Midvale",
      "Midway",
      "Midwest City",
      "Milford",
      "Milford",
      "Milford",
      "Milford Mill",
      "Mililani Town",
      "Mill Creek",
      "Mill Creek East",
      "Mill Valley",
      "Millbrae",
      "Millbrook",
      "Millburn",
      "Millbury",
      "Millcreek",
      "Milledgeville",
      "Miller Place",
      "Millington",
      "Millville",
      "Milpitas",
      "Milton",
      "Milton",
      "Milwaukee",
      "Milwaukie",
      "Minden",
      "Mineola",
      "Mineral Wells",
      "Minneapolis",
      "Minneola",
      "Minnetonka",
      "Minnetonka Mills",
      "Minooka",
      "Minot",
      "Mint Hill",
      "Mira Mesa",
      "Miramar",
      "Mishawaka",
      "Mission",
      "Mission Bend",
      "Mission District",
      "Mission Viejo",
      "Missoula",
      "Missouri City",
      "Mitchell",
      "Mitchellville",
      "Moberly",
      "Mobile",
      "Modesto",
      "Mokena",
      "Moline",
      "Monfort Heights",
      "Monmouth",
      "Monroe",
      "Monroe",
      "Monroe",
      "Monroe",
      "Monroe",
      "Monroe",
      "Monroe",
      "Monroeville",
      "Monrovia",
      "Monsey",
      "Montclair",
      "Montclair",
      "Montclair",
      "Montebello",
      "Monterey",
      "Monterey Park",
      "Montgomery",
      "Montgomery",
      "Montgomery",
      "Montgomery Village",
      "Montgomeryville",
      "Monticello",
      "Montrose",
      "Montville Center",
      "Moody",
      "Moore",
      "Moorestown-Lenola",
      "Mooresville",
      "Moorhead",
      "Moorpark",
      "Moraga",
      "Moreno Valley",
      "Morgan City",
      "Morgan Hill",
      "Morgan Park",
      "Morganton",
      "Morgantown",
      "Morningside Heights",
      "Morris",
      "Morris Heights",
      "Morris Park",
      "Morrisania",
      "Morristown",
      "Morristown",
      "Morrisville",
      "Morro Bay",
      "Morton",
      "Morton Grove",
      "Moscow",
      "Moses Lake",
      "Moss Bluff",
      "Moss Point",
      "Mott Haven",
      "Moultrie",
      "Mounds View",
      "Mount Airy",
      "Mount Clemens",
      "Mount Dora",
      "Mount Greenwood",
      "Mount Holly",
      "Mount Holly",
      "Mount Juliet",
      "Mount Kisco",
      "Mount Laurel",
      "Mount Lebanon",
      "Mount Pleasant",
      "Mount Pleasant",
      "Mount Pleasant",
      "Mount Pleasant",
      "Mount Prospect",
      "Mount Sinai",
      "Mount Vernon",
      "Mount Vernon",
      "Mount Vernon",
      "Mount Vernon",
      "Mount Vernon",
      "Mount Washington",
      "Mountain Brook",
      "Mountain Home",
      "Mountain Home",
      "Mountain Park",
      "Mountain Top",
      "Mountain View",
      "Mountlake Terrace",
      "Mukilteo",
      "Muncie",
      "Mundelein",
      "Munhall",
      "Munster",
      "Murfreesboro",
      "Murphy",
      "Murray",
      "Murray",
      "Murraysville",
      "Murrieta",
      "Murrysville",
      "Muscatine",
      "Muscle Shoals",
      "Muscoy",
      "Muskego",
      "Muskegon",
      "Muskegon Heights",
      "Muskogee",
      "Mustang",
      "Myrtle Beach",
      "Myrtle Grove",
      "Nacogdoches",
      "Nampa",
      "Nanakuli",
      "Nanticoke",
      "Nanuet",
      "Napa",
      "Naperville",
      "Naples",
      "Naranja",
      "Narragansett",
      "Nashua",
      "Nashville",
      "Natchez",
      "Natchitoches",
      "Natick",
      "National City",
      "Naugatuck",
      "Navarre",
      "Near North Side",
      "Near South Side",
      "Nederland",
      "Needham",
      "Neenah",
      "Neosho",
      "Nesconset",
      "New Albany",
      "New Baltimore",
      "New Bedford",
      "New Berlin",
      "New Bern",
      "New Braunfels",
      "New Brighton",
      "New Britain",
      "New Brunswick",
      "New Canaan",
      "New Carrollton",
      "New Cassel",
      "New Castle",
      "New Castle",
      "New City",
      "New City",
      "New Fairfield",
      "New Franklin",
      "New Haven",
      "New Haven",
      "New Hope",
      "New Iberia",
      "New Kensington",
      "New Kingman-Butler",
      "New Lenox",
      "New London",
      "New Milford",
      "New Orleans",
      "New Philadelphia",
      "New Port Richey",
      "New Port Richey East",
      "New Providence",
      "New River",
      "New Rochelle",
      "New Smyrna Beach",
      "New South Memphis",
      "New Springville",
      "New Territory",
      "New Ulm",
      "New York City",
      "Newark",
      "Newark",
      "Newark",
      "Newark",
      "Newberg",
      "Newberry",
      "Newburg",
      "Newburgh",
      "Newburyport",
      "Newcastle",
      "Newington",
      "Newington",
      "Newman",
      "Newnan",
      "Newport",
      "Newport",
      "Newport",
      "Newport Beach",
      "Newport East",
      "Newport News",
      "Newton",
      "Newton",
      "Newton",
      "Newton",
      "Niagara Falls",
      "Nicetown-Tioga",
      "Niceville",
      "Nicholasville",
      "Niles",
      "Niles",
      "Niles",
      "Nipomo",
      "Nixa",
      "Noblesville",
      "Noe Valley",
      "Nogales",
      "Norco",
      "Norcross",
      "Norfolk",
      "Norfolk",
      "Norfolk",
      "Norland",
      "Normal",
      "Norman",
      "Norridge",
      "Norristown",
      "North Adams",
      "North Amityville",
      "North Andover",
      "North Andrews Gardens",
      "North Arlington",
      "North Atlanta",
      "North Attleborough Center",
      "North Auburn",
      "North Augusta",
      "North Aurora",
      "North Babylon",
      "North Bay Shore",
      "North Bel Air",
      "North Bellmore",
      "North Bellport",
      "North Bergen",
      "North Bethesda",
      "North Branch",
      "North Branford",
      "North Canton",
      "North Castle",
      "North Center",
      "North Charleston",
      "North Chicago",
      "North Chicopee",
      "North Creek",
      "North Decatur",
      "North Druid Hills",
      "North Fair Oaks",
      "North Fort Myers",
      "North Haven",
      "North Highlands",
      "North Hills",
      "North Hollywood",
      "North Kingstown",
      "North La Crosse",
      "North Las Vegas",
      "North Lauderdale",
      "North Lawndale",
      "North Liberty",
      "North Lindenhurst",
      "North Little Rock",
      "North Logan",
      "North Madison",
      "North Mankato",
      "North Massapequa",
      "North Merrick",
      "North Miami",
      "North Miami Beach",
      "North Myrtle Beach",
      "North New Hyde Park",
      "North Ogden",
      "North Olmsted",
      "North Palm Beach",
      "North Peoria",
      "North Plainfield",
      "North Platte",
      "North Port",
      "North Potomac",
      "North Providence",
      "North Reading",
      "North Richland Hills",
      "North Ridgeville",
      "North Royalton",
      "North Saint Paul",
      "North Salt Lake",
      "North Scituate",
      "North Smithfield",
      "North Stamford",
      "North Tonawanda",
      "North Tustin",
      "North Valley",
      "North Valley Stream",
      "North Versailles",
      "North Wantagh",
      "Northampton",
      "Northbridge",
      "Northbrook",
      "Northbrook",
      "Northdale",
      "Northfield",
      "Northglenn",
      "Northlake",
      "Northport",
      "Northridge",
      "Northview",
      "Norton",
      "Norton",
      "Norton Shores",
      "Norwalk",
      "Norwalk",
      "Norwalk",
      "Norwalk",
      "Norwell",
      "Norwich",
      "Norwood",
      "Norwood",
      "Novato",
      "Novi",
      "Nutley",
      "O'Fallon",
      "O'Fallon",
      "Oak Creek",
      "Oak Forest",
      "Oak Grove",
      "Oak Grove",
      "Oak Harbor",
      "Oak Hill",
      "Oak Hills",
      "Oak Lawn",
      "Oak Park",
      "Oak Park",
      "Oak Park",
      "Oak Ridge",
      "Oak Ridge",
      "Oakdale",
      "Oakdale",
      "Oakland",
      "Oakland",
      "Oakland Park",
      "Oakleaf Plantation",
      "Oakley",
      "Oakton",
      "Oakville",
      "Oakwood",
      "Oatfield",
      "Ocala",
      "Ocean Acres",
      "Ocean City",
      "Ocean Pines",
      "Ocean Springs",
      "Oceanside",
      "Oceanside",
      "Ocoee",
      "Oconomowoc",
      "Odenton",
      "Odessa",
      "Ogden",
      "Ogdensburg",
      "Oil City",
      "Oildale",
      "Ojus",
      "Okemos",
      "Oklahoma City",
      "Okmulgee",
      "Okolona",
      "Olathe",
      "Old Bridge",
      "Old Jamestown",
      "Old Saybrook",
      "Oldsmar",
      "Olean",
      "Olive Branch",
      "Olivehurst",
      "Olney",
      "Olympia",
      "Olympia Heights",
      "Omaha",
      "Onalaska",
      "Oneida",
      "Oneonta",
      "Ontario",
      "Ontario",
      "Opa-locka",
      "Opelika",
      "Opelousas",
      "Opportunity",
      "Oquirrh",
      "Orange",
      "Orange",
      "Orange",
      "Orange",
      "Orange City",
      "Orangeburg",
      "Orangevale",
      "Orchards",
      "Orcutt",
      "Oregon",
      "Oregon",
      "Oregon City",
      "Orem",
      "Orinda",
      "Orland Park",
      "Orlando",
      "Ormond Beach",
      "Oro Valley",
      "Oroville",
      "Oshkosh",
      "Oskaloosa",
      "Ossining",
      "Oswego",
      "Oswego",
      "Otsego",
      "Ottawa",
      "Ottawa",
      "Ottumwa",
      "Overland",
      "Overland Park",
      "Overlea",
      "Oviedo",
      "Owasso",
      "Owatonna",
      "Owensboro",
      "Owings Mills",
      "Owosso",
      "Oxford",
      "Oxford",
      "Oxford",
      "Oxford",
      "Oxnard",
      "Oxon Hill",
      "Oxon Hill-Glassmanor",
      "Ozark",
      "Ozark",
      "Ozone Park",
      "Pace",
      "Pacific Grove",
      "Pacifica",
      "Paducah",
      "Pahrump",
      "Painesville",
      "Palatine",
      "Palatka",
      "Palestine",
      "Palisades Park",
      "Palm Bay",
      "Palm Beach Gardens",
      "Palm City",
      "Palm Coast",
      "Palm Desert",
      "Palm Harbor",
      "Palm River-Clair Mel",
      "Palm Springs",
      "Palm Springs",
      "Palm Valley",
      "Palmdale",
      "Palmer",
      "Palmetto",
      "Palmetto Bay",
      "Palmetto Estates",
      "Palo Alto",
      "Palos Heights",
      "Palos Hills",
      "Palos Verdes Estates",
      "Pampa",
      "Panama City",
      "Panama City Beach",
      "Papillion",
      "Paradise",
      "Paradise",
      "Paradise Valley",
      "Paragould",
      "Paramount",
      "Paramus",
      "Paris",
      "Paris",
      "Park Forest",
      "Park Ridge",
      "Park Slope",
      "Parkchester",
      "Parker",
      "Parker",
      "Parkersburg",
      "Parkland",
      "Parkland",
      "Parkville",
      "Parkway",
      "Parlier",
      "Parma",
      "Parma Heights",
      "Parole",
      "Parsippany",
      "Parsons",
      "Pasadena",
      "Pasadena",
      "Pasadena",
      "Pascagoula",
      "Pasco",
      "Paso Robles",
      "Passaic",
      "Pataskala",
      "Patchogue",
      "Paterson",
      "Patterson",
      "Pawtucket",
      "Payson",
      "Payson",
      "Peabody",
      "Peachtree City",
      "Peachtree Corners",
      "Pearl",
      "Pearl City",
      "Pearl River",
      "Pearland",
      "Pecan Grove",
      "Pedley",
      "Peekskill",
      "Pekin",
      "Pelham",
      "Pelham",
      "Pell City",
      "Pella",
      "Pembroke Pines",
      "Pendleton",
      "Penn Hills",
      "Pennsauken",
      "Pennsport",
      "Pennsville",
      "Pensacola",
      "Peoria",
      "Peoria",
      "Perris",
      "Perry",
      "Perry Hall",
      "Perrysburg",
      "Perth Amboy",
      "Peru",
      "Petal",
      "Petaluma",
      "Petersburg",
      "Pflugerville",
      "Pharr",
      "Phelan",
      "Phenix City",
      "Philadelphia",
      "Phillipsburg",
      "Phoenix",
      "Phoenixville",
      "Picayune",
      "Pickerington",
      "Picnic Point-North Lynnwood",
      "Pico Rivera",
      "Piedmont",
      "Pierre",
      "Pike Creek Valley",
      "Pikesville",
      "Pine Bluff",
      "Pine Castle",
      "Pine Hill",
      "Pine Hills",
      "Pinecrest",
      "Pinehurst",
      "Pinellas Park",
      "Pineville",
      "Pinewood",
      "Piney Green",
      "Pinole",
      "Piqua",
      "Piscataway",
      "Pittsburg",
      "Pittsburg",
      "Pittsburgh",
      "Pittsfield",
      "Placentia",
      "Placerville",
      "Plainfield",
      "Plainfield",
      "Plainfield",
      "Plainfield",
      "Plainview",
      "Plainview",
      "Plainville",
      "Plano",
      "Plano",
      "Plant City",
      "Plantation",
      "Platteville",
      "Plattsburgh",
      "Pleasant Grove",
      "Pleasant Grove",
      "Pleasant Hill",
      "Pleasant Prairie",
      "Pleasanton",
      "Pleasantville",
      "Pleasure Ridge Park",
      "Plover",
      "Plum",
      "Plymouth",
      "Plymouth",
      "Plymouth",
      "Pocatello",
      "Poinciana",
      "Point Pleasant",
      "Pomona",
      "Pompano Beach",
      "Pompton Lakes",
      "Ponca City",
      "Ponte Vedra Beach",
      "Pontiac",
      "Pontiac",
      "Pooler",
      "Poplar Bluff",
      "Poquoson",
      "Port Angeles",
      "Port Arthur",
      "Port Charlotte",
      "Port Chester",
      "Port Hueneme",
      "Port Huron",
      "Port Lavaca",
      "Port Neches",
      "Port Orange",
      "Port Orchard",
      "Port Richmond",
      "Port Royal",
      "Port Saint John",
      "Port Saint Lucie",
      "Port Salerno",
      "Port Washington",
      "Port Washington",
      "Portage",
      "Portage",
      "Portage",
      "Portage Park",
      "Portales",
      "Porterville",
      "Portland",
      "Portland",
      "Portland",
      "Portland",
      "Portsmouth",
      "Portsmouth",
      "Portsmouth",
      "Portsmouth",
      "Portsmouth Heights",
      "Post Falls",
      "Potomac",
      "Pottstown",
      "Pottsville",
      "Poughkeepsie",
      "Poulsbo",
      "Poway",
      "Powder Springs",
      "Powell",
      "Prairie Ridge",
      "Prairie Village",
      "Prairieville",
      "Prattville",
      "Prescott",
      "Prescott Valley",
      "Prichard",
      "Princeton",
      "Princeton",
      "Princeton Meadows",
      "Prior Lake",
      "Prospect Heights",
      "Prosper",
      "Providence",
      "Provo",
      "Prunedale",
      "Pueblo",
      "Pueblo West",
      "Pullman",
      "Punta Gorda",
      "Punta Gorda Isles",
      "Puyallup",
      "Quartz Hill",
      "Queen Creek",
      "Queens",
      "Queens Village",
      "Queensbury",
      "Quincy",
      "Quincy",
      "Raceland",
      "Racine",
      "Radcliff",
      "Radford",
      "Radnor",
      "Rahway",
      "Raleigh",
      "Ramona",
      "Ramsey",
      "Ramsey",
      "Rancho Cordova",
      "Rancho Cucamonga",
      "Rancho Mirage",
      "Rancho Palos Verdes",
      "Rancho Penasquitos",
      "Rancho San Diego",
      "Rancho Santa Margarita",
      "Randallstown",
      "Randolph",
      "Randolph",
      "Rantoul",
      "Rapid City",
      "Ravenna",
      "Raymondville",
      "Raymore",
      "Raynham",
      "Raytown",
      "Reading",
      "Reading",
      "Reading",
      "Red Bank",
      "Red Bank",
      "Red Bluff",
      "Red Hill",
      "Red Hill",
      "Red Oak",
      "Red Wing",
      "Redan",
      "Redding",
      "Redford",
      "Redland",
      "Redlands",
      "Redmond",
      "Redmond",
      "Redondo Beach",
      "Redwood City",
      "Redwood Shores",
      "Reedley",
      "Rego Park",
      "Rehoboth",
      "Reidsville",
      "Reisterstown",
      "Rendon",
      "Reno",
      "Renton",
      "Republic",
      "Reston",
      "Revere",
      "Rexburg",
      "Reynoldsburg",
      "Rialto",
      "Richardson",
      "Richfield",
      "Richfield",
      "Richland",
      "Richmond",
      "Richmond",
      "Richmond",
      "Richmond",
      "Richmond",
      "Richmond Heights",
      "Richmond Hill",
      "Richmond Hill",
      "Richmond West",
      "Richton Park",
      "Ridge",
      "Ridgecrest",
      "Ridgefield",
      "Ridgefield Park",
      "Ridgeland",
      "Ridgewood",
      "Ridgewood",
      "Ringwood",
      "Rio Grande City",
      "Rio Linda",
      "Rio Rancho",
      "Rio Rico",
      "Ripon",
      "River Edge",
      "River Falls",
      "River Forest",
      "River Grove",
      "River Ridge",
      "Riverbank",
      "Riverdale",
      "Riverdale",
      "Riverhead",
      "Riverside",
      "Riverside",
      "Riverton",
      "Riverton",
      "Riverview",
      "Riverview",
      "Riviera Beach",
      "Riviera Beach",
      "Roanoke",
      "Roanoke Rapids",
      "Robbinsdale",
      "Robertsville",
      "Robinson",
      "Robstown",
      "Rochester",
      "Rochester",
      "Rochester",
      "Rochester",
      "Rochester Hills",
      "Rock Hill",
      "Rock Island",
      "Rock Springs",
      "Rockford",
      "Rockland",
      "Rockledge",
      "Rocklin",
      "Rockport",
      "Rockville",
      "Rockville Centre",
      "Rockwall",
      "Rocky Mount",
      "Rocky Point",
      "Rocky River",
      "Roessleville",
      "Rogers",
      "Rogers",
      "Rogers Park",
      "Rohnert Park",
      "Rolla",
      "Rolling Meadows",
      "Roma",
      "Rome",
      "Rome",
      "Romeoville",
      "Romulus",
      "Ronkonkoma",
      "Roosevelt",
      "Rosamond",
      "Rosaryville",
      "Roscoe",
      "Rose Hill",
      "Roseburg",
      "Rosedale",
      "Rosedale",
      "Rosedale",
      "Roselle",
      "Roselle",
      "Roselle Park",
      "Rosemead",
      "Rosemont",
      "Rosemount",
      "Rosenberg",
      "Roseville",
      "Roseville",
      "Roseville",
      "Rossmoor",
      "Rossville",
      "Rossville",
      "Roswell",
      "Roswell",
      "Rotterdam",
      "Round Lake",
      "Round Lake Beach",
      "Round Rock",
      "Rowland Heights",
      "Rowlett",
      "Roy",
      "Royal Kunia",
      "Royal Oak",
      "Royal Palm Beach",
      "Royse City",
      "Rubidoux",
      "Ruskin",
      "Russellville",
      "Ruston",
      "Rutherford",
      "Rutland",
      "Rye",
      "Sachse",
      "Saco",
      "Sacramento",
      "Saddle Brook",
      "Saddlebrooke",
      "Safety Harbor",
      "Saginaw",
      "Saginaw",
      "Saginaw Township North",
      "Sahuarita",
      "Saint Albans",
      "Saint Andrews",
      "Saint Ann",
      "Saint Augustine",
      "Saint Charles",
      "Saint Charles",
      "Saint Clair Shores",
      "Saint Cloud",
      "Saint Cloud",
      "Saint George",
      "Saint Helens",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Louis Park",
      "Saint Marys",
      "Saint Matthews",
      "Saint Michael",
      "Saint Paul",
      "Saint Peter",
      "Saint Peters",
      "Saint Simon Mills",
      "Saint Simons Island",
      "Saks",
      "Salem",
      "Salem",
      "Salem",
      "Salem",
      "Salem",
      "Salem",
      "Salida",
      "Salina",
      "Salinas",
      "Salisbury",
      "Salisbury",
      "Salisbury",
      "Salmon Creek",
      "Salt Lake City",
      "Sammamish",
      "San Angelo",
      "San Anselmo",
      "San Antonio",
      "San Benito",
      "San Bernardino",
      "San Bruno",
      "San Carlos",
      "San Carlos Park",
      "San Clemente",
      "San Diego",
      "San Diego Country Estates",
      "San Dimas",
      "San Fernando",
      "San Francisco",
      "San Gabriel",
      "San Jacinto",
      "San Jose",
      "San Juan",
      "San Juan Capistrano",
      "San Leandro",
      "San Lorenzo",
      "San Luis",
      "San Luis Obispo",
      "San Marcos",
      "San Marcos",
      "San Marino",
      "San Mateo",
      "San Pablo",
      "San Pedro",
      "San Rafael",
      "San Ramon",
      "San Tan Valley",
      "Sand Springs",
      "Sandalfoot Cove",
      "Sandusky",
      "Sandy",
      "Sandy",
      "Sandy Hills",
      "Sandy Springs",
      "Sanford",
      "Sanford",
      "Sanford",
      "Sanger",
      "Santa Ana",
      "Santa Barbara",
      "Santa Clara",
      "Santa Clarita",
      "Santa Cruz",
      "Santa Fe",
      "Santa Fe",
      "Santa Fe Springs",
      "Santa Maria",
      "Santa Monica",
      "Santa Paula",
      "Santa Rosa",
      "Santa Rosa Beach",
      "Santaquin",
      "Santee",
      "Sapulpa",
      "Saraland",
      "Sarasota",
      "Sarasota Springs",
      "Saratoga",
      "Saratoga Springs",
      "Saratoga Springs",
      "Sartell",
      "Satellite Beach",
      "Saugus",
      "Sauk Rapids",
      "Sauk Village",
      "Sault Ste. Marie",
      "Savage",
      "Savannah",
      "Sayreville",
      "Sayreville Junction",
      "Sayville",
      "Scaggsville",
      "Scarsdale",
      "Schaumburg",
      "Schenectady",
      "Schererville",
      "Schertz",
      "Schiller Park",
      "Schofield Barracks",
      "Scotch Plains",
      "Scott Lake",
      "Scottdale",
      "Scotts Valley",
      "Scottsbluff",
      "Scottsboro",
      "Scottsdale",
      "Scranton",
      "SeaTac",
      "Seabrook",
      "Seabrook",
      "Seaford",
      "Seagoville",
      "Seal Beach",
      "Searcy",
      "Seaside",
      "Seaside",
      "Seattle",
      "Sebastian",
      "Sebring",
      "Secaucus",
      "Security-Widefield",
      "Sedalia",
      "Sedona",
      "Sedro-Woolley",
      "Seekonk",
      "Seguin",
      "Selden",
      "Selma",
      "Selma",
      "Seminole",
      "Setauket-East Setauket",
      "Seven Hills",
      "Seven Oaks",
      "Severn",
      "Severna Park",
      "Sevierville",
      "Sewell",
      "Seymour",
      "Seymour",
      "Seymour",
      "Shadow Hills",
      "Shady Hills",
      "Shafter",
      "Shaker Heights",
      "Shakopee",
      "Sharon",
      "Sharonville",
      "Shasta Lake",
      "Shaw",
      "Shawnee",
      "Shawnee",
      "Sheboygan",
      "Sheepshead Bay",
      "Shelby",
      "Shelby",
      "Shelbyville",
      "Shelbyville",
      "Shelbyville",
      "Shelton",
      "Shenandoah",
      "Shepherdsville",
      "Sheridan",
      "Sherman",
      "Sherman Oaks",
      "Sherrelwood",
      "Sherwood",
      "Sherwood",
      "Shiloh",
      "Shiloh",
      "Shiloh",
      "Shiloh",
      "Shirley",
      "Shively",
      "Shoreline",
      "Shoreview",
      "Shorewood",
      "Shorewood",
      "Short Hills",
      "Short Pump",
      "Show Low",
      "Shreveport",
      "Shrewsbury",
      "Sicklerville",
      "Sidney",
      "Sienna Plantation",
      "Sierra Madre",
      "Sierra Vista",
      "Sierra Vista Southeast",
      "Signal Hill",
      "Sikeston",
      "Siloam Springs",
      "Silver City",
      "Silver Firs",
      "Silver Lake",
      "Silver Spring",
      "Silver Springs",
      "Silverdale",
      "Simi Valley",
      "Simpsonville",
      "Sioux City",
      "Sioux Falls",
      "Skokie",
      "Sleepy Hollow",
      "Slidell",
      "Smithfield",
      "Smithfield",
      "Smithfield",
      "Smithtown",
      "Smyrna",
      "Smyrna",
      "Smyrna",
      "Snellville",
      "Snoqualmie",
      "Snyder",
      "Socastee",
      "Socorro",
      "Socorro Mission Number 1 Colonia",
      "Soddy-Daisy",
      "Solana Beach",
      "Soledad",
      "Solon",
      "Somers Point",
      "Somerset",
      "Somerset",
      "Somerset",
      "Somersworth",
      "Somerton",
      "Somerville",
      "Somerville",
      "Sonoma",
      "South Bel Air",
      "South Bend",
      "South Boston",
      "South Bradenton",
      "South Burlington",
      "South Charleston",
      "South Chicago",
      "South Daytona",
      "South El Monte",
      "South Elgin",
      "South Euclid",
      "South Farmingdale",
      "South Fulton",
      "South Gate",
      "South Gate",
      "South Hadley",
      "South Hill",
      "South Holland",
      "South Houston",
      "South Jordan",
      "South Jordan Heights",
      "South Kingstown",
      "South Lake Tahoe",
      "South Laurel",
      "South Lawndale",
      "South Lyon",
      "South Miami",
      "South Miami Heights",
      "South Milwaukee",
      "South Ogden",
      "South Old Bridge",
      "South Orange",
      "South Ozone Park",
      "South Park Township",
      "South Pasadena",
      "South Peabody",
      "South Plainfield",
      "South Portland",
      "South Portland Gardens",
      "South Riding",
      "South River",
      "South Saint Paul",
      "South Salt Lake",
      "South San Francisco",
      "South San Jose Hills",
      "South Shore",
      "South Sioux City",
      "South Suffolk",
      "South Valley",
      "South Venice",
      "South Vineland",
      "South Whittier",
      "South Windsor",
      "South Yarmouth",
      "South Yuba City",
      "Southaven",
      "Southbridge",
      "Southbury",
      "Southchase",
      "Southern Pines",
      "Southfield",
      "Southgate",
      "Southglenn",
      "Southlake",
      "Spanaway",
      "Spanish Fork",
      "Spanish Lake",
      "Spanish Springs",
      "Sparks",
      "Sparta",
      "Spartanburg",
      "Spearfish",
      "Speedway",
      "Spencer",
      "Spokane",
      "Spokane Valley",
      "Spring",
      "Spring Creek",
      "Spring Hill",
      "Spring Hill",
      "Spring Lake",
      "Spring Valley",
      "Spring Valley",
      "Spring Valley",
      "Springboro",
      "Springdale",
      "Springdale",
      "Springdale",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield",
      "Springfield Gardens",
      "Springville",
      "Spuyten Duyvil",
      "St. Charles",
      "St. Charles",
      "St. Johns",
      "St. Louis",
      "St. Marys",
      "St. Petersburg",
      "Stafford",
      "Stafford",
      "Stallings",
      "Stamford",
      "Stanford",
      "Stanton",
      "Starkville",
      "State College",
      "Staten Island",
      "Statesboro",
      "Statesville",
      "Staunton",
      "Steamboat Springs",
      "Stephenville",
      "Sterling",
      "Sterling",
      "Sterling",
      "Sterling Heights",
      "Steubenville",
      "Stevens Point",
      "Stevenson Ranch",
      "Stillwater",
      "Stillwater",
      "Stockbridge",
      "Stockton",
      "Stonecrest",
      "Stoneham",
      "Stony Brook",
      "Stony Point",
      "Storm Lake",
      "Storrs",
      "Stoughton",
      "Stoughton",
      "Stow",
      "Stratford",
      "Streamwood",
      "Streator",
      "Streetsboro",
      "Strongsville",
      "Struthers",
      "Stuart",
      "Studio City",
      "Sturgis",
      "Suamico",
      "Sudbury",
      "Sudley",
      "Suffern",
      "Suffolk",
      "Sugar Hill",
      "Sugar Land",
      "Sugarland Run",
      "Suisun",
      "Suitland",
      "Suitland-Silver Hill",
      "Sulphur",
      "Sulphur Springs",
      "Summerfield",
      "Summerfield",
      "Summerlin South",
      "Summerville",
      "Summit",
      "Summit",
      "Sumter",
      "Sun City",
      "Sun City",
      "Sun City Center",
      "Sun City West",
      "Sun Lakes",
      "Sun Prairie",
      "Sun Valley",
      "Sun Village",
      "Sunland",
      "Sunland Park",
      "Sunny Isles Beach",
      "Sunnyside",
      "Sunnyside",
      "Sunnyvale",
      "Sunrise",
      "Sunrise Manor",
      "Sunset",
      "Sunset Park",
      "Superior",
      "Superior",
      "Surprise",
      "Susanville",
      "Sussex",
      "Suwanee",
      "Swampscott",
      "Swansea",
      "Swansea",
      "Sweetwater",
      "Sweetwater",
      "Sycamore",
      "Sylacauga",
      "Sylvania",
      "Syosset",
      "Syracuse",
      "Syracuse",
      "Tacoma",
      "Tahlequah",
      "Takoma Park",
      "Talladega",
      "Tallahassee",
      "Tallmadge",
      "Tamalpais-Homestead Valley",
      "Tamarac",
      "Tamiami",
      "Tampa",
      "Tanque Verde",
      "Tarboro",
      "Tarpon Springs",
      "Tarrytown",
      "Taunton",
      "Tavares",
      "Taylor",
      "Taylor",
      "Taylors",
      "Taylorsville",
      "Taylorville",
      "Teaneck",
      "Teays Valley",
      "Tehachapi",
      "Temecula",
      "Tempe",
      "Tempe Junction",
      "Temple",
      "Temple City",
      "Temple Terrace",
      "Tenafly",
      "Terrace Heights",
      "Terre Haute",
      "Terrell",
      "Terrytown",
      "Terryville",
      "Tewksbury",
      "Texarkana",
      "Texarkana",
      "Texas City",
      "The Acreage",
      "The Bronx",
      "The Colony",
      "The Crossings",
      "The Dalles",
      "The Hammocks",
      "The Pinery",
      "The Villages",
      "The Woodlands",
      "Thibodaux",
      "Thomasville",
      "Thomasville",
      "Thonotosassa",
      "Thornton",
      "Thousand Oaks",
      "Three Lakes",
      "Throgs Neck",
      "Tiffin",
      "Tifton",
      "Tigard",
      "Tillmans Corner",
      "Timberlake",
      "Timberlane",
      "Timberwood Park",
      "Tinley Park",
      "Tinton Falls",
      "Titusville",
      "Toledo",
      "Tolland",
      "Tomball",
      "Toms River",
      "Tonawanda",
      "Tooele",
      "Topeka",
      "Torrance",
      "Torrington",
      "Totowa",
      "Town 'n' Country",
      "Town and Country",
      "Towson",
      "Tracy",
      "Traverse City",
      "Travilah",
      "Tremont",
      "Trenton",
      "Trenton",
      "Trenton",
      "Tri-Cities",
      "Trinity",
      "Trophy Club",
      "Trotwood",
      "Troutdale",
      "Troy",
      "Troy",
      "Troy",
      "Troy",
      "Troy",
      "Troy",
      "Truckee",
      "Trumbull",
      "Trussville",
      "Tualatin",
      "Tuckahoe",
      "Tucker",
      "Tucson",
      "Tucson Estates",
      "Tujunga",
      "Tukwila",
      "Tulare",
      "Tullahoma",
      "Tulsa",
      "Tumwater",
      "Tupelo",
      "Turlock",
      "Tuscaloosa",
      "Tustin",
      "Twentynine Palms",
      "Twin Falls",
      "Twinsburg",
      "Two Rivers",
      "Tyler",
      "Tyngsboro",
      "Tysons Corner",
      "Ukiah",
      "Union",
      "Union",
      "Union City",
      "Union City",
      "Union City",
      "Union City",
      "Union Hill-Novelty Hill",
      "Uniondale",
      "Unionport",
      "Universal City",
      "Universal City",
      "University",
      "University City",
      "University Heights",
      "University Heights",
      "University Park",
      "University Park",
      "University Place",
      "Upland",
      "Upper Alton",
      "Upper Arlington",
      "Upper Grand Lagoon",
      "Upper Montclair",
      "Upper Saint Clair",
      "Uptown",
      "Urbana",
      "Urbana",
      "Urbandale",
      "Utica",
      "Uvalde",
      "Uxbridge",
      "Vacaville",
      "Vadnais Heights",
      "Vail",
      "Valdosta",
      "Valencia",
      "Valinda",
      "Valle Vista",
      "Vallejo",
      "Valley Falls",
      "Valley Glen",
      "Valley Station",
      "Valley Stream",
      "Valparaiso",
      "Valrico",
      "Van Buren",
      "Van Nest",
      "Van Nuys",
      "Van Wert",
      "Vancouver",
      "Vandalia",
      "Vashon",
      "Venice",
      "Venice",
      "Ventnor City",
      "Ventura",
      "Verde Village",
      "Vermilion",
      "Vermilion-on-the-Lake",
      "Vermillion",
      "Vernal",
      "Vernon",
      "Vernon Hills",
      "Vero Beach",
      "Vero Beach South",
      "Verona",
      "Verona",
      "Vestal",
      "Vestavia Hills",
      "Vicksburg",
      "Victoria",
      "Victorville",
      "Vidalia",
      "Vidor",
      "Vienna",
      "Vienna",
      "Viera East",
      "View Park-Windsor Hills",
      "Villa Park",
      "Villa Rica",
      "Village Park",
      "Village of Campton Hills",
      "Villas",
      "Vincennes",
      "Vincent",
      "Vincent",
      "Vincentown",
      "Vineland",
      "Vineyard",
      "Virginia Beach",
      "Visalia",
      "Visitacion Valley",
      "Vista",
      "Voorhees",
      "Wabash",
      "Waco",
      "Waconia",
      "Wade Hampton",
      "Wadsworth",
      "Waggaman",
      "Wahiawa",
      "Waianae",
      "Wailuku",
      "Waimalu",
      "Waipahu",
      "Waipio",
      "Wake Forest",
      "Wakefield",
      "Wakefield",
      "Waldorf",
      "Waldwick",
      "Walker",
      "Walker Mill",
      "Walla Walla",
      "Wallingford",
      "Wallingford Center",
      "Wallington",
      "Walnut",
      "Walnut Creek",
      "Walnut Park",
      "Waltham",
      "Wanaque",
      "Wantagh",
      "Warner Robins",
      "Warr Acres",
      "Warren",
      "Warren",
      "Warren",
      "Warren Township",
      "Warrensburg",
      "Warrensville Heights",
      "Warrenville",
      "Warrington",
      "Warsaw",
      "Warwick",
      "Wasco",
      "Wasco",
      "Washington",
      "Washington",
      "Washington",
      "Washington",
      "Washington",
      "Washington",
      "Washington Court House",
      "Washington Heights",
      "Washougal",
      "Watauga",
      "Waterbury",
      "Waterford",
      "Waterford",
      "Waterloo",
      "Waterloo",
      "Watertown",
      "Watertown",
      "Watertown",
      "Watertown",
      "Waterville",
      "Watervliet",
      "Watsonville",
      "Wauconda",
      "Waukee",
      "Waukegan",
      "Waukesha",
      "Waunakee",
      "Waupun",
      "Wausau",
      "Wauwatosa",
      "Waverly",
      "Waverly",
      "Wawarsing",
      "Waxahachie",
      "Waxhaw",
      "Waycross",
      "Wayland",
      "Wayne",
      "Wayne",
      "Wayne",
      "Waynesboro",
      "Waynesboro",
      "Weatherford",
      "Weatherford",
      "Webb City",
      "Webster",
      "Webster",
      "Webster Groves",
      "Weddington",
      "Weehawken",
      "Weigelstown",
      "Weirton",
      "Weirton Heights",
      "Wekiwa Springs",
      "Welby",
      "Wellesley",
      "Wellington",
      "Wells Beach Station",
      "Wells Branch",
      "Wenatchee",
      "Wentzville",
      "Weslaco",
      "Wesley Chapel",
      "West Albany",
      "West Allis",
      "West Babylon",
      "West Bend",
      "West Bloomfield Township",
      "West Carrollton City",
      "West Carson",
      "West Chester",
      "West Chicago",
      "West Columbia",
      "West Coon Rapids",
      "West Covina",
      "West Des Moines",
      "West Elkridge",
      "West Elsdon",
      "West Englewood",
      "West Falls Church",
      "West Fargo",
      "West Freehold",
      "West Garfield Park",
      "West Gulfport",
      "West Hartford",
      "West Haven",
      "West Haven",
      "West Haverstraw",
      "West Hempstead",
      "West Henrietta",
      "West Hills",
      "West Hollywood",
      "West Hollywood",
      "West Islip",
      "West Jordan",
      "West Lafayette",
      "West Lake Sammamish",
      "West Lake Stevens",
      "West Lawn",
      "West Linn",
      "West Little River",
      "West Lynchburg",
      "West Melbourne",
      "West Memphis",
      "West Mifflin",
      "West Milford",
      "West Monroe",
      "West New York",
      "West Norriton",
      "West Odessa",
      "West Orange",
      "West Palm Beach",
      "West Park",
      "West Pensacola",
      "West Perrine",
      "West Plains",
      "West Point",
      "West Point",
      "West Puente Valley",
      "West Raleigh",
      "West Richland",
      "West Ridge",
      "West Sacramento",
      "West Saint Paul",
      "West Scarborough",
      "West Sedona",
      "West Seneca",
      "West Springfield",
      "West Springfield",
      "West Torrington",
      "West Town",
      "West University Place",
      "West Valley",
      "West Valley City",
      "West Warwick",
      "West Whittier-Los Nietos",
      "West and East Lealman",
      "Westbrook",
      "Westbury",
      "Westchase",
      "Westchester",
      "Westchester",
      "Westerly",
      "Western Springs",
      "Westerville",
      "Westfield",
      "Westfield",
      "Westfield",
      "Westford",
      "Westlake",
      "Westland",
      "Westminster",
      "Westminster",
      "Westminster",
      "Westmont",
      "Westmont",
      "Weston",
      "Weston",
      "Weston",
      "Westport",
      "Westwood",
      "Westwood",
      "Westwood Lake",
      "Wethersfield",
      "Weymouth",
      "Wharton",
      "Wheat Ridge",
      "Wheaton",
      "Wheaton",
      "Wheeling",
      "Wheeling",
      "White Bear Lake",
      "White Center",
      "White House",
      "White Oak",
      "White Oak",
      "White Plains",
      "White Settlement",
      "Whitefish Bay",
      "Whitehall",
      "Whitehall",
      "Whitehall Township",
      "Whitestone",
      "Whitewater",
      "Whitman",
      "Whitman",
      "Whitney",
      "Whittier",
      "Wichita",
      "Wichita Falls",
      "Wickliffe",
      "Wildomar",
      "Wildwood",
      "Wilkes-Barre",
      "Wilkinsburg",
      "Williamsburg",
      "Williamsburg",
      "Williamsport",
      "Williamstown",
      "Willimantic",
      "Willingboro",
      "Williston",
      "Willmar",
      "Willoughby",
      "Willow Grove",
      "Willowbrook",
      "Willowick",
      "Wilmette",
      "Wilmington",
      "Wilmington",
      "Wilmington",
      "Wilmington",
      "Wilmington Island",
      "Wilson",
      "Wilsonville",
      "Wilton",
      "Wilton Manors",
      "Winchester",
      "Winchester",
      "Winchester",
      "Winchester",
      "Winchester Center",
      "Winder",
      "Windham",
      "Windham",
      "Windsor",
      "Windsor",
      "Windsor",
      "Windsor Locks",
      "Winfield",
      "Winnetka",
      "Winona",
      "Winston-Salem",
      "Winter Garden",
      "Winter Gardens",
      "Winter Haven",
      "Winter Park",
      "Winter Springs",
      "Winthrop",
      "Winton",
      "Wisconsin Rapids",
      "Wixom",
      "Woburn",
      "Wolcott",
      "Wolf Trap",
      "Wood Dale",
      "Wood River",
      "Woodbridge",
      "Woodburn",
      "Woodbury",
      "Woodbury",
      "Woodbury",
      "Woodcrest",
      "Woodhaven",
      "Woodhaven",
      "Woodinville",
      "Woodland",
      "Woodland Hills",
      "Woodland Park",
      "Woodlawn",
      "Woodlawn",
      "Woodlawn",
      "Woodmere",
      "Woodmere",
      "Woodridge",
      "Woodrow",
      "Woods Cross",
      "Woodside",
      "Woodstock",
      "Woodstock",
      "Woodward",
      "Woonsocket",
      "Wooster",
      "Worcester",
      "Worth",
      "Worthington",
      "Worthington",
      "Wrentham",
      "Wright",
      "Wyandanch",
      "Wyandotte",
      "Wyckoff",
      "Wykagyl",
      "Wylie",
      "Wyoming",
      "Wyomissing",
      "Xenia",
      "Yakima",
      "Yankton",
      "Yarmouth",
      "Yazoo City",
      "Yeadon",
      "Yonkers",
      "Yorba Linda",
      "York",
      "York Beach",
      "Yorktown",
      "Yorkville",
      "Youngstown",
      "Youngsville",
      "Ypsilanti",
      "Yuba City",
      "Yucaipa",
      "Yucca Valley",
      "Yukon",
      "Yulee",
      "Yuma",
      "Zachary",
      "Zanesville",
      "Zephyrhills",
      "Zion",
      "Zionsville",
    ],
  },
  Uruguay: {
    code: "UY",
    cities: [
      "Artigas",
      "Barros Blancos",
      "Bella Union",
      "Canelones",
      "Carmelo",
      "Colonia del Sacramento",
      "Delta del Tigre",
      "Dolores",
      "Durazno",
      "Florida",
      "Fray Bentos",
      "Juan L. Lacaze",
      "La Paz",
      "Las Piedras",
      "Libertad",
      "Maldonado",
      "Melo",
      "Mercedes",
      "Minas",
      "Montevideo",
      "Nueva Helvecia",
      "Pando",
      "Paso de Carrasco",
      "Paso de los Toros",
      "Paysandu",
      "Progreso",
      "Rio Branco",
      "Rivera",
      "Rocha",
      "Rosario",
      "Salto",
      "San Carlos",
      "San Jose de Mayo",
      "Santa Lucia",
      "Tacuarembo",
      "Treinta y Tres",
      "Trinidad",
      "Young",
    ],
  },
  Uzbekistan: {
    code: "UZ",
    cities: [
      "Andijon",
      "Angren",
      "Asaka",
      "Bekobod",
      "Bektemir",
      "Beruniy",
      "Beshariq",
      "Beshkent",
      "Bo`ka",
      "Boysun",
      "Bukhara",
      "Bulung'ur Shahri",
      "Charxin",
      "Chelak",
      "Chinoz",
      "Chirchiq",
      "Chiroqchi",
      "Chortoq",
      "Chust",
      "Dardoq",
      "Dashtobod",
      "Denov",
      "Do'stlik Shahri",
      "Druzhba",
      "Fergana",
      "G'azalkent",
      "G'ijduvon Shahri",
      "G`uzor",
      "Gagarin Shahri",
      "Galaosiyo Shahri",
      "Guliston",
      "Gurlan",
      "Hamza",
      "Haqqulobod",
      "Hazorasp",
      "Ishtixon Shahri",
      "Iskandar",
      "Jalolquduq",
      "Jizzax",
      "Jomboy Shahri",
      "Juma Shahri",
      "Karakul'",
      "Kattaqo'rg'on Shahri",
      "Kegeyli Shahar",
      "Kirguli",
      "Kitob",
      "Kogon Shahri",
      "Koson",
      "Kosonsoy",
      "Manghit",
      "Marg'ilon",
      "Marhamat",
      "Muborak",
      "Muynoq",
      "Namangan",
      "Navoiy",
      "Nishon Tumani",
      "Novyy Turtkul'",
      "Nukus",
      "Nurota",
      "Ohangaron",
      "Olmaliq",
      "Olot Shahri",
      "Oltiariq",
      "Oltinko`l",
      "Oqqo'rg'on",
      "Oqtosh",
      "Oyim",
      "Parkent",
      "Paxtakor Shahri",
      "Payshamba Shahri",
      "Piskent",
      "Pop",
      "Qarshi",
      "Qibray",
      "Qiziltepa",
      "Qo'qon",
      "Qo'shko'pir",
      "Qo`rg`ontepa",
      "Qorasuv",
      "Qunghirot",
      "Quva",
      "Quvasoy",
      "Romitan Shahri",
      "Salor",
      "Samarkand",
      "Shahrisabz",
      "Sho'rchi",
      "Shofirkon Shahri",
      "Shovot",
      "Shumanay",
      "Sirdaryo",
      "Sultonobod",
      "Tashkent",
      "Tinchlik",
      "Tirmiz",
      "To'rqao'rg'on",
      "Toshbuloq",
      "Toshloq",
      "Tuytepa",
      "Uchqurghon Shahri",
      "Urganch",
      "Urgut Shahri",
      "Urtaowul",
      "Uychi",
      "Vobkent Shahri",
      "Xiva",
      "Xo`jaobod",
      "Xo`jayli Shahri",
      "Xonobod",
      "Yangi Marg`ilon",
      "Yangiobod",
      "Yangiqo`rg`on",
      "Yangirabot",
      "Yangiyer",
      "Yangiyul",
      "Yaypan",
      "Zafar",
      "Zomin Shaharchasi",
    ],
  },
  "Venezuela, Bolivarian Rep. of": {
    code: "VE",
    cities: [
      "Acarigua",
      "Achaguas",
      "Altagracia de Orituco",
      "Alto Barinas",
      "Anaco",
      "Aragua de Barcelona",
      "Araure",
      "Araya",
      "Aroa",
      "Bailadores",
      "Barcelona",
      "Barinas",
      "Barinitas",
      "Barquisimeto",
      "Barrancas",
      "Barrancas",
      "Baruta",
      "Bejuma",
      "Biruaca",
      "Boca de Aroa",
      "Boca de Uchire",
      "Bocono",
      "Boconoito",
      "Boraure",
      "Cabimas",
      "Cabudare",
      "Cagua",
      "Caicara",
      "Caicara del Orinoco",
      "Caja Seca",
      "Calabozo",
      "Camaguan",
      "Cantaura",
      "Capacho Nuevo",
      "Caraballeda",
      "Caracas",
      "Cariaco",
      "Caripito",
      "Carora",
      "Carrizal",
      "Carupano",
      "Casanay",
      "Casigua El Cubo",
      "Catia La Mar",
      "Caucagua",
      "Caucagueito",
      "Chacao",
      "Chaguaramas",
      "Charallave",
      "Chichiriviche",
      "Chiquinquira",
      "Chivacoa",
      "Ciudad Bolivar",
      "Ciudad Bolivia",
      "Ciudad Guayana",
      "Ciudad Ojeda",
      "Ciudad Piar",
      "Clarines",
      "Cocorote",
      "Colon",
      "Coloncito",
      "Concepcion",
      "Coro",
      "Cua",
      "Cumana",
      "Cumanacoa",
      "Dabajuro",
      "Duaca",
      "Ejido",
      "El Baul",
      "El Cafetal",
      "El Dorado",
      "El Hatillo",
      "El Limon",
      "El Pao",
      "El Sombrero",
      "El Tigre",
      "El Tocuyo",
      "El Vigia",
      "Elorza",
      "Encontrados",
      "Escuque",
      "Guacara",
      "Guama",
      "Guanare",
      "Guanarito",
      "Guarenas",
      "Guasdualito",
      "Guasipati",
      "Guatire",
      "Gueiguee",
      "Gueiria",
      "Higuerote",
      "Juan Griego",
      "Judibana",
      "La Asuncion",
      "La Azulita",
      "La Concepcion",
      "La Dolorita",
      "La Fria",
      "La Grita",
      "La Guaira",
      "La Paragua",
      "La Vela de Coro",
      "La Victoria",
      "La Villa del Rosario",
      "Lagunillas",
      "Lagunillas",
      "Las Tejerias",
      "Las Vegas",
      "Lecherias",
      "Libertad",
      "Lobatera",
      "Los Dos Caminos",
      "Los Puertos de Altagracia",
      "Los Rastrojos",
      "Los Teques",
      "Machiques",
      "Maiquetia",
      "Mamporal",
      "Maracaibo",
      "Maracay",
      "Mariara",
      "Maturin",
      "Mene Grande",
      "Mene de Mauroa",
      "Merida",
      "Michelena",
      "Miranda",
      "Montalban",
      "Moron",
      "Motatan",
      "Nirgua",
      "Nueva Bolivia",
      "Obispos",
      "Ocumare del Tuy",
      "Ospino",
      "Palmira",
      "Palo Negro",
      "Pampan",
      "Pampanito",
      "Pampatar",
      "Pariaguan",
      "Petare",
      "Piritu",
      "Porlamar",
      "Pueblo Nuevo",
      "Puerto Ayacucho",
      "Puerto Cabello",
      "Puerto Cumarebo",
      "Puerto La Cruz",
      "Puerto Piritu",
      "Punta Cardon",
      "Punta Gorda",
      "Punta de Mata",
      "Punta de Piedras",
      "Punto Fijo",
      "Quibor",
      "Rio Chico",
      "Rubio",
      "Sabana de Parra",
      "Sabaneta",
      "San Antonio",
      "San Antonio de Los Altos",
      "San Antonio del Tachira",
      "San Carlos",
      "San Carlos del Zulia",
      "San Cristobal",
      "San Felipe",
      "San Fernando de Apure",
      "San Fernando de Atabapo",
      "San Joaquin",
      "San Jose de Barlovento",
      "San Jose de Guanipa",
      "San Josecito",
      "San Juan Bautista",
      "San Juan de los Morros",
      "San Mateo",
      "San Mateo",
      "San Rafael",
      "San Rafael de Onoto",
      "Sanare",
      "Santa Ana",
      "Santa Ana",
      "Santa Barbara",
      "Santa Cruz de Mara",
      "Santa Cruz de Mora",
      "Santa Cruz de los Taques",
      "Santa Elena de Uairen",
      "Santa Rita",
      "Santa Teresa del Tuy",
      "Socopo",
      "Soledad",
      "Tabay",
      "Tacarigua",
      "Tariba",
      "Temblador",
      "Tia Juana",
      "Timotes",
      "Tinaco",
      "Tinaquillo",
      "Tovar",
      "Trujillo",
      "Tucacas",
      "Tucani",
      "Tucupido",
      "Tucupita",
      "Tumeremo",
      "Turmero",
      "Upata",
      "Urachiche",
      "Urena",
      "Valencia",
      "Valera",
      "Valle de Guanape",
      "Valle de La Pascua",
      "Villa Bruzual",
      "Villa de Cura",
      "Yaguaraparo",
      "Yaritagua",
      "Yumare",
      "Zaraza",
    ],
  },
  "Viet Nam": {
    code: "VN",
    cities: [
      "Bac Giang",
      "Bac Kan",
      "Bac Lieu",
      "Bac Ninh",
      "Bao Loc",
      "Ben Tre",
      "Bien Hoa",
      "Bim Son",
      "Binh Long",
      "Binh Minh",
      "Binh Thuy",
      "Buon Ho",
      "Buon Ma Thuot",
      "Ca Mau",
      "Cai Rang",
      "Cam Pha",
      "Cam Pha Mines",
      "Cam Ranh",
      "Can Gio",
      "Can Giuoc",
      "Can Tho",
      "Cao Bang",
      "Cao Lanh",
      "Cat Ba",
      "Chau GJoc",
      "Chi Linh",
      "Co GJo",
      "Co Loa",
      "Cu Chi",
      "Cung Kiem",
      "Da Lat",
      "Da Nang",
      "Di An",
      "Dien Bien Phu",
      "Dong Ha",
      "Duong GJong",
      "Ea Drang",
      "GJai GJong",
      "GJai Loc",
      "GJai Nghia",
      "GJai Quang",
      "GJinh Van",
      "GJong Hoi",
      "GJong Xoai",
      "GJuc Trong",
      "Gia Nghia",
      "Go Cong",
      "Ha GJong",
      "Ha Giang",
      "Ha Long",
      "Ha Tien",
      "Ha Tinh",
      "Hai Duong",
      "Haiphong",
      "Hanoi",
      "Ho Chi Minh City",
      "Hoa Binh",
      "Hoi An",
      "Hue",
      "Hung Yen",
      "Huyen Lam Ha",
      "Kon Tum",
      "La Gi",
      "Lang Son",
      "Lao Cai",
      "Long Xuyen",
      "Mong Cai",
      "Muong Lay",
      "My Tho",
      "Nam GJinh",
      "Nghia Lo",
      "Nha Be",
      "Nha Trang",
      "Ninh Binh",
      "Ninh Hoa",
      "O Mon",
      "Phan Rang-Thap Cham",
      "Phan Thiet",
      "Phong GJien",
      "Phu Khuong",
      "Phu Ly",
      "Phu Quoc",
      "Pleiku",
      "Quan GJuc Thanh",
      "Quan GJuc Thinh",
      "Quang Minh",
      "Quang Ngai",
      "Qui Nhon",
      "Rach Gia",
      "Sa Dec",
      "Sa Pa",
      "Soc Trang",
      "Son La",
      "Son Tay",
      "Song Cau",
      "Tam Ky",
      "Tan An",
      "Tay Ninh",
      "Thai Binh",
      "Thai Nguyen",
      "Thanh Hoa",
      "Thanh Pho Uong Bi",
      "Thi Tran Tan Thanh",
      "Thoi Lai",
      "Thot Not",
      "Thu Dau Mot",
      "Thuan An",
      "Tra Vinh",
      "Tuy Hoa",
      "Tuyen Quang",
      "Vi Thanh",
      "Viet Tri",
      "Vinh",
      "Vinh Chau",
      "Vinh Long",
      "Vinh Thanh",
      "Vinh Yen",
      "Vung Tau",
      "Xuan Mai",
      "Yen Bai",
      "Yen Vinh",
      "thi xa Quang Tri",
    ],
  },
  "Western Sahara": {
    code: "EH",
    cities: ["Boujdour", "Dakhla", "Laayoune", "Laayoune Plage"],
  },
  Yemen: {
    code: "YE",
    cities: [
      "'Amran",
      "Ad Dali`",
      "Aden",
      "Al Bayda",
      "Al Ghayzah",
      "Al Hazm",
      "Al Hudaydah",
      "Al Mahwit",
      "Ataq",
      "Bajil",
      "Bayt al Faqih",
      "Dhamar",
      "Dhi as Sufal",
      "Hajjah",
      "Ibb",
      "Jawf al Maqbabah",
      "Lahij",
      "Ma'rib",
      "Mukalla",
      "Sa'dah",
      "Sahar",
      "Sanaa",
      "Sayyan",
      "Taiz",
      "Yarim",
      "Zabid",
      "Zinjibar",
    ],
  },
  Luxembourg: {
    code: "LU",
    cities: ["Dudelange", "Esch-sur-Alzette", "Luxembourg"],
  },
  "Virgin Islands (US)": {
    code: "VI",
    cities: ["Charlotte Amalie", "Saint Croix"],
  },
  "West Bank and Gaza Strip": {
    code: "PS",
    cities: [
      "'Abasan al Kabirah",
      "'Anata",
      "'Atarut",
      "'Attil",
      "Abu Dis",
      "Al 'Ayzariyah",
      "Al 'Ubaydiyah",
      "Al Birah",
      "Al Burayj",
      "Al Qararah",
      "Al Yamun",
      "An Nusayrat",
      "Ar Ram wa Dahiyat al Barid",
      "As Samu'",
      "Az Zahiriyah",
      "Az Zuwaydah",
      "Balatah",
      "Bani Na'im",
      "Bani Suhayla",
      "Battir",
      "Bayt Fajjar",
      "Bayt Furik",
      "Bayt Hanun",
      "Bayt Jala",
      "Bayt Lahya",
      "Bayt Sahur",
      "Bayt Ummar",
      "Baytunya",
      "Bethlehem",
      "Dayr al Balah",
      "Dura",
      "East Jerusalem",
      "Efrata",
      "Gaza",
      "Givat Zeev",
      "Halhul",
      "Hebron",
      "Idhna",
      "Jabalya",
      "Janin",
      "Jericho",
      "Khan Yunis",
      "Ma'ale Adummim",
      "Nablus",
      "Old City",
      "Qabatiyah",
      "Qalqilyah",
      "Rafah",
      "Ramallah",
      "Sa'ir",
      "Salfit",
      "Shukat as Sufi",
      "Surif City",
      "Tammun",
      "Tarqumya",
      "Tubas",
      "Tulkarm",
      "Ya'bad",
      "Yatta",
    ],
  },
  Zambia: {
    code: "ZM",
    cities: [
      "Chambishi",
      "Chililabombwe",
      "Chingola",
      "Chinsali",
      "Chipata",
      "Choma",
      "Isoka",
      "Kabwe",
      "Kafue",
      "Kalomo",
      "Kalulushi",
      "Kansanshi",
      "Kaoma",
      "Kapiri Mposhi",
      "Kasama",
      "Kataba",
      "Kawambwa",
      "Kitwe",
      "Livingstone",
      "Luanshya",
      "Lundazi",
      "Lusaka",
      "Maamba",
      "Mansa",
      "Mazabuka",
      "Mbala",
      "Mkushi",
      "Mongu",
      "Monze",
      "Mpika",
      "Mufulira",
      "Mumbwa",
      "Mwinilunga",
      "Nakambala",
      "Nakonde",
      "Nchelenge",
      "Ndola",
      "Petauke",
      "Samfya",
      "Senanga",
      "Sesheke",
      "Siavonga",
      "Sinazongwe",
    ],
  },
  Zimbabwe: {
    code: "ZW",
    cities: [
      "Beitbridge",
      "Bindura",
      "Bulawayo",
      "Chegutu",
      "Chinhoyi",
      "Chipinge",
      "Chiredzi",
      "Chitungwiza",
      "Chivhu",
      "Epworth",
      "Gokwe",
      "Gwanda",
      "Gweru",
      "Harare",
      "Hwange",
      "Kadoma",
      "Kariba",
      "Karoi",
      "Kwekwe",
      "Marondera",
      "Mashava",
      "Masvingo",
      "Mutare",
      "Norton",
      "Redcliff",
      "Rusape",
      "Ruwa",
      "Shamva",
      "Shurugwi",
      "Victoria Falls",
      "Zvishavane",
    ],
  },
  Palau: {
    code: "PW",
    cities: ["Koror", "Koror Town"],
  },
  Vanuatu: {
    code: "VU",
    cities: ["Luganville", "Port-Vila"],
  },
  "Solomon Islands": {
    code: "SB",
    cities: ["Honiara", "Malango"],
  },
  "Antigua and Barbuda": {
    code: "AG",
    cities: ["Saint John's"],
  },
  "Sao Tome and Principe": {
    code: "ST",
    cities: ["Sao Tome"],
  },
};
