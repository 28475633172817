import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

export function CustomArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.246 7.35069C3.20733 7.31227 3.042 7.16719 2.906 7.03205C2.05067 6.23977 0.650667 4.17294 0.223333 3.09117C0.154667 2.92688 0.00933333 2.51153 0 2.28961C0 2.07696 0.048 1.87426 0.145333 1.68082C0.281333 1.43969 0.495333 1.24626 0.748 1.14027C0.923333 1.07204 1.448 0.966044 1.45733 0.966044C2.03133 0.860053 2.964 0.801758 3.99467 0.801758C4.97667 0.801758 5.87133 0.860053 6.454 0.946833C6.46333 0.95677 7.11533 1.06276 7.33867 1.17869C7.74667 1.39133 8 1.80669 8 2.25119V2.28961C7.99 2.5791 7.73667 3.18789 7.72733 3.18789C7.29933 4.21136 5.968 6.2305 5.08333 7.04199C5.08333 7.04199 4.856 7.27053 4.714 7.3699C4.51 7.52491 4.25733 7.60176 4.00467 7.60176C3.72267 7.60176 3.46 7.51498 3.246 7.35069Z"
        fill="#BDC1C6"
      />
    </SvgIcon>
  );
}
